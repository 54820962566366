import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FapModalButtonInterface } from './data/fap-modal-button.interface';



@Component({
    selector: 'fap-modal',
    templateUrl: './fap-modal.component.html',
    styleUrls: ['./fap-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FapModalComponent {

    @Input()
    public inputTitle: string;

    @Input()
    public titleColor = 'black';

    @Input()
    public inputPrimaryButtonInterface: FapModalButtonInterface;

    @Input()
    public inputSecondaryButtonInterface: FapModalButtonInterface;


    @Input()
    public inputIsCloseable = true;

    @Input()
    public inputIsLarge = false;

    @Input()
    public inputIsMedium = false;

    @Input()
    public inputIsMediumSmall = true;

    @Input()
    public inputIsSmall = false;

    @Input()
    public inputIsMediumLarge = false;

    @Input()
    public inputIsExtraLarge = false;

    @Input()
    public showFooter = true;

    @Output()
    public outputOnHide: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public outputOnShow: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('template', { static: true })
    public template: TemplateRef<any>;

    public dialogRef: MatDialogRef<FapModalComponent, any>;

    constructor(public dialog: MatDialog) {}

    public showModal(): void {
        this.dialogRef = this.dialog.open(this.template, {
            width: this.getModalWidth(),
            maxHeight: '90vh'
        });
        this.outputOnShow.emit();
    }

    public hideModal(): void {
        this.dialogRef.close();
        this.outputOnHide.emit();
    }

    private getModalWidth(): string {
        if (this.inputIsExtraLarge) {
            return '100%';
        } else if(this.inputIsMediumLarge) {
            return '800px';
        } else if (this.inputIsLarge) {
            return '600px';
        } else if (this.inputIsMedium) {
            return '500px';
        } else if (this.inputIsMediumSmall) {
            return '400px';
        } else if (this.inputIsSmall) {
            return '200px';
        }
    }
}
