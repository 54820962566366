<form class="theme-form" [formGroup]="groupForm">
    <div class="form-group">
        <mat-form-field class="w-90">
            <mat-label class="col-form-label">{{ 'people.groups.groupName' | translate }}</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="groupForm.controls['name'].invalid">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
        <!-- <div class="w-90">
            <mat-label style="color: gray; margin-bottom: 10px; display: block;">{{ "people.groups.users" | translate }}</mat-label>
            <ng-multiselect-dropdown
                [placeholder]="'people.groups.users' | translate"
                [settings]="dropdownSettings"
                [data]="persons"
                [(ngModel)]="selectedItems" 
                [ngModelOptions]="{standalone: true}"
                [class.is-invalid]="f?.users?.errors?.required && f?.users?.touched"
                >
            </ng-multiselect-dropdown>
        </div> -->
    </div>
</form>
<div class=" mt-3 text-center">
    <button class="btn btn-lg" type="submit" [disabled]="groupForm.pristine || groupForm.invalid" (click)="submit()">{{ 'save' | translate }}
        <i class="material-icons pl-1">send</i>
    </button>
</div>
