<form [formGroup]="addEditTranslationForm">
    <div class="form-group mb-0">
      <div class="row align-items-baseline">
        <div class="col-12">
          <div class="input-group w-100">
            <div class="langs">
              <ng-container *ngFor="let language of languages">
                <div *ngIf="language.id === langString" class="w-100 current_language">
                  <mat-form-field class="w-100">
                    <mat-label>{{ "translations.addTranslation" | translate }}</mat-label>
                    <input matInput [placeholder]="language.label" [formControlName]="language.id" (focusout)="onFocusOut(language.id, $event)">
                  </mat-form-field>
                  <div class="show_all_toggle">
                    <span (click)="toggleShowAllLanguages()">{{ 'translations.showAllLanguages' | translate }}</span>
                  </div>
                </div>
                <div class="w-100 other_languages" [class.show_all]="showAll === true">
                  <div *ngIf="language.id !== langString" class="w-100">
                    <mat-form-field class="w-100">
                      <mat-label>{{ language.label }}</mat-label>
                      <input matInput [placeholder]="language.label" [formControlName]="language.id">
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-12">
              <button class="btn" (click)="addTranslation()">{{ 'translations.save' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  