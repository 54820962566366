import { FapHelper } from '../../../../core/heplers/fap.helper';
import { ToastrService } from 'ngx-toastr';
import { TypesService } from '../../../../core/services/api/types/types.service';
import {
    Component,
    Input,
    EventEmitter,
    Output,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    OnInit,
    ViewChild,
    ElementRef,
    OnDestroy
} from '@angular/core';

import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    AbstractControl,
} from '@angular/forms';

import { IngredientTypeInterface } from '../../../../core/interfaces/type/ingredient-type.interface';
import { IngredientTypeModel } from '../../../../core/models/type/ingredient-type.model';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';
import { ResponseModel } from '../../../../core/models/response.model';
import { FapModalComponent } from '../../../../shared/partials';

@Component({
    selector: 'fap-add-edit-ingredient-type',
    templateUrl: './fap_add-edit-ingredient-type.component.html',
    styleUrls: ['./fap_add-edit-ingredient-type.component.scss']
})
export class FapAddEditIngredientTypeComponent implements OnChanges, OnInit, OnDestroy {

    @Input()
    public ingredientType: IngredientTypeModel;

    @Output()
    public ingredientsTypeUpdated: EventEmitter<IngredientTypeModel> = new EventEmitter();

    @Output()
    public ingredientsTypeCreated: EventEmitter<IngredientTypeModel> = new EventEmitter();

    @Output()
    public DeleteIngredient: EventEmitter<number> = new EventEmitter();

    public ingredientTypeForm: UntypedFormGroup;

    public innerImage: string | ArrayBuffer = 'assets/images/plus-icon.png';
    public imageTypePicture: File;
    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public isEdit = false;
    public translation = null;
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    
    @ViewChild('mySelect') mySelect;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    public prefix = 'INGREDIENT_';

    constructor(private formBuilder: UntypedFormBuilder,
                private toastyService: ToastrService,
                private typesService: TypesService,
                public globalRegistry: GlobalRegistryService,
                public translate: TranslateService,
                private cd: ChangeDetectorRef,
                private navService: NavService) {
        this.initIngredientTypeForm();
    }

    ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'ingredientType')) {
            this.initIngredientTypeForm(this.ingredientType);
        }
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: AbstractControl = this.ingredientTypeForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public submit(): void {
        if(this.ingredientTypeForm.invalid) {
            Object.keys(this.ingredientTypeForm.controls).forEach((controlName: string): void =>
                this.ingredientTypeForm.controls[controlName].markAsTouched()
            );
        }

        const body: IngredientTypeInterface = {
            name: this.ingredientTypeForm.controls['name'].value,
            name_t: this.ingredientTypeForm.controls['name_t'].value,
            color: this.ingredientTypeForm.controls['color'].value,
            image: this.imageTypePicture
        };

        this.ingredientType ? this.updateIngredientType({...{id: this.ingredientType.id}, ...body}) : this.createIngredientType(body);
        // this.mySelect.close();
    }

    public imageChanged(file: File): void {
        this.imageTypePicture = file;
    }

    private createIngredientType(ingredientType: IngredientTypeInterface): void {
        this.typesService.addIngredientType(FapHelper.convertObjectToFormData(ingredientType)).subscribe(
            (ingredientTyp: ResponseModel<IngredientTypeModel>): void => {
                this.globalRegistry.reloadIngredientsTypes();
                // this.globalRegistry.reloadIngredients();
                this.toastyService.success(this.translate.instant('stock.inredientType.successAddMessage'));
                this.ingredientsTypeCreated.emit(ingredientTyp.body.results);
                this.innerImage = 'assets/images/plus-icon.png';
            },
            (): void => {
                this.toastyService.error(this.translate.instant('stock.inredientType.failAddMessage'));
            }
        );
    }

    private updateIngredientType(ingredientType: IngredientTypeInterface) {
        this.typesService.updatengredientType(FapHelper.convertObjectToFormData(ingredientType), ingredientType.id).subscribe(
            (ingredientTyp: ResponseModel<IngredientTypeModel>): void => {
                this.globalRegistry.reloadIngredientsTypes();
                // this.globalRegistry.reloadIngredients();
                this.toastyService.success(this.translate.instant('stock.inredientType.successUpdateMessage'));
                this.ingredientsTypeUpdated.emit(ingredientTyp.body.results);
                this.innerImage = 'assets/images/plus-icon.png';
            },
            (): void => {
                this.toastyService.error(this.translate.instant('stock.inredientType.failUpdateMessage'));
            }
        );
    }

    private initIngredientTypeForm(ingredientType?: IngredientTypeModel): void {
        this.ingredientTypeForm = this.formBuilder.group({
                name: [ingredientType ? ingredientType.name : '', Validators.compose([
                    Validators.required,
                ])],
                name_t: [ingredientType ? ingredientType.nameT : ''],
                color: [ingredientType ? ingredientType.color : '']
        });
        if (ingredientType) {
            this.innerImage = ingredientType.image;
        }
    }

    

      convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
          const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
          camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
        }
        return camelCaseObj;
      }
      
      addEditTranslation() {
        const name = this.nameInput.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.ingredientType);
        if(name === null || name === '' || type.nameT === null) {
            this.translation = null
            this.addEditTranslationsModal.showModal();
        } else {
            this.globalRegistry.systemData.translations.forEach(translation => {
                if(translation.id === type.nameT) {
                    this.translation = translation;
                    console.log(this.translation);
                    this.addEditTranslationsModal.showModal();
                }
            });
        }
        }
    
        onAddUpdateTranslation(translation) {
            console.log(translation);
            this.translation = translation;
            this.ingredientTypeForm.controls['name_t'].setValue(translation.id);
            this.ingredientTypeForm.controls['name'].setValue(translation[this.langString]);
            this.addEditTranslationsModal.hideModal();
        }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

}
