<fap-activities-add-edit-layout
    [isEditMode]="isEditMode"
    [isPreviewMode]="isPreviewMode"
    [equipments]="equipments"
    [activity]="activity"
    [activityTypes]="activityTypes"
    [activityGroups]="activityGroups"
    [farms]="globalRegistry.systemData.farms"
    [lots]="globalRegistry.systemData.lots"
    (deleteActivity)="deleteActivity($event)"
    (submitActivity)="submitActivity($event)"
    (updateActivity)="updateActivity($event)"
>
</fap-activities-add-edit-layout>
