<div class="d-flex justify-content-center">
    <mat-form-field
        [ngSwitch]="context"
       
        class="w-100">
        <mat-label *ngSwitchCase="'pathogen'">
            {{ 'farm.crop.disease.pathogen' | translate }}
        </mat-label>
        <mat-label *ngSwitchCase="'weed'">
            {{ 'farm.crop.disease.weed' | translate }}
        </mat-label>
        <mat-label *ngSwitchCase="'insect'">
            {{ 'farm.crop.disease.insect' | translate }}
        </mat-label>
        <mat-select [(ngModel)]="selectedDisease" matNativeControl>
            <mat-form-field appearance="fill" class="w-100">
                <!-- <mat-label>
                    <i class="fa fa-search f-20"></i>
                </mat-label> -->
                <input matInput #diseaseFilter placeholder="Search">
            </mat-form-field>
            <mat-option style="height: 0!important;"></mat-option>
            <mat-option *ngFor="let disease of diseases | filter : diseaseFilter.value" [value]="disease.value">{{ disease.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <i class="fa fa-plus f-20 ml-2" (click)="createDisease()"></i>
</div>
<div class="d-flex justify-content-center">
    <button
        class="btn btn-success"
        type="button"
        (click)="onSelectDisease()"
        [disabled]="selectedDisease===null">
        {{ 'add' | translate }}
    </button>
</div>

<fap-modal #createDiseaseModal
    inputTitle="{{ 'create' | translate }}/{{ 'edit' | translate }} {{ 'farm.crop.disease.' + context | translate }}"
    [inputIsExtraLarge]="true">
    <fap-edit #editDisease [context]="context" (submitEvent)="createdDisease($event)"></fap-edit>
</fap-modal>
