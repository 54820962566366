import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CoordCheckbox } from '../../../../shared/layout/fap_main-map/fap_main-map.component';
import { environment } from '../../../../../environments/environment';
import { MapPointInterface } from '../../../../shared/layout/fap_main-map/data/map-point.interface';
import { MapButtonInterface } from '../../../../shared/layout/fap_main-map/data/map-button.interface';
import { MapPolygonInterface } from '../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { MapMarkerInterface } from '../../../../shared/layout/fap_main-map/data/map-marker.interface';
import { MapPolylineSegmentInterface } from '../../../../shared/layout/fap_main-map/data/map-polyline.interface';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MapHelper } from '../../../../core/heplers/map.helper';
import { LatLng, MapsAPILoader, PolyMouseEvent } from '@agm/core';
import { PathEvent } from '@agm/core/directives/polyline';
import { CsvDataService } from '../../../../shared/services/csv-data.service';
import { MapEmitterInterface } from '../../../../shared/layout/fap_main-map/data/map-emirter.interface';
import { NgxCSVParserError, NgxCsvParser } from 'ngx-csv-parser';
import { MapMenuOptionsInterface } from '../../../../shared/layout/fap_main-map/data/map-menu-options.interface';
import { PolygonPathEvent } from '@agm/core/directives/polygon';

@Component({
  selector: 'fap-attributes-map',
  templateUrl: './fap-attributes-map.component.html',
  styleUrls: ['./fap-attributes-map.component.scss']
})
export class FapAttributesMapComponent implements OnInit, OnChanges, OnDestroy {

  public latitude: number;
    public longitude: number;
    public address: string;
    public mapTypeControl = true;
    public mapSearchToggle1: boolean;
    public coordsListToggle = false;
    public editable: number;
    public editableCoords: number[][] = [];
    public editableCoordsBackup: number[][] = [];
    public markerCoordinates: number[][] = [];
    public changedCoordinate: number;
    public csvRecords: any[] = [];
    public header = true;
    public formCoords;
    public zoom = 8;
    public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';


    @Output()
    public toggledMap: EventEmitter<boolean> = new EventEmitter();
    @Output()
    public shiftMap: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('mapRevertBtn')
    public mapRevertBtn: ElementRef;

    @ViewChild('searchRef')
    public searchRef: ElementRef;

    @Input() public showMap = true;

    @ViewChild('fileImportInput', {static: false})
    public fileImportInput: any;

    private isCreateMod: boolean;
    private isFarm: boolean;
    private polyLength: number;
    private geoCoder;
    private checkedBoxes: number[] = [];
    private checkboxArr: CoordCheckbox[];
    private subscriptions: Array<Subscription> = [];
    public size;
    public icon = './assets/images/fap/Pin-white.svg';
    public object = [];
    public showBtn = true;
    public apiUrl = environment.baseBackendUrl;
    public opened = false;

    circleRadius = 20; // in meters
    circleFillColor = '#FF0000';
    circleStrokeColor = '#000000';
    circleStrokeWeight = 2;

  @Input()
  public coords : any = null;

  @Input()
  public polygons : any = null;

  @Input()
  public polylines: any = null;

  @Input()
  public mapType = 'coords';

  @Output()
  public editRelation: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public emitCoords: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public emitPolylines: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public emitPolygons: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public closePopup: EventEmitter<any> = new EventEmitter<any>();
  public bounds: google.maps.LatLngBounds;
  public contextmenuX = 0;
  public contextmenuY = 0;
  public contextMenuEnabled = false;
  @Input() public isEditMode = false;
  public mapHasContextMenu = false;
  public rightClickLastPosition: MapPointInterface = {};
  public buttons: Array<MapButtonInterface> = [];
  public onMapInit: BehaviorSubject<google.maps.Map> = new BehaviorSubject<google.maps.Map>(null);
  public mapFocusPoint: MapPointInterface = {};
  public mapPolygons: MapPolygonInterface[] = [];
  public mapMarkers: MapMarkerInterface[] = [];
  public mapPolylines: MapPolylineSegmentInterface[] = [];
  public lastPoligonTouchedSelectedArea = 0;
  public onLastPolyPathChange: EventEmitter<MapPolygonInterface> = new EventEmitter<MapPolygonInterface>();
  public onPolyRightClick: EventEmitter<MapEmitterInterface> = new EventEmitter<MapEmitterInterface>();
  public polyDrangEnd: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  public lineClicked: EventEmitter<PolyMouseEvent> = new EventEmitter<PolyMouseEvent>();
  public polyPathChanged: EventEmitter<PathEvent> = new EventEmitter<PathEvent>();
  public lastPoligonTouchedSelectedLenght: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public clickedRevertBtn: EventEmitter<void> = new EventEmitter<void>();
  public onPalygonDrawed: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public currentLocation: MapPointInterface = {};
  public mapMenuOptions: MapMenuOptionsInterface[] = [];
  public mapPoligonStrokeColor = 'blue';
  public mapPoligonFillColor = 'blue';
  public options;
  public polylinePath = []

  constructor(private fb: UntypedFormBuilder, private csvDataService: CsvDataService, private ngxCsvParser: NgxCsvParser,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {}

    public ngOnInit(): void {
      this.editableCoordsBackup = this.editableCoords;
            this.isCreateMod = true;
        // Revert changes
        // this.clickedRevertBtn.asObservable().subscribe(() => {
            this.editableCoords = this.editableCoordsBackup;
            this.checkboxArray();
            this.dynamicCheckboxCreate();
            if (!this.isCreateMod) {
                this.redrawPolygon();
            } else {
                this.polyLength = 0;
                this.lastPoligonTouchedSelectedArea = 0;
            }
            this.polygonSubmit();
        // });
      // this.getLengthOfPolygon().subscribe()
      console.log(this.apiUrl);
     
      this.lastPoligonTouchedSelectedLenght.subscribe((value: number) => {
          this.polyLength = value;
      });
      this.clickedRevertBtn.subscribe(() => {
        this.mapPolygons = [];
        this.mapPolylines = [];
      });
      this.onPalygonDrawed.subscribe(() => {
              this.setMapMarkers();
      });
      this.centerMapOnMarkers();
      this.mapsAPILoader.load().then(() => {
          this.geoCoder = new google.maps.Geocoder();
          const autocomplete = new google.maps.places.Autocomplete(this.searchRef.nativeElement);
          autocomplete.addListener('place_changed', () => {
              this.ngZone.run(() => {
                  const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                  if (place.geometry === undefined || place.geometry === null) {
                      this.mapFocusPoint.lat = this.currentLocation.lat;
                      this.mapFocusPoint.lng = this.currentLocation.lng;
                      return;
                  }
                  this.latitude = place.geometry.location.lat();
                  this.longitude = place.geometry.location.lng();
                  this.mapFocusPoint.lat = this.latitude;
                  this.mapFocusPoint.lng = this.longitude;
                  this.zoom = 16;
                  this.searchRef.nativeElement.value = ''
              });
          });
      });
      this.centerMapOnUserLocation();
      console.log(this.coords);
  }

  public centerMapOnUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
          this.mapFocusPoint.lat = position.coords.latitude;
          this.mapFocusPoint.lng = position.coords.longitude;
          this.zoom = 15;
      });
  }
}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if(Object.prototype.hasOwnProperty.call(changes, 'mapType')) {
      // this.isEditMode = true;
      this.mapHasContextMenu = true;
      if(this.mapType === 'coords') {
        this.polyLength = 0;
        this.mapPolylines = [];
        this.mapPolygons = [];
        this.mapMarkers = [];
        this.polylinePath = [];
        this.editableCoords = [];
        this.mapPolylines = [];
        console.log(this.coords);
        setTimeout(() => {
          if(this.coords) {
            const coords = this.coords.coordinates;
            if(coords) {
              const lat = parseFloat(coords[0]);
              const lng = parseFloat(coords[1]);
              const coord: MapMarkerInterface = {
                lat, 
                lng
              }
              this.mapMarkers.push(coord);
              console.log(this.mapMarkers);
              this.centerMapOnMarkers();
              this.zoom = 10;
            }
          }
        }, 500)
      }
      if(this.mapType === 'surface') {
        this.polyLength = 0;
        this.mapPolylines = [];
        this.mapMarkers = [];
        this.polylinePath = [];
        this.editableCoords = [];
        this.mapPolygons = this.polygons;
        // console.log(this.mapPolygons);
        if(this.mapPolygons && this.mapPolygons.length && this.mapPolygons[0].points) {
          this.calculateDistance(this.mapPolygons[0].points);
          this.lastPoligonTouchedSelectedArea = MapHelper.getPolygonArea(this.mapPolygons[0].points, 'ha');
          this.mapPolygons[0].points.map((point: any): any => {
            this.editableCoords.push([point.lat, point.lng]);
          });
          this.centerMapOnPolygons();
        }
      }
      if(this.mapType === 'polyline') {
        this.polyLength = 0;
        this.mapPolylines = [];
        this.mapPolygons = [];
        this.mapMarkers = [];
        this.polylinePath = [];
        this.editableCoords = [];
        this.mapPolylines = this.polylines;
        // console.log(this.mapPolylines);
        if(this.mapPolylines && this.mapPolylines.length && this.mapPolylines[0].points) {
          this.calculateCenter(this.mapPolylines[0].points);
          this.mapPolylines[0].points.forEach(element => {
            this.polyLineChange(element);
            this.polylinePath.push(element);
          });
          this.calculateDistance(this.mapPolylines[0].points);
        }
      }
    }
  }

  public onContextMenuRightClick(event: any): void {
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    if (this.isEditMode && this.mapHasContextMenu) {
        this.contextMenuEnabled = true;
    }
}

public clickedMarker(ev: MapMarkerInterface) {
  console.log(ev.infoWindowDetails);
}

public mapRightClicked(event): void {
  console.log(event);
  this.rightClickLastPosition.lat = event.coords.lat;
  this.rightClickLastPosition.lng = event.coords.lng;
  this.onContextMenuRightClick(event);
}

public onMapReady(gmap: google.maps.Map): void {
  this.buttons.forEach((button: MapButtonInterface): void => {
      gmap.controls[google.maps.ControlPosition.LEFT_CENTER].push(document.getElementById(button.value));
  });
  this.onMapInit.next(gmap);
}

public clearLastLine() {
  if(this.mapPolylines.length) {
    this.mapPolylines[0].points.pop();
    this.mapMarkers.pop();

    console.log(this.mapPolylines);
    console.log(this.mapMarkers);
  }
}

public getCoords($event) {
  console.log($event);
  if(this.mapType === 'coords') {
    this.mapMarkers = [];
    this.mapMarkers.push($event.coords);
    const coordinates = [$event.coords.lat, $event.coords.lng]
    const coord = {
      type: 'Point',
      coordinates: coordinates
    }
    this.coords = coord
    this.emitCoords.emit(coord);
  }
  this.disableContextMenu()
}

public disableContextMenu(): void {
  this.contextMenuEnabled = false;
}

private polygonSubmit(): void {
  console.log(this.editableCoords);
  this.emitPolygons.emit(this.mapPolygons);
}

private polylineSubmit(): void {
  console.log(this.editableCoords);
  this.emitPolylines.emit(this.mapPolylines);
}

public get coordCheckboxes(): UntypedFormArray {
  return this.formCoords.get('coords');
}

private setMapMarkers(): void {
  this.mapMarkers = [];
  this.editableCoords.forEach((coord: number[], index: number) => {
      if ((this.markerCoordinates.length === this.editableCoords.length) ? (this.markerCoordinates[index][0] === coord[0]) : true) {
          this.mapMarkers.push({lat: coord[0], lng: coord[1], label: this.getNumber(index + 1), iconUrl: './assets/images/fap/Pin-white.svg'});
      } else {
          this.mapMarkers.push({lat: coord[0], lng: coord[1], label: this.getNumber(index + 1), iconUrl: './assets/images/fap/Pin-green.svg'});
          this.changedCoordinate = index;
      }
  });
  this.markerCoordinates = this.editableCoords;
}

public getNumber(i: number): string {
  if (i % 100 === 0 && i !== 0) {
      return '' + i + '.';
  } else if (i % 10 === 0 && i !== 0) {
      return '0' + i + '.';
  } else {
      return '00' + i + '.';
  }
}

private fileChangeListener($event: any): void {
  // Select the files from the event
  const files = $event.srcElement.files;
  // Parse the file you want to select for the operation along with the configuration
  this.ngxCsvParser.parse(files[0], {header: this.header, delimiter: ','})
      .pipe().subscribe((result: Array<any>) => {
      this.csvRecords = result;
      this.importCoords(result);
  }, (error: NgxCSVParserError) => {
    console.log(error);
  });
}

public checkboxFormBuilder(): UntypedFormArray {
  const arr = this.checkboxArr.map((coords: CoordCheckbox) => {
      return this.fb.control(coords.selected);
  });
  return this.fb.array(arr);
}

public selectAllCheckboxes(): void {
  let checker = true;
  this.coordCheckboxes.controls.forEach((checkboxItem: AbstractControl) => {
      checker = checker && checkboxItem.value;
  });
  if (!checker) {
      this.coordCheckboxes.controls.forEach((checkboxItem: AbstractControl) => {
          checkboxItem.setValue(true);
      });
  } else {
      this.coordCheckboxes.controls.forEach((checkboxItem: AbstractControl) => {
          checkboxItem.setValue(false);
      });
  }

}

public getCheckboxIndex($event: Event, index: number): void {
  this.checkedBoxes.push(index);
}

public checkboxArray(): void {
  this.checkboxArr = [];
  this.editableCoords.map((coords: number[], index: number) => {
      this.checkboxArr.push({id: index, coords, selected: false});
  });
}

// Delete selected coordinates
private deleteCoords(value: any): void {
  console.log(this.editableCoords);
  const selectedIndex = [];
  const coordinates = {lat: this?.editableCoords[0][0], lng: this?.editableCoords[0][1]};
  value.coords.forEach((selected: boolean, i: number) => {
      if (selected === true) {
          selectedIndex.push(i);
      }
  });
  this.editableCoords = this.arrayRemove(this.editableCoords, selectedIndex);
  if(this.mapType === 'surface') {
    this.polygonSubmit();
  }
  if(this.mapType === 'polyline') {
    const poly = this.mapPolylines[0].points.filter(objCoord => {
      return this.editableCoords.some(arrCoord => {
          return objCoord.lat === arrCoord[0] && objCoord.lng === arrCoord[1];
      });
  });
  this.mapPolylines[0].points = poly;
  this.polylinePath = this.mapPolylines[0].points;
  console.log(this.mapPolylines);
    this.polylineSubmit();
  }
  if (this.editableCoords.length === 0) {
      this.mapPolygons = [];
  }
  this.mapFocusPoint.lat = coordinates.lat;
  this.mapFocusPoint.lng = coordinates.lng;
  this.setMapMarkers();
  this.checkboxArray();
  this.dynamicCheckboxCreate();
  if(this.mapType === 'surface') {
    this.redrawPolygon();
  }
}

private importCoords(results: any): void {
  const importedCoords: number[][] = [];
  results.forEach((record: any) => {
      importedCoords.push([Number.parseFloat(record.lat), Number.parseFloat(record.lng)]);
  });
  this.editableCoords = this.multiArrayUnique(importedCoords);
  this.polygonSubmit();
  this.polylineSubmit();
  // this.editableCoordsBackup = this.editableCoords;
  this.mapFocusPoint.lat = this.editableCoords[0][0];
  this.mapFocusPoint.lng = this.editableCoords[0][1];
  this.redrawPolygon();
  this.checkboxArray();
  this.dynamicCheckboxCreate();
  this.setMapMarkers();
}

private multiArrayUnique(arr: number[][]): number[][] {
  const uniques = [];
  const itemsFound = {};
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < arr.length; i++) {
      const stringifies = JSON.stringify(arr[i]);
      if (itemsFound[stringifies]) {
          continue;
      }
      uniques.push(arr[i]);
      itemsFound[stringifies] = true;
  }
  return uniques;
}

private dynamicCheckboxCreate(): void {
  this.formCoords = this.fb.group({
      coords: this.checkboxFormBuilder()
  });
}

private exportCoords(): void {
  if (this.editableCoords.length > 0) {
      const items = [];
      this.editableCoords.forEach((line: number[]) => {
          const csvLine = {
              lng: line[1],
              lat: line[0]
          };
          items.push(csvLine);
      });
      this.csvDataService.exportToCsv('coords.csv', items);
  }
}

private arrayRemove(arr: Array<Array<number>>, index: number[]): Array<Array<number>> {
  return arr.filter((value: number[], ind: number) => {
      return !index.includes(ind);
  });
}

private redrawPolygon(): void {
  const editablePoints: MapPointInterface[] = this.editableCoords.map((coords: number[]) => {
      const poly: MapPointInterface = {};
      poly.lat = coords[0];
      poly.lng = coords[1];
      return poly;
  });
  this.mapPolygons = [];
  const polygon: MapPolygonInterface = {
      points: editablePoints,
      strokeColor: this.isFarm ? '#a31f1f' : '#ffff00',
      fillColor: this.isFarm ? '#de3333' : '#ffff00',
      strokeWeight: 5,
      isEditable: true,
  };
  this.mapPolygons.push(polygon);
  this.polyPathChanged.emit();
  this.onLastPolyPathChange.emit(polygon);
  this.lastPoligonTouchedSelectedArea = MapHelper.getPolygonArea(polygon.points, 'ha');
  this.polyLength = this.getLengthOfPolygon(polygon);
}

public getLengthOfPolygon(poligon: MapPolygonInterface): number {
  return poligon ? MapHelper.getPolygonLength(poligon.points) / 1000 : 0;
}

public polyRightClick(event: PolyMouseEvent, poligon: MapPolygonInterface, polyIndex: number): void {
  this.onPolyRightClick.emit({
      polygon: poligon,
      index: polyIndex
  });
}

public polyDragEnd(event: MouseEvent): void {
  this.polyDrangEnd.emit(event);
}

public lineClick(event: PolyMouseEvent): void {
  this.lineClicked.emit(event);
}

public polyPathChange(event: PathEvent): void {
  console.log(event);
}

public lineDragEnd(event) {
  console.log(event)
}

hideModal() {
  // this.mapMarkers = [];
  // this.polygons = [];
  // this.polylines = [];
  // this.mapPolygons = [];
  // this.mapPolylines = [];
  // this.coords = '';
  console.log('close popup');
  this.closePopup.emit();
}

onMapMenuInit() {
  this.contextMenuEnabled = true;
}

public drawNewPolygon(): void {
  const newPolygon: MapPolygonInterface = {
      points: [this.rightClickLastPosition, this.rightClickLastPosition],
      fillColor: this.mapPoligonFillColor,
      strokeColor: this.mapPoligonStrokeColor
  };
  this.mapPolygons.push(newPolygon);
}

public drawNewPolyline(startPointCoordinates: MapPointInterface = this.rightClickLastPosition): void {
  const line = {lat : startPointCoordinates.lat, lng: startPointCoordinates.lng}
  this.polylinePath.push(line);
  this.polyLineChange(line);
  this.loadPath(this.polylinePath);
}

public loadPath(markers) {
  this.mapPolylines = [];
  const points = [];
  markers.forEach(element => {
    const point = {lat: element.lat, lng: element.lng}
    points.push(point);
  });
  this.mapPolylines.push(  
    {
      points: points
    }
  );
  console.log(this.mapPolylines);
}

public polyLineChange(event) {
  console.log(event);
  const polygon: Array<MapPointInterface> = [];
  this.markerCoordinates = this.editableCoords;
  // this.editableCoords = [];
  // this.lastPoligonTouchedSelectedArea = MapHelper.getPolygonArea(event, 'ha');
  this.polyLength = MapHelper.getPolygonLength([event.lat, event.lng]) / 1000;
  polygon.push({
    lat: event.lat,
    lng: event.lng
  });
  this.editableCoords.push([event.lat, event.lng]);
  this.setMapMarkers();
  this.checkboxArray();
  this.dynamicCheckboxCreate();
  console.log(this.editableCoords);
}

public setMarkerIconColor(markerColor: string): string {
  return (
      'http://maps.google.com/mapfiles/ms/icons/' +
      markerColor +
      '-dot.png'
  );
}

drawPolygon() {
  this.mapPolygons = [];
  this.contextMenuEnabled = false;
  this.drawNewPolygon()
}

drawPolyline() {
  this.mapPolylines = [];
  this.contextMenuEnabled = false;
  this.drawNewPolyline()
}

public polyPathsChange(event: PolygonPathEvent<LatLng | LatLng[]>, index: number): void {
  console.log(event);
  const polygon: Array<MapPointInterface> = [];
  this.markerCoordinates = this.editableCoords;
  this.editableCoords = [];
  this.lastPoligonTouchedSelectedArea = MapHelper.getPolygonArea(event.newArr[0], 'ha');
  this.polyLength = MapHelper.getPolygonLength(event.newArr[0]) / 1000;
  event.newArr[0].map((point: any): any => {
      polygon.push({
          lat: point.lat,
          lng: point.lng
      });
      this.editableCoords.push([point.lat, point.lng]);
  });
  this.mapPolygons[index] = {
      points: polygon,
      strokeColor: this.isFarm ? '#a31f1f' : '#ffff00',
      fillColor: this.isFarm ? '#de3333' : '#ffff00',
  };
  this.editableCoords = this.multiArrayUnique(this.editableCoords);
  this.polygonSubmit();
  this.onLastPolyPathChange.emit(this.mapPolygons[index]);
  this.setMapMarkers();
  this.checkboxArray();
  this.dynamicCheckboxCreate();
  console.log(this.editableCoords);
}

public polyHover($event: PolyMouseEvent, polygon: MapPolygonInterface): void {
  polygon.strokeWeight = 10;
}

public polyMouseOut($event: PolyMouseEvent, polygon: MapPolygonInterface): void {
  polygon.strokeWeight = 5;
}

public polyClickEdit(event: PolyMouseEvent, poligon: MapPolygonInterface, polyIndex: number): void {
  console.log(event, polyIndex);
  poligon.isEditable = true;
  poligon.strokeWeight = 10;
  this.markerCoordinates = this.editableCoords;
  this.setMapMarkers();
  this.checkboxArray();
  this.dynamicCheckboxCreate();
  this.redrawPolygon();
}

public centerMapOnMarkers(): void {
  if (!this.mapMarkers.length) {
      return;
  }
  this.bounds = new google.maps.LatLngBounds();
  if (this.mapMarkers.length) {
      // let latSum = 0;
      // let lngSum = 0;
      this.mapMarkers.forEach((point: MapPointInterface): void => {
          // latSum += point.lat;
          // lngSum += point.lng;
          this.bounds.extend(
              new google.maps.LatLng(point.lat, point.lng)
          );
      });
  }

  this.mapFocusPoint.lat += this.mapMarkers[0].lat;
  this.mapFocusPoint.lng += this.mapMarkers[0].lng;
  // this.zoom += this.mapMarkers[0].zoom;
  // this.zoom = 10;
}

submitValue() {
  this.hideModal()
}

public centerMapOnPolygons(): void {
  // this.onMapInit.subscribe((map: google.maps.Map): void => {
      // if (!map) {
      //     return;
      // }
      if (!this.mapPolygons.length) {
          return;
      }
      this.bounds = new google.maps.LatLngBounds();
      this.mapPolygons.forEach((polygon: MapPolygonInterface): void => {
          polygon.points.forEach((point: MapPointInterface): void => {
              this.bounds.extend(new google.maps.LatLng(point.lat, point.lng));
          });
      });
      this.zoom = 18;
  // });
}

calculateCenter(points) {
  // console.log('center on polyline');
  // const totalLat = points.reduce((acc, point) => acc + point.lat, 0);
  // const totalLng = points.reduce((acc, point) => acc + point.lng, 0);
  // const numPoints = points.length;
  // this.mapFocusPoint.lat = totalLat / numPoints;
  // this.mapFocusPoint.lng = totalLng / numPoints;
  this.zoom = 18;
  const bounds = new google.maps.LatLngBounds();

    for (const point of points) {
      bounds.extend(new google.maps.LatLng(point.lat, point.lng));
    }

    this.ngZone.run(() => {
      this.mapFocusPoint.lat = bounds.getCenter().lat();
      this.mapFocusPoint.lng = bounds.getCenter().lng();
      this.bounds = bounds;
    });
  }

public centerMapOnPolygon(polygon: MapPolygonInterface): void {
  this.centerMapOnPolygonIndex(this.mapPolygons.findIndex((poly: MapPolygonInterface): boolean => poly === polygon));
}

public centerMapOnPolyline(polyline: MapPolylineSegmentInterface): void {
  this.centerMapOnPolygonIndex(this.mapPolygons.findIndex((poly: MapPolylineSegmentInterface): boolean => poly === polyline));
}

public centerMapOnPolygonIndex(polygonIndex: number): void {
  // this.onMapInit.subscribe((map: google.maps.Map): void => {
      // if (!map) {
      //     return;
      // }
      this.bounds = new google.maps.LatLngBounds();
      if (polygonIndex >= 0 && polygonIndex < this.mapPolygons.length) {
          // let latSum = 0;
          // let lngSum = 0;
          this.mapPolygons[polygonIndex].points.forEach((point: MapPointInterface): void => {
              // latSum += point.lat;
              // lngSum += point.lng;
              this.bounds.extend(new google.maps.LatLng(point.lat, point.lng));
          });
      }
  // });
}

calculateDistance(coordinates) {
  if (coordinates.length < 2) {
    this.polyLength = 0;
    return;
  }

  this.polyLength = this.calculateTotalDistance(coordinates);
}

private calculateTotalDistance(coordinates: { lat: number; lng: number }[]): number {
  let totalDistance = 0;

  for (let i = 1; i < coordinates.length; i++) {
    const prevCoord = coordinates[i - 1];
    const currCoord = coordinates[i];
    totalDistance += this.calculateDistanceBetweenPoints(
      prevCoord.lat,
      prevCoord.lng,
      currCoord.lat,
      currCoord.lng
    );
  }

  return totalDistance;
}

private calculateDistanceBetweenPoints(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in kilometers
  const lat1Rad = this.degreesToRadians(lat1);
  const lon1Rad = this.degreesToRadians(lon1);
  const lat2Rad = this.degreesToRadians(lat2);
  const lon2Rad = this.degreesToRadians(lon2);

  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
}

private degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

ngOnDestroy() {
  this.mapMarkers = [];
  this.coords = [];
  this.polygons = [];
  this.polylines = [];
  this.mapPolygons = [];
  this.mapPolylines = [];
}

}
