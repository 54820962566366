import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { SensorsEnum } from '../../../../core/enums/sensors.enum';
import { WidgetsEnum } from '../../../../core/enums/widgets.enum';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { WidgetInterface } from '../../../../core/interfaces/widget/widget.interface';
import { ActivityModel } from '../../../../core/models/activity/activity.model';
import { DiseaseModel } from '../../../../core/models/diseases/disease.model';
import { LotModel } from '../../../../core/models/lot/lot.model';
import { SensorModel } from '../../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../../core/models/widget/widget.model';
import { ActivityService } from '../../../../core/services/api/activity/activity.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { CropService } from '../../../../core/services/api/crop/crop.service';
import { ObjectModel, ObjectService } from '../../../../core/services/api/objects/object.service';
import { UnitTypeModel } from '../../../../core/services/api/unit-type/unit-type.service';
import { WidgetsService } from '../../../../core/services/api/widgets/widgets.service';
import { FapModalComponent } from '../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NavService } from '../../../../shared/services/nav.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { SensorService } from '../../../../core/services/api/sensor/sensor.service';

export enum SensorCodes {
    COORDS_SENSOR = 39,
    PERCENTAGE_SENSOR = 62,
}

@Component({
    selector: 'fap-add-edit-widget',
    templateUrl: './fap-add-edit-widget.component.html',
    styleUrls: ['./fap-add-edit-widget.component.scss'],
})
export class FapAddEditWidgetComponent implements OnInit, OnDestroy, OnChanges {
    public widgetForm: UntypedFormGroup;
    public sensorForm: UntypedFormGroup;
    public searchText;
    public selectedWidgetType: string;
    public color = '#400000';
    public graphType = 'line';
    public graphItem
    public css = null;
    public sensorCss = null;
    public widgetsEnum = Object.keys(WidgetsEnum).map((key) => ({
        id: WidgetsEnum[key],
        name: key,
    }));
    public sensorsEnum = Object.keys(SensorsEnum).map((key) => ({
        id: SensorsEnum[key],
        name: key,
    }));

    public itemList = [];

    public filteredSensors: SensorModel[] = [];
    public filteredCoordSensors: SensorModel[] = [];
    public filteredPercentageSensors: SensorModel[] = [];
    public coordSensors: SensorModel[] = [];
    public percentageSensors: SensorModel[] = [];

    public subscriptions: Array<Subscription> = [];

    public widgetConfig: {} = {};
    public background;
    public disease;
    public device;
    public activity: ActivityModel;

    @Input() lots: LotModel[] = [];

    @Input() sensors: SensorModel[] = [];

    @Input() resetForm = false;
    @Input() view: number;
    @Input() widgetsLength = 1;
    @Input() widget: WidgetModel;
    @Input() objects: ObjectModel;
    @Input() unitTypes: UnitTypeModel;
    sizes = [
      { label: 'widget.phoneSize', controlName: 'sizeSmall', changeEvent: this.changesizeSmalls, prefix: 's', options: [11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28] },
      { label: 'widget.tabSize', controlName: 'sizeMedium', changeEvent: this.changesizeMediums, prefix: 'm', options: [11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48] },
      { label: 'widget.pcSize', controlName: 'sizeLarge', changeEvent: this.changesizeLarges, prefix: 'l', options: [11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 51, 52, 53, 54, 55, 56, 57, 58, 61, 62, 63, 64, 65, 66, 67, 68, 71, 72, 73, 74, 75, 76, 77, 78, 81, 82, 83, 84, 85, 86, 87, 88, 91, 92, 93, 94, 95, 96, 97, 98, 101, 102, 103, 104, 105, 106, 107, 108, 111, 112, 113, 114, 115, 116, 117, 118, 121, 122, 123, 124, 125, 126, 127, 128] }
  ];

    @Output()
    public addWidget: EventEmitter<any> = new EventEmitter();

    @Output()
    public updateWidget: EventEmitter<{ id: number; widget: WidgetInterface }> =
        new EventEmitter();

    @Output()
    public editForm: EventEmitter<any> = new EventEmitter();

    @Output()
    public deleteWidget: EventEmitter<number> = new EventEmitter();

    @ViewChild(FormGroupDirective) widgetFormDirective: FormGroupDirective;

    public images;
    public imageForm: UntypedFormGroup;
    public configForm: UntypedFormGroup;
    public graphConfigForm: UntypedFormGroup;
    public image: File;
    uploadFileAcceptFormats: string[] = ['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'];
    public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
    public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
    public configModalButtonPrimaryInterface: FapModalButtonInterface;
    public configModalButtonSecondaryInterface: FapModalButtonInterface;
    public graphConfigModalButtonPrimaryInterface: FapModalButtonInterface;
    public graphConfigModalButtonSecondaryInterface: FapModalButtonInterface;
    imageSrc: string;

    public cols = [2, 3, 4, 5, 6];
    public rows = [2, 3, 4, 5, 6];
    public selectedCol;
    public sizeSmall;
    public sizeMedium;
    public sizeLarge;
    public selectedRow;
    public bgColor;
    public order;
    public sensorGroup = [];
    public coordSensorGroup = [];
    public percentageSensorGroup = [];
    public finalSensorList = [];
    public diseases: Array<DiseaseModel> = [];
    public filteredDiseases: Array<any> = [];
    public translatedNames:any = [];
    public langString: string;
    public activities: Array<ActivityModel> = [];
    public devices = [];
    public getMore = true;
    public nextToken: { offset: number; limit: number; type: string; search: string} = null;
    public nextActivitiesToken: { offset: number; limit: number; search: string} = null;
    public nextDevicesToken: { offset: number; limit: number; search: string} = null;
    public limit = 20;
    public newDisease;
    public imgWidth: number;
    public imgHeight: number;
    public orientation: string;
    public mediaUrl = environment.mediaUrl;
    public nextImgToken :{ offset: number; limit: number;} = null;
    public getMoreImg = true;
    public changedImage: boolean;
    public widgetLots = [];
    public mapType = 'position';
    public form = {};
    @ViewChild('addImageModal')
    public addImageModal: FapModalComponent;
    @ViewChild('configModal')
    public configModal: FapModalComponent;
    @ViewChild('graphConfigModal')
    public graphConfigModal: FapModalComponent;
    public imgPath = '';
    public cssConfig
    @ViewChild('fileUploader') fileUploader:ElementRef;
    public widgetImages:any = [];
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    public colors = [
        'white',
        'pink',
        'blue',
        'orange',
        'green',
        'brown',
        'yellow',
    ];
    public translation = null;
    public selectedItems = [];
    public config;
    public mapTypes = ['position', 'tracking'];
    public graphConfig;
    @ViewChild('matSelect') matSelect;
    public graphColors = [
        {
          "name": "Red",
          "color": "#FF6B6B"
        },
        {
          "name": "Blue",
          "color": "#3A86FF"
        },
        {
          "name": "Green",
          "color": "#4CAF50"
        },
        {
          "name": "Orange",
          "color": "#E76F51"
        },
        {
          "name": "Gray",
          "color": "#AAB9B8"
        },
        {
          "name": "Dark Blue",
          "color": "#2B2E4A"
        },
        {
          "name": "Navy",
          "color": "#2C3E50"
        },
        {
          "name": "Burgundy",
          "color": "#D11149"
        },
        {
          "name": "Black",
          "color": "#1D1D1D"
        },
        {
          "name": "Pink",
          "color": "#FF2E63"
        }
      ];
      public tempSettings;

    constructor(
        private fb: UntypedFormBuilder,
        public widgetServices: WidgetsService,
        public navService: NavService,
        public cropService: CropService,
        public globalRegistry: GlobalRegistryService,
        public activityService: ActivityService,
        public objectService: ObjectService,
        public cacheService: CacheResolverService,
        public companyService: CompanyService,
        public translateService: TranslateService,
        public toastrService: ToastrService,
        public sensorService: SensorService
    ) {
        this.initForm();
    }

    ngOnInit(): void {
        // setTimeout(() => {
            // console.log(this.sensors);
            // console.log(this.objects);
            // console.log(this.unitTypes);
            this.langString = localStorage.getItem('language');
            this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
                if(lang) {
                    this.langString = lang;
                }
            }));
            this.translatedNames = this.globalRegistry.systemData.translations;

            this.filterDeviceSensors(this.sensors);
            const coordParams = {unit: SensorCodes.COORDS_SENSOR}
            this.subscriptions.push(
            this.sensorService.getSensors(coordParams).subscribe(data => {
                this.coordSensors = data.body.results;
            })
            )
            const percentageParams = {unit: SensorCodes.PERCENTAGE_SENSOR}
            this.subscriptions.push(
            this.sensorService.getSensors(percentageParams).subscribe(data => {
                this.percentageSensors = data.body.results;
                this.filterPercentageSensors(this.percentageSensors);
            })
            )
        // }, 5000);

        const url = this.activityService.getUrl('activities/')
        this.cacheService.delete(url+'limit='+this.limit);   
        this.getDevices();
        this.subscriptions.push(
            this.navService.resetModalForm.subscribe((value: boolean) => {
                if (value) {
                    this.resetForms();
                }
            }),
            this.activityService.getActivities().subscribe(data => {
                this.nextActivitiesToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextActivitiesToken) this.getMore = true
                this.activities = data.body.results;
            })
        );
        this.initImageModalButtons();
        this.initConfigModalButtons();
        this.initGraphConfigModalButtons();
        this.initImageForm();
        this.initConfigForm();
        this.initGraphConfigForm();
    }

    getSizeLabel(t: number): string {
      const row = Math.floor(t / 10);
      const col = t % 10;
      return `${row} x ${col}`;
  }

    initImageForm() {
        this.imageForm = new UntypedFormGroup({
          file: new UntypedFormControl('', Validators.required),
          group: new UntypedFormControl('equipments', Validators.required),
          tags: new UntypedFormControl([], Validators.required),
        });
      }

      initConfigForm() {
        console.log(this.config);
        this.configForm = new UntypedFormGroup({
          font_size: new UntypedFormControl(this.config ? this.config.font_size.replace('px', '') : '16'),
          color: new UntypedFormControl(this.config ? this.config.color : '#000000'),
          top: new UntypedFormControl(this.config ? this.config.top.replace('%', '') : '50'),
          left: new UntypedFormControl(this.config ? this.config.left.replace('%', '') : '50'),
          background: new UntypedFormControl(this.widget ? this.widget['settings'] ? this.widget.settings['background'] ? this.widget['settings']['background'] : null : null : null),
        });
      }

      initGraphConfigForm() {
        console.log(this.graphConfig);
        this.graphConfigForm = new UntypedFormGroup({
          graphType: new UntypedFormControl(this.graphConfig ? this.graphConfig.graphType : 'line'),
          color: new UntypedFormControl(this.graphConfig ? this.graphConfig.color : '400000'),
        });
      }

    public getDevices() {
        // const url = this.objectService.getUrl('devices/')
        // this.cacheService.delete(url+'limit='+this.limit);   
        this.subscriptions.push(this.objectService.getDevices().subscribe(data => {
          this.devices = data.model;
          console.log(this.devices);
          this.nextDevicesToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
        }))
      }

      convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
            const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
                p1.toUpperCase()
            );
            camelCaseObj[camelCaseKey] =
                obj[key] instanceof Object
                    ? this.convertKeysToCamelCase(obj[key])
                    : obj[key];
        }
        return camelCaseObj;
    }

    addEditTranslation() {
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.widget);
    if(name === null || name === '' || type.nameT === null) {
        this.translation = null
        this.addEditTranslationsModal.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
            }
        });
    }
    }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.widgetForm.controls['name_t'].setValue(translation.id);
        this.widgetForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    filterProduct(search) {
        let type = '';
        if(this.selectedWidgetType == 'fungal_alert') {
            type = 'fungal'
        } else if(this.selectedWidgetType == 'pathogen_alert') {
            type = 'viral'
        } else {
            type = 'insect'
        } 
        this.subscriptions.push(this.cropService.getDiseases({type, search}).subscribe(data => {
        this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
        this.filteredDiseases = data.body.results;
        }))
      }

      filterActivity(search) {
        this.subscriptions.push(this.activityService.getActivities({search}).subscribe(data => {
        this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
        this.activities = data.body.results;
        }))
      }

      filterDevices(search) {
        this.subscriptions.push(this.objectService.getDevices({search}).subscribe(data => {
        this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
        this.activities = data.body.results;
        }))
      }

    ngOnChanges(changes: SimpleChanges) {
        if (Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
            console.log(this.widget);
            this.initForm();
            this.itemList = this.widget.config['settings'] ? this.widget.config['settings']['options'] : null;
            this.sizeSmall = this.widget.config['sizeSmall'];
            this.sizeMedium = this.widget.config['sizeMedium'];
            this.sizeLarge = this.widget.config['sizeLarge'];
            this.bgColor = this.widget.config['bgColor'];
            this.background = this.widget.config['settings'] ? this.widget.config['settings']['background'] : null;
            this.widgetImages = this.widget.config['settings'] ? this.widget.config['settings']['background'] : null;
            this.disease = this.widget.config['settings'] ? this.widget.config['settings']['disease'] : null;
            this.activity = this.widget.config['settings'] ? this.widget.config['settings']['activity'] : null;
            this.device = this.widget.config['settings'] ? this.widget.config['settings']['device'] : null;
            this.mapType = this.widget.config['settings'] ? this.widget.config['settings']['mapType'] : null;
            this.form = this.widget.config['settings'] ? this.widget.config['settings']['form'] : null;
            this.order = this.widget.config['settings'] ? this.widget.config['settings']['order'] : 1;
            if(this.widget.config['disease']) {

                this.cropService.getDisease(this.widget.config['disease']).subscribe(data => {
                    if(this.filteredDiseases) {
                        this.filteredDiseases.unshift(data.body.results);
                        this.newDisease = data.body.results;
                    }
                })
            }
            console.log(this.widget.config);
        }
        if(this.background) {
            this.imgPath = this.background['file']
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    changeType(type) {
        console.log(type);
        this.itemList = [];
        this.mapType = type;
    }

    editCss(item) {
        if (this.selectedWidgetType === 'image') {
            if (item) {
                console.log(item);
                this.sensorForm.get('sensorId').reset();
                this.sensorForm.get('sensorId').setValue(item.sensor.id);
                this.css = item.css;
                this.showConfigModal();
            }
        } else if(this.selectedWidgetType === 'sensor_value') {
            if (item) {
                console.log(item)
                this.sensorForm.get('sensorId').reset();
                this.sensorForm.get('sensorId').setValue(item.sensor.id);
                this.sensorCss = item.css;
                this.showConfigModal();
            }
        }else {
            return;
        }
    }

    editGraphStyle(item) {
        console.log(item);
        this.graphItem = item;
        this.color = item.color;
        this.graphType = item.graphType;
        const obj = { graphType: this.graphType, color: this.color };
        this.graphConfig = obj;
        this.initGraphConfigForm();
        this.graphConfigModal.showModal();
    }

    public initForm(): void {
        this.selectedWidgetType = this.widget
            ? this.widget.config['widgetType']
            : 'graph';
            console.log(this.widget);
        this.widgetForm = this.fb.group({
            name: [
                this.widget ? this.widget.config['name'] : null,
                Validators.required,
            ],
            name_t: [
                this.widget ? this.widget.config['nameT'] : null,
                Validators.required,
            ],
            lot: [
                this.widget ? this.widget.objectId : null
            ],
            sizeSmall: this.widget ? this.widget.config['sizeSmall'] : null,
            sizeMedium: this.widget ? this.widget.config['sizeMedium'] : null,
            sizeLarge: this.widget ? this.widget.config['sizeLarge'] : null,
            bgColor: this.widget ? this.widget.config['bgColor'] : null,
            widgetType: [
                this.widget ? this.widget.config['widgetType'] : null,
                Validators.required,
            ],
            background: this.widget ? this.widget['settings'] ? this.widget.settings['background'] ? this.widget['settings']['background'] : null : null : null,
            disease: this.widget ? this.widget['settings'] ? this.widget.settings['disease'] ? this.widget['settings']['disease'] : null : null : null,
            activity: this.widget ? this.widget['settings'] ? this.widget.settings['activity'] ? this.widget['settings']['activity'] : null : null : null,
            device: this.widget ? this.widget['settings'] ? this.widget.settings['device'] ? this.widget['settings']['device'] : null : null : null,
            mapType: this.widget ? this.widget['settings'] ? this.widget.settings['mapType'] ? this.widget['settings']['mapType'] : 'position' : null : null,
            form: this.widget ? this.widget['settings'] ? this.widget.settings['form'] ? this.widget['settings']['form'] : null : null : null,
            graphType: this.widget ? this.widget['settings'] ? this.widget.settings['graphType'] ? this.widget['settings']['graphType'] : 'position' : null : null,
            order: this.widget ? this.widget['settings'] ? this.widget.order : 1000 : 1000,
        });
        // this.widgetForm.controls.disease.setValue(this.disease);
        this.widgetForm.controls.widgetType.setValue(this.selectedWidgetType);
        console.log(this.widgetForm.value);
        this.changedWidgetType(this.selectedWidgetType);
        if(this.widget) {
            this.selectedItems = this.widget.config[' '];
            if(this.selectedWidgetType === 'weather_forecast') {
                this.widgetLots = this.widget.settings['lots'];
                this.selectedItems = this.widget.settings['lots'];
            }
            if(this.widgetForm.value && this.widgetForm.value.lot) {
                this.filterSensors(this.widgetForm.value.lot);
                this.filterCoordSensors(this.widgetForm.value.lot);
                this.filterPercentageSensors(this.widgetForm.value.lot);
            }
        }
    }

    public changeCols(cols) {
        // console.log(cols);
        this.selectedCol = cols;
    }

    public changeDisease(col) {
        this.disease = col;
    }

    public changeDevice(col) {
        this.device = col;
    }

    public changeActivity(col) {
        this.activity = col;
    }

    public changesizeSmalls(col) {
        this.sizeSmall = col;
    }

    public changesizeMediums(col) {
        this.sizeMedium = col;
    }

    public changesizeLarges(col) {
        this.sizeLarge = col;
    }

    public changeBgColor(color) {
        this.bgColor = color;
    }

    public changeOrder(order) {
        this.order = order;
    }

    public changeCss(event) {
        this.css = event.target.value;
    }

    public changeSensorCss(event) {
        this.sensorCss = event.target.value;
    }

    public addbackground(event) {
        this.background = event.target.value;
    }

    updateItems(lots) {
        this.widgetLots = lots;
    }

    public createSoilLevelGroup = (data = null): UntypedFormGroup =>
        this.fb.group({
            temperature: this.fb.control(
                data ? data.temperature : null,
                Validators.required
            ),
            humidity: this.fb.control(
                data ? data.humidity : null,
                Validators.required
            ),
            ph: this.fb.control(data ? data.ph : null, Validators.required),
            ec: this.fb.control(data ? data.ec : null, Validators.required),
            N: this.fb.control(data ? data.N : null, Validators.required),
            P: this.fb.control(data ? data.P : null, Validators.required),
            K: this.fb.control(data ? data.K : null, Validators.required),
        });

    public addItem(): void {
        console.log(this.sensorForm.value);
        // if (this.sensorForm.invalid || !this.color || !this.css || !this.sensorCss) return;
        const { sensorId } = this.sensorForm.value;
        this.sensorForm.get('sensorId').reset();
        const selectedSensor = this.sensors.find(
            (sensor: SensorModel) => sensor.id === +sensorId
        );
        const item = {
            sensor: selectedSensor,
            graphType: 'line',
        };

        if (this.selectedWidgetType === 'graph') {
            item['color'] = this.color;
        }
        if (this.selectedWidgetType === 'image') {
            item['css'] = this.css;  
        }
        if (this.selectedWidgetType === 'sensor_value') {
            item['css'] = this.sensorCss;  
        }
        this.itemList = this.itemList.filter(nitem => nitem.sensor.id != item.sensor.id);
        this.itemList.push(item);
        console.log(this.itemList);
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
          return t[0][this.langString];
        } else {
            return translation
        }
      }

    public removeItem(index: number): void {
        this.itemList = [
            ...this.itemList.slice(0, index),
            ...this.itemList.slice(index + 1, this.itemList.length),
        ];
    }

    public openConfig() {
        if(this.widget) {
            console.log(this.widget.settings['options']['css']);
        } else {
            console.log('new');
        }
    }

    public changedWidgetType(valueType: string): void {
        // this.widgetForm.get('lot').reset();
        // this.disease = null;
        console.log(valueType)
        this.selectedWidgetType = valueType;
        this.itemList = [];
        this.background = null;
        this.imgPath = null;
        if (valueType === 'soil') {
            this.widgetForm.addControl(
                'soil',
                this.fb.group(
                    {
                        level30: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.config['settings']['options']['level30']
                                : null
                        ),
                        level15: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.config['settings']['options']['level15']
                                : null
                        ),
                        level10: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.config['settings']['options']['level10']
                                : null
                        ),
                        level5: this.createSoilLevelGroup(
                            this.widget
                                ? this.widget.config['settings']['options']['level5']
                                : null
                        ),
                    },
                    [Validators.required]
                )
            );
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'cropeye') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'table') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'graph') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
                graphType: ['line', Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l37';
            }
        } else if (valueType === 'graph_range') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
                graphType: ['line', Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l37';
            }
        } else if (valueType === 'graph_percentage') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.widgetForm.addControl('graphType', new UntypedFormControl('radialBar'));
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
                graphType: ['radialBar', Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l37';
            }
        } else if (valueType === 'image') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'sensor_value') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'map') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l46');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm36';
                this.sizeLarge = 'l46';
            }
        } else if (valueType === 'images_list') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'bins') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l26');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l26';
            }
        } else if (valueType === 'activities') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m36');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = '326';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'device_info') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'weather_forecast') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
                this.sensorForm = this.fb.group({
                sensorId: [null],
            });
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s26');
                this.widgetForm.get('sizeMedium').setValue('m26');
                this.widgetForm.get('sizeLarge').setValue('l36');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's26';
                this.sizeMedium = 'm26';
                this.sizeLarge = 'l36';
            }
        } else if (valueType === 'overview') {
          this.widgetForm.controls.soil &&
              this.widgetForm.removeControl('soil');
          this.sensorForm = this.fb.group({
              sensorId: [null, Validators.required],
          });
          if (
              this.widgetForm.get('bgColor').value == null ||
              this.widgetForm.get('bgColor').value == ''
          ) {
              this.widgetForm.get('bgColor').setValue('bg_white');
              this.widgetForm.get('sizeSmall').setValue('s26');
              this.widgetForm.get('sizeMedium').setValue('m26');
              this.widgetForm.get('sizeLarge').setValue('l46');
              this.widgetForm.get('order').setValue(1);
              this.order = 1000;
              this.bgColor = 'bg_white';
              this.sizeSmall = 's26';
              this.sizeMedium = 'm26';
              this.sizeLarge = 'l46';
          }
        }
        else if (valueType === 'fungal_alert') {
            if(this.filteredDiseases) {
            this.cropService.getDiseases({type: 'fungal'}).subscribe(data => {
                if(this.newDisease) {
                    this.filteredDiseases = [this.newDisease, ...data.body.results];
                  } else {
                    this.filteredDiseases = data.body.results;
                  }
                
                this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
            })}
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s25');
                this.widgetForm.get('sizeMedium').setValue('m35');
                this.widgetForm.get('sizeLarge').setValue('l35');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's25';
                this.sizeMedium = 'm35';
                this.sizeLarge = 'l35';
            }
          }
          else if (valueType === 'pathogen_alert') {
            if(this.filteredDiseases) {
                this.cropService.getDiseases({type: 'viral'}).subscribe(data => {
                    if(this.newDisease) {
                        this.filteredDiseases = [this.newDisease, ...data.body.results];
                      } else {
                        this.filteredDiseases = data.body.results;
                      }
                    
                    this.nextToken = data.body.next
                    ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                    : null;
                    if(this.nextToken) this.getMore = true;
                })}
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s25');
                this.widgetForm.get('sizeMedium').setValue('m35');
                this.widgetForm.get('sizeLarge').setValue('l35');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's25';
                this.sizeMedium = 'm35';
                this.sizeLarge = 'l35';
            }
          }
          else if (valueType === 'pest_alert') {
            if(this.filteredDiseases) {
                this.cropService.getDiseases({type: 'insect'}).subscribe(data => {
                    if(this.newDisease) {
                        this.filteredDiseases = [this.newDisease, ...data.body.results];
                      } else {
                        this.filteredDiseases = data.body.results;
                      }
                    
                    this.nextToken = data.body.next
                    ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                    : null;
                    if(this.nextToken) this.getMore = true;
                })}
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s25');
                this.widgetForm.get('sizeMedium').setValue('m35');
                this.widgetForm.get('sizeLarge').setValue('l35');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's25';
                this.sizeMedium = 'm35';
                this.sizeLarge = 'l35';
            }
          }
          else if (valueType === 'herbicide') {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            if (
                this.widgetForm.get('bgColor').value == null ||
                this.widgetForm.get('bgColor').value == ''
            ) {
                this.widgetForm.get('bgColor').setValue('bg_white');
                this.widgetForm.get('sizeSmall').setValue('s24');
                this.widgetForm.get('sizeMedium').setValue('m34');
                this.widgetForm.get('sizeLarge').setValue('l34');
                this.widgetForm.get('order').setValue(1);
                this.order = 1000;
                this.bgColor = 'bg_white';
                this.sizeSmall = 's24';
                this.sizeMedium = 'm34';
                this.sizeLarge = 'l34';
            }
          }
        else {
            this.widgetForm.controls.soil &&
                this.widgetForm.removeControl('soil');
            this.sensorForm = this.fb.group({
                sensorId: [null, Validators.required],
                graphType: [null, Validators.required],
            });
        }
    }
    
    onScrollImages(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          console.log("End");
          this.scrolledDown();
        }
    }

    onScrollDevices(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          console.log("End");
          this.scrolledDevices();
        }
    }

      scrolledDown() {
        console.log('scrolled');
        let type = '';
        if(this.selectedWidgetType == 'fungal_alert') {
            type = 'fungal'
        } else if(this.selectedWidgetType == 'pathogen_alert') {
            type = 'viral'
        } else {
            type = 'insect'
        }
        if(this.getMore) {
            this.nextToken &&
              this.subscriptions.push(this.cropService.getDiseases(this.nextToken).subscribe((data) => {
                // console.log(data);
                if(this.filteredDiseases) {
                  this.filteredDiseases = [...this.filteredDiseases, ...data.body.results];
                } else {  
                  this.filteredDiseases = data.body.results;
                }
                if(data.body.next == null) {
                  this.getMore = false;
                  return
                } else {
                  const url = data.body.next.split('?')
                  const urlParams = new URLSearchParams(url[1]);
                  const entries = urlParams.entries();
                  const params = this.paramsToObject(entries);
                  if(this.nextToken.offset != params['offset']) {
                  this.nextToken = {offset: params['offset'], limit: params['limit'], type: type, search: params['search']};
                  } else {
                    return
                  }
                }
              }));
            } else {
              return
            }
      }

      scrolledActivities() {
        console.log('scrolled')
        if(this.getMore) {
            this.nextActivitiesToken &&
              this.subscriptions.push(this.cropService.getDiseases(this.nextActivitiesToken).subscribe((data) => {
                // console.log(data);
                if(this.activities) {
                  this.activities = [...this.activities, ...data.body.results];
                } else {
                  this.activities = data.body.results;
                }
                if(data.body.next == null) {
                  this.getMore = false;
                  return
                } else {
                  const url = data.body.next.split('?')
                  const urlParams = new URLSearchParams(url[1]);
                  const entries = urlParams.entries();
                  const params = this.paramsToObject(entries);
                  if(this.nextActivitiesToken.offset != params['offset']) {
                  this.nextActivitiesToken = {offset: params['offset'], limit: params['limit'], search: params['search']};
                  } else {
                    return
                  }
                }
              }));
            } else {
              return
            }
      }

      scrolledDevices() {
        console.log('scrolled')
        if(this.getMore) {
            this.nextDevicesToken &&
              this.subscriptions.push(this.objectService.getDevices(this.nextDevicesToken).subscribe((data) => {
                // console.log(data);
                if(this.devices) {
                  this.devices = [...this.devices, ...data.body.results];
                } else {
                  this.devices = data.body.results;
                }
                if(data.body.next == null) {
                  this.getMore = false;
                  return
                } else {
                  const url = data.body.next.split('?')
                  const urlParams = new URLSearchParams(url[1]);
                  const entries = urlParams.entries();
                  const params = this.paramsToObject(entries);
                  if(this.nextDevicesToken.offset != params['offset']) {
                  this.nextDevicesToken = {offset: params['offset'], limit: params['limit'], search: params['search']};
                  } else {
                    return
                  }
                }
              }));
            } else {
              return
            }
      }

      public filterDeviceSensors(sensors) {
        const parent = sensors;
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
        const devices = Object.values(this.devices);
      
        const result = parent.map((item) => {
          return {
            ...item,
            objects: objects.filter((el) => el.id === item.objectId),
          };
        });
      
        const result1 = result.map((item) => {
          return {
            ...item,
            unitTypes: unitTypes.filter((el) => el.id === item.unitType),
          };
        });
      
        const result2 = result1.map((item) => {
          const device = devices.find((el) => el.id === item.device); // Find the device object
          return {
            ...item,
            fullDevice: device ? device : null,
          };
        });
      
        const groupedSensors = devices
        .map(device => {
        const sensorsForDevice = result2.filter(sensor => sensor.device === device.id);
        return sensorsForDevice.length > 0 ? { device: device, data: sensorsForDevice } : null;
        })
        .filter(group => group !== null);
        this.sensorGroup = groupedSensors;
          console.log(this.sensorGroup);
      }

      public filterCoordSensorList(sensors) {
        const parent = sensors;
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
        const devices = Object.values(this.devices);
      
        const result = parent.map((item) => {
          return {
            ...item,
            objects: objects.filter((el) => el.id === item.object_id),
          };
        });
      
        const result1 = result.map((item) => {
          return {
            ...item,
            unitTypes: unitTypes.filter((el) => el.id === item.unit_type),
          };
        });
      
        const result2 = result1.map((item) => {
          const device = devices.find((el) => el.id === item.device); // Find the device object
          return {
            ...item,
            fullDevice: device ? device : null,
          };
        });
      
        const groupedSensors = devices
        .map(device => {
        const sensorsForDevice = result2.filter(sensor => sensor.device === device.id);
        return sensorsForDevice.length > 0 ? { device: device, data: sensorsForDevice } : null;
        })
        .filter(group => group !== null);
        this.coordSensorGroup = groupedSensors;
          console.log(this.coordSensorGroup);
      }

      public filterPercentageSensorList(sensors) {
        const parent = sensors;
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
        const devices = Object.values(this.devices);
      
        const result = parent.map((item) => {
          return {
            ...item,
            objects: objects.filter((el) => el.id === item.object_id),
          };
        });
      
        const result1 = result.map((item) => {
          return {
            ...item,
            unitTypes: unitTypes.filter((el) => el.id === item.unit_type),
          };
        });
      
        const result2 = result1.map((item) => {
          const device = devices.find((el) => el.id === item.device); // Find the device object
          return {
            ...item,
            fullDevice: device ? device : null,
          };
        });
      
        const groupedSensors = devices
        .map(device => {
        const sensorsForDevice = result2.filter(sensor => sensor.device === device.id);
        return sensorsForDevice.length > 0 ? { device: device, data: sensorsForDevice } : null;
        })
        .filter(group => group !== null);
        this.percentageSensorGroup = groupedSensors;
          console.log(this.percentageSensorGroup);
      }

      areAllChildrenSelected(group: any): boolean {
        return group.data.every(
          (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
        );
      }
      
      onOptionSelected(event: MatSelectChange, limit?: number) {
        console.log('Limit is '+ limit);
        const selectedSensorId = event.value;
        if (this.itemList.some(item => item.sensor.id === selectedSensorId)) {
            this.itemList = this.itemList.filter(item => item.sensor.id !== selectedSensorId);
        } else {
            this.addItem();
            this.sensorGroup.forEach((group: any) => {
                const allChildrenSelected = group.data.every(
                  (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
                );
                group.isAllChildrenSelected = allChildrenSelected;
              });
        }
        console.log(this.itemList);
        this.sensorForm.get('sensorId').reset();
      }

      onCoordOptionSelected(event: MatSelectChange, limit?: number) {
        console.log('Limit is '+ limit);
        const selectedSensorId = event.value;
        if (this.itemList.some(item => item.sensor.id === selectedSensorId)) {
            this.itemList = this.itemList.filter(item => item.sensor.id !== selectedSensorId);
        } else {
            this.addItem();
            this.coordSensorGroup.forEach((group: any) => {
                const allChildrenSelected = group.data.every(
                  (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
                );
                group.isAllChildrenSelected = allChildrenSelected;
              });
        }
        console.log(this.itemList);
        this.sensorForm.get('sensorId').reset();
      }

    public filterSensors(lotId) {
        if (lotId) {
            const filteredDevices = this.devices.filter(device =>
                device.lots.some(lot => lot === lotId)
            );
            const filteredSensors = this.sensors.filter(sensor =>
                filteredDevices.some(device => device.id === sensor.device)
            );
            this.filteredSensors = filteredSensors;
            this.filterDeviceSensors(filteredSensors);
        }
    }

    public filterCoordSensors(lotId) {
        if (lotId) {
            const filteredDevices = this.devices.filter(device =>
                device.lots.some(lot => lot === lotId)
            );
            const filteredCoordSensors = this.coordSensors.filter(sensor =>
                filteredDevices.some(device => device.id === sensor.device)
            );
            this.filteredCoordSensors = filteredCoordSensors;
            this.filterCoordSensorList(filteredCoordSensors);
        }
    }

    public filterPercentageSensors(lotId) {
        if (lotId) {
            const filteredDevices = this.devices.filter(device =>
                device.lots.some(lot => lot === lotId)
            );
            const filteredPercentageSensors = this.percentageSensors.filter(sensor =>
                filteredDevices.some(device => device.id === sensor.device)
            );
            this.filteredPercentageSensors = filteredPercentageSensors;
            this.filterPercentageSensorList(filteredPercentageSensors);
        }
    }
    
    selectAllChildren(event: MatCheckboxChange, group: any) {
          const allChildrenSelected = group.data.every(
            (sensor: any) => this.itemList.some((item: any) => item.sensor.id === sensor.id)
          );
          if (allChildrenSelected) {
            group.data.forEach((sensor: any) => {
              this.itemList = this.itemList.filter((item: any) => item.sensor.id !== sensor.id);
            });
          } else {
            group.data.forEach((sensor: any) => {
              const selectedSensor = this.sensors.find((s: any) => s.id === sensor.id);
              const item = { sensor: selectedSensor };
              this.itemList.push(item);
            });
          }
          event.source.checked = !allChildrenSelected;
      }

    public submitWidget() {
        const { lot, name, widgetType, soil, name_t, graphType } = this.widgetForm.value;
        const sizeClass =
            this.sizeSmall + ' ' + this.sizeMedium + ' ' + this.sizeLarge;
        const bgColor = this.bgColor;
        const order = this.order;
        const background = this.widgetImages;
        const disease = this.disease;
        const activity = this.activity;
        const device = this.device;
        const lots = this.widgetLots;
        const mapType = this.mapType;
        const form = this.form;
        
        console.log(this.widgetForm.value);
        // if (this.widgetForm.invalid) return;
        if (
            (widgetType === 'graph' || widgetType === 'table' || widgetType === 'graph_percentage' || widgetType === 'graph_range') &&
            this.itemList.length === 0
        )
            return;

        const widget = {
            config: {
                options: widgetType === 'soil' ? soil : this.itemList,
                type: widgetType,
                sizeSmall: this.sizeSmall,
                sizeMedium: this.sizeMedium,
                sizeLarge: this.sizeLarge,
                sizeClass,
                bgColor,
            },
            settings: {
                options: widgetType === 'soil' ? soil : this.itemList, 
                background,
                disease, 
                device,
                activity,
                lots,
                mapType,
                form,
                graphType
            },
            sizeSmall: this.sizeSmall,
            sizeMedium: this.sizeMedium,
            sizeLarge: this.sizeLarge,
            bgColor,
            name,
            name_t,
            contentType: 'lot',
            objectId: lot ? lot : 0,
            widgetType,
            view: this.view,
            order
        };
        // this.resetForms();
        Object.assign(widget.config, this.widgetConfig);
        this.widget
            ? this.updateWidget.emit({ id: this.widget.id, widget })
            : this.addWidget.emit(widget);

        this.css = null;
        this.sensorCss = null;
        this.config = null;
    }

    public resetForms(): void {
        this.widgetForm.removeControl('soil');
        this.widgetFormDirective.resetForm();
        this.itemList = [];
        this.sensorForm && this.sensorForm.reset();
    }

    public addActivity() {
        console.log('activity add clicked');
    }

    public imageSubmit() {
        const data = {
          width: this.imgWidth,
          height: this.imgHeight,
          orientation: this.orientation
      }
          const formData = new FormData();
          formData.append("file", this.image);
          formData.append("group", this.imageForm.get('group').value);
          formData.append("tags", this.imageForm.get('tags').value);
          formData.append("data", JSON.stringify(data));
          console.log(formData);
          // return;
          this.companyService.postCompanyImages(formData).subscribe(data => {
              console.log(data);
              this.imageForm.reset();
              this.imageForm.clearValidators();
              this.initImageForm();
              this.widgetImages = [];
              this.imgPath = ''
              this.getImages();
          })
        this.addImageModal.hideModal();
      }

      public submitConfig() {
        console.log(this.configForm.value);
        console.log(this.imgPath);
        const css = {
            top: this.configForm.value.top+'%',
            left: this.configForm.value.left+'%',
            color: this.configForm.value.color,
            transform: `translate(-${this.configForm.value.left}%, -${this.configForm.value.top}%)`,
            font_size: this.configForm.value.font_size+'px'
        }
        console.log(css);
        this.css = css;
        this.sensorCss = css;
        if(this.widget) {
            this.widget.settings['options'][0]['css'] = css;
        } else {
            this.tempSettings = css;
        }
        this.configModal.hideModal();
      }

      public submitGraphConfig() {
        console.log(this.graphConfigForm.value);
        this.graphItem['color'] = this.graphConfigForm.value.color;
        this.graphItem['graphType'] = this.graphConfigForm.value.graphType;
        console.log(this.graphItem);
        console.log(this.itemList)
        this.graphConfigModal.hideModal();
      }
      
      public showImageModal(): void {
        this.addImageModal.showModal();
      }

      public showConfigModal(): void {
        this.configModal.showModal();
        if(this.widget) {
            this.config = this.widget.settings['options'][0]['css'];
            console.log(this.config);
        }
        this.initConfigForm();
      }
      
      onFileInput(event) {
        if(event.target.files && event.target.files.length) {
          if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
            this.toastrService.error(this.translateService.instant('image.notAnImage'))
            this.image = null;
            this.fileUploader.nativeElement.value = null;
            return
          }
          const [image] = event.target.files;
          this.image = image;
          const _URL = window.URL || window.webkitURL;
          let file, img, height, width;
          if ((file = event.target.files[0])) {
              img = new Image();
              const objectUrl = _URL.createObjectURL(file);
              img.onload = function () {
                  height = this.height;
                  width = this.width;
                  _URL.revokeObjectURL(objectUrl);
              };
              img.src = objectUrl;
          }
          setTimeout(()=> {
            if(width <= height) {
              this.orientation  = "portrait";
              console.log("portrait");
            } else {
                this.orientation  = "landscape";
                console.log("landscape");
            }
          }, 500);
        }
      }
      
      public imageUploaded(file: File): void {
        this.changedImage = true;
        this.image = file;
        console.log(this.image);
        this.imageForm.markAsDirty();
      }
      
      public initImageModalButtons(): void {
        const _this: FapAddEditWidgetComponent = this;
      
        this.addImageModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.imageSubmit();
            },
            text: this.translateService.instant('save')
        };
      
        this.addImageModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addImageModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
      }

      public initGraphConfigModalButtons(): void {
        const _this: FapAddEditWidgetComponent = this;
      
        this.graphConfigModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.submitGraphConfig();
            },
            text: this.translateService.instant('save')
        };
      
        this.graphConfigModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.graphConfigModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
      }

      public initConfigModalButtons(): void {
        const _this: FapAddEditWidgetComponent = this;
      
        this.configModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.submitConfig();
            },
            text: this.translateService.instant('save')
        };
      
        this.configModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.configModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
      }

      addImage(ev) {
        console.log(ev)
        const imgObj = {
            id: ev.value.id,
            file: ev.value.file
        }
        this.widgetImages = imgObj;
        this.imgPath = imgObj.file;
        this.widgetForm.get('background').setValue(this.widgetImages);
      }
      
      public getImages() {
        const url = this.companyService.getUrl('images/')
        this.cacheService.delete(url);
        this.companyService.getCompanyImages().subscribe((response): void => {
            this.images = response.body["results"];
            console.log(this.images);
            this.nextImgToken = response.body.next
            ? this.globalRegistry.getQueryStringParams(response.body.next.split("?")[1])
            : null;
            if(this.nextImgToken) this.getMoreImg = true
          })
        }
      
        onScroll(event: any) {
          if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            console.log("End");
            this.scrolledDownImages();
          }
        }
      
        public deleteImage(imageId: number): void {
          console.log(imageId);
          this.subscriptions.push(this.companyService.deleteCompanyImage(imageId).subscribe(data => {
              console.log(data);
              this.images = [];
              this.getImages();
          }))
        }
      
        scrolledDownImages() {
          const url = this.companyService.getUrl('images/');
          console.log('scrolled');
          if(this.getMoreImg) {
          this.nextImgToken &&
            this.subscriptions.push(this.companyService.getCompanyImages(this.nextImgToken).subscribe((data) => {
              this.cacheService.delete(url+'limit='+this.nextImgToken.limit+'&offset='+this.nextImgToken.offset);
              console.log(data);
              if(this.images) {
                this.images = [...this.images, ...data.body.results];
              } else {
                this.images = data.body.results;
              }
              if(data.body.next == null) {
                this.getMoreImg = false;
                return
              } else {
                const url = data.body.next.split('?')
                const urlParams = new URLSearchParams(url[1]);
                const entries = urlParams.entries();
                const params = this.paramsToObject(entries);
                console.log(params);
                if(this.nextImgToken.offset != params['offset']) {
                this.nextImgToken = {limit: params['limit'], offset: params['offset']};
                } else {
                  return
                }
              }
            }));
          } else {
            return
          }
        }
      
        paramsToObject(entries) {
          const result = {}
          for(const [key, value] of entries) {
            result[key] = value;
          }
          console.log(result);
          return result;
        }

        editWigetValue() {
            this.editForm.emit(this.widget.id);
        }

        changeItemColor(color) {
            console.log(color);
            console.log(this.graphItem);
            this.color = color.value;
        }
    
        getNumberArray(dynamicNumber: number): number[] {
            return Array.from({ length: dynamicNumber }, (_, index) => index + 1);
        }
}
