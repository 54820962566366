<!-- <ng-container *ngFor="let pool of pools">
    <pool-card [pool]="pool">
    </pool-card>
</ng-container> -->
<div id="parent" class="d-flex mbl_wrap">
    <ng-container *ngIf="pools.length">
        <div class="mr-2 mb-2 pool-card"*ngFor="let pool of pools; let index = index;">
            <fap-pool-widget
                *ngIf="pool.widgetType === 'pool'"
                (emitShow)="showError()"
                (emitDelete)="deletePool($event)"
                [widget]="pool"
                [objects]="objects"
                [sensors]="sensors"
                [unitTypes]="unitTypes"
                [devices]="devices"
                [editValue]="false"
                [index]="index"
                style="display: block; width: 100%;"
            ></fap-pool-widget>
        </div>
    </ng-container>
    
    <div class="custom_modal" id="pools_modal">
        <div class="modal_header">
            <h2>{{entityInfo?.name_t ? getTranslation(entityInfo?.name_t) : entityInfo?.name}}</h2>
            <button type="button" class="btn close-modal" (click)="hidePoolsModal(); redirectBack()"><i class="fa fa-times"></i></button>
        </div>
        <div class="modal_body" *ngIf="entityInfo?.id">
            <fap-attributes
                [entityRelation]="'pool'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="0"
                (closeModal)="hidePoolsModal(); redirectBack()"
                (formUpdate)="onUpdateAction()"
                [isDynamic]="true">
            </fap-attributes>
        </div>
    </div>
</div>