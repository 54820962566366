import { FapBaseModel } from '../../base/fap-base-model';
import { IngredientModel } from './ingredient.model';

export class ProductModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'code',
        'type',
        'producer',
        'produced',
        'prospect',
        'images',
        'pack_unit',
        'unit',
        'quantity',
        'quantity_pack',
        'cost',
        'alert_group',
        'alert_quantity',
        'ingredients'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'name',
        'code',
        'type',
    ];

    public static extendedFields: {} = {
        
    };

    public id: number;
    public name: string;
    public uniqueId: string;
    public code: string;
    public type: number;
    public producer: string;
    public produced: any;
    public prospect: string;
    public images: Array<any>;
    public packUnit: any;
    public quantityPack: number;
    public unit: string;
    public quantity: number;
    public cost: number;
    public alertGroup: number;
    public alertQuantity: number;
    public ingredients: Array<IngredientModel>
}
