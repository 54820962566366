import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NavService } from '../../services/nav.service';
import { TypesService } from '../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { FapModalComponent } from '../../partials';

@Component({
  selector: 'fap-activities-list-widget',
  templateUrl: './activities-list-widget.component.html',
  styleUrls: ['./activities-list-widget.component.scss']
})
export class FapActivitiesListComponent implements OnInit, OnChanges {
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() activities: any[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() activityTypes: UnitTypeModel[] = [];
  public subscriptions: Array<Subscription> = [];
  public mediaUrl = environment.mediaUrl;
  public translatedNames: any = [];
  public langString: string;
  public toggleMain = false;
  public toggleSub = false;
  public entityInfo;
  @Output()
    public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public emitScroll: EventEmitter<any> = new EventEmitter<any>();
  activityFormPopup: FapModalComponent;

  public selectedWidget;

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService, public typesService: TypesService, public cacheService: CacheResolverService) { 
  }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'activities') && this.activities) {
      console.log(this.activities);
    }
  }

  hideMain() {
    this.toggleMain = false;
}

toggleMenu() {
    this.toggleMain = !this.toggleMain;
}

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter((trans) => {
        return trans.id === translation;
    });
    if (t[0]) {
        if (t[0][this.langString] != '') {
            return t[0][this.langString];
        } else {
            return;
        }
    } else {
        return translation;
    }
}

onScroll(event: any) {
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    this.emitScroll.emit(true);
    console.log('scrolled')
  }
}

public getFormTypes() {
  const url = this.typesService.getUrl('form_type/');
  this.cacheService.delete(url + 'uniqueid=CREATE_POOL_ACTIVITY');
  const params = { uniqueid: 'CREATE_POOL_ACTIVITY' };
  this.typesService.getFormTypes(params).subscribe((data) => {
      console.log(data);
      this.entityInfo = data.body.results[0];
  });
}

getActivityForm(id) {
  console.log(id)
  this.getFormTypes();
}

hidePoolsModal() {
  console.log('Hide pools modal');
}

onUpdateAction() {
  console.log('update action called');
}

}
