<div class="widget-card">
    <div class="widget-card-title">
        <div>
          {{ chartData.chartTitle | translate }}
        </div>
        <div *ngIf="chartData['objectName']" class="widget-content-type-text">
          {{ chartData['contentType'] | translate }} - {{ chartData['objectName'] }}
        </div>
      </div>
    <div class="card-body">
        <div class="flot-chart-container">
            <div class="flot-chart-placeholder ngx-chart-direction" id="github-issues">
                <!-- <ngx-charts-line-chart
                    [scheme]="chartData.chartColorScheme"
                    [results]="chartData.chartMultiData"
                    [gradient]="chartData.chartGradient"
                    [xAxis]="chartData.chartShowXAxis"
                    [yAxis]="chartData.chartShowYAxis"
                    [showXAxisLabel]="chartData.chartShowXAxisLabel"
                    [showYAxisLabel]="chartData.chartShowYAxisLabel"
                    xAxisLabel="{{chartData.chartXAxisLabel | translate }}"
                    yAxisLabel="{{chartData.chartYAxisLabel | translate}}"
                    [curve]="curveStep"
                    (select)="onSelect($event)"
                    (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)">
                </ngx-charts-line-chart> -->
            </div>
        </div>
    </div>
</div>
