<div class="extra_pages">
    <div
        class="overview_container"
        [ngClass]="{ 'desktop-view': width >= 768, 'mobile-view': width < 768 }"
    >
    <div class="forms">
        <ng-container *ngIf="currentStep !== 0">
            <fap-attributes
                [entityRelation]="'workflow'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="objectId"
                (closeModal)="hideModal()"
                (formUpdate)="onUpdateAction()"
                [type]="'edit'"
                [formDisplayType]="'page'"
                [previousFormClick]="previousFormClick"
                [nextFormClick]="nextFormClick"
                [form]="workflow?.current_form"
                (nextButtonClick)="nextButtonClick($event)"
                [isDynamic]="true"
            >
            </fap-attributes>
        </ng-container>
        <div class="edit_profile" *ngIf="currentStep === 0">
            <p>{{'alergotel.selectQuestionnaire' | translate}} <button class="btn" (click)="goToNextForm()">{{'alergotel.checkAlergicProfile' | translate}}</button></p>
        </div>
    </div>
        <div class="nav_btns" *ngIf="currentStep !== 0">
            <button
                (click)="previousFormClick = true; goToPreviousForm()"
                class="cbtn prev"
            ></button>
            <button (click)="nextFormClick = true" class="cbtn next"></button>
        </div>
    </div>
</div>
