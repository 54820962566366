<div class="w-100 disease-table">
    <table>
        <thead>
            <tr>
                <th>{{ headerTitle }}</th>
                <th class="add-pathogen-header">
                    <i class="fa fa-plus center f-20" (click)="addDisease()"></i>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let disease of diseases">
                <td>
                    {{ disease.name }}
                </td>
                <td>
                    <i class="fa fa-trash center f-20" (click)="removeDisease(disease.value)"></i>
                </td>
            </tr>
            <span *ngIf="!diseases || diseases.length === 0">
                {{ 'empty' | translate }}                
            </span>
        </tbody>
    </table>
</div>

<fap-modal #addDiseaseModal
    inputTitle="{{ 'add' | translate }} {{ headerTitle }}">
    <fap-select-disease #selectDisease
        [context]="context"
        (selectDisease)="addedDisease($event)">
    </fap-select-disease>
</fap-modal>
