<div
    *ngIf="widget"
    class="dashboard-card dash_cont"
    style="position: relative"
    id="parent"
>
    <div class="row">
        <div class="col-md-4">
            <h1>
                {{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}
            </h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row" style="height: calc(100% - 15px)">
    </div>
</div>