import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { WidgetInterface } from "../../../../../core/interfaces/widget/widget.interface";
import { LotModel } from "../../../../../core/models/lot/lot.model";
import { NoteModel } from "../../../../../core/models/notes/note.model";
import { SensorModel } from "../../../../../core/models/sensor/sensor.model";
import { WidgetModel } from "../../../../../core/models/widget/widget.model";
import { DeviceModel, ObjectModel } from "../../../../../core/services/api/objects/object.service";
import { UnitTypeModel } from "../../../../../core/services/api/unit-type/unit-type.service";
import { FapModalComponent } from "../../../../../shared/partials";
import { NavService } from "../../../../../shared/services/nav.service";
import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
import { WidgetsService } from "../../../../../core/services/api/widgets/widgets.service";
import { CropService } from "../../../../../core/services/api/crop/crop.service";
import { ActivityService } from "../../../../../core/services/api/activity/activity.service";
import { ProductModel } from "../../../../../core/models/stock/product.model";
import { CompanyService } from "../../../../../core/services/api/company/company.service";
import { CropModel } from "../../../../../core/models/crops/crop.model";
import { ActivityTypeModel } from "../../../../../core/models/activity/activity-type.model";
import { CacheResolverService } from "../../../../../core/services/api/cache/cache-resolver.service";
import { GlobalRegistryService } from "../../../../../core/global-registry/global-registry.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../../../core/services/api/user/user.service";
import { ToastrService } from "ngx-toastr";
export interface RecordData { 
  id: number, 
  title: string, 
  category: string, 
  opened?: boolean, 
}

interface RecordStore {
  cached?: RecordData[];
  refined?: RecordData[];
  stamp?: Date;
}

@Component({
  selector: "dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class DashboardLayoutComponent implements OnInit, OnDestroy, OnChanges {
  public resetForm: boolean;
  private subscriptions: Array<Subscription> = [];
  public selectedWidget: WidgetModel;

  @Input() lots: LotModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() widgets: WidgetModel[] = [];
  @Input() notes: NoteModel[] = [];
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() activityTypes: ActivityTypeModel[] = [];
  @Input() products: ProductModel[] = [];
  @Input() devices: DeviceModel[] = [];
  public selection: string;
  public widgetCategories: any[] = [];
  public reset: string;
  selectedButton;
  selectedButtonId;
  public filteredWidgets: WidgetModel[] = [];
  public store: RecordStore = {};
  public crops: Array<CropModel> = [];
  public activities: any[] = [];
  public limit = 20;
  public getMore = true;
  public nextToken: { limit: number; offset: number; search: string } = null;
  public options: NgxMasonryOptions = {
    gutter: 0,
  };
  public screenSize = 'pc';

  @Output()
  public addWidget: EventEmitter<WidgetInterface> = new EventEmitter();

  @Output()
  public updateWidget: EventEmitter<{ id: number; widget: WidgetInterface }> =
    new EventEmitter();

  @Output()
  public deleteWidget: EventEmitter<WidgetInterface> = new EventEmitter();

  @ViewChild("addEditWidgetModal") addEditWidgetModal: FapModalComponent;

  @ViewChild("addEditWidgetCategoryModal") addEditWidgetCategoryModal: FapModalComponent;

  @ViewChild("addEditTranslationsModal") addEditTranslationsModal: FapModalComponent;

  @ViewChild(NgxMasonryComponent, { static: false }) 
  public masonry: NgxMasonryComponent;

  @ViewChild('container', { static: true }) container: ElementRef<HTMLElement>;
  public triggerEditForm: any;

  public translatedNames:any = [];
  public langString: string;
  public viewForm: UntypedFormGroup;
  public view;
  public translation;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  public sizes = [1,2,3,4,5,6,7,8,9,10,11,12];
  public currentUserId = 0;
  public createView = true;

  constructor(private navServices: NavService, private widgetsService: WidgetsService, public cropService: CropService, public activityService: ActivityService, public companyService: CompanyService, public cacheService: CacheResolverService, public globalRegistry: GlobalRegistryService, public fb: UntypedFormBuilder, public userService: UserService, public toastr: ToastrService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.navServices.openWidget.subscribe((value: boolean) => {
        this.navServices.resetModalForm.next(true);
        this.selectedWidget = null;
        value && this.addEditWidgetModal.showModal();
      }),
    );
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navServices.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
    this.subscriptions.push(
      this.cropService.getCrops().subscribe((data) => {
          this.crops = data.model;
      }))
      this.subscriptions.push(
        this.activityService.getActivities().subscribe(data => {
          this.activities = data.body.results;
        })
      )
    this.getWidgetCategories();
    if(window.innerWidth <= 767) {
      this.screenSize = 'mobile';
    } else {
      this.screenSize = 'pc';
    }
    console.log(this.screenSize);
    // this.setup();
    
    this.userService.getCurrentUser.subscribe(user => {
      this.currentUserId = user.profile.id;
      this.initViewForm();
    })
  }

  initViewForm() {
    this.viewForm = this.fb.group({
      name: [
          this.selectedButton ? this.selectedButton['name'] : null,
          Validators.required,
      ],
      name_t: [
          this.selectedButton ? this.selectedButton['name_t'] : null,
          Validators.required,
      ],
      order: [
          this.selectedButton ? this.selectedButton['order'] : 1000,
          Validators.required,
      ],
      map_resize: [
        this.selectedButton ? this.selectedButton['map_resize'] : true,
        Validators.required,
      ],
      size: [
        this.selectedButton ? this.selectedButton['size'] : 12,
        Validators.required,
      ],
      drill: [
        this.selectedButton ? this.selectedButton['drill'] : true
      ],
      locked: [
        this.selectedButton ? this.selectedButton['locked'] : true
      ],
      create_widget: [
        this.selectedButton ? this.selectedButton['create_widget'] : true
      ],
      owner: [
        this.selectedButton ? this.selectedButton['owner'] : this.currentUserId,
        Validators.required,
      ],
      objs: [
        this.selectedButton ? this.selectedButton['owner'] : {}
      ],
    })
    if(this.selectedButton && this.selectedButton['name_t']) {
      const translation = this.getTranslation(this.selectedButton['name_t']);
      this.viewForm.get('name').setValue(translation);
    }
  }

  addEditTranslation() {
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.selectedButton);
    if(name === null || name === '' || type.nameT === null) {
        this.translation = null
        this.addEditTranslationsModal.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
            }
        });
    }
    }

    convertKeysToCamelCase(obj) {
      const camelCaseObj = {};
      for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
      }
      return camelCaseObj;
    }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.viewForm.controls['name_t'].setValue(translation.id);
        this.viewForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    submitView() {
      console.log(this.viewForm.value);
      if(this.createView) {
        this.widgetsService.addWidgetCategory(this.viewForm.value).subscribe(() => {
          this.selectedButton = null;
          this.addEditWidgetCategoryModal.hideModal()
          this.getWidgetCategories()
        }, (err) => {
          this.toastr.error(err.error.results.error);
        })
      } else {
        this.widgetsService.updateWidgetCategory(this.selectedButton.id, this.viewForm.value).subscribe(() => {
          this.selectedButton = null;
          this.addEditWidgetCategoryModal.hideModal()
          this.getWidgetCategories()
        }, (err) => {
          this.toastr.error(err.error.results.error);
        })
      }
    }

  setup() {
    const numCols = 3;
    const colHeights = Array(numCols).fill(0);
    const container = this.container.nativeElement;

    Array.from(container.children).forEach((child: any, i: number) => {
      const order = i % numCols;
      child.style.order = order;
      colHeights[order] += parseFloat(child.clientHeight);
    });
    container.style.height = Math.max(...colHeights) + "px";
  }

  itemsLoaded() {
    console.log('itemsloaded');
  }

  private getWidgetCategories() {
    const url = this.widgetsService.getUrl('views/');
    this.cacheService.delete(url);
    this.subscriptions.push(this.widgetsService
        .getWidgetCategories()
        .subscribe(data => {
         this.widgetCategories = data.results;
          this.toggleSelect(this.widgetCategories[0]);
        }));
  }

  public loadWidgets() {
    if(this.selectedButton) {
      this.filteredWidgets = this.widgets.filter(item => item.view == this.selectedButton.id);
    }
  }

  ngOnChanges() {
    this.loadWidgets();
  }

  showModal(widget: WidgetModel): void {
    this.selectedWidget = widget;
    this.addEditWidgetModal.showModal();
  }

  showAddCategoryModal() {
    this.createView = true;
    this.viewForm.get('name').reset();
    this.viewForm.get('name_t').reset();
    this.viewForm.get('order').reset();
    this.viewForm.clearValidators();
    this.addEditWidgetCategoryModal.showModal();
  }

  toggleSelect(button) {
    if(button == this.selectedButton) {
      console.log(this.selectedButton);
      this.createView = false;
      this.initViewForm();
      this.addEditWidgetCategoryModal.showModal();
      return
    }
    this.selectedButton = button
    this.selectedButtonId = button.id
    const widgets = this.widgets.filter(item => item['view'] == button.id);
    this.filteredWidgets = widgets;
    this.widgetsService.setDrill(button.drill);
    this.widgetsService.setCreateWidget(button.create_widget);
    this.widgetsService.setMapResize(button.map_resize);
    this.widgetsService.setSize(button.size);
    if(button.objs) {
      this.widgetsService.setDCoords(button.objs);
    }
    this.loadWidgets();
    // this.masonry.reloadItems();
    // this.masonry.layout();
}

  getConfig(item) {
    console.log(item);
  }

  deleteView(id) {
    this.widgetsService.deleteWidgetCategory(id).subscribe(() => {
      this.getWidgetCategories();
    }, (err) => {
      this.toastr.error(err.error.results.error);
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  reloadMasonryLayout() {
    if (this.masonry !== undefined) {
      this.masonry.reloadItems();
      this.masonry.layout();
      this.masonry.options.gutter = 5;
      this.masonry.ordered = false;
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
        return translation
      }
  }

  scrollActivities() {
    const url = this.activityService.getUrl('activities/');
    console.log('activities scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.activityService.getActivities(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.activities) {
          this.activities = [...this.activities, ...data.body.results];
        } else {
          this.activities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  editForm(value) {
    this.triggerEditForm = 0
    console.log(value);
    setTimeout(() => {
      this.triggerEditForm = value;
    }, 100)
  }

  setEmitTriggerEditForm(v) {
    this.triggerEditForm = 0;
    console.log(v);
  }

  deletePool(widget) {
    console.log(widget)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
