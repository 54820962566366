<div class="card container-fluid p-0 mb-5">
    <div class="header">
        <p class="paths-header">
            {{ 'equipment.paths' | translate }}
        </p>
    </div>
    <div class="paths-grid" *ngIf="paths">
        <fap-equipment-path-row [paths]="paths"></fap-equipment-path-row>
    </div>
    <!-- <div *ngIf="!(equipment?.paths?.length > 0)">
        {{ 'equipment.noTrackingAvailable' | translate }}
    </div> -->
</div>
