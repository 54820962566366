import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';


import { CropsComponent } from './crops.component';
import { FapCropsLayoutComponent } from './components/fap_crops-layout/fap_crops-layout.component';
import { CropsContainerComponent } from './containers/crops-container/crops-container.component';
import { SharedModule } from '../../../shared/shared.module';
import { FapLotCardComponent } from './components/fap_lot-card/fap_lot-card.component';
import { FapLotFarmHeaderComponent } from './components/fap_lot-farm-header/fap_lot-farm-header.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PartialsModule } from '../../views-partials/partials.module';


const COMPONENTS: any[] = [
    CropsComponent,
    FapCropsLayoutComponent,
    FapLotCardComponent,
    FapLotFarmHeaderComponent

];

const CONTAINERS: any[] = [
    CropsContainerComponent,

];


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatCardModule,
        NgxPermissionsModule,
        PartialsModule
    ],
    declarations: [
        ...CONTAINERS,
        ...COMPONENTS
    ]
})

export class CropsModule {

}
