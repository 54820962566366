import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OverviewService } from '../../../core/services/api/overview/overview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AgmMap } from '@agm/core';

@Component({
    selector: 'fap-overview',
    templateUrl: './fap-overview.component.html',
    styleUrls: ['./fap-overview.component.scss'],
   
})
export class FapOverviewComponent implements OnInit {
    public token: string = environment.overviewToken;
    public overviewData = null;
    public imageObjects = [];
    public apiUrl = environment.baseBackendUrl;
    public activeSlide = null;
    public counter = 0;
    public index:number;
    public size = '';
    public innerWidth: any;
    public lang:string;
    public location = '';
    defaultCenter = {lat: 51.673858, lng: 7.815982};
    currentCenter = Object.assign({}, this.defaultCenter);
    zoom = 15;
    @ViewChild(AgmMap) agmMap: AgmMap;
    public mediaUrl = environment.mediaUrl

    constructor(public overviewService: OverviewService, public route:ActivatedRoute, public router:Router) {
    }

    public get width() {
        return window.innerWidth;
    }

    public get language() {
      return navigator.language.substr(0, 2);
  }

    ngOnInit() {
      this.route.queryParams.subscribe(params => {
        this.lang = params['lang'];
        this.location = params['location'];
        console.log(this.location);
      })
        this.overviewService.overviewContent({}).subscribe((data) => {
            this.overviewData = data.body;
            this.imageObjects = this.overviewData.pages;
        },
        (error) => {
          console.log(error);
          this.router.navigate(['login']);
        }
        )
    }

    showNextImage() {
      const total = this.imageObjects.length - 1;
      this.counter = this.inc(total);
    }

    showPreviousImage() {
      const total = this.imageObjects.length - 1;
      this.counter = this.dec(total);
    }

    inc(total) {
      return this.counter < total ? this.counter + 1 : 0;
    }
  
    dec(total) {
      return this.counter > 0 ? this.counter - 1 : total;
    }

    onChange(idx) {
        console.log(idx);
        this.index = idx;
    }

    mapReady(event) {
      console.log(event);
      this.setCurrentPosition();
    }

    private setCurrentPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.currentCenter.lat = position.coords.latitude;
          this.currentCenter.lng = position.coords.longitude;
          this.zoom = 15;
        });
      }
    }

    calculateCenter(markers: any[]): { lat: number; lng: number } {
      let totalLat = 0;
      let totalLng = 0;
  
      for (const m of markers) {
        totalLat += m.coords[0];
        totalLng += m.coords[1];
      }
  
      const averageLat = totalLat / markers.length;
      const averageLng = totalLng / markers.length;
  
      return { lat: averageLat, lng: averageLng };
    }
  
    async checkIcon(iconUrl: string): Promise<boolean> {
      try {
        const response = await fetch(iconUrl);
        return response.status === 200; // 200 means the image exists
      } catch (error) {
        return false; // Error occurred, image not available
      }
    }

    setDuration() {
      setInterval(() => {
        this.showNextImage();
      },this.overviewData.displaytime*1000);
    }
}
