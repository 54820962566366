import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from 'src/app/core/global-registry/global-registry.service';
import { LotModel } from 'src/app/core/models/lot/lot.model';
import { NotesService } from 'src/app/core/services/api/farm/notes.service';
import { ObjectService } from 'src/app/core/services/api/objects/object.service';
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';
import { NavService } from 'src/app/shared/services/nav.service';
import { FapModalComponent } from '../../../../../shared/partials';

@Component({
  selector: 'device-item',
  templateUrl: './device-item.component.html',
  styleUrls: ['./device-item.component.scss']
})
export class DeviceItemComponent implements OnInit, OnDestroy {

  public device;
  public deviceEditForm: UntypedFormGroup;
  public deviceId: string;
  public subscriptions: Array<Subscription> = [];
  public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  public coords;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  public lots: Array<LotModel> = [];
  public coordsString: string;
  @ViewChild('addEditTranslationsModal') public addEditTranslationsModal: FapModalComponent;
  public langString: string;
  public translation: any = null;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

  constructor(public formBuilder: UntypedFormBuilder, public route: ActivatedRoute, public deviceService: ObjectService, public navService: NavService, public router: Router, public mapService: MapService, public notesService: NotesService, public globalRegistry: GlobalRegistryService, public toastrService: ToastrService) {
    this.initDeviceEditForm();
    this.lots = this.globalRegistry.systemData.lots
   }

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.params.deviceId;
    if(this.deviceId) {
      this.deviceService.getDevice(this.deviceId).subscribe(data => {
        this.device = data.body.results;
        this.initDeviceEditForm();
      })
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3000,
      allowSearchFilter: true
    };
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    this.navService.editFarm.next(true);
    this.mapService.resetMap();
    this.mapService.showMap();
    this.subscriptions.push(
      this.navService.emitCancel.subscribe((value: boolean) => {
        if (value) {
          this.router.navigate(["/pages/devices"]);
          this.navService.emitCancel.next(false);
        }
      })
    );
    this.notesService.notesCoords.subscribe(val => {
      if(val) {
          this.coords = val;
          this.coordsString = val.lng+','+val.lat;
          console.log(this.coords);
      }
  });
  this.subscriptions.push(this.navService.emitSubmit.subscribe((value: boolean) => {
      value && this.updateDevice();
  }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
    this.navService.editFarm.next(false);
    this.mapService.resetMap();
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.deviceEditForm.controls['name_t'].setValue(translation.id);
    this.deviceEditForm.controls['name'].setValue(translation[this.langString]);
    this.addEditTranslationsModal.hideModal();
}

  public initDeviceEditForm() {
    const formBody = {
      id : [this.device ? this.device.id : '', Validators.required],
      name: [this.device ? this.device.name : '', Validators.required],
      name_t: [this.device ? this.device.name_t : '', Validators.required],
      lots: [this.device ? this.device.lots: [], Validators.required],
      location: [null, Validators.required]
    };
    this.deviceEditForm = this.formBuilder.group(formBody);
    if(this.device) {
      console.log(this.device);
      this.selectedItems = this.device.lots;
      if(this.device.coords && this.device.coords.last_value) { 
        const markerInterface = {
          lat: this.device.coords.last_value.value_coord.coordinates[1],
          lng: this.device.coords.last_value.value_coord.coordinates[0],
          iconUrl: this.mapIconUrl
        }
        this.mapService.mapMarkers.push(markerInterface);
        this.mapService.centerMapOnMarkers();
      }
    }
  }

  updateItems(lots) {
    this.deviceEditForm.get('lots').setValue(lots);
}

  changeLotFormat(lotIds) {
    const lots:LotModel[] = [];
    lotIds.forEach(element => {
        if(typeof(element) === 'number') {
        const lot = this.lots.filter(l => {
            if(l.id === element) {
                return l;
            }
        });
        if(lot.length) {
          lots.push(lot[0]);
        }
    }});
    const uniqueLots = lots.filter(function(item, pos) {
        return lots.indexOf(item) == pos;
    })
    if(this.device) {
        this.device.lots = uniqueLots;
    }
    this.selectedItems = uniqueLots;
    console.log(uniqueLots);
    return uniqueLots;
  }

  get f() {
    return this.deviceEditForm.controls;
  }

  public updateDevice() {
    if(this.coords) {
      const coordString = this.coordsString.replace(',', ' ');
      const coord = `Point(${coordString})`;
      this.deviceEditForm.get('location').patchValue(coord);
    } else {
      this.toastrService.error('Please mark a coordinate on the map');
      return
    }
    console.log(this.deviceEditForm.value);
    this.deviceService.updateDevice(this.deviceId, this.deviceEditForm.value).subscribe(data => {
      console.log(data.body.results);
      if(data) {
        this.toastrService.success('Device updated successfully');
        this.router.navigate(['/pages/devices']);
      }
    })
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
        return t[0][this.langString];
      } else {
        return translation
      }
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  addEditTranslation() {
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.device);
    if(name === null || name === '' || type.nameT === null) {
        this.translation = null
        this.addEditTranslationsModal.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
            }
        });
    }
  }

}
