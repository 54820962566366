import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { NavService } from '../../../../shared/services/nav.service';

@Component({
  selector: 'add-edit-translations',
  templateUrl: './add-edit-translations.component.html',
  styleUrls: ['./add-edit-translations.component.scss']
})
export class AddEditTranslationsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public prefix: string;
  @Input()
  public group: string;
  @Input()
  public obj: {} = {};
  @Input()
  public translation: any;
  @Output()
  public submitTranslation: EventEmitter<any> = new EventEmitter();
  @Output()
  public updateTranslation: EventEmitter<any> = new EventEmitter();
  public isEdit = false;
  @Input() public field = 'nameT';

  public addEditTranslationForm: UntypedFormGroup;
  public subscriptions: Array<Subscription> = [];
  public langString:string = null;
  public translatedNames = [];
  public languages = [
    {id: 'en', label: 'English'},
    {id: 'fr', label: 'Français'},
    {id: 'ro', label: 'Română'}
  ];
  public showAll = false;

  constructor(public typeService: TypesService, public globalRegistry: GlobalRegistryService, public navService: NavService, public toastr: ToastrService, public translateService: TranslateService) { }

  ngOnInit(): void {
    this.initAddEditTranslationForm();
    this.langString = localStorage.getItem('language');
    this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
      if(lang) {
                this.langString = lang;
            }
        }));
    this.translatedNames = this.globalRegistry.systemData.translations;
    this.langString = localStorage.getItem('language');
    
  }

  initAddEditTranslationForm() {
    this.addEditTranslationForm = new UntypedFormGroup({
      en: new UntypedFormControl('', Validators.required),
      fr: new UntypedFormControl(''),
      ro: new UntypedFormControl(''),
      group: new UntypedFormControl(this.group ? this.group : '')
  });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'translation')) {
      this.editTranslation(this.translation);
    }
  }

  toggleShowAllLanguages() {
    this.showAll = !this.showAll
  }

  addTranslation() {
    if(this.addEditTranslationForm.invalid) {
      this.showAll = true;
      this.toastr.error(this.translateService.instant(
        'equipment.pleaseFillInMandatoryFields'
      ));
      return;
    }
    if(this.isEdit === false) {
      // this.addEditTranslationForm.get('id').setValue(this.prefix+this.addEditTranslationForm.value.en.toUpperCase().replace(/\s+/g, '_'));
        this.subscriptions.push(this.typeService.postTranslations(this.addEditTranslationForm.value).subscribe(data => {
        console.log(data);
        this.submitTranslation.emit(data.body.results);
        // this.addEditAttributeForm.controls['name_t'].setValue(this.nameT[this.langString]);
        this.globalRegistry.systemData.translations.push(data.body.results);
        }))
    } else {
        this.subscriptions.push(this.typeService.updateTranslations(this.translation.id, this.addEditTranslationForm.value).subscribe(data => {
        console.log(data);
        this.submitTranslation.emit(data.model);
        // this.addEditAttributeForm.controls['name_t'].setValue(this.nameT[this.langString]);
        const translationIndex = this.globalRegistry.systemData.translations.findIndex(
          fruit => fruit.id === data.model.id
        );
        
        if (translationIndex !== -1) {
          this.globalRegistry.systemData.translations[translationIndex] = {
            ...this.globalRegistry.systemData.translations[translationIndex],
            ...data.model
          };
        }
      }))
    }
  }

  addEditTranslation(input) {
    const type = this.convertKeysToCamelCase(this.obj)
      const name = this[input].nativeElement.value;
      console.log(type);
    if(!name || name === '' || type[this.field] === null) {
      this.isEdit = false;
        // this.showModal();
    } else {
      this.isEdit = true;
        this.translatedNames.forEach(translation => {
            if(translation.id === type[this.field] && (translation[this.langString] === '' || translation[this.langString] == null)) {
                translation[this.langString] = name
                this.editTranslation(translation);
            }
            if(translation[this.langString] === name || translation.id === type[this.field]) {
                console.log(translation.id);
                this.editTranslation(translation);
            }
        });
    }
  }

  onFocusOut(langId: string, event: Event) {
    const currentValue = (event.target as HTMLInputElement).value.trim();
    this.languages.forEach(language => {
      if (language.id !== langId && !this.addEditTranslationForm.controls[language.id].value) {
        this.addEditTranslationForm.controls[language.id].setValue(currentValue + ' ' + language.id);
      }
    });
  }
  

  editTranslation(translation) {
    if(translation) {
      this.isEdit = true;
      // this.addEditTranslationForm.controls['id'].setValue(translation.id);
      this.addEditTranslationForm.controls['en'].setValue(translation.en);
      this.addEditTranslationForm.controls['fr'].setValue(translation.fr);
      this.addEditTranslationForm.controls['ro'].setValue(translation.ro);
    } else {
      this.isEdit = false;
      this.initAddEditTranslationForm();
    }
}

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
        return t[0][this.langString];
      } else {
        return translation
      }
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  ngOnDestroy(): void {
    this.showAll = false
  }

}
