<div class="classical_forms row d-flex dark_bg m-0">
    <div class="form_img col-md-3 col-sm-12 p-0 pc-view" *ngIf="entityInfo?.image?.file">
        <img [src]="mediaUrl + entityInfo?.image?.file" alt="">
    </div>
    
    <div class="fixed_section form_img col-md-3 col-sm-12 p-0 mobile-view">
        <img [src]="mediaUrl + entityInfo?.image?.file" alt="" *ngIf="entityInfo?.image?.file">
        <h3 class="title mobile-view" *ngIf="typeOfForm === 'workflow' && entityInfo?.name_t?.id">{{ entityInfo?.name_t[langString] ? entityInfo?.name_t[langString] : entityInfo?.name }}</h3>
        <h3 class="title mobile-view" *ngIf="typeOfForm === 'workflow' && !entityInfo?.name_t">{{ entityInfo?.name }}</h3>
    </div>

    <div class="form_area col-sm-12" [ngClass]="entityInfo?.image?.file ? 'col-md-9' : ''">
        <h3 class="title pc-view" *ngIf="typeOfForm === 'workflow' && entityInfo?.name_t?.id">{{ entityInfo?.name_t[langString] ? entityInfo?.name_t[langString] : entityInfo?.name }}</h3>
        <h3 class="title pc-view" *ngIf="typeOfForm === 'workflow' && !entityInfo?.name_t">{{ entityInfo?.name }}</h3>
        <div class="row d-flex">
        <ng-container *ngFor="let field of entityInfo?.fields_css">
            <ng-container *ngFor="let attr of formType?.fields">
                <!-- <ng-container *ngFor="let style of entityInfo?.css"> -->
                    <ng-container *ngIf="attr.type === field.field.id">
                        <div
                            [ngClass]="'col-lg-'+field?.pc?.cols+' '+'col-md-'+field?.tablet?.cols+' '+'col-sm-'+field?.mobile?.cols"
                            [ngStyle]="{'order': field?.pc?.pos}"
                        >
                            <classical-form-field [field]="field" [selectedAttr]="attr" (editValue)="editValue(attr.id, $event)" [resetForm]="resetForm" [entityInfo]="entityInfo">
                            </classical-form-field>
                        </div>
                    </ng-container>
                <!-- </ng-container> -->
            </ng-container>
        </ng-container>
        </div>
        <div class="btns" *ngIf="formDisplayType === 'modal'">
            <button class="btn" (click)="closeModal.emit()">{{ 'cancel' | translate }}</button>
            <button class="btn"(click)="updateFields()">{{ 'ok' | translate }}</button>
        </div>
        <div class="btns" *ngIf="formDisplayType === 'workflow_modal'">
            <button class="btn prev" *ngIf="workflow?.step > 1 && workflow?.step <= workflow?.steps_count && workflow?.steps_count > 1" (click)="prevButtonClick.emit(); checkPrevButtonClick()">
                {{ 'prev' | translate }}
            </button>
            <span class="page_count" *ngIf="workflow?.step <= workflow?.steps_count">
                {{ workflow?.step }} / {{ workflow?.steps_count }}
            </span>
            <button class="btn next" (click)="updateFields()" *ngIf="workflow?.steps_count >= workflow?.step">
                {{ 'next' | translate }}
            </button>
            <button class="btn" *ngIf="workflow?.steps_count < workflow?.step" (click)="hideModal.emit()">
                {{ 'alergotel.close' | translate }}
            </button>
        </div>
                       
        <!-- <div class="nav_btns" *ngIf="formDisplayType === 'workflow_modal'">
            <button
                (click)="previousFormClick = true; goToPreviousForm()"
                class="cbtn prev"
            ></button>
            <button (click)="nextFormClick = true" class="cbtn next"></button>
        </div> -->
    </div>
</div>