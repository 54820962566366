<div class="container-fluid ter-container nc d-flex">
    <!-- <div class="form_types_list">
        <div class="form_type" *ngFor="let type of formTypes; let i = index;" (click)="displayFormType(type)">
            <span *ngIf="type?.name_t && type?.name_t.id">{{ type?.name_t[langString].trim() !== '' ? type?.name_t[langString] : type?.name  }}</span>
            <span *ngIf="!type?.name_t">{{ type?.name }}</span>

            <div class="delete" *ngIf="!type?.locked" (click)="deleteFormType(type.id); $event.preventDefault(); $event.stopPropagation()">
                <span><i class="fa fa-trash"></i></span>
            </div>
        </div>
    </div> -->

    <div class="form_editor" *ngIf="type || newType">
        <div class="form_fields">
            <!-- <button [disabled]="type?.locked" class="btn" (click)="edit('name_t')">{{"forms.name" | translate}}</button> -->
            <button [disabled]="type?.locked" class="btn" (click)="edit('info')">{{"forms.info" | translate}}</button>
            <button [disabled]="type?.locked" class="btn" (click)="editImage(); showBgImageModal()">{{"forms.image" | translate}}</button>
            <!-- <mat-slide-toggle color="#34A59A" [checked]="bgImg" [(ngModel)]="bgImg" (change)="onToggleImage($event)">{{"forms.image" | translate}}</mat-slide-toggle> -->
            <button [disabled]="type?.locked" class="btn" (click)="showFields()">{{"forms.addField" | translate}}</button>
            <button [disabled]="!isSubmitEnabled || type?.locked" class="btn" (click)="updateFormType()">{{ 'translations.save' | translate }}</button>
        </div>
        <!-- <p class="form_preview_text">{{ "forms.formTemplate" | translate }}</p> -->
        <div class="form_preview">
            <div class="screen_fields">
                <button class="btn" [ngClass]="{'active': defaultSize === 'pc'}" (click)="defaultSize = 'pc'">{{"forms.pc" | translate}}</button>
                <button class="btn" [ngClass]="{'active': defaultSize === 'tab'}" (click)="defaultSize = 'tab'">{{"forms.tablet" | translate}}</button>
                <button class="btn" [ngClass]="{'active': defaultSize === 'mobile'}" (click)="defaultSize = 'mobile'">{{"forms.mobile" | translate}}</button>
            </div>
            <div class="form_name" (click)="edit('name_t')">
                {{formName ? formName : namePlaceholder}} <i *ngIf="!type?.locked" class="fa fa-edit center f-16 ml-2"></i>
            </div>
            <div class="form_body d-flex">
                <div class="form_pic">
                    <div class="img" *ngIf="bgImg && bgImg?.file && showImg" (click)="editImage(); showBgImageModal()">
                        <img [src]="mediaUrl + bgImg?.file" alt="">
                        <span class="cls" *ngIf="!type?.locked" (click)="hideImg()"><i class="fa fa-times"></i></span>
                    </div>
                </div>
                <div class="form_content">
                    <div class="info" (click)="edit('info')" *ngIf="showInfo && info">
                        {{ info ? info[langString] : '' }} <span class="cls" *ngIf="!type?.locked" (click)="hideInfo()"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="fields_area row w-100 m-0" cdkDropList (cdkDropListDropped)="onDrop($event, defaultSize)">
                        <ng-container *ngFor="let item of fields; let i = index">
                            <div
                                [ngClass]="'col-' + (defaultSize === 'pc' ? item?.pc?.cols : defaultSize === 'tab' ? item?.tablet?.cols : item?.mobile?.cols)"
                                [ngStyle]="{ 'order': calculateOrder(item) }"
                                class="field"
                                cdkDrag
                                [cdkDragData]="i"
                            >
                            <div class="area" (click)="editCss(item)">
                                <span class="text">{{item?.field?.name_t ? getTranslation(item?.field?.name_t) : item?.field?.name }}</span>
                                <span class="cls" *ngIf="!type?.locked" (click)="removeFormFieldType(item?.id, item?.field); $event.preventDefault(); $event.stopPropagation();"><i class="fa fa-times"></i></span>
                            </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

<fap-modal #editFieldModal
    [inputPrimaryButtonInterface]="editFieldButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="editFieldButtonSecondaryInterface"
    [inputTitle]="selectedEditFieldLabel">
<form>
    <div class="form-group">
        <div class="row align-items-baseline">
            <div class="col-12">
                <div class="input-group align-items-baseline w-100">
                    <!-- <mat-form-field class="w-100">
                        <mat-label>{{ selectedEditField }}</mat-label>
                        <input matInput (focusout)="updateTextField(selectedEditField, $event)" [value]="type? type[selectedEditField] : ''">
                    </mat-form-field> -->

                    <!-- <mat-form-field class="w-100" (click)="addEditFieldTranslation()" *ngIf="selectedEditField === 'name'">
                        <mat-label class="col-form-label">{{ selectedEditField }}</mat-label>
                        <input matInput readonly #nameInput1 [value]="type? type[selectedEditField] : ''">
                    </mat-form-field> -->

                    <mat-form-field class="w-100" (click)="addEditFieldTranslation()">
                        <mat-label class="col-form-label">{{ selectedEditFieldLabel }}</mat-label>
                        <input matInput readonly #nameInput1 [value]="type ? type[selectedEditField] ? type[selectedEditField][langString] : '' : ''">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</form>
</fap-modal>

<fap-modal 
    #addImageModal
    [inputPrimaryButtonInterface]="addImageModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addImageModalButtonSecondaryInterface"
    inputTitle="Add Image"
>
<form [formGroup]="imageForm" class="col-12 p-0">
    <div class="form-group" 
    style="display: none">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Group' }}</mat-label>
            <input matInput formControlName="group" readonly value="forms">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Tags' }}</mat-label>
            <input matInput formControlName="tags" placeholder="Tags">
        </mat-form-field>
    </div>
    <div class="form-group">
        <label style="display: block;" class="upload-img-label">{{ 'Image' | translate }}</label>
        <input #fileUploader type="file" (change)="onFileInput($event)">
        <img *ngIf="imageSrc" class="preview-img d-block" [src]="imageSrc" alt="image">
    </div>
</form>
</fap-modal>

<fap-modal #imageModal
[inputPrimaryButtonInterface]="imageModalButtonPrimaryInterface"
[inputSecondaryButtonInterface]="imageModalButtonSecondaryInterface"
inputTitle="Image">
<div class="image_select mb-3" *ngIf="imgPath">
    <img [src]="mediaUrl + imgPath" alt="">
</div>
    <div class="w-100">
        <label class="col-form-labl">Background</label>
        <mat-form-field (click)="getImages()" class="w-100">
        <span style="position: absolute; text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;">{{ imgPath }}</span>
        <div class="form-group w-100">
            <div class="rw">
                <div class="d-flex w-100">
                    <div class="d-flex w-100" style="overflow-y: hidden;">
                        <div class="bx">
                            <mat-label style="display: block;"> {{ 'notes.images' | translate }} </mat-label>
                            <div class="input-group align-items-baseline w-100 img_bx">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ 'Images' | translate }}</mat-label>
                                    <mat-select (selectionChange)="addImage($event)" name="image" (infiniteScroll)="scrolledDownImages()" msInfiniteScroll>
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100 disbld">
                                            <input matInput #typeFilter disabled placeholder="Search">
                                        </mat-form-field>
                                        <div class="input-group-append pl-1" (click)="showImageModal()">
                                            <span class="input-group-text"><i
                                                    class="icofont icofont icofont-plus"></i></span>
                                        </div>
                                    </div>
                                    <div class="scroll_wrap" (scroll)="onScroll($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
                                            <div class="name" style="margin: 5px;">
                                                <img style="height: 100px; width: 100%; object-fit: contain" [src]="mediaUrl + image.file" alt="image">
                                            </div>
                                            <div class="actions">
                                                <i class="fa fa-trash" (click)="deleteImage(image?.id); $event.stopPropagation()"></i>
                                            </div>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                </mat-form-field>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-form-field>
</div>
</fap-modal>

<fap-modal #fieldsModal
[inputPrimaryButtonInterface]="fieldModalButtonPrimaryInterface"
[inputSecondaryButtonInterface]="fieldModalButtonSecondaryInterface"
inputTitle="Fields">
    <div class="w-100">
        <!-- {{selectedFields | json}} -->
        <!-- <mat-form-field disabled class="w-100">
            <mat-label>{{ 'attribute.attributes' | translate }}</mat-label>
            <mat-select required [(ngModel)]="selectedFields" multiple (ngModelChange)="collectAttributes($event)">
                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                <mat-form-field class="w-100" appearance="fill">
                    <input matInput #attributeNameFilter placeholder="Search">
                </mat-form-field>
                <em class="fa fa-plus center f-20 ml-2" (click)="addEditAttribute()"></em>
            </div>
            <div class="scroll_wrap" style="padding-left: 10px;">
            <mat-option style="height: 0!important;"></mat-option>
                <mat-option style="margin: 0;" class='row' *ngFor="let attribute of globalRegistry?.systemData?.attributes | filter : attributeNameFilter.value" [value]='attribute?.id'>
                    <div class="attr-type-name">{{attribute.name}}</div>
                    <div class="attr-type-actions">
                        <i class="fa fa-edit center f-20" (click)="addEditAttribute(attribute); $event.preventDefault(); $event.stopPropagation()"></i>
                    </div>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field> -->
        <chips 
            [items]="globalRegistry?.systemData?.attributes" 
            [selectedItems]="selectedFields"
            [label]="'attribute.attributes' | translate"
            (updatedItems)="collectAttributes($event)"
            [addEnabled]="true"
            [isEditable]="true"
            (editItem)="addEditAttribute($event)"
            (showAddModal)="addEditAttribute()"
        >
        </chips>
    </div>
</fap-modal>

<fap-modal #addEditAttributePopup inputTitle="Add/Edit Form Fields">
    <form [formGroup]="addEditAttributeForm" class="col-12 p-0">
        <div class="form-group w-100 m-0">
            <mat-form-field class="w-100" (click)="addEditTranslation()">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name_t" readonly #nameInput>
                <mat-error *ngIf="addEditAttributeForm.controls['name_t'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col-6">
                <mat-label class="col-form-label">{{ "notes.color" | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color"/>
                <mat-error *ngIf="addEditAttributeForm.controls['color'].errors?.required">
                    <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                </mat-error>
            </mat-form-field>
            <div class="col-6">
                <label class="col-form-labl">Icon</label>
                <mat-form-field (click)="getImages()" class="w-100">
                <!-- <mat-label class="col-form-label" [ngClass]="imgPath ? 'float' : ''">{{ "widget.background" | translate }}</mat-label> -->
                <span style="position: absolute; text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;">{{ imgPath }}</span>
                <div class="form-group w-100">
                    <div class="rw h10">
                        <div class="d-flex w-100">
                            <div class="d-flex w-100" style="overflow-y: hidden;">
                                <div class="bx">
                                    <div class="input-group align-items-baseline w-100 img_bx">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{ 'Images' | translate }}</mat-label>
                                            <mat-select (selectionChange)="addImage($event)" name="image" (infiniteScroll)="scrolledDownImages()" msInfiniteScroll> <!--Add formcontrol name here-->
                                                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                                <mat-form-field appearance="fill" class="w-100 disbld">
                                                    <input matInput #typeFilter disabled placeholder="Search">
                                                </mat-form-field>
                                                <div class="input-group-append pl-1" (click)="showImageModal()">
                                                    <span class="input-group-text"><i
                                                            class="icofont icofont icofont-plus"></i></span>
                                                </div>
                                            </div>
                                            <div class="scroll_wrap" (scroll)="onScroll($event)">
                                                <mat-option style="height: 0!important;"></mat-option>
                                                <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
                                                    <div class="name" style="margin: 5px;">
                                                        <img style="height: 100px; width: 100%; object-fit: contain" [src]="mediaUrl + image.file" alt="image">
                                                    </div>
                                                    <div class="actions">
                                                        <i class="fa fa-trash" (click)="deleteImage(image?.id); $event.stopPropagation()"></i>
                                                    </div>
                                                </mat-option>
                                            </div>
                                            </mat-select>
                                        </mat-form-field>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-form-field>
        </div>
        </div>
        <div class="row">
            <mat-form-field class="col-6">
                <mat-label>{{ 'attribute.valueType' | translate }}</mat-label>
                <mat-select required formControlName='value_type' (selectionChange)="validateInputType($event.value)">
                <div class="scroll_wrap" style="padding-left: 10px;">
                <mat-option style="height: 0!important;"></mat-option>
                    <mat-option style="margin: 0;" class='row' *ngFor="let type of valueTypes" [value]='type'>
                        <div class="attr-type-name">{{ type | titlecase }}</div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
            <div class="col-6">
                <mat-form-field style="max-width: 100%; margin-left: -1px;" *ngIf="addEditAttributeForm.controls['value_type'].value ==='number'">
                    <mat-label>Unit</mat-label>
                <mat-select class="select u" required formControlName="unit">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #unitFilter placeholder="Search">
                        </mat-form-field>
                        <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                    </div>
                    <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-optgroup class="noteIcons" *ngFor="let parent of globalRegistry?.systemData?.units | filter : unitFilter.value">
                        <span class="mat-option-text">
                            <div class="type-name_title">
                                <span class="theme_color">{{parent.name}}</span>
                            </div>
                        </span>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                            <div class="type-name">
                                <span>{{child.shortName}}</span>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </div>
                </mat-select>
                </mat-form-field>
                <mat-form-field style="max-width: 100%; margin-left: -1px;" *ngIf="addEditAttributeForm.controls['value_type'].value ==='entity'">
                    <mat-label>{{ 'attribute.entity' | translate }}</mat-label>
                <mat-select required formControlName='entity'>
                <div class="scroll_wrap" style="padding-left: 10px;">
                <mat-option style="height: 0!important;"></mat-option>
                    <mat-option style="margin: 0;" class='row' *ngFor="let entity of allEntities" [value]='entity.id'>
                        <div class="attr-type-name">
                            <span *ngIf="getTranslation(entity?.name_t) === '' || getTranslation(entity?.name_t) == null || getTranslation(type?.name_t) == undefined ">{{entity?.name}}</span>
                            <span *ngIf="getTranslation(entity?.name_t) !== ''">{{getTranslation(entity?.name_t)}}</span>
                            </div>
                    </mat-option>
                </div>
                </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'attribute.inputType' | translate }}</mat-label>
                    <mat-select required formControlName='input_type'>
                    <div class="scroll_wrap" style="padding-left: 10px;">
                    <mat-option style="height: 0!important;"></mat-option>
                        <mat-option style="margin: 0;" class='row' *ngFor="let type of filteredInputTypes" [value]='type'>
                            <div class="attr-type-name">{{ type | titlecase }}</div>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="btns">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="selectedAttribute">
                    <i class="fa fa-trash" (click)="deleteAttribute(selectedAttribute?.id)"></i>
                </button>
            </div>
            <div class="action_btns d-flex justify-content-between mt-3">
                <button class="btn" (click)="addEditAttributePopup.hideModal(); addEditAttributeForm.reset()">{{ 'translations.cancel' | translate }}</button>
                <button class="btn" (click)="submitAttribute()">{{ 'translations.save' | translate }}</button>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #cssModal
    [inputPrimaryButtonInterface]="cssButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="cssButtonSecondaryInterface"
    inputTitle="Style">
                <div class="row" *ngIf="defaultSize === 'pc'">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Columns</mat-label>
                            <mat-select [value]="pcCol" (selectionChange)="changeCssItem('pcCol', $event)">
                                <div class="scroll_wrap">
                                    <mat-option *ngFor="let column of columnOptions" [value]="column">{{ column }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Position</mat-label>
                            <input matInput type="number" min="1" max="12" [value]="pcPosition" (focusout)="changevalue('pcPosition', $event)">
                        </mat-form-field>
                    </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>UI Component</mat-label>
                            <mat-select [value]="pcComponent" (selectionChange)="changeCssItem('pcComponent', $event)">
                                <div class="scroll_wrap">
                                    <mat-option *ngFor="let comp of filteredComponents" [value]="comp">{{ comp }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            
                <div class="row" *ngIf="defaultSize === 'tab'">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Columns</mat-label>
                            <mat-select [value]="tabCol" (selectionChange)="changeCssItem('tabCol', $event)">
                                <div class="scroll_wrap">
                                    <mat-option *ngFor="let column of columnOptions" [value]="column">{{ column }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Position</mat-label>
                            <input matInput type="number" min="1" max="12" [value]="tabPosition" (focusout)="changevalue('tabPosition', $event)">
                        </mat-form-field>
                    </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>UI Component</mat-label>
                            <mat-select [value]="tabComponent" (selectionChange)="changeCssItem('tabComponent', $event)">
                                <div class="scroll_wrap">
                                    <mat-option *ngFor="let comp of filteredComponents" [value]="comp">{{ comp }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            
                <div class="row" *ngIf="defaultSize === 'mobile'">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Columns</mat-label>
                            <mat-select [value]="mobileCol" (selectionChange)="changeCssItem('mobileCol', $event)">
                                <div class="scroll_wrap">
                                    <mat-option *ngFor="let column of columnOptions" [value]="column">{{ column }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>Position</mat-label>
                            <input matInput type="number" min="1" max="12" [value]="mobilePosition" (focusout)="changevalue('mobilePosition', $event)">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <mat-form-field class="w-100">
                            <mat-label>UI Component</mat-label>
                            <mat-select [value]="mobileComponent" (selectionChange)="changeCssItem('mobileComponent', $event)">
                                <div class="scroll_wrap">
                                    <mat-option *ngFor="let comp of filteredComponents" [value]="comp">{{ comp }}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="'Field type name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.selectedAttribute)" 
        [prefix]="'FIELD_TYPE_'" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditFieldTranslationPopup [inputTitle]="'Edit information'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.type)"
        [prefix]="'FORM_TYPE_'" 
        [field]="selectedEditField"
        [translation]="fieldTranslation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateFieldTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>