<div class="row" style="border: 1px solid #ddd;">
  <div class="col-md-12 d-flex justify-content-center p-0">
    <div class="card mb-0">
        <div class="user-profile-header">
            <p>
                {{ 'user.profile.myProfile' | translate }}
            </p>
            <span (click)="emitToggleProfileDialog()"></span>
        </div>


      <div class="user-profile-content">
          <div class="col-4 d-flex justify-content-center">
              <div class="details mb-3">
                  <fap-upload-avatar
                      style = "width: 100%"
                      [ngStyle]="{'height': '100%'}"
                      [accentColor]="accentColor"
                      [inputImageUrl]="innerImage"
                      (outputImageLoaded)="imageChanged($event)">
                  </fap-upload-avatar>
<!--                  <div class="form-group mb-2">-->
<!--                  <div class="contact-profile" [ngStyle]="{'background-image': 'url(' + innerImage + ')'}">-->
<!--                                <label [ngStyle]="{'background': accentColor}" for="upload" class="fa fa-pencil edit-icon">-->
<!--                                </label>-->
<!--                                <input id="upload" class="upload" type="file" (change)="handleInputChange($event)" />-->
<!--                      </div>-->
<!--                  </div>-->
                  <button (click)="passwordReset()" class="reset-button btn">{{'user.resetPassword' | translate}}</button>
                  <button (click)="deleteAccount()" class="reset-button btn mt-2">{{'user.accountDelete' | translate}}</button> 

              </div>
          </div>
          <div class="col-8 pl-0">
              <form [formGroup]="editProfileForm">
                  <div class="row">
                      <div class="col-6">
                              <mat-form-field class="w-100">
                                  <mat-label class="col-form-label">{{ 'auth.register.firstName' | translate }}</mat-label>
                                  <input matInput formControlName="firstName">
                                  <mat-error *ngIf="ifControlHasError('firstName','required')">
                                      <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                  </mat-error>
                                  <mat-error *ngIf="ifControlHasError('firstName','minlength')">
                                      <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                                  </mat-error>
                                  <mat-error *ngIf="ifControlHasError('firstName','maxlength')">
                                      <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                                  </mat-error>
                              </mat-form-field>
                      </div>
                      <div class="col-6">
                              <mat-form-field class="w-100">
                                  <mat-label class="col-form-label">{{ 'auth.register.lastName' | translate }}</mat-label>
                                  <input matInput formControlName="lastName">
                                  <mat-error *ngIf="ifControlHasError('lastName','required')">
                                      <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                  </mat-error>
                                  <mat-error *ngIf="ifControlHasError('lastName','minlength')">
                                      <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                                  </mat-error>
                                  <mat-error *ngIf="ifControlHasError('lastName','maxlength')">
                                      <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                                  </mat-error>
                              </mat-form-field>
                      </div>
                      <div class="col-12">
                          <div class="job-field">
                              <mat-form-field class="w-100">
                                  <mat-label class="col-form-label">{{ 'user.profile.job' | translate }}</mat-label>
                                  <input matInput formControlName="job">
                                  <mat-error *ngIf="ifControlHasError('job','required')">
                                      <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                  </mat-error>
                              </mat-form-field>
                          </div>
                      </div>
                  
                <div class="col-12 dbld">
                      <mat-form-field class="w-100">
                          <mat-label class="col-form-label">{{ 'user.profile.email' | translate }}</mat-label>
                          <input matInput formControlName="email" required disabled readonly (click)="promptChangeEmail()">
                          <mat-error *ngIf="ifControlHasError('email','email')">
                              <strong>{{ 'auth.validation.invalidField' | translate }}</strong>
                          </mat-error>
                          <mat-error *ngIf="ifControlHasError('email','required')">
                              <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                          </mat-error>
                          <mat-error *ngIf="ifControlHasError('email','minlength')">
                              <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                          </mat-error>
                          <mat-error *ngIf="ifControlHasError('email','maxlength')">
                              <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                          </mat-error>
                      </mat-form-field>
                    </div>
                   
                    <div class="col-6 dbld">
                        <mat-form-field class="w-100">
                            <mat-label class="col-form-label">{{ 'user.profile.phone' | translate }}</mat-label>
                            <input matInput formControlName="phone" required disabled readonly (click)="promptChangePhone()">
                            <mat-error *ngIf="ifControlHasError('phone','required')">
                                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-6">
                        <mat-form-field class="w-100">
                            <mat-label class="col-form-label">{{ 'user.profile.language' | translate }}</mat-label>
                            <mat-select formControlName="language">
                                <div class="scroll_wrap">
                                    <div class="slct">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let language of languages" [value]="language.key">
                                            <span class="middle_text">{{ language?.value }}</span>
                                        </mat-option>
                                    </div>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12">
                        <mat-form-field class="w-100">
                            <mat-label class="col-form-label">{{ 'user.profile.timezone' | translate }}</mat-label>
                           <mat-select formControlName="timezone">
                                <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                                <input matInput #timeFilter placeholder="Search"/>
                                </mat-form-field>
                                <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-optgroup *ngFor="let group of timezones | filter : timeFilter.value" [label]="group?.country" ngShow="group.timezones.length">
                                        <div class="slct">
                                            <mat-option *ngFor="let timezone of group.timezones" [value]="timezone?.shortName" style="text-transform: capitalize">
                                                <span class="middle_text">{{ timezone?.timeZoneString }}</span>
                                            </mat-option>
                                        </div>
                                    </mat-optgroup>
                                </div>
                            </mat-select>
                            
                          </mat-form-field>
                  </div>
                    
                </div>
                  <div class="form-group adress-field">
                      <div class="w-100">
                          <mat-label class="col-form-label" [ngClass]="fullAddress ? 'float' : ''">{{ 'user.profile.address' | translate }}</mat-label>
                          <input matInput formControlName="address_id" style="display: none;">
                          <div class="full_address" (click)="editAddress(fullAddress)">
                            {{fullAddress?.number ? fullAddress?.number+',' : ''}} {{fullAddress?.street ? fullAddress?.street+',' : ''}} {{fullAddress?.city ? fullAddress?.city+',' : ''}} {{fullAddress?.postal_code ? fullAddress?.postal_code+'' : ''}} {{fullAddress?.country ? fullAddress?.country : ''}}
                          </div>                          
                        </div>
                  </div>

                  <div class="button-wrap">
                      <button class="btn" [ngStyle]="{'background':buttonColor, 'color':'white'}" (click)="submit()">Save</button>
                      <button class="btn" [ngStyle]="{'background':buttonColor, 'color':'white'}" (click)="emitToggleProfileDialog()">Cancel</button>
                  </div>
              </form>
          </div>
      </div>
    </div>
  </div>
</div>
<fap-modal #addEditAddressModal inputTitle="{{ 'party.address.addEditAddressModal' | translate }}" [inputIsLarge]="true">
    <fap-add-edit-address [address]="selectedAddress" [type]="'person'"
        (addedAddress)="addressAdd($event);editProfileForm.controls['address_id'].setValue($event.id);addEditAddressModal.hideModal()"
        (updatedAddress)="addressUpdate($event);editProfileForm.controls['address_id'].setValue($event.id);addEditAddressModal.hideModal()">
    </fap-add-edit-address>
</fap-modal>

<div class="custom_modal" id="custom_modal400">
    <div class="modal_header">
        <h2>Enter new phone number</h2>
        <button type="button" class="btn close-modal" (click)="closePhoneModal()"><i class="fa fa-times"></i></button>
    </div>
    <form [formGroup]="phoneForm">
        <div class="form-group">
            <div class="row align-items-baseline">
              <div class="col-4">
                <div class="form-group">
                  <mat-form-field class="w-100">
                      <mat-label class="col-form-label">Country code</mat-label>
                      <mat-select formControlName="countryCode">
                          <mat-form-field appearance="fill" class="w-100">
                          <input matInput #country placeholder="Search"/>
                          </mat-form-field>
                          <div class="scroll_wrap">
                                  <div class="slct">
                                    <mat-option style="height:0;"></mat-option>
                                    <mat-option *ngFor="let timezone of data | filter : country.value" [value]="timezone?.CountryCallingCode" style="text-transform: capitalize">
                                        <div class="type-name ddd" [attr.name]="timezone.Country +' ('+ timezone?.Region + ')'"><b style="width: 50px; display: inline-block;">{{ '+'+timezone?.CountryCallingCode }}</b></div>
                                    </mat-option>
                                  </div>
                             
                          </div>
                      </mat-select>
                    </mat-form-field>
            </div>
          </div>
            <div class="col-8">
              <div class="input-group w-100">
                <mat-form-field>
                    <mat-label>Phone</mat-label>
                    <input type="number" matInput ngDefaultControl formControlName="phone">
                </mat-form-field>
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn" (click)="getPin()">{{"notes.submit" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
  </div>

  <div class="custom_modal" id="custom_modal402">
    <div class="modal_header">
        <h2>Enter new email</h2>
        <button type="button" class="btn close-modal" (click)="closeEmailModal()"><i class="fa fa-times"></i></button>
    </div>
    <form [formGroup]="emailForm">
        <div class="form-group">
            <div class="row align-items-baseline">
            <div class="col-8">
              <div class="input-group w-100">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input type="email" matInput ngDefaultControl formControlName="email">
                </mat-form-field>
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn" (click)="geEmailPin()">{{"notes.submit" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
  </div>
  
  <div class="custom_modal" id="custom_modal401">
    <div class="modal_header">
        <h2>Enter Pin</h2>
        <button type="button" class="btn close-modal" (click)="closeOTPModal()"><i class="fa fa-times"></i></button>
    </div>
    <form [formGroup]="OTPForm">
      <div class="cc">
        <p *ngIf="phoneChange">{{"auth.checkSMS" | translate}} {{ phoneNumber }}</p>
        <p *ngIf="emailChange">{{"auth.checkMail" | translate}} {{ email }}</p>
        <div class="counter">{{counter | timer}}</div>
      </div>
        <div class="form-group">
            <div class="row align-items-baseline">
              <div class="input-group w-100">
                <mat-form-field>
                    <mat-label>{{"auth.enterPin" | translate}}</mat-label>
                    <input type="number" max="60" matInput ngDefaultControl formControlName="pin">
                </mat-form-field>
            </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn" *ngIf="phoneChange" (click)="postPin()">{{"notes.submit" | translate}}</button>
                    <button class="btn" *ngIf="emailChange" (click)="postEmailPin()">{{"notes.submit" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
  </div>