import { Component, Input } from '@angular/core';

@Component({
    selector: 'fap-add-button',
    templateUrl: './add-button.component.html',
    styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent {

    @Input()
    public backgroundColor: string;

    @Input()
    public color: string;
}
