<div class="container-fluid p-0 grey-background overflow-auto" [ngStyle]="{'height': '95vh'}">
    <div class="card grey-background p-3 mx-auto">
        <div [ngStyle]="{'background':navService.sidebarBackgroundColor}" class="header">
            <p>{{'farm.genericData' | translate }}</p>
        </div>
        <div class="card-body d-flex no-wrap white-background justify-content-between p-3 p-md-4 mb-2">
            <form [formGroup]="farmForm"
                  [ngClass]="{'col-6': mapService.mapShiftedOnce, 'col-12': mapService.mapShiftedTwice, 'col-8': !mapService.mapShiftedOnce && !mapService.mapShiftedTwice}"
                  class="col-8 mobview">
                <div class="">
                    <div class="form-group m-form__group">
                        <div class="input-group">
                            <mat-form-field>
                                <mat-label>{{ 'farm.farmName' | translate }}</mat-label>
                                <input (ngModelChange)="checkName()" formControlName="farmName" matInput required
                                       placeholder="{{ 'farm.farmName' | translate }}" name="farmName">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
            <div [ngStyle]="{'color':navService.sidebarBackgroundColor}"
                 [ngClass]="{'col-6': mapService.mapShiftedOnce, 'col-12': mapService.mapShiftedTwice, 'col-4': !mapService.mapShiftedOnce && !mapService.mapShiftedTwice}"
                 class="edit-map mobview">
                <p class="para">
                    Edit area on map <br>
                    or press
                    <span [ngStyle]="{'background':navService.hoverColor}">
                        <img src="./assets/images/fap/add_edit_farm.svg" alt="Edit on map">
                    </span>
                </p>
                <span>
                    <img [src]=navService.triangle alt="triangle">
                </span>
            </div>
        </div>
        <fap-attributes
                [entityRelation]="'farm'"
                [entityId]="entityInfo?.id"
                [objectId]="farmId">
                [entityInfo]="entityInfo"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
            </fap-attributes>
    </div>
</div>
