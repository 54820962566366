import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AlergotelService } from '../../../../../core/services/api/alergotel/alergotel.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
export enum StatusCodes {
  REPORTED = 0,
  VALIDATE_REPORT = 1,
  AUTHORITY_CHECK = 2,
  NOTIFY_OWNER = 3,
  OWNER_FIX = 4,
  CHECK_AFTER = 5,
  CLOSED = 6
}
@Component({
  selector: 'validate-report',
  templateUrl: './validate-report.component.html',
  styleUrls: ['./validate-report.component.scss']
})
export class ValidateReportComponent implements OnInit, OnDestroy {

  public reportId = -1;
  public historyId = -1;
  public report;
  public history;
  public isLoading = false;
  public mediaUrl = environment.mediaUrl;
  public isBaseApplication = true;
  public validateForm: UntypedFormGroup;
  public showCamera = false;
  public position = 'closeup';
  public coords;
  public isCloseReport = false;
  public subscriptions: Array<Subscription> = []

  latitude: number;
  longitude: number;

  constructor(private activatedRoute: ActivatedRoute, public alergotelService: AlergotelService, public router: Router, public formBuilder: UntypedFormBuilder, public toastr: ToastrService, public translate: TranslateService, private location: Location, public navService: NavService, public mapService: MapService) { 
    this.activatedRoute.params.subscribe((params: Params): void => {
      this.reportId = params['reportId'];
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.historyId = params['history'];
    }); 
    this.getReportDetail();
  }

  getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          const coords = {
            type: "Point",
            coordinates: [this.latitude, this.longitude]
          };
          this.coords = coords;
          console.log(coords);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  }

  ngOnInit(): void {
    this.navService.editFarm.next(true);
    this.navService.viewMod.next(true);
    this.getCurrentLocation();
    this.initValidateForm();
    const currentUrl = this.router.url; 
    if (currentUrl.includes('pages')) {
      this.isBaseApplication = true;
    } else {
      this.isBaseApplication = false;
    }

    let currentPath = ''
    console.log(this.location.path().split('/')[2]);
    if(this.isBaseApplication) {
      currentPath = this.location.path().split('/')[2]
      if(currentPath === 'alergotel_close-report') {
        this.isCloseReport = true
      }
    } else {
      currentPath = this.location.path().split('/')[2]
      if(currentPath === 'close-report') {
        this.isCloseReport = true
      }
    }

    this.subscriptions.push(this.navService.emitCancel.subscribe((value: boolean) => {
      if (value) {
          this.router.navigate(['/pages/alergotel_reports/'+this.report.id]);
          this.navService.emitCancel.next(false);
      }
  }));
  }

  addImage(obj) {
    const key = Object.keys(obj)[0];
    if(key === 'img_closeup') {
      this.validateForm.get('img_closeup').setValue(obj.img_closeup);
      this.position = 'left';
    } 
    if(key === 'img_left') {
      this.validateForm.get('img_left').setValue(obj.img_left);
      this.position = 'right';
    } 
    if(key === 'img_right') {
      this.validateForm.get('img_right').setValue(obj.img_right);
      this.position = 'closeup';
      this.showCamera = false;
    }
  }

  initValidateForm() {
    this.validateForm = new UntypedFormGroup({
      img_closeup: new UntypedFormControl('', Validators.required),
      img_left: new UntypedFormControl('', Validators.required),
      img_right: new UntypedFormControl('', Validators.required),
      info: new UntypedFormControl('', Validators.required),
      coords: new UntypedFormControl(null, Validators.required),
    });
  }

  getReportDetail() {
    this.isLoading = true;
    this.alergotelService.getReportDetail(this.reportId).subscribe(data => {
      this.report = data.body.results;
      this.isLoading = false;
      console.log(this.report);
      if(this.report.coords && this.report.coords.coordinates) {
        const coords = {
          lat: this.report.coords.coordinates[0],
          lng: this.report.coords.coordinates[1]
        }
        this.mapService.mapMarkers.push(coords);
        this.mapService.centerMapOnMarkers();
      }
      this.report.history.forEach(history => {
        if(history.id == this.historyId) {
          this.history = history;
          console.log(this.history);
          if(history?.status == StatusCodes.OWNER_FIX || history?.status == StatusCodes.CHECK_AFTER) {
            this.showCamera = true;
          } else {
            this.showCamera = false;
          }
        }
      });
    })
  }

  validateReport() {
    console.log(this.validateForm.value);
    const formData = new FormData();
    formData.append("img_closeup", this.validateForm.get('img_closeup').value);
    formData.append("img_left", this.validateForm.get('img_left').value);
    formData.append("img_right", this.validateForm.get('img_right').value);
    
    const object = {
      info: this.validateForm.get('info').value,
      coords: this.coords,
    }

    formData.append('json', JSON.stringify(object));
    this.alergotelService.validateReport(this.history.id, formData).subscribe(data => {
      console.log(data);
      this.toastr.success(this.translate.instant('alergotel.reportVerified'));
      if(this.isBaseApplication) {
        this.router.navigate(['/pages/alergotel_reports']);
      } else {
        this.router.navigate(['/alergotel/reports']);
      }
    }, (err) => {
      console.log(err)
      this.toastr.error(err.error.results.error);
    })
  }

  closeReport() {
    console.log(this.validateForm.value);
    const formData = new FormData();
        
    const object = {
      info: this.validateForm.get('info').value,
      coords: this.coords,
    }

    formData.append('json', JSON.stringify(object));
    this.alergotelService.closeReport(this.report.id, formData).subscribe(data => {
      console.log(data);
      this.toastr.success(this.translate.instant('alergotel.reportClosed'));
      if(this.isBaseApplication) {
        this.router.navigate(['/pages/alergotel_reports']);
      } else {
        this.router.navigate(['/alergotel/reports']);
      }
    }, (err) => {
      console.log(err)
      this.toastr.error(err.error.results.error);
    })
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.mapService.mapMarkers = [];
    this.navService.editFarm.next(false);
    this.navService.viewMod.next(false);
  }

}
