<form class="" [formGroup]="stockOutcomeForm">
    <div class="required">
        {{ 'stock.requiredFields' | translate }}
    </div>
    <div class="grid">
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'stock.farm' | translate }}</mat-label>
                <mat-select required formControlName="farm">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #farmFilter placeholder="Search">
                    </mat-form-field>
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option *ngFor="let farm of farmArray | filter : farmFilter.value" [value]="farm.id">
                        {{ farm.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('farm','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <fap-unit-value class="unit-value"
                [fieldName]="'stock.quantity' | translate"
                [(ngModel)]="quantity"
                [ngModelOptions]="{standalone: true}"
                class="w-100"
                >
            </fap-unit-value>
        </div>
        <div class="form-group" *ngIf="hasOutcomeType">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-group"></i>{{ 'stock.outcomeType' | translate }}</mat-label>
                <mat-select required formControlName="outcomeType">
                    <mat-option [value]="StockOutcomeTypesEnum.INTERNAL_CONSUMED">
                        {{ 'stock.consumedInternal' | translate }}
                    </mat-option>
                    <mat-option [value]="StockOutcomeTypesEnum.EXTERNAL">
                        {{ 'stock.externalTransaction' | translate }}
                    </mat-option>
                    <mat-option [value]="StockOutcomeTypesEnum.INTERNAL_FARM">
                        {{ 'stock.farmsTransaction' | translate }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('outcomeType','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group" *ngIf="resourceTypes">
            <mat-form-field class="w-100">
                <mat-label>{{ 'stock.type' | translate }}</mat-label>
                <mat-select class="select" required formControlName="type">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                        <!-- <mat-label>
                            <i class="fa fa-search f-20"></i>
                        </mat-label> -->
                            <input matInput #resourceFilter placeholder="Search">
                        </mat-form-field>
                        <i class="fa fa-plus f-20" (click)="selectResource()"></i>
                    </div>
                    <mat-optgroup *ngFor="let parent of resourceTypes | filter : resourceFilter.value">
                            <div class="type-name">
                                {{ parent.nameT ? getTranslation(parent.nameT) : parent.name }}
                            </div>
                            <div class="d-flex text-center">
                                <i class="fa fa-edit align-self-center f-20" (click)="selectResource(parent);$event.stopPropagation()"></i>
                                <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                            </div>
                            <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let child of parent.children" [value]="child.id">
                            <div class="type-name">
                                {{ child.nameT ? getTranslation(child.nameT) : child.name }}
                            </div>
                            <div class="d-flex text-center">
                                <i class="fa fa-edit align-self-center f-20" (click)="selectResource(child, parent);$event.stopPropagation()"></i>
                                <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('farm','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
            
        </div>
        <div class="form-group" *ngIf="farms">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'stock.farm' | translate }}</mat-label>
                <mat-select required formControlName="farm">
                    <mat-form-field appearance="fill" class="w-100">
                        <!-- <mat-label>
                            <i class="fa fa-search f-20"></i>
                        </mat-label> -->
                        <input matInput #farmFilter placeholder="Search">
                    </mat-form-field>
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option *ngFor="let farm of farms | filter : farmFilter.value" [value]="farm.id">
                        {{ farm.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('farm','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <fap-add-edit-delete-select
                inputName="{{ 'stock.partyFrom' | translate }}"
                class="w-100"
                [formControl]="stockOutcomeForm.controls['partyFrom']"
                [inputIsRequired]="true"
                [inputValueFieldName]="'id'"
                [inputNameFieldName]="'name'"
                [inputSelectValues]="parties"
                [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                (outputOnDelete)="onDeleteParty($event)"
                (outputOnCreate)="addPartyModal();partyFormFieldName='partyFrom'"
                (outputOnEdit)="editParty($event);partyFormFieldName='partyFrom'">
            </fap-add-edit-delete-select>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'stock.cost' | translate }}</mat-label>
                <input required type="number" matInput formControlName="cost">
                <mat-error *ngIf="ifControlHasError('cost','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <fap-add-edit-delete-select
                inputName="{{ 'stock.partyTo' | translate }}"
                class="w-100"
                [formControl]="stockOutcomeForm.controls['partyTo']"
                [inputIsRequired]="true"
                [inputValueFieldName]="'id'"
                [inputNameFieldName]="'name'"
                [inputSelectValues]="parties"
                [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                (outputOnDelete)="onDeleteParty($event)"
                (outputOnCreate)="addPartyModal();partyFormFieldName='partyTo'"
                (outputOnEdit)="editParty($event);partyFormFieldName='partyTo'">
            </fap-add-edit-delete-select>
        </div>

        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'stock.receiver' | translate }}</mat-label>
                <input matInput formControlName="receiver">
                <mat-error *ngIf="ifControlHasError('receiver','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100" (click)="receiveDatePicker.open()">
                <mat-label>{{ 'stock.receiveDate' | translate }}</mat-label>
                <input matInput readonly formControlName="receiveDate" [matDatepicker]="receiveDatePicker">
                <mat-datepicker-toggle matSuffix [for]="receiveDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #receiveDatePicker></mat-datepicker>
                <mat-error *ngIf="ifControlHasError('receiveDate','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 mt-3 text-center">
        <button class="btn btn-success" type="submit" (click)="submit()">{{ 'save' | translate }}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>

<fap-modal #addResourceTypeModal inputTitle="Add/Edit Resource Type">
    <fap-add-edit-resource-type
        [resourceTypes]="resourceTypes"
        [selectedResource]="selectedResource"
        [parentResource]="parentResource"
        (resourceTypeActionCompleted)="onResourceTypeAction($event)">
    </fap-add-edit-resource-type>
</fap-modal>
<fap-modal #addEditPartyModal
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
        (addedParty)="addedOrUpdatedParty($event)"
        (updatedParty)="addedOrUpdatedParty($event)">
    </fap-add-edit-party>
</fap-modal>
