import {
    Component,
    Input,
    EventEmitter,
    Output
} from '@angular/core';

import { ChartBarWidgetInterface } from '../data/chart-bar-widget.interface';
import { NameValueInterface } from '../../../core/interfaces/general/name-value.interface';
import { EventResponseInterface } from '../data/event-response.interface';

@Component({
    selector: 'fap-vertical-bar-chart',
    templateUrl: './fap_vertical-bar-chart.component.html',
    styleUrls: ['./fap_vertical-bar-chart.component.scss']
})
export class FapVerticalBarChartComponent {
    @Input()
    public chartData: ChartBarWidgetInterface;

    @Output()
    public selectedValue: EventEmitter<NameValueInterface> = new EventEmitter<NameValueInterface>();

    @Output()
    public activate: EventEmitter<EventResponseInterface> = new EventEmitter<EventResponseInterface>();

    @Output()
    public deactivate: EventEmitter<EventResponseInterface> = new EventEmitter<EventResponseInterface>();

    public defaultColorScheme: {} = { domain: [] };

    constructor(){
        this.defaultColorScheme['domain'] = [];
        for(let index = 0; index < 100; index++){
            this.defaultColorScheme['domain'].push('#324fd1');
        }
    }
    public onSelect(event: NameValueInterface): void {
        console.log(event);
        this.selectedValue.emit(event);
    }

    public onActivate(data: EventResponseInterface): void {
        this.activate.emit(data);
        console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    public onDeactivate(data: EventResponseInterface): void {
        this.deactivate.emit(data);
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

}
