<div
  class="container-fluid ter-container nc"
  style="overflow-x: hidden; width: 100%"
>
  <scheduler-layout
    [farms]="filteredFarms"
    [sensors]="filteredSensors"
    [farmForecastMapping]="farmForecastMapping"
    (addNote)="addSensor($event)"
    (deleteNote)="deleteSensor($event)"
  >
  </scheduler-layout>
</div>
