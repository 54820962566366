<div class="container-fluid ter-container nc">
     <fap-equipments-layout
          [farms]="filteredFarms"
          [equipments]="filteredEquipments"
          [farmForecastMapping]='farmForecastMapping'
          [equipmentTypes]="globalRegistry.systemData.equipmentTypes"
          [equipmentPoweredTypes]="equipmentPoweredTypes"
          [parties]="globalRegistry.systemData.parties"
          (deleteEquipment)="deleteEquipment($event)">
     </fap-equipments-layout>
</div>
