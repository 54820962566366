import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MapService } from '../../../shared/layout/fap_main-map/service/map-service';
import { TypesService } from '../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../shared/services/nav.service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FapModalButtonInterface } from '../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { FapModalComponent } from '../../../shared/partials';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AttributeModel } from '../../../core/models/attribute/attribute.model';
import { UnitModel } from '../../../core/models/units/unit.model';
import { ConfirmModalService } from '../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { UnitInterface } from '../../../core/interfaces/unit/unit.interface';
import { UnitService } from '../../../core/services/api/units/unit.service';
import { ResponseModel } from '../../../core/models/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash';

@Component({
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit, OnDestroy {
  formTypes = [];
  public langString: string;
  public subscriptions:Array<Subscription> = [];
  newType = false;
  public formName = '';
  public namePlaceholder = 'ADD FORM NAME';
  public infoPlaceholder = 'ADD INFO';
  public uniqueid = '';
  public type = null;
  public originalType = null
  public bgImg = null;
  public info = '';
  public mediaUrl = environment.mediaUrl;
  dragItemIndex: number | null = null;
  public selectedEditField = 'name';
  public selectedEditFieldLabel = '';
  public showInfo = false;
  public showImg = false;
  public images = [];
  public imgPath = '';
  public nextImgToken :{ offset: number; limit: number;} = null;
  public getMoreImg = true;
  imageSrc: string;
  public imageForm: FormGroup;
  @ViewChild('addImageModal')
  public addImageModal: FapModalComponent;
  @ViewChild('imageModal') public imageModal: FapModalComponent;
  @ViewChild('editFieldModal') public editFieldModal: FapModalComponent;
  @ViewChild('fieldsModal') public fieldsModal: FapModalComponent;
  @ViewChild('cssModal') 
  public cssModal: FapModalComponent;
  public imgWidth: number;
  public imgHeight: number;
  public orientation: string;
  public image
  @ViewChild('fileUploader') fileUploader:ElementRef;
  public changedImage: boolean;
  public fields = [];
  public addEditAttributeForm: UntypedFormGroup;
  public icon: any;
  public selectedAttribute: AttributeModel;
  public valueTypes = ['string', 'number', 'entity', 'datetime', 'boolean', 'image', 'coords', 'surface', 'polyline'];
  public inputTypes = ['single', 'multiselect', 'range', 'choice'];
  public components = ['input', 'radio', 'checkbox', 'combo', 'full size'];
  public filteredComponents = [];
  public filteredInputTypes = [];
  @ViewChild('addEditAttributePopup')
  public addEditAttributePopup: FapModalComponent;
  public translatedNames:any = [];
  public nameT: any = null;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  @ViewChild('addEditFieldTranslationPopup')
  public addEditFieldTranslationPopup: FapModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
  public translation = null;
  public fieldTranslation = null;
  public units: Array<UnitModel> = [];
  public unitForm: UntypedFormGroup;
  public selectedUnitId: number;
  public selectedUnit: UnitModel;
  public currentUnit: UnitModel;
  public parentUnit: UnitModel;
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  public selectedFields = [];
  public selectedField = null;
  columnOptions = Array.from({ length: 12 }, (_, index) => index + 1);
  public typeCss = null;
  public valueType = null;
  public inputType = null;
  public component = null;
  selectedTabIndex = 0;
  public css = null;
  public defaultSize = 'pc';
  public newFormFieldTypes = [];
  public removeFormFieldTypes = [];
  public isSubmitEnabled: boolean = false;
  public newFormType: number
  
  public pcCol = 12
  public tabCol = 12
  public mobileCol = 12

  public pcComponent:string = null
  public tabComponent:string = null
  public mobileComponent:string = null
  
  public pcPosition = 0
  public tabPosition = 0
  public mobilePosition = 0

  public editFieldButtonPrimaryInterface: FapModalButtonInterface;
  public editFieldButtonSecondaryInterface: FapModalButtonInterface;
  public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
  public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
  public imageModalButtonPrimaryInterface: FapModalButtonInterface;
  public imageModalButtonSecondaryInterface: FapModalButtonInterface;
  public fieldModalButtonPrimaryInterface: FapModalButtonInterface;
  public fieldModalButtonSecondaryInterface: FapModalButtonInterface;
  public cssButtonPrimaryInterface: FapModalButtonInterface;
  public cssButtonSecondaryInterface: FapModalButtonInterface;

  constructor(public mapService: MapService, public typesService: TypesService, public cacheService: CacheResolverService, public navService: NavService, public widgetsService: WidgetsService, public globalRegistry: GlobalRegistryService, private changeDetector: ChangeDetectorRef, public translateService: TranslateService, public companyService: CompanyService, public toastrService: ToastrService, public typeService: TypesService, public formBuilder: UntypedFormBuilder, public confirmModalService: ConfirmModalService, public unitService: UnitService, public activatedRoute: ActivatedRoute, public router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.mapService.hideMap();
    this.widgetsService.setSize(12);
    this.getFormTypes();
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
      }));
      this.translatedNames = this.globalRegistry.systemData.translations;
      this.initImageForm();
      this.initaddEditAttributeForm();
      this.initunitForm();
      this.initBgImageModalButtons();
      this.initEditModalButtons();
      this.initFieldModalButtons();
      this.initImageModalButtons();
      this.initCssModalButtons();
      this.filteredInputTypes = this.inputTypes;
      this.filteredComponents = this.components;
      setTimeout(() => {
        this.updateSelectedTabIndex();
      });
      this.subscriptions.push(this.activatedRoute.queryParams.subscribe(data => {
        console.log(data);
        if(data && data['edit']) {
          let type = this.formTypes.find(item => item.id === +data['edit']);
          this.newFormType = +data['edit']
          if(this.type && this.newFormType != this.type.id) {
            console.log('verify')
            this.verifyFormFields(this.type)
          } else {
            this.displayFormType(type);
            console.log('no verify')
          }
        }
        if(data && data['delete']) {
          this.deleteFormType(+data['delete']);
        }
        if(data && data['popup']) {
          const currentDate = new Date();
          const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getFullYear()}`;
          console.log(formattedDate);
          this.type = {};
          this.bgImg = null;
          this.fields = [];
          this.type = {
            uniqueid: 'FORM_TYPE_'+formattedDate,
            color: null,
            icon: null,
            name: 'FORM_TYPE_'+formattedDate,
            name_t: null,
            groups: [],
            locked: false,
            fields_css: [],
            info: null,
            image: {},
            commit: null
        }
        this.displayFormType(this.type)
        }
      }))
  }

  getSizeLabel(t: number): string {
    const row = Math.floor(t / 10);
    const col = t % 10;
    return `${row} x ${col}`;
  }

  initImageForm() {
    this.imageForm = new UntypedFormGroup({
      file: new UntypedFormControl('', Validators.required),
      group: new UntypedFormControl(''),
      tags: new UntypedFormControl([], Validators.required),
    });
  }

  public showFields() {
    if(this.type.locked) {
      return;
    }
    this.fieldsModal.showModal();
    console.log(this.selectedFields);
  }

  public imageSubmit() {
    const data = {
      width: this.imgWidth,
      height: this.imgHeight,
      orientation: this.orientation
    }
      const formData = new FormData();
      formData.append("file", this.image);
      formData.append("group", this.imageForm.get('group').value);
      formData.append("tags", this.imageForm.get('tags').value);
      formData.append("data", JSON.stringify(data));
      console.log(formData);
      // return;
      this.companyService.postCompanyImages(formData).subscribe(data => {
          console.log(data);
          this.imageForm.reset();
          this.imageForm.clearValidators();
          this.initImageForm();
          this.bgImg = null;
          this.imgPath = '';
          this.getImages();
      })
    this.addImageModal.hideModal();
  }

  onFileInput(event) {
    if(event.target.files && event.target.files.length) {
      if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
        this.toastrService.error(this.translateService.instant('image.notAnImage'))
        this.image = null;
        this.fileUploader.nativeElement.value = null;
        return
      }
      const [image] = event.target.files;
      this.image = image;
      const _URL = window.URL || window.webkitURL;
      let file, img, height, width;
      if ((file = event.target.files[0])) {
          img = new Image();
          const objectUrl = _URL.createObjectURL(file);
          img.onload = function () {
              height = this.height;
              width = this.width;
              _URL.revokeObjectURL(objectUrl);
          };
          img.src = objectUrl;
      }
      setTimeout(()=> {
        if(width <= height) {
          this.orientation  = "portrait";
          console.log("portrait");
        } else {
            this.orientation  = "landscape";
            console.log("landscape");
        }
      }, 500);
    }
  }
  
  public imageUploaded(file: File): void {
    this.changedImage = true;
    this.image = file;
    console.log(this.image);
    this.imageForm.markAsDirty();
  }

  private initEditModalButtons(): void {
    const _this: FormsComponent = this;
    this.editFieldButtonSecondaryInterface = {
        clickFunction: (): void => {
            _this.updateFields();
            _this.editFieldModal.hideModal();
        },
        text: this.translateService.instant('save'),
    };
    this.editFieldButtonPrimaryInterface = {
        clickFunction: (): void => {
          _this.editFieldModal.hideModal();
        },
        text: this.translateService.instant('cancel'),
    };
}

private initCssModalButtons(): void {
  const _this: FormsComponent = this;
  this.cssButtonSecondaryInterface = {
      clickFunction: (): void => {
          _this.updateCss();
          _this.cssModal.hideModal();
      },
      text: this.translateService.instant('save'),
  };
  this.cssButtonPrimaryInterface = {
      clickFunction: (): void => {
        _this.cssModal.hideModal();
      },
      text: this.translateService.instant('cancel'),
  };
}

public initImageModalButtons(): void {
  const _this: FormsComponent = this;
  this.addImageModalButtonPrimaryInterface = {
      clickFunction(): void {
        _this.imageSubmit();
      },
      text: this.translateService.instant('save')
  };

  this.addImageModalButtonSecondaryInterface = {
      clickFunction(): void {
        if(!this.type.image) {
          this.type.image = null;
          this.bgImg = null;
        }
        _this.addImageModal.hideModal();
      },
      text: this.translateService.instant('cancel')
  };
}

public initBgImageModalButtons(): void {
  const _this: FormsComponent = this;
  this.imageModalButtonPrimaryInterface = {
      clickFunction(): void {
        _this.updateImage();
      },
      text: this.translateService.instant('save')
  };

  this.imageModalButtonSecondaryInterface = {
      clickFunction(): void {
        _this.imageModal.hideModal();
        this.image = null;
      },
      text: this.translateService.instant('cancel')
  };
}

camelToSnake(obj) {
  const snakeObj = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const snakeKey = key.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
      snakeObj[snakeKey] = obj[key];
    }
  } 

  return snakeObj;
}

public collectAttributes(fields) {
  this.selectedFields = [];
  let fieldIds = this.fields.map(fieldObj => fieldObj.field.id);
  this.newFormFieldTypes = fields.filter(id => !fieldIds.includes(id));

  const missingItems = this.fields.filter(obj => !fields.includes(obj.field.id));
  
  if (missingItems.length) {
    missingItems.forEach(item => {
      this.removeFormFieldTypes.push(item);
    });
  }

  this.fields = this.fields.filter(obj => fields.includes(obj.field.id));
  console.log(fields);
  console.log(this.fields);
  setTimeout(() => {
    this.selectedFields = fields;
  }, 100)
}

public initFieldModalButtons(): void {
  const _this: FormsComponent = this;
  this.fieldModalButtonPrimaryInterface = {
      clickFunction(): void {
        _this.updateFields();
        _this.fieldsModal.hideModal();
      },
      text: this.translateService.instant('save')
  };

  this.fieldModalButtonSecondaryInterface = {
      clickFunction(): void {
        _this.fieldsModal.hideModal();
        this.image = null;
      },
      text: this.translateService.instant('cancel')
  };
}

async updateFormType() {
  if (this.type.locked) {
      return;
  }

  console.log(this.type);
  console.log(this.removeFormFieldTypes);

  try {
      // Use Promise.all to await all deleteFormFieldTypeAsync calls
      await Promise.all(this.removeFormFieldTypes.map(async (element) => {
          await this.deleteFormFieldType(element);
      }));

      console.log(this.type);

      this.type.name = this.formName;

      if (this.type.name === 'ADD FORM NAME') {
          this.type.name = null;
      }

      if (this.type.name_t) {
          this.type.name_t = this.type.name_t.id;
      }

      if (this.type.info) {
          if (this.type.info === 'ADD INFO') {
              this.type.info = null;
          } else {
              this.type.info = this.type.info.id;
          }
      }

      const body = {
          name: this.type.name,
          name_t: this.type.name_t,
          info: this.type.info,
          fields_css: this.type.fields_css.map(element => element.id),
          image: null
      };

      let imgId = null;

      if (this.type.image) {
          imgId = this.type.image.id;
          body.image = imgId;
      }

      if (this.type.id) {
          this.typeService.patchFormTypes(this.type.id, body)
              .subscribe(data => {
                  console.log(data);
                  if (data) {
                      this.type = data.body.results;
                      this.originalType = data.body.results;
                      this.fields = this.type.fields_css;
                      this.isSubmitEnabled = false;
                  }
                  this.toastrService.success('Form type updated successfully');
                  this.getFormTypes();
              }, error => {
                  this.toastrService.error(error);
              });
      } else {
          this.typeService.createFormType(body).subscribe(data => {
              console.log(data);
              this.type = data.body.results;
              this.isSubmitEnabled = false;
              this.toastrService.success('Form type created successfully');
              this.router.navigate(['pages/forms']);
          }, error => {
              this.toastrService.error(error);
          });
      }
  } catch (error) {
      console.error('Error updating form type:', error);
      this.toastrService.error('Error updating form type');
  }
}



public filterComponents() {
  if(!this.valueType || !this.inputType) {
    return
  }
  const components = this.components
  if(this.valueType ===  'string') {
    if(this.inputType === 'single') {
      this.filteredComponents = components.filter(component => !(component === 'radio' || component === 'checkbox' || component === 'combo' || component === 'full size'));
      console.log(this.filteredComponents);
    } else if(this.inputType === 'multiselect') {
      this.filteredComponents = components.filter(component => !(component === 'radio' || component === 'input' || component === 'full size'));
      console.log(this.filteredComponents);
    }
  }
  if(this.valueType === 'number') {
    this.filteredComponents = components.filter(component => !(component === 'radio' || component === 'checkbox' || component === 'combo' || component === 'full size'));
    console.log(this.filteredComponents);
  }
  if(this.valueType === 'boolean') {
    this.filteredComponents = components.filter(component => !(component === 'input' || component === 'full size'));
    console.log(this.filteredComponents);
  }
  if(this.valueType === 'coords' || this.valueType === 'surface' || this.valueType === 'polyline') {
    this.filteredComponents = components.filter(component => !(component === 'input' || component === 'radio' || component === 'checkbox'));
    console.log(this.filteredComponents);
  }
}

public editCss(item) {
  if(this.type.locked) {
    return
  }
  console.log(item);
  this.selectedField = item;
  this.valueType = item.field.value_type;
  this.inputType = item.field.input_type;
  
  this.filterComponents()
  this.cssModal.showModal();
  if(item.pc) {
    this.pcCol = item.pc.cols;
    this.pcPosition = item.pc.pos;
    this.pcComponent = item.pc.component;
  }
  if(item.tab) {
    this.tabCol = item.tab.cols;
    this.tabPosition = item.tab.pos;
    this.tabComponent = item.tab.component;
  }
  if(item.mobile) {
    this.mobileCol = item.mobile.cols;
    this.mobilePosition = item.mobile.pos;
    this.mobileComponent = item.mobile.component;
  }
}

public updateCss() {
  const pc = { cols: +this.pcCol, pos: +this.pcPosition, component: this.pcComponent };
  const tablet = { cols: +this.tabCol, pos: +this.tabPosition, component: this.tabComponent };
  const mobile = { cols: +this.mobileCol, pos: +this.mobilePosition, component: this.mobileComponent }

  const existingElement = this.selectedField;

  if (existingElement) {
    existingElement.pc = pc;
    existingElement.tablet = tablet;
    existingElement.mobile = mobile;
  } else {
    this.type.pc = pc;
    this.type.tablet = tablet;
    this.type.mobile = mobile;
  }
  let body = {
    id: this.selectedField.id,
    field: this.selectedField.field.id,
    form: this.type.id,
    pc,
    tablet,
    mobile
  }
  console.log(body)
  this.typesService.updateFormFieldType(this.selectedField.id, body).subscribe(data => {
    console.log(data);
  })
  console.log(this.type.fields_css);
  this.isSubmitEnabled = true;
  this.displayFormType(this.type);
  console.log(this.type);
}

public changeCssItem(prop, $event) {
  console.log($event.value);
  this[prop] = $event.value;
}

public changevalue(prop, $event) {
  console.log(prop, $event.target.value);
  this[prop] = $event.target.value;
}

public updateFields() {
  console.log(this.selectedFields);
  
  const selectedFieldIds = new Set(this.selectedFields);
  this.fields = this.fields.filter(field => {
      if (!selectedFieldIds.has(field.field.id)) {
          return false;
      }
      return true;
  });
  console.log(this.fields);
  this.type.fields_css = this.fields;
  console.log(this.newFormFieldTypes);
  this.newFormFieldTypes.forEach((field, index, array) => {
    let body = {
      form: this.type.id,
      field,
      pc: {cols: 12, pos: 1000, component: null},
      tablet: {cols: 12, pos: 1000, component: null},
      mobile: {cols: 12, pos: 1000, component: null}
    };
    this.typesService.addFormFieldType(body).subscribe(data => {
      if (data) {
        this.fields.push(data.body.results);
        this.selectedFields.push(data.body.results.field.id);
        this.type.fields_css = this.fields;
        this.isSubmitEnabled = true;
        if (index === array.length - 1) {
          this.newFormFieldTypes = [];
        }
      }
    });
  });
}

public updateImage() {
  console.log(this.imgPath);
  console.log(this.image);
  if(!this.image) {
    return
  }
  this.bgImg = this.image;
  this.showImg = true;
  this.type.image = this.image;
  console.log(this.type);
  this.imageModal.hideModal();
}

public showImageModal(): void {
  this.addImageModal.showModal();
}

public showBgImageModal(): void {
  this.imageModal.showModal();
}

public hideInfo() {
  this.showInfo = false;
  this.info = null;
}

public hideImg() {
  this.showImg = false;
  this.bgImg = null;
  this.type.image = null;
}

public updateTextField(field, $event) {
  console.log($event.target.value);
  if(!$event.target.value || $event.target.value.trim() === '') {
    return
  }
  this.type[field] = $event.target.value;
  console.log(this.type);
  this.showInfo = true;
  if(field === 'info') {
    this.info =  $event.target.value
  } else {
    this.formName = $event.target.value
  }
}

onToggleImage(event) {
  console.log(event)
  if(event.checked === true) {
    this.editImage();
    this.showBgImageModal();
  } else {
    this.type.image = null;
    this.bgImg = null;
  }
}

  public getFormTypes() {
    const url = this.typesService.getUrl('form_type/');
    this.cacheService.delete(url);
    this.typesService.getFormTypes().subscribe((data) => {
        console.log(data);
        this.formTypes = data.body.results;
        // this.displayFormType(this.formTypes[0]);
        if(localStorage.getItem('type')) {
          this.type = this.formTypes.find(type => type.id === +localStorage.getItem('type'));
          this.displayFormType(this.type);
          console.log(this.type);
          console.log(localStorage.getItem('type'));
        } else {
          if(!this.type) {
            this.type = this.formTypes[0];
            this.displayFormType(this.type);
            localStorage.setItem('type', this.type.id);
          }
        }
        this.typesService.setFormTypes(data.body.results);
    });
  }

  displayFormType(type) {
    console.log(type);
    if (!type || !type.fields_css) {
      return;
    }
  
    if (type.id) {
      this.newType = false;
      this.router.navigate(['pages/forms']);
    }

    this.type = type;
    localStorage.setItem('type', this.type.id);
      console.log(type);
      this.configureFields(type);
      this.fields = type.fields_css;
      this.info = type.info;
      if (type.info) {
        this.showInfo = true;
      }
    
  }

  verifyFormFields(type) {
    console.log(type, this.type, this.newFormType);

    const updateFormTypeAndDisplay = () => {
        this.updateFormType();
    };

    const resetToNewFormType = () => {
        this.type = this.formTypes.find(ftype => ftype.id === this.newFormType);
        if (this.type) {
            this.selectedFields = this.type.fields_css.map(type => type.id);
            this.fields = this.type.fields_css;
            console.log(this.fields);
            this.isSubmitEnabled = false;
            this.displayFormType(this.type);
        }
    };

    if (this.type && this.isSubmitEnabled) {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('alert'),
            "Do you want to save your changes?"
        );
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                updateFormTypeAndDisplay();
            } else {
                resetToNewFormType();
            }
        });
    } else {
        resetToNewFormType();
    }
}

  deepObjectComparison(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  
  configureFields(type) {
    console.log(type);
    const nameT = type.name_t ? type.name_t[this.langString] : type.name;
    const name = type.name
    this.formName = nameT ? nameT : name;
    this.bgImg = type.image;
    this.selectedFields = [];
    if(type.fields_css.length) {
      type.fields_css.forEach(element => {
        this.selectedFields.push(element.field.id)
      });
    }
    // this.selectedFields = type.fields_css.map(item => item.id);
    console.log(this.selectedFields);
    if(this.selectedFields.length === 0) {
      this.fields = [];
    }
    if(this.bgImg) {
      this.showImg = true;
      this.image = this.bgImg;
      this.imgPath = this.bgImg.file
    }
    // if(type.info && type.info.trim() !== '') {
    //   this.info = type.info
    // } else {
    //   this.info = this.infoPlaceholder;
    // }
  }

  validateInputType(type) {
    const inputTypes = this.inputTypes;
    if(type === 'number') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'multiselect'));
    }
    if(type === 'string') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'range'));
    }
    if(type === 'datetime') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'multiselect'));
    }
    if(type === 'entity') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'range'));
    }
    if(type === 'boolean') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'range' || type === 'multiselect'));
    }
  }

  public initaddEditAttributeForm(): void {
    this.addEditAttributeForm = this.formBuilder.group({
        name_t: [this.selectedAttribute ? this.selectedAttribute.name : null, Validators.required],
        color: [this.selectedAttribute ? this.selectedAttribute.color : '#000000', Validators.required],
        icon: [this.selectedAttribute ? this.selectedAttribute.icon ? this.selectedAttribute.icon.id : null : null, Validators.required],
        entity: [this.selectedAttribute ? this.selectedAttribute.entity ? this.selectedAttribute.entity.id : null : null, Validators.required],
        input_type: [this.selectedAttribute ? this.selectedAttribute.inputType : 'single', Validators.required],
        unit: [this.selectedAttribute ? this.selectedAttribute.unit : null, Validators.required],
        value_type: [this.selectedAttribute ? this.selectedAttribute.valueType : 'number', Validators.required],
    });
    if(this.selectedAttribute) {
      this.validateInputType(this.selectedAttribute.inputType);
    }
  }

  addEditAttribute(attribute?:any) {
    console.log(attribute);
    this.addEditAttributeForm.reset();
    this.addEditAttributeForm.clearValidators();
    // this.imgPath = '';
    this.icon = null;
    if(this.selectedAttribute) {
      this.validateInputType(this.selectedAttribute.inputType);
      this.icon = this.selectedAttribute.icon
      this.imgPath = this.selectedAttribute.icon ? this.selectedAttribute.icon.file : '';
    }
    this.selectedAttribute = attribute;
    this.initaddEditAttributeForm();
    this.addEditAttributePopup.showModal();
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  addEditTranslation() {
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.selectedAttribute);
    if(name === null || name === '' || type.nameT === null) {
        this.translation = null
        this.addEditTranslationsModal.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
            }
        });
    }
  }

  addEditFieldTranslation() {
    const name = this.nameInput1.nativeElement.value;
    console.log(name);
    const type: any = this.type;
    if(name === null || name === '' || !type[this.selectedEditField]) {
        this.fieldTranslation = null
        this.addEditFieldTranslationPopup.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type[this.selectedEditField]['id']) {
                this.fieldTranslation = translation;
                console.log(this.fieldTranslation);
                this.addEditFieldTranslationPopup.showModal();
            }
        });
    }
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.addEditAttributeForm.controls['name_t'].setValue(translation[this.langString]);
    this.nameT = translation;
    this.isSubmitEnabled = true;
    this.addEditTranslationsModal.hideModal();
}

onAddUpdateFieldTranslation(translation) {
    console.log(translation);
    this.fieldTranslation = translation;
    if(this.selectedEditField === 'info') {
      this.type.info = translation;
      this.info = translation;
      this.showInfo = true;
      console.log(this.type);
    } else {
      this.type.name_t = translation;
      this.type.name = translation[this.langString];
      this.formName = translation[this.langString];
      console.log(this.type);
    }
    this.fieldTranslation = translation;
    this.isSubmitEnabled = true;
    this.addEditFieldTranslationPopup.hideModal();
}

  submitAttribute() {
    if(!this.nameT) {
      this.translatedNames.filter(trans => {
        if(trans.id === this.selectedAttribute.nameT) {
          this.nameT = trans;
        }
      });
    }
    console.log(this.nameT);
    const body = {
      name_t: this.nameT.id,
      color: this.addEditAttributeForm.value.color,
      icon: this.addEditAttributeForm.value.icon,
      value_type: this.addEditAttributeForm.value.value_type,
      unit: this.addEditAttributeForm.value.unit,
      input_type: this.addEditAttributeForm.value.input_type,
      entity: this.addEditAttributeForm.value.entity
    }
   
    if(this.selectedAttribute) {
      this.typeService.updateAttribute(this.selectedAttribute.id, body).subscribe(() => {
        this.globalRegistry.reloadAttributes();
        this.toastrService.success(this.translateService.instant('attribute.formTypeUpdatedSuccessfully'))
        this.addEditAttributePopup.hideModal();
        this.nameT = null
        this.icon = null;
        this.selectedAttribute = null;
        this.addEditAttributeForm.reset();
        this.addEditAttributeForm.clearValidators();
        this.initaddEditAttributeForm();
        setTimeout(() => {
          const itemIds = this.type.fields_css.map(item => item.type);
          this.fields = this.globalRegistry.systemData.attributes.filter(item => itemIds.includes(item.id))
            .map(item => this.camelToSnake(item));
        }, 1000)
      })
    } else {
      this.typeService.createAttribute(body).subscribe(() => {
        this.globalRegistry.reloadAttributes();
        this.toastrService.success(this.translateService.instant('attribute.formTypeCreatedSuccessfully'))
        this.addEditAttributePopup.hideModal();
        this.nameT = null;
        this.icon = null;
        this.selectedAttribute = null;
        this.addEditAttributeForm.reset();
        this.addEditAttributeForm.clearValidators();
        this.initaddEditAttributeForm();
        setTimeout(() => {
          const itemIds = this.type.fields_css.map(item => item.type);
          this.fields = this.globalRegistry.systemData.attributes.filter(item => itemIds.includes(item.id))
            .map(item => this.camelToSnake(item));
        }, 1000)
      })   
    }
  }

  onDragStart(event: DragEvent, index: number): void {
    // Store the index of the dragged item
    this.dragItemIndex = index;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragEnter(event: DragEvent): void {
    event.preventDefault();
  }

  calculateOrder(item: any): number {
    if (this.defaultSize === 'pc') {
      return item?.pc?.pos || 1000;
    } else if (this.defaultSize === 'tab') {
      return item?.tablet?.pos || 1000;
    } else {
      return item?.mobile?.pos || 1000;
    }
  }

  public deleteFormType(formId) {
    console.log(formId);
    // return;
    this.typesService.deleteFormType(formId).subscribe(data => {
      console.log(data);
      this.toastrService.success('Form type deleted successfully!');
      this.type = null;
      this.selectedField = null;
      this.fields = [];
      this.getFormTypes();
    }, (error) => {
      console.log(error);
      this.toastrService.error('This form has been used for input data. \n Please remove all input data references.');
    })
  }

  onDrop(event: CdkDragDrop<any>, type: string): void {
    if(this.type.locked) {
      return;
    }
    if (event.previousContainer === event.container) {
      const prevIndex = event.previousIndex;
      const currentIndex = event.currentIndex;
      if (prevIndex !== currentIndex) {
        [this.fields[prevIndex], this.fields[currentIndex]] = [this.fields[currentIndex], this.fields[prevIndex]];
  
        // Ensure each item has a 'pos' property
        this.fields.forEach((item, index) => {
          if (!item[type]) {
            item[type] = {}; // Create the 'type' object if it doesn't exist
          }
          item[type].pos = index + 1;
        });
        this.isSubmitEnabled = true;
        console.log(this.fields);
      }
    }
  }
  
  editImage() {
    if(this.type.locked) {
      return
    }
    this.selectedEditField = 'image';
    this.image = this.type.image;
    if(this.bgImg) {
      this.imgPath = this.bgImg.file
    } else {
      this.imgPath = '';
    }
  }

  edit(field) {
    if(this.type.locked) {
      return
    }
    this.selectedEditField = field;
    if(field === 'name_t') {
      this.selectedEditFieldLabel = 'Name'
    } else {
      this.selectedEditFieldLabel = 'Info'
    }
    console.log(this.type[field]);
    this.editFieldModal.showModal();
  }
  
  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
        return translation
      }
  }

  handleFileInput($event) {
    console.log($event)
  }

  addImage(ev) {
    console.log(ev)
    const imgObj = {
        id: ev.value.id,
        file: ev.value.file
    }
    this.image = imgObj;
    this.imgPath = imgObj.file;
  }
  
  deleteNoteImage() {
    this.image = null;
    this.bgImg = null;
    this.type.image = null;
  }
  
  public getImages() {
    const url = this.companyService.getUrl('images/')
    this.cacheService.delete(url);
    this.companyService.getCompanyImages().subscribe((response): void => {
        this.images = response.body["results"];
        console.log(this.images);
        this.nextImgToken = response.body.next
        ? this.globalRegistry.getQueryStringParams(response.body.next.split("?")[1])
        : null;
        if(this.nextImgToken) this.getMoreImg = true
      })
    }
  
    onScroll(event: any) {
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        console.log("End");
        this.scrolledDownImages();
      }
    }
  
    public deleteImage(imageId: number): void {
      console.log(imageId);
      this.subscriptions.push(this.companyService.deleteCompanyImage(imageId).subscribe(data => {
          console.log(data);
          this.images = [];
          this.getImages();
      }))
    }
  
    scrolledDownImages() {
      const url = this.companyService.getUrl('images/');
      console.log('scrolled');
      if(this.getMoreImg) {
      this.nextImgToken &&
        this.subscriptions.push(this.companyService.getCompanyImages(this.nextImgToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.nextImgToken.limit+'&offset='+this.nextImgToken.offset);
          console.log(data);
          if(this.images) {
            this.images = [...this.images, ...data.body.results];
          } else {
            this.images = data.body.results;
          }
          if(data.body.next == null) {
            this.getMoreImg = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextImgToken.offset != params['offset']) {
            this.nextImgToken = {limit: params['limit'], offset: params['offset']};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
    }
  
    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }

    public selectUnit(unit?: UnitModel, parent?:  UnitModel, genre?: string): void {
      if(unit?.locked) {
        return;
    }
      this.currentUnit = unit;
      this.parentUnit = parent ? parent : null;
      if(genre) {
        console.log(genre);
        this.units = this.globalRegistry.systemData.units.filter(unit => {
          return unit.genre == genre
        });
      }
      this.initunitForm();
      this.addEditUnitModal.showModal();
    }
    
    public onDeleteUnit(unit: UnitModel): void {
      if(unit?.locked) {
        return;
    }
     
      this.confirmModalService.openConfirmModal(
          this.translateService.instant('confirmDelete'),
          this.translateService.instant('units.deleteUnitConfirmMessage')
      );
      this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
          if (confirmed) {
              this.deleteUnit(unit.id);
          }
      });
    }
    
    public onUnitAction(unitId: number): void {
      this.selectedUnitId = unitId;
      this.addEditUnitModal.hideModal();
    }

    public submit(): void {

      if(this.unitForm.dirty) {
          const unit: UnitInterface = {
              name: this.unitForm.controls['name'].value,
              shortName: this.unitForm.controls['shortName'].value,
              genre: this.unitForm.controls['genre'].value
          };
    
          if(typeof this.unitForm.controls['parent'].value !== 'string') {
              unit.parent = this.unitForm.controls['parent'].value === 'new' ?
                  null :
                  this.unitForm.controls['parent'].value;
          }
    
          if(this.currentUnit) {
              this.updateUnit({...{id: this.currentUnit.id}, ...unit});
              this.globalRegistry.reloadUnits();
          }
          else {
              this.addUnit(unit);
              this.globalRegistry.reloadUnits();
          }
          this.getUnits()
      }
    }
   
    public getUnits() {
      this.subscriptions.push(this.unitService.getUnits().subscribe(data => {
        this.units = data.model;
      }))
    }
    
    private initunitForm(): void {
      this.unitForm = this.formBuilder.group({
          name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(3),
                  Validators.maxLength(100)
              ])
          ],
          shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(1),
                  Validators.maxLength(100)
              ])
          ],
          parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
                  Validators.required
              ])
          ],
          genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
                  Validators.required
              ])
          ]
      });
    }
    
    private addUnit(unit: UnitInterface): void {
      this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
          this.toastrService.success(this.translateService.instant('units.unitAddSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(response.model.id);
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.unitAddError'));
      });
    }
    
    private updateUnit(unit: UnitInterface): void {
      this.unitService.updateUnit(unit).subscribe((): void => {
          this.toastrService.success(this.translateService.instant('units.unitUpdateSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(this.currentUnit.id);
    
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.UnitUpdateError'));
      });
    }
    
    public deleteUnit(unitId: number): void {
      this.unitService.deleteUnit(unitId).subscribe((): void => {
          this.toastrService.success(this.translateService.instant('unit.unitDeleteSuccessfull'));
          this.globalRegistry.reloadUnits();
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('unit.UnitDeleteError'));
      });
    }

    deleteAttribute(ev) {
      this.typeService.deleteAttribute(ev).subscribe(()=>{
        this.globalRegistry.reloadAttributes();
        this.addEditAttributePopup.hideModal();
        this.nameT = null;
        this.toastrService.success(this.translateService.instant('attribute.formTypeCreatedSuccessfully'))
      }, err => {
        this.toastrService.error(err.error.results.error);
      });
    }
    
    public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
      const control: any = this.unitForm.controls[controlName];
      if (!control) {
          return false;
      }
    
      const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
      if (window.innerWidth < 768) {
        // Set the selected tab index to 1 for smaller screens (Mobile tab)
        this.selectedTabIndex = 1;
      } else {
        // Set the selected tab index to 0 for larger screens (PC tab)
        this.selectedTabIndex = 0;
      }
    }

    updateSelectedTabIndex(): void {
      if (window.innerWidth < 768) {
        // Set the selected tab index to 1 for smaller screens (Mobile tab)
        this.selectedTabIndex = 1;
      } else {
        // Set the selected tab index to 0 for larger screens (PC tab)
        this.selectedTabIndex = 0;
      }
    }

    async removeFormFieldType(formFieldTypeId: number, field: any) {
      console.log(formFieldTypeId);
      this.selectedFields = this.selectedFields.filter(id => id !== field.id);
      console.log(this.selectedFields)
      this.isSubmitEnabled = true;
      this.collectAttributes(this.selectedFields);
      this.cdr.detectChanges();
  }  

    private deleteFormFieldType(field: any): Promise<void> {
      return new Promise<void>(() => {
        if(field.used === false) {
          // this.confirmDeleteFormFieldType(field.id);
          this.removeFormFieldTypes = this.removeFormFieldTypes.filter(rfield => rfield.id !== field.id);
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
          return
        } else 
        this.typeService.getFormFieldTypePresence(field.id).subscribe(data => {
          console.log(data);
          if (data.body.results.forms > 0) {
            this.confirmModalService.openConfirmModal(
              this.translateService.instant('confirmDelete'),
              'The Field "' +field.field.name+ '" contains '+data.body.results.forms+' instances of input data that will be removed ! Please confirm this critical operation ! '
              // this.translateService.instant('forms.deleteFormFieldMessage')
            );
    
            this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
              if (confirmed) {
                // this.updateFormType()
                //   .then(() => resolve())
                //   .catch(error => reject(error));
                this.fields = this.fields.filter(item => item.id !== field.id);
                this.type.fields_css = this.fields;
                this.removeFormFieldTypes = this.removeFormFieldTypes.filter(rfield => rfield.id !== field.id);
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
                this.updateFormType();
              } else {
                let copiedSelectedFields = this.selectedFields.slice();
                this.selectedFields = [];
                this.selectedFields = copiedSelectedFields;
                console.log(this.selectedFields);
                this.fields.push(field);
                this.selectedFields.push(field.field.id);
                this.removeFormFieldTypes = this.removeFormFieldTypes.filter(rfield => rfield.id !== field.id);
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
                console.log(this.fields);
                return
              }
            });
          } else {
            // this.confirmDeleteFormFieldType(formFieldTypeId)
            //   .then(() => resolve())
            //   .catch(error => reject(error));
            this.removeFormFieldTypes = this.removeFormFieldTypes.filter(rfield => rfield.id !== field.id);
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
            this.fields = this.fields.filter(item => item.id !== field.id);
            this.type.fields_css = this.fields
            this.updateFormType();
          }
        });
      });
    }    
    
    public confirmDeleteFormFieldType(formFieldTypeId: number): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        this.typeService.deleteFormFieldType(formFieldTypeId).subscribe(
          data => {
            this.selectedFields = this.selectedFields.filter(item => item !== formFieldTypeId);
            this.isSubmitEnabled = true;
            this.removeFormFieldTypes = this.removeFormFieldTypes.filter(rfield => rfield.id !== formFieldTypeId);
                console.log(this.removeFormFieldTypes);
                if(this.removeFormFieldTypes.length === 0) {
                  this.isSubmitEnabled = false;
                }
            resolve();
          },
          error => {
            reject(error);
          }
        );
      });
    }    

  ngOnDestroy() {
    this.mapService.showMap();
  }

}
