import { PartyService } from './../../../../../core/services/api/company/party.service';
import { CropTypeModel } from '../../../../../core/models/type/crop-type.model';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { MapHelper } from './../../../../../core/heplers/map.helper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { CropInterface } from '../../../../../core/services/api/crop/data/crop.interface';
import { CropService } from '../../../../../core/services/api/crop/crop.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LotService } from '../../../../../core/services/api/farm/lot.service';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
    templateUrl: './culture-edit-container.component.html',
    styleUrls: ['./culture-edit-container.component.scss']
})
export class CultureEditContainerComponent implements OnDestroy, OnInit {

    public lotId: number;
    public deletedCropId: number = null;
    public isNoCrop = false;
    public lot: LotModel;

    /**
     * @todo
     * This should be replaced with model, CropModel
     */
    public crop: CropInterface = {
        cropType: null,
        start: null,
        progress: null,
        end: null,
        distPlant: null,
        distRow: null,
        distUnit: null
    };

    private subscriptions: Array<Subscription> = [];

    constructor(public globalRegistry: GlobalRegistryService,
                public navService: NavService,
                public cropService: CropService,
                public lotService: LotService,
                public activatedRoute: ActivatedRoute,
                public toastr: ToastrService,
                public router: Router,
                public mapService: MapService,
                public translate: TranslateService,
                private stockService: StockService,
                private partyService: PartyService,
                private translateService: TranslateService,
                private toastyService: ToastrService) {
        this.activatedRoute.params.subscribe((params: Params): void => {
            this.lotId = parseInt(params['lotId'], 10);
            this.getExistingCultureData();
        });
    }

    public ngOnInit(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
        this.navService.editFarm.next(true);
        this.navService.submitFarm.next(false);
        this.subscriptions.push(this.navService.emitSubmit.subscribe((value: boolean) => {
            if (value) {
                this.submit();
            }
        }));

        this.subscriptions.push(this.navService.emitDelete.subscribe((value: boolean) => {
            if (value) {
                // this.deleteCurrentLot();
            }
        }));

        this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                this.router.navigate(['/pages/lot/edit/'+this.lotId]);
                this.navService.emitCancel.next(false);
            }
        });
    }

    public ngOnDestroy(): void {
        this.mapService.resetMap();
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.navService.editFarm.next(false);
    }

    public submit(): void {
        this.crop.lot = this.lotId;
        if (this.deletedCropId !== null && this.deletedCropId !== undefined) {
            // we have to delete this culture first, in case the user chose to delete the existing one
            this.cropService.deleteCrop(this.deletedCropId).subscribe((): void => {
                if(!this.isNoCrop) {
                    // if the user added a new crop. create it
                    this.updateOrCreateCrop();
                }
                else {
                    this.toastr.success(this.translate.instant('farm.crop.cropDeletedSuccessfully'));
                    this.router.navigate(['/pages/lots']);
                }
            });
        } else {
            this.updateOrCreateCrop();
        }
    }

    public getExistingCultureData(data?:any): void {
        this.lotService.getLot(this.lotId).subscribe((lot: ResponseModel<LotModel>): void => {
            this.lot = lot.model;
            this.initMap();
            if (this.lot.currentCrop) {
                this.crop = {
                    cropType: this.lot.currentCrop.cropType.id,
                    progress: this.lot.currentCrop.progress,
                    start: this.lot.currentCrop.start,
                    end: this.lot.currentCrop.end,
                    distPlant: this.lot.currentCrop.distPlant,
                    distRow: this.lot.currentCrop.distRow,
                    distUnit: this.lot.currentCrop.distUnit,
                    forecastCost: this.lot.currentCrop.forecastCost,
                    forecastDoc: this.lot.currentCrop.forecastDoc ? this.lot.currentCrop.forecastDoc : +data,
                    cropYield: this.lot.currentCrop.cropYield,
                    product: this.lot.currentCrop.product
                };
                
                if(data) {
                        this.cropService.updateCrop(this.lot.currentCrop.id, this.crop).subscribe(data1 => {
                            if(data1) {
                                this.router.navigate(['pages/lot/edit/'+this.lotId]);
                            }
                        })
                }
            }
        });
    }

    public updateCulture(culture: CropInterface): void {
        if (culture.cropType) {
            const cropTypeColor: string = this.globalRegistry.systemData.cropTypes.find((cropType: CropTypeModel): boolean =>
                    cropType.id === culture.cropType)?.color || 'yellow';

            this.mapService.mapPolygons.map((poligon: MapPolygonInterface): void => {
                poligon.fillColor = cropTypeColor;
                poligon.strokeColor = cropTypeColor;
            });
        }
        this.crop = culture;
    }

    public deleteCulture(): void {
        this.isNoCrop = true;
        // clear everything
        this.crop = {
            cropType: null,
            start: null,
            progress: null,
            end: null,
            distPlant: null,
            distRow: null,
            distUnit: null
        };
        // if we have an existing culture, we will keep the id for deletion on submit
        this.deletedCropId = this.lot.currentCrop ? this.lot.currentCrop.id : null;
    }

    public startNewCulture(): void {
        this.isNoCrop = false;
    }

    // public addStockIncome(stockIncome: StockIncomeInterface): void {
    //     this.stockService.addStockIncome({...{farm: this.lot.farm}, ...stockIncome}).subscribe((response: ResponseModel<{}>): void => {
    //         this.toastyService.success(this.translateService.instant('stock.stockIncomeSucces'));
    //         this.cropService.updateCrop(this.lot.currentCrop.id, {production: response.body['resource']}).subscribe((): void => {
    //             this.toastr.success(this.translate.instant('farm.crop.cropUpdatedSuccessfully'));
    //             this.getExistingCultureData();
    //         }, (): void => {
    //             this.toastr.error(this.translate.instant('farm.crop.errorUpdatingCrop'));
    //         });
    //     },
    //     (): void => {
    //         this.toastyService.error(this.translateService.instant('stock.stockIncomeError'));
    //     });
    // }

    // public addStockOutcome(stockOutcome: StockOutcomeInterface): void {
    //     this.stockService.addStockOutcome({...stockOutcome}).subscribe((response: ResponseModel<{}>): void => {
    //         this.toastyService.success(this.translateService.instant('stock.stockOutcomeSucces'));
    //         this.cropService.updateCrop(this.lot.currentCrop.id, {usedResources: response.body['resources']}).subscribe((): void => {
    //             this.toastr.success(this.translate.instant('farm.crop.cropUpdatedSuccessfully'));
    //             this.getExistingCultureData();
    //         }, (): void => {
    //             this.toastr.error(this.translate.instant('farm.crop.errorUpdatingCrop'));
    //         });
    //     },
    //     (): void => {
    //         this.toastyService.error(this.translateService.instant('stock.stockOutcomeError'));
    //     });
    // }

    public deleteParty(partyId: number): void {
        this.partyService.deleteParty(partyId).subscribe((): void => {
            this.globalRegistry.reloadParties();
            this.toastyService.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
        }, (): void => {
            this.toastyService.success(this.translateService.instant('people.party.errorDeletingParty'));
        });
    }

    private initMap(): void {
        this.mapService.resetMap();
        this.mapService.isEditMode = false;
        this.mapService.mapPolygons = [{
            points: MapHelper.convertToAGMPolygon(this.lot.coords.coordinates[0]),
            strokeColor: 'yellow',
            fillColor: 'yellow'
        }];
        this.mapService.centerMapOnPolygonIndex(0); //  center on farm polygon
    }

    private updateOrCreateCrop(): void {
        // we must decide which method to use
        const cropRequest: Observable<ResponseModel<{}>> = this.lot.currentCrop && (this.deletedCropId === null || this.deletedCropId === undefined)
            ? this.cropService.updateCrop(this.lot.currentCrop.id, this.crop)
            : this.cropService.beginCrop(this.crop);
        if (this.isNoCrop) {
            this.router.navigate(['/pages/lots']);
            return;
        }

        this.subscriptions.push(cropRequest.subscribe((): void => {
            this.toastr.success(this.translate.instant('farm.crop.cropUpdatedSuccessfully'));
            this.getExistingCultureData();
        }, (): void => {
            this.toastr.error(this.translate.instant('farm.crop.errorUpdatingCrop'));
        }, (): void => {
            this.globalRegistry.reloadLots((): void => {
                this.router.navigate(['/pages/lots']);
            });
        }));
    }
}
