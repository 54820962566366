import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UserModel } from '../../../../../core/models/user/user.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserInterface } from '../../../../../core/services/api/user/data/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../../core/services/api/auth/auth.service';
import { Subject } from 'rxjs';
import data from "../../../../../../assets/timezone/timezone.json";
import { UserService } from '../../../../../core/services/api/user/user.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { AddressModel } from '../../../../../core/models/company/address.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
    selector: 'fap-user-profile-layout',
    templateUrl: './fap_user-profile-layout.component.html',
    styleUrls: ['./fap_user-profile-layout.component.scss']
})
export class FapUserProfileLayoutComponent implements OnChanges, OnInit{

    @Input()
    public userInfo: UserModel;

    @Input()
    public isProfileOpen: boolean;

    @Input()
    public buttonColor: string;

    @Input()
    public accentColor: string;

    @Output()
    public updateUser: EventEmitter<UserInterface> = new EventEmitter();

    @Output()
    public updateProfilePicture: EventEmitter<FormData> = new EventEmitter();

    @Output()
    public resetPassword: EventEmitter<any> = new EventEmitter();

    @Output()
    public toggleProfileModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    public name = '';
    public role: number = null;
    public innerImage: string | ArrayBuffer = '';
    public profilePicture: File;

    public editProfileForm: UntypedFormGroup;

    private unsubscribe: Subject<any>;
    data;
    timezones;
    keyword;
    countDown;
    counter=60;
    tick=1000;
    public phoneChange = false;
    public emailChange = false;
    public phoneNumber = null;
    public email = null

    @Output()
    public deleteAddress: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @Output()
    public updatedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @ViewChild('addEditAddressModal')
    public addEditAddressModal: FapModalComponent;

    public selectedAddress: AddressModel;
    public phoneForm: UntypedFormGroup;
    public OTPForm: UntypedFormGroup;
    public emailForm: UntypedFormGroup;
    public fullAddress;
    public mediaUrl = environment.mediaUrl;
    public languages = [
      {key: 'en', value: 'English'},
      {key: 'ro', value: 'Română'},
      {key: 'fr', value: 'Français'},
      {key: 'ru', value: 'Русский'},
    ]

    constructor(private formBuilder: UntypedFormBuilder,
                public translate: TranslateService,
                private cdr: ChangeDetectorRef,
                public authService: AuthService,
                public userService: UserService,
                public companyService: CompanyService,
                public globalRegistry: GlobalRegistryService,
                public toastr: ToastrService,
                public router: Router,
                public navServices: NavService
                ) {
        //
    }

    public ngOnInit(): void {
        this.initEditProfileForm();
        this.initPhoneForm();
        this.initEmailForm();
        this.initOTPForm();
        this.data = data.data;
        this.formatData();
        console.log(this.userInfo);
    }

    public getAddress(addressId) {
      this.companyService.getSingleAddress(addressId).subscribe(data => {
        console.log(data);
        this.fullAddress = data.body.results;
      })
    }

    formatData() {
        console.log(this.data);
        this.timezones = [];
        this.data.forEach(item => {
          if (!this.timezones.filter(timezone=>timezone.country===item.Country).length) {
            this.timezones.push({ country: item.Country, timezones: [] });
          }
          this.timezones.forEach(country => {
            if (country.country === item.Country) {
              country.timezones.push(this.getTimzoneObject(item));
            }
          });
        });
      }
    
      getTimzoneObject(obj) {
        const timeZoneString = this.getTimeZoneString(obj);
        // console.log(obj);
        const data = {
          country: obj.Country,
          timeZoneId: obj.TimeZoneID,
          timeZoneString: timeZoneString,
          shortName: obj.ShortName,
          search:obj.Country+" "+timeZoneString
        };
        return data;
      }
    
      getTimeZoneString(timezoneData): string {
        let timezonestring = null;
        let dlsOffset = null;
        const _newDate = new Date();
        const dtNowObject = _newDate.getTime();
        if (timezoneData.DayLightSavings.length > 0) {
          timezoneData.DayLightSavings.forEach(dlsObj => {
            if (
              dtNowObject >= new Date(dlsObj.StartTime).getTime() &&
              dtNowObject < new Date(dlsObj.EndTime).getTime()
            ) {
              dlsOffset = dlsObj.Offset;
            }
          });
        } else {
          dlsOffset = null;
        }
        timezonestring = this.getRegionName(
          timezoneData.Region,
          timezoneData.TimeZone,
          dlsOffset
        );
        // timezonestring = objRegion + ' (' + objGMTorDST + objSign + parseFloat(dlsOffset).toFixed(2) + ')';
        return timezonestring;
      }
    
      getRegionName(region, timeZone, dls) {
        let dstString = "";
        if (dls !== null) {
          timeZone = dls;
          dstString = " [DST]";
        }
        return (
          region + " (GMT " + this.changeTimeZoneFormat(timeZone) + ")" + dstString
        );
      }
    
      changeTimeZoneFormat(timeZone): string {
        let formatString = "",
          hours = Math.floor(timeZone);
        if (timeZone < 0) {
          hours = Math.ceil(timeZone);
        }
        let minutes: any = (Math.abs(timeZone) % 1) * 60;
        if (minutes === 0) {
          minutes = "00";
          // minutes = 0;
        }
        if (hours > 0) {
          formatString += "+";
        }
        return formatString + hours + ":" + minutes;
      }
    
      onChange(event){
        console.log(event.value);
      }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'userInfo')) {
            this.name = this.userInfo.fullName;
            this.role = this.userInfo.profile.role;
            this.innerImage = this.mediaUrl + this.userInfo.profile.picture;
            if(this.userInfo.profile.addressId) {
              this.getAddress(this.userInfo.profile.addressId)
            }
            // if(this.userInfo.profile.picture !== undefined && this.userInfo.profile.picture !== null
            //     && this.userInfo.profile.picture !== '') {
            //         this.innerImage = this.userInfo.profile.picture;
            // }
            this.initEditProfileForm();
            // this.globalRegistry.reloadUserData();
        }
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.editProfileForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public initEditProfileForm(): void {
        this.editProfileForm = this.formBuilder.group({
            firstName: [this.userInfo && this.userInfo.firstName ? this.userInfo.first_name ? this.userInfo.first_name : this.userInfo.firstName : '', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            lastName: [this.userInfo && this.userInfo.lastName ? this.userInfo.last_name ? this.userInfo.last_name : this.userInfo.lastName : '', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            email: [this.userInfo && this.userInfo.email ? this.userInfo.email : '', Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.minLength(3),
                    Validators.maxLength(320)
                ]),
            ],
            address_id: [this.userInfo && this.userInfo.profile.addressId ? this.userInfo.profile.addressId : '', Validators.compose([
            ]),
            ],
            phone: [this.userInfo && this.userInfo.profile.phone ? this.userInfo.profile.phone : '', Validators.compose([
            ])
            ],
            job: [this.userInfo && this.userInfo.profile.job ? this.userInfo.profile.job : '', Validators.compose([
            ]),
            ],
            language: [this.userInfo && this.userInfo.profile.data.language ? this.userInfo.profile.data.language : 'en', Validators.compose([
            ]),
            ],
            timezone: [this.userInfo && this.userInfo.profile.data.timezone ? this.userInfo.profile.data.timezone : '', Validators.compose([
            ])
            ],
        });
    }

    public submit(): void {
        // console.log(this.editProfileForm.value);
        // return;
        if(this.editProfileForm.invalid) {
            Object.keys(this.editProfileForm.controls).forEach((controlName: string) =>
                this.editProfileForm.controls[controlName].markAsTouched()
            );
            return;
        }
        // let address_id = this.editProfileForm.controls['address_id'].value
        const body: UserInterface = {
            id: this.userInfo.id,
            firstName: this.editProfileForm.controls['firstName'].value,
            lastName: this.editProfileForm.controls['lastName'].value,
            email: this.editProfileForm.controls['email'].value,
            profile: {
              address_id: this.editProfileForm.controls['address_id'].value,
                phone: this.editProfileForm.controls['phone'].value,
                job: this.editProfileForm.controls['job'].value,
                data: {
                    allowed: this.userInfo.profile.data.allowed,
                    timezone: this.editProfileForm.controls['timezone'].value,
                    language: this.editProfileForm.controls['language'].value
                }
            },
        };
        console.log('this.profilePicture', this.profilePicture);
        if(this.profilePicture) {
            const picture: FormData = new FormData();
            picture.append('picture', this.profilePicture);
            this.updateProfilePicture.emit(picture);
            console.log(picture, 'picture');
        }

        this.updateUser.emit(body);
        this.emitToggleProfileDialog();
        this.globalRegistry.reloadUserData();
        localStorage.setItem('language', this.editProfileForm.controls['language'].value);
        this.translate.use(this.editProfileForm.controls['language'].value);
        this.navServices.setCurrentLanguage(this.editProfileForm.controls['language'].value);
    }

    public imageChanged(file: File): void {
        this.profilePicture = file;
    }

    public passwordReset(): void {
      this.authService.changePassword({email: this.userInfo.email}).subscribe(() => {
        this.resetPassword.emit();
    }, err => {
      this.toastr.error(err.error.results.error);
    });
        // const body: RegisterInterface = {
        //     firstName: this.editProfileForm.controls['firstName'].value,
        //     lastName: this.editProfileForm.controls['lastName'].value,
        //     email: this.editProfileForm.controls['email'].value,
        // };
        // this.authService.resetPassword(body).pipe(
        //     tap(() => {
        //         this.resetPassword.emit();
        //     }),
        //     catchError((error: HttpErrorResponse) => {
        //         return throwError(error);
        //     }),
        //     takeUntil(this.unsubscribe),
        //     finalize(() => {
        //         this.cdr.markForCheck();
        //     })
        // );
    }

    public emitToggleProfileDialog(): void {
        this.isProfileOpen = !this.isProfileOpen;
        this.toggleProfileModal.emit(this.isProfileOpen);
    }

    public addAddressModal(): void {
      this.selectedAddress = null;
      this.addEditAddressModal.showModal();
      // this.globalRegistry.reloadAddresses();
  }

  public editAddress(address: AddressModel): void {
      this.selectedAddress = address;
      this.addEditAddressModal.showModal();
      // this.globalRegistry.reloadAddresses();
  }

  public initPhoneForm(): void {
    const formBody = {
        countryCode: ['', Validators.required
        ],
        phone: ['', Validators.required]
    }
    this.phoneForm = this.formBuilder.group(formBody);
}

public initEmailForm(): void {
  const formBody = {
      email: ['', Validators.required]
  }
  this.emailForm = this.formBuilder.group(formBody);
}

public initOTPForm(): void {
    const formBody = {
        pin: ['', Validators.required]
    }
    this.OTPForm = this.formBuilder.group(formBody);
}

  public showPhonePopup() {
    this.phoneChange = true;
    this.countDown = null;
    const elem = document.getElementById('custom_modal400');
    const parent = document.getElementById('parent');
    elem.classList.add('active');
    parent.classList.add('backdrop');
}

public showEmailPopup() {
  this.emailChange = true;
  this.countDown = null;
  const elem = document.getElementById('custom_modal402');
  const parent = document.getElementById('parent');
  elem.classList.add('active');
  parent.classList.add('backdrop');
}

public showOTPPopup() {
  this.counter = 60;
  const elem = document.getElementById('custom_modal401');
  const parent = document.getElementById('parent');
  elem.classList.add('active');
  parent.classList.add('backdrop');
  this.countDown = this.authService.getCounter(this.tick).subscribe(() => {
      this.counter--;
      if(this.counter == 0) {
        this.countDown = null
          this.tick = null
          elem.classList.remove('active');
          parent.classList.remove('backdrop');
          if(this.phoneChange) {
            this.phoneChange = false;
            this.phoneNumber = null;
            this.showPhonePopup();
          } else {
            this.emailChange = false;
            this.showEmailPopup();
          }
          return;
      }
  });
      
}

public getUserInfo() {
  this.userService.getUser().subscribe(data => {
    this.userInfo = data.model;
    this.initEditProfileForm();
  })
}

  closePhoneModal() {
    const elem = document.getElementById('custom_modal400');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.phoneChange = false;
  }

  closeEmailModal() {
    const elem = document.getElementById('custom_modal402');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.emailChange = false;
  }

  closeOTPModal() {
    this.countDown = null;
    this.counter = null;
    this.tick = null;
    this.phoneChange = false;
    this.emailChange = false;
    const elem = document.getElementById('custom_modal401');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
  }

  public getPin() {
    console.log(this.phoneForm.value);
    if(this.phoneForm.invalid) {
        Object.keys(this.phoneForm.controls).forEach((controlName: string) =>
            this.phoneForm.controls[controlName].markAsTouched()
        );
        this.toastr.error('Please select a valid country')
        return;
    }

    const body = {
        phone: '+' + this.phoneForm.controls['countryCode'].value + this.phoneForm.controls['phone'].value,
    };
    this.phoneNumber = body.phone
    console.log(body);
    this.authService.changePhone(body).subscribe(data => {
        console.log(data);
        if(data) {
            const elem = document.getElementById('custom_modal400');
            const parent = document.getElementById('parent');
            elem.classList.remove('active');
            parent.classList.remove('backdrop');
            this.showOTPPopup();
        }
    }, (err) => {
        console.log(err);
        this.toastr.error(err.error.results.error);
    })
}

public geEmailPin() {
  console.log(this.emailForm.value);
  if(this.emailForm.invalid) {
      Object.keys(this.emailForm.controls).forEach((controlName: string) =>
          this.emailForm.controls[controlName].markAsTouched()
      );
      return;
  }

  const body = {
      email: this.emailForm.controls['email'].value,
  };
  this.email = body.email
  console.log(body);
  this.authService.changeEmail(body).subscribe(data => {
      console.log(data);
      if(data) {
          const elem = document.getElementById('custom_modal402');
          const parent = document.getElementById('parent');
          elem.classList.remove('active');
          parent.classList.remove('backdrop');
          this.toastr.success(this.translate.instant('auth.register.mailSent'));
          this.showOTPPopup();
      }
  }, (err) => {
      console.log(err);
      this.toastr.error(err.error.results.error);
  })
}

public postPin() {
    console.log(this.OTPForm.value);
    if(this.OTPForm.invalid) {
        Object.keys(this.OTPForm.controls).forEach((controlName: string) =>
            this.OTPForm.controls[controlName].markAsTouched()
        );
        this.toastr.error('Please enter the pin sent to your phone')
        return;
    }

    const body = {
        pin: this.OTPForm.controls['pin'].value,
        phone: '+' + this.phoneForm.controls['countryCode'].value + this.phoneForm.controls['phone'].value,
        email: null
    };
    this.phoneNumber = body.phone;
    console.log(body);
    this.authService.confirmPhoneEmailPin(body).subscribe(data => {
        console.log(data);
        if(data.body) {
            const elem = document.getElementById('custom_modal401');
            const parent = document.getElementById('parent');
            elem.classList.remove('active');
            parent.classList.remove('backdrop');
            this.phoneChange = false
            this.closeOTPModal();
            this.getUserInfo();
        }
    }, (err) => {
        this.toastr.error('Incorrect pin');
        console.log(err)
        return;
    })
}

public postEmailPin() {
  console.log(this.OTPForm.value);
  if(this.OTPForm.invalid) {
      Object.keys(this.OTPForm.controls).forEach((controlName: string) =>
          this.OTPForm.controls[controlName].markAsTouched()
      );
      this.toastr.error('Please enter the pin sent to your email')
      return;
  }

  const body = {
      pin: this.OTPForm.controls['pin'].value,
      phone: null,
      email: this.emailForm.controls['email'].value
  };
  this.email = body.email;
  console.log(body);
  this.authService.confirmPhoneEmailPin(body).subscribe(data => {
      console.log(data);
      if(data.body) {
          const elem = document.getElementById('custom_modal402');
          const parent = document.getElementById('parent');
          elem.classList.remove('active');
          parent.classList.remove('backdrop');
          this.emailChange = false;
          this.closeOTPModal();
          this.getUserInfo();
      }
  }, (err) => {
      this.toastr.error('Incorrect pin');
      console.log(err)
      return;
  })
}

  public promptChangePhone() {
    this.showPhonePopup();
  }

  public promptChangeEmail() {
    this.showEmailPopup();
  }

  addressAdd(ev) {
    this.fullAddress = ev;
    this.addedAddress.emit(ev);
  }

  addressUpdate(ev) {
    this.fullAddress = ev;
    this.updatedAddress.emit(ev);
  }

  deleteAccount() {
    console.log('delete account prompt');
    this.router.navigate(['request-delete-account'])
  }

}
