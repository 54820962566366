import { FapBaseModel } from '../../base/fap-base-model';


export class WidgetTypeModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'name_t',
        'color',
        'icon'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public nameT: string;
    public color?: string;
    public icon?: any;
}
