import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { NotesContainerComponent } from './containers/notes-container/notes-container.component';
import { FapNotesLayoutComponent } from './components/fap_notes-layout/fap_notes-layout.component';
import { NotesComponent } from './notes.component';
import { FapNotesEditComponent } from './components/fap_notes-edit/fap_notes-edit.component';
import { NotesEditContainerComponent } from './containers/notes-edit-container/notes-edit-container.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FapNotesAttributeRowComponent } from './components/fap_notes-edit-attributes/fap_notes-edit-attributes.component';
import { PartialsModule } from '../../views-partials/partials.module';
import { FapNotesCardComponent } from './components/fap-notes-card/fap-notes-card.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule,NgxNativeDateModule } from '@angular-material-components/datetime-picker'
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        PartialsModule,
        InfiniteScrollModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
    ],
    declarations: [
        NotesContainerComponent,
        FapNotesLayoutComponent,
        NotesComponent,
        FapNotesEditComponent,
        NotesEditContainerComponent,
        FapNotesAttributeRowComponent,
        FapNotesCardComponent
    ]
})

export class NotesModule {

}
