import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PeopleGroupModel } from 'src/app/core/models/groups/people-group.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from '../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { UnitInterface } from '../../../../core/interfaces/unit/unit.interface';
import { PartyModel } from '../../../../core/models/party/party.model';
import { ResponseModel } from '../../../../core/models/response.model';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import { IngredientTypeModel } from '../../../../core/models/type/ingredient-type.model';
import { ResourceTypeModel } from '../../../../core/models/type/resource-type.model';
import { NewUnitModel } from '../../../../core/models/units/new-unit-model';
import { UnitModel } from '../../../../core/models/units/unit.model';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { PartyService } from '../../../../core/services/api/company/party.service';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { UnitService } from '../../../../core/services/api/units/unit.service';
import { FapModalComponent } from '../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NavService } from '../../../../shared/services/nav.service';
import { AttributeService } from '../../../../core/services/api/attribute/attribute.service';

@Component({
  selector: 'fap-add-edit-product',
  templateUrl: './fap-add-edit-product.component.html',
  styleUrls: ['./fap-add-edit-product.component.scss']
})
export class FapAddEditProductComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public productId;

  @Input() public produced = false;
  @Input() public from;

  @Input()
  public group: string;

  @Output()
  public addedProduct: EventEmitter<any> = new EventEmitter();

  @Output()
  public updatedProduct: EventEmitter<any> = new EventEmitter();

  @ViewChild('addImageModal')
  public addImageModal: FapModalComponent;
  @ViewChild('addResourceTypeModal')
  public addResourceTypeModal: FapModalComponent;
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  @ViewChild('addEditPartyModal')
  public addEditPartyModal: FapModalComponent;
  @ViewChild('addEditIngredientModal')
  public addEditIngredientModal: FapModalComponent;

  @ViewChild('imageRef') public imageRef;
  @ViewChild('rsp') public rsp;
  // @Output()
  // public createdIngredient: EventEmitter<IngredientModel> = new EventEmitter();

  // @Output()
  // public updatedIngredient: EventEmitter<IngredientModel> = new EventEmitter();

  // @Output()
  // public deleteIngredient: EventEmitter<number> = new EventEmitter();

  // @Output()
  // public deleteLocalIngredient: EventEmitter<number> = new EventEmitter();

  public product;
  public nextToken: { offset: number; limit: number, search: string; group?: string } = null;
  public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
  public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
  public imgWidth: number;
  public imgHeight: number;
  public orientation: string;
  
  public productForm: UntypedFormGroup;
  public localIngredients: Array<IngredientModel> = [];
  public localIngredientsId: Array<any> = [];
  public quantityPack: NewUnitModel;
  public packUnit: NewUnitModel;
  public ingredients: Array<IngredientModel> = [];
  public subscription: Subscription = new Subscription();
  public limit = 20;
  public searchKeyword = '';
  public getMore = true;
  public images = [];
  public mediaUrl = environment.mediaUrl;
  public image;
  public imageForm: UntypedFormGroup;
  public changedImage: boolean;
  public unitForm: UntypedFormGroup;
  public units: Array<UnitModel> = [];
  public selectedResource: ResourceTypeModel;
  public selectedUnit: UnitModel;
  public currentUnit: UnitModel;
  public parentUnit: UnitModel;
  public parentResource: ResourceTypeModel;
  public translatedNames:any = [];
  public langString: string;
  public selectedUnitId: number;
  public productImages = [];
  imageSrc: string;
  public parties: Array<PartyModel> = [];
  public selectedGroup: PeopleGroupModel;
  
  public selectedParty: PartyModel;
  public selectedIngredient: IngredientModel = null;
  public ingredientTypeMap: { [key: number]: IngredientTypeModel } = {};
  public getMoreParties = true;
  public partyToken: { limit: number; offset: number; search: string; } = null;

  public defaultPackUnit;
  public defaultUnit;
  public peopleGroup;
  @ViewChild('addEditGroupModal')
  public addEditGroupModal: FapModalComponent;
  @ViewChild('deleteGroupModal')
  public deleteGroupModal: FapModalComponent;
  @Output()
  public deleteGroup: EventEmitter<number> = new EventEmitter();
  public deleteGroupModalButtonPrimaryInterface: FapModalButtonInterface;
  public deleteGroupModalButtonSecondaryInterface: FapModalButtonInterface;
  public nextImgToken :{ offset: number; limit: number; group: string; } = null;
  public getMoreImg = true;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  public localAttributes: Array<IngredientModel> = [];
  public entityInfo: any;
  public resourceTypeId: any;
  public resourceType: any;
  public formType;
    public formFields;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public globalRegistry: GlobalRegistryService,
    public stockService: StockService,
    public toastr: ToastrService,
    public translate: TranslateService,
    public companyService: CompanyService,
    public unitService: UnitService,
    public typesService: TypesService,
    public navService: NavService,
    public partyService: PartyService,
    public translateService: TranslateService,
    public cacheService: CacheResolverService,
    public attributeService: AttributeService
  ) {}

  ngOnInit(): void {
    this.product = null;
    this.entityInfo = null;
    this.resourceType = null;
    this.units = this.globalRegistry.systemData.units;
    this.ingredients = this.globalRegistry.systemData.ingredients;
    this.units.filter(u => {
      if(u.nameT == 'UNIT_CN_PACKAGING') {
        u.children.forEach(element => {
          if(element.nameT == 'UNIT_N_ITEMS') {
            this.defaultPackUnit = element.id
          }
        });
      }
      if(u.nameT == 'UNIT_CN_WEIGHT') {
        u.children.forEach(element => {
          if(element.nameT == 'UNIT_N_KILOGRAM') {
            this.defaultUnit = element.id
          }
        });
      }
    });
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
        
    this.initProductForm();
    this.initunitForm();
    this.initImageForm();
    this.initImageModalButtons();
    this.initDeleteGroupModalButtons();
    // this.getIngredients();
  }

  public checkDefaultPartyExistence(producer) {
    
      const INpartyFromPresence = this.parties.some(p => p.id == producer)
      
      if(!INpartyFromPresence) {
        this.subscription.add(this.partyService.getParty(producer).subscribe(data => {
          this.parties.unshift(data.body.results);
        }))
      }
    
  }

  initImageForm() {
    this.imageForm = new UntypedFormGroup({
      file: new UntypedFormControl('', Validators.required),
      group: new UntypedFormControl('products', Validators.required),
      tags: new UntypedFormControl([], Validators.required),
      orientation: new UntypedFormControl('', Validators.required)
    });
  }

  filterParties(value) {
    console.log(value);
    this.subscription.add(this.partyService.getParties({search: value}).subscribe(data => {
      console.log(data);
      this.partyToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.parties = data.body.results;
      if(this.partyToken) this.getMoreParties = true
    }))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'produced') && this.produced) {
      console.log(this.produced);
    }
    if(Object.prototype.hasOwnProperty.call(changes, 'from') && this.from) {
      console.log(this.from);
    }
    if(Object.prototype.hasOwnProperty.call(changes, 'productId') && this.productId) {
      console.log(changes);
      console.log(this.productId);
      // this.entityInfo = null;
      if(this.productId == -1) {
        this.resetForm(this.productForm);
        this.packUnit = null;
        this.quantityPack = null;
        this.productImages = [];
        this.productId = null;
        this.product = null;
        this.localIngredients = [];
        this.productForm.get('images').setValue([]);
        this.productForm.get('cost').setValue(0);
        this.productForm.get('alert_quantity').setValue(1);
        this.productForm.get('ingredients').setValue([]);
        this.quantityPack = {unit: this.defaultUnit, value: 1};
        this.packUnit = {unit: this.defaultPackUnit, value: 1};
        this.localIngredientsId = [];
        this.produced = false;
      } else {
        this.resetForm(this.productForm);
        this.localIngredients = [];
        this.getProduct(this.productId);
      }
    }
    
  }

  public initImageModalButtons(): void {
    const _this: FapAddEditProductComponent = this;
  
    this.addImageModalButtonPrimaryInterface = {
        clickFunction(): void {
            _this.imageSubmit();
        },
        text: this.translate.instant('save')
    };
  
    this.addImageModalButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addImageModal.hideModal();
        },
        text: this.translate.instant('cancel')
    };
  }

  resetForm(form: UntypedFormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
  }

  public getProduct(productId) {
    const url = this.stockService.getUrl('products/')
    this.subscription.add(this.stockService.getProduct(productId).subscribe(data => {
      this.cacheService.delete(url+productId+'/')
      console.log(data);
      this.product = data.model;
      this.productImages = data.model.images;
      console.log(data.model.ingredients);
      this.getIngredients();
      this.getIngredient(data.model.ingredients.toString());
      this.initProductForm();
    }))
  }

  public getIngredient(list:string) {
    this.stockService.getIngredient(list).subscribe(data => {
      console.log(data);
      this.localIngredients = data.body.results;
    })
  }

  public addPartyModal(): void {
    this.addEditPartyModal.showModal();
    this.selectedParty = null;
  }

  public editParty(party: PartyModel): void {
    this.addEditPartyModal.showModal();
    this.selectedParty = party;
  }

  public addParty(ev) {
    this.parties.push(ev);
    this.productForm.controls['producer'].setValue(ev.id);
    this.addEditPartyModal.hideModal();
  }

  public addedOrUpdatedParty(ev) {
    this.productForm.controls['producer'].setValue(ev.id);
    this.addEditPartyModal.hideModal();
  }

  initProductForm() {
    const formBody = {
      name: [this.product ? this.product.name : null, Validators.compose([Validators.required])],
      code: [this.product ? this.product.code : null],
      unique_id: [this.product ? this.product.uniqueId : null],
      type: [this.product ? this.product.type : null, Validators.compose([Validators.required])],
      quantity: [this.product ? this.product.quantity : 1, Validators.compose([Validators.required])],
      producer: [this.product ? this.product.producer : null, Validators.compose([Validators.required])],
      prospect: [this.product ? this.product.prospect : null],
      images: [this.product ? this.product.images : []],
      pack_unit: [this.product ? this.product.packUnit : this.defaultPackUnit, Validators.compose([Validators.required])],
      quantity_pack: [this.product ? this.product.quantityPack : 1, Validators.compose([Validators.required])],
      unit: [this.product ? this.product.unit : this.defaultUnit, Validators.compose([Validators.required])],
      cost: [this.product ? this.product.cost : 0],
      produced: [this.product ? this.product.produced : false],
      ingredients: [this.product ? this.product.ingredients : []],
      alert_group: [this.product ? this.product.alertGroup : null],
      alert_quantity: [this.product ? this.product.alertQuantity : 1, Validators.compose([Validators.required])]
    }
    this.productForm = this.formBuilder.group(formBody);
    if(this.product) {
      this.quantityPack = {unit: this.product.unit ? this.product.unit : this.defaultUnit, value: this.product.quantity ? this.product.quantity : 1};
      this.packUnit = {unit: this.product.packUnit ? this.product.packUnit : this.defaultPackUnit, value: this.product.quantityPack ? this.product.quantityPack: 1};
      console.log(this.product);
      if(this.product.producer) {
        this.checkDefaultPartyExistence(this.product.producer)
      }
      if(this.product.produced == 0) {
        this.produced = true
      } else {
        this.produced = false;
      }
      if(this.product.type) {
        this.getResourceType(this.product.type)
      } else {
        this.entityInfo = null;
        this.resourceType = null;
      }
      this.selectIngredient(this.product.ingredients);
    } else {
      this.productForm.reset();
      this.productForm.controls['alert_quantity'].patchValue(1)
    }
  }

  public toggleProduced() {
    this.produced = !this.produced;
  }

  public getParties() {
    const url = this.partyService.getUrl('');
    this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword);
    this.subscription.add(this.partyService.getParties({ limit: this.limit, search: this.searchKeyword }).subscribe((data: ResponseModel<PartyModel[]>): void => {
      console.log(data);
      this.parties = data.body.results;
      this.partyToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.partyToken) this.getMore = true;
    }))
}

  public getPeopleGroups() {
    const url = this.companyService.getUrl('people_group/');
    this.cacheService.delete(url);
    this.subscription.add(
      this.companyService.getPeopleGroups().subscribe(data => {
        console.log(data);
        this.peopleGroup = data.body.results;
      })
    )
  }

  public editGroup(group: PeopleGroupModel): void {
    this.selectedGroup = group;
    this.addEditGroupModal.showModal();
}

public createdGroup(group: PeopleGroupModel): void {
  console.log(group);
  if (!this.peopleGroup.some(e => e.id === group.id)) {
    this.peopleGroup.push(group);
  } else {
    this.getPeopleGroups();
  }
  this.addEditGroupModal.hideModal();
}

public addGroupModal(): void {
    this.selectedGroup = null;
    this.addEditGroupModal.showModal();
}

private initDeleteGroupModalButtons(): void {
  this.deleteGroupModalButtonPrimaryInterface = {
      clickFunction: (): void => {
          this.deleteGroup.emit(this.selectedGroup.id);
          this.deleteGroupModal.hideModal();
          this.peopleGroup = this.peopleGroup.filter((groupId: number): boolean => groupId !== this.selectedGroup.id); },
      text: this.translateService.instant('yes')
  };
  this.deleteGroupModalButtonSecondaryInterface = {
      clickFunction: (): void => { this.deleteGroupModal.hideModal(); },
      text: this.translateService.instant('cancel')
  };
}

  public onDeleteParty(party: PartyModel): void {
    this.partyService.deleteParty(party.id).pipe(take(1)).subscribe(
      (): void => {
          this.getParties();
          // this.globalRegistryService.reloadParties();
          this.toastr.success(this.translate.instant('people.party.partyDeletedSuccessfully'));
      }, (): void => {
          this.toastr.success(this.translate.instant('people.party.errorDeletingParty'));
      }
    );
  }

  public selectResourceType(resource?: ResourceTypeModel, parent?:  ResourceTypeModel): void {
    this.selectedResource = resource;
    console.log(this.selectedResource)
    this.parentResource = parent ? parent : null;
    // this.globalRegistry.reloadResourceTypes();
    this.addResourceTypeModal.showModal();
  }

  public onResourceTypeAction(resourceTypeId: number): void {
    this.productForm.controls['type'].setValue(resourceTypeId);
    this.globalRegistry.systemData.resourceTypes = [];
    this.globalRegistry.reloadResourceTypes();
    this.getTranslations();
    this.addResourceTypeModal.hideModal();
    this.rsp.close();
    this.getResourceType(resourceTypeId);
  }

  public getFormType(formTypeId) {
    this.typesService.getFormTypeById(formTypeId).subscribe(data => {
      this.entityInfo = data.body.results;
      console.log(this.entityInfo);
    })
  }

  public getResourceType(type) {
    if(this.globalRegistry.systemData.resourceTypes.length) {
      console.log(this.globalRegistry.systemData.resourceTypes)
      const resourceType = this.globalRegistry.systemData.resourceTypes.map(obj => this.findObjectById(obj, type)).find(Boolean);
      console.log(resourceType);
      this.resourceTypeId = resourceType.id;
      this.resourceType = resourceType;
      if(resourceType.form_type) {
        this.getFormType(resourceType.form_type);
      } else {
        this.entityInfo = null;
        this.resourceType = null;
      }
    }
  }

  findObjectById(obj, desiredId) {
    if (obj.id === desiredId) {
      return obj;
    }
  
    if (obj.children && obj.children.length > 0) {
      for (const child of obj.children) {
        const foundObject = this.findObjectById(child, desiredId);
        if (foundObject) {
          return foundObject;
        }
      }
    }
  
    return null;
  }

  public deleteResourceType(resourceId?: number): void {
    console.log(resourceId);
    this.typesService.deleteResourceType(resourceId).subscribe(data => {
      console.log(data);
      this.globalRegistry.reloadResourceTypes();
    });
  }

public ifControlHasError(controlName: string, validationType: string): boolean {
  const control: any = this.productForm.controls[controlName];
  if (!control) {
      return false;
  }

  const result: boolean =
      control.hasError(validationType) &&
      (control.dirty || control.touched);
  return result;
  }

  public submitProduct() {
    if(this.quantityPack) {
      this.productForm.get('unit').setValue(this.quantityPack.unit);
      this.productForm.get('quantity').setValue(this.quantityPack.value);
  } 

  if(this.packUnit) {
      this.productForm.get('quantity_pack').setValue(this.packUnit.value);
      this.productForm.get('pack_unit').setValue(this.packUnit.unit);
  }

  let produced;
  if(this.produced == true) {
    produced = 1
  } else {
    produced = 0
  }
  this.productForm.get('produced').setValue(produced);

  if(this.productForm.get('name').value == null || this.productForm.get('name').value == '') {
    this.toastr.error('Please enter a name');
    return;
  }

  if(this.productForm.get('type').value == null) {
    this.toastr.error('Please select a type');
    return;
  }

  if(this.productForm.get('quantity_pack').value == null || this.productForm.get('pack_unit').value == null) {
    this.toastr.error('Please input valid packaging details');
    return;
  }

  if(this.productForm.get('unit').value == null || this.productForm.get('quantity').value == null) {
    this.toastr.error('Please input valid quantity details');
    return;
  }

  if(this.productForm.get('producer').value == null) {
    this.toastr.error('Please select the producer');
    return;
  }
 
  console.log(this.productForm.value);
  console.log(this.product);
  // return
  if(this.product) {
    this.stockService.updateProduct(this.product.id, this.productForm.value).subscribe(data => {
      console.log(data);
      this.updatedProduct.emit(data.model);
      this.toastr.success('Product updated successfully')
      // this.product = null;
      this.productImages = [];
      // this.productForm.reset();
      this.productForm.clearValidators();
      this.initProductForm();
          },
          error => {
            this.toastr.error(error.error.results.error)
          }
          )
          this.updateAttributes();
  } else {
    this.stockService.addProduct(this.productForm.value).subscribe(data => {
      console.log(data);
      this.addedProduct.emit(data.body.results);
      this.toastr.success('Product created successfully')
      this.product = null;
      this.productImages = [];
      this.productForm.reset();
      this.productForm.clearValidators();
      this.initProductForm();
    }, error => {
      this.toastr.error(error.error.results.error)
    })
  }

  }

  public updateAttributes() {
    this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
        console.log(data);
    })
}

public showImageModal(): void {
  this.addImageModal.showModal();
}

public verifyAlertQuantity(val) {
  // console.log(val.target.value);
  if(val.target.value == 0 || val.target.value == undefined || val.target.value == '') {
    this.productForm.get('alert_group').setValue(null);
  }
}

onFileInput(event) {
  if(event.target.files && event.target.files.length) {
    if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
      this.toastr.error(this.translateService.instant('image.notAnImage'))
      this.image = null;
      this.fileUploader.nativeElement.value = null;
      return
    }
    const [image] = event.target.files;
    this.image = image;
    const _URL = window.URL || window.webkitURL;
    let file, img, height, width;
    if ((file = event.target.files[0])) {
        img = new Image();
        const objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
            height = this.height;
            width = this.width;
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    }
    setTimeout(()=> {
      if(width <= height) {
        this.orientation  = "portrait";
        console.log("portrait");
      } else {
          this.orientation  = "landscape";
          console.log("landscape");
      }
    }, 500);
  }
}

public imageUploaded(file: File): void {
  this.changedImage = true;
  this.image = file;
  console.log(this.image);
  this.imageForm.markAsDirty();
}

addImage(ev) {
  this.productImages.push(ev.value.id);
  this.productForm.get('images').setValue(this.productImages);
  console.log(this.productForm.value);
}

deleteNoteImage(imgId) {
  this.productImages = this.productImages.filter((item) => item !== imgId);
  console.log(this.productImages);
  this.productForm.get('images').setValue(this.productImages);
  console.log(this.productForm.value);
}

public getImages() {
  const url = this.companyService.getUrl('images/')
  this.cacheService.delete(url+'group=products');
  this.companyService.getCompanyImages({group: 'products'}).subscribe((response): void => {
      this.images = response.body["results"];
      console.log(this.images);
      if(this.productId == -1) {
        this.productImages.push(this.images);
      }
      this.nextImgToken = response.body.next
      ? this.globalRegistry.getQueryStringParams(response.body.next.split("?")[1])
      : null;
      if(this.nextImgToken) this.getMoreImg = true
    })
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
      this.scrolledDownImages();
    }
  }

  scrolledDownImages() {
    const url = this.companyService.getUrl('images/');
    console.log('scrolled');
    if(this.getMoreImg) {
    this.nextImgToken &&
      this.subscription.add(this.companyService.getCompanyImages(this.nextImgToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextImgToken.limit+'&offset='+this.nextImgToken.offset+'&group=products');
        console.log(data);
        if(this.images) {
          this.images = [...this.images, ...data.body.results];
        } else {
          this.images = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreImg = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextImgToken.offset != params['offset']) {
          this.nextImgToken = {limit: params['limit'], offset: params['offset'], group: 'products'};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  public deleteImage(imageId: number): void {
    console.log(imageId);
    this.companyService.deleteCompanyImage(imageId).subscribe(data => {
      console.log(data);
      this.getImages();
    })
  }

  public imageSubmit() {
    const data = {
      width: this.imgWidth,
      height: this.imgHeight,
      orientation: this.orientation
    }
    const formData = new FormData();
    formData.append("file", this.image);
    formData.append("group", this.imageForm.get('group').value);
    formData.append("tags", this.imageForm.get('tags').value);
    formData.append("data", JSON.stringify(data));
    console.log(formData);
    // return;
    this.companyService.postCompanyImages(formData).subscribe(data => {
        console.log(data);
        if(data.body.results.id) {
          this.productImages.push(data.body.results.id);
          console.log(this.productImages);
          if(this.productId == -1 || this.productId == null) {
            this.productForm.get('images').patchValue(this.productImages);
            console.log(this.productForm.value);
          }
          this.getImages();
        }
      })
    this.imageRef.close();
    this.addImageModal.hideModal();
  }

  scrollParties() {
    const url = this.partyService.getUrl('');
    console.log(this.partyToken);
    console.log('parties scrolled');
    if(this.getMoreParties) {
    this.partyToken &&
    this.subscription.add(this.partyService.getParties(this.partyToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.partyToken.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search);
        console.log(data);
        if(this.parties) {
          this.parties = [...this.parties, ...data.body.results];
        } else {
          this.parties = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreParties = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.partyToken.offset != params['offset']) {
          this.partyToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

public submit(): void {

  if(this.unitForm.dirty) {
      const unit: UnitInterface = {
          name: this.unitForm.controls['name'].value,
          shortName: this.unitForm.controls['shortName'].value,
          genre: this.unitForm.controls['genre'].value
      };

      if(typeof this.unitForm.controls['parent'].value !== 'string') {
          unit.parent = this.unitForm.controls['parent'].value === 'new' ?
              null :
              this.unitForm.controls['parent'].value;
      }

      if(this.currentUnit) {
          this.updateUnit({...{id: this.currentUnit.id}, ...unit});
          this.globalRegistry.reloadUnits();
      }
      else {
          this.addUnit(unit);
          this.globalRegistry.reloadUnits();
      }
      this.getUnits()
    }
  }

  public getUnits() {
    this.subscription.add(this.unitService.getUnits().subscribe(data => {
      this.units = data.body.results;
    }))
  }

  private initunitForm(): void {
  this.unitForm = this.formBuilder.group({
      name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(100)
          ])
      ],
      shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(100)
          ])
      ],
      parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
              Validators.required
          ])
      ],
      genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
              Validators.required
          ])
      ]
    });
  }

  private addUnit(unit: UnitInterface): void {
    this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
      this.toastr.success(this.translate.instant('units.unitAddSuccessfull'));
      this.globalRegistry.reloadUnits();
      this.onUnitAction(response.model.id);
    },
    (): void => {
      this.toastr.error(this.translate.instant('units.unitAddError'));
    });
  }

  private updateUnit(unit: UnitInterface): void {
    this.unitService.updateUnit(unit).subscribe((): void => {
        this.toastr.success(this.translate.instant('units.unitUpdateSuccessfull'));
        this.globalRegistry.reloadUnits();
        this.onUnitAction(this.currentUnit.id);

    },
    (): void => {
        this.toastr.error(this.translate.instant('units.UnitUpdateError'));
    });
  }

  public deleteUnit(unitId: number): void {
    this.unitService.deleteUnit(unitId).subscribe((): void => {
        this.toastr.success(this.translate.instant('unit.unitDeleteSuccessfull'));
        this.globalRegistry.reloadUnits();
    },
    (): void => {
        this.toastr.error(this.translate.instant('unit.UnitDeleteError'));
    });
  }

  public getIngredients() {
    this.globalRegistry.reloadIngredients();
    this.globalRegistry.reloadIngredientsTypes();
    console.log(this.globalRegistry.systemData.ingredients);
  }

  public updateIngredient(ingredient: IngredientModel): void {
    console.log(ingredient);
    this.globalRegistry.reloadIngredients();
    this.updateCropTypeTable();
    this.addEditIngredientModal.hideModal();
  }

  public createdIngredient(ingredient: IngredientModel): void {
    this.globalRegistry.systemData.ingredients.push(ingredient);
    this.localIngredientsId = this.localIngredientsId || [];
    this.localIngredients.push(ingredient);
    this.localIngredientsId.push(ingredient.id);
    console.log(ingredient);
    this.updateCropTypeTable();
    this.addEditIngredientModal.hideModal();
  }

  public addIngredient(ingredient: IngredientModel): void {
    this.globalRegistry.systemData.ingredients.push(ingredient);
    this.localIngredientsId = this.localIngredientsId || [];
    this.localIngredients.push(ingredient);
    this.localIngredientsId.push(ingredient.id);
    console.log(ingredient);
    this.updateCropTypeTable();
    this.addEditIngredientModal.hideModal();
  }

  public deleteLocalIngredient(ingredientId: number): void {
    this.localIngredients = this.localIngredients.filter(ingredient => {
      return ingredient.id !== ingredientId
  });
  const ids = [];
  if(this.localIngredients.length == 0) {
    return
  }
  this.localIngredients.forEach(element => {
    ids.push(element.id);
  });
  console.log(ids);
  console.log(this.localIngredients);
  if(ids.length > 0) {
    this.selectIngredient(ids);
  }
  }

  private updateCropTypeTable(): void {
    this.globalRegistry.reloadCropTypes();
}

  public editIngredient(ingredient) {
    console.log(ingredient);
    this.selectedIngredient = ingredient
    this.addEditIngredientModal.showModal();
  }

  public deleteIngredient(ingredientId) {
    this.stockService.deleteIngredient(ingredientId).pipe(take(1)).subscribe((): void => {
        this.globalRegistry.systemData.ingredients = this.globalRegistry.systemData.ingredients.filter((ingredient: IngredientModel): boolean => ingredient.id !== ingredientId);
        this.toastr.success(this.translate.instant('stock.ingredients.successDeletedMessage'));
    });
  }

  public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.unitForm.controls[controlName];
    if (!control) {
        return false;
    }

    const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  public refreshIngredientList(ids) {
    console.log(ids);
  }

  public selectIngredient(value) {
    console.log(value);
    const localIngredients = [];
    this.localIngredientsId = value;
    if(this.globalRegistry.systemData.ingredients && this.globalRegistry.systemData.ingredients.length) {
    this.globalRegistry.systemData.ingredients.forEach(ingredient => {
      this.localIngredientsId.forEach(ingredientId => {
        if(ingredient.id == ingredientId) {
          localIngredients.push(ingredient);
        }
      });
    });
  }
    this.localIngredients = localIngredients;
  }

  public onUnitAction(unitId: number): void {
    this.selectedUnitId = unitId;
    this.addEditUnitModal.hideModal();
  }

  getTranslations() {
    this.subscription.add(
        this.typesService.getTranslations().subscribe((data) => {
            this.translatedNames = data.model;
            return;
        })
    );
}

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
        return translation
      }
  }

  showIngredientPopup() {
    this.selectedIngredient = null;
    this.addEditIngredientModal.showModal();
  }

  ngOnDestroy(): void {
    this.product = null;
    this.productImages = [];
    this.subscription.unsubscribe();
    this.productForm.reset();
    this.productForm.clearValidators();
  }

}
