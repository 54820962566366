import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FieldInterface } from 'src/app/core/interfaces/field/field.interface';
import { LotModel } from 'src/app/core/models/lot/lot.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { MapPolygonInterface } from 'src/app/shared/layout/fap_main-map/data/map-polygon.interface';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './activities-container.component.html',
    styleUrls: ['./activities-container.component.scss']
})
export class ActivitiesContainerComponent implements OnInit, OnDestroy {
    private subscriptions: Array<Subscription> = [];
    public farms: Array<FarmModel>;
    public filteredFarms: Array<FarmModel> = [];
    public currentUser;
    public activities: Array<any>;
    public filteredActivities: Array<any>;
    public activityTypes: Array<ActivityTypeModel>;
    public nextToken: { offset: number; limit: number } = null;
    public limit = 20;
    public getMore = true;
    public personIds = [];
    public lotPolygon: MapPolygonInterface;
    public lotFields: Array<FieldInterface> = [];

    constructor(
        public farmService: FarmService,
        public activatedRoute: ActivatedRoute,
        public userService: UserService,
        public router: Router,
        public activityService: ActivityService,
        public mapService: MapService,
        public toastr: ToastrService,
        public translateService: TranslateService,
        public typeService: TypesService,
        public globalRegistry: GlobalRegistryService,
        public companyService: CompanyService,
        public cacheService: CacheResolverService
    ) {
        this.subscriptions.push(
            this.userService.getCurrentUser.subscribe((data) => {
                if (Object.keys(data).length != 0) {
                    this.currentUser = data;
                    const path = this.router.routerState.snapshot.url;
                    this.userService.getMenus.subscribe((menus) => {
                        if (Object.keys(menus).length != 0) {
                            const pathExist = menus.some(
                                (menu) => menu.path === path
                            );
                            if (pathExist === false) {
                                this.router.navigate(['pages/posts']);
                            }
                        }
                    });
                }
            })
        );
        const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: localQueryParams,
          });
        this.subscriptions.push(
            combineLatest([
                activatedRoute.queryParams,
                
                this.activityService.getActivities({limit: this.limit})
            ]).subscribe(
                ([queryParams, activities]: [
                    Params,
                    
                    ResponseModel<ActivityModel[]>
                ]): void => {
                    this.farms = globalRegistry.systemData.farms;
                    if (queryParams['farms'] !== undefined) {
                        const filteredFarmIds: Array<number> = Array.isArray(
                            queryParams['farms']
                        )
                            ? queryParams['farms'].map(
                                  (farmId: string): number => Number(farmId)
                              )
                            : [Number(queryParams['farms'])];
                        this.filteredFarms = this.farms.filter(
                            (farm: FarmModel): boolean => {
                                return filteredFarmIds.includes(farm.id);
                            }
                        );
                    } else {
                        this.filteredFarms = this.farms;
                    }
                    this.activities = activities.body.results;
                    
                    this.nextToken = activities.body.next
                        ? this.globalRegistry.getQueryStringParams(activities.body.next.split("?")[1])
                        : null;
                    this.filteredActivities = this.activities;
                    const url = this.activityService.getUrl('activities/');
                    this.cacheService.delete(url+'limit='+this.limit);
                    console.log(this.filteredActivities);
                    this.getPersons();
                    if (queryParams["filter"] !== undefined) {
                        const search = (queryParams["filter"] as string).toLowerCase();
                        activityService.getActivities({search}).subscribe(data => {
                          cacheService.delete(url+'search='+search);
                          this.nextToken = data.body.next
                        ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                        : null;
                        if(this.nextToken) this.getMore = true;
                        this.filteredActivities = data.body.results;
                        this.getPersons();
                        console.log(this.filteredActivities);
                        })
                      }
                }
            )
        );
    }

    getPersons() {
        if(this.filteredActivities && this.globalRegistry.systemData.persons) {
        this.personIds = [];
        this.filteredActivities.forEach(activity => {
          const personExist = this.globalRegistry.systemData.persons.some((p) => p.id === activity.assignedTo);
          if(!personExist) {
            this.personIds.push(activity.assignedTo);
          }
        });
        const unique = this.personIds.filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        });
        unique.forEach(element => {
          if(element!= null) {
          this.companyService.getPerson(element).subscribe(data => {
            this.globalRegistry.systemData.persons.push(data.body.results);
          })}
        }); 
      } else {
        this.refreshPersons();  
      }
      }

    public refreshPersons() {
      this.globalRegistry.reloadPersons();
      setTimeout(() => {
        this.getPersons()
      }, 1000);
    }

    paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }
    
      scrolledDown() {
        const url = this.activityService.getUrl('activities/');
        
        console.log('activities scrolled');
        if(this.getMore) {
          this.nextToken &&
            this.subscriptions.push(this.activityService.getActivities(this.nextToken).subscribe((data) => {
              this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset);
              console.log(data);
              if(this.filteredActivities) {
                this.filteredActivities = [...this.filteredActivities, ...data.body.results];
              } else {
                this.filteredActivities = data.body.results;
              }
              if(data.body.next == null) {
                this.getMore = false;
                return
              } else {
                const url = data.body.next.split('?')
                const urlParams = new URLSearchParams(url[1]);
                const entries = urlParams.entries();
                const params = this.paramsToObject(entries);
                console.log(params);
                if(this.nextToken.offset != params['offset']) {
                this.nextToken = {limit: params['limit'], offset: params['offset']};
                } else {
                  return
                }
              }
            }));
          } else {
            return
          }
      }

    ngOnInit(): void {
        this.typeService.getActivityTypes().subscribe((types:ResponseModel<ActivityTypeModel[]>) => {
            this.activityTypes = types.body.results;
        })
        this.initMap();
        this.mapService.centerMapOnUserLocation();
        this.showAllFieldsOnMap();
    }

    public showAllFieldsOnMap(): void {  
      this.globalRegistry.systemData.lots.forEach((lot: LotModel): void => {
          this.mapService.mapPolygons.push({
              points: MapHelper.convertToAGMPolygon(lot.coords.coordinates[0]),
              strokeColor: '#33de55',
              fillColor: '#248a38',
              data: {field: true},
              isEditable: false
          });
      });
      this.mapService.centerMapOnPolygons();
  }

    private loadActivities(): void {
        this.activityService
            .getActivities()
            .pipe(take(1))
            .subscribe((activities: ResponseModel<ActivityModel[]>): void => {
                this.filteredActivities = activities.body.results;
                this.getPersons();
                console.log(this.filteredActivities);
                this.initMap();
            });
        
    }

    public deleteActivity(actvityId: number): void {
        this.activityService
            .deleteActivity(actvityId)
            .pipe(take(1))
            .subscribe((): void => {
                this.toastr.success(
                    this.translateService.instant(
                        'activity.activityDeletedSuccessfully'
                    )
                );
                this.loadActivities();
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe()
        );
        this.mapService.isEditMode = false;
        this.mapService.resetMap();
    }

    private initMap(): void {
        this.mapService.showMap();
        this.mapService.isEditMode = false;
        this.mapService.resetMap();
        this.filteredFarms.forEach((farm: FarmModel): void => {
            this.mapService.mapPolygons.push({
                points: MapHelper.convertToAGMPolygon(
                    farm.coords.coordinates[0]
                ),
                strokeColor: '#a31f1f',
                fillColor: '#de3333',
            });
        });

        this.mapService.centerMapOnPolygons();
    }

    public centerMapOnLot(lot: LotModel): void {
      const lotPolygon: MapPolygonInterface = this.mapService.mapPolygons
          .find((poly: MapPolygonInterface): boolean => poly.data['lot'] === lot.id);
      this.mapService.centerMapOnPolygon(lotPolygon);
  }
}
