<div class="donut-chart-widget">
    <div class="widget-card">
        <div class="widget-card-title">
            <div>
                {{ chartData.chartTitle | translate }}
            </div>
            <div *ngIf="chartData['objectName']" class="widget-content-type-text">
                {{ chartData['contentType'] | translate }} - {{ chartData['objectName'] }}
            </div>
        </div>
        <div class="widget-card-body">
            <div class="chart-container">
                <!-- <ngx-charts-bar-vertical
                    [scheme]="chartData.chartColorScheme ? chartData.chartColorScheme : defaultColorScheme"
                    [results]="chartData.chartData"
                    [gradient]="chartData.chartGradient"
                    [xAxis]="chartData.chartShowXAxis"
                    [yAxis]="chartData.chartShowYAxis"
                    [legend]="chartData.chartShowLegend"
                    [showXAxisLabel]="chartData.chartShowXAxisLabel"
                    [showYAxisLabel]="chartData.chartShowYAxisLabel"
                    xAxisLabel="{{chartData.chartXAxisLabel | translate}}"
                    yAxisLabel="{{chartData.chartYAxisLabel | translate}}"
                    (select)="onSelect($event)"
                    (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)">
                </ngx-charts-bar-vertical> -->
            </div>
        </div>
    </div>
</div>
