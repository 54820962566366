import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { NavService } from 'src/app/shared/services/nav.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { BillModel } from '../../../../../core/models/stock/bill.model';
import { DocumentModel } from '../../../../../core/models/stock/document.model';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';
import { StockResourceModel } from '../../../../../core/models/stock/stock-resource.model';
import { PartyService } from '../../../../../core/services/api/company/party.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { StateService } from '../../../../../shared/services/state.service';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'MMM DD YYYY',
  },
  display: {
    dateInput: 'MMM DD YYYY',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@Component({
    selector: 'fap-stock-items',
    templateUrl: './fap-stock-items.component.html',
    styleUrls: ['./fap-stock-items.component.scss'],
    providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class FapStockItemsComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public stockOverview: Array<StockItemModel> = [];
    public checkIds: Array<number> = [];
    public entries = [];
    public documents: Array<BillModel> = [];
    public currencyUnits = [];
    closeActive = new Subject<void>();
    public subscriptions: Array<Subscription> = [];
    public transactions : Array<any> = [];
    @ViewChild('docsModal')
    public docsModal: FapModalComponent;
    public limit = 20;
    public docs: Array<DocumentModel> = [];
    public nextToken: { limit: number, offset: number; search?: string, type?: string; from?: any, to?:any, farm?: number } = null;
    public nextProductToken: { limit: number, offset: number; search?: string; } = null;
    public getMore = true;
    public minDate;
    public maxDate;
    public docSearchForm: UntypedFormGroup;
    public prodFilterForm: UntypedFormGroup
    public products: Array<any> = [];
    public translatedNames:any = [];
    public langString: string;
    @ViewChild('addEditProductModal')
    public addEditProductModal: FapModalComponent;
    public productId: number;
    @ViewChild('doc_search') public doc_search : HTMLElement;
    status = false;
    prodStatus = false;
    public documentTypes = [
      {key: 'invoice_in', value: 'Invoice in'},
      {key: 'invoice_out', value: 'Invoice out'},
      {key: 'internal_note', value: 'Internal note'},
      {key: 'production_note', value: 'Production note'},
      {key: 'estimation_note', value: 'Estimation note'},
    ];
    public pdfDoc;
    public excelDoc;
    private partyCache: { [id: number]: Observable<string> } = {};
    @ViewChild('stockTable') stockTable: ElementRef;
    @ViewChild('entriesTable') entriesTable: ElementRef;
    @ViewChild('transactionsTable') transactionsTable: ElementRef;
    @ViewChild('documentTable') documentTable: ElementRef;

    constructor(
        public globalRegistry: GlobalRegistryService,
        public stockService: StockService,
        public unitService: UnitService,
        public router:Router,
        public activatedRoute: ActivatedRoute,
        public partyService: PartyService,
        public formBuilder: UntypedFormBuilder,
        public navService: NavService,
        public cacheService: CacheResolverService,
        public widgetService: WidgetsService,
        private stateService: StateService
    ) {
      this.initDocSearchForm();
      this.initProdSearchForm();
    }

    public minValueChanged(ev) {
      this.minDate = ev.target.value;
  }

  public maxValueChanged(ev) {
      this.maxDate = ev.target.value;
  }

  public getProducts() {
    const url = this.stockService.getUrl('products/');
    this.subscriptions.push(this.stockService.getProducts({ limit: this.limit }).subscribe(data => {
      this.cacheService.delete(url+'limit='+this.limit);
      console.log(data);
      this.products = data.body.results;
      this.nextProductToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
          if(this.nextProductToken) this.getMore = true
    }))
  }

    public productEdit(product) {
      this.productId = product.id
      this.addEditProductModal.showModal();
    }

    public showProductModal(): void {
      this.productId = -1;
      this.addEditProductModal.showModal();
    }

    public clearDocInputs() {
      this.docSearchForm.reset();
      this.docSearchForm.clearValidators();
      this.docSearchForm.markAsUntouched();
      this.initDocSearchForm();
    }

    public clearProdInputs() {
      this.prodFilterForm.reset();
      this.prodFilterForm.clearValidators();
      this.prodFilterForm.markAsUntouched();
      this.initDocSearchForm();
    }

    public filterDocs() {
      const url = this.stockService.getUrl('docs/');
      const params = {
        search: this.docSearchForm.get('search').value ? this.docSearchForm.get('search').value : '',
        from: moment.utc(this.docSearchForm.get('from').value).format('YYYY-MM-DD'),
        to: moment.utc(this.docSearchForm.get('to').value).format('YYYY-MM-DD')
      }
      if(this.docSearchForm.get('type').value) { Object.assign(params, {type : this.docSearchForm.get('type').value}) }
      if(this.docSearchForm.get('farm').value) { Object.assign(params, {farm : this.docSearchForm.get('farm').value}) }
      this.subscriptions.push(
      this.stockService.getDocs(params).subscribe(data => {
        this.cacheService.delete(url+'search='+params.search+'&from='+params.from+'&to='+params.to);
          this.docs = data.model;
          this.getDocScrollPosition();
          this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.nextToken) this.getMore = true;
        }))
    }

    filterProduct() {
      const url = this.stockService.getUrl('');
      const params = {
        search: this.prodFilterForm.get('search').value ? this.prodFilterForm.get('search').value : '',
      }
      if(this.prodFilterForm.get('type').value) { Object.assign(params, {type : this.prodFilterForm.get('type').value}) }
      this.subscriptions.push(this.stockService.getProducts(params).subscribe(data => {
        this.cacheService.delete(url+'search='+params.search)
        console.log(data);
        this.nextProductToken = data.body.next
              ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
              : null;
        this.products = data.body.results;
        if(this.nextProductToken) this.getMore = true
      }))
    }

    getDocScrollPosition() {
      setTimeout(() => {
        this.documentTable.nativeElement.scrollTop = this.stateService.scrollPositions['documentTable'] || 0;
        const divHeight = this.documentTable.nativeElement.clientHeight;
        console.log(divHeight);
        console.log(this.stateService.scrollPositions['documentTable']);
        console.log(this.documentTable.nativeElement.scrollTop);
        // if(this.stateService.scrollPositions['documentTable'] && divHeight > this.stateService.scrollPositions['documentTable']) {
        //   this.scrolledDown();
        // }
      }, 1000)
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
      const control: any = this.docSearchForm.controls[controlName];
      if (!control) {
          return false;
      }

      const result: boolean =
          control.hasError(validationType) &&
          (control.dirty || control.touched);
      return result;
  }

    public initDocSearchForm() {
      const oneYearFromNow = new Date();
        oneYearFromNow.setMonth(oneYearFromNow.getMonth() - 12);
      const formBody = {
        search: [''],
        type: [''],
        from: [oneYearFromNow, Validators.required],
        to: [new Date(), Validators.required],
        farm: [null]
      };
        this.docSearchForm = this.formBuilder.group(formBody);
    }

    public initProdSearchForm() {
      const formBody = {
        search: [''],
        type: [''],
      };
        this.prodFilterForm = this.formBuilder.group(formBody);
    }

    getTranslation(translation) {
      const t =this.translatedNames.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        return t[0][this.langString];
      } else {
        return translation
    }
    }

    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }

    scrolledDownProducts() {
      console.log('scrolled');
      const params = this.nextProductToken;
      if(this.getMore) {
      this.nextProductToken && 
        this.subscriptions.push(this.stockService.getProducts(params).subscribe((data) => {
          console.log(data);
          if(this.products) {
            this.products = [...this.products, ...data.body.results];
          } else {
            this.products = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextProductToken.offset != params['offset']) {
            this.nextProductToken = {offset: params['offset'], limit: params['limit']};
            if(params['search']) this.nextProductToken.search = params['search'];
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
    }
  
    scrolledDown() {
      const url =  this.stockService.getUrl('docs/');
      console.log('scrolled');
      if(this.getMore) {
      this.nextToken &&
        this.stockService.getDocs(this.nextToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset);
          console.log(data);
          if(this.docs) {
            this.docs = [...this.docs, ...data.model];
          } else {
            this.docs = data.model;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            if(this.nextToken.offset != params['offset']) {
            this.nextToken = {limit: params['limit'], offset: params['offset']};
            if(params['search']) this.nextToken.search = params['search'];
            if(params['from']) this.nextToken.from = params['from'];
            if(params['to']) this.nextToken.to = params['to'];
            if(params['type']) this.nextToken.type = params['type'];
            if(params['farm']) this.nextToken.type = params['farm'];
            } else {
              return
            }
          }
        });
      } else {
        return
      }
      this.getDocScrollPosition();
    }

    refreshItems() {
      this.stockOverview = [];
      this.checkIds = [];
      this.entries = [];
      this.transactions = []
      const params = {refresh: 1}
      const url = this.stockService.getUrl('');
      this.cacheService.delete(url+'stock_items/refresh=1')
      this.stockService.getStockItems(params).subscribe(data => {
        this.stockOverview = data.model;
      })
    }

    public ngOnChanges(changes: SimpleChanges): void {
      if(Object.prototype.hasOwnProperty.call(changes, 'stockOverview') && this.stockOverview) {
            console.log(this.stockOverview)
              this.stockOverview.forEach((item) => {
                item.checked = this.stateService.getSelectedEntryIds().has(item.id);
              });
              
              this.fetchEntries();
              const resourceEntryId = this.stateService.getResourceEntryId()
              if(resourceEntryId) {
                this.getTransaction(resourceEntryId)
              }
              setTimeout(() => {
                this.stockTable.nativeElement.scrollTop = this.stateService.scrollPositions['stockTable'] || 0;
              }, 500);
      }
    }

    public ngOnInit(): void {
      console.log('Selected Entry IDs:', this.stateService.getSelectedEntryIds());
      this.navService.editFarm.next(false);
      this.navService.createMod.next(false);
      this.widgetService.setSize(12);
      this.translatedNames = this.globalRegistry.systemData.translations;
      this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.subscriptions.push(
            this.unitService.getUnitByGenre('$').subscribe((data) => {
                this.currencyUnits = data.model;
            })
        );
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe(data => {
          console.log(data);
          if(data && data['popup']) {
            this.showModal();
          }
          if(data && data['products']) {
            this.showProductsModal();
          }
          if(data.farms) {
            console.log(data.farms.toString());
            const params = {
              locations: data.farms.toString()
            }
            this.stockService.getStockItems(params).subscribe(data => {
              this.stockOverview = data.body.results;
            })
          }
        }));
        this.globalRegistry.reloadParties();
    }

    onDivScroll(div, event: Event) {
      const divElement = event.target as HTMLElement;
      const scrollTop = divElement.scrollTop;
      this.stateService.scrollPositions[div] = scrollTop;
    }

    showModal() {
      this.filterDocs()
      const elem = document.getElementById('docs_modal');
      const parent = document.getElementById('parent');
      elem.classList.add('active');
      parent.classList.add('backdrop');
  }

  hideModal() {
      const elem = document.getElementById('docs_modal');
      const parent = document.getElementById('parent');
      elem.classList.remove('active');
      parent.classList.remove('backdrop');
      this.router.navigate(['pages/stock'])
      this.docSearchForm.reset();
      this.docSearchForm.clearValidators();
      this.initDocSearchForm();
  }

  showProductsModal() {
    this.filterProduct();
    const elem = document.getElementById('products_modal');
    const parent = document.getElementById('parent');
    elem.classList.add('active');
    parent.classList.add('backdrop');
}

hideProductsModal() {
    const elem = document.getElementById('products_modal');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.router.navigate(['pages/stock']);
    this.prodFilterForm.reset();
    this.prodFilterForm.clearValidators();
}

    getMoment(date) {
      const dstring = moment.utc(date).local();
      return moment(dstring).fromNow();
    }

    selectEntry(event: any, item: any): void {
      item.checked ? this.stateService.addSelectedEntryId(item.id) : this.stateService.removeSelectedEntryId(item.id);
      this.fetchEntries();
    }
  
    fetchEntries(): void {
      const selectedEntryIds = Array.from(this.stateService.getSelectedEntryIds());
      if (selectedEntryIds.length > 0) {
        console.log(selectedEntryIds);
        this.subscriptions.push(
          this.stockService.getStockResourceEntry(selectedEntryIds).subscribe((data: ResponseModel<StockResourceModel[]>) => {
            this.entries = data.body.results;
            console.log(this.entries);
            setTimeout(() => {
              if (this.entriesTable && this.entriesTable.nativeElement) {
                this.entriesTable.nativeElement.scrollTop = this.stateService.scrollPositions['entriesTable'] || 0;
                console.log(this.stateService.scrollPositions['entriesTable'])
              }
            }, 1000);
            if (this.transactions.length > 0) {
              const bool = this.entries.some((value) => value.id == this.transactions[0].resource_entry.id);
              if (!bool) {
                this.transactions = [];
              }
            }
          })
        );
      } else {
        this.transactions = [];
        this.entries = [];
      }
    }

    public ngOnDestroy(): void {
      this.widgetService.setSize(6);
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }

    public getTransaction(resourceEntryId) {
      this.stateService.setResourceEntryId(resourceEntryId);
      this.stockService.getTransactions(resourceEntryId).subscribe(data => {
        this.transactions = data.body.results;
        setTimeout(() => {
          this.transactionsTable.nativeElement.scrollTop = this.stateService.scrollPositions['transactionsTable'] || 0;
        }, 500)
        console.log(this.transactions);
      })
    }

    public goToBill(billId, billType) {
      this.router.navigate(['/pages/stock/bill/'+billId], {queryParams: {type: billType, new_doc: 'false'}})
    }

    public selectAll(event) {
      this.checkIds = [];
      if(event.target.checked === true) {
      this.stockOverview.forEach(element => {
        this.checkIds.push(element.id);
      });
    } else {
      this.checkIds = [];
    }
    }

    public refreshItem(stockItemId) {
      const params = { refresh: 1 }
      const url = this.stockService.getUrl('');
      this.cacheService.delete(url+'stock_items/'+ stockItemId +'/refresh=1');
      this.stockService.refreshStockItem(stockItemId, params).subscribe(data => {
        console.log(data);
        // this.stockOverview = data.body.results;
      });
      // this.refreshItems();
    }

    getPartyInfo(id) {
      // Check if the result is already in the cache
      if (this.partyCache[id]) {
        return this.partyCache[id];
      }
  
      // Check if the party exists in the systemData.parties array
      const existingParty = this.globalRegistry.systemData.parties.find(party => party.id === id);
      if (existingParty) {
        this.partyCache[id] = of(existingParty.name).pipe(shareReplay(1)); // Store the value in the cache as an observable
        return this.partyCache[id];
      } else {
        // Make the API call only when the value is not in the cache
        const party$ = this.partyService.getParty(id).pipe(
          switchMap(data => {
            const party = data.body.results;
            const partyExist = this.globalRegistry.systemData.parties.some(obj => obj.id === party.id);
            if (!partyExist) {
              this.globalRegistry.systemData.parties.push(party); // Add the party to the systemData.parties array
            }
            this.partyCache[id] = of(party.name).pipe(shareReplay(1)); // Store the value in the cache as an observable
            return this.partyCache[id];
          }),
          tap(() => {
            // Do nothing in the tap operator, just to trigger the API call
          }),
          shareReplay(1) // Cache and replay the API call result
        );
  
        this.partyCache[id] = party$; // Store the observable in the cache
        return party$;
      }
    }

    CheckAllOptions() {
      
      if (this.stockOverview.every(val => {
        this.checkIds.push(val.id);
        return val.checked == true
      }))
        this.stockOverview.forEach(val => {
          this.checkIds = [];
          return val.checked = false 
        });
      else
        this.stockOverview.forEach(val => {
          this.checkIds.push(val.id);
          return val.checked = true
        });
        
        setTimeout(() => {
          this.checkIds.filter((item,
            index) => this.checkIds.indexOf(item) === index)
          this.fetchEntries();
        }, 1500);
    }

    public toggleDocFilter() {
      this.status = !this.status;
    }

    public toggleProdFilter() {
      this.prodStatus = !this.prodStatus;
    }

    public exportAsPDF(doc) {
      this.router.navigate(['pages/stock/pdf/'+doc.id], {queryParams: {type: doc.type}});
    }

    public exportAsExcel(docId) {
      console.log(docId);
    }
      
}
