<div class="workflow_card">
    <div class="image">
        <div class="user_im img_place_bg">
        <img *ngIf="workflow?.icon" [src]="mediaUrl + workflow?.icon" alt="icon">
        <img *ngIf="workflow?.icon == 0 || workflow?.icon === null" class="user_im" src="../../../../../../assets/images/landscape_placeholder.png" alt="icon">
    </div>
    </div>
    <div class="info">
        <div class="name">{{selectedType?.name ? selectedType?.name : selectedType?.uniqueid}}</div>
        <div class="created_at">{{workflow?.created_at | date : "MMM dd yyyy, HH:mm"}}</div>
        <!-- <div *ngIf="!workflow?.complete">
            <button class="btn">Resume</button>
        </div> -->
    </div>
</div>