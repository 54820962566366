<div class="search opened1">
    <label for="search" class="fa fa-search"> </label>
    <input id="searchamp" matInput type="text" class="form-control color-black" (keydown.enter)="$event.preventDefault()" placeholder="Search map" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #searchRef>
    <!-- <span (click)="coordsListToggle = !coordsListToggle" *ngIf="mapTypeControl">
        <img src="./assets/images/fap/add_edit_farm.svg" alt="edit farm">
    </span> -->
</div>
<div class="attr_map d-flex">
<div class="coords-list" *ngIf="mapType !== 'coords'">
<div class="header">
    <p><b>Length:</b> {{polyLength | number: '1.1-2'}} Km</p>
    <p *ngIf="mapType === 'surface'"><b>Area: </b>{{lastPoligonTouchedSelectedArea | number: '1.1-2'}} Ha</p>
</div>
<div class="content-wrapper">
    <form [formGroup]="formCoords" (submit)="deleteCoords(formCoords.value)">
    <div class="utils">
        <div class="modifiers">
            <span (click)="selectAllCheckboxes()">
            <img src="./assets/images/fap/select_all.svg" alt="select all">
        </span>
            <button [ngStyle]="{'background': 'none'}" type="submit">
            <img src="./assets/images/fap/trash.svg" alt="delete selected">
        </button>
        </div>
        <div class="buttons">
            <label class="import-label" name="import">
                    import
                <input name="import" (change)="fileChangeListener($event)" #fileImportInput type="file">
            </label>

            <button
                (click)="exportCoords()"
            >
                export
            </button>
        </div>
    </div>
    <div class="overflow-auto">
    <div *ngFor="let coord of coordCheckboxes.controls, let i = index">
        <div class="coords" [ngClass]="{'green': changedCoordinate === i}">
            <input [formControl]="coord" type="checkbox" (change)="getCheckboxIndex($event, i)"/>
            <b><p>{{getNumber(i + 1)}}</p></b>
            <p>{{editableCoords.length ? editableCoords[i].toString() : ''}}</p>
        </div>
    </div>
    </div>
    </form>
</div>
</div>
<div class="map_container">
<agm-map
[latitude]="mapFocusPoint.lat"
[longitude]="mapFocusPoint.lng"
[(zoom)]="zoom"
[streetViewControl]="false"
[mapTypeControl]="false"
[mapTypeId]="'hybrid'"
[fitBounds]="bounds"
(contextmenu)="onContextMenuRightClick($event)"
(mapRightClick)="mapRightClicked($event)"
(mapReady)="onMapReady($event)"
(mapClick)="getCoords($event)"
>

<!--        <button #mapRevertBtn-->
<!--                (click)="mapService.clickedRevertBtn.emit()"-->
<!--                [ngClass]="{'d-none': !mapService.isEditMode}"-->
<!--                class="btn btn-light mr-2 mt-2">-->
<!--            {{ 'revertChanges' | translate }}-->
<!--        </button>-->
<button *ngFor="let button of buttons"
        id={{button.value}}
        (click)="mapButtonClick.emit(button.toString())"
        [ngClass]="{'d-none': !showButtons}"
        class="btn btn-light m-1 px-1 map-action-left-button">
    {{ button.caption }}
</button>

<ng-container>
    <agm-polygon
        *ngFor="let polygon of mapPolygons; let index = index"
        [editable]="isEditMode&&polygon.isEditable!==undefined&&polygon.isEditable!==null?polygon.isEditable:isEditMode"
        [paths]="polygon.points"
        [zIndex]="1000"
        [draggable]="false"
        [strokeColor]="polygon.strokeColor"
        [fillColor]="polygon.fillColor"
        [strokeWeight]="polygon.strokeWeight"
        [polyDraggable]="isEditMode&&polygon.isEditable!==undefined&&polygon.isEditable!==null?polygon.isEditable:isEditMode"
        (polyPathsChange)="polyPathsChange($event, index)"
        (polyDblClick)="polyClickEdit($event, polygon, index)"
        (polyRightClick)="polyRightClick($event, polygon, index)"
        (polyMouseOver)="polyHover($event, polygon)"
        (polyMouseOut)="polyMouseOut($event, polygon)"
        (polyDragEnd)="polyDragEnd($event)">
    </agm-polygon>
</ng-container>

<ng-container>
    <agm-marker
        *ngFor="let marker of mapMarkers"
        [latitude]="marker?.lat"
        [longitude]="marker?.lng"
        [label]="marker?.label"
        [zoom]="marker?.zoom"
        [iconUrl]="marker?.iconUrl"
        [markerDraggable]="false"
        (markerClick)="clickedMarker($event, marker)"
        (dragEnd)="markerDragEnd(marker, $event)">

        <agm-info-window *ngIf="marker.infoWindowDetails">
            <div class="card-group">
                <div *ngFor="let info of marker.infoWindowDetails" class="card pr-1">
                <img class="card-img-top" [src]="info.img">
                <div class="card-body p-1">
                    <p class="card-text">{{ info.text }}</p>
                </div>
                </div>
            </div>
        </agm-info-window>
    </agm-marker>
    
</ng-container>
<ng-container>
    <agm-polyline *ngFor="let polyline of mapPolylines;let i = index;"
                  [strokeColor]="'yellow'"
                  [editable]="isEditable"
                  (lineClick)="lineClick($event)"
                  (polyPathChange)="polyPathChange($event)">

        <agm-polyline-point *ngFor="let point of polyline?.points"
                            [latitude]="point.lat"
                            [longitude]="point.lng">
        </agm-polyline-point>
    </agm-polyline>
</ng-container>
</agm-map>
</div>
</div>
<div *ngIf="contextMenuEnabled">
    <div class="contextmenu" [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
        <div class="map-menu">
            <ul>
                <ng-container>
                    <li class="divider">
                    </li>
                    <li class="header" *ngIf="mapType === 'surface'">
                        {{ "attribute.drawPolygon" | translate }}
                    </li>
                    <li class="header" *ngIf="mapType === 'polyline'">
                        {{ "attribute.drawPolyline" | translate }}
                    </li>
                    <li (click)="drawPolygon()" class="item" *ngIf="mapType === 'surface'">{{ 'map.actions.drawPolygon' | translate }}</li>
                    <li (click)="drawPolyline()" class="item" *ngIf="mapType === 'polyline'">{{ 'map.actions.addPolyline' | translate }}</li>
                    <li *ngIf="mapType !== 'polyline'" (click)="clickedRevertBtn.emit()" class="item">{{ 'revertChanges' | translate }}</li>
                    <li *ngIf="mapType === 'polyline'" (click)="clearLastLine()" class="item">{{ 'revertChanges' | translate }}</li>
                </ng-container>
            </ul>
        </div> 
    </div>
    
</div>

<div class="btns mt-2">
    <button class="btn btn-primary" (click)="hideModal()">{{ 'translations.cancel' | translate }}</button>
    <button class="btn btn-primary" (click)="submitValue()">{{ 'translations.save' | translate }}</button>
</div>