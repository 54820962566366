import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    NgZone
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subject, throwError, Observable, Subscription } from 'rxjs';
import { tap, catchError, takeUntil, finalize, map } from 'rxjs/operators';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyInterface } from '../../../../../core/services/api/company/data/company.interface';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { AddressModel } from '../../../../../core/models/company/address.model';
import { FapModalComponent } from '../../../../../shared/partials';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapsAPILoader } from '@agm/core';
import { environment } from '../../../../../../environments/environment';
declare let google;

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  content: string;
  color?: string;
  iconUrl?: string;
}

interface coords {
  lat: number;
  lng: number;
}


@Component({
    selector: 'fap-create-company',
    templateUrl: './fap_create-company.component.html',
    styleUrls: ['./fap_create-company.component.scss']
})
export class FapCreateCompanyComponent implements OnInit, OnDestroy {
    public createCompanyForm: UntypedFormGroup;
    public data$: Observable<Data>;
    @Output()
    public deleteAddress: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @Output()
    public updatedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @ViewChild('addEditAddressModal')
    public addEditAddressModal: FapModalComponent;

    public selectedAddress: AddressModel;
    private unsubscribe: Subject<any>;
    public companyInfo: any;
    public types = ['person', 'company']
    public fullAddress;
    zoom = 8;
    markers:Array<marker> = [];
    public searchControl: UntypedFormControl;
    public mapSearchToggle: boolean;
    public mapTypeControl: boolean;
    @ViewChild('search', {static: true}) public searchElementRef: ElementRef;
    public addressString;
    @Output()
    public coords: EventEmitter<coords> = new EventEmitter();
    public subscriptions: Array<Subscription> = [];
    public coordsString = '';
    defaultCenter = {lat: 51.673858, lng: 7.815982};
    currentCenter = Object.assign({}, this.defaultCenter);
    public type = 'person';
    public mediaUrl = environment.mediaUrl+'settings/login_background.jpg';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private toastyService: ToastrService,
        public translate: TranslateService,
        public companyService: CompanyService,
        public router: Router,
        private cdr: ChangeDetectorRef,
        public globalRegistry: GlobalRegistryService,
        public toastr: ToastrService,
        private route: ActivatedRoute,
        private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) {

        translate.setDefaultLang(localStorage.getItem('language'));
        translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        this.unsubscribe = new Subject();
    }

    public ngOnInit(): void {
        this.getCountriesData();
        console.log(JSON.parse(localStorage.getItem('company')));
        this.companyInfo = JSON.parse(localStorage.getItem('company'));
        this.initCreateCompanyForm();
        this.data$ = this.route.data.pipe(map((data: Data) => {
            return {
                mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
            };
        }));

        this.searchControl = new UntypedFormControl();

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.currentCenter.lat = place.geometry.location.lat();
          this.currentCenter.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
    }

    public changeType(ev) {
        if(ev.value === 'company') {
            this.type = 'party'
        } else {
            this.type = 'person'
        }
    }

    public ngOnDestroy(): void {
         this.unsubscribe.next(null);
        this.unsubscribe.complete();
        localStorage.removeItem('company')
    }

    public getCountriesData() {
        this.subscriptions.push(this.companyService.getCountries().subscribe(data => {
            this.globalRegistry.systemData.countries = data.body;
        }));
    }

    mapClicked($event) {
        this.markers = [];
        this.markers.push({
          lat: $event.coords.lat,
          lng: $event.coords.lng,
          draggable: true,
          content: $event.coords.lat +', '+ $event.coords.lng,
        });
        this.coords.emit({lat: $event.coords.lat, lng: $event.coords.lng});
        this.coordsString = 'Point(' + $event.coords.lat + ' ' + $event.coords.lng + ')';
        console.log(this.coordsString);
      }
    
      markerDragEnd(m: marker, $event) {
        console.log("dragEnd", m, $event);
      }


    public submit(): void {
        if(this.createCompanyForm.invalid) {
            Object.keys(this.createCompanyForm.controls).forEach((controlName: string) =>
                this.createCompanyForm.controls[controlName].markAsTouched()
            );
            return;
        }

        
        const body: CompanyInterface = {
            accType: this.createCompanyForm.controls['type'].value,
            name: this.createCompanyForm.controls['name'].value,
            cif: this.createCompanyForm.controls['cif'].value,
            vat: this.createCompanyForm.controls['vat'].value,
            addressId: this.createCompanyForm.controls['address_id'].value,
            coords: this.coordsString
        };
       
        if(this.companyInfo.id) {
            Object.assign(body, {id: this.companyInfo.id})
           this.companyService.updateCompany(body).pipe(
            tap(() => {
                this.toastyService.success(this.translate.instant('auth.company.companyupdated'));
                this.router.navigateByUrl('/pages');
            }),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error.results.error);
                return throwError(error)
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cdr.markForCheck();
            })
           )
          //  .subscribe(() => {
          //  }) 
        } else {
        this.companyService.createCompany(body).pipe(
            tap(() => {
                this.toastyService.success(this.translate.instant('auth.company.companyCreated'));
                this.router.navigateByUrl('/pages');
            }),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error.results.error);
                return throwError(error);
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cdr.markForCheck();
            })
        ).subscribe();
        }
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.createCompanyForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    private initCreateCompanyForm(): void {
        this.createCompanyForm = this.formBuilder.group({
            name: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            cif: [''],
            vat: [''],
            type: ['person', Validators.required],
            address_id: [null, Validators.required],
            coords: [null, Validators.required]
        });

      if(this.type === 'person') {
        this.createCompanyForm.get('name').setValue(localStorage.getItem('temp_user_name'))
      }
    }

    public addAddressModal(): void {
        this.selectedAddress = null;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }
  
    public editAddress(address: AddressModel): void {
        this.selectedAddress = address;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

    geocode(address: string): Promise<any> {
        const geocoder = new google.maps.Geocoder();
        return new Promise((resolve, reject) => {
          geocoder.geocode(
            {
              address: address
            },
            (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                resolve(results[0]);
              } else {
                reject(new Error(status));
              }
            }
          );
        });
      }

    addressAdd(ev) {
        this.fullAddress = ev;
        this.addedAddress.emit(ev);
        this.createCompanyForm.get('address_id').patchValue(ev.id)
        this.addressString =  ev.city + ', ' + ev.country;
        console.log(this.addressString);
        this.geocode(this.addressString).then(place => {
          this.currentCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
          
          this.markers = [];
            this.markers.push({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            draggable: true,
            content: place.geometry.location.lat() +', '+ place.geometry.location.lng(),
          });
          this.coords.emit({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
          this.coordsString = 'Point(' + place.geometry.location.lat() + ' ' + place.geometry.location.lng() + ')';
          this.createCompanyForm.get('coords').patchValue(this.coordsString)
          console.log(this.coordsString);
        })
      }
    
      addressUpdate(ev) {
        this.fullAddress = ev;
        this.updatedAddress.emit(ev);
        this.createCompanyForm.get('address_id').patchValue(ev.id)
        this.addressString =  ev.city + ', ' + ev.country;
        console.log(this.addressString);
        this.geocode(this.addressString).then(place => {
          this.currentCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
          
          this.markers = [];
            this.markers.push({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            draggable: true,
            content: place.geometry.location.lat() +', '+ place.geometry.location.lng(),
          });
          this.coords.emit({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
          this.coordsString = 'Point(' + place.geometry.location.lat() + ' ' + place.geometry.location.lng() + ')';
          this.createCompanyForm.get('coords').patchValue(this.coordsString)
          console.log(this.coordsString);
        })
      }

      mapReady(event) {
        console.log(event);
        this.setCurrentPosition();
      }

      private setCurrentPosition() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.currentCenter.lat = position.coords.latitude;
            this.currentCenter.lng = position.coords.longitude;
            this.zoom = 15;
          });
        }
      }

}
