<form [formGroup]="cultureForm" class="mt-0 p-0 col-12">
    <!-- <div class="card-header-wrap d-flex align-items-center mb-4 flex-wrap" style="display: none;">
        <h2 class="card-header-title mr-4">{{ 'farm.crop.startNewCulture' | translate }}</h2>
        <button *ngIf="showDeleteCultureBtn && enableDelete" class="btn btn-danger px-2" type="button" (click)="deletedCulture.emit()">
            <i class="material-icons mr-1">delete_outline</i>
            {{ 'farm.crop.deleteCulture' | translate }}
        </button>
    </div> -->
    <div class="form-grid">
        <div class="row align-items-baseline cform">
            <div class="col-4">
            <mat-form-field style="max-width: 100%">
                <mat-label>Crop product</mat-label>
                <mat-select [alwaysCallback]="true" panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()" #mys formControlName="product" name="product" required (infiniteScroll)="scrolledDown()" msInfiniteScroll formControlName="product" name="product" required>
                    <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #typeFilter placeholder="Search" (keydown.enter)="filterProduct(typeFilter.value); $event.stopPropagation()">
                    </mat-form-field>
                    <mat-label style="position: absolute; right: 65px; cursor: pointer;" (click)="filterProduct(typeFilter.value)">
                        <i class="fa fa-search f-20"></i>
                    </mat-label>
                    <div class="input-group-append pl-1" (click)="showProductModal()">
                        <span class="input-group-text">
                            <i class="icofont icofont icofont-plus"></i>
                         </span>
                    </div>
                </div>
                <div class="scroll_wrap" (scroll)="onScroll($event)">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let product of products" [value]="product.id">
                        <span class="mat-option-text">
                        <div class="name">{{ product?.name }}</div>
                        <div class="actions">
                            <i class="fa fa-edit" (click)="productEdit(product); $event.stopPropagation()"></i>
                            <i class="fa fa-trash" (click)="productDelete(product?.id); $event.stopPropagation()"></i>
                        </div>
                        </span>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
            </div>
            <div class="col-4">
            <mat-form-field style="max-width: 100%" (click)="start.open()">
                <mat-label>
                    {{ 'farm.crop.startedOn' | translate }}</mat-label>
                <input matInput readonly formControlName="start" [matDatepicker]="start">
                <mat-datepicker-toggle matSuffix [for]="start">
                </mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            </div>
            <div class="col-4">
            <mat-form-field style="max-width: 100%">
                <input formControlName="progress" matInput placeholder="{{ 'farm.crop.progress' | translate }}">
            </mat-form-field>
            </div>
            <div class="col-4">
                <div class="row clearfix">
                    <div class="col-7 pr-0">
                        <mat-form-field style="max-width: 100%">
                            <input formControlName="rowDistance" style="text-align: right; padding-right: 10px;"  matInput placeholder="{{ 'farm.crop.rowDistance' | translate }}">
                        </mat-form-field>
                    </div>
                    <div class="col-5 pl-0">
                        <mat-form-field style="max-width: 100%; margin-left: -1px;">
                            <mat-label>Unit</mat-label>
                        <mat-select class="select u" required formControlName="distUnit" (selectionChange)="setUnit($event)">
                            <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #unitFilter placeholder="Search">
                                </mat-form-field>
                                <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                            </div>
                            <div class="scroll_wrap">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-optgroup class="noteIcons" *ngFor="let parent of areaUnits | filter : unitFilter.value">
                                <span class="mat-option-text">
                                    <div class="type-name_title">
                                        <span class="theme_color">{{parent.name}}</span>
                                    </div>
                                </span>
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                    <div class="type-name">
                                        <span>{{child.shortName}}</span>
                                    </div>
                                </mat-option>
                            </mat-optgroup>
                        </div>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row clearfix">
                    <div class="col-7 pr-0">
                        <mat-form-field style="max-width: 100%">
                            <input formControlName="plantDistance" style="text-align: right; padding-right: 10px;" matInput placeholder="{{ 'farm.crop.plantDistance' | translate }}">
                        </mat-form-field>
                    </div>
                    <div class="col-5 pl-0">
                        <mat-form-field style="max-width: 100%; margin-left: -1px;">
                            <mat-label>Unit</mat-label>
                        <mat-select class="select u" required formControlName="distUnit" (selectionChange)="setUnit($event)">
                            <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #unitFilter placeholder="Search">
                                </mat-form-field>
                                <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                            </div>
                            <div class="scroll_wrap">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-optgroup class="noteIcons" *ngFor="let parent of areaUnits | filter : unitFilter.value">
                                <span class="mat-option-text">
                                    <div class="type-name_title">
                                        <span class="theme_color">{{parent.name}}</span>
                                    </div>
                                </span>
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                    <div class="type-name">
                                        <span>{{child.shortName}}</span>
                                    </div>
                                </mat-option>
                            </mat-optgroup>
                        </div>
                        </mat-select>
                    </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-4 mbtm">
                <div class="d-flex">
                    <button class="bbtn clsbtn" *ngIf="!markedAsEnded" (click)="markedAsEnded=true;emitCultureChanged()">{{ 'farm.crop.markEnded' | translate }}</button>
                    <button class="bbtn clsbtn" *ngIf="markedAsEnded" (click)="markedAsEnded=false;emitCultureChanged()">{{ 'farm.crop.unmarkEnded' | translate }}</button>
                    <button class="bbtn dltbtn"><i class="fa fa-trash" (click)="deletedCulture.emit()"></i></button>
                </div>
            </div>
            
            <div class="col-3" *ngIf="crop">
                <div class="input-group align-items-baseline w-100">
                    <mat-form-field style="max-width: 100%">
                        <mat-label class="col-form-label">Yield</mat-label>
                        <input type="text" class="no-border" matInput formControlName="yield" readonly>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>


<div class="card mt-2" style="margin-bottom: 0" *ngIf="crop">
    <div class="card-header px-3 rounded-0 title_row" style="padding: 9px;">
        <p style="margin: 0; font-size: 18px;">Plantation used resources</p>
        <span (click)="goToBill()" style="margin-top: 0;" class="theme_color"></span>
    </div>
    <div class="notedit table-responsive">
        <table class="items_table">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Product</th>
                    <th>Forecasted <span>quantity</span></th>
                    <th>Forecasted <span>cost</span></th>
                    <th>Current <span>quantity</span></th>
                    <th>Current <span>cost</span></th>
                    <th>Savings</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of r_items">
                    <td>{{item?.product.id}}</td>
                    <td>{{item?.product?.name}} <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                        <span *ngIf="type?.id === item?.product?.type">({{type?.name}})</span>
                        <ng-container *ngIf="type?.id !== item?.product?.type">
                            <ng-container *ngFor="let childUnit of type?.children">
                                <span *ngIf="childUnit?.id === item?.product?.type">({{childUnit?.name}})</span> 
                            </ng-container>
                        </ng-container>
                        </ng-container></td>
                    <td>
                        <div class="frs">{{ myMath.abs(item?.forecasted?.qty | number: '1.0-2') }}</div>
                            <div class="inlin">
                                    <b *ngIf="item?.product?.quantity_pack !== 0 && item?.product?.quantity_pack !== 1">{{item?.product?.quantity_pack}}</b>
                                    
                                    <b>{{item?.product?.pack_unit?.short_name}}</b>
                                
                                    <b *ngIf="item?.product?.quantity !== 0 && item?.product?.quantity !== 1">/{{item?.product?.quantity}}</b>
                                    <b *ngIf="item?.product.quantity == 0 || item?.product?.quantity == 1">/</b>
                                    <b>{{item?.product?.unit?.short_name}}</b>
                                </div>
                    </td>
                    <td><span>{{myMath.abs(item?.forecasted?.cost | number:'1.'+globalRegistry.systemData.company.digits)}} </span> <span><b>{{item?.product?.currency?.short_name}}</b></span></td>
                    <td><span>{{myMath.abs(item?.used?.qty)}} </span> <span>
                        <b>{{item?.product?.unit?.short_name}}</b>
                    </span></td>
                    <td><span>{{myMath.abs(item?.used?.cost | number:'1.'+globalRegistry.systemData.company.digits)}} </span> <span>
                        <b>{{item?.product?.currency?.short_name}}</b>
                        </span>
                    </td>
                    <td><span>{{myMath.abs(item?.savings | number:'1.'+globalRegistry.systemData.company.digits)}} </span> <span><b>{{item?.product?.currency?.short_name}}</b>
                    </span></td>
             </tr>
         </tbody>
       </table>
       <div class="d-flex calcs">
       <div class="col-4" *ngIf="crop">
        <div class="input-group align-items-baseline w-100 custom_border">
            <mat-form-field class="custom_width no_border" style="max-width: 100%">
                <mat-label class="col-form-label">Forecasted Costs</mat-label>
                <input type="text" class="no-border" style="text-align: right;" matInput formControlName="usedForecastedCost" readonly>
            </mat-form-field>
            <div class="selected_unit">
                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                    <b *ngIf="unit?.id === currency">{{unit?.shortName}}</b>
                    <ng-container *ngIf="unit?.id !== currency">
                        <ng-container *ngFor="let childUnit of unit?.children">
                            <b *ngIf="childUnit?.id === currency">{{childUnit?.shortName}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="crop">
        <div class="input-group align-items-baseline w-100 custom_border">
            <mat-form-field class="custom_width no_border" style="max-width: 100%">
                <mat-label class="col-form-label">Current Costs</mat-label>
                <input type="text" class="no-border" style="text-align: right;" matInput formControlName="usedCurrentCost" readonly>
            </mat-form-field>
            <div class="selected_unit">
                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                    <b *ngIf="unit?.id === currency">{{unit?.shortName}}</b>
                    <ng-container *ngIf="unit?.id !== currency">
                        <ng-container *ngFor="let childUnit of unit?.children">
                            <b *ngIf="childUnit?.id === currency">{{childUnit?.shortName}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="crop">
        <div class="input-group align-items-baseline w-100 custom_border">
            <mat-form-field class="custom_width no_border" style="max-width: 100%">
                <mat-label class="col-form-label">Savings</mat-label>
                <input type="text" class="no-border" style="text-align: right;" matInput formControlName="usedSavings" readonly>
            </mat-form-field>
            <div class="selected_unit">
                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                    <b *ngIf="unit?.id === currency">{{unit?.shortName}}</b>
                    <ng-container *ngIf="unit?.id !== currency">
                        <ng-container *ngFor="let childUnit of unit?.children">
                            <b *ngIf="childUnit?.id === currency">{{childUnit?.shortName}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    </div>
    </div>
</div>

<div class="card mt-2" style="margin-bottom: 0" *ngIf="crop">
    <div class="card-header px-3 rounded-0 title_row" style="padding: 9px;">
        <p style="margin: 0; font-size: 18px;">Plantation produced resources</p>
        <span (click)="goToBill()" style="margin-top: 0;" class="theme_color"></span>
    </div>
    <div class="notedit table-responsive">
        <table class="items_table">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Product</th>
                    <th>Forecasted <span>quantity</span></th>
                    <th>Forecasted <span>price</span></th>
                    <th>Produced <span>quantity</span></th>
                    <th>Selling <span>price</span></th>
                    <th>Savings</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of p_items">
                    <td>{{item?.product.id}}</td>
                    <td>{{item?.product?.name}} <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                        <span *ngIf="type?.id === item?.product?.type">({{type?.name}})</span>
                        <ng-container *ngIf="type?.id !== item?.product?.type">
                            <ng-container *ngFor="let childUnit of type?.children">
                                <span *ngIf="childUnit?.id === item?.product?.type">({{childUnit?.name}})</span> 
                            </ng-container>
                        </ng-container>
                        </ng-container></td>
                    <td>
                        <div class="frs">{{ myMath.abs(item?.forecasted?.qty | number: '1.0-2') }}</div>
                            <div class="inlin">
                                    <b *ngIf="item?.product?.quantity_pack !== 0 && item?.product?.quantity_pack !== 1">{{item?.product?.quantity_pack}}</b>
                                    
                                    <b>{{item?.product?.pack_unit?.short_name}}</b>
                                
                                    <b *ngIf="item?.product?.quantity !== 0 && item?.product?.quantity !== 1">/{{item?.product?.quantity}}</b>
                                    <b *ngIf="item?.product.quantity == 0 || item?.product?.quantity == 1">/</b>
                                    <b>{{item?.product?.unit?.short_name}}</b>
                                </div>
                    </td>
                    <td><span>{{item?.forecasted?.cost | number:'1.'+globalRegistry.systemData.company.digits}} </span> <span><b>{{item?.product?.currency?.short_name}}</b></span></td>
                    <td><span>{{item?.used?.qty}} </span> <span>
                        <b>{{item?.product?.unit?.short_name}}</b>
                    </span></td>
                    <td><span>{{item?.used?.cost | number:'1.'+globalRegistry.systemData.company.digits}} </span> <span>
                        <b>{{item?.product?.currency?.short_name}}</b>
                        </span>
                    </td>
                    <td><span>{{item?.savings | number:'1.'+globalRegistry.systemData.company.digits}} </span> <span><b>{{item?.product?.currency?.short_name}}</b>
                    </span></td>
             </tr>
         </tbody>
       </table>
       <div class="d-flex calcs">
       <div class="col-4" *ngIf="crop">
        <div class="input-group align-items-baseline w-100 custom_border">
            <mat-form-field class="custom_width no_border" style="max-width: 100%">
                <mat-label class="col-form-label">Forecasted Price</mat-label>
                <input type="text" class="no-border" style="text-align: right;" matInput formControlName="producedForecastedCost" readonly>
            </mat-form-field>
            <div class="selected_unit">
                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                    <b *ngIf="unit?.id === currency">{{unit?.shortName}}</b>
                    <ng-container *ngIf="unit?.id !== currency">
                        <ng-container *ngFor="let childUnit of unit?.children">
                            <b *ngIf="childUnit?.id === currency">{{childUnit?.shortName}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="crop">
        <div class="input-group align-items-baseline w-100 custom_border">
            <mat-form-field class="custom_width no_border" style="max-width: 100%">
                <mat-label class="col-form-label">Selling Price</mat-label>
                <input type="text" class="no-border" style="text-align: right;" matInput formControlName="producedCurrentCost" readonly>
            </mat-form-field>
            <div class="selected_unit">
                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                    <b *ngIf="unit?.id === currency">{{unit?.shortName}}</b>
                    <ng-container *ngIf="unit?.id !== currency">
                        <ng-container *ngFor="let childUnit of unit?.children">
                            <b *ngIf="childUnit?.id === currency">{{childUnit?.shortName}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="col-4" *ngIf="crop">
        <div class="input-group align-items-baseline w-100 custom_border">
            <mat-form-field class="custom_width no_border" style="max-width: 100%">
                <mat-label class="col-form-label">Profit</mat-label>
                <input type="text" class="no-border" style="text-align: right;" matInput formControlName="producedSavings" readonly>
            </mat-form-field>
            <div class="selected_unit">
                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                    <b *ngIf="unit?.id === currency">{{unit?.shortName}}</b>
                    <ng-container *ngIf="unit?.id !== currency">
                        <ng-container *ngFor="let childUnit of unit?.children">
                            <b *ngIf="childUnit?.id === currency">{{childUnit?.shortName}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    </div>
    </div>
</div>
</form>

<!-- <div class="row">
    <div class="col-md-12 mt-2">
        <fap-resource-table
            [parties]="parties"
            [resourceTypes]="resourceTypes"
            [farmId]="farmId"
            [resources]="crop?.usedResources"
            [headerTitle]="'Required resources'"
            (addStockOutcome)="addStockOutcome.emit($event)"
            (deleteParty)="deleteParty.emit($event)">
        </fap-resource-table>
    </div>
    <div class="col-md-12 mt-2">
        <fap-resource-table
            [parties]="parties"
            [resourceTypes]="resourceTypes"
            [farmId]="farmId"
            [headerTitle]="'Production'"
            [isIncome]="true"
            (addStockIncome)="addStockIncome.emit($event)"
            (deleteParty)="deleteParty.emit($event)">
        </fap-resource-table>
    </div>
</div> -->
<fap-modal #addEditCropTypeModal inputTitle="{{ 'farm.crop.addEditCropType' | translate }}">
    <fap-add-edit-crop-type [farmId]="farmId" [cropType]="selectedCropType" (updateCropType)="updateCropType($event)"
        (addCropType)="addCropType($event)">
    </fap-add-edit-crop-type>
</fap-modal>

<fap-modal #addEditUnitModal 
inputTitle="{{ 'units.addEditUnit' | translate }}">
<form [formGroup]="unitForm" class="unit_form col-md-12 p-0">
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.name' | translate }}</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('name','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.shortName' | translate }}</mat-label>
            <input matInput formControlName="shortName">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('shortName','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.genre' | translate }}</mat-label>
            <!-- <input matInput formControlName="genre"> -->
            <mat-select required formControlName="genre">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #genreFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let genre of this.globalRegistry.genres | filter : genreFilter.value" [value]="genre?.indicator">
                    {{ genre?.name }} ( <b>{{genre?.indicator}}</b> )
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('genre','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group" *ngIf="units">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'stock.parent' | translate }}</mat-label>
            <mat-select required formControlName="parent" [disabled]="!parentUnit && currentUnit">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #unitFilter placeholder="Search">
                </mat-form-field>
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option [value]="'new'" class="font-weight-bold">
                    {{'units.newParent' | translate}}
                </mat-option>
                <mat-option *ngFor="let parent of units | filter : unitFilter.value" [value]="parent?.id">
                    {{ parent?.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('parent','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 mt-3 text-center">
        <button class="btn btn-lg" type="button" (click)="submit()">{{'resources.submitBtn' | translate}}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>
</fap-modal>

<fap-modal #addEditPartyModal
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
    (addedParty)="addParty($event)"
    (updatedParty)="addedOrUpdatedParty($event)">
    </fap-add-edit-party>
</fap-modal>

<fap-modal #addEditProductModal
    inputTitle="Add/Edit Product">
        <fap-add-edit-product [productId]="productId"
        (addedProduct)="addProductAction($event);addEditProductModal.hideModal()"
        (updatedProduct)="updateProductAction($event);addEditProductModal.hideModal()">
        </fap-add-edit-product>
</fap-modal>
