<div class="container-fluid ter-container nc"
infiniteScroll
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="500"
  (scrolled)="scrolledDown()"
  [scrollWindow]="false"
  style="height: 100vh; flex: auto;">
     <fap-people-layout 
        key="people"
        [enableAddEdit]="enableAddEdit"
        [enableDelete]="enableDelete"
        [noFarmPersons]='noFarmPersons'
        [persons]="filteredPersons"
        [farms]='filteredFarms'
        [farmForecastMapping]="farmForecastMapping"
        (removePerson)="removePerson($event)">
     </fap-people-layout>
 </div>
