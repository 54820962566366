import { NameValueInterface } from '../../../../core/interfaces/general/name-value.interface';

/**
 * @todo Maybe it's better to move this helper somwhere else in the future
 */
export class PrecisionHelper {
    public static get precisionTypes(): Array<NameValueInterface> {
        return [
            {
                name: 'RGB',
                value: 'agri:rgb_sentinel2'},
            {
                name: 'NIR',
                value: 'agri:nir'},
            {
                name: 'EVI2',
                value: 'agri:evi2'},
            {
                name: 'GNVDI',
                value: 'agri:gndvi'},
            {
                name: 'IPVI',
                value: 'agri:ipvi'},
            {
                name: 'LAI',
                value: 'agri:lai'},
            {
                name: 'NVDI',
                value: 'agri:ndvi'},
            {
                name: 'SAVI',
                value: 'agri:savi'},
            {
                name: 'OSAVI',
                value: 'agri:osavi'},
            {
                name: 'GCI',
                value: 'agri:gci'},
            {
                name: 'NDWI',
                value: 'agri:ndwi'},
            {
                name: 'WDRVI',
                value: 'agri:wdrvi'
            }
        ];
    }
}
