import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalRegistryService } from "../../../core/global-registry/global-registry.service";
import { DrillParams } from "../../../core/interfaces/widget/drill-info.interface";
import { SensorModel } from "../../../core/models/sensor/sensor.model";
import { WidgetModel } from "../../../core/models/widget/widget.model";
import { ObjectModel } from "../../../core/services/api/objects/object.service";
import { SensorService } from "../../../core/services/api/sensor/sensor.service";
import { UnitTypeModel } from "../../../core/services/api/unit-type/unit-type.service";
import { TableUtil } from "./tableUtil";
import { NavService } from "../../services/nav.service";
import moment from "moment";

@Component({
  selector: "fap-table-widget",
  templateUrl: "./fap-table-widget.component.html",
  styleUrls: ["./fap-table-widget.component.scss"],
})
export class FapTableWidgetComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    public globalRegistry: GlobalRegistryService,
    public sensorService: SensorService,
    public navService: NavService
  ) {}

  @Input() public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() bgColor = '';

  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  public params: DrillParams;

  public columns: any[] = [];

  public dataSource: any[] = [];
  public finalSensorList = [];
  public subscription: Subscription;
  public sensorIds = [];
  public sensorNames = [];
  public subscription1: Subscription;
  public subscription2: Subscription;
  public subscription3: Subscription;
  public subscription4: Subscription;
  public fromDate = new Date();
  public toDate =  new Date();
  public agg = 'day';
  public trigger = true;
  public toggleMain = false;
  public toggleSub = false;
  public tglShow = false;
  public tableRows = [];
  public tableDates = [];
  public rowNames = [];
  private subscriptions: Array<Subscription> = [];
  public randomId;
  public translatedNames: any = [];
  public langString: string;
  public noData = false;

  ngOnInit(): void {
    this.randomId = Math.random();
    this.getFromDate();
    this.getToDate();
    this.getAgg();
    this.subscription = this.sensorService.getName.subscribe(res => {
      if(res) {
        this.dataSource = [];
        this.tableRows = [];
        this.drillData();
      }
    });
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
        if(t[0][this.langString] != '') {
            return t[0][this.langString];
        } else {
            return
        }
    } else {
        return translation
      }
  }

  getFromDate() {
    this.subscription1 = this.sensorService.getFromDate.subscribe(res => {
      this.fromDate = res;
    });
  }

  getToDate() {
    this.subscription2 = this.sensorService.getToDate.subscribe(res => {
      this.toDate = res;
    });
  }

  getTrigger() {
    this.subscription4 = this.sensorService.getName.subscribe(res => {
      this.trigger = res;
    });
  }

  getAgg() {
    this.subscription3 = this.sensorService.getAgg.subscribe(res => {
      this.agg = res;
    });
  }

  tglValues() {
    this.columns = [];
    this.tglShow = !this.tglShow;
    this.drillData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  ngOnChanges() {
    this.dataSource = [];
      this.drillData();
  }

  exportTable() {
    TableUtil.exportTableToExcel('ExampleMaterialTable'+this.randomId);
  }

  hideMain() {
    this.toggleMain = false
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  public drillData() {
    this.dataSource = [];
    setTimeout(()=> {
      const sensors: Array<SensorModel> = this.widget.config["settings"]["options"].map(
        (sensor: { sensor: SensorModel }) => sensor?.sensor
      );
      const sensorIds = sensors.map((sensor) => sensor.id);
      this.subscriptions.push(this.sensorService.advdrillData({ sensors: sensorIds.toString(), from:this.fromDate.toISOString(),agg_by: this.agg, to:this.toDate.toISOString(), agg_data:'mean,sum,min,max', compare:0}).subscribe(data1 => {
        const Newdata = data1.results;
        const data = Newdata['0'];
        console.log(data);
        const objects = Object.values(this.objects);
        const unitTypes = Object.values(this.unitTypes);
          const arr = Object.keys(data).map(function (key) {
            return { ...data[key], sensor: key, names: '' };
          });
          arr.pop();
          this.widget.config['settings']['options'] = this.widget.config['settings'][
            'options'
        ].map((item) => {
            return {
                ...item,
                unitTypes: unitTypes.filter(
                    (el) => el.id === item.sensor.unitType
                ),
                objects: objects.filter(
                    (el) => el.id === item.sensor.object_id
                ),
            };
          });
          console.log(this.widget.config['settings']['options']);
          const datesData = Object.keys(data).map(function (key) {
            return { [key]: { ...data[key]} };
          });
          const dates = datesData.pop();
          let datesArr = Object.values(dates.labels);
          datesArr = datesArr.reverse();
          const seriesDates = [];
          const tableDates = [];
        //   function toDateTime(secs) {
        //     var t = new Date(1970, 0, 1); // Epoch
        //     t.setSeconds(secs).toLocaleString();
        //     return t;
        // }
        //   datesArr.forEach(element => {
        //     seriesDates.push(toDateTime(element));
        //   });
          datesArr.forEach((element: any) => {
            const date = new Date(element * 1000);
            seriesDates.push(date.toLocaleString('en-US'));
          });
          seriesDates.forEach(element => {
            if(this.agg == 'hour') {
              element = moment.utc(element).local().format('DD MMM YYYY, HH') + ' h'
            }
            if(this.agg == 'month') {
              element = moment.utc(element).local().format('MMM YYYY')
            }
            tableDates.push(element);
          });
         this.tableDates = tableDates;
         
        arr.forEach(element => {
          element.date = this.tableDates;
        });
        this.widget.config['settings']['options'] = this.widget.config['settings']['options'].map((item, index) => {
          return {
            ...item,
            data: arr[index]
          };
        });
        console.log(this.widget.config['settings']['options']);
        
        arr.forEach(element => {
          this.widget.config['settings']['options'].forEach(e => {
            if(element.sensor == e.sensor.id) {
              element.name = element.name +'<span>' + e.unitTypes[0].unit + '</span>' 
            }
          });
        });
        console.log(arr);

        const names = [];
        arr.forEach(el => {
          names.push(el.name);
        });
        
        this.columns = names;
        this.rowNames = names;
        
        const objectArr = [];
        this.columns.forEach(element => {
          const object = {
            colspan: null,
            name: null
          };
          if(this.tglShow) {
            object.colspan = 4;
          } else {
            object.colspan = 1;
          }
          object.name = element;
          objectArr.push(object);
        });
        // this.columns.unshift('');
        const dateObj = {
          colspan: 1,
          name: 'date'
        }
        objectArr.unshift(dateObj);
        this.columns = objectArr;
        const rows = [];
        for (let index = 0; index < arr.length; index++) {
          const data = ['min', 'val', 'max', 'sum'];
          rows.unshift(data);
        }
        rows.unshift('');
        this.tableRows = rows.join(",").split(",");
        console.log(this.tableRows);
        const groupByDate = arr => (
          Object.values(       
            arr.reduce(         
              (acc, ob) => {
                console.log(ob);
                const {name, date, aggr_type, min, max, sum} = ob;
                const tgt = ob[aggr_type];    
                date.forEach(   
                  (d, i) => {   
                    acc[d] = {
                      ...(acc[d] || {}),
                      date: d,
                      [name]: [min[date.length - 1 - i], tgt ? tgt[date.length - 1 - i] : '-', max[date.length - 1 - i], sum[date.length - 1 - i]],    
                    }
                  }
                )
                return acc;        
              },
              {}                  
            )
          )
        );
        
        const result = groupByDate(arr);
        this.dataSource = result;
        // this.dataSource.unshift(this.tableRows);
        console.log(this.dataSource);
            if(this.dataSource.length === 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
          const table = document.getElementById('ExampleMaterialTable'+this.randomId);
          const tableBody = document.getElementById("table-body"+this.randomId);
          tableBody.innerHTML = '';
          if(table && tableBody) {
        if(this.dataSource.length != 0) {
          this.dataSource.forEach((mark) => {
            const tr = document.createElement("tr");
            const { date, ...subs } = mark;
        
            const td = document.createElement("td");
            const aggClassName = this.agg === 'hour' ? 'mw120' : '';
            td.className = `stick ${aggClassName} ${this.bgColor}`;
            td.style.backgroundColor = this.bgColor;
            
            const formattedDate = (this.agg === 'day') ? moment(date).format('DD MMM YYYY') : date;
            td.textContent = formattedDate;
            tr.appendChild(td);
        
            Object.values(subs).forEach(([min, val, max, sum]) => {
                tr.innerHTML += `<td class="minVal">${min || '0'}</td><td>${val || '0'}</td><td class="maxVal">${max || '0'}</td><td class="sumVal">${sum || '0'}</td>`;
            });
        
            tableBody.appendChild(tr);
        });
        
      }
    }
      }));
      this.sensorService.setName(false);
  }, 1000);

  }

  public getLotName(lotId: number = this.widget.objectId): string {
    return this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId)
      .name;
  }
}
