import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { ResponseModel } from '../../../models/response.model';
import { CropInterface } from './data/crop.interface';
import { CropModel } from '../../../models/crops/crop.model';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { DiseaseModel } from '../../../models/diseases/disease.model';
import { TreatmentModel } from '../../../models/diseases/treatment.model';
import * as CryptoJS from 'crypto-js'


@Injectable()
export class CropService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/crop/');
    }

    public beginCrop(crop: CropInterface): Observable<ResponseModel<CropModel>> {
        return this.mapRequest<CropModel>(
            this._http.post(this.url + 'crops/',
                this.camelCaseModelToSnakeCaseJson(crop),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            CropModel,
            false
        ).pipe(take(1));
    }

    public updateCrop(cropId: number, crop: CropInterface): Observable<ResponseModel<CropModel>> {
        return this.mapRequest<CropModel>(
            this._http.patch(this.url + 'crops/' + cropId + '/',
                this.camelCaseModelToSnakeCaseJson(crop),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            CropModel,
            false
        ).pipe(take(1));
    }

    public deleteCrop(cropId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'crops/' + cropId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public getCrops(): Observable<ResponseModel<CropModel[]>> {
        return this.mapRequest<CropModel[]>(
            this._http.get(this.url + 'crops/',
                FapAPIRequestOptions.withTokenRequestOptions),
                CropModel,
            true
        ).pipe(take(1));
    }

    public getCrop(cropId: number): Observable<ResponseModel<CropModel>> {
        return this.mapRequest<CropModel>(
            this._http.get(this.url + 'crops/' + cropId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
                CropModel,
            true
        ).pipe(take(1));
    }

    public getAlertData(lotId:number, diseaseId:number): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'alerts/?lot='+lotId+'&disease='+diseaseId,
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        ).pipe(take(1));
    }

    public getHerbicideData(lotId:number, activityId:number): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'herbicide/?lot='+lotId+'&activity='+activityId,
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        ).pipe(take(1));
    }

    public getHerbicideEfficacity(activityId: number): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.get(this.url + 'herbicide_efficacity/?activity='+activityId,
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        ).pipe(take(1));
    }

    public getDiseases(params?: {}): Observable<ResponseModel<DiseaseModel[]>> {
        return this.mapRequest<DiseaseModel[]>(
       this._http.get(this.url + 'diseases/',                 {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            })
            .pipe(map((x) => {
            return this.decryptResponse(x['raw'])
        })), false).pipe(take(1))
    }

    decryptResponse(r) {
        const token = localStorage.getItem('auth_token').substring(0, 32);
        const _key = CryptoJS.enc.Utf8.parse(token);
        const decrypted = CryptoJS.AES.decrypt(
          r, _key, {
            keySize: 32,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8);
          return JSON.parse(decrypted);
        }

    public getDisease(diseaseId:number): Observable<ResponseModel<DiseaseModel>> {
        return this.mapRequest<DiseaseModel>(
            this._http.get(this.url + 'diseases/'+ diseaseId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
                DiseaseModel,
            false
        ).pipe(take(1));
    }

    public getTreatments(): Observable<ResponseModel<TreatmentModel[]>> {
        return this.mapRequest<TreatmentModel[]>(
            this._http.get(this.url + 'treatments/',
                FapAPIRequestOptions.withTokenRequestOptions),
                TreatmentModel,
            true
        ).pipe(take(1));
    }

    public getForecastInfo(id: number): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.get(this.url + 'costs/?id='+id,
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));

    }
}
