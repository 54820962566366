import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    OnDestroy,
    OnInit
} from '@angular/core';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { TranslateService } from '@ngx-translate/core';
import {
    FapModalButtonInterface
} from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { FapModalComponent } from '../../../../../shared/partials';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { CropService } from '../../../../../core/services/api/crop/crop.service';
import { CropModel } from '../../../../../core/models/crops/crop.model';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
    selector: 'fap-people-layout',
    templateUrl: './fap_people-layout.component.html',
    styleUrls: ['./fap_people-layout.component.scss']
})
export class FapPeopleLayoutComponent implements OnDestroy, OnInit {

    @Input()
    public persons: Array<PersonModel> = [];

    @Input()
    public key: string;

    @Input()
    public enableAddEdit:boolean;

    @Input()
    public enableDelete:boolean;

    @Input()
    public noFarmPersons: Array<PersonModel> = [];

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public farmForecastMapping: { [key: number]: WeatherModel } = {};

    @Output()
    public removePerson: EventEmitter<number> = new EventEmitter();

    @ViewChild('list')
    public list: MatSelectionList;

    @ViewChild('removePersonModal')
    public removePersonModal: FapModalComponent;
    
    public crops: Array<CropModel> = [];

    public removePersonModalButtonPrimaryInterface: FapModalButtonInterface;
    public removePersonModalButtonSecondaryInterface: FapModalButtonInterface;

    public selectedPerson: PersonModel;
    public images;
    public subscription: Subscription = new Subscription();

    constructor(public translateService: TranslateService, public globalRegistry: GlobalRegistryService, public cropService:CropService, public companyService: CompanyService, public cacheService: CacheResolverService, public widgetService: WidgetsService) {
        this.initRemovePersonModalButtons();
    }

    ngOnInit() {
        console.log(this.persons);
        this.globalRegistry.reloadPersons();
        this.persons = this.globalRegistry.systemData.persons
        // this.getImages();
        this.subscription.add(this.cropService.getCrops().subscribe(data => {
            this.crops = data.model;
          }));
          this.widgetService.setSize(5);
    }

    public getImages() {
        const url = this.companyService.getUrl('images/')
        this.cacheService.delete(url+'group=posts');
        this.companyService.getCompanyImages({group: 'posts'}).subscribe((response): void => {
            this.images = response.body["results"];
            console.log(this.images);
       })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.widgetService.setSize(6);
    }

    public showRemovePersonModal(person: PersonModel): void {
        this.selectedPerson = person;
        this.removePersonModal.showModal();
    }

    private initRemovePersonModalButtons(): void {
        this.removePersonModalButtonSecondaryInterface = {
            clickFunction: (): void => {
                this.removePerson.emit(this.selectedPerson.id);
                this.removePersonModal.hideModal();
            },
            text: this.translateService.instant('delete')
        };
        this.removePersonModalButtonPrimaryInterface = {
            clickFunction: (): void => {
                this.removePersonModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
    }

}
