import { FapBaseModel } from '../../base/fap-base-model';


export class AttributeModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'mandatory_for',
        'is_default',
        'is_used',
        'color',
        'data',
        'entity',
        'icon',
        'input_type',
        'locked',
        'name_t',
        'uniqueid',
        'value_type',
        'unit',
        'type'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public mandatoryFor: string[] = [];
    public isDefault: boolean;
    public isUsed?: boolean;
    public tempId: string;
    public color: string;
    public icon: any;
    public data: any;
    public entity: any;
    public locked: boolean;
    public nameT: any;
    public unit: any;
    public type: any;
    public uniqueid: string;
    public valueType: string
    public inputType: string
}
