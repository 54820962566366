<div class="container-fluid ter-container crops-container-scroll">
    <fap-crops-layout 
        key = "lots"
        [farms]="filteredFarms"
        [lots]="filteredLots"
        [farmForecastMapping]="farmForecastMapping"
        (clickedLot)="centerMapOnLot($event)"
        (deleteLot)="deleteLot($event)">
    </fap-crops-layout>
</div>
