import { FapBaseModel } from '../../base/fap-base-model';
import { CropTypeModel } from '../type/crop-type.model';
import { ResourceEntryModel } from '../stock/resource-entry.model';
import { PhenologyModel } from './phenology.model';


export class CropModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'lot',
        'start',
        'end',
        'progress',
        'crop_type',
        'used_resources',
        'phenology',
        'dist_row',
        'dist_plant',
        'dist_unit',
        'product',
        'forecast_doc',
        'forecast_cost',
        'total_cost',
        'crop_yield'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'lot',
        'start',
        'end',
    ];

    public static extendedFields: {} = {
        crop_type: CropTypeModel,
    };

    public id: number;
    public lot: number;
    public start: string;
    public end: string;
    public progress: string;
    public cropType: CropTypeModel = new CropTypeModel();
    public usedResources: Array<ResourceEntryModel> = [];
    public phenology?: Array<PhenologyModel> = [];
    public distRow: number;
    public distPlant: number;
    public distUnit: any;
    public product?: number;
    public forecastDoc?: number;
    public forecastCost?: number;
    public totalCost?: number;
    public cropYield?: number;
}
