import {
    Component,
    Input,
    EventEmitter,
    Output,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { NameValueInterface } from '../../../../../core/interfaces/general/name-value.interface';
import { FapStaticDataService } from '../../../../../core/static-data/fap-static-data.service';
import { EquipmentTypeModel } from '../../../../../core/models/type/equipment-type.model';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import * as moment from 'moment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'fap-equipment-card',
    templateUrl: './fap_equipment-card.component.html',
    styleUrls: ['./fap_equipment-card.component.scss']
})
export class FapEquipmentCardComponent implements OnChanges {

    @Input()
    public equipment: EquipmentModel;

    @Input()
    public equipmentTypes: Array<EquipmentTypeModel>;

    @Input()
    public equipmentPoweredTypes: Array<NameValueInterface> = FapStaticDataService.equipmentPoweredTypesStaticData();

    @Input()
    public parties: Array<PartyModel>;

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public images;

    @Output()
    public deleteEquipment: EventEmitter<number> = new EventEmitter();

    public equipmentTypeNames: {} = {};
    public equipmentPoweredTypeNames: {} = {};
    public partyNames: {} = {};
    public mediaUrl = environment.mediaUrl

    constructor(public globalRegistryService: GlobalRegistryService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'equipmentTypes') || Object.prototype.hasOwnProperty.call(changes, 'equipmentPoweredTypes')) {
            if (this.equipmentTypes && this.equipmentPoweredTypes && this.parties) {
                this.equipmentTypes.forEach((equipmentType: EquipmentTypeModel): void => {
                    this.equipmentTypeNames[equipmentType.id] = equipmentType.name;
                });
                this.equipmentPoweredTypes.forEach((equipmentPoweredType: any): void => {
                    this.equipmentPoweredTypeNames[equipmentPoweredType.id] = equipmentPoweredType.name;
                });
            }
        }
    }

    calcTime(time) {
        return moment.duration(time).asMinutes();
    }
}
