<form [formGroup]="ingredientForm">
    <div class="form-grid">
        <div class="d-flex">
            <mat-form-field class="w-100">
                <mat-label>{{ 'stock.inredientType.dropdownName' | translate }}</mat-label>
                <mat-select #mys class="select" formControlName="ingredientType" required matNativeControl>
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #ingredientFilter placeholder="Search">
                    </mat-form-field>
                    <em class="fa fa-plus center f-20 ml-2" (click)="addIngredientType()"></em>
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="row" *ngFor="let type of globalRegistry?.systemData?.ingredientTypes | filter : ingredientFilter.value" style="margin: 0"
                        [value]="type.id">
                        <div class="crop-type-name">
                           
                            <span >{{type?.name}}</span>    
                        </div>
                        <div class="crop-type-actions actions">
                            <i class="fa fa-edit center f-20"
                                (click)="addIngredientType(type); $event.stopPropagation()"></i>
                            <i class="fa fa-trash center ml-2 f-20" (click)="deleteIngredientType(type.id); $event.stopPropagation()"></i>
                        </div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="w-100" *ngIf="globalRegistry.systemData.ingredientTypes">
                <mat-label>{{'stock.ingredients.ingredientSubType' | translate}}</mat-label>
                <input type="text" matInput formControlName="ingredientSubType" placeholder="{{ 'stock.ingredients.ingredientSubType' | translate }}">
            </mat-form-field>
        </div>
            <!-- <mat-form-field class="w-50">
                <mat-label>{{ 'stock.ingredients.ingredientQuantity' | translate }}</mat-label>
                <input type="number" matInput required formControlName="ingredientQuantity" placeholder="{{ 'stock.ingredients.ingredientQuantity' | translate }}">
            </mat-form-field> -->
            <div class="d-flex row">
                <div class="col-6">
                    <mat-form-field class="w-50">
                        <mat-label>{{ 'stock.ingredients.ingredientMinValue' | translate }}</mat-label>
                        <input type="number" matInput formControlName="ingredientMinValue" placeholder="{{ 'stock.ingredients.ingredientMinValue' | translate }}">
                    </mat-form-field>
                    <mat-form-field class="w-50">
                        <mat-label>{{ 'stock.ingredients.ingredientMaxValue' | translate }}</mat-label>
                        <input type="number" matInput formControlName="ingredientMaxValue" placeholder="{{ 'stock.ingredients.ingredientMaxValue' | translate }}">
                    </mat-form-field>
                    </div>
            <mat-form-field class="col-6">
                <mat-label>{{ 'stock.ingredients.ingredientUnit' | translate }}</mat-label>
                <!-- <input matInput required formControlName="ingredientUnit" placeholder="{{ 'stock.ingredients.ingredientUnit' | translate }}"> -->
                <mat-select #mySelect class="select" required formControlName="ingredientUnit">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #u1Filter placeholder="Search">
                        </mat-form-field>
                        <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                    </div>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                    <mat-optgroup class="noteIcons currs" *ngFor="let parent of globalRegistry?.systemData?.units | filter : u1Filter.value">
                        <div class="frow w-100">
                            <div class="barr">
                            <div class="type-name">
                                    <span *ngIf="parent?.nameT">
                                        <span *ngIf="getTranslation(parent?.nameT) === '' || getTranslation(parent?.nameT) == null || getTranslation(parent?.nameT) == undefined ">{{parent?.name}}</span>
                                        <span *ngIf="getTranslation(parent?.nameT) !== ''">{{getTranslation(parent?.nameT)}}</span>
                                    </span>
                                    <span *ngIf="!parent?.nameT">{{parent?.name}}</span>
                            </div>
                            <div class="actions d-flex text-center">
                                <i [ngClass]="{ 'disabled': parent?.locked }" class="fa fa-edit align-self-center f-20" style="padding: 0;" (click)="selectUnit(parent);$event.stopPropagation()"></i>
                                <i [ngClass]="{ 'disabled': parent?.locked }" class="fa fa-trash align-self-center ml-2 f-20" style="padding: 0;" (click)="deleteUnit(parent?.id);$event.stopPropagation()"></i>
                            </div>
                        </div>
                        <div class="slct">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                            <div class="type-name">
                                <span *ngIf="child?.nameT">
                                    <span *ngIf="getTranslation(child?.nameT) === '' || getTranslation(child?.nameT) == null || getTranslation(child?.nameT) == undefined ">{{child?.name}}</span>
                                    <span *ngIf="getTranslation(child?.nameT) !== ''">{{getTranslation(child?.nameT)}}</span>
                                </span>
                                <span *ngIf="!child?.nameT">{{child?.name}}</span>
                            </div>
                            <div class="actions d-flex text-center">
                                <i [ngClass]="{ 'disabled': child?.locked }" class="fa fa-edit align-self-center f-20" style="padding: 0;" (click)="selectUnit(child, parent);$event.stopPropagation()"></i>
                                <i [ngClass]="{ 'disabled': child?.locked }" class="fa fa-trash align-self-center ml-2 f-20" style="padding: 0;" (click)="deleteUnit(child?.id);$event.stopPropagation()"></i>
                            </div>
                        </mat-option>
                        </div>
                    </div>
                    
                    </mat-optgroup>
                </div>
                </mat-select>
            </mat-form-field>
            </div>
            <mat-form-field class="w-100">
                <mat-label>{{ 'stock.ingredients.ingredientDescription' | translate }}</mat-label>
                <input matInput formControlName="ingredientDescription" placeholder="{{ 'stock.ingredients.ingredientDescription' | translate }}">
            </mat-form-field>
    </div>
    <div class="col-md-12 mt-3 submit-button">
        <button class="btn btn-lg" type="button" (click)="submit()">{{ 'stock.ingredients.ingredientSaveBtn' | translate }}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>

<fap-modal #addIngredientTypeModal [inputTitle]="'stock.ingredients.ingredientTypeForm' | translate">
    <fap-add-edit-ingredient-type [ingredientType]="selectedIndredientType" (ingredientsTypeUpdated)="updatedIngredients($event); addIngredientTypeModal.hideModal()" (ingredientsTypeCreated)="createdIngredientType($event); addIngredientTypeModal.hideModal()"></fap-add-edit-ingredient-type>
</fap-modal>

<fap-modal #addEditUnitModal 
inputTitle="{{ 'units.addEditUnit' | translate }}">
<form [formGroup]="unitForm" class="unit_form col-md-12 p-0">
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.name' | translate }}</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('name','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.shortName' | translate }}</mat-label>
            <input matInput formControlName="shortName">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('shortName','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.genre' | translate }}</mat-label>
            <!-- <input matInput formControlName="genre"> -->
            <mat-select required formControlName="genre">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #genreFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let genre of this.globalRegistry.genres | filter : genreFilter.value" [value]="genre?.indicator">
                    {{ genre?.name }} ( <b>{{genre?.indicator}}</b> )
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('genre','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group" *ngIf="units">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'stock.parent' | translate }}</mat-label>
            <mat-select required formControlName="parent" [disabled]="!parentUnit && currentUnit">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #unitFilter placeholder="Search">
                </mat-form-field>
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option [value]="'new'" class="font-weight-bold">
                    {{'units.newParent' | translate}}
                </mat-option>
                <mat-option *ngFor="let parent of units | filter : unitFilter.value" [value]="parent?.id">
                    {{ parent?.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('parent','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 mt-3 text-center">
        <button class="btn btn-lg" type="button" (click)="submitUnit()">{{'resources.submitBtn' | translate}}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>
</fap-modal>