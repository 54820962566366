import { FapBaseModel } from '../../base/fap-base-model';

/**
 * @todo
 * maybe it's better to move it under base folder???
 */
export class BaseTypeModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'uniqueid',
        'color',
        'name',
        'name_t',
        'icon',
        'locked',
        'data',
        'form_type'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'name',
    ];

    public id: number;
    public uniqueId: string;
    public color: string;
    public name: string;
    public nameT: string;
    public icon: string;
    public locked: boolean;
    public data: any;
    public formType?: any;
}
