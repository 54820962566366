<div class="container-fluid nc">
    <div class="row m-0">
        <div class="col-md-12 p-0">
            <div class="card mx-auto">
                <div class="card-header">
                    <p class="mb-0" *ngIf="!isEditMode">{{ 'farm.field.addField' | translate }}</p>
                    <p class="mb-0" *ngIf="isEditMode">{{ 'farm.field.editField' | translate }}</p>
                </div>
                <div class="card-body">
                    <fap-add-edit-field
                        [fieldId]="fieldId"
                        [fieldName]="fieldName"
                        [area]="area"
                        [soilType]="soilType"
                        [isSubmitDisabled]="fieldPolygon?.points?.length<3"
                        [owner]="owner"
                        (submitField)="submitField($event)"
                        (deleteParty)="deleteParty($event)">
                    </fap-add-edit-field>
                </div>
            </div>
        </div>
    </div>
</div>
