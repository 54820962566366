<div class="cards-grid d-flex flex-col flex-wrap flex-md-{grow|shrink}-1 justify-content-md-start justify-content-center" id="parent91">
    <ng-container *ngFor="let asset of assets">
        <product-item [asset]="asset"></product-item>
    </ng-container>

    <div class="custom_modal" id="offers_modal">
        <div class="modal_header">
            <h2>{{ 'assets.buyProducts' | translate }}</h2>
            <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div *ngIf="isLoading">
                <h3 style="text-align: center">
                    <span class="loader">
                        <i></i>
                    </span>
                </h3>
            </div>
            <div *ngIf="!isLoading">
                <div class="listed_products" *ngIf="listedProducts.length > 0" infiniteScroll
                    [infiniteScrollDistance]="4"
                    [infiniteScrollThrottle]="500"
                    (scrolled)="scrolledDown()"
                    [scrollWindow]="false" style="overflow: auto;
                    height: 80vh;">
                    <div class="asset_card" *ngFor="let asset of listedProducts">
                        <div class="image">
                            <div class="user_im img_place_bg">
                            <img *ngIf="asset?.product?.images?.length" [src]="mediaUrl + asset?.product?.images[asset?.product?.images?.length - 1]?.file" alt="product">
                            <img *ngIf="asset?.product?.images?.length == 0 || asset?.product === null" src="../../../../../../assets/images/image_placeholder.png" alt="product">
                        </div>
                        </div>
                        <div class="info">
                            <div class="name">{{asset?.product?.name}}</div>
                            <div class="txn">
                                <div class="txn_details">
                                    <!-- <div class="rw">
                                        Price: {{asset?.product?.cost}}
                                    </div> -->
                                    <div class="rw">
                                        <button class="btn" (click)="showBuyOptionsModal(asset.id, asset.product.id)">{{ 'assets.buy' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="custom_modal" id="buy_options_modal">
        <div class="modal_header">
            <h2>{{ 'assets.addDetails' | translate }}</h2>
            <button type="button" class="btn close-modal" (click)="hideBuyOptionsModal()"><i class="fa fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="offer_form">
            <form [formGroup]="buyOfferForm">
                <div class="form-group">
                    <div class="row align-items-baseline">
                        <div class="col-6">
                            <b class="text-center">{{product?.name}}</b>
                        </div>
                        <div class="col-6 d-flex nn posrltv custom_border">
                            <div class="colq">
                                <div class="input-group w-100">
                                    <mat-form-field>
                                        <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                        <input type="number" style="text-align: right;" matInput formControlName="quantity">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="colu" style="padding: 0;
                            
                            width: auto;
                            
                            margin-left: -5px;" *ngIf="product && product.unit">
        
                                <div class="inlin">
                                    <b>{{product | getProductUnit: globalRegistry?.systemData?.units}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group w-100">
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'assets.farm' | translate }}</mat-label>
                                    <mat-select required formControlName="farm">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let farm of globalRegistry.systemData.farms | filter : farmFilter.value" [value]="farm.id">
                                                {{ farm?.name }}
                                            </mat-option>
                                        </div>
                                    </mat-select> 
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-6">
                            <fap-add-edit-delete-select
                                    inputName="{{ 'party.billedTo' | translate }}"
                                    class="w-100"
                                    [formControl]="buyOfferForm.controls['billedTo']"
                                    name="billedTo"
                                    [inputIsRequired]="true"
                                    [apiSearchEnabled]="true"
                                    [inputValueFieldName]="'id'"
                                    [inputNameFieldName]="'name'"
                                    [inputSelectValues]="ownParties"
                                    [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                                    (outputOnDelete)="onDeleteParty($event, 'billedTo')"
                                    (outputOnCreate)="addPartyModal()"
                                    (outputOnScroll)="scrollParties()"
                                    (outputOnSearch)="filterOwnParties($event)"
                                    (click)="getParties()"
                                    (outputOnEdit)="editParty($event)">
                                </fap-add-edit-delete-select>
                        </div>
                        <div class="btns w-100">
                            <button class="btn" (click)="buyProduct()">{{"translations.save" | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </div>
      </div>
</div>

<fap-modal #addEditPartyModal
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
    (addedParty)="addParty($event)"
    (updatedParty)="addedOrUpdatedParty($event)">
    </fap-add-edit-party>
</fap-modal>