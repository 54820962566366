<div class="dashboard-card dash_cont">
    <div class="row">
        <div class="col-md-4">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
            <h1>
                <!-- <ng-container *ngIf="widget.settings['options'][0]?.sensor?.nameT">
                    {{ getTranslation(widget.settings['options'][0]?.sensor?.nameT) }}
                </ng-container>
                <ng-container *ngIf="!widget.settings['options'][0]?.sensor?.nameT">
                    {{ widget.settings['options'][0]?.objects ? widget.settings['options'][0]?.objects[0]?.nameT ? getTranslation(widget.settings['options'][0]?.objects[0]?.nameT) : widget.settings['options'][0]?.objects[0]?.extraText  : '' }}
                </ng-container> -->
            </h1>
        </div>
        <!-- <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div> -->
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="card-content">
        <div class="custom-img" *ngIf="dataSource">
          <img [src]="mediaUrl + widget.settings['background']['file']" style="width: 100%; object-fit: cover; max-height: 100%; max-width: 100%; height: 100%;"/>
          <ng-container>
              <span *ngFor="let item of widget.settings['options']" 
              >
              <span *ngIf="item.css"  class="itm"
              [style.fontSize.px]="item?.css?.font_size"
              [style.left.%]="item?.css?.left"
              [style.top.%]="item?.css?.top"
              [style.color]="item?.css?.color"
              [style.transform]="'translate(-' + item?.css?.transformX + '%, -' + item?.css?.transformY + '%)'">
                <b>{{item?.sensor?.lastValue?.value_float | number:'1.'+globalRegistry.systemData.company.digits}} </b>
                <span class="unit" *ngIf="item?.unitTypes && item?.unitTypes[0]?.unit != null">{{ item?.unitTypes[0]?.unit }}</span>
                </span>

                <span *ngIf="item.css === null" class="itm"
              [style.fontSize.px]="16"
              [style.left.%]="50"
              [style.top.%]="50"
              [style.color]="black"
              [style.transform]="'translate(-50%, -50%)'">
                <b>{{item?.sensor?.lastValue?.value_float | number:'1.'+globalRegistry.systemData.company.digits}} </b>
                <span class="unit" *ngIf="item?.unitTypes && item?.unitTypes[0]?.unit != null">{{ item?.unitTypes[0]?.unit }}</span>
                </span>
              </span>
          </ng-container>
        </div>
      </div>
</div>
