<div class="widget-card">
    <div class="widget-card-title">
        <div>
          {{ chartData.chartTitle | translate }}
        </div>
        <div *ngIf="chartData['objectName']" class="widget-content-type-text">
          {{ chartData['contentType'] | translate }} - {{ chartData['objectName'] }}
        </div>
    </div>
    <div class="card-body">
        <div class="flot-chart-container">
            <div class="flot-chart-placeholder ngx-chart-direction" id="github-issues">
                <!-- <ngx-charts-pie-chart
                    [scheme]="chartData.chartColorScheme"
                    [results]="chartData.pieChartData"
                    [explodeSlices]="chartData.chartExplodeSlices"
                    [labels]="chartData.chartShowLabels"
                    [doughnut]="chartData.isDoughnut"
                    [arcWidth]="chartData.arcWidth"
                    [gradient]="chartData.chartGradient"
                    (select)="onSelect($event)"
                    (activate)="onActivate($event)"
                    (deactivate)="onDeactivate($event)">
                </ngx-charts-pie-chart> -->
            </div>
        </div>
    </div>
</div>
