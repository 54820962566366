<div
    *ngIf="widget"
    class="dashboard-card dash_cont"
    style="position: relative"
    id="parent"
>
    <div class="row">
        <div class="col-md-4">
            <h1>
                {{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}
            </h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <!-- <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2> -->
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row m-0" style="height: calc(100% - 15px)">
        <div class="d-flex cont w-100">
            <div class="progres_bar">
                <div class="barcontainer">
                    <div class="bar" [ngStyle]="{'height': progressData}">
                        <span *ngIf="progressData!=='null%'">{{progressData}}</span>
                        <span *ngIf="progressData === 'null%'" class="empty_value">0%</span>
                    </div>
                </div>
            </div>
            <div class="other_data">
                <ng-container *ngIf="imageData">
                    <img class="bin_image" [src]="mediaUrl + imageData?.file" alt="">
                </ng-container>
                <div class="labels">
                    <div class="temp mb10">
                        <div class="label_container">
                            <div class="icon">
                                <ng-container *ngIf="temperatureData?.element?.sensor_info?.icon">
                                    <img [src]="mediaUrl + temperatureData?.element?.sensor_info?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!temperatureData?.element?.sensor_info?.icon && temperatureData?.element?.unit?.icon">
                                    <img [src]="mediaUrl + temperatureData?.element?.unit?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!temperatureData?.element?.sensor_info?.icon && !temperatureData?.element?.unit?.icon">
                                    <img src="../../../../assets/icons/sensor_microchip.svg" alt="sensor">
                                </ng-container>
                            </div>
                            <div class="value">
                                {{temperatureData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{temperatureData?.element?.unit?.unit}}
                            </div>
                        </div>
                    </div>
                    <div class="humidity mb10">
                        <div class="label_container">
                            <div class="icon">
                                <ng-container *ngIf="humidityData?.element?.sensor_info?.icon">
                                    <img [src]="mediaUrl + humidityData?.element?.sensor_info?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!humidityData?.element?.sensor_info?.icon && humidityData?.element?.unit?.icon">
                                    <img [src]="mediaUrl + humidityData?.element?.unit?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!humidityData?.element?.sensor_info?.icon && !humidityData?.element?.unit?.icon">
                                    <img src="../../../../assets/icons/sensor_microchip.svg" alt="sensor">
                                </ng-container>
                            </div>
                            <div class="value">
                                {{humidityData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{humidityData?.element?.unit?.unit}}
                            </div>
                        </div>
                    </div>
                    <div class="lid mb10">
                        <div class="label_container">
                            <div class="icon">
                                <ng-container *ngIf="lidData?.element?.sensor_info?.icon">
                                    <img [src]="mediaUrl + lidData?.element?.sensor_info?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!lidData?.element?.sensor_info?.icon && lidData?.element?.unit?.icon">
                                    <img [src]="mediaUrl + lidData?.element?.unit?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!lidData?.element?.sensor_info?.icon && !lidData?.element?.unit?.icon">
                                    <img src="../../../../assets/icons/sensor_microchip.svg" alt="sensor">
                                </ng-container>
                            </div>
                            <div class="date">{{getMoment(lidData?.element?.timestamp)}}</div>
                        </div>
                    </div>
                    <div class="battery">
                        <div class="label_container">
                            <div class="icon">
                                <ng-container *ngIf="batteryData?.element?.sensor_info?.icon">
                                    <img [src]="mediaUrl + batteryData?.element?.sensor_info?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!batteryData?.element?.sensor_info?.icon && batteryData?.element?.unit?.icon">
                                    <img [src]="mediaUrl + batteryData?.element?.unit?.icon?.file" alt="">
                                </ng-container>
                                <ng-container *ngIf="!batteryData?.element?.sensor_info?.icon && !batteryData?.element?.unit?.icon">
                                    <img src="../../../../assets/icons/sensor_microchip.svg" alt="sensor">
                                </ng-container>
                            </div>
                            <div class="value">
                                {{batteryData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{batteryData?.element?.unit?.unit}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>