import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalRegistryService } from './core/global-registry/global-registry.service';
import { UserModel } from './core/models/user/user.model';
import { SystemDataInterface } from './core/global-registry/system-data.interface';
import { DataService } from './core/services/websocket/data.service';
import { ThemeService } from './core/services/theme/theme.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    
    constructor(
        private _globalRegistry: GlobalRegistryService,
        private dataService: DataService, 
        private themeService: ThemeService ,
        private http: HttpClient,
        public translate: TranslateService      
    ) {
        translate.setDefaultLang(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        this.initUserData();
        this.initTranslations();
        // this.themeService.applyStyles();
        const userLang = navigator.language;
        console.log(userLang);
        if(!localStorage.getItem('language')) {
            if(userLang.slice(0, 2) == 'en' || userLang.slice(0, 2) == 'fr' || userLang.slice(0, 2) == 'ro' || userLang.slice(0, 2) == 'ru') {
                this.translate.setDefaultLang(userLang.slice(0, 2));
                this.translate.use(userLang.slice(0, 2));
                localStorage.setItem('language', userLang.slice(0, 2))
            } else {
                this.translate.setDefaultLang('en');
                this.translate.use('en');
                localStorage.setItem('language', 'en')
            }
        }
        // alert(localStorage.getItem('language'));
    }

    ngOnInit(): void {
        this.dataService.openWebSocket();
        if(localStorage.getItem('language') == null) {
            localStorage.setItem('language', 'en');
        }
        this.getCurrentCountry().subscribe(data => {
            localStorage.setItem('country_info', JSON.stringify(data));
        })
    }

    public getCurrentCountry() {
        return this.http.get('https://ipapi.co/json/');
      }

    ngOnDestroy(): void {
        this.dataService.closeWs();
    }

    public initUserData(): void {
        this._globalRegistry.initUserData = (userData: UserModel): void => {
            this._globalRegistry.userData = userData;
            this.initSystemData();
        };
    }

    public initSystemData(): void {
        this._globalRegistry.initSystemData = (
            systemData: SystemDataInterface
        ): void => {
            this._globalRegistry.systemData = systemData;
        };
    }

    public initTranslations() :void {
        this._globalRegistry.iniTranslations = (userData: UserModel): void => {
            this._globalRegistry.userData = userData;
            this.initSystemData();
        };
    }
}
