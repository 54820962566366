<form [formGroup]="editValuesForm" class="col-12 p-0">

    <ng-container *ngIf="field?.field?.input_type === 'choice'">
        <div class="input-group w-100">
            <mat-form-field class="w-100">
                <mat-label>{{field.name_t ? getTranslation(field.name_t) : field.name}}</mat-label>
                <mat-select panelClass="prevent-enter-close" formControlName="value" name="value" required (selectionChange)="setValue($event.value)">
                    <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #entityTextFilter placeholder="Search">
                    </mat-form-field>
                    <mat-label style="position: absolute; right: 35px; cursor: pointer;">
                        <i class="fa fa-search f-20"></i>
                    </mat-label>
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let entity of entities" [value]="entity.input">
                        <span class="mat-option-text">
                        <div class="name">{{ entity.name_t ? getTranslation(entity.name_t) : entity.name_t }}</div>
                        </span>
                    </mat-option>
                </div>
                </mat-select>
                <mat-error *ngIf="editValuesForm.controls['value'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'image'">
        <div class="form-group mt-3">
            <label>{{ field.name_t ? getTranslation(field.name_t) : field.name }}</label>
            <div class="field_images">
                <div class="col d-flex p-0">
                    <div class="d-flex" style="overflow-y: hidden;">
                        <div class="img_container">
                            <div class="images" style="display: flex;">
                                <div class="nimg" *ngFor="let imageId of fieldImages">
                                <ng-container *ngFor="let image of images">
                                    <div class="imgbox" *ngIf="image.id == imageId">
                                        <div class="delt_btn">
                                            <i class="fa fa-trash" (click)="deleteNoteImage(image?.id)"></i>
                                        </div>
                                        <img [src]="mediaUrl+image?.file" alt="" [attr.class]="image?.data?.orientation">
                                    </div>
                                </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="bx">
                            <mat-label style="display: block;"> {{ 'notes.images' | translate }} </mat-label>
                            <div class="input-group align-items-baseline w-100 img_bx">
                                <mat-form-field (click)="getImages()">
                                    <mat-label>{{ 'Images' | translate }}</mat-label>
                                    <mat-select (selectionChange)="addImage($event)" name="image" required (infiniteScroll)="scrolledDownImages()" msInfiniteScroll> <!--Add formcontrol name here-->
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100 disbld">
                                            <input matInput #typeFilter disabled placeholder="Search">
                                        </mat-form-field>
                                        <div class="input-group-append pl-1" (click)="showImageModal()">
                                            <span class="input-group-text"><i
                                                    class="icofont icofont icofont-plus"></i></span>
                                        </div>
                                    </div>
                                    <div class="scroll_wrap" (scroll)="onScrollImages($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
                                            <div class="name" style="margin: 5px;">
                                                <img style="height: 100px; width: 100%; object-fit: contain" [src]="mediaUrl+image.file" alt="image">
                                            </div>
                                            <div class="actions">
                                                <i class="fa fa-trash" (click)="deleteImage(image?.id); $event.stopPropagation()"></i>
                                            </div>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                </mat-form-field>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'string'">
        <ng-container *ngIf="field?.field?.input_type === 'single'">
            <div class="form-group w-100 m-0">
                <mat-form-field class="w-100" (click)="addEditTranslation()">
                    <mat-label class="col-form-label">{{ field.name_t ? getTranslation(field.name_t) : field.name }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput1 (change)="editValue.emit({value: editValuesForm.get('value'), type: 'value'})">
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type === 'choice'">               
            <ng-container *ngIf="css?.component === 'radio' && selectedField?.choices?.length">
                <div class="choices_container">
                    <label>{{ "attribute.value" | translate }}</label>
                     <div class="custom_toggle">
                            <ng-container *ngFor="let choice of selectedField?.choices">
                                        <div class="choice_container d-flex">
                                            <div class="choice">
                                                <input type="radio" (change)="setChoice(choice?.id)" name="value" formControlName="value" [value]="choice?.id">
                                                <span class="circ"></span>
                                            </div>
                                            <span>{{ getTranslation(choice?.name_t) }}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>                          
            </ng-container>
            <ng-container *ngIf="css?.component !== 'radio' && selectedField?.choices?.length">
                            <div class="input-group w-100">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ "attribute.value" | translate }}</mat-label>
                                    <mat-select panelClass="prevent-enter-close" formControlName="value" name="value" required (selectionChange)="setValue($event.value)">
                                        <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #entityTextFilter placeholder="Search">
                                        </mat-form-field>
                                        <mat-label style="position: absolute; right: 35px; cursor: pointer;">
                                            <i class="fa fa-search f-20"></i>
                                        </mat-label>
                                    </div>
                                    <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option class="noteIcons" *ngFor="let choice of selectedField?.choices" [value]="choice.id">
                                            <span class="mat-option-text">
                                            <div class="name">{{ choice.name_t ? getTranslation(choice.name_t) : choice.name_t }}</div>
                                            </span>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                    <mat-error *ngIf="editValuesForm.controls['value'].errors?.required">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="field?.field?.input_type === 'multiselect'">

            <chips 
                [items]="field?.field?.choices"
                [selectedItems]="selectedAttr.value.input"
                [label]="css?.name ? css.name : entityModel | titlecase"
                (updatedItems)="setValue($event)"
                [addEnabled]="false"
                [isEditable]="true"
                [isDeleteable]="true"
                (filterItem)="filterEntity(entityModel, $event)"
                (emitScroll)="scrolledDown(entityModel)"
            >
            </chips>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'number'">
        <ng-container *ngIf="field?.field?.input_type ==='single'">
            <div class="d-flex with_units">
                <div class="col-9 p-0">
                    <div class="input-group w-100">
                        <mat-form-field class="w-100">
                            <mat-label>{{ field.name_t ? getTranslation(field.name_t) : field.name }}</mat-label>
                            <input type="number" style="text-align: right;" matInput formControlName="value" (change)="editValue.emit({value: $event.target.value, type: 'value'})">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-3" style="padding: 0;
                     margin-left: -1px;
                    width: auto;
                    margin-bottom: 17px;">
                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                    <b class="unit_name" style="display: inline-block; margin-top: 20px;" *ngIf="unit?.id === field?.field?.unit">{{unit?.shortName ? unit?.shortName : unit?.short_name}}</b>
                    <ng-container *ngIf="unit?.id !== stockUnit">
                        <ng-container *ngFor="let childUnit of unit?.children">
                            <b class="unit_name" style="display: inline-block; margin-top: 20px;" *ngIf="childUnit?.id === field?.field?.unit">{{childUnit?.shortName ? childUnit?.shortName : childUnit?.short_name}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            </div>
        </ng-container>
        <ng-container *ngIf="field?.field?.input_type ==='range'">
            <div class="min_number">
                <div class="d-flex">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field class="w-100">
                                <mat-label>{{ field.name_t ? getTranslation(field.name_t) : field.name }} {{'units.min' | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="min">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                         margin-left: -1px;
                        width: auto;
                        margin-bottom: 17px;">
                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                        <b class="unit_name" style="display: inline-block; margin-top: 20px;" *ngIf="unit?.id === field?.field?.unit">{{unit?.shortName ? unit?.shortName : unit?.short_name}}</b>
                        <ng-container *ngIf="unit?.id !== stockUnit">
                            <ng-container *ngFor="let childUnit of unit?.children">
                                <b class="unit_name" style="display: inline-block; margin-top: 20px;" *ngIf="childUnit?.id === field?.field?.unit">{{childUnit?.shortName ? childUnit?.shortName : childUnit?.short_name}}</b> 
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                </div>
            </div>

            <div class="max_number">
                <div class="d-flex">
                    <div class="col-9 p-0">
                        <div class="input-group w-100">
                            <mat-form-field class="w-100">
                                <mat-label>{{ field.name_t ? getTranslation(field.name_t) : field.name }} {{'units.max' | translate}}</mat-label>
                                <input type="number" style="text-align: right;" matInput formControlName="max" (change)="editValue.emit({value: {min: editValuesForm.get('min').value ,max:$event.target.value}, type: 'value'})">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-3" style="padding: 0;
                         margin-left: -1px;
                        width: auto;
                        margin-bottom: 17px;">
                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                        <b class="unit_name" style="display: inline-block; margin-top: 20px;" *ngIf="unit?.id === field?.field?.unit">{{unit?.shortName ? unit?.shortName : unit?.short_name}}</b>
                        <ng-container *ngIf="unit?.id !== stockUnit">
                            <ng-container *ngFor="let childUnit of unit?.children">
                                <b class="unit_name" style="display: inline-block; margin-top: 20px;" *ngIf="childUnit?.id === field?.field?.unit">{{childUnit?.shortName ? childUnit?.shortName : childUnit?.short_name}}</b> 
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'entity'">
        <ng-container *ngIf="field?.field?.input_type ==='single'">
            <div class="input-group w-100">
                <mat-form-field class="w-100">
                    <mat-label>{{ css?.name ? css.name : entityModel | titlecase }}</mat-label>
                    <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()" (infiniteScroll)="scrolledDown(entityModel)" [complete]="offset === entities.length" msInfiniteScroll [alwaysCallback]="true" formControlName="value" name="value" required (selectionChange)="setValue($event.value)">
                        <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #entityTextFilter placeholder="Search" (keydown.enter)="filterEntity(entityModel, entityTextFilter.value); $event.stopPropagation(); mys.open()">
                        </mat-form-field>
                        <mat-label style="position: absolute; right: 35px; cursor: pointer;" (click)="filterEntity(entityModel, entityTextFilter.value)">
                            <i class="fa fa-search f-20"></i>
                        </mat-label>
                    </div>
                    <div class="scroll_wrap" (scroll)="onScroll(entityModel, $event)">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option class="noteIcons" *ngFor="let entity of entities" [value]="entity.id">
                            <span class="mat-option-text">
                            <div class="name" *ngIf="entity?.name">{{ entity?.name }}</div>
                            <div class="name" *ngIf="entity?.doc_id">{{ entity?.doc_id }}</div>
                            <div class="name" *ngIf="entity?.display">{{ entity?.display }}</div>
                            </span>
                        </mat-option>
                    </div>
                    </mat-select>
                    <mat-error *ngIf="editValuesForm.controls['value'].errors?.required">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container *ngIf="field?.field?.input_type ==='multiselect' && entities.length">
            <chips 
                [items]="entities" 
                [selectedItems]="selectedAttr.value.input"
                [label]="css?.name ? css?.name : entityModel | titlecase"
                (updatedItems)="setValue($event)"
                [addEnabled]="true"
                [isEditable]="false"
                [isDeleteable]="false"
                (filterItem)="filterEntity(entityModel, $event)"
                (emitScroll)="scrolledDown(entityModel)"
            >
            </chips>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'datetime'">
        <ng-container *ngIf="field?.field?.input_type ==='single'">
            <mat-form-field (click)="picker.open()" class="w-100">
                <input matInput readonly [matDatepicker]="picker" placeholder="Start" [disabled]="false" formControlName="value"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="field?.field?.input_type ==='range'">
            <div class="start_date">
                <mat-form-field (click)="startPicker.open()" class="w-100">
                    <input matInput readonly [matDatepicker]="startPicker" placeholder="Start" [max]="maxDate" (dateChange)="minValueChanged($event)" [disabled]="false" formControlName="min"/>
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="end_date">
                <mat-form-field (click)="endPicker.open()" class="w-100">
                    <input matInput readonly [matDatepicker]="endPicker" placeholder="End" [min]="minDate" (dateChange)="maxValueChanged($event)" [disabled]="false" formControlName="max"/>
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'boolean'">
        <ng-container *ngIf="entityInfo?.css?.length === 0">
          <mat-form-field class="w-100">
            <mat-label>{{ css?.name }}</mat-label>
            <mat-select formControlName="value" (selectionChange)="setValue($event.value)">
              <mat-option [value]="0">
                False
              </mat-option>
              <mat-option [value]="1">
                True
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      
        <ng-container *ngIf="entityInfo?.css?.length > 0">
          <ng-container *ngFor="let css of entityInfo?.css">
            <ng-container *ngIf="field?.field?.uniqueid == css.uniqueid">
              <ng-container *ngIf="css?.pc?.component === 'combo'">
                <mat-form-field class="w-100">
                  <mat-label>{{ css?.name }}</mat-label>
                  <mat-select formControlName="value" (selectionChange)="setValue($event.value)">
                    <mat-option [value]="'0'">
                      False
                    </mat-option>
                    <mat-option [value]="'1'">
                      True
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="css?.pc?.component === 'radio'">
                <div class="choices_container">
                  <label>{{ css?.name }}</label>
                  <div class="custom_toggle">
                    <div class="choice_container d-flex">
                      <div class="choice">
                        <input type="radio" (change)="setValue(0)" name="value" formControlName="value" [value]="0">
                        <span class="circ"></span>
                      </div>
                      <span>False</span>
                    </div>
                    <div class="choice_container d-flex">
                      <div class="choice">
                        <input type="radio" (change)="setValue(1)" name="value" formControlName="value" [value]="1">
                        <span class="circ"></span>
                      </div>
                      <span>True</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="css?.pc?.component === 'checkbox'">
                <div class="choices_container">
                  <div class="choice">
                    <mat-checkbox formControlName="value" (change)="checkBooleanValue($event)">
                      <div style="position: relative; font-weight: normal">{{ getTranslation(css?.uniqueid) }}</div>
                    </mat-checkbox>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      
    
    <ng-container *ngIf="field?.field?.value_type === 'coords'">
        <div class="form-group w-100 m-0">
            <mat-form-field class="w-100" (click)="updateMap()">
                <mat-label class="col-form-label">{{ field.name_t ? getTranslation(field.name_t) : field.name }}</mat-label>
                <input matInput formControlName="value" #nameInput>
            </mat-form-field>
        </div>
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'surface'">
        <div class="form-group w-100 m-0">
            <mat-form-field class="w-100" (click)="updateMap()">
                <mat-label class="col-form-label">{{ field.name_t ? getTranslation(field.name_t) : field.name }}</mat-label>
                <input matInput formControlName="value" #nameInput>
            </mat-form-field>
        </div>
        <!-- <button (click)="updateMap()" class="btn mb-3">{{ 'attribute.editPolygons' | translate }}</button> -->
    </ng-container>

    <ng-container *ngIf="field?.field?.value_type === 'polyline'">
        <div class="form-group w-100 m-0">
            <mat-form-field class="w-100" (click)="updateMap()">
                <mat-label class="col-form-label">{{ field.name_t ? getTranslation(field.name_t) : field.name }}</mat-label>
                <input matInput formControlName="value" #nameInput>
            </mat-form-field>
        </div>
        <!-- <button (click)="updateMap()" class="btn mb-3">{{ 'attribute.editPolyline' | translate }}</button> -->
    </ng-container>

    <fap-modal [inputIsMediumLarge]="true"
        #locationPopup
        [titleColor]="'#ffffff'"
        inputTitle="Select Location"
        (outputOnHide)="mapType = ''"
        >

        <fap-attributes-map 
        [coords]="coords"
        [polylines]="polylines"
        [polygons]="polygons"
        [mapType]="mapType"
        [isEditMode]="isEditMode"
        (emitCoords)="updateCoords($event)"
        (emitPolylines)="updatePolylines($event)"
        (emitPolygons)="updatePolygons($event)"
        (closePopup)="closePopup()"
    >
    </fap-attributes-map>
</fap-modal>

<fap-modal #addImageModal
[inputPrimaryButtonInterface]="addImageModalButtonPrimaryInterface"
[inputSecondaryButtonInterface]="addImageModalButtonSecondaryInterface"
inputTitle="Add Image">
<form [formGroup]="imageForm" class="col-12 p-0">
    <div class="form-group" 
    style="display: none">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Group' }}</mat-label>
            <input matInput formControlName="group" readonly value="notes">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Tags' }}</mat-label>
            <input matInput formControlName="tags" placeholder="Tags">
        </mat-form-field>
    </div>
    <div class="form-group">
        <label style="display: block;" class="upload-img-label">{{ 'Image' }}</label>
        <input #fileUploader type="file" (change)="onFileInput($event)">
        <img *ngIf="imageSrc" class="preview-img d-block" [src]="imageSrc" alt="image">
    </div>
</form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="'Field type name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(null)" 
        [prefix]="'FIELD_TYPE__'" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)"
    >
    </add-edit-translations>
</fap-modal>