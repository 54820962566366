import { Routes } from '@angular/router';
import { FapStockBillComponent } from './components/fap-stock-bill/fap-stock-bill.component';
import { PrintExcelComponent } from './components/print-excel/print-excel.component';
import { PrintPdfComponent } from './components/print-pdf/print-pdf.component';

import { StockContainerComponent } from './containers/stock-container/stock-container.component';
import { StockFarmContainerComponent } from './containers/stock-farm-container/stock-farm-container.component';

export const stockRoutes: Routes = [
    {
        path:       '',
        data: {
            filters: {
                hasFarmFilter: true,
                hasLotFilter: true,
                hasStockFilter: true
            },
            key: 'stock',
            index: 0
        },
        component:  StockContainerComponent
    },
    {
        path: ':farmId',
        component: StockFarmContainerComponent
    },
    {
        path: 'bill/add',
        component:  FapStockBillComponent
    },
    {
        path: 'bill/:billId',
        component: FapStockBillComponent
    },
    {
        path: 'pdf/:billId',
        component: PrintPdfComponent
    },
    {
        path: 'excel/:billId',
        component: PrintExcelComponent
    },
];
