import { NoteModel } from "./../../../../../core/models/notes/note.model";
import { ToastrService } from "ngx-toastr";
import { NotesService } from "./../../../../../core/services/api/farm/notes.service";
import { OnInit, Component, ElementRef, HostListener, ViewChild, SimpleChanges, AfterViewChecked, OnChanges, OnDestroy } from "@angular/core";
import { tap, take } from "rxjs/operators";
import { combineLatest, Subscription } from "rxjs";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ResponseModel } from "../../../../../core/models/response.model";
import { MapService } from "../../../../../shared/layout/fap_main-map/service/map-service";
import { TranslateService } from "@ngx-translate/core";
import { FarmModel } from "../../../../../core/models/farm/farm.model";
import { ForecastService } from "../../../../../core/services/api/forecast/forecast.service";
import { WeatherModel } from "../../../../../core/models/widget/weather/weather.model";
import { FarmService } from "../../../../../core/services/api/farm/farm.service";
import { ConfirmModalService } from "../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service";
import { HttpClient } from "@angular/common/http";
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { UserService } from "../../../../../core/services/api/user/user.service";
import { CompanyService } from "../../../../../core/services/api/company/company.service";
import { CacheResolverService } from "src/app/core/services/api/cache/cache-resolver.service";
import { ScrollService } from "../../../../../shared/services/scroll.service";

@Component({
  templateUrl: "./notes-container.component.html",
  styleUrls: ['./notes-container.component.scss']
})
export class NotesContainerComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
  public farms: Array<FarmModel>;
  public notes: Array<any> = [];
  public filteredFarms: Array<FarmModel> = [];
  private subscriptions: Array<Subscription> = [];
  public forecasts: Array<WeatherModel> = [];
  public farmForecastMapping: { [key: number]: WeatherModel } = {};
  public nextToken: { limit: number; offset: number; search: string } = null;
  filteredNotes: Array<any> = [];
  public searchFilteredNotes: NoteModel[];
  public limit = 20;
  public currentUser;
  public getMore = true;
  public personIds = [];
  private scrollPosition = 0;
  public divHeight:number;
  @ViewChild('notesContainer') notesContainer: ElementRef;
  private isScrolled = false;

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    // Update the scroll position when the user scrolls
    this.scrollPosition = (event.target as HTMLElement).scrollTop;
  }

  constructor(
    public activatedRoute: ActivatedRoute,
    public notesService: NotesService,
    private toastyService: ToastrService,
    public translate: TranslateService,
    private mapService: MapService,
    public forecastService: ForecastService,
    public router: Router,
    public farmService: FarmService,
    public confirmModalService: ConfirmModalService,
    public http: HttpClient,
    public globalRegistry:GlobalRegistryService,
    public userService: UserService,
    public companyService: CompanyService,
    public cacheService: CacheResolverService,
    private el: ElementRef,
    private scrollService: ScrollService
  ) {
    this.subscriptions.push(this.userService.getCurrentUser.subscribe(data => {
      if(Object.keys(data).length != 0) {
      this.currentUser = data;
      const path = this.router.routerState.snapshot.url;
      this.userService.getMenus.subscribe(menus => {  
          if(Object.keys(menus).length != 0) {
              const pathExist = menus.some(menu => menu.path === path);
              if(pathExist === false) {
                  this.router.navigate(['pages/posts']);
              }
          }
      })
      }
  }));
    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: localQueryParams,
    });
    const url = notesService.getUrl('')
    this.subscriptions.push(
      combineLatest([
        activatedRoute.queryParams,
        
        this.notesService.getNotes({ limit: this.limit }),
      ]).subscribe(
        ([queryParams, notes]: [
          Params,
          
          any
        ]): void => {
          if (!this.forecasts || this.forecasts.length === 0) {
            // this.getForecasts();
          }
          this.farms = globalRegistry.systemData.farms;
          if (queryParams["farms"] !== undefined) {
            // we have farms in query params, but need to check if we have an array of farms
            const filteredFarmIds: Array<number> = Array.isArray(
              queryParams["farms"]
            )
              ? queryParams["farms"].map((farmId: string): number =>
                  Number(farmId)
                )
              : [Number(queryParams["farms"])];
            this.filteredFarms = this.farms.filter(
              (farm: FarmModel): boolean => {
                return filteredFarmIds.includes(farm.id);
              }
            );
          } else {
            this.filteredFarms = this.farms;
          }
          this.notes = notes.results;
          cacheService.delete(url+'limit='+this.limit);   
          this.nextToken = notes.next
            ? this.globalRegistry.getQueryStringParams(notes.next.split("?")[1])
            : null;
          this.filteredNotes = this.notes;
          this.getPersons();

          if (queryParams["filter"] !== undefined) {
            const search = (queryParams["filter"] as string).toLowerCase();
            notesService.getNotes({search}).subscribe(data => {
              cacheService.delete(url+'search='+search);
              this.nextToken = data["next"]
            ? this.globalRegistry.getQueryStringParams(data["next"].split("?")[1])
            : null;
            if(this.nextToken) this.getMore = true;
            this.filteredNotes = data["results"];
            this.getPersons();
            console.log(this.filteredNotes);
            })
          }
          this.getScrollPosition();
        }
      )
    );
    this.mapService.showMap();
    this.mapService.centerMapOnUserLocation();
  }

  getScrollPosition() {
    this.scrollPosition = this.scrollService.getScrollPosition('notes');
      setTimeout(() => {
        if(this.filteredNotes.length) {
          if (this.notesContainer) {
            this.divHeight = this.notesContainer.nativeElement.clientHeight;
            const contentHeight = this.notesContainer.nativeElement.scrollHeight;
            console.log(this.scrollPosition);
            console.log('Div height:', this.divHeight);
            console.log('Scroll height:', contentHeight);
            if(this.scrollPosition > contentHeight) {
              this.scrolledDown();
            }
            this.notesContainer.nativeElement.scrollTop = this.scrollPosition;
          }
        }
      }, 500);
  }

  ngAfterViewChecked() {
    if (!this.isScrolled && this.notesContainer && this.scrollPosition > 0) {
      // const contentHeight = this.notesContainer.nativeElement.scrollHeight;
      if (this.scrollPosition > this.divHeight) {
        this.notesContainer.nativeElement.scrollTop = this.scrollPosition + this.divHeight;
        this.isScrolled = true;
      }
    }
  }

  getPersons() {
    this.companyService.getPersons().subscribe(data => {
      this.globalRegistry.systemData.persons = data.body.results;
      if(this.filteredNotes) {
        this.personIds = [];
        this.filteredNotes.forEach(post => {
          
          const personExist = this.globalRegistry.systemData.persons.some((p) => p.id === post.created_by);
          if(!personExist) {
            this.personIds.push(post.created_by);
          }
        });
        const unique = this.personIds.filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        });
        setTimeout(()=> {
          unique.forEach(element => {
            this.companyService.getPerson(element).subscribe(data => {
              if(data) {
                this.globalRegistry.systemData.persons.push(data.body.results)
              }
              this.globalRegistry.systemData.persons = this.globalRegistry.systemData.persons.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
              });
              // console.log(this.globalRegistry.systemData.persons);
            })
          });
        },1000)  
      }
    })
  }


  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
 
  scrolledDown() {
    const url = this.notesService.getUrl('');
    if(this.getMore) {
    this.nextToken &&
      this.notesService.getNotes(this.nextToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.notes) {
          this.notes = [...this.notes, ...data["results"]];
        } else {
          this.notes = data["results"];
        }
        this.filteredNotes = this.notes;
        this.getPersons();
        if(data["next"] == null) {
          this.getMore = false;
          return
        } else {
          const url = data['next'].split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search : params['search']};
          } else {
            return
          }
        }
      });
    } else {
      return
    }
    this.getScrollPosition();
  }

  ngOnInit() {
    this.mapService.resetMap();
  }
  

  onDivScroll(event: Event) {
    const divElement = event.target as HTMLElement;
    const scrollTop = divElement.scrollTop;
    this.scrollPosition = scrollTop;
    this.scrollService.setScrollPosition(scrollTop, 'notes')
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void =>
      subscription.unsubscribe()
    );
    // this.mapService.isEditMode = false;
    // this.mapService.resetMap();
  }

  private initForecastMapping(): void {
    if (this.forecasts) {
      this.forecasts.forEach((forecast: WeatherModel): void => {
        this.farmForecastMapping[forecast.objectId] = forecast;
      });
    }
  }

  private getForecasts(): void {
    this.forecastService
      .getForecast({ content_type: "farm" })
      .pipe(take(1))
      .subscribe((forecasts: ResponseModel<WeatherModel[]>): void => {
        this.forecasts = forecasts.model;
        this.initForecastMapping();
      });
  }

  public addNote(notes: FormData): void {
    this.notesService
      .createNote(notes)
      .pipe(
        tap(
          () => {
            this.getNotes();
            this.toastyService.success(
              this.translate.instant("notes.messages.createSuccess")
            );
          },
          () =>
            this.toastyService.error(
              this.translate.instant("notes.messages.failCreate")
            )
        )
      )
      .subscribe();
  }

  public deleteNote(noteId: number): void {
    this.confirmModalService.openConfirmModal(
      this.translate.instant("notes.deleteNoteList"),
      this.translate.instant("notes.deleteNoteListMessage")
    );
    this.confirmModalService
      .onModalClose()
      .subscribe((confirmed: boolean): void => {
        if (confirmed) {
          this.notesService
            .deleteNote(noteId)
            .pipe(
              tap(
                () => {
                  this.getNotes();
                  this.toastyService.success(
                    this.translate.instant("notes.messages.deleteSuccess")
                  );
                },
                () =>
                  this.toastyService.error(
                    this.translate.instant("notes.messages.failDelete")
                  )
              )
            )
            .subscribe();
        }
      });
  }

  private getNotes(): void {
    const url = this.notesService.getUrl('')
    this.cacheService.delete(url+'limit='+this.limit);   
    this.notesService
      .getNotes({ limit: this.limit })
      .subscribe((response) => {
        this.filteredNotes = response.model;
        this.getPersons();
      });
  }
}
