import {
    Component,
    Input
} from '@angular/core';
import { WeatherModel } from '../../../core/models/widget/weather/weather.model';
import { UtilsHelper } from '../../../core/heplers/utils.helper';


@Component({
    selector: 'fap-weather-widget',
    templateUrl: './fap_weather-widget.component.html',
    styleUrls: ['./fap_weather-widget.component.scss']
})
export class FapWeatherWidgetComponent {

    @Input()
    public data: WeatherModel;

    public convertDateString(date: string): string {
        return 'days.' + UtilsHelper.getDayNameFromDateString(date.substr(0, 10));
    }
}
