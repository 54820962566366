<div class="dashboard-card dash_cont">
    <div class="row">
        <div class="col-md-4">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>Settings</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div #container class="fixed_img_width" style="text-align: center; max-height: 100%;">
            <div class="position-relative crop-eye-img" (click)="getCoords($event)">
                <!-- <ng-image-slider *ngIf="images.length > 0"
                
                    #nav
                    [showArrow]="false"
                    [imagePopup]="false"
                    [images]="images"
                    [manageImageRatio]="false"
                    [imageSize]="{ width: '100%', height: '250px', space: 0 }"
                >
                </ng-image-slider> -->
                <img class="myImg" #originalImage *ngIf="images.length > 0" [src]="images[0]?.image" alt="">
                <div id="sball"></div>
                <span class="loading small-loader" *ngIf="!images.length" >
                    <span class="loader">
                        <i></i>
                    </span>
                </span>
                <!--<div *ngIf="showMenu && images[0]?.image != undefined">
                  <div class="nav_btns" [ngClass]="!hideCtrls ? 'enabled' : 'disabled'">
                    <button
                        class="round-btn round-btn-right"
                        (click)="nextPan()"
                        [disabled]="currentPanLength == initialPanValue + 1"
                        
                    >
                        <img
                            src="./assets/images/Polygon 4.svg"
                            alt="Polygon 4"
                        />
                    </button>

                    <button 
                        class="round-btn round-btn-left"
                        (click)="prevPan()"
                        [disabled]="initialPanValue == 0"
                    >
                        <img
                            src="./assets/images/Polygon 4.svg"
                            alt="Polygon 4"
                        />
                  </button>
                    <button class="round-btn round-btn-up" [disabled]="currentObjectLength == initialZoomValue + 1" (click)="zoomInClick()">
                        <i class="fa fa-search-plus text-white"></i>
                    </button>
                    <button
                        class="round-btn round-btn-down"
                        (click)="zoomOutClick()"
                        [disabled]="initialZoomValue == 0"
                    >
                        <i class="fa fa-search-minus text-white"></i>
                      </button>
                    </div>
                     <div class="round-btn round-btn-autorotate">
                        <i class="fa fa-repeat f-20 text-white"></i>
                    </div> 
                    
                </div> -->
                <!-- <div class="actions" *ngIf="actions && showMenu">
                    <div class="action" *ngFor="let action of actions">
                        <span *ngIf="action.cmd == 800" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                            <img src="{{apiUrl + action['icon:']}}" alt="">
                        </span>
                        <span *ngIf="action.cmd == 801" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                            <img src="{{apiUrl + action['icon:']}}" alt="">
                        </span>
                        <span *ngIf="action.cmd == 802" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                            <img class="pngImg" src="{{apiUrl + action['icon:']}}" alt="">
                        </span>
                    </div>
                </div> 
                <div class="battery color-yellow-a" *ngIf="batteryLevel && showMenu">
                    <img class="battery_icon" src="../../../../assets/images/battery.svg"> {{batteryLevel}}%
                </div> -->
                <div class="battery color-yellow-a" *ngIf="batteryData?.element?.value_float">
                    <div class="label_container d-flex align-items-center">
                        <div class="icon">
                            <ng-container *ngIf="batteryData?.element?.sensor_info?.icon">
                                <img [src]="mediaUrl + batteryData?.element?.sensor_info?.icon?.file" alt="">
                            </ng-container>
                            <ng-container *ngIf="!batteryData?.element?.sensor_info?.icon && batteryData?.element?.unit?.icon">
                                <img [src]="mediaUrl + batteryData?.element?.unit?.icon?.file" alt="">
                            </ng-container>
                            <!-- <ng-container *ngIf="!batteryData?.element?.sensor_info?.icon && !batteryData?.element?.unit?.icon">
                                <img src="../../../../assets/icons/sensor_microchip.svg" alt="sensor">
                            </ng-container> -->
                        </div>
                        <div class="value">
                            {{batteryData?.element?.value_float}}
                        </div>
                        <div class="unit">
                            {{batteryData?.element?.unit?.unit}}
                        </div>
                    </div>
                </div>
                <div class="camera_status color-yellow-a" *ngIf="cameraStatus?.element?.value_float === 1">
                    <div class="label_container d-flex align-items-center">
                        <div class="icon">
                            <ng-container *ngIf="cameraStatus?.element?.sensor_info?.icon">
                                <img [src]="mediaUrl + cameraStatus?.element?.sensor_info?.icon?.file" alt="">
                            </ng-container>
                            <ng-container *ngIf="!cameraStatus?.element?.sensor_info?.icon && cameraStatus?.element?.unit?.icon">
                                <img [src]="mediaUrl + cameraStatus?.element?.unit?.icon?.file" alt="">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="teaser w-100 d-flex" (click)="showDataModal(widget)" *ngIf="notes">
                <div class="info">
                    <div class="text-white fs12 bg-blur" style="padding: 1px; text-align: left;">
                        <span class="color-yellow-a">Zoom: {{
                            zoomLevelString
                        }}</span>
                        <span>
                            Pan: {{ this.currentIteration }}º
                        </span>
                        <span class="color-yellow-a"
                            >Tilt: {{ this.currentPan }}º</span
                        >
                        <!-- <span class="action_btns" *ngIf="actions" style="display: none;">
                            <div class="action" *ngFor="let action of actions">
                                <span *ngIf="action.cmd == 800 && selectedRequest?.blockchain" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                                    <img src="{{apiUrl + action['icon:']}}" alt="" [ngClass]="selectedRequest.blockchain ? 'enabled' : 'disabled'">
                                </span>
                                <span *ngIf="action.cmd == 801 && selectedRequest?.satellite" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                                    <img src="{{apiUrl + action['icon:']}}" alt="" [ngClass]="selectedRequest.satellite ? 'enabled' : 'disabled'">
                                </span>
                                <span *ngIf="action.cmd == 802 && selectedRequest?.list" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                                    <img class="pngImg" src="{{apiUrl + action['icon:']}}" alt="" [ngClass]="selectedRequest.list ? 'enabled' : 'disabled'">
                                </span>
                            </div>
                        </span> -->
                    </div>
                </div>
                <div class="slider">
                    <marquee *ngIf="notes.length > 0">
                        <div class="row" *ngFor="let note of notes">
                            <div class="inner-item">
                                <span
                                    class="dot"
                                    [ngStyle]="{ background: note?.type?.color }"
                                ></span>
                                <p
                                    class="mb-0 f-14 green letter-spacing-pt2em text-center line-height-pt3"
                                >
                                    {{ note?.type?.name }}
                                </p>

                                <p class="mb-0 val font-weight-bold">
                                    <span *ngIf="note?.name">{{ note?.name }}</span>
                                </p>
                            </div>
                        </div>
                    </marquee>
                </div>
                <span *ngIf="notes.length === 0">
                    {{ 'widget.noExtraInfo' | translate }}
                </span>
            </div>
            <div class="bg-green-b row mx-0">
                <div class="col-md-12 px-2 py-1" style="width: 100%; display: block;">
                    <div class="d-flex flex-wrap flex-md-nowrap">
                        <button
                            (click)="previousNextValue(false)"
                            [disabled]="selectedDropdownValue <= 1"
                            class="round-btn-green round-btn-green-left mr-2"
                        >
                            <img
                                src="./assets/images/Polygon 4.svg"
                                alt="Polygon 4"
                            />
                        </button>
                        <select
                            (change)="changeDropdown($event.target.value)"
                            [value]="selectedDropdownValue"
                            name="id"
                            class="custom-select bs-select border-green-select text-center m-0"
                        >
                            <option
                                *ngFor="
                                    let option of dropdownValue;
                                    let i = index
                                "
                                [value]="option.id"
                            >
                                {{ getFormattedDate(option.time) }}
                            </option>
                        </select>
                        <button
                            (click)="previousNextValue(true)"
                                                       
                            class="round-btn-green round-btn-green-right ml-2"
                        >
                            <img
                                src="./assets/images/Polygon 4.svg"
                                alt="Polygon 4"
                            />
                        </button>
                        <!-- <span class="btn" (click)="showImage(images[0]?.image)">View</span> -->
                        <a class="btn" (click)="showList()" style="line-height:20px">List</a>
                        <!-- <div class="add_circle_a ml-auto">
                            <button
                                class="rounded-circle bg-green-c text-white"
                                (click)="showModal(widget)"
                            ></button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col_width pl-1 mt-3 mt-md-0 c-eye">
      <div class="crop-eye-bg h-100" >
        <div class="row border-green-a mb-2 mx-0" *ngFor="let note of notes">
          <div class="col-6 border-left-blue-a" [ngStyle]="{'border-left-color':note.type.color}">
            <p
              class="mb-0 f-14 letter-spacing-pt2em text-center line-height-pt3"
            >
              {{note.type.name}}
            </p>
            
          </div>
          <div class="col-6 px-0 bg-green-a py-1">
            <p
              class="
                mb-0
                pad-1px
                letter-spacing-pt2em
                f-13
                text-center
                line-height-pt3
                font-weight-bold
              "
            >
              {{note.info}}
            </p>
           </div>
        </div>

        </div>
    </div> -->
    </div>
</div>

<fap-modal
    [inputIsLarge]="true"
    #addEditWidgetModal
    [titleColor]="'#ffffff'"
    inputTitle="Crop eye"
    class="crop_modal"
    [showFooter]="false"
>
    <div class="md_content">
        <h4>{{"widget.acquisitionList" | translate}}</h4>
        <div class="aq_list">
            <div class="aq_li" *ngFor="let list of acquisitionList; let idx = index">
                <div class="date">
                    {{ getFormattedDate(list.time) }}
                </div>
                <div class="user">
                  
                    <ng-container *ngFor="let user of personList">
                      <ng-container *ngIf="user?.user">
                          <span *ngIf="user?.user?.id == list?.user">
                            <span class="picture" *ngIf="user?.picture">
                              <img style="max-width: 100%; display: inline-block;" src="{{mediaUrl + user?.picture}}" alt="profile picture" />
                            </span>
                            <span class="picture" *ngIf="!user?.picture">
                              <img style="max-width: 100%; display: inline-block;" src="../../../../assets/images/people_placeholder.png" alt="profile picture" />
                            </span>
                            <b *ngIf="list.user === 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</b>
                            <span *ngIf="list.user != 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</span>
                            </span
                        >
                        </ng-container>
                    </ng-container>
                </div>
                <!-- <div class="type">
                    <ng-container *ngFor="let type of imageTypes">
                        <span *ngIf="type.id === list.type">{{
                            type.name
                        }}</span>
                    </ng-container>
                </div> -->
                <div class="status">
                    <input
                        type="checkbox"
                        (change)="statusChange(list)"
                        [value]="list.accepted"
                        [checked]="list.accepted"
                        [disabled]="
                            !globalRegistry.userData.profile.role == 1000
                        "
                    />
                    <span></span>
                </div>
                <div
                    class="delete"
                    *ngIf="
                        globalRegistry.userData.profile.role == 1000 ||
                        globalRegistry.userData.profile.id == list.user
                    "
                >
                    <span style="cursor: pointer">
                        <em class="material-icons delete-icon" (click)="removeItem(list.id)"
                            >delete</em
                        >
                    </span>
                </div>
            </div>
        </div>
        <a class="btn mt-2 ml-0" target="_blank" [href]="images[0]?.image" style="line-height:20px">View</a>
    </div>
</fap-modal>

<fap-modal
    [inputIsLarge]="true"
    #notesModal
    [titleColor]="'#ffffff'"
    inputTitle="Notes"
>
    <div class="pl-1 mt-3 mt-md-0 c-eye">
        <div class="crop-eye-bg h-100">
            <div
                class="row border-green-a mb-2 mx-0"
                *ngFor="let note of notes"
            >
                <div
                    class="col-6 border-left-blue-a"
                    [ngStyle]="{ 'border-left-color': note?.type?.color }"
                >
                    <p
                        class="mb-0 f-14 letter-spacing-pt2em text-center line-height-pt3"
                    >
                        {{ note?.type?.name }}
                    </p>
                </div>
                <div class="col-6 px-0 bg-green-a py-1">
                    <p
                        class="mb-0 pad-1px letter-spacing-pt2em f-13 text-center line-height-pt3 font-weight-bold"
                    >
                        {{ note?.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</fap-modal>

<fap-modal
    [inputIsLarge]="true"
    [showFooter]="false"
    #confirmPopup
    [titleColor]="'#ffffff'"
    [inputTitle]="'widget.imageAcquisition' | translate"
>
    <div class="mod_body">
        <h2>{{"widget.focusArea" | translate}}</h2>
        <p class="region_info">{{'widget.focusHere' | translate}}</p>
        <div class="img_box" *ngIf="size">
            <img class="myImg" #originalImage *ngIf="images.length > 0" [src]="images[0]?.image" alt="" [ngStyle]="{'width': size[0] + 'px', 'height': size[1] + 'px'}">
            <div id="sball1"></div>
        </div>
    <div class="d-flex p-1 row">
        <form
            class="form-group w-100 image_form"
            [formGroup]="imageForm"
        >
            <!-- <mat-form-field class="acquiring">
                <mat-label>Acquiring</mat-label>
                <mat-select
                    required
                    (selectionChange)="changeType($event)"
                    
                >
                     <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
            <input matInput #farmFilter placeholder="Search">
        </mat-form-field> 
                    <div class="slct">
                        <mat-option
                            *ngFor="let image of imageTypes"
                            [value]="image.id"
                            style="text-transform: capitalize"
                        >
                            <span class="small_text">{{
                                image?.name
                            }}</span>
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field> -->
            <mat-form-field class="zoom">
                <mat-label>{{"widget.zoom" | translate}}</mat-label>
                <mat-select required formControlName="zoom">
                    <div class="slct scroll_wrap">
                        <mat-option
                            *ngFor="let zoom of zoomLevels"
                            [value]="zoom"
                            style="text-transform: capitalize"
                        >
                            <span class="small_text">{{ zoom }}</span>
                        </mat-option>
                    </div>
                </mat-select>
                <span class="unit small"><i class="fa fa-times"></i></span>
            </mat-form-field>
            <mat-form-field class="pan" *ngIf="this.selectedType != 30">
                <mat-label>{{"widget.pan" | translate}}</mat-label>
                <mat-select required formControlName="pan">
                    <div class="slct scroll_wrap">
                        <mat-option
                            *ngFor="
                                let pan of panLevels
                            "
                            [value]="pan"
                            style="text-transform: capitalize"
                        >
                            <span class="small_text">{{ pan }}</span>
                        </mat-option>
                    </div>
                </mat-select>
                <span class="unit">°</span>
            </mat-form-field>
            <mat-form-field class="tilt">
                <mat-label>{{"widget.tilt" | translate}}</mat-label>
                <!-- <mat-select required formControlName="tilt">
                    <div class="slct scroll_wrap">
                        <mat-option
                            *ngFor="
                                let tilt of tiltLevels
                            "
                            [value]="tilt"
                            style="text-transform: capitalize"
                        >
                            <span class="small_text">{{ tilt }}</span>
                        </mat-option>
                    </div>
                </mat-select> -->
                <input matInput placeholder="Tilt" type="number" (blur)="restrict($event)" formControlName="tilt">
                <span class="unit">°</span>
            </mat-form-field>
            <!-- <div class="add_circle_a ml-auto">
                <button
                class="btn"
                    (click)="submit()"
                >Take snapshot</button>
            </div> -->
            <div class="btns">
                <button class="btn" (click)="submit()">{{"yes" | translate}}</button>
                <button class="btn" (click)="closeModal(widget)">{{"no" | translate}}</button>
            </div>
        </form>
    </div>
    </div>
</fap-modal>
