
<span *ngIf="isLoading" class="loader">
  <i></i>
</span>
  <ng-container *ngFor="let activity of activities">
    <div class="cards-grid">
      <fap-activities-card
        [activity]="activity"
        [equipments]="equipments"
        (deleteActivity)="deleteActivity($event)"
        [activityTypes]="activityTypes"
        [persons]="persons"
      ></fap-activities-card>
    </div>
  </ng-container>

<fap-modal #removeActivityModal
    [inputPrimaryButtonInterface]="removeActivityModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="removeActivityModalButtonSecondaryInterface"
    inputTitle="{{ 'activity.removeActivity' | translate }}">
    <span class="remove-person-text">
        {{ 'people.areYouSureYouWantToRemove' | translate }}
        <b>{{ selectedActivity?.name }}</b>
    </span>
</fap-modal>