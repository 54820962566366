import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { NoteModel } from '../../../core/models/notes/note.model';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { DataService } from '../../../core/services/websocket/data.service';
import { FapModalComponent } from '../../partials';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'fap-sensor-value-widget',
  templateUrl: './fap-sensor-value-widget.component.html',
  styleUrls: ['./fap-sensor-value-widget.component.scss']
})
export class FapSensorValueWidgetComponent implements OnInit {

  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];

  public selectedWidget;
  public mediaUrl = environment.mediaUrl;
  @Input()
  public notes: NoteModel[];

  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('addEditWidgetModal') addEditWidgetModal: FapModalComponent;

  public toggleMain = false;
  public toggleSub = false;
  public personList: any;
  public showMenu = true;
  public fromDate = new Date();
  public toDate = new Date();
  public agg = 'day';
  public subscription1: Subscription;
  public subscription2: Subscription;
  public subscription3: Subscription;
  public dataSource: any[] = [];
  public finalSensorList = [];
  public sensorNames = [];
  public columns: any[] = [];
  public sensorIds = [];
  public units: [];

  public subscription: Subscription;
  public translatedNames: any = [];
  public langString: string;

  constructor(
      private globalRegistry: GlobalRegistryService,
      public companyService: CompanyService,
      public navService: NavService,
      public sensorService: SensorService,
      public toastr: ToastrService,
      public translateService: TranslateService,
      public dataService: DataService
  ) {}

  public toggleImageMenu() {
      this.showMenu = !this.showMenu;
  }

  getTranslation(translation) {
      const t =this.globalRegistry.systemData.translations.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
          if(t[0][this.langString] != '') {
              return t[0][this.langString];
          } else {
              return
          }
      } else {
          return translation
        }
    }

  ngOnInit(): void {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.agg = '';

      setTimeout(() => {
          this.drillData();
      }, 2000);

      this.subscription = this.sensorService.getName.subscribe((res) => {
          if (res) {
              this.dataSource = [];
              this.drillData();
          }
      });

      this.langString = localStorage.getItem('language');
      this.subscription.add(
          this.navService.getCurrentLanguage.subscribe((lang) => {
              if (lang) {
                  this.langString = lang;
              }
          })
      );
      this.translatedNames = this.globalRegistry.systemData.translations;
  }

  

  showModal(widget): void {
      this.selectedWidget = widget;
      this.addEditWidgetModal.showModal();
  }

  public getLotName(lotId: number = this.widget.objectId): string {
      return this.globalRegistry.systemData.lots.find(
          (lot) => lot.id === lotId
      ).name;
  }

  hideMain() {
      this.toggleMain = false;
  }

  toggleMenu() {
      this.toggleMain = !this.toggleMain;
  }

  getFromDate() {
      this.subscription1 = this.sensorService.getFromDate.subscribe((res) => {
          this.fromDate = res;
          // console.log(this.fromDate);
      });
  }

  getToDate() {
      this.subscription2 = this.sensorService.getToDate.subscribe((res) => {
          this.toDate = res;
          // console.log(this.toDate);
      });
  }

  getAgg() {
      this.subscription3 = this.sensorService.getAgg.subscribe((res) => {
          this.agg = res;
          // console.log(res);
      });
  }

  public drillData() {
      
      this.fromDate = new Date();
      this.toDate = new Date();
      this.agg = '';
      this.getFromDate();
      this.getToDate();
      this.getAgg();
      this.dataSource = [];
      const sensors: Array<SensorModel> = this.widget.config['settings']['options'].map(
          (sensor: { sensor: SensorModel }) => sensor?.sensor
      );
      const css: Array<SensorModel> = this.widget.config['settings']['options'].map(
          (css: { css }) => css?.css
      );
      const sensorIds = sensors.map((sensor) => sensor.id);
      // console.log(sensorIds.toString());
      setTimeout(() => {
          const observables = sensorIds.map((sensorId) =>
              this.sensorService.drillData({
                  sensor: sensorId,
                  from: this.fromDate.toISOString(),
                  agg_by: this.agg,
                  to: this.toDate.toISOString(),
              })
          );
        //   const res: any = {};
          combineLatest(observables).subscribe((drilled) => {
              console.log(drilled);
              const objects = Object.values(this.objects);
              const unitTypes = Object.values(this.unitTypes);
              this.widget.config['settings']['options'] = this.widget.config['settings'][
                  'options'
              ].map((item) => {
                  return {
                      ...item,
                      unitTypes: unitTypes.filter(
                          (el) => el.id === item.sensor.unitType
                      ),
                      objects: objects.filter(
                          (el) => el.id === item.sensor.object_id
                      ),
                  };
              });
              this.sensorNames = this.widget.config['settings']['options'].map(
                  (item) =>
                      `${item.objects[0].extraText} ${item.unitTypes[0].name_t ? item.unitTypes[0].name_t : item.unitTypes[0].name}`
              );

              this.units = this.widget.config['settings']['options'].map(
                  (item) => `${item.unitTypes[0].unit}`
              );

              this.columns = [...this.sensorNames];
              //console.log(this.columns);
              this.dataSource = [];
              drilled.forEach((element) => {
                  const last = element[element.length - 1];
                  this.dataSource.push(last);
              });
              setTimeout(()=> {
                if(this.dataSource.length) {
                  this.dataSource.forEach((e, i) => {
                      if(e && e.css) e.css = css[i];
                  });
                  this.dataSource.forEach((e, i) => {
                      if(e && e.name) e.name = this.columns[i];
                  });
                  this.dataSource.forEach((e, i) => {
                      if(e && e.unit) e.unit = this.units[i];
                  });
                }
              },1000);
              console.log(this.dataSource);
          }),
              1000;
      });
  }
}
