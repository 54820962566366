import { Component, OnChanges, SimpleChanges, EventEmitter, Output, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { FarmModel } from '../../../../core/models/farm/farm.model';
import { PartyModel } from '../../../../core/models/party/party.model';
import { DatePipe } from '@angular/common';
import { StockIncomeInterface } from '../../../../core/interfaces/stock/stock-income.interface';
import { ResourceTypeModel } from '../../../../core/models/type/resource-type.model';
import { FapModalComponent } from '../../../../shared/partials';
import { UnitValueModel } from '../../../../core/models/units/unit-value.model';
import { Subscription } from 'rxjs';
import { TranslationModel } from '../../../../core/models/type/translation-type.model';
import { NavService } from '../../../../shared/services/nav.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
    selector: 'fap-stock-income',
    templateUrl: './fap_stock-income.component.html',
    styleUrls: ['./fap_stock-income.component.scss'],
    providers: [DatePipe]
})
export class FapStockIncomeComponent implements OnChanges, OnInit, OnDestroy{

    @Input()
    public farms: Array<FarmModel>;

    @Input()
    public parties: Array<PartyModel>;

    @Input()
    public resourceTypes: Array<ResourceTypeModel>;

    @Output()
    public addStockIncome: EventEmitter<StockIncomeInterface> = new EventEmitter();

    @ViewChild('addResourceTypeModal')
    public addResourceTypeModal: FapModalComponent;

    public stockIncomeForm: UntypedFormGroup;

    public selectedResource: ResourceTypeModel;

    public parentResource: ResourceTypeModel;

    public quantity: UnitValueModel;
    public translatedNames: Array<TranslationModel>;
    public langString: string;
    public subscription: Subscription = new Subscription();

    constructor(private formBuilder: UntypedFormBuilder,
                private datePipe: DatePipe, private navService:NavService, public global:GlobalRegistryService) {
        this.initStockIncomeForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'farms')) {
            this.initStockIncomeForm();
        }
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
        if(lang) {
          this.langString = lang;
        }
    }));
    this.translatedNames = this.global.systemData.translations;
    }

    public submit(): void {
        if(this.stockIncomeForm.invalid) {
            Object.keys(this.stockIncomeForm.controls).forEach((controlName: string): void =>
                this.stockIncomeForm.controls[controlName].markAsTouched()
            );
            return;
        }

        if(this.stockIncomeForm.dirty) {
            const stockIncome: StockIncomeInterface = {
                storageTemperature: this.stockIncomeForm.controls['storageTemperature'].value,
                storageHumidityMin: this.stockIncomeForm.controls['storageMinimumHumidity'].value,
                unit: this.quantity.unit,
                storageHumidityMax: this.stockIncomeForm.controls['storageMaximumHumidity'].value,
                cost: this.stockIncomeForm.controls['cost'].value,
                info: this.stockIncomeForm.controls['info'].value,
                type: this.stockIncomeForm.controls['type'].value,
                expiration: this.datePipe.transform(this.stockIncomeForm.controls['expirationDate'].value, 'yyyy-MM-dd'),
                partyFrom: this.stockIncomeForm.controls['partyFrom'].value,
                partyTo: this.stockIncomeForm.controls['partyTo'].value,
                receiver: this.stockIncomeForm.controls['receiver'].value,
                receiveDate: this.datePipe.transform(this.stockIncomeForm.controls['receiveDate'].value, 'yyyy-MM-dd'),
                internalTransaction: this.stockIncomeForm.controls['internalTransaction'].value,
                virtualQuantity: this.quantity.value
            };

            this.addStockIncome.emit(this.farms ? {...{farm: this.stockIncomeForm.controls['farm'].value}, ...stockIncome} : stockIncome);
        }
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.stockIncomeForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public selectResource(resource?: ResourceTypeModel, parent?:  ResourceTypeModel): void {
        this.selectedResource = resource;

        this.parentResource = parent ? parent : null;
        this.addResourceTypeModal.showModal();
    }

    public onResourceTypeAction(resourceTypeId: number): void {
        this.stockIncomeForm.controls['type'].setValue(resourceTypeId);
        this.addResourceTypeModal.hideModal();
    }

    private initStockIncomeForm(): void {
        this.stockIncomeForm = this.formBuilder.group({
            storageTemperature: [null],
            storageMinimumHumidity: [null],
            storageMaximumHumidity: [null],
            cost: [null, Validators.compose([
                Validators.required,
            ])],
            info: [''],
            type: [null, Validators.compose([
                    Validators.required,
                ])
            ],
            expirationDate: [null],
            partyFrom: [null, Validators.compose([
                    Validators.required,
                ])
            ],
            partyTo: [null, Validators.compose([
                    Validators.required,
                ])
            ],
            receiver: [''],
            receiveDate: [null],
            internalTransaction: [false],
        });

        if(this.farms) {
            this.stockIncomeForm.addControl('farm', new UntypedFormControl(null, Validators.required));
        }
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
          return t[0][this.langString];
        } else {
            return translation
        }
      }

      public ngOnDestroy(): void {
          this.subscription.unsubscribe()
      }
}
