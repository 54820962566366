<div class="dashboard-card dash_cont">
    <div class="row">
        <div class="col-md-4">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name" *ngIf="lotName !== ''">
            <h2>{{ lotName }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="dataSource.length == 0" style="display: flex; align-items:center; justify-content: center; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 70%; width: 100%;">
        <h3 style="text-align: center;">
          <span class="loader">
            <i></i>
          </span>
      </h3>
      </div> -->
    <div class="card-content" *ngIf="dataSource.length != 0">
        <table>
            <tr *ngFor="let item of dataSource">
                <td class="icon">
                    <span *ngIf="item?.sensor?.icon">
                        <img [src]="mediaUrl + item.sensor?.icon?.file" alt="">
                    </span>
                    <span *ngIf="!item?.sensor?.icon && item?.unitTypes[0].icon">
                        <img [src]="mediaUrl + item?.unitTypes[0].icon?.file" alt="default placeholder">
                    </span>
                    <span *ngIf="!item?.sensor?.icon && !item?.unitTypes[0].icon">
                        <img src="../../../../assets/icons/sensor_microchip.svg" alt="default placeholder">
                    </span>
                </td>
                <td class="name overviewName">
                    <div class="main_text">
                        {{item?.name}}
                        <span>{{item?.devices[0]?.product?.name}} {{item?.devices[0]?.id}}</span>
                    </div>
                    <div class="tooltip">{{item?.name}} <span>{{item?.devices[0]?.product?.name}} {{item?.devices[0]?.id}}</span></div>
                </td>
                <td class="action_btn">
                    <button *ngIf="item?.sensor?.type && item?.sensor?.type?.groups" (click)="getFormTypes(item)">
                        <ng-container *ngFor="let group of item.sensor?.type?.groups">
                            <img src="../../../../assets/images/icons/link.png" alt="icon" *ngIf="group === 'actuator'">
                        </ng-container>
                    </button>
                    
                </td>
                <td class="dateTime">
                    <span *ngIf="item?.date">
                        {{item?.date}}
                    </span>
                    <span *ngIf="!item?.date">
                        --
                    </span>
                </td>
                <td class="value">
                    <span *ngIf="item?.sensor?.lastValue && item?.sensor?.lastValue?.value_coord">
                        <button (click)="displayCoord(item?.sensor?.lastValue?.value_coord)"><img src="../../../../assets/icons/marker-white.png" style="max-width: 12px" alt="marker"></button>
                    </span>
                    <span *ngIf="item?.value_float">
                        {{item?.value_float | number:'1.'+globalRegistry.systemData.company.digits}}
                    </span>
                    <span *ngIf="!item?.value_float && !item?.sensor?.lastValue?.value_coord">
                        0
                    </span>
                </td>
                <td class="unit" *ngIf="!item?.sensor?.lastValue?.value_coord">
                    <span *ngIf="item?.value_float">{{item.unit && item.unit !== 'null' ? item.unit : ''}}</span>
                </td>
            </tr>
        </table>
      </div>
</div>

<fap-modal #sensorInformationPopup [inputTitle]="'widget.sensorInformation' | translate">
    <form [formGroup]="addEditSensorInformationForm" class="col-12 p-0">
        <mat-form-field class="disabled w-100">
            <mat-label class="col-form-label">{{ 'widget.device' | translate }}</mat-label>
            <input matInput formControlName="device" readonly #nameInput>
        </mat-form-field>

        <mat-form-field class="w-100" (click)="addEditTranslation()">
            <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
            <input matInput formControlName="name" readonly #nameInput>
            <mat-error *ngIf="addEditSensorInformationForm.controls['name_t'].errors?.required">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>

        <ng-container *ngFor="let sensorForm of sensorInformationForm">
            <ng-container *ngFor="let formField of formFields">
                <ng-container *ngIf="sensorForm.type === formField.id">
                    <ng-container *ngIf="formField.valueType === 'boolean'">
                        <label>{{ formField.name }}</label>
                        <div class="custom_toggle mb-4">
                            <div class="on">
                                <input type="radio" (change)="toggleValue(1)" [formControlName]="sensorForm?.id" value="1">
                                <span>On</span>
                            </div>
                            <div class="off">
                                <input type="radio" (change)="toggleValue(0)" [formControlName]="sensorForm?.id" value="0">
                                <span>Off</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        
        <chips 
            [items]="automations" 
            [selectedItems]="chips"
            [label]="'widget.automation' | translate"
            (updatedItems)="updatechips($event)"
            [addEnabled]="true"
            [isEditable]="true"
            (editItem)="editAutomation($event)"
            (showAddModal)="validateAutomationPopup()"
            (removeItemFromList)="removeItem($event)"
            (filterItem)="filterAutomation($event)"
        >
        </chips>

        <div class="btns">
            <div class="action_btns">
                <button class="btn" (click)="sensorInformationPopup.hideModal(); addEditSensorInformationForm.reset(); addEditSensorInformationForm.clearValidators()">{{ 'translations.cancel' | translate }}</button>
                <button class="btn" (click)="submitForm()">{{ 'translations.save' | translate }}</button>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #automationPopup [inputTitle]="'widget.automation' | translate">
    <form [formGroup]="addEditAutomationForm" class="col-12 p-0">
        <mat-form-field class="w-100" (click)="addEditAutomationTranslation()">
            <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
            <input matInput formControlName="name" readonly #nameInput1>
            <mat-error *ngIf="addEditAutomationForm.controls['name_t'].errors?.required">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>{{ 'widget.scheduler' | translate }}</mat-label>
            <mat-select #mys formControlName="scheduler" required>
                <div class="d-flex" style="padding: 0 10px; position: relative; align-items: center;">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #typeFilter placeholder="Search">
                </mat-form-field>
                <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                    <i class="fa fa-search f-20"></i>
                </mat-label>
                <div class="input-group-append pl-1" (click)="schedulerPopup.showModal()">
                    <span class="input-group-text">
                        <i class="icofont icofont icofont-plus"></i>
                    </span>
                </div>
            </div>
            <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option class="noteIcons" *ngFor="let scheduler of schedulers" [value]="scheduler?.id">
                    <span class="mat-option-text">
                    <div class="name">{{ scheduler?.name_t ? getTranslation(scheduler?.name_t) : '' }}</div>
                    <div class="actions">
                        <i class="fa fa-edit" (click)="editScheduler(scheduler); $event.stopPropagation()"></i>
                        <i class="fa fa-trash" (click)="deleteScheduler(scheduler?.id); $event.stopPropagation()"></i>
                    </div>
                    </span>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field>

        <ng-container *ngFor="let autoForm of automationForm">
            <ng-container *ngFor="let formField of autoFormFields">
                <ng-container *ngIf="autoForm.type === formField.id">
                    <ng-container *ngIf="formField.valueType === 'boolean'">
                        <label>{{ formField.name }}</label>
                        <div class="custom_toggle mb-4">
                            <div class="on">
                                <input type="radio" (change)="toggleValue(1)" [formControlName]="autoForm?.id" value="1">
                                <span>On</span>
                            </div>
                            <div class="off">
                                <input type="radio" (change)="toggleValue(0)" [formControlName]="autoForm?.id" value="0">
                                <span>Off</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        
        <div class="btns">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="automation">
                    <i class="fa fa-trash" (click)="deleteAutomation(automation?.id)"></i>
                </button>
            </div>
            <div class="action_btns">
                <button class="btn" (click)="automationPopup.hideModal(); addEditAutomationForm.reset()">{{ 'translations.cancel' | translate }}</button>
                <button class="btn" (click)="submitAutomation()">{{ 'translations.save' | translate }}</button>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #schedulerPopup [inputTitle]="'widget.scheduler' | translate">
    <form [formGroup]="addEditSchedulerForm" class="col-12 p-0">
        <mat-form-field class="w-100" (click)="addEditSchedulerTranslation()">
            <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
            <input matInput formControlName="name" readonly #nameInput2>
            <mat-error *ngIf="addEditSchedulerForm.controls['name_t'].errors?.required">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>

        <div class="period">
            <div class="start_date">
                <mat-form-field (click)="startPicker.open()" class="w-100">
                    <mat-label class="col-form-label">From</mat-label>
                    <input matInput readonly [matDatepicker]="startPicker" placeholder="From" [max]="maxDate" (dateChange)="minValueChanged($event)" [disabled]="false" formControlName="from"/>
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="end_date">
                <mat-form-field (click)="endPicker.open()" class="w-100">
                    <mat-label class="col-form-label">To</mat-label>
                    <input matInput readonly [matDatepicker]="endPicker" placeholder="To" [min]="minDate" (dateChange)="maxValueChanged($event)" [disabled]="false" formControlName="to"/>
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="days mb-4">
            <div class="days_container">
                <div class="day" *ngFor="let day of availableDays">
                    <label >
                        <input type="checkbox" [formControl]="getCheckboxControl(day.id)" [value]="day.id">
                        <span class="box"></span>
                        <span>{{ day.day }}</span>
                    </label>
                </div>
            </div>
        </div>

        <div class="time_info">
            <div class="hours cl">
                <mat-form-field class="w-100">
                    <mat-label>Hour</mat-label>
                    <mat-select
                        formControlName="hour"
                        required
                        multiple
                    >
                        <div class="scroll_wrap">
                            <mat-option
                                *ngFor="
                                    let t of [
                                        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 
                                        '13', '14', '15', '16', '17', '18', '21', '22', '23', '24'
                                    ]
                                "[value]="t">
                                {{t}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="minute cl">
                <mat-form-field class="w-100">
                    <mat-label>Minute</mat-label>
                    <mat-select
                        formControlName="minute"
                        required
                    >
                        <div class="scroll_wrap">
                            <mat-option
                                *ngFor="
                                    let t of [
                                        '5', '10', '15', '20', '25', '30',
                                        '35', '40', '45', '50', '55', '60'
                                    ]
                                "[value]="t">
                                {{t}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="duration cl">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">Duration</mat-label>
                    <input matInput formControlName="duration" #nameInput> min
                </mat-form-field>
            </div>
        </div>

        <div class="btns">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="scheduler">
                    <i class="fa fa-trash" (click)="deleteScheduler(scheduler?.id)"></i>
                </button>
            </div>
            <div class="action_btns">
                <button class="btn" (click)="schedulerPopup.hideModal(); addEditSchedulerForm.reset()">{{ 'translations.cancel' | translate }}</button>
                <button class="btn" (click)="submitScheduler()">{{ 'translations.save' | translate }}</button>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditTranslationsPopup [inputTitle]="'Sensor name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.currentSensor)" 
        prefix="SENSOR_" 
        [translation]="translation"
        [group]="'sensors'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditAutomationTranslationPopup [inputTitle]="'Automation name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.currentSensor)" 
        prefix="TRIGGER_" 
        [translation]="automationTranslation"
        [group]="'triggers'"
        (submitTranslation)="onAddUpdateAutomationTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditSchedulerTranslationPopup [inputTitle]="'Scheduler name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.currentSensor)" 
        prefix="SCHEDULER_" 
        [translation]="schedulerTranslation"
        [group]="'schedulers'"
        (submitTranslation)="onAddUpdateSchedulerTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #mapModal [showFooter]="false" [inputTitle]="'widget.location' | translate" [inputIsMediumLarge]="true">
    <div class="form-group map-container">
        <div
          class="search"
          [ngClass]="{ opened: mapSearchToggle, 'd-flex': mapTypeControl }"
        >
          <label for="search" class="fa fa-search" (click)="mapSearchToggle = true"> </label>
            <input
              matInput
              (focus)="mapSearchToggle = true"
              (focusout)="mapSearchToggle = false"
              (keydown.enter)="$event.preventDefault()"
              placeholder="search for location"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              class="form-control"
              #search
              [formControl]="searchControl"
            />
          
        </div>
        <agm-map
          [latitude]="currentCenter?.lat"
          [longitude]="currentCenter?.lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="true"
          [scaleControl]="true"
          (mapClick)="mapClicked($event)"
          (mapReady)="mapReady($event)"
        >
          <agm-marker
            *ngFor="let m of markers; let i = index"
            [latitude]="m.lat"
            [longitude]="m.lng"
            [label]="m.label"
            [markerDraggable]="m.draggable"
            [iconUrl]="m.iconUrl"
            [animation]="'DROP'"
            (dragEnd)="markerDragEnd(m, $event)"
          >
            <!-- [animation]="'DROP'"  -->
      
            <agm-info-window>
              <strong>{{ m.content }}</strong>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
</fap-modal>