import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { ActivityModel } from '../../../models/activity/activity.model';
import { ActivityGroupModel } from '../../../models/activity/activity-group.model';
import { ActivityGroupInterface } from '../../../interfaces/activity/activity-group.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { map, take } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js'
import { CacheResolverService } from '../cache/cache-resolver.service';


@Injectable()
export class ActivityService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient, public cacheService: CacheResolverService) {
        super(tosterService, _http, '/activity/');
    }
    public getActivitiesList: Subject<void> = new Subject<void>();
    public activityLots = new BehaviorSubject([]);

    getActivityLots = this.activityLots.asObservable();

    public setActivityLots(lotId) {
        this.activityLots.next(lotId);
    }

    getUrl(slug) {
        return this.url+slug
    }

    decryptResponse(r) {
        const token = localStorage.getItem('auth_token').substring(0, 32);
        const _key = CryptoJS.enc.Utf8.parse(token);
        const decrypted = CryptoJS.AES.decrypt(
          r, _key, {
            keySize: 32,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8);

          const data = JSON.parse(decrypted)

          const toCamel = (s) => {
            return s.replace(/([-_][a-z])/ig, ($1) => {
              return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
            });
          };
          
          const isArray = function (a) {
            return Array.isArray(a);
          };
          
          const isObject = function (o) {
            return o === Object(o) && !isArray(o) && typeof o !== 'function';
          };
          
          const keysToCamel = function (o) {
            if (isObject(o)) {
              const n = {};
          
              Object.keys(o)
                .forEach((k) => {
                  n[toCamel(k)] = keysToCamel(o[k]);
                });
          
              return n;
            } else if (isArray(o)) {
              return o.map((i) => {
                return keysToCamel(i);
              });
            }
          
            return o;
          };
          return keysToCamel(data);
        }

    public getActivities(params?: {}): Observable<ResponseModel<ActivityModel[]>> {
        return this.mapRequest<ActivityModel[]>(
            this._http.get(this.url + 'activities/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                })
                .pipe(map((x) => {
                    return this.decryptResponse(x['raw'])
                })), false).pipe(take(1))
    }
 
    public getActivity(activityId: number): Observable<ResponseModel<ActivityModel>> {
        this.cacheService.delete(this.url + 'activities/' + activityId + '/')
        return this.mapRequest(
            this._http.get(this.url + 'activities/' + activityId + '/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                }),
            ActivityModel,
            false
        );
    }

    public getActivityGroup(activityGroupId: number): Observable<ResponseModel<ActivityGroupModel>> {
        return this.mapRequest(
            this._http.get(this.url + 'group/' + activityGroupId + '/', FapAPIRequestOptions.withTokenRequestOptions),
            ActivityGroupModel,
            false
        );
    }

    public updateActivityGroup(activityGroupId: number, activityGroup: ActivityGroupInterface): Observable<ResponseModel<ActivityGroupModel>> {
        return this.mapRequest(
            this._http.patch(this.url + 'group/' + activityGroupId + '/',
                this.camelCaseModelToSnakeCaseJson(activityGroup),
                FapAPIRequestOptions.withTokenRequestOptions),
            ActivityGroupModel,
            false
        );
    }

    public createActivityGroup(activityGroup: ActivityGroupInterface): Observable<ResponseModel<ActivityGroupModel>> {
        return this.mapRequest(
            this._http.post(this.url + 'group/',
                this.camelCaseModelToSnakeCaseJson(activityGroup),
                FapAPIRequestOptions.withTokenRequestOptions),
            ActivityGroupModel,
            false
        );
    }

    public deleteActivityGroup(activityGroupId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'group/' + activityGroupId + '/',
                FapAPIRequestOptions.withTokenRequestOptions));
    }

    public getActivityGroups(params?: {}): Observable<ResponseModel<ActivityGroupModel[]>> {
        params = params || { limit: 1000, offset: 0 };
        return this.mapRequest<ActivityGroupModel[]>(
            this._http.get(this.url + 'group/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
                ActivityGroupModel,
            true
        ).pipe(take(1));
    }

    public createActivity(activity: ActivityModel): Observable<ResponseModel<ActivityModel>> {
        return this.mapRequest(
            this._http.post(this.url + 'activities/',
            this.camelCaseModelToSnakeCaseJson(activity),
                FapAPIRequestOptions.withTokenRequestOptions),
            ActivityModel,
            false
        );
    }

    public deleteActivity(activityId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'activities/' + activityId + '/',
                FapAPIRequestOptions.withTokenRequestOptions));
    }

    public updateActivity(activityId: number, activity: ActivityModel): Observable<ResponseModel<ActivityModel>> {
        this.cacheService.delete(this.url + 'activities/' + activityId + '/')
        return this.mapRequest(
            this._http.patch(this.url + 'activities/' + activityId + '/',
                this.camelCaseModelToSnakeCaseJson(activity),
                FapAPIRequestOptions.withTokenRequestOptions),
            ActivityModel,
            false
        );
    }
}
