<div class="row add-edit-container ter-container nc">
    <div class="col-md-12 p-0 col-header">
        <div [ngStyle]="{'background':navService.sidebarBackgroundColor}" class="header">
            <p *ngIf="!person">{{ 'people.createPerson' | translate }}</p>
            <p *ngIf="person">{{ 'people.editPerson' | translate }}</p>
        </div>
        <!-- <div class="card">
            <h2 *ngIf="person" class="card-header-title">
                {{ 'people.editPerson' | translate }}
            </h2>
            <h2 *ngIf="!person" class="card-header-title">
                {{ 'people.createPerson' | translate }}
            </h2>
        </div> -->
    </div>
    <div class="wrp w-100">
    <div class="image mb-3 col-4 p-0">
        <fap-upload-avatar [inputImageUrl]="innerImage" (outputImageLoaded)="imageChanged($event)">
        </fap-upload-avatar>
        <span *ngIf="noProfilePictureError" class="profile-image-error">
            {{ 'Profile picture required' }}
        </span>
    </div>
    <form [formGroup]="editPersonForm" class="col-md-12 p-0 p-15">
        <div class="form-grid">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'auth.register.firstName' | translate }}</mat-label>
                    <input matInput formControlName="firstName">
                    <mat-error *ngIf="ifEditPersonFormControlHasError('firstName','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="ifEditPersonFormControlHasError('firstName','minlength')">
                        <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="ifEditPersonFormControlHasError('firstName','maxlength')">
                        <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">

                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'auth.register.lastName' | translate }}</mat-label>
                    <input matInput formControlName="lastName">
                    <mat-error *ngIf="ifEditPersonFormControlHasError('lastName','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="ifEditPersonFormControlHasError('lastName','minlength')">
                        <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="ifEditPersonFormControlHasError('lastName','maxlength')">
                        <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group adress-field">
                <div class="w-100">
                    <mat-label class="col-form-label" [ngClass]="fullAddress ? 'float' : ''">{{ 'user.profile.address' | translate }}</mat-label>
                    <input matInput formControlName="addressId" style="display: none;">
                    <div class="full_address" (click)="editAddress(fullAddress)">
                      {{fullAddress?.number ? fullAddress?.number+',' : ''}} {{fullAddress?.street ? fullAddress?.street+',' : ''}} {{fullAddress?.city ? fullAddress?.city+',' : ''}} {{fullAddress?.postal_code ? fullAddress?.postal_code+'' : ''}} {{fullAddress?.country ? fullAddress?.country : ''}}
                    </div>                          
                  </div>
            </div>
            <div class="form-group">

                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'people.phone' | translate }}</mat-label>
                    <input matInput formControlName="phone">
						<!-- <ngx-mat-intl-tel-input formControlName="phone" [preferredCountries]="['ro']" [enablePlaceholder]="true" [enableSearch]="true"
							name="phone" #phone>
						</ngx-mat-intl-tel-input>                      -->
                    <mat-error *ngIf="ifEditPersonFormControlHasError('phone','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">

                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'people.job' | translate }}</mat-label>
                    <input matInput formControlName="job">
                    <mat-error *ngIf="ifEditPersonFormControlHasError('job','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <fap-add-edit-delete-select
                    inputName="{{ 'party.partyName' | translate }}"
                    class="w-100"
                    [formControl]="editPersonForm.controls['party']"
                    name="party"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputSelectValues]="parties"
                    (click)="getParties()"
                    [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                    (outputOnDelete)="onDeleteParty($event)"
                    (outputOnCreate)="addPartyModal()"
                    (outputOnScroll)="scrollParties()"
                    (outputOnSearch)="filterParties($event)"
                    (outputOnEdit)="editParty($event)"
                    [addExtraHeight]="status"
                    >
                </fap-add-edit-delete-select>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'people.activeFarms' | translate }}</mat-label>
                    <mat-select formControlName="activeFarms" multiple>
                    <mat-form-field appearance="fill" class="w-100">
                        <!-- <mat-label>
                            <i class="fa fa-search f-20"></i>
                        </mat-label> -->
                        <input matInput #farmFilter placeholder="Search">
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let farm of farms | filter : farmFilter.value" [value]="farm.id">{{ farm.name }}</mat-option>
                    </div>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'people.groups.groups' | translate }}</mat-label>
                    <mat-select formControlName="userGroups" multiple>
                        <div class="d-flex" style="align-items: center; padding: 0 10px;">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #userGroupsFilter placeholder="Search"placeholder="Search">
                        </mat-form-field>
                        <i class="fa fa-plus center f-20 ml-2" (click)="addGroupModal()"></i>
                    </div>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let group of groups | filter : userGroupsFilter.value" [value]="group.id">
                            <div class="party-name">
                                {{ group.name }}
                            </div>
                            <div class="party-actions">
                                <i class="fa fa-edit center f-20"
                                    (click)="editGroup(group);$event.stopPropagation()"></i>
                                <i class="fa fa-trash center ml-2 f-20"
                                    (click)="selectedGroup=group;deleteGroupModal.showModal();$event.stopPropagation()">
                                </i>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
            </div>
        </div>
        <!-- <div class="card mt-5">
            <h2 class="card-header-title mr-4">
                User
            </h2>
            <button *ngIf="!userExists && !newUser" class="btn btn-success px-2" (click)="addButtonClick()">
                <i class="material-icons mr-2">group_add</i>
                NEW USER
            </button>
            <button *ngIf="userExists || newUser" class="btn btn-danger px-2" (click)="deleteButtonClick()">
                <i class="material-icons mr-2">delete_outline</i>
                {{ 'people.deleteUser' | translate }}
            </button>
        </div> -->
    </form>
    </div>
    <div class="col-12 p-0">
        <div [ngStyle]="{'background':navService.sidebarBackgroundColor}" class="header">
            <p>New User</p>
            <span class="material-icons" (click)="addButtonClick()" *ngIf="!userExists && !newUser"  [ngStyle]="{'color':navService.sidebarBackgroundColor}">group_add</span>
            <span (click)="deleteButtonClick()" *ngIf="userExists || newUser" class="material-icons">delete_outline</span>
        </div>
    </div>
    <div class="wrp w-100">
    <form [formGroup]="editUserForm" class="col-md-12 p-0">
        <div *ngIf="userExists || newUser">
            <div class="form-grid">
                <div class="form-group">

                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'people.username' | translate }}</mat-label>
                        <input matInput formControlName="username">
                        <mat-error *ngIf="ifEditUserControlHasError('username','required')">
                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">

                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'people.email' | translate }}</mat-label>
                        <input matInput formControlName="email">
                        <mat-error *ngIf="ifEditUserControlHasError('email','required')">
                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">

                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'people.password' | translate }}</mat-label>
                        <input matInput formControlName="password" type='password' autocomplete="new-password">
                        <mat-error *ngIf="ifEditUserControlHasError('password','required')">
                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="ifEditUserControlHasError('password','hasNumber')">
                            <strong>{{ 'auth.validation.passwordHasNumber' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="ifEditUserControlHasError('password','hasCapitalCase')">
                            <strong>{{ 'auth.validation.passwordHasCapitalCase' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="ifEditUserControlHasError('password','hasSmallCase')">
                            <strong>{{ 'auth.validation.passwordHasSmallCase' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="ifEditUserControlHasError('password','hasSpecialCharacters')">
                            <strong>{{ 'auth.validation.passwordHasSpecialCharacters' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="ifEditUserControlHasError('password','minlength')">
                            <strong>{{ 'auth.validation.minLengthField' | translate }} 8</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">

                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'people.confirmPassword' | translate }}</mat-label>
                        <input matInput formControlName="confirmPassword" type='password'>
                        <mat-error *ngIf="ifEditUserControlHasError('confirmPassword','required')">
                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="ifEditUserControlHasError('confirmPassword','confirmPassword')">
                            <strong>{{ 'auth.validation.matchPassword' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">

                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'people.userType' | translate }}</mat-label>
                        <mat-select matNativeControl required formControlName="userType">
                            <mat-option value="10">{{ 'people.readOnly' | translate }} </mat-option>
                            <mat-option value="100">{{ 'people.farmAdmin' | translate }} </mat-option>
                            <mat-option value="1000">{{ 'people.companyAdmin' | translate }} </mat-option>
                        </mat-select>
                        <mat-error *ngIf="ifEditUserControlHasError('userType','required')">
                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 d-flex justify-content-end mt-4 mb-5 p-0">
            <div class="btn btn-success" (click)="submit()">
                <i class="material-icons mr-2">save</i>
                 {{ person ? 'save' : 'create' | translate }}
            </div>
        </div> -->
    </form>
    </div>
    <div class="col-12 0 p-0">
        <fap-attributes
        [entityRelation]="'person'"
        [entityId]="entityInfo?.id"
        [entityInfo]="entityInfo"
        [objectId]="personId"
        (formTypeEmit)="formType = $event"
        (formFieldsEmit)="formFields = $event"
    >
    </fap-attributes>
    </div>
</div>
<fap-modal #addEditPartyModal
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
        (addedParty)="addedParty.emit($event);editPersonForm.controls['party'].setValue($event.id);addEditPartyModal.hideModal()"
        (updatedParty)="updatedParty.emit($event);editPersonForm.controls['party'].setValue($event.id);addEditPartyModal.hideModal()">
    </fap-add-edit-party>
</fap-modal>
<fap-modal #deleteUserModal [inputPrimaryButtonInterface]="deleteUserModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="deleteUserModalButtonSecondaryInterface"
    inputTitle="{{ 'people.deleteUser' | translate }}">
    {{ 'people.deleteUserMessage' | translate}}
</fap-modal>
<fap-modal #deleteGroupModal [inputPrimaryButtonInterface]="deleteGroupModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="deleteGroupModalButtonSecondaryInterface"
    inputTitle="{{ 'people.groups.deleteGroup' | translate }}">
    {{ 'people.groups.deleteGroupMessage' | translate}}
</fap-modal>
<fap-modal #addEditGroupModal [inputTitle]="'people.groups.addEditGroup' | translate">
    <fap-add-edit-group [group]="selectedGroup" (createdGroup)="createdGroup($event)"></fap-add-edit-group>
</fap-modal>
<fap-modal #addEditAddressModal inputTitle="{{ 'party.address.addEditAddressModal' | translate }}" [inputIsLarge]="true">
    <fap-add-edit-address [address]="selectedAddress" [type]="'person'"
        (addedAddress)="addressAdd($event);editPersonForm.controls['addressId'].setValue($event.id);addEditAddressModal.hideModal()"
        (updatedAddress)="addressUpdate($event);editPersonForm.controls['addressId'].setValue($event.id);addEditAddressModal.hideModal()">
    </fap-add-edit-address>
</fap-modal>