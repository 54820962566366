import { NoteModel } from "./../../../../../core/models/notes/note.model";
import { NoteTypeModel } from "./../../../../../core/models/type/note-type.model";
import { TypesService } from "./../../../../../core/services/api/types/types.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NotesService } from "./../../../../../core/services/api/farm/notes.service";
import { LotModel } from "./../../../../../core/models/lot/lot.model";
import { LotService } from "./../../../../../core/services/api/farm/lot.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { tap } from "rxjs/operators";
import { ResponseModel } from "../../../../../core/models/response.model";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subscription } from "rxjs";
import { NavService } from "../../../../../shared/services/nav.service";
import { GlobalRegistryService } from "../../../../../core/global-registry/global-registry.service";
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FarmModel } from "../../../../../core/models/farm/farm.model";

@Component({
  templateUrl: "./notes-edit-container.component.html",
})
export class NotesEditContainerComponent implements OnInit, OnDestroy {
  public lots: Array<LotModel>;
  public types: Array<NoteTypeModel>;
  public farms: Array<FarmModel>;
  public notes: Array<NoteModel>;
  public noteId: number;
  public note: NoteModel;
  public farmId: number;
  public subscriptions = new Subscription();

  constructor(
    public lotService: LotService,
    public noteTypeService: TypesService,
    public noteService: NotesService,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public navService: NavService,
    public router: Router,
    public globalRegistry: GlobalRegistryService,
    private toastyService: ToastrService,
    private mapService:MapService
  ) {
    this.subscriptions.add(
      combineLatest([
        activatedRoute.params,
      ]).subscribe(([params]: [Params]) => {
        this.lots = this.globalRegistry.systemData.lots;
        this.farms = this.globalRegistry.systemData.farms;
        this.navService.createMod.next(true);
        this.getTypes();
        if (params["noteId"] !== undefined) {
          this.noteId = Number(params["noteId"]);
          this.navService.createMod.next(false);
          this.navService.editMod.next(true);
          this.getNote(this.noteId);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.navService.editFarm.next(false);
  }

  ngOnInit() {
    if (window.innerWidth >= 767) {
      this.mapService.showMap();
  }
  this.mapService.resetMap();
    this.navService.editFarm.next(true);
    this.navService.submitFarm.next(true);
    this.navService.invalidForm.next(true);
    this.subscriptions.add(
      this.noteService.getNotesList.subscribe(() => {
        this.getTypes();
      })
    );

    this.subscriptions.add(
      this.navService.emitCancel.subscribe((value: boolean) => {
        if (value) {
          this.router.navigate(["/pages/notes"]);
          this.navService.emitCancel.next(false);
          this.noteService
        }
      })
    );
  }

  public submitNote(note: FormData): void {
    this.noteService.createNote(note).subscribe(
      () => {
        this.noteService.getNotesList.next();
        this.globalRegistry.addLocalRelationsToService.next(this.noteId);
        this.toastyService.success(
          this.translate.instant("notes.messages.createSuccess")
        );
        this.getNotes();
      },
      () => {
        this.toastyService.error(
          this.translate.instant("notes.messages.failCreate")
        );
      }
    );
  }

  public updateNote(note): void {
    console.log(note);
    this.noteService
      .updateNote(Number(note.id), note)
      .pipe(
        tap(
          () => {
            this.getNotes();
            this.noteService.getNotesList.next();
            this.globalRegistry.addLocalRelationsToService.next(this.noteId);
            this.toastyService.success(
              this.translate.instant("notes.messages.updateSuccess")
            );
          },
          () =>
            this.toastyService.error(
              this.translate.instant("notes.messages.failUpdate")
            )
        )
      )
      .subscribe();
  }

  public deleteNote(id): void {
    console.log(id);
    this.noteService
      .deleteNote(Number(id))
      .pipe(
        tap(
          () => {
            this.getNotes();
            this.noteService.getNotesList.next();
            this.globalRegistry.addLocalRelationsToService.next(this.noteId);
            this.toastyService.success(this.translate.instant("notes.messages.deleteSuccess"));
          },
          () =>
            this.toastyService.error(
              this.translate.instant("notes.messages.failUpdate")
            )
        )
      )
      .subscribe();
  }

  private getNotes(): void {
      this.noteService.getNotes().subscribe((response: ResponseModel<NoteModel[]>) => {
          this.notes = response.model;
      });
  }

  private getTypes(): void {
    this.noteTypeService
      .getNoteTypes()
      .subscribe((response: ResponseModel<NoteTypeModel[]>) => {
        this.types = response.model;
      });
  }

  private getNote(id: number): void {
    this.noteService
      .getNote(id)
      .subscribe((response: ResponseModel<NoteModel>) => {
        this.note = response.model;
      });
  }
}
