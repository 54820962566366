import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    ViewChild
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../../../../core/services/api/auth/auth.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { LoginBodyInterface } from '../../../../../core/services/api/auth/data/login-body.interface';
import { ResponseModel } from '../../../../../core/models/response.model';
import { TokenModel } from '../../../../../core/models/auth/token.model';
import { environment } from '../../../../../../environments/environment';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { FapModalComponent } from '../../../../../shared/partials';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { Menu, NavService } from '../../../../../shared/services/nav.service';


@Component({
    selector: 'fap-login',
    templateUrl: './fap_login.component.html',
    styleUrls: ['./fap_login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FapLoginComponent implements OnInit, OnDestroy {

    public newUser = false;
    public loginForm: UntypedFormGroup;
    public errorMessage: any;

    public data$: Observable<Data>;

    private unsubscribe: Subject<any>;
    private subscription: Subscription;
    public emailForm: UntypedFormGroup;
    public show = false;
    public versionNumber:string = environment.version;
    public changePasswordModalButtonPrimaryInterface: FapModalButtonInterface;
    public changePasswordModalButtonSecondaryInterface: FapModalButtonInterface;
    @ViewChild('changePasswordModal')
    public changePasswordModal: FapModalComponent;
    public mediaUrl = environment.mediaUrl+'settings/login_background.jpg';

    constructor(private fb: UntypedFormBuilder,
                private authService: AuthService,
                public router: Router,
                private globalRegistry: GlobalRegistryService,
                protected toastrService: ToastrService,
                private route: ActivatedRoute,
                public translate: TranslateService, public userService: UserService, public navServices: NavService) {
        this.unsubscribe = new Subject();
        translate.setDefaultLang(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');

        this.loginForm = fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    public ngOnInit(): void {
        this.data$ = this.route.data.pipe(map((data: Data) => {
            return {
                mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
            };
        }));
        this.initEmailForm();
    }

    public initchangePasswordModalButtons(): void {
        const _this: FapLoginComponent = this;

        this.changePasswordModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.emailSubmit();
            },
            text: this.translate.instant('submit'),
        };

        this.changePasswordModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.changePasswordModal.hideModal();
            },
            text: this.translate.instant('cancel'),
        };
    }

    public ngOnDestroy(): void {
        if(this.subscription) {
        this.subscription.unsubscribe();
        }
         this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    togglePassword() {
        this.show = !this.show;
    }

    public login(loginData: LoginBodyInterface): void {
        this.subscription = this.authService.login(loginData).subscribe(
            (response: ResponseModel<TokenModel> ) => {
                const msg = this.translate.instant('auth.login.loginSuccess')
                this.toastrService.success(msg);
                localStorage.setItem('auth_token', response.model.token);
                localStorage.setItem("access",JSON.stringify({lots:[1,0,0],people:[1,1,0],equipments:[0,0,0],notes:[0,0,0]}));
                // this.router.navigate(['pages']);
                this.getUserInfo();
                const userLang = navigator.language;
                if(!localStorage.getItem('language')) {
                    if(userLang.slice(0, 2) == 'en' || userLang.slice(0, 2) == 'fr' || userLang.slice(0, 2) == 'ro' || userLang.slice(0, 2) == 'ru') {
                        this.translate.setDefaultLang(userLang.slice(0, 2));
                        this.translate.use(userLang.slice(0, 2));
                        localStorage.setItem('language', userLang.slice(0, 2))
                    } else {
                        this.translate.setDefaultLang('en');
                        this.translate.use('en');
                        localStorage.setItem('language', 'en')
                    }
                }
            },
            () => {
                this.toastrService.error(this.translate.instant('auth.login.loginFailed'));
            }
        );
        // this.authService.login(loginData).pipe(
        //     tap((response: ResponseModel<TokenModel>) => {
        //         if (response) {
        //             // this.globalRegistry.initUserData(response.model.user);
        //             // localStorage.setItem('auth_token', response.model.token);
        //             // localStorage.setItem('user', response.model.user.id.toString());
        //             // this.router.navigate(['pages']);
        //             this.toastrService.success('Ati fost logat cu success');
        //         }
        //     }),
        //     catchError((error: HttpErrorResponse) => {
        //         console.error(error);
        //         this.toastrService.error('A aparut o eroare la logare');
        //         return throwError(error);
        //     }),
        //     takeUntil(this.unsubscribe),
        //     finalize(() => {

        //     })
        // ).subscribe();
    }

    public getUserInfo() {
        this.userService.getUser().subscribe(response => {
            const landing = response.body.results.profile.data.landing;
            if(landing) {
                this.navServices.items.subscribe((menuItems: Menu[]) => {
                    const result = menuItems.find(obj => obj.key === landing);
                    this.router.navigate([result.path]);
                })
            } else {
                this.router.navigate(['pages']);
            }
        })
    }

    public getTranslationsByKey(item: string): string {
        return this.translate.instant(item);
    }

    initEmailForm() {
        this.emailForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    emailSubmit() {
        if(this.emailForm.get('email').value == '') {
            return;
        }
        console.log(this.emailForm.value);
        this.authService.changePassword(this.emailForm.value).subscribe(() => {
            // console.log(data.body.results.token);
            this.toastrService.success(this.translate.instant('auth.register.mailSent'));
            this.changePasswordModal.hideModal();
        });
    }

}
