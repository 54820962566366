import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { DeviceModel, ObjectService } from '../../../../../core/services/api/objects/object.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { DataService } from '../../../../../core/services/websocket/data.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
  selector: 'scheduler-card',
  templateUrl: './scheduler-card.component.html',
  styleUrls: ['./scheduler-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerCardComponent implements OnInit, OnDestroy, OnChanges {

  public lots: Array<LotModel>;
  @Input()
  public sensor;
  @Input ()
  public devices: Array<DeviceModel>;
  @Output()
  public deleteNote: EventEmitter<number> = new EventEmitter();

OpenState = false;
    @ViewChild(MatAccordion) accordion: MatAccordion;
    public lotGroup: any = [];
    
    public farms: Array<FarmModel>;
    public groupForm: UntypedFormGroup;
    public schedulerGroups: any = [];
    public sensors:SensorModel;
    public minDate = new Date();
    public eyeData;
    public isEdit: boolean;
    public device: DeviceModel;
    public lot:LotModel;
    public farm:FarmModel;
    public subscriptions: Array<Subscription> = [];
    constructor(
        public mapService: MapService,
        public activatedRoute: ActivatedRoute,
        public globalRegistry: GlobalRegistryService,
        public router: Router,
        public sensorService: SensorService,
        public dataService: DataService,
        public toastr: ToastrService,
        public objectService: ObjectService,
        public navService: NavService
    ) {
        this.mapService.showMap();
        this.mapService.centerMapOnUserLocation();
        this.farms = this.globalRegistry.systemData.farms;
        this.lots = this.globalRegistry.systemData.lots;
        console.log(this.farms);
        console.log(this.lots);
        const result = this.lots.map((item) => {
            return {
                ...item,
                farms: this.farms.filter((el) => el.id === item.farm),
            };
        });

        const res = Array.from(
            result
                .reduce((acc, promotion) => {
                    acc.set(
                        promotion.farm,
                        (acc.get(promotion.farm) || []).concat(promotion)
                    );
                    return acc;
                }, new Map())
                .entries(),
            ([farm, data]) => ({ farm, data })
        );
        this.lotGroup = res;
        console.log(this.lotGroup);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes)
        if(Object.prototype.hasOwnProperty.call(changes, 'devices')) {
            
            this.getDevices();
        }
    }

    openAllFirst() {
        this.accordion.openAll();
    }

    ngOnInit(): void {
        if (this.accordion) {
            this.openAllFirst();
        }
        this.initForm(null);
    }

    getDevices() {
        const device = this.devices.filter(device => {
          return device.id === this.sensor.device
        });
        this.device = device[0];
        // console.log(this.device);
        this.getLot(this.device.lots[0]);
    }

    public getLot(lotId:number) {
      const lot = this.globalRegistry.systemData.lots.filter(lot => {
        return lot.id === lotId
      });
      this.lot = lot[0];
      this.getFarm(this.lot.farm)
    }

    public getFarm(farmId:number) {
      const farm = this.globalRegistry.systemData.farms.filter(farm => {
        return farm.id === farmId
      });
      this.farm = farm[0];
    }

    public getLotName(lotId) {
      return this.globalRegistry.systemData.lots.find(
          (lot) => lot.id === lotId
      ).name;
  }

    showModal(sensorId) {
      this.isEdit = false;
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.add('active');
        parent.classList.add('backdrop');
        this.initForm(sensorId);
    }

    showUpdateModal(sensorId) {
      this.isEdit = true;
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.add('active');
        parent.classList.add('backdrop');
        this.initForm(sensorId);
    }

    hideModal() {
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.remove('active');
        parent.classList.remove('backdrop');
        this.clearPosParam();
    }

    getNotification(sensorId) {
        const obj = {
            cmd: 600,
            sensor: sensorId,
            real: false,
            params: { state: true },
        };
        this.subscriptions.push(this.sensorService.postAcquisition(obj).subscribe((data) => {
            console.log(data);
        }));
    }

    public getFarmName(farmid) {
        let farm;
        this.farms.forEach((element) => {
            if (element.id == farmid) {
                farm = element;
            }
        });
        return farm.name;
    }

    public initForm(sensorId) {
        this.groupForm = new UntypedFormGroup({
            sensor: new UntypedFormControl(sensorId, Validators.required),
            start: new UntypedFormControl(null, Validators.required),
            end: new UntypedFormControl('', Validators.required),
        });
    }

    clearPosParam() {
        this.router.navigate(['.'], {
            relativeTo: this.activatedRoute,
            queryParams: { action: null },
        });
    }

    public saveAction() {
      console.log(this.groupForm.value);
      return;
        const formData = {
            sensor: this.groupForm.get('sensor').value,
            start: this.groupForm.get('start').value.format(),
            end: this.groupForm.get('end').value.format(),
        };
        console.log(formData);
        this.sensorService.postSensorActions(formData).subscribe((data) => {
            console.log(data.results);
            if (data.results) {
                // this.getSensors();
            }
        });
        this.groupForm.reset();
        Object.keys(this.groupForm.controls).forEach((key) => {
            this.groupForm.get(key).setErrors(null);
        });
        this.hideModal();
    }

    public editAction(action) {
        console.log(action);
        this.showUpdateModal(action.sensor);
        const start = new Date(action.start).toISOString();
        const end = new Date(action.end).toISOString();
        this.groupForm.get('start').patchValue(start);
        this.groupForm.get('end').patchValue(end);
    }

    public updateAction() {
      console.log(this.groupForm.value);
        const formData = {
            pk: 1,
            sensor: this.groupForm.get('sensor').value,
            start: this.groupForm.get('start').value.format(),
            end: this.groupForm.get('end').value.format(),
        };
        console.log(formData);
        this.sensorService.postSensorActions(formData).subscribe((data) => {
            console.log(data.results);
            if (data.results) {
                // this.getSensors();
            }
        });
        this.groupForm.reset();
        Object.keys(this.groupForm.controls).forEach((key) => {
            this.groupForm.get(key).setErrors(null);
        });
        this.hideModal();
        this.isEdit = false;
    }

    public deleteAction(actionId) {
        console.log(actionId);
    }

    public editSensor(sensor) {
      console.log(sensor);
      this.router.navigate(['pages/scheduler/edit/'+sensor.id]);
      this.navService.createMod.next(true);
    }

    public ngOnDestroy(): void {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }

}
