<div class="dashboard-soil-card dash_cont">
  <div class="row">
    <div class="col-md-4">
      <h1>{{ widget.widgetType | translate }}</h1>
    </div>
    <div class="col-md-7 col_lot_name">
      <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
    </div>
    <div class="col-md-2 text-right p-0">
      <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
      <span class="conf_icon" (click)="toggleMenu()">
        <i></i>
        <i></i>
        <i></i>
      </span>
      <div class="new_menu {{toggleMain}}">
        <ul (click)="hideMain()">
          <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
        </ul>
      </div>
    </div>
  </div>
  <table class="w-100">
    <tbody>
      <tr>
        <td class="bg-brown1"><div class="percent-value">5</div></td>
        <th scope="col">{{ levelValues["level5"]["humidity"] | number:'1.'+globalRegistry.systemData.company.digits }} %</th>
        <td>
          <p class="mb-0">{{ levelValues["level5"]["temperature"] | number:'1.'+globalRegistry.systemData.company.digits}} °C</p>
          <p class="mb-0">N: {{ levelValues["level5"]["N"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">PH: {{ levelValues["level5"]["ph"] }}</p>
          <p class="mb-0">P: {{ levelValues["level5"]["P"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">D: {{ levelValues["level5"]["ec"] }}</p>
          <p class="mb-0">K: {{ levelValues["level5"]["K"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
      </tr>
      <tr>
        <td class="bg-brown2"><div class="percent-value">10</div></td>
        <th scope="col">{{ levelValues["level10"]["humidity"] | number:'1.'+globalRegistry.systemData.company.digits}} %</th>
        <td>
          <p class="mb-0">{{ levelValues["level10"]["temperature"] | number:'1.'+globalRegistry.systemData.company.digits}} °C</p>
          <p class="mb-0">N: {{ levelValues["level10"]["N"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">PH: {{ levelValues["level10"]["ph"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
          <p class="mb-0">P: {{ levelValues["level10"]["P"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">D: {{ levelValues["level10"]["ec"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
          <p class="mb-0">K: {{ levelValues["level10"]["K"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
      </tr>
      <tr>
        <td class="bg-brown3"><div class="percent-value">15</div></td>
        <th scope="col">{{ levelValues["level15"]["humidity"] | number:'1.'+globalRegistry.systemData.company.digits}} %</th>
        <td>
          <p class="mb-0">{{ levelValues["level15"]["temperature"] | number:'1.'+globalRegistry.systemData.company.digits}} °C</p>
          <p class="mb-0">N: {{ levelValues["level15"]["N"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">PH: {{ levelValues["level15"]["ph"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
          <p class="mb-0">P: {{ levelValues["level15"]["P"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">D: {{ levelValues["level15"]["ec"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
          <p class="mb-0">K: {{ levelValues["level15"]["K"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
      </tr>
      <tr>
        <td class="bg-brown4"><div class="percent-value">30</div></td>
        <th scope="col">{{ levelValues["level30"]["humidity"] | number:'1.'+globalRegistry.systemData.company.digits}} %</th>
        <td>
          <p class="mb-0">{{ levelValues["level30"]["temperature"] | number:'1.'+globalRegistry.systemData.company.digits}} °C</p>
          <p class="mb-0">N: {{ levelValues["level30"]["N"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">PH: {{ levelValues["level30"]["ph"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
          <p class="mb-0">P: {{ levelValues["level30"]["P"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
        <td>
          <p class="mb-0">D: {{ levelValues["level30"]["ec"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
          <p class="mb-0">K: {{ levelValues["level30"]["K"] | number:'1.'+globalRegistry.systemData.company.digits}}</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
