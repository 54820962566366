<ng-container *ngIf="device">
    <div
        class="bgrow"
        style="
            position: relative;
            width: 100%;
            background: #e0e0e0;
            min-height: calc(100vh - 50px);
            z-index: 0;
        "
    >
        <ngx-masonry [options]="options" [ordered]="false">
            <div
                ngxMasonryItem
                *ngFor="let widget of widgets"
                class="example-box  {{ widget.widgetType }} {{
                    widget.sizeSmall
                }} {{ widget.sizeMedium }} {{ widget.sizeLarge }} {{
                    widget.bgColor
                }}"
                style="margin: 5px 2.5px"
            >
                <fap-table-widget
                    *ngIf="widget.widgetType === 'table'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [bgColor]="widget.bgColor"
                >
                </fap-table-widget>
                <fap-crop-eye-simple-widget
                    *ngIf="widget.widgetType === 'cropeyesimple'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%; overflow: hidden"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                >
                </fap-crop-eye-simple-widget>
                <fap-water-content-widget
                    *ngIf="widget.widgetType === '*lux5GMLWat'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                >
                </fap-water-content-widget>
                <fap-satellite-widget
                    *ngIf="widget.widgetType === '*lux5GSat'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                >
                </fap-satellite-widget>

                <fap-image-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'image'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    style="display: block; width: 100%"
                ></fap-image-widget>

                <fap-overview-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'overview'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    style="display: block; width: 100%"
                ></fap-overview-widget>

                <fap-soil-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'soil'"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [widget]="widget"
                    style="display: block; width: 100%"
                >
                </fap-soil-widget>

                <fap-image-slider-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'cropeye'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    style="display: block; width: 100%; overflow: hidden"
                ></fap-image-slider-widget>
                <!-- <fap-crop-eye-widget *ngIf="widget.widgetType === 'cropeye'" class="mb-5" [widget]="widget"></fap-crop-eye-widget> -->
                <fap-alert-widget
                    (emitShow)="showError()"
                    *ngIf="
                        widget.widgetType === 'fungal_alert' ||
                        widget.widgetType === 'pest_alert' ||
                        widget.widgetType === 'pathogen_alert'
                    "
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [crops]="crops"
                ></fap-alert-widget>

                <fap-weed-control-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'herbicide'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [crops]="crops"
                >
                </fap-weed-control-widget>

                <fap-chart-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'graph'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></fap-chart-widget>

                <graph-range-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'graph_range'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></graph-range-widget>

                <graph-percentage-widget
                    (emitShow)="showError()"
                    *ngIf="widget.widgetType === 'graph_percentage'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></graph-percentage-widget>

                <fap-carousel-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'images_list'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></fap-carousel-widget>

                <fap-bin-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'bins'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [sensors]="sensors"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></fap-bin-widget>

                <fap-sensor-value-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'sensor_value'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    style="display: block; width: 100%"
                ></fap-sensor-value-widget>

                <fap-overview-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'overview'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [devices]="devices"
                    style="display: block; width: 100%"
                ></fap-overview-widget>

                <fap-activities-list-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'activities'"
                    [widget]="widget"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [activities]="activities"
                    [activityTypes]="activityTypes"
                    [devices]="devices"
                    (emitScroll)="scrollActivities()"
                    style="display: block; width: 100%"
                ></fap-activities-list-widget>

                <fap-pool-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'pool'"
                    [widget]="widget"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [editValue]="false"
                    [devices]="devices"
                    style="display: block; width: 100%"
                ></fap-pool-widget>

                <fap-weather-forecast-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'weather_forecast'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [sensors]="sensors"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></fap-weather-forecast-widget>

                <fap-map-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'map'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [sensors]="sensors"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></fap-map-widget>

                <fap-device-info-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget.widgetType === 'device_info'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                ></fap-device-info-widget>
            </div>
        </ngx-masonry>
    </div>
</ng-container>
