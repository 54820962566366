<div
  class="container-fluid ter-container nc"
  #notesContainer
  infiniteScroll
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="500"
  (scrolled)="scrolledDown()"
  [scrollWindow]="false"
  (scroll)="onDivScroll($event)"
  style="flex: auto;"
>
  <fap-notes-layout
    [farms]="filteredFarms"
    [persons]="globalRegistry?.systemData?.persons"
    [notes]="filteredNotes"
    [farmForecastMapping]="farmForecastMapping"
    (addNote)="addNote($event)"
    (deleteNote)="deleteNote($event)"
  >
  </fap-notes-layout>
</div>
