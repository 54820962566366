import { FapBaseModel } from '../../base/fap-base-model';
export class ActivityModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'started',
        'ended',
        'name',
        'info',
        'working_time',
        'group',
        'created_by',
        'activity_type',
        'assigned_to',
        'attached_equipments',
        'lots',
        'phaseId',
        'resources_doc',
        'distance',
        'area',
        'cost',
        'data',
        'name_t',
        'completed'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id?: number;
    public started: string;
    public ended: string;
    public name: string;
    public info: string;
    public workingTime: string;
    public group: number;
    public createdBy: number;
    public activityType: number;
    public assignedTo: number;
    public attachedEquipments: any[];
    public lots: number[];
    public phaseId: number;
    public resourcesDoc: any;
    public distance: any;
    public area: any;
    public cost: number;
    public data: any;
    public trackId: any;
    public nameT: string;
    public completed?: boolean;
    
}
