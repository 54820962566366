import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { WidgetsService } from 'src/app/core/services/api/widgets/widgets.service';
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../../../core/models/widget/widget.model';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { DeviceModel, ObjectModel, ObjectService } from '../../../../../core/services/api/objects/object.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { UnitTypeModel, UnitTypeService } from '../../../../../core/services/api/unit-type/unit-type.service';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';

@Component({
  selector: 'device-view',
  templateUrl: './device-view.component.html',
  styleUrls: ['./device-view.component.scss']
})
export class DeviceViewComponent implements OnInit, OnDestroy {

  public deviceId: any;
  public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  public device: any;
  public widgets:WidgetModel[] = [];
  public filteredWidgets:WidgetModel[] = [];
  public filterFarmIds: Array<number> = [];
  public filterLotIds: Array<number> = [];
  public sensors: Array<SensorModel> = [];
  public subscriptions: Array<Subscription> = [];
  public objects: Array<ObjectModel> = [];
  public unitTypes: Array<UnitTypeModel> = [];
  public devices: Array<DeviceModel> = [];
  public activities: Array<ActivityModel> = [];
  public activityTypes: Array<ActivityTypeModel> = [];

  constructor(public mapService: MapService, public widgetsService: WidgetsService, public route: ActivatedRoute, public deviceService: ObjectService, public globalRegistry: GlobalRegistryService, public toastr: ToastrService, public sensorService: SensorService, public ObjectService: ObjectService, public unitTypeService: UnitTypeService, public cacheService: CacheResolverService, public activityService: ActivityService, public typeService: TypesService) { }

  ngOnDestroy(): void {
    this.widgetsService.setSize(6);
  }

  ngOnInit(): void {
    this.widgetsService.setSize(12);
    this.deviceId = this.route.snapshot.params.deviceId;
    if(this.deviceId) {
      this.deviceService.getDevice(this.deviceId).subscribe(data => {
        this.device = data.body.results;
        if(this.device.coords && this.device.coords.last_value) {
          const markerInterface = {
            lat: this.device.coords.last_value.value_coord.coordinates[0],
            lng: this.device.coords.last_value.value_coord.coordinates[1],
            iconUrl: this.mapIconUrl
          }
          this.mapService.mapMarkers.push(markerInterface);
          this.mapService.centerMapOnMarkers();
        }
      })
    }
    this.mapService.resetMap();
    this.mapService.hideMap();
    this.getDeviceView(this.deviceId);
    this.getActivities();
    this.getActivityTypes();
    this.subscriptions.push(
      combineLatest([
        this.ObjectService.getObjectTypes(),
        this.unitTypeService.getObjectTypes(),
        this.sensorService.getSensors(),
        this.ObjectService.getDevices()
      ]).subscribe(
        ([objectTypes, unitTypes, sensors, devices]: [
          ResponseModel<ObjectModel[]>,
          ResponseModel<UnitTypeModel[]>,
          ResponseModel<SensorModel[]>,
          ResponseModel<DeviceModel[]>,
        ]): void => {
          this.objects = objectTypes.model;
          this.unitTypes = unitTypes.model;
          this.sensors = sensors.model;
          this.devices = devices.model;
        }))
  }

  public getDeviceView(deviceId) {
    this.widgetsService.setDrill(true);
    const param = {device: deviceId}
    const url = this.widgetsService.getUrl('widgets/device='+deviceId);
    this.cacheService.delete(url);
    this.widgetsService.getWidgetView(param).subscribe((data: ResponseModel<WidgetModel[]>) => {
      console.log(data);
      this.widgets = data.model;
      this.filterWidgets();
    })
  }

  public getActivityTypes() {
    this.typeService.getActivityTypes().subscribe(data => {
      this.activityTypes = data.model;
    })
  }

  public getActivities() {
    this.activityService.getActivities({limit: 20}).subscribe(data => {
      this.activities = data.body.results;
    })
  }

  public showError() {
    this.toastr.error('Cannot edit widget in this view!');
    return;
  }

  private filterWidgets(): void {
    const dict = ['bgColor', 'sizeLarge', 'sizeMedium', 'sizeSmall', 'name', 'settings', 'nameT', 'view', 'fgColor', 'widgetType'];
      this.widgets = this.widgets.map(obj => { 
      obj.config={};
      for (const key of dict) {
         obj.config[key] = obj[key];
      }
        return obj;
      });
        this.filteredWidgets = this.widgets.filter(
      (widget: WidgetModel): boolean => {
        if (this.filterLotIds.length > 0) {
          return (
            widget.contentType === "lot" &&
            this.filterLotIds.includes(widget.objectId)
          );
        }
        if (this.filterFarmIds.length > 0) {
          if (widget.contentType === "lot") {
            const matchingLot: LotModel =
              this.globalRegistry.systemData.lots.find(
                (lot: LotModel): boolean => lot.id === widget.objectId
              );
            return this.filterFarmIds.includes(matchingLot.farm);
          }
          if (widget.contentType === "farm") {
            return this.filterFarmIds.includes(widget.objectId);
          }
          return false; // reached this line, filters applied, content type other than lot or farm => filter out
        }
        return true; // no filters to apply, show everything
      }
    );
    console.log(this.filteredWidgets);
  }

}
