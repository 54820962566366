import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { AlergotelService } from '../../../../core/services/api/alergotel/alergotel.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { UserService } from '../../../../core/services/api/user/user.service';

@Component({
    templateUrl: './particles.component.html',
    styleUrls: ['./particles.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('in', style({ transform: 'translateX(0)' })),
            transition('void => *', [
                style({ transform: 'translateX(-100%)' }),
                animate(1000),
            ]),
            transition('* => void', [
                animate(1000, style({ transform: 'translateX(100%)' })),
            ]),
        ]),
    ],
})
export class ParticlesComponent implements OnInit {
  @ViewChild('loadedImage', { static: false }) loadedImageRef: ElementRef;
    public activeSlide = null;
    public counter = 0;
    public index: number;
    public size = '';
    public innerWidth: any;
    public langString: string;
    public subscriptions: Array<Subscription> = [];
    public items = [];
    public mediaUrl = environment.mediaUrl;
    public translatedNames: any = [];
    public currentIndex = 0;
    public limit = 100;
    public currentUser;
    public getMore = true;
    public nextToken: { limit: number; offset: number; search: string } = null;
    isPortrait: boolean;
    isLandscape: boolean;
    public isLoading = false;

    constructor(
        public alergotelService: AlergotelService,
        public globalRegistry: GlobalRegistryService,
        public navService: NavService,
        public router: Router,
        public typesService: TypesService,
        public cacheService: CacheResolverService,
        public userService: UserService
    ) {
    //   this.userService.getUser().subscribe(response => {
    //     this.currentUser = response.body.results;
    //     this.userService.setCurrentUser(this.currentUser);
    //     this.userService.setTheme(this.currentUser.profile.data.ui.theme);
    // });
    }

    getTranslations() {
        this.subscriptions.push(
            this.typesService.getTranslations().subscribe((data) => {
                this.translatedNames = data.model;
                return;
            })
        );
    }

    ngOnInit() {
      this.isLoading = true;
        this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.alergotelService.getBioParticles({ limit: this.limit }).subscribe(
            (data) => {
              this.isLoading = false;
                this.items = data.body.results;
                console.log(this.items);
                this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
                if(this.nextToken) this.getMore = true;
            },
            (err) => {
                console.log(err.error);
                return;
            }
        );
    }

    public get width() {
        return window.innerWidth;
    }

    showNextSlide() {
      if(this.currentIndex >= this.items.length - 1) {
        this.getNextParticles();
        return
      } else {
        this.currentIndex += 1;
        const total = this.items.length - 1;
        this.counter = this.inc(total);
      }
    }

    showPreviousSlide() {
      this.currentIndex -= 1;
      if(this.currentIndex < 0) {
        this.currentIndex = 0;
        return
      }
        const total = this.items.length - 1;
        this.counter = this.dec(total);
    }

    inc(total) {
        return this.counter < total ? this.counter + 1 : 0;
    }

    dec(total) {
        return this.counter > 0 ? this.counter - 1 : total;
    }

    getTranslation(translation) {
        const t = this.translatedNames.filter((trans) => {
            return trans.id === translation;
        });
        if (t[0]) {
            return t[0][this.langString];
        } else {
            return translation;
        }
    }

    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }

    getNextParticles() {
      const url = this.alergotelService.getUrl('');
    if(this.getMore) {
    this.nextToken &&
      this.alergotelService.getBioParticles(this.nextToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.items) {
          this.items = [...this.items, ...data.body.results];
        } else {
          this.items = data.body.results;
        }
        // this.filterdItems = this.items;
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const total = this.items.length - 1;
          this.counter = this.inc(total);
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search : params['search'] ? params['search'] : ''};
          } else {
            return
          }
        }
      });
    } else {
      return
    }
    }
}
