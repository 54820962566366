import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { ResponseModel } from '../../../models/response.model';
import { take } from 'rxjs/operators';
import { AttributeRelationModel } from '../../../models/attribute/attribute-relation.model';
import { AttributeRelationInterface } from '../../../interfaces/attribute/attribute-relation.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { AttributeRelationEnum } from '../../../enums/attribute/attribute-relation.enum';
import { AttributeUnitsEnum } from '../../../enums/attribute/attribute-units.enum';
import { CacheResolverService } from '../cache/cache-resolver.service';


@Injectable()
export class AttributeService extends FapRestBaseService {
    public attributeRelations: Subject<void> = new Subject<void>();
    public unitTypes: {}[] = [
        {
            id: AttributeUnitsEnum.Kilograms,
            name: 'kg'
        },
        {
            id: AttributeUnitsEnum.Kilometers,
            name: 'km'
        },
        {
            id: AttributeUnitsEnum.Meters,
            name: 'm'
        }
    ];
    public attributeValueTypes: {}[] = [
        {
            id: AttributeRelationEnum.String,
            name: 'attribute.string'
        },
        {
            id: AttributeRelationEnum.Datetime,
            name: 'attribute.datetime'
        },
        {
            id: AttributeRelationEnum.DateRange,
            name: 'attribute.dateRange'
        },
        {
            id: AttributeRelationEnum.Number,
            name: 'attribute.number'
        },
        {
            id: AttributeRelationEnum.MinMaxRange,
            name: 'attribute.minMaxRange'
        },
        {
            id: AttributeRelationEnum.NumberList,
            name: 'attribute.numberList'
        },
        {
            id: AttributeRelationEnum.StringList,
            name: 'attribute.stringList'
        },
        {
            id: AttributeRelationEnum.Entity,
            name: 'attribute.entity'
        },
        {
            id: AttributeRelationEnum.EntityList,
            name: 'attribute.entityList'
        }
    ];
    constructor(tosterService: ToastrService,
                http: HttpClient, public cacheService: CacheResolverService) {
        super(tosterService, http, '/attribute/');
    }

    

    public getLocalAttributesRelations():any[]{
        return localStorage.getItem(
            "fap-attribute-relations"
          )
            ? JSON.parse(localStorage.getItem("fap-attribute-relations"))
            : [];
    }

    getUrl(slug) {
        return this.url+slug
    }
    

    public getAttributeRelations(params?: {}): Observable<ResponseModel<AttributeRelationModel[]>> {
        params = params || {};
        return this.mapRequest<AttributeRelationModel[]>(
            this.http.get(this.url + 'attribute_relations/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            AttributeRelationModel,
            true
        ).pipe(take(1));
    }

    public patchField(fieldId: number, value?:{}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'field/' + fieldId + '/',
                this.camelCaseModelToSnakeCaseJson(value),
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public getAttributeForm(params?: {}): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.get(this.url + 'form/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

    public getForm(id: number): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this.http.get(this.url + 'form/' + id + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            false,
            false
        ).pipe(take(1));
    }

    public updateForm(formId, body): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'form/' + formId + '/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public createAttributeRelation(attributeRelation: AttributeRelationInterface): Observable<ResponseModel<AttributeRelationModel>> {
        this.cacheService.delete(this.url + 'attribute_relations/');
        return this.mapRequest<AttributeRelationModel>(
            this.http.post(this.url + 'attribute_relations/',
                this.camelCaseModelToSnakeCaseJson(attributeRelation),
                FapAPIRequestOptions.withTokenRequestOptions),
            AttributeRelationModel,
            false
        ).pipe(take(1));
    }

    public deleteAttributeRelation(attributeRelationId: number): Observable<{}> {
        return this.mapRequest(
            this.http.delete(this.url + 'attribute_relations/' + attributeRelationId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)).pipe(take(1));
    }

    public updateAttributeRelation(attributeRelationId: number, attributeRelation: AttributeRelationInterface):
        Observable<ResponseModel<AttributeRelationModel>> {
            this.cacheService.delete(this.url + 'attribute_relations/');
        return this.mapRequest<AttributeRelationModel>(
            this.http.patch(this.url + 'attribute_relations/' + attributeRelationId + '/',
                this.camelCaseModelToSnakeCaseJson(attributeRelation),
                FapAPIRequestOptions.withTokenRequestOptions),
            AttributeRelationModel,
            false
        ).pipe(take(1));
    }

    public getRelationInfo(attr: AttributeRelationModel): string {
        return attr.info;
    }

    public getRelationValue(attr: AttributeRelationModel): any {
        switch (attr.valueType) {
            case 'min-max': {
                return 'min ' + attr.value['min'] + ' - ' + 'max ' + attr.value['max'];
            }
            case 'list-number': {
                const list = [];
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                attr.value['list'] && attr.value['list'].length && attr.value['list'].map((item: number) => {
                    list.push(' ' + item + ' ');
                });
                return list;
            }
            case 'list-string': {
                const list = [];
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                attr.value['list'] && attr.value['list'].length && attr.value['list'].map((item: number) => {
                    list.push(' ' + item + ' ');
                });
                return list;
            }
                case 'entity': {
                    return attr.value['entity'] + ' ' + attr.value['entityType'];
                }
            default : {
                return attr.value['value'] + ' ' + (attr.unit ? attr.unit : '');
            }
        }
    }

    public getWorkflow(params?: {}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any[]>(
            this.http.get(this.url + 'workflow/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false,
            false
        ).pipe(take(1));
    }

    public postWorkflow(params?: {}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this.http.post(this.url + 'workflow/',
                params,
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        ).pipe(take(1));
    }

    public getNextForm(formId): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'workflow/' + formId + '/next/',
                {},
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public getPreviousForm(formId): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'workflow/' + formId + '/prev/',
                {},
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

}
