<div class="cover" id="parent90">
    <div class="reports_table">
        <table>
            <thead>
                <tr>
                    <th style="width: 40px;">
                        <input type="checkbox" name="checkAll" (change)="CheckAllOptions()"><span class="checkbx"></span>
                    </th>
                    <th>Date</th>
                    
                    <th>Type</th>
                    <th>Farm & Lot</th>
                    <th>Period</th>
                    <th>Requested By</th>
                    <th style="width: 35px;"><span class="dicon">
                        <img src="../../../../../../assets/images/item.png" alt="item">
                    </span></th>
                    <th style="width: 50px; padding-right: 15px;">
                        <span style="display: block; z-index: 2; position: relative; cursor: pointer;"
                            ><i
                                class="fa fa-trash align-self-center ml-2 f-20"
                                (click)="deleteMarkedRecords()"
                            ></i
                        ></span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let report of reports">
                    <td><input type="checkbox" name="check" [(ngModel)]="report.checked" [value]="report.id" (change)="selectEntry($event, report)"><span class="checkbx"></span></td>
                    <td>{{report?.created_at | date: "MMM dd yyyy, HH:mm"}}</td>
                    
                    <td>
                        <ng-container *ngFor="let type of templates">
                            <span *ngIf="type.id === report?.type">{{type.name}}</span>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                            <span style="display: inline-block;" *ngIf="farm.id === report?.farm">{{farm.name}}</span>
                        </ng-container>
                        <ng-container *ngFor="let lot of globalRegistry?.systemData?.lots">
                            <span style="display: inline-block; padding-left: 3px;" *ngIf="lot.id === report?.lot">{{' / ' + lot.name}}</span>
                        </ng-container>
                    </td>
                    <td>
                        <span>{{report?.start | date: "MMM dd yyyy"}} - {{report?.end | date: "MMM dd yyyy"}}</span>
                    </td>
                    <td>
                        <ng-container *ngFor="let person of persons">
                            <span *ngIf="person?.id === report?.created_by">{{person?.firstName}} {{person?.lastName}} {{person?.first_name}} {{person?.last_name}}</span>
                        </ng-container>
                    </td>
                    <td>
                        <span class="dicon" *ngIf="report.file != null">
                            <a [href]="mediaUrl+report.file" download style="display: block;">
                                <img src="../../../../../../assets/images/item.png" alt="item">
                            </a>
                        </span></td>
                    <td>
                        <span style="display: block; z-index: 2; position: relative; cursor: pointer;"
                            ><i
                                class="fa fa-trash align-self-center ml-2 f-20"
                                (click)="deleteReport(report?.id)"
                            ></i
                        ></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="custom_modal" id="reports_modal">
        <div class="modal_header">
            <h2>Request Report</h2>
            <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
        </div>
        <div class="modal_body">
            <div class="add_report">
                <form [formGroup]="reportForm">
                    <div class="form-group">
                        <div class="form-group w-100 row">
                            <div class="col-12">
                                <div class="input-group w-100">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{ 'stock.type' | translate }}</mat-label>
                                        <mat-select formControlName="type" required>
                                            <mat-form-field appearance="fill" class="w-100">
                                                <input matInput #personFilter placeholder="Search">
                                            </mat-form-field>
                                            <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let type of templates | filter : personFilter.value" [value]="type.id">
                                                {{type?.name}}
                                            </mat-option>
                                            </div>
                                        </mat-select>
                                        <mat-error *ngIf="ifControlHasError('type')">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'stock.farm' | translate }}</mat-label>
                                    <mat-select required formControlName="farm" (selectionChange)="filterLots($event)">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let farm of globalRegistry.systemData.farms | filter : farmFilter.value" [value]="farm.id">
                                            {{ farm.name }}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                <mat-form-field class="w-100">
                                    <mat-label class="col-form-label">
                                        {{ 'notes.lot' | translate }}</mat-label>
                                    <mat-select required formControlName="lot">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #lotFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let lot of filteredLots | filter : lotFilter.value" [value]="lot.id">
                                            {{ lot?.name }}
                                        </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <mat-form-field (click)="picker.open()">
                                    <input
                                        matInput
                                        readonly
                                        [ngxMatDatetimePicker]="picker"
                                        placeholder="From"
                                        [disabled]="false"
                                        formControlName="start"
                                        [max]="maxDate"
                                        (dateChange)="minValueChanged($event)"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker"
                                    ></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker
                                        [touchUi]="true"
                                        #picker
                                        [showSpinners]="true"
                                        [showSeconds]="false"
                                    >
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field (click)="until.open()">
                                    <input
                                        matInput
                                        readonly
                                        [ngxMatDatetimePicker]="until"
                                        placeholder="To"
                                        [min]="minDate"
                                        (dateChange)="maxValueChanged($event)"
                                        [disabled]="false"
                                        formControlName="end"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="until"
                                    ></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker
                                        [touchUi]="true"
                                        #until
                                        [showSpinners]="true"
                                        [showSeconds]="false"
                                    >
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="btns">
                                <button class="btn"(click)="addReport()">Request</button>
                                <button class="btn" (click)="hideModal()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </div>
</div>
