import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FapOverviewComponent } from './fap-overview.component';
import { AgmCoreModule } from '@agm/core';
import { MapHelper } from '../../../core/heplers/map.helper';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: MapHelper.GOOGLE_API_KEY,
            libraries: ['drawing', 'places']
        }),
    ],
    declarations: [
       FapOverviewComponent
    ]
})

export class OverviewModule {

}
