import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
    selector: 'fap-activities-card',
    templateUrl: './fap-activities-card.component.html',
    styleUrls: ['./fap-activities-card.component.scss'],
})
export class FapActivitiesCardComponent implements OnDestroy {
    @Input()
    public activity: ActivityModel;

    @Input()
    public activityTypes: Array<ActivityModel>;

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public equipments: Array<EquipmentModel> = [];

    @Input()
    public images = [];

    @Output()
    public deleteActivity: EventEmitter<number> = new EventEmitter();

    @Input() public persons: Array<PersonModel>;
    public subscription: Subscription = new Subscription();

    public mediaUrl = environment.mediaUrl;
    
    constructor(public globalRegistry: GlobalRegistryService, public equipmentsservice: EquipmentService, public typeService:TypesService, public widgetsService:WidgetsService) {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    calcTime(time) {
        return moment.duration(time).asMinutes();
    }
}
