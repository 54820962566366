import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import { NgxPermissionsModule } from 'ngx-permissions';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ActivitiesComponent } from './activities.component';
import { ActivitiesContainerComponent } from './containers/activities-container/activities-container.component';
import { ActivitiesAddEditContainerComponent } from './containers/activities-add-edit-container/activities-add-edit-container.component';
import { FapActivitiesLayoutComponent } from './components/fap-activities-layout/fap-activities-layout.component';
import { FapActivitiesCardComponent } from './components/fap-activities-card/fap-activities-card.component';
import { FapActivitiesAddEditLayoutComponent } from './components/fap-activities-add-edit-layout/fap-activities-add-edit-layout.component';
import { SharedModule } from '../../../shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PartialsModule } from '../../views-partials/partials.module';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule,NgxNativeDateModule } from '@angular-material-components/datetime-picker'
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports:[
        MatTableModule,
        RouterModule,
        SharedModule,
        CommonModule,
        FormsModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule,
        PartialsModule,
        NgxPermissionsModule.forChild(),
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatButtonModule,
        InfiniteScrollModule,
        // NgMultiSelectDropDownModule.forRoot()
    ],
    declarations:[
        ActivitiesComponent,
        ActivitiesContainerComponent,
        ActivitiesAddEditContainerComponent,
        FapActivitiesLayoutComponent,
        FapActivitiesCardComponent,
        FapActivitiesAddEditLayoutComponent
    ]
})

export class ActivitiesModule {

}