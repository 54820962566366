import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PartialsModule } from '../../views-partials/partials.module';
import { SharedModule } from '../../../shared/shared.module';
import { FormsComponent } from './forms.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        PartialsModule,
        DragDropModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatSlideToggleModule
    ],
    declarations: [
        FormsComponent,
    ]
})
export class FormModule {

}
