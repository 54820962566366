<div class="card mx-auto w-100 grey-background" *ngIf="!isDynamic">
    <div class="header" *ngIf="showHeader">
        <div class="heading">
            <p>Additional data</p>
            <!-- <span (click)="editForm()"><img src="../../../../../assets/images/fap/pencil.svg" alt="edit"></span> -->
        </div>
        <!-- <span *ngIf="objectId" (click)="addRelations = true" class="theme_color add_edit"></span> -->
    </div>
    <!-- {{formType | json}} -->
    <!-- <fap-list-attribute-relations
        [objectId]='objectId'
        [relationType]="entityRelation"
        [entityId]="entityId"
        (editRelation)="currentRelation = $event; editRelations = true"
        [formType]="formType"
        [entityInfo]="entityInfo"
        (reloadFormType)="updateRelation()"
    ></fap-list-attribute-relations> -->
    <fap-classical-form
        [objectId]='objectId'
        [entityId]="entityId"
        [entityInfo]="entityInfo"
        [formType]="formType"
        (formUpdate)="formUpdate.emit()"
        (reloadFormType)="updateRelation()"
        [type]="type"
        [formDisplayType]="'form'"
        [typeOfForm]="entityRelation"
        [entityRelation]="entityRelation"
        (formFieldsEmit)="formFieldsEmit.emit($event)"
    ></fap-classical-form>
     <div class="relations-wrapper-bckg" (click)="editRelations = false;addRelations = false" *ngIf="editRelations||addRelations"></div>
    <div *ngIf="editRelations || addRelations" class="attr-overlay">
        <div class="relations-wrapper">
            <fap-attribute-forms
                (closeRelations)="editRelations=false;addRelations=false"
                [objectId]="objectId"
                [relation]="entityRelation"
                [entityId]="entityId"
                [formType]="formType"
                (updateRelations)="updateRelation()"
            >
            </fap-attribute-forms>
        </div>
    </div> 
</div>

<ng-container *ngIf="isDynamic">
    <fap-classical-form
        [objectId]='objectId'
        [entityInfo]="entityInfo"
        [formType]="formType"
        (closeModal)="closeModal.emit()"
        (hideModal)="hideModal.emit()"
        (formUpdate)="formUpdate.emit()"
        (reloadFormType)="updatePoolList()"
        [type]="type"
        [formDisplayType]="formDisplayType"
        [typeOfForm]="entityRelation"
        [previousFormClick]="previousFormClick"
        [nextFormClick]="nextFormClick"
        [entityRelation]="entityRelation"
        (nextButtonClick)="nextButtonClick.emit($event)"
        (prevButtonClick)="prevButtonClick.emit($event)"
        [workflow]="workflow"
    ></fap-classical-form>
</ng-container>