<div [formGroup]="selectFormGroup" class="add-edit-delete-select">
    <div class="form-group w-100">
    <mat-form-field class="w-100">
        <mat-label class="col-form-label">{{ inputName }}</mat-label>
        <mat-select #aeds panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()" *ngIf="inputIsRequired" formControlName="value" required (infiniteScroll)="scrolledDown()" [alwaysCallback]="true" msInfiniteScroll [infiniteScrollDistance]="4"
        [infiniteScrollThrottle]="500">
            <div class="d-flex" style="align-items: center; padding: 0 10px;">
            <mat-form-field class="w-100" appearance="fill">
                <!-- <mat-label>
                    <em class="fa fa-search f-20" style="opacity: 0;"></em>
                </mat-label> -->
                <input matInput (keydown.enter)="searchKeyword(selectFilter.value); $event.stopPropagation()" #selectFilter placeholder="Search">
            </mat-form-field>
            <mat-label *ngIf="apiSearchEnabled" style="position: absolute; right: 80px; cursor: pointer;" (click)="searchKeyword(selectFilter.value)">
                <i class="fa fa-search f-20"></i>
            </mat-label>
            <i class="fa fa-plus center f-20 ml-2"
           (click)="outputOnCreate.emit(selectFilterValue);$event.stopPropagation();aeds.close()" alt='Create new equipment type' [ngClass]="{'preview': previewMode}"></i>
        </div><div class="scroll_wrap" #fapaeds (scroll)="onScroll($event)">
            <mat-option style="height: 0!important;"></mat-option>
            <mat-option *ngFor="let val of inputSelectValues | filter : selectFilter.value" [value]="val[inputValueFieldName]">
                <div class="name t">
                    <span *ngIf="val[inputNameTFieldName]">{{getTranslation(val[inputNameTFieldName])}}</span>
                    <span *ngIf="!val[inputNameTFieldName] || val[inputNameTFieldName] == null || val[inputNameTFieldName] === ''">{{ val[inputNameFieldName] | translate }}</span>
                </div>
                <div *ngIf="!val[inputReadOnlyFieldName]" class="actions">
                    <em class="fa fa-edit center f-20"
                        (click)="outputOnEdit.emit(val);$event.stopPropagation();aeds.close()"></em>
                    <em *ngIf="!val[inputProtectedFieldName]" class="fa fa-trash center ml-2 f-20"
                        (click)="clickedValue=val;deleteValueModal.showModal();$event.stopPropagation()">
                </em>
                </div>
            </mat-option>
        </div>
        </mat-select>
        <mat-select *ngIf="!inputIsRequired" formControlName="value" (infiniteScroll)="scrolledDown()" msInfiniteScroll>
            <div class="d-flex" style="align-items: center; padding: 0 10px;">
            <mat-form-field class="w-100" appearance="fill">
                <!-- <mat-label>
                    <em class="fa fa-search f-20" style="opacity: 0;"></em>
                </mat-label> -->
                <input matInput [ngModel]="selectFilterValue" [ngModelOptions]="{standalone: true}" placeholder="Search" #selectFilter>
            </mat-form-field>
            <i class="fa fa-plus center f-20 ml-2"
           (click)="outputOnCreate.emit(selectFilterValue)" alt='Create new equipment type' [ngClass]="{'preview': previewMode}"></i>
            </div>
            <div class="scroll_wrap" (scroll)="onScroll($event)">
                <mat-option style="height: 0!important;"></mat-option>
            <mat-option *ngFor="let val of inputSelectValues | filter : selectFilter.value" [value]="val[inputValueFieldName]">
                <div class="name t">
                    <span *ngIf="val[inputNameTFieldName]">{{ getTranslation(val[inputNameTFieldName]) }}</span>
                    <span *ngIf="!val[inputNameTFieldName] || val[inputNameTFieldName] == null || val[inputNameTFieldName] === ''">{{ val[inputNameFieldName] | translate }}</span>
                </div>
                <div class="actions">
                    <em class="fa fa-edit center f-20"
                        (click)="outputOnEdit.emit(val);$event.stopPropagation()"></em>
                    <em class="fa fa-trash center ml-2 f-20"
                        (click)="clickedValue=val;deleteValueModal.showModal();$event.stopPropagation()">
                </em>
                </div>
            </mat-option>
        </div>
        </mat-select>
        <mat-error *ngIf="selectFormGroup.controls['value'].errors?.required">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
        </mat-error>
    </mat-form-field>
    <!-- <img class="editor-pen" src="./assets/images/fap/edit_pencil.svg"
           (click)="outputOnCreate.emit(selectFilterValue)" alt='Create new equipment type' [ngClass]="{'preview': previewMode}"/> -->
</div>
</div>

<fap-modal #deleteValueModal
    inputTitle="{{ 'confirmDelete' | translate }}"
    [inputPrimaryButtonInterface]="deleteModalPrimaryButtonInterface"
    [inputSecondaryButtonInterface]="deleteModalSecondaryButtonInterface">
    {{ inputConfirmDeleteText }}
</fap-modal>
