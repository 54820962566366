<div class="scheduler_page" id="parent">
    <div class="scheduled_groups">
        <mat-accordion class="sgroups" multi openAll>
            <mat-expansion-panel expanded hideToggle class="group">
                <mat-expansion-panel-header class="hrow" #panelH (click)="panelH._toggle()">
                    <mat-panel-title class="title" (click)="panelH._toggle()">
                        <h2>{{device?.name ? device?.name : device?.id}} </h2>
                    </mat-panel-title>
                    <mat-panel-description class="info">
                        <span class="farm_name">{{farm?.name}}</span>
                    <span class="lot_name">({{lot?.name}})</span>
                    <span class="add_icon" style="display:none;">
                        <div class="rounded-circle v" (click)="showModal(sensor?.id)"></div>
                    </span>
                    <!-- <span class="collapse_icon" (click)="getNotification(sensor?.id)"></span> -->
                    <i class="fa fa-edit" (click)="editSensor(sensor)"></i>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="actions">
                    <div class="action-viewer" *ngFor="let action of sensor?.actions[0]">
                        <div [ngStyle]="{'color': '#000'}" class="pad0 dat">
                            <b>{{action.start | date: "MMM dd yyyy"}}</b> <b>{{ action.end | date: "MMM dd yyyy" }}</b>
                        </div>
                        <div class="min-hour">
                            <div class="mins">
                                <span class="lbl">min:</span> 
                                <span *ngIf="action?.minute">{{action.minute}}</span> <span *ngIf="!action?.minute">*</span>
                            </div>
                            <div class="hours">
                                <span class="lbl">hour:</span> 
                                <span *ngIf="action?.hour">{{action.hour}}</span> <span *ngIf="!action?.hour">*</span>
                            </div>
                        </div>
                        <div class="day-month">
                            <div class="days">
                                <span class="lbl">day:</span> 
                                <span *ngIf="action?.minute">{{action.day}}</span> <span *ngIf="!action?.day">*</span>
                            </div>
                            <div class="months">
                                <span class="lbl">month:</span> 
                                <span *ngIf="action?.month">{{action.month}}</span> <span *ngIf="!action?.month">*</span>
                            </div>
                        </div>
                        <div class="week-duration">
                            <div class="weeks">
                                <span class="lbl">week day:</span> 
                                <span *ngIf="action?.dweek">{{action.dweek}}</span> <span *ngIf="!action?.dweek">*</span>
                            </div>
                            <div class="duration">
                                <span class="lbl blk">duration:</span> 
                                <span *ngIf="action?.duration">{{action.duration}} mins</span> <span *ngIf="!action?.duration">*</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<div class="custom_modal" id="custom_modal">
    <div class="modal_header">
        <h2>{{"translations.addEditAction" | translate}}</h2>
        <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
    </div>
    <form [formGroup]="groupForm">
        <div class="form-group">
            <div class="row align-items-baseline">
                <div class="col-12">
                    <div class="input-group w-100">
                        <div class="col">
                            <mat-form-field (click)="picker.open()">
                                <input matInput readonly [ngxMatDatetimePicker]="picker" placeholder="Start" 
                                  [min]="minDate" [disabled]="false" formControlName="start">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker [touchUi]="true" #picker [showSpinners]="true" [showSeconds]="false">
                                </ngx-mat-datetime-picker>
                              </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field (click)="picker1.open()">
                                <input matInput readonly [ngxMatDatetimePicker]="picker1" placeholder="End" 
                                [min]="minDate" [disabled]="false" formControlName="end">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker [touchUi]="true" #picker1 [showSpinners]="true" [showSeconds]="false">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-baseline">
                <div class="col-12">
                    <button class="btn save" *ngIf="!isEdit" (click)="saveAction()">{{"translations.save" | translate}}</button>
                    <button class="btn save" *ngIf="isEdit" (click)="updateAction()">{{"translations.update" | translate}}</button>
                </div>
            </div>
        </div>
    </form>
</div>