import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { ActivityInterface } from '../../../../core/interfaces/activity/activity.interface';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { ActivityModel } from '../../../../core/models/activity/activity.model';
import { ActivityTypeModel } from '../../../../core/models/activity/activity-type.model';

@Component({
    selector: 'fap-add-edit-activity',
    templateUrl: './fap_add-edit-activity.component.html',
    styleUrls: ['./fap_add-edit-activity.component.scss']
})
export class FapAddEditActivityComponent implements OnChanges {

    @Input()
    public activity: ActivityModel;

    @Input()
    public activityTypes: Array<ActivityTypeModel>;

    @Output()
    public submitEvent: EventEmitter<ActivityInterface> = new EventEmitter();

    public activityForm: UntypedFormGroup;

    public trackingList: Array<number> = [1, 2, 3, 4, 5]; // mock data

    constructor(public formBuilder: UntypedFormBuilder) {
        this.initActivityForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'activity')){
            this.initActivityForm();
        }
    }

    public submitForm(): void {
        const activity: ActivityInterface = this.activityForm.value;
        if(this.activity){
            activity.id = this.activity.id;
        }
        this.submitEvent.emit(activity);
        this.initActivityForm();
    }

    private initActivityForm(): void {
        if (this.activity) {
            this.activityForm = this.formBuilder.group({
                name: [this.activity.name, Validators.compose([
                    Validators.required,
                ])],
                activityType: [this.activity.activityType, Validators.compose([
                    Validators.required,
                ])],
                started: [this.activity.started ? this.activity.started.replace(' ', 'T') : null],
                ended: [this.activity.ended ? this.activity.ended.replace(' ', 'T') : null],
                info: [this.activity.info],
                workingTime: [this.activity.workingTime]
            });
        }
        else {
            this.activityForm = this.formBuilder.group({
                name: ['', Validators.compose([
                    Validators.required,
                ])],
                activityType: [null, Validators.compose([
                    Validators.required,
                ])],
                started: [null],
                ended: [null],
                info: [null],
                workingTime: [null]
            });
        }
    }
}
