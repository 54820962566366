<fap-farm-edit-layout
    [farmId]="farmId"
    [farmName]="farmName"
    [farmParty]="farmParty"
    [isEditMode]="isEditMode"
    [isSubmitDisabled]="checkSubmitEnable()"
    [parties]="globalRegistry.systemData.parties"
    (submitFarm)="submitFarm($event)"
    (deleteFarmEvent)="showDeleteFarmModal($event)"
    >
</fap-farm-edit-layout>
<fap-modal
    #deleteFarmConfirmationModal
    inputTitle="{{ 'farm.areYouSureDeleteFarm' | translate }}"
    [inputPrimaryButtonInterface]="deleteFarmModalPrimaryButton"
    [inputSecondaryButtonInterface]="deleteFarmModalSecondaryButton">
</fap-modal>