import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavService } from '../../../../../../shared/services/nav.service';
import { AttributeService } from '../../../../../../core/services/api/attribute/attribute.service';
import { GlobalRegistryService } from '../../../../../../core/global-registry/global-registry.service';
import { CacheResolverService } from '../../../../../../core/services/api/cache/cache-resolver.service';
import { EquipmentService } from '../../../../../../core/services/api/equipment/equipment.service';
import { ActivityService } from '../../../../../../core/services/api/activity/activity.service';
import { StockService } from '../../../../../../core/services/api/stock/stock.service';
import { NotesService } from '../../../../../../core/services/api/farm/notes.service';
import { FarmService } from '../../../../../../core/services/api/farm/farm.service';
import { Subscription } from 'rxjs';
import { FapModalComponent } from '../../../../../../shared/partials';
import * as moment from 'moment';
import { UnitModel } from '../../../../../../core/models/units/unit.model';
import { MapsAPILoader } from '@agm/core';
import { FapModalButtonInterface } from '../../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../../../../core/services/api/company/company.service';
import { environment } from '../../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TypesService } from '../../../../../../core/services/api/types/types.service';
import { MapPointInterface } from '../../../../../../shared/layout/fap_main-map/data/map-point.interface';
declare let google;

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  content: string;
  color?: string;
  iconUrl?: string;
}

@Component({
  selector: 'classical-form-field',
  templateUrl: './classical-form-field.component.html',
  styleUrls: ['./classical-form-field.component.scss']
})
export class ClassicalFormFieldComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() field: any;
  public editValuesForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];
  @Input() selectedAttr = null;
  @Input() resetForm = false;
  public minDate;
    public maxDate;
  @ViewChild('editValuesPopup')
  public editValuesPopup: FapModalComponent;
  public entities = [];
  public limit = 100;
  public getMore = true;
  public nextToken: { limit: number; offset: number; search: string } = null;
  public entityModel = '';
  public inputType = '';
  public valueType = '';
  public dropdownSettings = {}
  @ViewChild('mys') mys;
  @Output()
  public editValue = new EventEmitter<{ value: any, type: string }>();
  public langString: string;
  zoom = 8;
  markers:Array<marker> = [];
  public searchControl: UntypedFormControl;
  public mapSearchToggle: boolean;
  public mapTypeControl: boolean;
  @ViewChild('search1', {static: true}) public searchElementRef: ElementRef;
  public coordsArray = [];
  defaultCenter = {lat: 51.673858, lng: 7.815982};
  public fieldImages:Array<number> = [];
  currentCenter = Object.assign({}, this.defaultCenter);
  @ViewChild('locationPopup') locationPopup: FapModalComponent;
  public images =  [];
  public image: File;
  public fieldImagesId: any = [];
  @ViewChild('addImageModal')
  public addImageModal: FapModalComponent;
  public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
  public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
  public imgWidth: number;
  public imgHeight: number;
  public orientation: string;
  public nextImgToken :{ offset: number; limit: number; group: string; } = null;
  public getMoreImg = true;
  public imageForm: UntypedFormGroup;
  public mediaUrl = environment.mediaUrl;
  imageSrc: string;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  public changedImage: boolean;
  public translatedNames = [];
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  @ViewChild('nameInput1') nameInput1: ElementRef;
  public translation = null;
  public nameT = null;
  public bounds: google.maps.LatLngBounds;
  public polygons;
  public polylines;
  public mapType = '';
  public mapCoords;
  public mapPolygons;
  public mapPolylines;
  public isEditMode = false;
  public coords;
  @Input() entityInfo: any;
  public selectedChoices: number[] = [];
  public selectedChoiceId: number | null = null;

  constructor(
    public navService: NavService,
    public attributeService: AttributeService,
    public globalRegistry: GlobalRegistryService,
    public cacheService: CacheResolverService,
    public formBuilder: UntypedFormBuilder,
    public equipmentService: EquipmentService,
    public activityService: ActivityService,
    public stockService: StockService,
    public notesService: NotesService,
    public farmService: FarmService, public translateService: TranslateService, public companyService: CompanyService, public toastr: ToastrService, public typesService: TypesService) { }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
    this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
      if(lang) {
        this.langString = lang;
      }
    }));
    this.getTranslations();
    this.initNewRelationForm();
      this.imageForm = new UntypedFormGroup({
        file: new UntypedFormControl('', Validators.required),
        group: new UntypedFormControl('form_field', Validators.required),
        tags: new UntypedFormControl([], Validators.required),
        orientation: new UntypedFormControl('', Validators.required)
      });
      this.initImageModalButtons();
      // if(this.resetForm) {
        // this.editValuesForm.reset();
      // }
  }

  public initImageModalButtons(): void {
    const _this: ClassicalFormFieldComponent = this;

    this.addImageModalButtonPrimaryInterface = {
        clickFunction(): void {
            _this.imageSubmit();
        },
        text: this.translateService.instant('save')
    };

    this.addImageModalButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addImageModal.hideModal();
        },
        text: this.translateService.instant('cancel')
    };
}

addImage(ev) {
  this.fieldImages.push(ev.value.id);
  console.log(this.fieldImages);
  this.editValuesForm.get('value').setValue(this.fieldImages);
  console.log(this.editValuesForm.value);
  this.editValue.emit({value: this.fieldImages, type: 'value'})
}

deleteNoteImage(imgId) { 
  this.fieldImages = this.fieldImages.filter((item) => item !== imgId);
  console.log(this.fieldImages);
  this.editValuesForm.get('value').setValue(this.fieldImages);
  console.log(this.editValuesForm.value);
  this.editValue.emit({value: this.fieldImages, type: 'value'})
}

public getImages() {
  const url = this.companyService.getUrl('images/')
  this.cacheService.delete(url+'group=form_field');
  this.companyService.getCompanyImages({group: 'form_field'}).subscribe((response): void => {
      this.images = response.body["results"];
      console.log(this.images);
      this.nextImgToken = response.body.next
      ? this.globalRegistry.getQueryStringParams(response.body.next.split("?")[1])
      : null;
      if(this.nextImgToken) this.getMoreImg = true
    })
  }

  onScrollImages(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
      this.scrolledDownImages();
    }
  }

  public showImageModal(): void {
    this.addImageModal.showModal();
}

  scrolledDownImages() {
    const url = this.companyService.getUrl('images/');
    console.log('scrolled');
    if(this.getMoreImg) {
    this.nextImgToken &&
      this.subscriptions.push(this.companyService.getCompanyImages(this.nextImgToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextImgToken.limit+'&offset='+this.nextImgToken.offset+'&group=form_field');
        console.log(data);
        if(this.images) {
          this.images = [...this.images, ...data.body.results];
        } else {
          this.images = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreImg = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextImgToken.offset != params['offset']) {
          this.nextImgToken = {limit: params['limit'], offset: params['offset'], group: 'form_field'};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

public imageSubmit() {
  const data = {
      width: this.imgWidth,
      height: this.imgHeight,
      orientation: this.orientation
  }
  const formData = new FormData();
  formData.append("file", this.image);
  formData.append("group", this.imageForm.get('group').value);
  formData.append("tags", this.imageForm.get('tags').value);
  formData.append("data", JSON.stringify(data));
  
  this.companyService.postCompanyImages(formData).subscribe(data => {
      console.log(data);
      if(data.body.results.id) {
          this.getImages();
      }
  })
  this.addImageModal.hideModal();
}

onFileInput(event) {
  console.log(event);
  if(event.target.files && event.target.files.length) {
    if ( /\.(jpeg|jpg|png|gif)$/i.test(event.target.files[0].name) === false ) { 
      this.toastr.error(this.translateService.instant('image.notAnImage'))
      this.image = null;
      this.fileUploader.nativeElement.value = null;
      return
    }
    const [image] = event.target.files;
    this.image = image;
    const _URL = window.URL || window.webkitURL;
    let file, img, height, width;
    if ((file = event.target.files[0])) {
        img = new Image();
        const objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
            height = this.height;
            width = this.width;
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    }
    setTimeout(()=> {
      if(width <= height) {
        this.orientation  = "portrait";
        console.log("portrait");
      } else {
          this.orientation  = "landscape";
          console.log("landscape");
      }
    }, 500);
  }
}

getTranslations() {
  this.subscriptions.push(
      this.typesService.getTranslations().subscribe((data) => {
          this.translatedNames = data.model;
          return;
      })
  );
}

getImage(id) {
  this.companyService.getCompanyImage(id).subscribe(data => {
    this.images.push(data.body.results);
  })
}

public imageUploaded(file: File): void {
  this.changedImage = true;
  this.image = file;
  console.log(this.image);
  this.imageForm.markAsDirty();
}

ngAfterViewInit() {
  if(this.mys && this.mys.nativeElement) {
    this.mys.nativeElement.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) { // enter key code
        event.stopPropagation();
      }
    });
  }
  }

  changeValue(event) {
    console.log(event.target.value)
  }

  collectEntities(ev) {
    console.log(ev);
  }

  scrollEntities(filter) {
    console.log(filter);
  }

  public minValueChanged(ev) {
    this.minDate = ev.target.value;
}

public maxValueChanged(ev) {
    this.maxDate = ev.target.value;
}

getTranslation(translation) {
  const t =this.translatedNames.filter(trans => {
    return trans.id == translation
  });
  if(t[0]) {
      return t[0][this.langString];
    } else {
      return translation
    }
}

updateMap() {
  console.log(this.field.field.value_type);
  this.locationPopup.showModal();
  // this.polygons = [];
  // this.polylines = [];
  setTimeout(() => {
    this.initNewRelationForm();
  }, 200)
  if(this.field.field.value_type == 'surface' || this.field.field.value_type == 'polyline') {
    this.isEditMode = true;
  } else {
    this.isEditMode = false
  }
}

public centerMapOnMarkers(): void {
  if (!this.markers.length) {
      return;
  }
  this.bounds = new google.maps.LatLngBounds();
  if (this.markers.length) {
      // let latSum = 0;
      // let lngSum = 0;
      this.markers.forEach((point: MapPointInterface): void => {
          // latSum += point.lat;
          // lngSum += point.lng;
          this.bounds.extend(
              new google.maps.LatLng(point.lat, point.lng)
          );
      });
  }

  this.currentCenter.lat += this.markers[0].lat;
  this.currentCenter.lng += this.markers[0].lng;
  // this.zoom += this.markers[0].zoom;
  this.zoom = 10;
}
 
mapClicked($event) {
  this.markers = [];
  this.markers.push({
    lat: $event.coords.lat,
    lng: $event.coords.lng,
    draggable: true,
    content: $event.coords.lat +', '+ $event.coords.lng,
  });
  this.coordsArray = [$event.coords.lat, $event.coords.lng];
  this.editValuesForm.get('value').patchValue(this.coordsArray.toString());
  console.log(this.coordsArray);
}

markerDragEnd(m: marker, $event) {
  console.log("dragEnd", m, $event);
}

mapReady(event) {
  console.log(event);
  this.setCurrentPosition();
}

private setCurrentPosition() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.currentCenter.lat = position.coords.latitude;
      this.currentCenter.lng = position.coords.longitude;
      this.zoom = 15;
    });
  }
}

toggleChoice(choiceName: number) {
  const index = this.selectedChoices.indexOf(choiceName);
  if (index === -1) {
    this.selectedChoices.push(choiceName);
  } else {
    this.selectedChoices.splice(index, 1);
  }
  console.log(this.selectedChoices);
  this.editValuesForm.get('value').setValue(this.selectedChoices);
}

getChoice(item) {
  let string = '';
  if(this.entityInfo && this.entityInfo.fields.length) {
  this.entityInfo.fields.forEach(element => {
    if(element.choices && element.choices.length) {
      element.choices.forEach(e => {
        if(e.id == item) {
          string = this.getTranslation(e.name_t);
        }  
      })
    }
  });
}
  return string;
}

setChoice(choiceId: number) {
  this.selectedChoiceId = choiceId;
  console.log(this.selectedChoiceId);
}

  public initNewRelationForm(): void {
    this.editValuesForm = this.formBuilder.group({
        name: [null, Validators.required],
        value: [null, Validators.required],
        info: [this.selectedAttr ? this.selectedAttr.info : null, Validators.required],
        min: [null],
        max: [null],
    });
    console.log('selected attribute', this.selectedAttr);
    this.mapType = this.field ? this.field.field.value_type : 'coords';
    if(this.field && this.selectedAttr) {
      if(this.field.field.value_type === 'string') {
        // this.editValuesForm.get('name').patchValue(this.getTranslation(this.selectedAttr.value.input))
        // console.log(this.selectedAttr.value.input)
        this.selectedChoices = [];
        this.translatedNames.forEach(element => {
          if(element.id === this.selectedAttr.value.input) {
            console.log(this.langString);
            console.log(element[this.langString]);
            this.editValuesForm.get('name').patchValue(this.langString ? element[this.langString] : element.en)
          }
        });
      }

      if(this.field.field.value_type === 'string' || this.field.field.value_type === 'boolean' || this.field.field.input_type === 'choice') {
        this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input)
      }

      if(this.field.field.value_type ==='image') {
        if(this.selectedAttr.value.input) {
          this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input)
          this.fieldImages = this.selectedAttr.value.input;
          if(this.fieldImages && this.fieldImages.length) {
            this.fieldImages.forEach(imgId => {
              this.getImage(imgId);
            });
          }
        }
      }

      if(this.field.field.value_type === 'number') {
        if(this.field.field.input_type === 'single') {
          this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input)
        } else {
          if((this.selectedAttr.value.input && this.selectedAttr.value.input.min)) {
          this.editValuesForm.get('min').patchValue(this.selectedAttr.value.input.min)
        }
        if(this.selectedAttr.value.input && this.selectedAttr.value.input.max) {
            this.editValuesForm.get('max').patchValue(this.selectedAttr.value.input.max)
          }
        }
      }

      if(this.field.field.value_type === 'datetime') {
        if(this.field.field.input_type === 'single') {
          this.editValuesForm.get('value').patchValue(moment(this.selectedAttr.value.input).format(''))
        } else {
          this.editValuesForm.get('min').patchValue(moment(this.selectedAttr.value.input.min).format(''))
          this.editValuesForm.get('max').patchValue(moment(this.selectedAttr.value.input.max).format(''))
        }
      }

      if(this.field.field.value_type === 'coords') {
        this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input.coordinates.toString())
        if(this.selectedAttr && this.selectedAttr.value && this.selectedAttr.value.input) {
          this.coords = this.selectedAttr.value.input;
        }
      }

      if(this.field.field.value_type === 'surface') {
        if(this.selectedAttr.value.input) {
          const pointsString = this.selectedAttr.value.input.map(entry =>
            entry.points.map(point => `${point.lat},${point.lng}`).join(',')
          ).join(';');
          this.editValuesForm.get('value').patchValue(pointsString)
        }
        if(this.selectedAttr && this.selectedAttr.value && this.selectedAttr.value.input) {
          this.polygons = this.selectedAttr.value.input;
        }
      }

      if(this.field.field.value_type === 'polyline') {
        if(this.selectedAttr.value.input) {
          const pointsString = this.selectedAttr.value.input.map(entry =>
            entry.points.map(point => `${point.lat},${point.lng}`).join(',')
          ).join(';');
          this.editValuesForm.get('value').patchValue(pointsString)
        }
        if(this.selectedAttr && this.selectedAttr.value && this.selectedAttr.value.input) {
          this.polylines = this.selectedAttr.value.input;
        }
      }

      if(this.field.field.entity) {
        console.log(this.selectedAttr.value);
        this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input)
        this.entityModel = this.field.field.entity.model;
        const entity : string = this.entityModel;
        console.log(entity);
        switch (entity) {
            case 'farm':
                this.getFarms();
                break;
            case 'lot':
                this.getLots();
                break;
            case 'equipment':
                this.getEquipments();
                break;
            case 'activity':
                this.getActivities();
                break;
            case 'ingredient':
                this.getIngredients();
                break;
            case 'note':
                this.getNotes();
                break;
            case 'bill':
                this.getBills();
                break;
            case 'post':
                this.getPosts();
                break;
            case 'person':
                this.getPersons();
                break;
            case 'simulation':
                this.getSimulations()
                break;
            default:
                console.log("no entities matched");
                break;
        }
        console.log(this.entities)
      }
    }
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.editValuesForm.controls['value'].setValue(translation.id);
    this.editValuesForm.controls['name'].setValue(translation[this.langString]);
    this.editValue.emit({value: translation.id, type: 'value'})
    this.addEditTranslationsModal.hideModal();
}

  addEditTranslation() {
    // const name = this.nameInput1.nativeElement.value;
    const name = this.editValuesForm.controls['value'].value;
    console.log(name);
    const type: any = this.convertKeysToCamelCase(this.editValuesForm.value);
    console.log(type);
    if(name === null || name === '' || !name.includes('_')) {
        this.translation = null;
        this.addEditTranslationsModal.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type.value) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
            }
        });
    }
    }

    convertKeysToCamelCase(obj) {
      const camelCaseObj = {};
      for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
      }
      return camelCaseObj;
    }

  editFieldValue(event) {
    console.log(event.target.value);
  }

  removeItem(id) {
    // let value = this.selectedAttr.value.input.filter(item => item !== id);
    this.selectedAttr.value.input = this.selectedAttr.value.input.filter(item => item !== id);
    this.editValuesForm.get('value').patchValue(this.selectedAttr.value.input);
  }

  ngOnDestroy(){
    this.subscriptions.forEach(x=>x.unsubscribe());
    localStorage.removeItem('fap-attribute-relations');
    this.entities = [];
    this.inputType = null;
    this.valueType = null;
    this.entityModel = null;
  }

  public getUnitName(unitId:number):string {
    const units: UnitModel[] = [];
    this.globalRegistry.systemData.units.forEach(unit=>{
      unit.children && unit.children.length>0 && units.push(...unit.children);
    })
    return units.find(unit=>unit.id===unitId)?.shortName;
  }

  public getEntity(model, value) {
    const valueIds = [value]
    if(model === 'farm') {
      const names = this.globalRegistry?.systemData.farms.filter(item => valueIds.includes(item.id)).map(item => item.name);
      return names 
    } else if(model === 'lot') {
      const names = this.globalRegistry?.systemData.lots.filter(item => valueIds.includes(item.id)).map(item => item.name);
      return names
    } else if(model === 'bill') {
      if(typeof value === 'number') {
        this.stockService.getDoc(value).subscribe(data => {
          return data.body.results.doc_id
        })
      } else {
        const names = []
        value.forEach(id => {
          this.stockService.getDoc(id).subscribe(data => {
            names.push(data.body.results.doc_id);
          })
        });
      }
    }
  }

  public editValues(attr) {
    this.selectedAttr = attr;
    console.log(attr);
    this.globalRegistry.systemData.attributes.forEach(element => {
      if(element.id === attr.type) {
        this.field = element;
        this.inputType = this.field.field.inputType;
        this.valueType = this.field.field.value_type;
        
      }
    });
    this.editValuesPopup.showModal();
    this.initNewRelationForm();
  }

  setValue(value) {
    console.log(value);
    this.selectedAttr.value.input = value;
    this.editValuesForm.get('value').setValue(value);
    this.editValue.emit({value: value, type: 'value'})
  }

  filterEntity(model, keyword) {
    console.log(model + ':' + keyword);
    if(model === 'bill') {
      this.filterBills(keyword);
    } else if(model === 'post') {
      this.filterPosts(keyword);
    } else if(model === 'activity') {
      this.filterActivities(keyword);
    } else if(model === 'ingredient') {
      this.filterIngredients(keyword);
    } else if(model === 'note') {
      this.filterNotes(keyword);
    } else if(model === 'person') {
      this.filterPersons(keyword);
    } else {
      return
    }
  }

  public getLots() {
    this.entities = this.globalRegistry.systemData.lots;
  }

  public getFarms() {
    this.entities = this.globalRegistry.systemData.farms;
  }

  public getEquipments() {
    const url = this.equipmentService.getUrl('equipment/');
        this.cacheService.delete(url+'limit=1000')
        this.equipmentService.getEquipments({limit: 1000}).subscribe(data => {
            console.log(data.model);
            this.entities = data.model;
        })
  }

  public getIngredients() {
    const url = this.stockService.getUrl('ingredients/')
    this.subscriptions.push(
      this.stockService.getIngredients({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
      })
    )
  }

  filterIngredients(value) {
    const url = this.stockService.getUrl('activities/');
    const params = { search: value }
    this.subscriptions.push(this.stockService.getIngredients(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getActivities() {
    const url = this.activityService.getUrl('activities/')
    this.subscriptions.push(
      this.activityService.getActivities({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterActivities(value) {
    const url = this.activityService.getUrl('activities/');
    const params = { search: value }
    this.subscriptions.push(this.activityService.getActivities(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getPersons() {
    const url = this.companyService.getUrl('persons/')
    this.subscriptions.push(
      this.companyService.getPersons({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterPersons(value) {
    const url = this.companyService.getUrl('persons/');
    const params = { search: value }
    this.subscriptions.push(this.companyService.getPersons(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getNotes() {
    const url = this.notesService.getUrl('')
    this.subscriptions.push(
      this.notesService.getNotes({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data['results'];
        this.nextToken = data['next']
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterNotes(value) {
    const url = this.notesService.getUrl('');
    const params = { search: value }
    this.subscriptions.push(this.notesService.getNotes(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data['next']
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data['results'];
      if(this.nextToken) this.getMore = true
    }))
  }

  public getBills() {
    const url = this.stockService.getUrl('docs/')
    this.subscriptions.push(
      this.stockService.getDocs({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterBills(value) {
    const url = this.stockService.getUrl('');
    const params = { search: value }
    this.subscriptions.push(this.stockService.getDocs(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getPosts() {
    const url = this.farmService.getUrl('posts/')
    this.subscriptions.push(
      this.farmService.getPosts({limit: this.limit}).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit)
        this.entities = data.body.results;
        this.nextToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.nextToken) this.getMore = true;
      })
    )
  }

  filterPosts(value) {
    const url = this.farmService.getUrl('posts/');
    const params = { search: value }
    this.subscriptions.push(this.farmService.getPosts(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search)
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.entities = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  public getSimulations() {
    console.log('get simulations')
  }

  onScroll(model, event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrolledDown(model);
    }
  }

  scrolledDown(model) {
    console.log(model);
    if(model === 'bill') {
      this.scrollBills();
    } else if(model === 'post') {
      this.scrollPosts();
    } else if(model === 'activity') {
      this.scrollActivities();
    } else if(model === 'ingredient') {
      this.scrollIngredients();
    } else if(model === 'note') {
      this.scrollNotes();
    } else if(model === 'person') {
      this.scrollPersons();
    }else {
      return
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  scrollBills() {
    const url = this.stockService.getUrl('docs/');
    console.log('docs scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.stockService.getDocs(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollPosts() {
    const url = this.farmService.getUrl('posts/');
    console.log('posts scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.farmService.getPosts(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollActivities() {
    const url = this.activityService.getUrl('activities/');
    console.log('activities scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.activityService.getActivities(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollPersons() {
    const url = this.companyService.getUrl('persons/');
    console.log('persons scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.companyService.getPersons(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollIngredients() {
    const url = this.stockService.getUrl('ingredients/');
    console.log('ingredients scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.stockService.getIngredients(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data.body.results];
        } else {
          this.entities = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollNotes() {
    const url = this.notesService.getUrl('');
    console.log('notes scrolled');
    if(this.getMore) {
    this.nextToken &&
    this.subscriptions.push(this.stockService.getIngredients(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.entities) {
          this.entities = [...this.entities, ...data['results']];
        } else {
          this.entities = data['results'];
        }
        if(data['next'] == null) {
          this.getMore = false;
          return
        } else {
          const url = data['next'].split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  updateCoords(event) {
    console.log(event);
    if(event.coordinates.length) {
      this.editValuesForm.get('value').setValue(event.coordinates.toString());
      this.editValue.emit({value: event, type: 'coords'})
    }
  }

  updatePolylines(event) {
    console.log(event);
    if(event && event.length) {
      const pointsString = event.map(entry =>
        entry.points.map(point => `${point.lat},${point.lng}`).join(',')
      ).join(';');
      this.editValuesForm.get('value').setValue(pointsString);
      // this.selectedAttr.value.input = event
      this.editValue.emit({value: event, type: 'polyline'})
    }
  }

  updatePolygons(event) {
    if(event && event.length) {
      const pointsString = event.map(entry =>
        entry.points.map(point => `${point.lat},${point.lng}`).join(',')
      ).join(';');
      this.editValuesForm.get('value').setValue(pointsString);
      // this.selectedAttr.value.input = event
      this.editValue.emit({value: event, type: 'surface'})
      console.log(this.editValuesForm.value);
    }
  }

  closePopup() {
    this.locationPopup.hideModal();
    console.log(this.editValuesForm.value);
    console.log(this.selectedAttr.value.input);
  }

}
