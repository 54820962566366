import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
  selector: 'workflow-card',
  templateUrl: './workflow-card.component.html',
  styleUrls: ['./workflow-card.component.scss']
})
export class WorkflowCardComponent implements OnInit {
  @Input() public workflow:any;
  @Input() public selectedType:any;
  public mediaUrl = environment.mediaUrl;
  public langString: string;
  public subscriptions:Array<Subscription> = [];

  constructor(public globalRegistry: GlobalRegistryService, private navService: NavService) {}

  ngOnInit() {
    console.log(this.workflow);
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
      }));
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
        return translation
      }
  }
}
