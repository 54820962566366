<div *ngIf="!showCreateOrEditField" class="container-fluid p-0 grey-background" [ngStyle]="{'height': '90vh'}">
    <div class="card mx-auto p-3 grey-background">
        <div class="d-flex justify-content-between">
            <div class="header w-100 theme_bg">
                <p>{{'farm.genericData' | translate }}</p>
            </div>
        </div>
        <div class="card-body p-md-4 mb-3 white-background" style="padding: 10px 0 0!important;">
            <form [formGroup]="lotForm" style="margin-left: -10px"
                  [ngClass]="{'col-6': mapService.mapShiftedOnce, 'col-12': mapService.mapShiftedTwice, 'col-6': !mapService.mapShiftedOnce && !mapService.mapShiftedTwice}">
                  <mat-form-field class="w-100" *ngIf="showFormCombo">
                    <mat-label class="col-form-label">{{"farm.farm" | translate}}</mat-label>
                    <mat-select formControlName="farm" (selectionChange)="setFarm($event.value)">
                        <mat-form-field appearance="fill" class="w-100">
                        <input matInput #farm placeholder="Search"/>
                        </mat-form-field>
                        <div class="scroll_wrap">
                            <div class="slct">
                                <mat-option style="height:0;"></mat-option>
                                 <mat-option *ngFor="let farm of globalRegistry?.systemData?.farms | filter : farm.value" [value]="farm?.id">
                                    {{farm?.name}}
                                </mat-option>
                            </div>
                        </div>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>{{ 'farm.lot.lotName' | translate }}</mat-label>
                    <input (input)="checkSubmitEnable()" formControlName="lotName" matInput placeholder="{{ 'farm.lot.lotName' | translate }}">
                </mat-form-field>
            </form>
            <div [ngStyle]="{'color':navService.sidebarBackgroundColor}"
                 [ngClass]="{'col-6': mapService.mapShiftedOnce, 'col-12': mapService.mapShiftedTwice, 'col-6': !mapService.mapShiftedOnce && !mapService.mapShiftedTwice}"
                 class="edit-map">
                <p class="para">
                    Edit area on map 
                    or press
                    <span [ngStyle]="{'background':navService.hoverColor}">
                        <img src="./assets/images/fap/add_edit_farm.svg" alt="Edit on map">
                    </span>
                </p>
                <span>
                    <img class="mt_12" [src]=navService.triangle alt="triangle">
                </span>
            </div>
            <fap-lot-field-card *ngFor="let field of lotFields"
                                [fieldName]="field.name"
                                [fieldSoilType]="field.soilType"
                                [fieldArea]="field.area"
                                [fieldPolygon]="field.coords.coordinates[0]"
                                [fieldOwner]="field.owner"
                                [fieldPreview]="field.previewImg"
                                [parties]="globalRegistry.systemData.parties"
                                (editField)="editField(field)"
                                (deleteField)="deleteField(field.id)">
            </fap-lot-field-card>
            <div class="divider"></div>

            <fap-culture-edit *ngIf="!isNoCrop"
                              [crop]="currentCrop"
                              [showMarkUnmarkAsEndedBtn]="true"
                              [showDeleteCultureBtn]="true"
                              (cropChanged)="crop=$event"
                              [parties]="globalRegistry.systemData.parties"
                              [resourceTypes]="globalRegistry.systemData.resourceTypes"
                              (addStockIncome)="addStockIncome($event)"
                              (addStockOutcome)="addStockOutcome($event)"
                              (deletedCulture)="deleteCulture()"
                              (deleteParty)="deleteParty($event)">
            </fap-culture-edit>
        </div>
            <fap-attributes
                [entityRelation]="'lot'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="lotId"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
            >
            </fap-attributes>
    </div>
    <div *ngIf="showCreateOrEditField" class="container-fluid p-0">
        <div class="card mx-auto">
            <div class="card-header d-flex justify-content-between">
                <h3 class="mb-0">{{ 'farm.field.addEditField' | translate }}</h3>
                <i class="material-icons ter-btn-icon ter-btn-icon-success ml-auto"
                   (click)="exitCreateOrEditFieldMode()">close</i>
            </div>
            <div class="card-body p-4">
                <fap-add-edit-field
                    [fieldId]="editFieldId"
                    [isSubmitDisabled]="editPolygon?.points?.length<3"
                    [fieldName]="editFieldName"
                    [soilType]="editFieldSoilType"
                    [area]="editFieldArea"
                    [owner]="editFieldOwner"
                    [parties]="globalRegistry.systemData.parties"
                    (submitField)="createdOrEditedField($event)"
                    (deleteParty)="deleteParty($event)">
                </fap-add-edit-field>
            </div>
        </div>
    </div>
</div>
