<!-- Page Header Start-->
<div class="page-main-header"
     [ngClass]="{'open' : toggleSidebar}" id="page-main-header">
    <div class="main-header-right row">
        <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
                <label class="switch">
                    <a href="javascript:void(0)" (click)="navServices.collapseSidebar.next(!toggleSidebar)">
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="mobile-filters">
            <div class="edit-create" *ngIf="farmEditCreate">
                <h5 *ngIf="isAlergicProfile" class="mobBtn">{{ 'alergotel.alergicProfile' | translate }}</h5>
                <h5 *ngIf="isBioParticles" class="mobBtn">{{ 'alergotel.bioParticles' | translate }}</h5>
                <h5 *ngIf="isRecommendations" class="mobBtn">{{ 'alergotel.recommendations' | translate }}</h5>
                <h5 *ngIf="isReports" class="mobBtn">{{ 'alergotel.reports' | translate }}</h5>
                <h5 *ngIf="isFarmEditCreate">{{ 'farm.park' | translate }}</h5>
                <h5 *ngIf="isLotEditCreate" class="mobBtn">{{ 'farm.lot.plantation' | translate }}</h5>
                <h5 *ngIf="isEquipmentEditCreate" class="mobBtn">{{ 'equipment.equipment' | translate }}</h5>
                <h5 *ngIf="isPersonEditCreate" class="mobBtn">{{ 'people.person' | translate }}</h5>
                <h5 *ngIf="isNotesEditCreate" class="mobBtn">{{ 'leftMenu.notes' | translate }}</h5>
                <h5 *ngIf="isFieldsEditCreate" class="mobBtn">{{ 'leftMenu.fields' | translate }}</h5>
                <h5 *ngIf="isActivitiesEditCreate" class="mobBtn">{{ 'leftMenu.activities' | translate }}</h5>
                <h5 *ngIf="isSchedulerEditCreate" class="mobBtn">{{ 'leftMenu.scheduler' | translate }}</h5>
                <h5 *ngIf="isDeviceEditCreate" class="mobBtn">{{ 'leftMenu.devices' | translate }}</h5>
                <h5 *ngIf="isSimulationEditCreate" class="mobBtn">{{ 'leftMenu.simulations' | translate }}</h5>
                <h5 *ngIf="isBillEditCreate" class="mobBtn">
                    <span *ngIf="activatedRoute.snapshot.queryParams['type'] === 'invoice_in'">
                        {{"stock.invoiceIn" | translate}}
                    </span>
                    <span *ngIf="activatedRoute.snapshot.queryParams['type'] === 'invoice_out'">
                        {{"stock.invoiceOut" | translate}}
                    </span>
                    <span *ngIf="activatedRoute.snapshot.queryParams['type'] === 'internal_note'">
                        {{"stock.internalNote" | translate}}
                    </span>
                    <span *ngIf="activatedRoute.snapshot.queryParams['type'] === 'estimation_note'">
                        {{"stock.estimationNote" | translate}}
                    </span>
                    <span *ngIf="activatedRoute.snapshot.queryParams['type'] === 'production_note'">
                        {{"stock.productionNote" | translate}}
                    </span>
                </h5>
                <h5 *ngIf="isDiseaseEditCreate" class="mobBtn">{{ 'leftMenu.diseases' | translate }}</h5>
                <button *ngIf="!isViewMod" (click)="navServices.emitSubmit.next(true)"  [ngStyle]="{'background':buttonColor}" class="btn mobBtn actionBtn">
                    <span>{{isCreateMod ? ('header.submit' | translate) : ('header.submit' | translate)}}</span>
                    <span><i class="fa fa-check"></i></span>
                </button>
                <button (click)="navServices.emitCancel.next(true)" [ngStyle]="{'background':buttonColor}" class="btn mobBtn actionBtn">
                    <span>{{'header.cancel' | translate}}</span>
                    <span><i class="fa fa-ban"></i></span>
                </button>
                <button *ngIf="isEditMod" (click)="navServices.emitDelete.next(true)"  [ngStyle]="{'background':buttonColor}" class="btn mobBtn actionBtn">
                    <span>{{isEditMod ? ('header.delete' | translate) : ('header.delete' | translate)}}</span>
                    <span><i class="fa fa-trash"></i></span>
                </button>
            </div>
            <div *ngIf="hasFarmFilters || hasSingleFarmFilter || hasLotFilters || hasSingleLotFilter || hasDateFilters || hasDashboardFilter || hasPostsFilter || hasReportsFilter || hasMyProductsFilter || hasDevicesFilter || hasPoolsFilter || hasAlergotelReportsFilter">
                <div class="d-flex filters">
                    <div class="farms mr-4 farms_toggle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" *ngIf="hasFarmFilters && !hasAlergotelReportsFilter && !hasFormsFilter && !hasWorkflowsFilter">
                        <i class="material-icons select-icon text-white">house</i>
                        <mat-form-field (click)="emitOpenDialog()" appearance="outline">
                            <mat-select disabled
                                [formControl]="selectedFarms"
                                multiple
                                placeholder="{{'leftMenu.farms' | translate}}">
                                <mat-option style="height: 0!important;"></mat-option>
                                <!-- <mat-option *ngFor="let farm of globalRegistry.systemData.farms"
                                            [value]="farm.id">
                                    {{farm.name}}
                                </mat-option>
                                <mat-option [value]="-1"><i>{{ 'farm.noFarm' | translate }}</i></mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="lots ibox mr-3" *ngIf="hasAlergotelReportsFilter">
                        <i class="material-icons select-icon text-white">view_quilt</i>
                        <mat-form-field
                            [ngStyle]="{'background-color':navServices.searchBarBackground}"
                            appearance="outline">
                            <span class="search" *ngIf="!reportFilterCtrl.value, else clear">
                            <i class="fa fa-search f-20"></i>
                            </span>
                            <ng-template #clear>
                                    <span (click)="clearReportsFilter()" class="search">
                                     <i class="fa fa-times f-20"></i>
                                    </span>
                            </ng-template>
                            <input
                                (keydown.enter)="onSearchSubmit('alergotel_reports',reportFilterCtrl.value)"
                                (search)="onSearchSubmit('alergotel_reports',reportFilterCtrl.value)"
                                matInput
                                placeholder="Search Reports"
                                [formControl]="reportFilterCtrl"
                                aria-label="Reports"
                                [autocomplete]="auto"
                                type="text">
                            <mat-autocomplete #auto>
                                    <mat-option  *ngFor="let option of filteredFarms | async"
                                                 [value]="option">
                                        {{option}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div *ngIf="hasDashboardFilter && createWidget && !hasDeviceViewFilter && !hasAlergotelReportsFilter" class="add_circle mr-5" (click)="navServices.openWidget.next(true)">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasNotesFilter" class="add_circle mr-5" [routerLink]="'/pages/notes/add'">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasActivityFilter" class="add_circle mr-5" [routerLink]="'/pages/activities/add'">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasSimulationsFilter" class="add_circle mr-5" [routerLink]="'/pages/simulations/add'">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasPeopleFilter" class="add_circle mr-5" [routerLink]="'/pages/people/add'">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasEquipmentFilter" class="add_circle mr-5" [routerLink]="'/pages/equipments/add'">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasReportsFilter" class="add_circle mr-5" [routerLink]="'/pages/reports/'" [queryParams]="{popup: 'true'}">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasMyProductsFilter" class="add_circle mr-5" [routerLink]="'/pages/my-products/'" [queryParams]="{popup: 'true'}">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasDevicesFilter" class="add_circle mr-2" [routerLink]="'/pages/devices/'" [queryParams]="{popup: 'true'}">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasDevicesFilter" class="add_circle mr-3" [routerLink]="'/pages/devices/'" [queryParams]="{config: 'true'}">
                        <div class="rounded-circle r config {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasStockFilter" class="add_circle mennu mr-2" (click)="dropdown()">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}"></div>
                        <div class="dropdown_cover" [ngClass]="{'open' : showDropdown}">
                            <div  class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'invoice_in', new_doc: 'true'}">
                                <div class="rounded-circle r invoice_in {{ hasDashboardFilter && createWidget ? '' : 'def' }}"></div>
                                <div class="stock_text">{{"stock.invoiceIn" | translate}}</div>
                            </div>
                            <div class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'invoice_out', new_doc: 'true'}">
                                <div class="rounded-circle r invoice_out {{ hasDashboardFilter && createWidget ? '' : 'def' }}"></div>
                                <div class="stock_text">{{"stock.invoiceOut" | translate}}</div>
                            </div>
                            <div class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'internal_note', new_doc: 'true'}">
                                <div class="rounded-circle r internal_note {{ hasDashboardFilter && createWidget ? '' : 'def' }}"></div>
                                <div class="stock_text">{{"stock.internalNote" | translate}}</div>
                            </div>
                            <div class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'production_note', new_doc: 'true'}">
                                <div class="rounded-circle r production_note {{ hasDashboardFilter && createWidget ? '' : 'def' }}"></div>
                                <div class="stock_text">{{"stock.productionNote" | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="hasStockFilter" class="add_circle mr-2" [routerLink]="'/pages/stock/'" [queryParams]="{popup: 'true'}">
                        <div class="rounded-circle r all_docs {{ hasDashboardFilter && createWidget ? '' : 'def' }}"></div>
                    </div>
                    <div *ngIf="hasStockFilter" class="add_circle mr-5" [routerLink]="'/pages/stock/'" [queryParams]="{products: 'true'}">
                        <div class="rounded-circle r production_note {{ hasDashboardFilter && createWidget ? '' : 'def' }}"></div>
                    </div>
                    <div *ngIf="hasPoolsFilter" class="add_circle mr-5 pool" [routerLink]="'/pages/pools'" [queryParams]="{add: 'true'}">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <div *ngIf="hasAlergotelReportsFilter" class="add_circle mr-3" [routerLink]="'/pages/alergotel_add-report'">
                        <div class="rounded-circle {{ hasDashboardFilter && createWidget ? '' : 'def' }}" ></div>
                    </div>
                    <span *ngIf="hasFormsFilter" class="add_circle mr-2" (click)="getFormTypes()">
                        <mat-form-field>
                            <mat-label>{{'forms.formTypes' | translate}}</mat-label>
                            <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="formTypes">
                                <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #personFilter placeholder="Search" (keydown.enter)="$event.stopPropagation()">
                                </mat-form-field>
                                <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                                    <i class="fa fa-search f-20"></i>
                                </mat-label>
                                <div class="input-group-append pl-1">
                                    <span class="input-group-text">
                                        <i class="icofont icofont icofont-plus" (click)="newFormType()"></i>
                                     </span>
                                </div>
                            </div>
                            <div class="scroll_wrap">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option class="noteIcons" *ngFor="let type of formTypes | filter : personFilter.value" [value]="type.id" (click)="editFormType(type?.id);">
                                    <span class="mat-option-text">
                                    <div class="name">{{ type?.name }}</div>
                                    <div class="actions">
                                        <i class="fa fa-edit center f-20" (click)="editFormType(type?.id);"></i>
                                        <i class="fa fa-trash center f-20 ml-1" (click)="deleteFormType(type?.id); $event.stopPropagation()"></i>
                                    </div>
                                    </span>
                                </mat-option>
                            </div>
                            </mat-select>
                        </mat-form-field>
                    </span>
                    <span *ngIf="hasWorkflowsFilter" class="add_circle mr-3" (click)="getWorkflowTypes()">
                        <mat-form-field>
                            <mat-label>{{'workflow.workflowTypes' | translate}}</mat-label>
                            <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="workflowTypes">
                                <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #workflowFilter placeholder="Search" (keydown.enter)="$event.stopPropagation()">
                                </mat-form-field>
                                <mat-label style="position: absolute; right: 30px; cursor: pointer;">
                                    <i class="fa fa-search f-20"></i>
                                </mat-label>
                                <!-- <div class="input-group-append pl-1">
                                    <span class="input-group-text">
                                        <i class="icofont icofont icofont-plus" (click)="newFormType()"></i>
                                     </span>
                                </div> -->
                            </div>
                            <div class="scroll_wrap">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option class="noteIcons" *ngFor="let type of workflowTypes | filter : workflowFilter.value" [value]="type.id" (click)="editWorkflowType(type?.id);">
                                    <span class="mat-option-text">
                                    <div class="name">{{ type?.name ? type?.name : type?.uniqueid }}</div>
                                    <!-- <div class="actions">
                                        <i class="fa fa-edit center f-20" (click)="editFormType(type?.id);"></i>
                                        <i class="fa fa-trash center f-20 ml-1" (click)="deleteFormType(type?.id); $event.stopPropagation()"></i>
                                    </div> -->
                                    </span>
                                </mat-option>
                            </div>
                            </mat-select>
                        </mat-form-field>
                    </span>
                    <div class="add_circle mr-5" *ngIf="hasWorkflowsFilter && workflowId" (click)="setWorkflowId(workflowId)">
                        <div class="rounded-circle"></div>
                    </div>
                    <ng-container [formGroup]="drill" *ngIf="hasDashboardFilter && showDrill && !hasPostsFilter && !hasAlergotelReportsFilter">
                        <div class="farms mr-4 date_picker">
                            <!-- <i><img src="../../../../assets/icons/calendar.png" alt=""></i> -->
                            <mat-form-field appearance="outline" class="dateform" >
                                <mat-date-range-input [rangePicker]="picker">
                                  <input matStartDate formControlName="start" placeholder="Start Date" disabled>
                                  <input matEndDate formControlName="end" placeholder="End Date" disabled>  
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <div class="farms mr-4 drill_type">
                            <mat-form-field appearance="outline" class="aggregate">
                                <mat-select placeholder="Aggregate By" formControlName="aggBy" (selectionChange)="changeDate($event.value)">
                                    <mat-option value="hour">Hour</mat-option>
                                    <mat-option value="day">Day</mat-option>
                                    <mat-option value="month">Month</mat-option>
                                    <!-- <mat-option value="year">Year</mat-option> -->
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container [formGroup]="filterPost" *ngIf="hasDashboardFilter && showDrill && hasPostsFilter && !hasAlergotelReportsFilter">
                        <div class="farms mr-2 date_picker">
                            <mat-form-field appearance="outline" class="dateform" >
                                <mat-date-range-input [rangePicker]="picker">
                                  <input matStartDate formControlName="start" (dateChange)="setFromDate($event)" placeholder="Start Date" disabled>
                                  <input matEndDate formControlName="end" (dateChange)="setToDate($event)" placeholder="End Date" disabled>  
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <ng-container [formGroup]="filterReport" *ngIf="hasDashboardFilter && showDrill && hasAlergotelReportsFilter && !hasPostsFilter">
                        <div class="farms mr-2 date_picker">
                            <mat-form-field appearance="outline" class="dateform">
                                <mat-date-range-input [rangePicker]="picker">
                                  <input matStartDate formControlName="start" (dateChange)="setFromDate($event)" placeholder="Start Date" disabled>
                                  <input matEndDate formControlName="end" (dateChange)="setToDate($event)" placeholder="End Date" disabled>  
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <div *ngIf="hasDashboardFilter && showDrill && hasPostsFilter" class="mr-2">
                        <button class="btn btn-drill mt-1" (click)="filterPosts()">Filter</button>
                    </div>
                    <!-- <div *ngIf="hasDashboardFilter && showDrill && hasAlergotelReportsFilter" class="mr-2">
                        <button class="btn btn-drill mt-1" (click)="filterReports()">Filter</button>
                    </div> -->
                    <div *ngIf="hasDashboardFilter && showDrill && hasPostsFilter">
                        <button class="btn btn-drill mt-1" (click)="exportPostData()">Export</button>
                    </div>
                    <div *ngIf="hasDashboardFilter && showDrill && !hasPostsFilter && !hasAlergotelReportsFilter">
                        <button class="btn btn-drill mt-1" (click)="drillData()">Drill</button>
                    </div>
                    <div *ngIf="(!hasPeopleFilter&&!hasDashboardFilter && !hasNotesFilter && !hasEquipmentFilter &&!hasActivityFilter &&!hasStockFilter &&!hasTreatmentsFilter &&!hasReportsFilter &&!hasMyProductsFilter && !hasDevicesFilter &&!hasDeviceViewFilter &&!hasSimulationsFilter &&!hasPoolsFilter &&!hasFormsFilter &&!hasWorkflowsFilter && !hasAlergotelReportsFilter) && hasFarmFilters" [routerLink]="'/pages/farm/create'" class="add_circle mr-5" (click)="farmAddMenu = !farmAddMenu">
                        <div class="rounded-circle" >
                        </div>
<!--                        <div class="add_dropdown" [ngStyle]="{'background': sidebarColor}" *ngIf="farmAddMenu">-->
<!--                                <div (click)="closeFarmDropdown()" [ngStyle]="hoverPark && {'background':  hoverColor, 'color': 'black'}" (mouseover)="hoverPark=true" (mouseleave)="hoverPark=false" [routerLink]="'/pages/lots/'">Add park</div>-->
<!--                                <div (click)="closeFarmDropdown()" [ngStyle]="hoverPlantation && {'background':  hoverColor, 'color': 'black'}" (mouseover)="hoverPlantation=true" (mouseleave)="hoverPlantation=false" [routerLink]="'/pages/farm/'">Add plantation</div>-->
<!--                        </div>-->
                    </div>
                    <div class="lots ibox {{ hasDashboardFilter && createWidget ? '' : 'def' }}" *ngIf="hasLotFilters">
                        <i class="material-icons select-icon text-white">view_quilt</i>
                        <mat-form-field
                            [ngStyle]="{'background-color':navServices.searchBarBackground}"
                            appearance="outline">
                            <span class="search" *ngIf="!lotFilterCtrl.value, else clear">
                            <i class="fa fa-search f-20"></i>
                            </span>
                            <ng-template #clear>
                                    <span (click)="lotFilterCtrl.reset('')" class="search">
                                     <i class="fa fa-times f-20"></i>
                                    </span>
                            </ng-template>
                            <input
                                placeholder="Search plantations"
                                (keydown.enter)="onSearchSubmit('lots',lotFilterCtrl.value)"
                                (search)="onSearchSubmit('lots',lotFilterCtrl.value)"
                                matInput
                                [formControl]="lotFilterCtrl"
                                aria-label="Farms"
                                [autocomplete]="auto"
                                type="text">
                            <mat-autocomplete #auto>
                                    <mat-option  *ngFor="let option of filteredFarms | async"
                                                 [value]="option">
                                        {{option}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="lots ibox" *ngIf="hasNotesFilter">
                        <i class="material-icons select-icon text-white">view_quilt</i>
                        <mat-form-field
                            [ngStyle]="{'background-color':navServices.searchBarBackground}"
                            appearance="outline">
                            <span class="search" *ngIf="!noteFilterCtrl.value, else clear">
                            <i class="fa fa-search f-20"></i>
                            </span>
                            <ng-template #clear>
                                    <span (click)="clearNotesFilter()" class="search">
                                     <i class="fa fa-times f-20"></i>
                                    </span>
                            </ng-template>
                            <input
                                (keydown.enter)="onSearchSubmit('notes',noteFilterCtrl.value)"
                                (search)="onSearchSubmit('notes',noteFilterCtrl.value)"
                                matInput
                                placeholder="Search Notes"
                                [formControl]="noteFilterCtrl"
                                aria-label="Notes"
                                [autocomplete]="auto"
                                type="text">
                            <mat-autocomplete #auto>
                                    <mat-option  *ngFor="let option of filteredFarms | async"
                                                 [value]="option">
                                        {{option}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="lots ibox" *ngIf="hasActivityFilter">
                        <i class="material-icons select-icon text-white">view_quilt</i>
                        <mat-form-field
                            [ngStyle]="{'background-color':navServices.searchBarBackground}"
                            appearance="outline">
                            <span class="search" *ngIf="!activityFilterCtrl.value, else clear">
                            <i class="fa fa-search f-20"></i>
                            </span>
                            <ng-template #clear>
                                    <span (click)="clearActivitiesFilter()" class="search">
                                     <i class="fa fa-times f-20"></i>
                                    </span>
                            </ng-template>
                            <input
                                (keydown.enter)="onSearchSubmit('activities',activityFilterCtrl.value)"
                                (search)="onSearchSubmit('activities',activityFilterCtrl.value)"
                                matInput
                                placeholder="Search Activities"
                                [formControl]="activityFilterCtrl"
                                aria-label="Activities"
                                [autocomplete]="auto"
                                type="text">
                            <mat-autocomplete #auto>
                                    <mat-option  *ngFor="let option of filteredFarms | async"
                                                 [value]="option">
                                        {{option}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="lots ibox" *ngIf="hasDevicesFilter">
                        <i class="material-icons select-icon text-white">view_quilt</i>
                        <mat-form-field
                            [ngStyle]="{'background-color':navServices.searchBarBackground}"
                            appearance="outline">
                            <span class="search" *ngIf="!deviceFilterCtrl.value, else clear">
                            <i class="fa fa-search f-20"></i>
                            </span>
                            <ng-template #clear>
                                    <span (click)="clearDevicesFilter()" class="search">
                                     <i class="fa fa-times f-20"></i>
                                    </span>
                            </ng-template>
                            <input
                                (keydown.enter)="onSearchSubmit('devices',deviceFilterCtrl.value)"
                                (search)="onSearchSubmit('devices',deviceFilterCtrl.value)"
                                matInput
                                placeholder="Search Devices"
                                [formControl]="deviceFilterCtrl"
                                aria-label="Devices"
                                [autocomplete]="auto"
                                type="text">
                            <mat-autocomplete #auto>
                                    <mat-option  *ngFor="let option of filteredFarms | async"
                                                 [value]="option">
                                        {{option}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="lots ibox" *ngIf="hasEquipmentFilter">
                        <i class="material-icons select-icon text-white">view_quilt</i>
                        <mat-form-field
                            [ngStyle]="{'background-color':navServices.searchBarBackground}"
                            appearance="outline">
                            <span class="search" *ngIf="!equipmentFilterCtrl.value, else clear">
                            <i class="fa fa-search f-20"></i>
                            </span>
                            <ng-template #clear>
                                    <span (click)="clearEquipmentsFilter()" class="search">
                                     <i class="fa fa-times f-20"></i>
                                    </span>
                            </ng-template>
                            <input
                                (keydown.enter)="onSearchSubmit('equipments',equipmentFilterCtrl.value)"
                                (search)="onSearchSubmit('equipments',equipmentFilterCtrl.value)"
                                matInput
                                placeholder="Search Equipments"
                                [formControl]="equipmentFilterCtrl"
                                aria-label="Equipments"
                                [autocomplete]="auto"
                                type="text">
                            <mat-autocomplete #auto>
                                    <mat-option  *ngFor="let option of filteredFarms | async"
                                                 [value]="option">
                                        {{option}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="farms mr-5" *ngIf="hasSingleFarmFilter">
                        <i class="material-icons select-icon text-white">house</i>
                        <mat-form-field appearance="outline">
                            <mat-select
                                [formControl]="selectedFarm"
                                (selectionChange)="changedFilters('farms')"
                                placeholder="Farms">
                                <mat-form-field appearance="fill" class="w-100">
                                    <!-- <mat-label>
                                        <i class="fa fa-search f-20"></i>
                                    </mat-label> -->
                                    <input matInput #singleFarmFilter placeholder="Search">
                                </mat-form-field>
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let farm of globalRegistry.systemData.farms | filter : singleFarmFilter.value"
                                    [value]="farm.id">
                                    {{farm.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="lots mr-5" *ngIf="hasSingleLotFilter">
                        <i class="material-icons select-icon text-white">view_quilt</i>
                        <mat-form-field appearance="outline">
                            <mat-select
                                [formControl]="selectedLot"
                                (selectionChange)="changedFilters('lots');globalRegistry.precisionDatesList.emit($event.value)"
                                placeholder="Lots">
                                <mat-form-field appearance="fill" class="w-100">
                                    <!-- <mat-label>
                                        <i class="fa fa-search f-20"></i>
                                    </mat-label> -->
                                    <input matInput #singleLotFilter placeholder="Search">
                                </mat-form-field>
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let lot of lotList | filter : singleLotFilter.value" [value]="lot.id">{{lot.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="groups {{ hasDashboardFilter && createWidget ? '' : 'def' }}" *ngIf="hasGroupFilters">
                        <i class="material-icons select-icon text-white">supervisor_account</i>
                        <mat-form-field appearance="outline">
                            <mat-select
                                [formControl]="selectedGroups"
                                (selectionChange)="changedFilters('groups')"
                                multiple
                                placeholder="Groups">
                                <mat-form-field appearance="fill" class="w-100">
                                    <!-- <mat-label>
                                        <i class="fa fa-search f-20"></i>
                                    </mat-label> -->
                                    <input matInput #groupsFilter placeholder="Search">
                                </mat-form-field>
                                <mat-option
                                    *ngFor="let group of globalRegistry.systemData.peopleGroups | filter : groupsFilter.value"
                                    [value]="group.id">{{group.name}}</mat-option>
                                <mat-option [value]="-1"><i>{{ 'people.groups.noGroup' | translate }}</i>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="date mr-5" *ngIf="hasDateFilters && globalRegistry.systemData.datesList">
                        <i class="material-icons select-icon text-white">gps_fixed</i>
                        <mat-form-field appearance="outline">
                            <mat-select
                                [(ngModel)]="globalRegistry.selectedDate"
                                (selectionChange)="this.globalRegistry.precisionDate.emit($event.value)"
                                placeholder="Date">
                                <mat-form-field appearance="fill" class="w-100">
                                    <!-- <mat-label>
                                        <i class="fa fa-search f-20"></i>
                                    </mat-label> -->
                                    <input matInput #dateFilter placeholder="Search">
                                </mat-form-field>
                                <mat-option
                                    *ngFor="let date of globalRegistry.systemData.datesList | filter : dateFilter.value"
                                    [value]="date">{{date | date: 'medium'}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="precision" *ngIf="hasDateFilters && globalRegistry.systemData.datesList">
                        <i class="material-icons select-icon text-white">date_range</i>
                        <mat-form-field appearance="outline">
                            <mat-select
                                [formControl]="selectedType"
                                (selectionChange)="this.globalRegistry.precisionType.emit($event.value)"
                                placeholder="Precision">
                                <mat-form-field appearance="fill" class="w-100">
                                    <!-- <mat-label>
                                        <i class="fa fa-search f-20"></i>
                                    </mat-label> -->
                                    <input matInput #precisionFilter placeholder="Search">
                                </mat-form-field>
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let precisionType of precisionTypes"
                                            [value]="precisionType.value">{{precisionType.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-right">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown language">
                    <a class="text-white d-flex align-items-center" href="javascript:void(0)">
                        <h6 class="text-white">{{this.language | uppercase}}</h6>
                    </a>
                    <ul class="language-dropdown onhover-show-div p-20">
                        <li><a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
                            class="flag-icon flag-icon-is"></i> English</a></li>
                        <li><a href="javascript:void(0)" data-lng="ro" (click)="changeLanguage('ro')"><i
                            class="flag-icon flag-icon-ro"></i> Romanian</a></li>
                        <li><a href="javascript:void(0)" data-lng="fr" (click)="changeLanguage('fr')"><i
                            class="flag-icon flag-icon-fr"></i> French</a></li>
                    </ul>
                </li>
                <!-- <li class="onhover-dropdown ml-5 d-flex align-items-center notifications">
                    <app-feather-icons class="bell" [icon]="'bell'"></app-feather-icons><span class="dot bg-white"></span>
                    <ul class="notification-dropdown onhover-show-div">
                        <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0"><span>
                                            <app-feather-icons class="shopping-color" [icon]="'shopping-bag'">
                                            </app-feather-icons>
                                        </span>Your order ready for Ship..!<small class="pull-right">9:00 AM</small>
                                    </h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-success"><span>
                                            <app-feather-icons class="download-color font-success" [icon]="'download'">
                                            </app-feather-icons>
                                        </span>Download Complete<small class="pull-right">2:30 PM</small></h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-danger"><span>
                                            <app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'">
                                            </app-feather-icons>
                                        </span>250 MB trush files<small class="pull-right">5:00 PM</small></h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li class="bg-light txt-dark"><a href="javascript:void(0)">All</a> notification</li>
                    </ul>
                </li>
                <li class="mr-5 d-flex align-items-center messages">
                    <a>
                        <app-feather-icons class="right_side_toggle" [icon]="'message-circle'">
                        </app-feather-icons><span class="dot bg-white"></span>
                    </a>
                </li> -->

<!--                Profile Start-->
<!--                <li class="onhover-dropdown p-0 d-flex">-->
<!--                    <div class="media align-items-center">-->
<!--                        <img class="align-self-center pull-right rounded-circle user-image"-->
<!--                            [src]="globalRegistry.userData.profile.pictureSrc" alt="header-user">-->
<!--                        &lt;!&ndash; <div class="dotted-animation">-->
<!--                            <span class="animate-circle border-white"></span>-->
<!--                            <span class="main-circle bg-white"></span>-->
<!--                        </div> &ndash;&gt;-->
<!--                    </div>-->
<!--                    <ul class="profile-dropdown onhover-show-div p-20">-->
<!--                        <li>-->
<!--                            <a (click)="router.navigate(['pages/user-profile'])">-->
<!--                                <app-feather-icons [icon]="'user'"></app-feather-icons>{{ "header.editProfileLabel" | translate }}-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a (click)="router.navigate(['pages/company'])">-->
<!--                                <app-feather-icons [icon]="'briefcase'"></app-feather-icons>{{ "header.companyLabel" | translate }}-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a href="javascript:void(0)">-->
<!--                                <app-feather-icons [icon]="'settings'"></app-feather-icons>{{ "header.settingsLabel" | translate }}-->
<!--                            </a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a (click)="logOut()">-->
<!--                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>{{ "header.logoutLabel" | translate }}-->
<!--                            </a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                Profile end-->
            </ul>
            <div class="d-lg-none mobile-toggle pull-right"
                (click)="openSecondNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>
<!-- Page Header Ends -->
