import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    pure: false,
    name: 'getUnit',
})
export class getUnitPipe implements PipeTransform {
    public transform([units, unitId]) {
        let unitName;
        units.forEach((unit) => {
            if (unit.id === unitId) {
                if(unit.unit) {
                    unitName = unit.unit
                } else {
                    unitName = ''
                }
            }
        });
        return unitName
    }
}
