import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { WeatherModel } from "../../../../core/models/widget/weather/weather.model";
import { FarmModel } from "../../../../core/models/farm/farm.model";
import { NavService } from "../../../../shared/services/nav.service";
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
  selector: "fap-farm-header",
  templateUrl: "./fap-farm-header.component.html",
  styleUrls: ["./fap-farm-header.component.scss"],
})
export class FapFarmHeaderComponent implements OnInit {
  @Input()
  public forecast: WeatherModel;

  @Input()
  public key:string;

  @Input()
  public farm: FarmModel;

  @Input()
  public addRoute: string;

  public enableAddEdit = true;

  constructor(
    public navService: NavService,
    public translateService: TranslateService,
    public globalRegistry: GlobalRegistryService
  ) {
  }

  ngOnInit():void {
    [this.enableAddEdit] = this.globalRegistry.getAccessRights(this.key);
  }

  public getTranslate(word: string): string {
    return this.translateService.instant(word);
  }
}
