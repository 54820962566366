<div class="row m-0" id="parent">
    <div class="col-12 p-0">
        <div class="card">
            <div class="card-header px-3  rounded-0">
                {{title}} {{ 'widget.overview' | translate }}
            </div>
            <div class="card-body notedit">
                <form [formGroup]="notesForm">
                    <div class="form-group">
                        <div class="row align-items-baseline">
                            <div class="col-12">
                                <div class="input-group align-items-baseline w-100">
                                    <!-- <mat-form-field class="w-100">
                                        <mat-label>{{ 'notes.name' | translate }}</mat-label>
                                        <input matInput formControlName="name">
                                        <mat-error *ngIf="notesTypeForm.controls['name'].errors?.required">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field> -->

                                    <mat-form-field class="w-100" (click)="addEditNameTranslation()">
                                        <mat-label>{{ "notes.name" | translate }}</mat-label>
                                        <input
                                            matInput
                                            formControlName="name"
                                            name="name"
                                            class="p-0"
                                            style="height: 16px;"
                                            readonly #nameInput1
                                        />
                                        <mat-error
                                                *ngIf="
                                                notesForm.controls[
                                                        'name'
                                                    ].errors?.required
                                                "
                                            >
                                                <strong>{{
                                                    "auth.validation.requiredField"
                                                        | translate
                                                }}</strong>
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-baseline">
                            <div class="col-4">
                                <div class="input-group align-items-baseline w-100">
                                    <mat-form-field>
                                        <mat-label>{{ 'notes.note_type' | translate }}</mat-label>
                                        <mat-select formControlName="type" name="type" required [value]="note?.type.id">
                                            <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                            <mat-form-field appearance="fill" class="w-100">
                                                <!-- <mat-label>
                                                    <i class="fa fa-search f-20"></i>
                                                </mat-label> -->
                                                <input matInput #typeFilter placeholder="Search">
                                            </mat-form-field>
                                            <div class="input-group-append pl-1" (click)="showNoteTypeModal(true)">
                                                <span class="input-group-text"><i
                                                        class="icofont icofont icofont-plus"></i></span>
                                            </div>
                                        </div>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option class="noteIcons" *ngFor="let type of types | filter : typeFilter.value" [value]="type.id">
                                                <div class="name">
                                                    <span *ngIf="type?.nameT">
                                                        <span *ngIf="getTranslation(type?.nameT) === '' || getTranslation(type?.nameT) == null || getTranslation(type?.nameT) == undefined ">{{type?.name}}</span>
                                                        <span *ngIf="getTranslation(type?.nameT) !== ''">{{getTranslation(type?.nameT)}}</span>
                                                        </span>
                                                    <span *ngIf="!type?.nameT">{{type?.name}}</span>                                                    
                                                </div>
                                                <div class="actions">
                                                    <i class="fa fa-edit" (click)="noteTypeEdit($event, type)"></i>
                                                    <i class="fa fa-trash" (click)="noteTypeDelete($event, type)"></i>
                                                </div>
                                            </mat-option>
                                        </div>
                                        </mat-select>
                                        <mat-error *ngIf="notesForm.controls['type'].errors?.required">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <!-- <mat-form-field class="border-top-0 border-right-0 border-left-0" (click)="timestamp.open()">
                                    <mat-label>{{ 'notes.timestamp' | translate }}</mat-label>
                                    <input matInput readonly [max]="notesForm.controls['timestamp'].value"
                                        formControlName="timestamp" [matDatepicker]="timestamp">
                                    <mat-datepicker-toggle matSuffix [for]="timestamp">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker  #timestamp></mat-datepicker>
                                    <mat-error *ngIf="notesForm.controls['timestamp'].errors?.required">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field> -->
                                <!-- <mat-form-field class="border-top-0 border-right-0 border-left-0" (click)="open($event, 'timestamp')">
                                    <mat-label>Time</mat-label>
                                    <input matInput readonly required formControlName="timestampTime">
                                     <mat-error *ngIf="notesForm.controls['timestampTime'].errors?.required">
                                     <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field> -->
                                <mat-form-field (click)="picker.open()">
                                    <input matInput readonly [ngxMatDatetimePicker]="picker" placeholder="Time" 
                                      [min]="minDate" [disabled]="false" formControlName="timestamp">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker [touchUi]="true" #picker [showSpinners]="true" [showSeconds]="false">
                                    </ngx-mat-datetime-picker>
                                  </mat-form-field>
                            </div>
                            <div class="col-4">
                                <!-- <mat-form-field class="" (click)="until.open()">
                                    <mat-label>{{ 'notes.until' | translate }}</mat-label>
                                    <input matInput readonly required [min]="notesForm.controls['until'].value"
                                        formControlName="until" [matDatepicker]="until">
                                    <mat-datepicker #until></mat-datepicker>
                                    <mat-error *ngIf="notesForm.controls['until'].errors?.required">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="" (click)="open($event, 'until')">
                                    <mat-label>Time</mat-label>
                                    <input matInput readonly required formControlName="untilTime">
                                     <mat-error *ngIf="notesForm.controls['untilTime'].errors?.required">
                                     <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field> -->
                                <mat-form-field (click)="until.open()">
                                   <input matInput readonly [ngxMatDatetimePicker]="until" placeholder="Until" 
                                      [min]="minDate" [disabled]="false" formControlName="until">
                                    <mat-datepicker-toggle matSuffix [for]="until"></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker [touchUi]="true" #until [showSpinners]="true" [showSeconds]="false">
                                    </ngx-mat-datetime-picker>
                                  </mat-form-field>
                            </div>
                            <div class="col-12 mb-2">
                                <chips 
                                    [items]="globalRegistryService.systemData.lots" 
                                    [selectedItems]="selectedItems"
                                    [label]="'activities.lots' | translate"
                                    (updatedItems)="updateItems($event)"
                                    [addEnabled]="false"
                                >
                                </chips>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100">
                                    <mat-label>{{"notes.coords" | translate}}</mat-label>
                                    <input class="w-100" matInput readonly [min]="notesForm.controls['coords'].value"
                                        formControlName="coords" [value]="coordsString">    
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <div class="input-group w-100">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{"Assigned to"}}</mat-label>
                                        <mat-select formControlName="assigned_to" name="assigned_to">
                                            <mat-form-field appearance="fill" class="w-100">
                                                <!-- <mat-label>
                                                    <i class="fa fa-search f-20"></i>
                                                </mat-label> -->
                                                <input matInput #personFilter placeholder="Seach">
                                            </mat-form-field>
                                            <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let person of personList | filter:personFilter.value" [value]="person?.id">{{person?.firstName}} {{person?.lastName}} {{ person?.first_name }} {{ person?.last_name }}</mat-option>
                                        </div>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row ">
                            <div class="col col-12 d-flex">
                                <div class="d-flex" style="overflow-y: hidden;">
                                    <div class="img_container">
                                        <div class="images" style="display: flex;">
                                            <div class="nimg" *ngFor="let imageId of noteImages">
                                            <ng-container *ngFor="let image of images">
                                                <div class="imgbox" *ngIf="image.id == imageId">
                                                    <div class="delt_btn">
                                                        <i class="fa fa-trash" (click)="deleteNoteImage(image?.id)"></i>
                                                    </div>
                                                    <img [src]="mediaUrl+image?.file" alt="" [attr.class]="image?.data?.orientation">
                                                </div>
                                            </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bx">
                                        <mat-label style="display: block;"> {{ 'notes.images' | translate }} </mat-label>
                                        <div class="input-group align-items-baseline w-100 img_bx">
                                            <mat-form-field (click)="getImages()">
                                                <mat-label>{{ 'Images' | translate }}</mat-label>
                                                <mat-select (selectionChange)="addImage($event)" name="image" required (infiniteScroll)="scrolledDownImages()" msInfiniteScroll> <!--Add formcontrol name here-->
                                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                                    <mat-form-field appearance="fill" class="w-100 disbld">
                                                        <input matInput #typeFilter disabled placeholder="Search">
                                                    </mat-form-field>
                                                    <div class="input-group-append pl-1" (click)="showImageModal()">
                                                        <span class="input-group-text"><i
                                                                class="icofont icofont icofont-plus"></i></span>
                                                    </div>
                                                </div>
                                                <div class="scroll_wrap" (scroll)="onScroll($event)">
                                                    <mat-option style="height: 0!important;"></mat-option>
                                                    <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
                                                        <div class="name" style="margin: 5px;">
                                                            <img style="height: 100px; width: 100%; object-fit: contain" [src]="mediaUrl+image.file" alt="image">
                                                        </div>
                                                        <div class="actions">
                                                            <i class="fa fa-trash" (click)="deleteImage(image?.id); $event.stopPropagation()"></i>
                                                        </div>
                                                    </mat-option>
                                                </div>
                                                </mat-select>
                                            </mat-form-field>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-3 mt-20">
                                
                                <!-- <div class="assigned-to-box">
                                    <span>{{ 'notes.assigned_to' | translate }}:</span>
                                    <div class="frame">
                                        <div class="frame-header">{{assignedTo && assignedTo.firstName}}</div>
                                           <div class="justify-content-center h-75 d-flex" (click)="isPerson = true">
                                                <img [src]="assignedTo.picture" alt="Assigned To picture" *ngIf="assignedTo">
                                               <em class="fa fa-plus f-20 align-self-center" *ngIf="!assignedTo"></em>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row ">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label>{{"notes.info" | translate}}</mat-label>
                                    <textarea matInput formControlName="info" name="info" cols="30" rows="10" class="text-background p-2"></textarea>
                                    <!-- <input matInput formControlName="info" name="info" placeholder="Info"> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row ">
                            <div class="col-12">
                                <div class="input-group justify-content-center">
                                    <mat-label> {{ 'notes.closed' | translate }} </mat-label>
                                    <mat-slide-toggle color="#34A59A" formControlName="active" name="active">{{ 'notes.open' | translate }}</mat-slide-toggle>
                                </div>
                            </div>
                            <!-- <div class="col-7">
                                <button class="btn btn-success"
                                    (click)="submitForm()">{{ 'notes.submit' | translate }}</button>
                            </div> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
<fap-modal #addNoteTypeModal
    [inputPrimaryButtonInterface]="addNoteTypeModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addNoteTypeModalButtonSecondaryInterface"
    inputTitle="Add/Edit note type">
    <form [formGroup]="notesTypeForm" class="col-12 p-0">
        <div class="form-group">
            <mat-form-field class="w-100" (click)="addEditTranslation()">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput>
                <mat-error *ngIf="notesTypeForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col_form-label">{{ 'Translation' }}</mat-label>
                <mat-select formControlName="name_t" name="name_t" [value]="note?.type.name_t">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #nFilter placeholder="Search">
                    </mat-form-field>
                    <em class="fa fa-plus center f-20 ml-2" (click)="showModal()"></em>
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let type of translatedNames | filter : nFilter.value" [value]="type.id">
                        <div class="name"><span>{{ getTranslation(type?.id) }}</span></div>
                        <div class="actions">
                            <i class="fa fa-edit" (click)="editTranslation(type) ;$event.stopPropagation()"></i>
                            <i class="fa fa-trash" (click)="deleteTranslation(type) ;$event.stopPropagation()"></i>
                        </div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
        </div> -->
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="notesTypeForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</fap-modal>

<fap-modal #addImageModal
[inputPrimaryButtonInterface]="addImageModalButtonPrimaryInterface"
[inputSecondaryButtonInterface]="addImageModalButtonSecondaryInterface"
inputTitle="Add Image">
<form [formGroup]="imageForm" class="col-12 p-0">
    <div class="form-group" 
    style="display: none">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Group' }}</mat-label>
            <input matInput formControlName="group" readonly value="notes">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Tags' }}</mat-label>
            <input matInput formControlName="tags" placeholder="Tags">
        </mat-form-field>
    </div>
    <div class="form-group">
        <label style="display: block;" class="upload-img-label">{{ 'Image' | translate }}</label>
        <input #fileUploader type="file" (change)="onFileInput($event)">
        <img *ngIf="imageSrc" class="preview-img d-block" [src]="imageSrc" alt="image">
    </div>
</form>
</fap-modal>

            <fap-attributes
                [entityRelation]="'note'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="noteId"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
            >
            </fap-attributes>

<div class="overlay" *ngIf="isPerson" (click)="isPerson=false"></div>
    <div class="popup" *ngIf="isPerson">
        <fap-people-card-list [persons]="personList" (closeModal)="closeModal($event)"></fap-people-card-list>
</div>

<fap-modal #addEditTranslationsModal [inputTitle]="'Type name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.noteType)" 
        [prefix]="prefix" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditNameTranslationsModal [inputTitle]="'Note name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.note)" 
        [prefix]="'NOTE_'" 
        [translation]="nameT"
        [group]="'notes'"
        (submitTranslation)="onAddUpdateNameTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>