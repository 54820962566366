<div
    *ngIf="widget"
    class="dashboard-card dash_cont"
    style="position: relative"
    id="parent"
>
    <div class="row">
        <div class="col-md-4">
            <h1>
                {{
                    widget.config["nameT"]
                        ? getTranslation(widget.config["nameT"])
                        : widget.config["name"]
                }}
            </h1>
        </div>

        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)">
                        <span>{{ "widget.settings" | translate }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div style="height: calc(100% - 30px); overflow-y: auto; overflow-x: hidden;" class="widget_area">
        <div class="weather_forecast">
            <div class="main_dates">
                <div class="box" *ngFor="let forecast of forecasts">
                    <div class="lot_info">
                        <h4>{{getLotName(forecast?.object_id)}}</h4>
                    </div>
                    <div class="main_info" *ngIf="forecast?.forecast">
                        <ng-container *ngFor="let item of getObjectValues(forecast?.forecast) | slice:0:1">
                            <div class="icon">
                                <img [src]="mediaUrl + item?.weather_code?.img" alt="">
                            </div>
                            <div class="info">
                                <div class="min_max_values">
                                    <div class="max_val">
                                        <span>{{ item.temperature.max }}</span> <span class="unit">{{ [unitTypes, item?.temperature?.unit] | getUnit }}</span>
                                    </div>
                                    <div class="min_val">
                                        <span>{{ item.temperature.min }}</span> <span class="unit">{{ [unitTypes, item?.temperature?.unit] | getUnit }}</span>
                                    </div>
                                </div>
                                <div class="humidity" *ngIf="item.humidity.value">
                                    <b>{{ item.humidity.value }} </b> <span class="unit">{{ [unitTypes, item?.temperature?.unit] | getUnit }}</span>
                                </div>
                                <div class="rain_and_wind">
                                    <div class="rain rw">
                                        <div class="icn">
                                            <img src="../../../../assets/icons/cloud.svg" alt="rain">
                                        </div>
                                        <div class="main_val">
                                            <span><b>{{ item.rainfall.value }}</b></span> <span class="unit">{{ [unitTypes, item?.rainfall?.unit] | getUnit }}</span>
                                        </div>
                                        <div class="last_col">
                                            {{ item.percent_rain.value }} <span class="unit">{{ [unitTypes, item?.percent_rain?.unit] | getUnit }}</span>
                                        </div>
                                    </div>
                                    <div class="wind rw">
                                        <div class="icn">
                                            <img src="../../../../assets/icons/wind.svg" alt="wind">
                                        </div>
                                        <div class="main_val">
                                            <span><b>{{ item.wind_speed.value }}</b></span> <span class="unit">{{ [unitTypes, item?.wind_speed?.unit] | getUnit }}</span>
                                        </div>
                                        <div class="last_col">
                                            {{ item.wind_dir.value }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="other_dates scrollable-container" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)" #container>
                <div class="scrollable-content">
                <div class="box" *ngFor="let forecast of forecasts">
                    <div class="days">
                        <ng-container *ngFor="let item of getObjectValues(forecast?.forecast) | slice:1">
                            <div class="day_container">
                                <div class="day">
                                    <h4>{{ getTranslation(item.day.name_t) }}</h4>
                                </div>
                                <div class="day_data">
                                    <div class="icon">
                                        <img [src]="mediaUrl + item?.weather_code?.img" alt="">
                                    </div>
                                    <div class="info">
                                        <div class="min_max_values">
                                            <div class="max_val">
                                                <span>{{ item.temperature.max }}</span> <span class="unit">{{ [unitTypes, item?.temperature?.unit] | getUnit }}</span>
                                            </div>
                                            <div class="min_val">
                                                <span>{{ item.temperature.min }}</span> <span class="unit">{{ [unitTypes, item?.temperature?.unit] | getUnit }}</span>
                                            </div>
                                        </div>
                                        <div class="humidity" *ngIf="item.humidity.value">
                                            <b>{{ item.humidity.value }} </b> <span class="unit">{{ [unitTypes, item?.temperature?.unit] | getUnit }}</span>
                                        </div>
                                        <div class="rain_and_wind">
                                            <div class="rain rw">
                                                <div class="main_val">
                                                    <span><b>{{ item.rainfall.value }}</b></span> <span class="unit">{{ [unitTypes, item?.rainfall?.unit] | getUnit }}</span>
                                                </div>
                                                <div class="last_col">
                                                    {{ item.percent_rain.value }} <span class="unit">{{ [unitTypes, item?.percent_rain?.unit] | getUnit }}</span>
                                                </div>
                                            </div>
                                            <div class="wind rw">
                                                <div class="main_val">
                                                    <span><b>{{ item.wind_speed.value }}</b></span> <span class="unit">{{ [unitTypes, item?.wind_speed?.unit] | getUnit }}</span>
                                                </div>
                                                <div class="last_col">
                                                    {{ item.wind_dir.value }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                </div>
                <div class="scrollable-indicator"></div>
            </div>
        </div>
    </div>
</div>
