import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { NoteModel } from '../../../core/models/notes/note.model';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { AttributeService } from '../../../core/services/api/attribute/attribute.service';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../core/services/api/company/company.service';
import {
    DeviceModel,
    ObjectModel,
    ObjectService,
} from '../../../core/services/api/objects/object.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { TypesService } from '../../../core/services/api/types/types.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { DataService } from '../../../core/services/websocket/data.service';
import { FapModalComponent } from '../../partials';
import { NavService } from '../../services/nav.service';
import { AgmGeocoder, MapsAPILoader } from '@agm/core';
import { MapService } from '../../layout/fap_main-map/service/map-service';
import { UtcToLocalPipe } from '../../../core/pipes/utcToLocal.pipe';
import { UserService } from '../../../core/services/api/user/user.service';

export const DATE_TIME_FORMAT = {
    parse: {
      dateInput: 'l, LTS',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
}

interface marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
    content: string;
    color?: string;
    iconUrl?: string;
  }
  
  interface coords {
    lat: number;
    lng: number;
  }

@Component({
    selector: 'fap-overview-widget',
    templateUrl: './fap-overview-widget.component.html',
    styleUrls: ['./fap-overview-widget.component.scss'],
    providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class FapOverviewWidgetComponent
    implements OnInit, OnDestroy, OnChanges
{
    @Input()
    public widget: WidgetModel;
    @Input() objects: ObjectModel[] = [];
    @Input() sensors: SensorModel[] = [];
    @Input() unitTypes: UnitTypeModel[] = [];

    public selectedWidget;

    @Input()
    public notes: NoteModel[];

    @Output()
    public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('addEditWidgetModal') addEditWidgetModal: FapModalComponent;
    @ViewChild('sensorInformationPopup')
    sensorInformationPopup: FapModalComponent;
    @ViewChild('automationPopup') automationPopup: FapModalComponent;
    @ViewChild('schedulerPopup') schedulerPopup: FapModalComponent;
    public lotName: string;

    public toggleMain = false;
    public toggleSub = false;
    public personList: any;
    public showMenu = true;
    public fromDate = new Date();
    public toDate = new Date();
    public agg = 'day';
    public dataSource: any[] = [];
    public finalSensorList = [];
    public sensorNames = [];
    public columns: any[] = [];
    public sensorIds = [];
    public units: [];
    public currentSensors: [];
    public subscriptions: Array<Subscription> = [];
    public mediaUrl = environment.mediaUrl;
    public translatedNames: any = [];
    public langString: string;
    @Input()
    public devices: Array<DeviceModel> = [];
    @ViewChild('addEditTranslationsPopup')
    public addEditTranslationsPopup: FapModalComponent;
    @ViewChild('addEditAutomationTranslationPopup')
    public addEditAutomationTranslationPopup: FapModalComponent;
    @ViewChild('addEditSchedulerTranslationPopup')
    public addEditSchedulerTranslationPopup: FapModalComponent;
    public automationFormData = null;
    public formType = null;
    public fields = null;
    public formFields = null;
    public autoFormFields = null;
    public currentSensor;
    public addEditSensorInformationForm: UntypedFormGroup;
    public addEditAutomationForm: UntypedFormGroup;
    public addEditSchedulerForm: UntypedFormGroup;
    public sensorInformation = null;
    public automations = [];
    public schedulers = [];
    public automation = null;
    public scheduler = null;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
    @ViewChild('nameInput2', { static: true }) nameInput2: ElementRef;
    public translation = null;
    public automationTranslation = null;
    public schedulerTranslation = null;
    public chips = [];
    public minDate = null;
    public maxDate = null;
    public automationForm = [];
    public sensorInformationForm = []
    checkboxControls: { [key: string]: UntypedFormControl } = {};
    public availableDays = [
        {id: '1', day: 'Sunday'},
        {id: '2', day: 'Monday'},
        {id: '3', day: 'Tuesday'},
        {id: '4', day: 'Wednesday'},
        {id: '5', day: 'Thursday'},
        {id: '6', day: 'Friday'},
        {id: '7', day: 'Saturday'},
    ]
    public days = [];
    apiCalled = false;
    @ViewChild('mapModal')
    public mapModal: FapModalComponent;
    public isLoading = false;
    zoom = 8;
    markers:Array<marker> = [];
    public searchControl: UntypedFormControl;
    public mapSearchToggle: boolean;
    public mapTypeControl: boolean;
    @ViewChild('search', {static: true}) public searchElementRef: ElementRef;
    @Output()
    public coords: EventEmitter<coords> = new EventEmitter();
    defaultCenter = {lat: 51.673858, lng: 7.815982};
    currentCenter = Object.assign({}, this.defaultCenter);
    private utcToLocalPipe: UtcToLocalPipe;
    private timezone

    constructor(
        private globalRegistry: GlobalRegistryService,
        public companyService: CompanyService,
        public navService: NavService,
        public sensorService: SensorService,
        public toastr: ToastrService,
        public translateService: TranslateService,
        public dataService: DataService,
        public objectService: ObjectService,
        public cacheService: CacheResolverService,
        public typesService: TypesService,
        public attributeService: AttributeService,
        public formBuilder: UntypedFormBuilder,
        public mapService: MapService,
        private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,
        private geocoder: AgmGeocoder,
        private userService: UserService
    ) {
        // this.utcToLocalPipe = new UtcToLocalPipe();
        this.subscriptions.push(this.userService.getCurrentUser.subscribe((user) => {
            this.timezone = user.profile.data.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
            // console.log(this.timezone);
        }));
    }

    public toggleImageMenu() {
        this.showMenu = !this.showMenu;
    }

    ngOnInit(): void {
        this.fromDate = new Date();
        this.toDate = new Date();
        this.agg = '';

        setTimeout(() => {
            this.drillData();
        }, 2000);

        this.subscriptions.push(
            this.sensorService.getName.subscribe((res) => {
                if (res) {
                    this.dataSource = [];
                    this.drillData();
                }
            })
        );
        this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.initAddEditSensorInformationForm();
        this.initAddEditAutomationForm();
        this.initAddEditSchedulerForm();

        this.searchControl = new UntypedFormControl();

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.currentCenter.lat = place.geometry.location.lat();
          this.currentCenter.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
    }

    showModal(widget): void {
        this.selectedWidget = widget;
        this.addEditWidgetModal.showModal();
    }

    public getLotName(lotId: number = this.widget.objectId) {
        if(lotId != 0) {
        this.lotName = this.globalRegistry.systemData.lots.find(
            (lot) => lot.id === lotId
        ).name;
        }
    }

    hideMain() {
        this.toggleMain = false;
    }

    toggleMenu() {
        this.toggleMain = !this.toggleMain;
    }

    public drillData() {
        this.isLoading = true;
        // console.log(this.widget);
        this.dataSource = [];
        const sensors: Array<SensorModel> = this.widget.config['settings'][
            'options'
        ].map((sensor: { sensor: SensorModel }) => sensor?.sensor);
        const sensorIds = sensors.map((sensor) => sensor.id);
        this.subscriptions.push(
            this.dataService
                .getLastData(sensorIds.toString())
                .subscribe((data) => {
                    console.log(data);
                    const objects = Object.values(this.objects);
                    const unitTypes = Object.values(this.unitTypes);
                    const sensors = Object.values(this.sensors);
                    const devices = Object.values(this.devices);
                    this.widget.config['settings']['options'] =
                        this.widget.config['settings']['options'].map(
                            (item) => {
                                return {
                                    ...item,
                                    unitTypes: unitTypes.filter(
                                        (el) => el.id === item.sensor.unitType
                                    ),
                                    objects: objects.filter(
                                        (el) => el.id === item.sensor.object_id
                                    ),
                                    currentSensor: sensors.filter(
                                        (el) => el.id === item.sensor.id
                                    ),
                                    devices: devices.filter(
                                        (el) => el.id === item.sensor.device
                                    ),
                                };
                            }
                        );
                    this.sensorNames = [];
                    this.widget.config['settings']['options'].forEach(
                        (item) => {
                            const nameT = item.sensor.nameT
                                ? this.getTranslation(item.sensor.nameT)
                                : '';
                            let extraText = item.objects[0].nameT
                                ? this.getTranslation(item.objects[0].nameT)
                                : null;
                            if (!extraText) {
                                extraText = item.objects[0].extraText
                                    ? item.objects[0].extraText
                                    : '';
                            }
                            const unit = item.unitTypes[0].nameT
                                ? this.getTranslation(item.unitTypes[0].nameT)
                                : item.unitTypes[0].name;
                            if(item.devices.length) {
                            const name = nameT ? nameT : extraText + ' ' + unit;
                            this.sensorNames.push(name);
                            } else {
                                const name = nameT ? nameT : extraText + ' ' + unit;
                                this.sensorNames.push(name);
                            }
                        }
                    );
                    // console.log(this.sensorNames);
                    this.currentSensors = this.widget.config['settings'][
                        'options'
                    ].map((item) => item.currentSensor);
                    // console.log(this.currentSensors);
                    this.units = this.widget.config['settings']['options'].map(
                        (item) => `${item.unitTypes[0].unit}`
                    );

                    this.columns = [...this.sensorNames];

                    this.widget.config['settings']['options'].forEach(
                        (element) => {
                            element.date = null;
                            element.name = null;
                            element.timestamp = null;
                            element.unit = null;
                            element.value_coord = null;
                            element.value_float = null;
                            element.value_string = null;
                        }
                    );
                    // console.log(data);
                    // console.log(this.widget.config['settings']['options']);
                    data.map((itm) => ({
                        ...itm,
                        ...this.widget.config['settings']['options'].find(
                            (elm) => elm.currentSensor.id == itm.sensor
                        ),
                    }));
                    // console.log(data);

                    const arr3 = [];

                    for (
                        let i = 0;
                        i < this.widget.config['settings']['options'].length;
                        i++
                    ) {
                        arr3.push({
                            ...this.widget.config['settings']['options'][i],
                            ...data.find(
                                (itmInner) =>
                                    itmInner.sensor ===
                                    this.widget.config['settings']['options'][i]
                                        .currentSensor.id
                            ),
                        });
                    }

                    // console.log(arr3);
                    this.dataSource = arr3;
                    // console.log(this.dataSource);
                    
                    this.dataSource.forEach((e, i) => {
                        e.name = this.columns[i];
                        e.unit = this.units[i];
                        e.currentSensor = this.currentSensors[i];
                    });

                    // this.dataSource.forEach(element => {
                    //   element.name = element.name.replace(/null/g, "");
                    // });

                    // console.log(this.dataSource);

                    this.dataSource.forEach(sensor2 => {
                        // Find the corresponding sensor in sensorData based on ID
                        const correspondingSensor = data.find(sensor1 => sensor1.sensor === sensor2.sensor.id);
                    
                        if (correspondingSensor) {
                            // Update the values in sensor2 with the values from correspondingSensor
                            sensor2.value_float = correspondingSensor.value_float;
                            sensor2.value_coord = correspondingSensor.value_coord;
                            sensor2.timestamp = correspondingSensor.timestamp;
                        }
                    });
                    // console.log(this.dataSource);
                    this.isLoading = false;
                    this.dataSource.forEach((element) => {
                        if (element.timestamp != null) {
                            moment.locale('en', {
                                relativeTime: {
                                    future: 'in %s',
                                    past: '%s ago',
                                    s: '3s',
                                    ss: '%ss',
                                    m: '1m',
                                    mm: '%dm',
                                    h: '1h',
                                    hh: '%dh',
                                    d: 'a day',
                                    dd: '%dd',
                                    M: '1M',
                                    MM: '%dM',
                                    y: '1y',
                                    yy: '%dy',
                                },
                            });
                            // console.log(this.utcToLocalPipe.transform(element.timestamp, 'IN'));
                            const dstring = moment.utc(element.timestamp).local();
                            element.date = moment(dstring).fromNow();
                        }
                    });
                })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
            this.getLotName(this.widget.objectId);
        }
    }

    maxValueChanged(ev) {
        console.log(ev);
    }

    minValueChanged(ev) {
        console.log(ev);
    }

    public getform(id) {
        this.automationForm = [];
        const url = this.attributeService.getUrl('form/');
        this.cacheService.delete(url);
        this.attributeService.getForm(id).subscribe(data => {
        //   console.log(data);
          this.automationFormData = data.body.results;
          const formTypeFields = this.automationFormData.fields
                const itemIds = formTypeFields.map((item) => item.type);
                this.autoFormFields = this.globalRegistry.systemData.attributes.filter((item) =>
                    itemIds.includes(item.id)
            );
            formTypeFields.forEach(field => {
            this.autoFormFields.forEach(attr => {
                if(field.type === attr.id) {
                    // console.log(attr);
                    // console.log(field);
                    this.automationForm.push(field);
                    this.addEditAutomationForm.addControl(field.id, new UntypedFormControl(field.value.input, Validators.required))
                    // console.log(this.addEditAutomationForm.value);
                }
            })
        })
        })
      }

    public getFormTypes(item) {
        this.sensorInformationForm = [];
        const filters = {
            entity: 'sensor',
            id: item.currentSensor.id,
            type: item.currentSensor.type.form_type,
        };
        this.currentSensor = item.currentSensor;
        const url = this.attributeService.getUrl('form/');
        this.sensorInformationPopup.showModal();
        this.cacheService.delete(
            url +
                'entity=sensor' +
                '&id=' +
                item.currentSensor.id +
                '&type=' +
                item.currentSensor.type.form_type
        );
        this.attributeService
            .getAttributeForm(filters)
            .subscribe((response) => {
                // console.log(response);
                this.formType = response.body.results[0];
                const formTypeFields = this.formType.fields
                const itemIds = this.formType.fields.map((item) => item.type);
                this.formFields = this.globalRegistry.systemData.attributes.filter((item) =>
                    itemIds.includes(item.id)
                );
                // console.log(this.formFields);
                this.initAddEditSensorInformationForm();
                formTypeFields.forEach(field => {
                    this.formFields.forEach(attr => {
                        if(field.type === attr.id) {
                            // console.log(attr);
                            // console.log(field);
                            this.sensorInformationForm.push(field);
                            this.addEditSensorInformationForm.addControl(field.id, new UntypedFormControl(field.value.input, Validators.required))
                            console.log(this.addEditSensorInformationForm.value);
                        }
                    })
                });
                this.getAutomation();
            });
    }

    toggleValue(val) {
        console.log(val);
    }

    addEditTranslation() {
        const name = this.nameInput.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.currentSensor);
        if (name === null || name === '' || type.nameT === null) {
            this.translation = null;
            this.addEditTranslationsPopup.showModal();
        } else {
            this.globalRegistry.systemData.translations.forEach(
                (translation) => {
                    if (translation.id === type.nameT) {
                        this.translation = translation;
                        console.log(this.translation);
                        this.addEditTranslationsPopup.showModal();
                    }
                }
            );
        }
    }

    addEditAutomationTranslation() {
        const name = this.nameInput1.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.automation);
        if (name === null || name === '' || type.nameT === null) {
            this.automationTranslation = null;
            this.addEditAutomationTranslationPopup.showModal();
        } else {
            this.globalRegistry.systemData.translations.forEach(
                (translation) => {
                    if (translation.id === type.nameT) {
                        this.automationTranslation = translation;
                        console.log(this.automationTranslation);
                        this.addEditAutomationTranslationPopup.showModal();
                    }
                }
            );
        }
    }

    addEditSchedulerTranslation() {
        const name = this.nameInput2.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.scheduler);
        if (name === null || name === '' || type.nameT === null) {
            this.schedulerTranslation = null;
            this.addEditSchedulerTranslationPopup.showModal();
        } else {
            this.globalRegistry.systemData.translations.forEach(
                (translation) => {
                    if (translation.id === type.nameT) {
                        this.schedulerTranslation = translation;
                        console.log(this.schedulerTranslation);
                        this.addEditSchedulerTranslationPopup.showModal();
                    }
                }
            );
        }
    }

    convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
            const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) =>
                p1.toUpperCase()
            );
            camelCaseObj[camelCaseKey] =
                obj[key] instanceof Object
                    ? this.convertKeysToCamelCase(obj[key])
                    : obj[key];
        }
        return camelCaseObj;
    }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.addEditSensorInformationForm.controls['name_t'].setValue(
            translation.id
        );
        this.addEditSensorInformationForm.controls['name'].setValue(
            translation[this.langString]
        );
        this.translation = translation;
        this.addEditTranslationsPopup.hideModal();
    }

    onAddUpdateAutomationTranslation(translation) {
        console.log(translation);
        this.automationTranslation = translation;
        this.addEditAutomationForm.controls['name_t'].setValue(
            translation.id
        );
        this.addEditAutomationForm.controls['name'].setValue(
            translation[this.langString]
        );
        this.automationTranslation = translation;
        this.addEditAutomationTranslationPopup.hideModal();
    }

    onAddUpdateSchedulerTranslation(translation) {
        console.log(translation);
        this.schedulerTranslation = translation;
        this.addEditSchedulerForm.controls['name_t'].setValue(
            translation.id
        );
        this.addEditSchedulerForm.controls['name'].setValue(
            translation[this.langString]
        );
        this.schedulerTranslation = translation;
        this.addEditSchedulerTranslationPopup.hideModal();
    }

    submitForm() {
        console.log(this.addEditSensorInformationForm.value);
        const excludedTriggerIds = this.automations.filter(automation => !this.addEditSensorInformationForm.value.automation.includes(automation.id))
            .map(automation => automation.id);
            this.automations = this.automations.filter(automation => !this.addEditSensorInformationForm.value.automation.includes(automation.id));
        const { ...clonedObj } = this.addEditSensorInformationForm.value;
        // console.log(clonedObj);
        // console.log(this.sensorInformationForm);
        for (const key in clonedObj) {
            this.sensorInformationForm.forEach(item => {
                if(String(item.id) === key) {
                    // console.log(item);
                    item.value.input = clonedObj[key]
                    this.attributeService.patchField(item.id, item).subscribe(data => {
                        console.log(data);
                        this.sensorInformationPopup.hideModal();
                    })
                }
            });
          }
        const body = {
            name_t: this.translation ? this.translation.id : '',
        }
        this.sensorService.updateSensorName(this.currentSensor.id, body).subscribe(data => {
            // console.log(data);
            this.currentSensor = data.model
        })

        excludedTriggerIds.forEach(id => {
            this.sensorService.deleteTrigger(id).subscribe();
        });
    }

    editAutomation(automation) {
        // console.log(automation);
        this.automation = automation;
        this.automationPopup.showModal();
        this.initAddEditAutomationForm();
        this.getform(automation.form);
    }
    editScheduler(scheduler) {
        // console.log(scheduler);
        this.scheduler = scheduler;
        this.days = this.scheduler.day;
        this.scheduler.hour = this.scheduler.hour ? typeof this.scheduler.hour === 'string' ? this.scheduler.hour.split(',') : this.scheduler.hour : ''
        this.schedulerPopup.showModal();
        this.initAddEditSchedulerForm();
    }

    deleteAutomation(id) {
        this.sensorService.deleteTrigger(id).subscribe(()=> {
            this.automationPopup.hideModal();
            this.getAutomation();
        }, (error) => {
            this.toastr.error(error.error.results.error);
        });
    }

    deleteScheduler(id) {
        this.sensorService.deleteScheduler(id).subscribe(() => {
            this.schedulerPopup.hideModal();
            this.getSchedulers();
        }, (error) => {
            this.toastr.error(error.error.results.error)
        })
    }

    submitAutomation() {
        console.log(this.addEditAutomationForm.value);
        const { ...clonedObj } = this.addEditAutomationForm.value;
        // console.log(clonedObj);
        // console.log(this.automationForm);
        for (const key in clonedObj) {
            this.automationForm.forEach(item => {
                if(String(item.id) === key) {
                    // console.log(item);
                    item.value.input = clonedObj[key]
                    this.attributeService.patchField(item.id, item).subscribe(() => {
                        // console.log(data);
                        this.automationPopup.hideModal();
                    })
                }
            });
          }
        const body = {
            name_t: this.automationTranslation ? this.automationTranslation.id : '',
            scheduler: this.addEditAutomationForm.value.scheduler
        }
        // console.log(this.automation);
        if(this.automation) {
            this.sensorService.updateTrigger(this.automation.id, body).subscribe(() => {
                // console.log(data);
                this.getAutomation();
            })
        }
    }

    submitScheduler() {
        const selectedDays = Object.keys(this.addEditSchedulerForm.value.day)
        .filter((dayId) => this.addEditSchedulerForm.value.day[dayId])
        .map((dayId) => parseInt(dayId, 10));
        const body = {
            name_t : this.schedulerTranslation ? this.schedulerTranslation.id : '',
            start: moment(this.addEditSchedulerForm.value.from).format('YYYY-MM-DD'),
            end: moment(this.addEditSchedulerForm.value.to).format('YYYY-MM-DD'),
            day: selectedDays.toString(),
            hour: this.addEditSchedulerForm.value.hour.toString(),
            minute: this.addEditSchedulerForm.value.minute,
            duration: this.addEditSchedulerForm.value.duration,
            dweek: "*",
            month: "*"
        }
        // console.log(body);
        if(this.scheduler) {
            this.sensorService.updateScheduler(this.scheduler.id, body).subscribe(() => {
                // console.log(data);
                this.getSchedulers();
                this.schedulerPopup.hideModal();
            })
        } else {
            this.sensorService.createScheduler(body).subscribe(() => {
                // console.log(data);
                this.getSchedulers();
                this.schedulerPopup.hideModal();
            })
        }
    }

    getTranslation(translation) {
        const t = this.globalRegistry.systemData.translations.filter((trans) => {
            return trans.id === translation;
        });
        if (t[0]) {
            if (t[0][this.langString] != '') {
                return t[0][this.langString];
            } else {
                return;
            }
        } else {
            return translation;
        }
    }

    initAddEditSensorInformationForm() {
        // console.log(this.currentSensor);
        
        this.addEditSensorInformationForm = this.formBuilder.group({
            name: [
                this.currentSensor ? this.currentSensor.name : '',
                Validators.required,
            ],
            name_t: [
                this.currentSensor ? this.currentSensor.nameT : '',
                Validators.required,
            ],
            device: [
                this.currentSensor ? this.currentSensor.device : '',
                Validators.required,
            ],
            automation: [null],
        });
        if(this.currentSensor && this.currentSensor.nameT) {
            this.globalRegistry.systemData.translations.forEach(
                (translation) => {
                    if (translation.id === this.currentSensor.nameT) {
                        this.translation = translation;
                    }
                }
            );
            this.addEditSensorInformationForm.get('name').setValue(this.getTranslation(this.currentSensor.nameT))
        }
    }

    removeItem(id) {
        this.chips = this.chips.filter(item => item !== id);
        this.addEditSensorInformationForm.get('automation').setValue(this.chips)
      }

    filterAutomation(keyword) {
        console.log(keyword)
    }

    getAutomation() {
        const param = {sensor: this.currentSensor.id}
        const url = this.sensorService.getUrl('trigger/');
        this.cacheService.delete(url+'sensor='+this.currentSensor.id)
        if (this.currentSensor) {
            this.sensorService
                .getTriggers(param)
                .subscribe((data) => {
                    console.log(data);
                    this.getSchedulers()
                    this.automations = data.body.results;
                    const itemIds = this.automations.map((item) => item.id)
                    this.addEditSensorInformationForm.get('automation').setValue(itemIds)
                    this.chips = itemIds;
                });
        }
    }

    validateAutomationPopup() {
        // console.log(this.currentSensor);
        this.sensorService.postTrigger({sensor: this.currentSensor.id}).subscribe(() => {
            // console.log(data);
            this.getAutomation();
        })
    }

    getSchedulers() {
        const url = this.sensorService.getUrl('scheduler/');
        this.cacheService.delete(url);
        if (this.currentSensor) {
            this.sensorService
                .getSchedulers()
                .subscribe((data) => {
                    // console.log(data);
                    this.schedulers = data.body.results;
                });
        }
    }

    updatechips(value) {
        this.chips = value;
    }

    initAddEditAutomationForm() {
        this.addEditAutomationForm = this.formBuilder.group({
            name: [
                this.automation ? this.automation.name : '',
                Validators.required,
            ],
            name_t: [
                this.automation ? this.automation.name_t : '',
                Validators.required,
            ],
            scheduler: [
                this.automation ? this.automation.scheduler : '',
                Validators.required,
            ]
        });
        if(this.automation && this.automation.name_t) {
            this.globalRegistry.systemData.translations.forEach(
                (translation) => {
                    if (translation.id === this.automation.name_t) {
                        this.automationTranslation = translation;
                    }
                }
            );
            this.addEditAutomationForm.get('name').setValue(this.getTranslation(this.automation.name_t))
        }
    }

    initAddEditSchedulerForm() {
        this.availableDays.forEach((day) => {
            const control = new UntypedFormControl(this.days.includes(day.id));
            this.checkboxControls[day.id] = control;
          });
        this.addEditSchedulerForm = this.formBuilder.group({
            name: [
                this.scheduler ? this.scheduler.name : '',
                Validators.required,
            ],
            name_t: [
                this.scheduler ? this.scheduler.name_t : '',
                Validators.required,
            ],
            from: [
                this.scheduler ? this.scheduler.start : new Date(),
                Validators.required,
            ],
            to: [
                this.scheduler ? this.scheduler.end : new Date(),
                Validators.required,
            ],
            day: new UntypedFormGroup(this.checkboxControls),
            hour: [
                this.scheduler ? this.scheduler.hour : [],
                Validators.required,
            ],
            minute: [
                this.scheduler ? this.scheduler.minute : '',
                Validators.required,
            ],
            duration: [
                this.scheduler ? this.scheduler.duration : '',
                Validators.required,
            ]
        });
        if(this.scheduler && this.scheduler.name_t) {
            this.globalRegistry.systemData.translations.forEach(
                (translation) => {
                    if (translation.id === this.scheduler.name_t) {
                        this.schedulerTranslation = translation;
                    }
                }
            );
            this.addEditSchedulerForm.get('name').setValue(this.getTranslation(this.scheduler.name_t))
        }
    }

    getCheckboxControl(dayId: string): UntypedFormControl {
        return this.checkboxControls[dayId];
      }
    
    // public initGraphConfigModalButtons(): void {
    //     const _this: FapOverviewWidgetComponent = this;
      
    //     this.graphConfigModalButtonPrimaryInterface = {
    //         clickFunction(): void {
    //             _this.submitGraphConfig();
    //         },
    //         text: this.translateService.instant('save')
    //     };
      
    //     this.graphConfigModalButtonSecondaryInterface = {
    //         clickFunction(): void {
    //             _this.graphConfigModal.hideModal();
    //         },
    //         text: this.translateService.instant('cancel')
    //     };
    // }
    
    displayCoord(info) {
        this.mapModal.showModal();
        // console.log(info);
        const lng = info.coordinates[0];
        const lat = info.coordinates[1];
        this.markers = [];
        this.markers.push({
          lat: lat,
          lng: lng,
          draggable: false,
          content: lat +', '+ lng,
          iconUrl: this.setMarkerIconColor('red')
        });
        this.coords.emit({lat, lng});
        this.currentCenter.lat = lat;
        this.currentCenter.lng = lng;
    }

    public setMarkerIconColor(markerColor: string): string {
        return (
            'http://maps.google.com/mapfiles/ms/icons/' +
            markerColor +
            '-dot.png'
        );
    }

    mapClicked($event) {
        console.log($event)
      }

      mapReady(event) {
        console.log(event);
        this.setCurrentPosition();
      }

      private setCurrentPosition() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.currentCenter.lat = position.coords.latitude;
            this.currentCenter.lng = position.coords.longitude;
            this.zoom = 15;
          });
        }
      }
    
      markerDragEnd(m: marker, $event) {
        console.log("dragEnd", m, $event);
      }

      getTitleContent(input: string) {
        return input.replace(/<[^>]*>/g, ' ');
      }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }
}
