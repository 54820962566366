import { AttributesComponent } from './attributes/attributes.component';
import { atributesRoutes } from './attributes/attributes.routing';
import {
    Routes,
    RouterModule
} from '@angular/router';

// import { AuthGuard } from '../core/guard/auth.guard';
import { FapLoginComponent } from './auth-views/fap_views/fap_login/fap_login.component';
import { HomeComponent } from './home/home.component';
import { homeRoutes } from './home/home.routing';
import { CropsComponent } from './crops/crops.component';
import { cropsRoutes } from './crops/crops.routing';
import { PeopleComponent } from './people/people.component';
import { peopleRoutes } from './people/people.routing';
import { stockRoutes } from './stock/stock.routing';
import { treatmentRoutes } from './treatments/treatments.routing';
import { EquipmentsComponent } from './equipments/equipments.component';
import { equipmentsRoutes } from './equipments/equipments.routing';
import { WeedsComponent } from './weeds/weeds.component';
import { weedsRoutes } from './weeds/weeds.routing';
import { FarmComponent } from './farm/farm.component';
import { farmRoutes } from './farm/farm.routing';
import { InsectsComponent } from './insects/insects.component';
import { insectsRoutes } from './insects/insects.routing';
import { PathogensComponent } from './pathogens/pathogens.component';
import { pathogensRoutes } from './pathogens/pathogens.routing';
import { BaseComponent } from '../../shared/layout/base/base.component';
import { CanActivateAuthGuard } from '../../core/guard/can-activate-auth.guards';
import { BasePermissionsGuard } from '../../core/guard/base-permissions.guard';
import { lotRoutes } from './lot/lot.routing';
import { LotComponent } from './lot/lot.component';
import { FapRegisterComponent } from './auth-views/fap_views/fap_register/fap_register.component';
import { MediaContentResolver } from './auth-views/resolver/media-content.resolver';
import { FapConfirmAccountComponent } from './auth-views/fap_views/fap_confirm-account/fap_confirm-account.component';
import { FapCreateCompanyComponent } from './auth-views/fap_views/fap_create-company/fap_create-company.component';
import { FapBillingPlanComponent } from './auth-views/fap_views/fap_billing-plan/fap_billing-plan.component';
import { CompanyComponent } from './company/company.component';
import { companyRoutes } from './company/company.routing';
import { FieldComponent } from './field/field.component';
import { fieldRoutes } from './field/field.routing';
import { UserComponent } from './user/user.component';
import { userRoutes } from './user/user.routing';
import { ActivityComponent } from './activity/activity.component';
import { activityRoutes } from './activity/activity.routing';
import { UnitsComponent } from './units/units.component';
import { unitsRoutes } from './units/units.routing';
import { NotesComponent } from './notes/notes.component';
import { notesRoutes } from './notes/notes.routing';
import { PartyComponent } from './party/party.component';
import { partyRoutes } from './party/party.routing';
import { StockComponent } from './stock/stock.component';
import { PrecisionComponent } from './precision/precision.component';
import { precisionRoutes } from './precision/precision.routing';
import { ActivitiesComponent } from './activities/activities.component';
import { activitiesRoutes } from './activities/activities.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { dashboardRoutes } from './dashboard/dashboard.routing';
import { AccessGuard } from '../../core/guard/access.guard';
import { FapOverviewComponent } from './fap-overview/fap-overview.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { schedulerRoutes } from './scheduler/scheduler.routing';
import { TreatmentsComponent } from './treatments/treatments.component';
import { PostsComponent } from './posts/posts.component';
import { postsRoutes } from './posts/posts.routing';
import { ReportsComponent } from './reports/reports.component';
import { reportsRoutes } from './reports/reports.routing';
import { MyProductsComponent } from './my-products/my-products.component';
import { myProductsRoutes } from './my-products/my-products.routing';
import { DevicesComponent } from './devices/devices.component';
import { deivcesRoutes } from './devices/devices.routing';
import { PlanComponent } from './plan/plan.component';
import { planRoutes } from './plan/plan.routing';
import { SimulationsComponent } from './simulations/simulations.component';
import { simulationsRoutes } from './simulations/simulations.routing';
import { PoolsComponent } from './pools/pools.component';
import { poolsRoutes } from './pools/pools.routing';
import { ParticlesComponent } from './alergotel/particles/particles.component';
import { RecommendationsComponent } from './alergotel/recommendations/recommendations.component';
import { AllergyProfileComponent } from './alergotel/allergy-profile/allergy-profile.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { AlergotelReportsComponent } from './alergotel/reports/reports.component';
import { AlergotelReportDetailComponent } from './alergotel/reports/report-detail/report-detail.component';
import { ValidateReportComponent } from './alergotel/reports/validate-report/validate-report.component';
import { AddReportComponent } from './alergotel/reports/add-report/add-report.component';
import { FapResetPasswordComponent } from './auth-views/fap_views/fap-reset-password/fap-reset-password.component';
import { formRoutes } from './forms/forms.routing';
import { AuthorizeComponent } from './authorize/authorize.component';
import { WorkflowsComponent } from './workflows/workflows.component';
import { workflowRoutes } from './workflows/workflows.routing';


const pagesRoutes: Routes = [
    {
        path: 'login',
        component: FapLoginComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'overview',
        component: FapOverviewComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'reset_password',
        component: FapResetPasswordComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'alergotel/bio-particles',
        component: ParticlesComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'alergotel/recommendations',
        component: RecommendationsComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'request-delete-account',
        component: DeleteAccountComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'confirm_delete/:confirmationKey',
        component: DeleteAccountComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'alergotel/alergic-profile',
        component: AllergyProfileComponent,
        resolve: {
            mediaContent: MediaContentResolver
        },
        canActivate: [CanActivateAuthGuard, BasePermissionsGuard]
    },
    {
        path: 'alergotel/reports',
        component: AlergotelReportsComponent,
        resolve: {
            mediaContent: MediaContentResolver
        },
        canActivate: [CanActivateAuthGuard, BasePermissionsGuard],
    },
    {
        path: 'alergotel/add-report',
        component: AddReportComponent,
        resolve: {
            mediaContent: MediaContentResolver
        },
        canActivate: [CanActivateAuthGuard, BasePermissionsGuard],
    },
    {
        path: 'alergotel/reports/:reportId',
        component: AlergotelReportDetailComponent,
        resolve: {
            mediaContent: MediaContentResolver
        },
        canActivate: [CanActivateAuthGuard, BasePermissionsGuard],
    },
    {
        path: 'alergotel/validate-report/:reportId',
        component: ValidateReportComponent,
        resolve: {
            mediaContent: MediaContentResolver
        },
        canActivate: [CanActivateAuthGuard, BasePermissionsGuard],
    },
    {
        path: 'alergotel/close-report/:reportId',
        component: ValidateReportComponent,
        resolve: {
            mediaContent: MediaContentResolver
        },
        canActivate: [CanActivateAuthGuard, BasePermissionsGuard],
    },
    {
        path: 'confirm_account/:confirmationKey',
        component: FapConfirmAccountComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'change_password/:confirmationKey',
        component: FapConfirmAccountComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'register',
        component: FapRegisterComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'create-company',
        component: FapCreateCompanyComponent,
        resolve: {
            mediaContent: MediaContentResolver
        },
        canActivate: [CanActivateAuthGuard]
    },
    {
        path: 'billing-plan',
        component: FapBillingPlanComponent,
        resolve: {
            mediaContent: MediaContentResolver
        }
    },
    {
        path: 'authorize',
        component: AuthorizeComponent
    },
    {
        path: 'pages',
        component: BaseComponent,
        canActivate: [CanActivateAuthGuard, BasePermissionsGuard],
        children: [
            {
                path: '',
                redirectTo: 'posts',
                pathMatch: 'full'
            },
            {
                path: 'home',
                data: {
                    filters: {
                        hasFarmFilter:true,
                        hasDashboardFilter:true
                    }
                },
                component: DashboardComponent,
                children: dashboardRoutes
            },
            {
                path: 'sample',
                data: {
                    filters: {
                        hasDashboardFilter:true
                    }
                },
                component: HomeComponent,
                children: homeRoutes
            },
            {
                path: 'simulations',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasLotFilter: true,
                        hasSimulationsFilter:true
                    },
                    key: "simulations",
                    index: 0
                },
                component: SimulationsComponent,
                children: simulationsRoutes,
                canActivate: [AccessGuard],
            },
            {
                path: 'lots',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasLotFilter: true
                    },
                    key: "lots",
                    index: 0
                },
                component: CropsComponent,
                children: cropsRoutes,
                canActivate: [AccessGuard],
            },
            {
                path: 'people',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasGroupFilter: true,
                        hasPeopleFilter:true
                    },
                    key:"people",
                    index:0
                },
                component: PeopleComponent,
                children: peopleRoutes,
                canActivate: [AccessGuard],
            },
            {
                path: 'stock',
                data: {
                    cache: true,
                    filters: {
                        hasFarmFilter: true
                    }
                },
                component: StockComponent,
                children: stockRoutes,
                canActivate: [AccessGuard],
            },
            {
                path: 'diseases',
                data: {
                    filters: {
                        hasFarmFilter: true
                    }
                },
                component: TreatmentsComponent,
                children: treatmentRoutes,
                canActivate: [AccessGuard],
            },
            {
                path: 'equipments',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasEquipmentFilter: true,
                        hasSearchFilter: true
                    },
                    key: 'equipments',
                    index: 0
                },
                component: EquipmentsComponent,
                children: equipmentsRoutes,
                canActivate:[AccessGuard]
            },
            {
                path: 'activities',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasActivityFilter: true,
                        hasSearchFilter: true
                    },
                    key: 'activities',
                    index: 0
                },
                component: ActivitiesComponent,
                children: activitiesRoutes,
                canActivate:[AccessGuard],
            },
            {
                path: 'weeds',
                component: WeedsComponent,
                children: weedsRoutes,
                canActivate:[AccessGuard],
            },
            {
                path: 'insects',
                component: InsectsComponent,
                children: insectsRoutes,
                canActivate:[AccessGuard],
            },
            {
                path: 'pathogens',
                component: PathogensComponent,
                children: pathogensRoutes,
                canActivate:[AccessGuard],
            },
            {
                path: 'farm',
                component: FarmComponent,
                children: farmRoutes
            },
            {
                path: 'lot',
                component: LotComponent,
                children: lotRoutes
            },
            {
                path: 'company',
                component: CompanyComponent,
                children: companyRoutes
            },
            {
                path: 'fields',
                component: FieldComponent,
                children: fieldRoutes,
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasLotFilter: true
                    }
                },
            },
            {
                path: 'user-profile',
                component: UserComponent,
                children: userRoutes
            },
            {
                path: 'activity',
                component: ActivityComponent,
                children: activityRoutes
            },
            {
                path: 'units',
                component: UnitsComponent,
                children: unitsRoutes
            },
            {
                path: 'party',
                component: PartyComponent,
                children: partyRoutes
            },
            {
                path: 'notes',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasNotesFilter:true
                    }
                },
                component: NotesComponent,
                children: notesRoutes
            },
            {
                path: 'scheduler',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasSchedulerFilter:true
                    }
                },
                component: SchedulerComponent,
                children: schedulerRoutes
            },
            {
                path: 'reports',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasReportsFilter:true,
                    }
                },
                component: ReportsComponent,
                children: reportsRoutes
            },
            {
                path: 'my-products',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasMyProductsFilter:true,
                    }
                },
                component: MyProductsComponent,
                children: myProductsRoutes
            },
            {
                path: 'devices',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasDevicesFilter:true,
                    }
                },
                component: DevicesComponent,
                children: deivcesRoutes
            },
            {
                path: 'pools',
                data: {
                    filters: {
                        hasFarmFilter: false,
                        hasPoolsFilter:true,
                    }
                },
                component: PoolsComponent,
                children: poolsRoutes
            },
            {
                path: 'plan',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasPlanFilter:true,
                    }
                },
                component: PlanComponent,
                children: planRoutes
            },
            {
                path: 'posts',
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasPostsFilter:true,
                        hasDashboardFilter:true
                    }
                },
                component: PostsComponent,
                children: postsRoutes
            },
            {
                path: 'analytics',
                component: NotesComponent,
                children: notesRoutes
            },
            {
                path: 'attributes',
                component: AttributesComponent,
                children: atributesRoutes
            },
            {
                path: 'precision',
                data: {
                    filters: {
                        hasSingleFarmFilter: true,
                        hasSingleLotFilter: true,
                        hasDateFilters: true
                    }
                },
                component: PrecisionComponent,
                children: precisionRoutes
            },
            {
                path: 'alergotel_bio-particles',
                component: ParticlesComponent,
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'alergotel_recommendations',
                component: RecommendationsComponent,
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'alergotel_alergic-profile',
                component: AllergyProfileComponent,
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'alergotel_reports',
                component: AlergotelReportsComponent,
                data: {
                    filters: {
                        hasFarmFilter: true,
                        hasAlergotelReportsFilter:true,
                        hasDashboardFilter:true
                    }
                },
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'alergotel_add-report',
                component: AddReportComponent,
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'alergotel_reports/:reportId',
                component: AlergotelReportDetailComponent,
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'alergotel_validate-report/:reportId',
                component: ValidateReportComponent,
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'alergotel_close-report/:reportId',
                component: ValidateReportComponent,
                resolve: {
                    mediaContent: MediaContentResolver
                }
            },
            {
                path: 'forms',
                component: NotesComponent,
                children: formRoutes
            },
            {
                path: 'workflows',
                // data: {
                //     filters: {
                //         hasFarmFilter: true,
                //         hasMyProductsFilter:true,
                //     }
                // },
                component: WorkflowsComponent,
                children: workflowRoutes
            },
        ]
    }
];

export const viewsRouting: any = RouterModule.forChild(pagesRoutes);
