import { IngredientTypeModel } from '../../../../core/models/type/ingredient-type.model';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { FapModalComponent } from '../../../../shared/partials';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
    selector: 'fap-ingredient-table',
    templateUrl: './fap_ingredient-table.component.html',
    styleUrls: ['./fap_ingredient-table.component.scss']
})
export class FapIngredientTableComponent implements OnChanges {

    @Input()
    public headerTitle: string;

    @Input()
    public farmId: number;

    @Input()
    public ingredients: Array<IngredientModel>;

    @Input()
    public ingredientTypes: Array<IngredientTypeModel>;

    @ViewChild('addEditIngredientModal')
    public addEditIngredientModal: FapModalComponent;

    @Output()
    public createdIngredient: EventEmitter<IngredientModel> = new EventEmitter();

    @Output()
    public updatedIngredient: EventEmitter<IngredientModel> = new EventEmitter();

    @Output()
    public deleteIngredient: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteLocalIngredient: EventEmitter<number> = new EventEmitter();

    public selectedIngredient: IngredientModel = null;
    public ingredientTypeMap: { [key: number]: IngredientTypeModel } = {};

    constructor(public globalRegistry: GlobalRegistryService) {}

    public addIngredient(): void {
        this.selectedIngredient = null;
        this.addEditIngredientModal.showModal();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'ingredientTypes') && this.ingredientTypes) {
            this.ingredientTypeMap = {};
            this.ingredientTypes.forEach((ingredientType: IngredientTypeModel): void => {
                this.ingredientTypeMap[ingredientType.id] = ingredientType;
            });
        }
    }

}
