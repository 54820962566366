<div *ngIf="widgetData" class="widget-card">
  <div class="widget-card-title">
    <div>
      {{ widgetData.title | translate }}
    </div>
    <div *ngIf="widgetData['objectName']" class="widget-content-type-text">
      {{ widgetData['contentType'] | translate }} - {{ widgetData['objectName'] }}
    </div>
  </div>
  <div class="widget-card-body">
    <div class="list-grid">
      <div class="list-item" *ngFor="let item of widgetData.items; let idx=index;">
        <p class="item-name mb-0">{{ item.name }}</p>
        <a class="item-link btn btn-success px-2" (click)="widgetData.clickFunction(item.id)">
          View
          <i class="fa fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
</div>