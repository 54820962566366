<form class="theme-form" [formGroup]="ingredientTypeForm" id="parent1">
    <div class="col-md-12">
        <mat-form-field class="w-100" (click)="addEditTranslation()">
            <mat-label class="col-form-label">{{ 'stock.inredientType.name' | translate }}</mat-label>
            <input matInput placeholder="{{ 'stock.inredientType.name' | translate }}" formControlName="name" readonly #nameInput>
            <mat-error *ngIf="ifControlHasError('name','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <!-- <div class="col-md-12">
        <mat-form-field class="w-100">
            <mat-label class="col_form-label">{{ 'Translation' }}</mat-label>
            <mat-select #mySelect formControlName="name_t" name="name_t" [value]="ingredientType?.nameT">
                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #nFilter placeholder="Search">
                </mat-form-field>
                <em class="fa fa-plus center f-20 ml-2" (click)="showModal()"></em>
            </div>
            <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option class="noteIcons" *ngFor="let type of translatedNames | filter : nFilter.value" [value]="type.id">
                    <div class="name">
                        <span>{{ type[langString] ? type[langString] : type.id  }}</span>
                    </div>
                    <div class="actions">
                        <i class="fa fa-edit" (click)="editTranslation(type); $event.stopPropagation()"></i>
                        <i class="fa fa-trash" (click)="deleteTranslation(type?.id); $event.stopPropagation()"></i>
                    </div>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field>
    </div> -->
    <div class="col-md-12">
        <div>
            <i class="material-icons">format_color_fill</i>
            <div class="color-input ml-2">    
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'stock.inredientType.color' | translate }}</mat-label>
                    <input matInput class="w-100" placeholder="{{ 'stock.inredientType.color' | translate }}" formControlName="color" type="Color">
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- <fap-upload-avatar [inputImageUrl]="innerImage" (outputImageLoaded)="imageChanged($event)">
    </fap-upload-avatar> -->
    <div class="col-md-12 mt-3 text-center">
        <button class="btn btn-lg"
                type="button"
                [disabled]="ingredientTypeForm.invalid"
                (click)="submit()">{{ 'stock.inredientType.submitButton' | translate }}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>

<fap-modal #addEditTranslationsModal [inputTitle]="'Ingredient type name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.ingredientType)" 
        [prefix]="prefix" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>
