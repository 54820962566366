import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'random'
})
export class RandomPipe implements PipeTransform {
  transform(maxValue: number): number {
    return Math.floor(Math.random() * (maxValue + 1));
  }
}
