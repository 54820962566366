import { DiseaseInterface } from '../../../../core/interfaces/crop/disease.interface';
import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { FapModalComponent } from '../../../../shared/partials';
import { NameValueInterface } from '../../../../core/interfaces/general/name-value.interface';
import { FapEditComponent } from '../fap-edit-component/fap-edit.component';

@Component({
    selector: 'fap-select-disease',
    templateUrl: './fap_select-disease.component.html',
    styleUrls: ['./fap_select-disease.component.scss']
})
export class FapSelectDiseaseComponent {

    @Input()
    public context: string;

    @Input()
    public diseases: Array<NameValueInterface> = [
        {
            name: 'Disease1',
            value: 1
        },
        {
            name: 'Disease2',
            value: 2
        },
        {
            name: 'Disease3',
            value: 3
        }];

    @Output()
    public selectDisease: EventEmitter<NameValueInterface> = new EventEmitter();

    @ViewChild('createDiseaseModal')
    public createDiseaseModal: FapModalComponent;

    @ViewChild('editDisease')
    public editDisease: FapEditComponent;

    public selectedDisease: number;

    public reset(): void {
        this.selectedDisease = null;
    }

    public createDisease(): void {
        this.createDiseaseModal.showModal();
        this.editDisease.resetForm();
    }

    public onSelectDisease(): void {
        this.selectDisease.emit(this.diseases.find((disease: NameValueInterface): boolean => disease.value === this.selectedDisease));
    }

    public createdDisease(disease: DiseaseInterface): void {
        // TODO: POST the form to backend, then push the newly created disease in the option list
        console.log('created a disease', disease);
        this.createDiseaseModal.hideModal();
    }
}
