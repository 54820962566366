<div class="simulation_edit" id="parent">
    <div class="left_part">
        <div class="overview card">
            <div class="left_box">
                <div class="d-flex theme_bg header">
                    <h2
                        class="card-header-title custom_title card-header-wrapper"
                    >
                    {{ "simulations.overview" | translate }}
                    </h2>
                </div>
                <div class="card-body" *ngIf="formFields.fields">
                    <!-- <form [formGroup]="simulationForm">
                        <div class="form-group mb-0">
                            <div class="row align-items-baseline">
                                <div class="col-12 tbw">
                                    <mat-form-field class="w-100">
                                        <mat-label>
                                            {{ "simulations.name" | translate }}
                                        </mat-label>
                                        <input matInput required formControlName="name" name="name" class="p-0" style="height: 16px"/>
                                        <mat-error *ngIf="simulationForm.controls['name'].errors?.required">
                                            <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 tbw">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{ "notes.lot" | translate }}</mat-label>
                                        <mat-select formControlName="lot" name="lot" required>
                                            <mat-form-field appearance="fill" class="w-100">
                                                <input matInput #lotFilter placeholder="Search" />
                                            </mat-form-field>
                                            <div class="scroll_wrap">
                                                <mat-option *ngFor="let lot of lots | filter: lotFilter.value" [value]="lot.id">
                                                    {{ lot.name }}
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                        <mat-error *ngIf="simulationForm.controls['lot'].errors?.required">
                                            <strong>
                                                {{ "auth.validation.requiredField" | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="w-100 d-flex" style="align-items: self-end;">
                                    <div class="col-6 tbw cpr7">
                                        <div class="w-100">
                                            <mat-form-field (click)="picker.open()" class="w-100">
                                                <input matInput readonly [ngxMatDatetimePicker]="picker" placeholder="Start" [disabled]="false" formControlName="start" [min]="minDate"/>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <ngx-mat-datetime-picker [touchUi]="true" #picker [showSpinners]="true" [showSeconds]="false" [hideTime]="true">
                                        </ngx-mat-datetime-picker>
                                              </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-6 tbw cpl7 d-flex">
                                        <div class="colq" style="width: calc(100% - 40px); text-align: right;">
                                            <mat-form-field class="w-100">
                                                <mat-label>{{ "simulations.duration" | translate }}</mat-label>
                                                <input matInput required formControlName="duration" name="duration" class="p-0" style="height: 16px; text-align: right;"/>
                                                <mat-error *ngIf="simulationForm.controls['duration'].errors?.required">
                                                    <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="colu">
                                            <span>d</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form> -->
                    <form [formGroup]="formGroup">
                        <div class="row">
                        <div *ngFor="let field of formFields.fields" [class]="field?.style.class">
                          <ng-container [ngSwitch]="field.type">
                            <mat-form-field *ngSwitchCase="'edit'" [appearance]="field.appearance" class="w-100">
                              <mat-label>{{field.name}}</mat-label>
                              <input matInput formControlName="{{field.id}}" [type]="field.inputType">
                            </mat-form-field>

                            <mat-form-field *ngSwitchCase="'combo'" [appearance]="field.appearance" class="w-100">
                              <mat-label>{{field.name}}</mat-label>
                              <mat-select formControlName="{{field.id}}">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let option of field.options" [value]="option.id">
                                  {{option.text}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngSwitchCase="'number'" [appearance]="field.appearance" class="w-100">
                                <mat-label>{{field.name}}</mat-label>
                                <input matInput formControlName="{{field.id}}" type="number">
                              </mat-form-field>

                              <div class="w-100" *ngSwitchCase="'multi_select'">
                                <chips 
                                    [items]="field.options" 
                                    [selectedItems]="selectedItems"
                                    [label]="field.name"
                                    (updatedItems)="updateItems($event, field.id)"
                                    [addEnabled]="false"
                                >
                                </chips>
                            </div>
                           
                          </ng-container>
                        </div>
                        </div>
                      </form>

                      <div class="btn_container">
                        <button class="btn" (click)="updateSimulationOverview()">{{ "simulations.update" | translate }}</button>
                      </div>
                </div>
            </div>
        </div>
        <div class="summary">
            <div class="left_box">
                <div class="d-flex theme_bg header">
                    <h2
                        class="card-header-title custom_title card-header-wrapper"
                    >
                    {{ "simulations.summary" | translate }}
                    </h2>
                </div>
                <div class="card-body">
                    <div class="summary_table">

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="right_part">
        <div class="items">
            <div class="right_box">
                <div class="d-flex theme_bg header">
                    <h2
                        class="card-header-title custom_title card-header-wrapper"
                    >
                    {{ "simulations.items" | translate }}
                    </h2>
                </div>
                <div class="card_body">
                    <div class="items_header">
                        <div class="title_col">
                            <h5>{{"simulations.actRes" | translate}}</h5>
                        </div>
                        <div class="add_btn" (click)="showAddItemPopup()">
                        </div>
                        <div class="qt_min">
                            <h5>{{"simulations.qtyMin" | translate}}</h5>
                            <span>{{"simulations.total" | translate}}</span>
                        </div>
                        <div class="qt_max">
                            <h5>{{"simulations.qtyMax" | translate}}</h5>
                            <span>{{"simulations.total" | translate}}</span>
                        </div>
                        <div class="qt_max">
                            <h5>{{"simulations.costMin" | translate}}</h5>
                            <span>{{"simulations.total" | translate}}</span>
                        </div>
                        <div class="qt_max">
                            <h5>{{"simulations.costMax" | translate}}</h5>
                            <span>{{"simulations.total" | translate}}</span>
                        </div>
                        <div class="qt_max">
                            <h5>{{"simulations.co2Min" | translate}}</h5>
                            <span>{{"simulations.total" | translate}}</span>
                        </div>
                        <div class="qt_max">
                            <h5>{{"simulations.co2Max" | translate}}</h5>
                            <span>{{"simulations.total" | translate}}</span>
                        </div>
                        <div class="empty_dlt"></div>
                    </div>
                    <div class="item_list_container">
                        <!-- TODO loop the item list  -->
                        <div class="item">
                            <div class="item_header">
                                <div class="title_col">
                                    <h5>Herbiciding</h5>
                                </div>
                                <div class="add_btn" (click)="showAddItemProductPopup()">
                                </div>
                                <div class="qt_min dyn">
                                   
                                </div>
                                <div class="qt_max dyn">
                                    
                                </div>
                                <div class="qt_max dyn">
                                    <h5>100 <span class="unit">eur</span></h5>
                                </div>
                                <div class="qt_max dyn">
                                    <h5>1200 <span class="unit">eur</span></h5>
                                </div>
                                <div class="qt_max dyn">
                                    <h5>100 <span class="unit">mc</span></h5>
                                </div>
                                <div class="qt_max dyn">
                                    <h5>120 <span class="unit">mc</span></h5>
                                </div>
                                <div class="empty_dlt"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<fap-modal #addItemPopup [inputPrimaryButtonInterface]="addItemPopupButtonPrimaryInterface" [inputSecondaryButtonInterface]="addItemPopupButtonSecondaryInterface" inputTitle="Add/Edit Item">
        <form [formGroup]="itemForm" class="col-12 p-0">
            <div class="form-group w-100">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" />
                    <mat-error *ngIf="itemForm.controls['name'].errors?.required">
                        <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group w-100">
                <div class="input-group align-items-baseline w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ "activities.activity_type" | translate }}</mat-label>
                        <mat-select formControlName="activityType" name="type" required>
                            <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #typeFilter placeholder="Search"/>
                                </mat-form-field>
                                <div class="input-group-append pl-1" (click)="showActivityTypeModal(true)">
                                    <span class="input-group-text">
                                        <i class="icofont icofont icofont-plus"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="scroll_wrap">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option class="noteIcons" *ngFor="let type of activityTypes | filter: typeFilter.value" [value]="type?.id">
                                    <div class="name">
                                        {{ type?.name_t && getTranslation(type?.name_t) ? getTranslation(type?.name_t) : type?.name }}
                                    </div>
                                    <div class="actions">
                                        <i class="fa fa-edit" (click)="activityTypeEdit($event, type)"></i>
                                        <i class="fa fa-trash" (click)="activityTypeDelete($event,type)"></i>
                                    </div>
                                </mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="itemForm.controls['activityType'].errors?.required">
                            <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="w-100 d-flex" style="align-items: self-end;">
                <div class="col-6 tbw cpr7 pl-0">
                    <div class="w-100">
                        <mat-form-field (click)="picke1r.open()" class="w-100">
                            <input matInput readonly [ngxMatDatetimePicker]="picke1r" placeholder="Start" [disabled]="false" formControlName="start" [min]="minDate"/>
                            <mat-datepicker-toggle matSuffix [for]="picke1r"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker [touchUi]="true" #picke1r [showSpinners]="true" [showSeconds]="false" [hideTime]="true">
                    </ngx-mat-datetime-picker>
                          </mat-form-field>
                    </div>
                </div>
                <div class="col-6 tbw cpl7 d-flex pr-0">
                    <div class="colq" style="width: calc(100% - 40px); text-align: right;">
                        <mat-form-field class="w-100">
                            <mat-label>{{ "simulations.duration" | translate }}</mat-label>
                            <input matInput required formControlName="duration" name="duration" class="p-0" style="height: 16px; text-align: right;"/>
                            <mat-error *ngIf="simulationForm.controls['duration'].errors?.required">
                                <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="colu">
                        <span>d</span>
                    </div>
                </div>
            </div>
        </form>
    </fap-modal>

    <fap-modal #addItemProductPopup [inputPrimaryButtonInterface]="addItemProductPopupButtonPrimaryInterface" [inputSecondaryButtonInterface]="addItemProductPopupButtonSecondaryInterface" inputTitle="Add/Edit Row">
        <form [formGroup]="itemProductForm" class="col-12 p-0">
            <div class="form-group w-100">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" />
                    <mat-error *ngIf="itemProductForm.controls['name'].errors?.required">
                        <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </fap-modal>

    <fap-modal #addActivityTypeModal [inputPrimaryButtonInterface]="addActivityTypeModalButtonPrimaryInterface" [inputSecondaryButtonInterface]="addActivityTypeModalButtonSecondaryInterface" inputTitle="Add/Edit activity type">
        <form [formGroup]="activityTypeForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100" (click)="addEditTranslation()">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput/>
                    <mat-error *ngIf="activityTypeForm.controls['name'].errors?.required">
                        <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col_form-label">{{ 'Translation' }}</mat-label>
                    <mat-select formControlName="name_t" name="name_t">
                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #nFilter placeholder="Search">
                        </mat-form-field>
                        <em class="fa fa-plus center f-20 ml-2" (click)="showModal()"></em>
                        </div>
                        <div class="scroll_wrap">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-option class="noteIcons" *ngFor="let type of translatedNames | filter : nFilter.value" [value]="type.id">
                                <div class="name"><span>{{ type[langString] ? type[langString] : type.id  }}</span></div>
                                <div class="actions">
                                    <i class="fa fa-edit" (click)="editTranslation(type) ;$event.stopPropagation()"></i>
                                    <i class="fa fa-trash" (click)="deleteTranslation(type?.id)"></i>
                                </div>
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            
            </div> -->
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "notes.color" | translate
                    }}</mat-label>
                    <input
                        class="w-100"
                        matInput
                        type="color"
                        formControlName="color"
                    />
                    <mat-error
                        *ngIf="
                            activityTypeForm.controls['color'].errors?.required
                        "
                    >
                        <strong>{{
                            "auth.validation.requiredField" | translate
                        }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </fap-modal>

    <fap-modal #addEditTranslationsModal [inputTitle]="'Activity type name'">
        <add-edit-translations 
            [obj]="convertKeysToCamelCase(this.activityType)" 
            [prefix]="prefix" 
            [translation]="translation"
            [group]="'types'"
            (submitTranslation)="onAddUpdateTranslation($event)" 
        >
        </add-edit-translations>
    </fap-modal>