<form #form="ngForm" class="form_pad">
    <div class="form-group m-form__group">
        <div class="input-group" style="margin-bottom: -1rem;">
            <fap-add-edit-delete-select
            style="display: inline-block;"
                class="w-100"
                [(ngModel)]="owner"
                name="owner"
                [inputName]="'farm.field.owner' | translate"
                [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                [inputNameFieldName]="'name'"
                [inputNameTFieldName]="'nameT'"
                [inputValueFieldName]="'id'"
                [inputSelectValues]="parties"
                [apiSearchEnabled]="true"
                (click)="getParties()"
                [inputIsRequired]="true"
                (outputOnCreate)="selectedParty=null;addEditPartyModal.showModal()"
                (outputOnEdit)="selectedParty=$event;addEditPartyModal.showModal()"
                (outputOnScroll)="scrollParties()"
                (outputOnDelete)="onDeleteParty($event)"
                (outputOnSearch)="filterParties($event)"
                [addExtraHeight]="status"
                >
            </fap-add-edit-delete-select>
        </div>
        <div class="input-group">
            <mat-form-field>
                <mat-label>{{ 'farm.field.fieldName' | translate }}</mat-label>
                <input [(ngModel)]="fieldName" matInput required placeholder="{{ 'farm.field.fieldName' | translate }}"
                    name="fieldName">
            </mat-form-field>
        </div>
        <div class="input-group">
            <mat-form-field>
                <mat-label>{{ 'farm.field.soilType' | translate }}</mat-label>
                <input [(ngModel)]="soilType" matInput placeholder="{{ 'farm.field.soilType' | translate }}"
                    name="soilType">
            </mat-form-field>
        </div>
        <fap-unit-value
            [fieldName]="'farm.field.area' | translate"
            [(ngModel)]="area"
            [ngModelOptions]="{standalone: true}">
        </fap-unit-value>
    </div>
</form>
<!-- <div *ngIf="isSubmitDisabled" class="row center mb-2">{{ 'farm.field.fieldMustBeDrawn' | translate }}</div>
<div class="row center">
    <button [disabled]="form.invalid || isSubmitDisabled" class="btn btn-success px-2" type="button"
        (click)="submitField.emit({name: fieldName, soilType: soilType, area: { id: area.id, unit: area.unit, value: area.value}, owner: owner})">
        Submit<i class="material-icons pl-1">send</i>
    </button>
</div> -->
<fap-attributes
        [entityRelation]="'field'"
        [entityId]="entityInfo?.id"
        [entityInfo]="entityInfo"
        [objectId]="fieldId"
        (formTypeEmit)="formType = $event"
        (formFieldsEmit)="formFields = $event"
    >
    </fap-attributes>
<fap-modal #addEditPartyModal
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
        (addedParty)="owner=$event.id;addEditPartyModal.hideModal()"
        (updatedParty)="owner=$event.id;addEditPartyModal.hideModal()">
    </fap-add-edit-party>
</fap-modal>
