import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceModel, ObjectService } from 'src/app/core/services/api/objects/object.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';

@Component({
  selector: 'scheduler-layout',
  templateUrl: './scheduler-layout.component.html',
  styleUrls: ['./scheduler-layout.component.scss']
})
export class SchedulerLayoutComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public farms: Array<FarmModel>;
  @Input()
  public sensors: Array<SensorModel> = [];
  public isLoading = false;
  @Output()
  public addSensor: EventEmitter<any> = new EventEmitter();
  @Input()
  public farmForecastMapping: { [key: number]: WeatherModel } = {};
  @Output()
  public updateSensor: EventEmitter<any> = new EventEmitter();
  public devices: Array<DeviceModel>;
  @Output()
  public deleteSensor: EventEmitter<number> = new EventEmitter();
  public subscription: Subscription

  constructor(public objectService: ObjectService) { }

  ngOnInit() {
    this.isLoading = true;
    this.getDevices();
    console.log(this.sensors);
  }

  ngOnChanges() {
    if(this.sensors != undefined) {
      this.isLoading = false
    }
  }

  deleteNotes(event) {
    this.deleteSensor.emit(event);
  }

  getDevices() {
    this.subscription = this.objectService.getDevices().subscribe(data => {
      this.devices = data.body.results;
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
