<div *ngIf="data?.forecast" class="widget-container card p-3">
    <p class="f-16 mb-0">Forecast</p>
    <div class="today">
        <h3 class="grades">{{ data.temperature }}&#8451;</h3>
        <h3 class="day">{{ convertDateString(data.lastUpdate) | translate }}</h3>
        <p>Partly Cloudy</p>
        <div class="details d-flex align-items-center">
            <p class="d-flex mr-3">
                <i class="fa fa-tint mr-1" aria-hidden="true"></i>
                {{ data.humidity }}%
            </p>
            <p class="d-flex mr-3">
                <span class="material-icons f-16">place</span>
                {{ data.pressure }}PHa
            </p>
            <p class="d-flex mr-3">
                <span class="material-icons f-16">place</span>
                {{ data.windSpeed }}km/h {{data.windDir}}
            </p>
            <p class="d-flex">
                <span class="material-icons f-16">cloud</span>
                {{ data.precip }}mm
            </p>
        </div>
    </div>
    <div class="week pt-2">
        <div *ngFor="let dayForecast of data.forecast.forecast" class="text-center p-3">
            <h3 class="grades mb-0">{{ dayForecast.temperatureAvg }}&#8451;</h3>
            <h3 class="day">{{ convertDateString(dayForecast.date) | translate | slice:0:3 }}</h3>
            <div class="details">
                <div class="d-flex">
                    <p class="d-flex mr-2 mb-0">
                        <span class="material-icons f-16">place</span>
                        {{ dayForecast.temperatureMax }}
                    </p>
                    <p class="d-flex mb-0">
                        <span class="material-icons f-16">place</span>
                        {{ dayForecast.temperatureMin }}
                    </p>
                </div>
                <p class="d-flex mb-0">
                    <span class="material-icons f-16">place</span>
                    {{ dayForecast.humidity }}%
                </p>
                <p class="d-flex mb-0">
                    <span class="material-icons f-16">place</span>
                    {{ dayForecast.windSpeed }}km/h
                </p>
                <p class="d-flex mb-0">
                    <span class="material-icons f-16">cloud</span>
                    {{ dayForecast.precip }}mm
                </p>
            </div>
        </div>
    </div>
</div>
