import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { ForecastService } from '../../../../../core/services/api/forecast/forecast.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';

@Component({
  selector: 'scheduler-container',
  templateUrl: './scheduler-container.component.html',
  styleUrls: ['./scheduler-container.component.scss']
})
export class SchedulerContainerComponent implements OnInit {

  public farmForecastMapping: { [key: number]: WeatherModel } = {};
  public farms: Array<FarmModel>;
  public forecasts: Array<WeatherModel> = [];
  public subscriptions: Array<Subscription> = [];
  public currentUser;
  public filteredFarms: Array<FarmModel> = [];
  public sensors: Array<SensorModel> = [];
  public filteredSensors: Array<SensorModel> = [];
  constructor(public forecastService: ForecastService, public userService:UserService, public router:Router, public mapService:MapService, public activatedRoute:ActivatedRoute, public farmService:FarmService, public sensorService:SensorService, public globalRegistry: GlobalRegistryService, public toastyService: ToastrService, public translate: TranslateService, public confirmModalService: ConfirmModalService,
    public http: HttpClient, public cacheService: CacheResolverService) {

    this.subscriptions.push(this.userService.getCurrentUser.subscribe(data => {
      if(Object.keys(data).length != 0) {
      this.currentUser = data;
      const path = this.router.routerState.snapshot.url;
      this.userService.getMenus.subscribe(menus => {  
          if(Object.keys(menus).length != 0) {
              const pathExist = menus.some(menu => menu.path === path);
              if(pathExist === false) {
                  this.router.navigate(['pages/posts']);
              }
          }
      })
      }
  }));
    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: localQueryParams,
    });
    this.subscriptions.push(
      combineLatest([
        activatedRoute.queryParams,
        // this.farmService.getFarms(),
      ]).subscribe(
        ([queryParams]: [
          Params,
          
        ]): void => {
          if (!this.forecasts || this.forecasts.length === 0) {
            // this.getForecasts();
          }
          this.farms = globalRegistry.systemData.farms;
          if (queryParams["farms"] !== undefined) {
            // we have farms in query params, but need to check if we have an array of farms
            const filteredFarmIds: Array<number> = Array.isArray(
              queryParams["farms"]
            )
              ? queryParams["farms"].map((farmId: string): number =>
                  Number(farmId)
                )
              : [Number(queryParams["farms"])];
            this.filteredFarms = this.farms.filter(
              (farm: FarmModel): boolean => {
                return filteredFarmIds.includes(farm.id);
              }
            );
          } else {
            this.filteredFarms = this.farms;
          }
        }
      )
    );
    this.mapService.showMap();

    this.mapService.centerMapOnUserLocation();

   }

  ngOnInit(): void {
    this.getSensors();
  }

  private getForecasts(): void {
    this.forecastService
      .getForecast({ content_type: "farm" })
      .pipe(take(1))
      .subscribe((forecasts: ResponseModel<WeatherModel[]>): void => {
        this.forecasts = forecasts.model;
        this.initForecastMapping();
      });
  }

  private initForecastMapping(): void {
    if (this.forecasts) {
      this.forecasts.forEach((forecast: WeatherModel): void => {
        this.farmForecastMapping[forecast.objectId] = forecast;
      });
    }
  }

  public addSensor(sensor: FormData): void {
    console.log(sensor)
    // this.sensorService
    //   .createSensor(sensor)
    //   .pipe(
    //     tap(
    //       () => {
    //         this.getSensors();
    //         this.toastyService.success(
    //           this.translate.instant("sensor.messages.createSuccess")
    //         );
    //       },
    //       () =>
    //         this.toastyService.error(
    //           this.translate.instant("sensor.messages.failCreate")
    //         )
    //     )
    //   )
    //   .subscribe();
  }

  public deleteSensor(sensorId: number): void {
    console.log(sensorId)
    // this.confirmModalService.openConfirmModal(
    //   this.translate.instant("sensors.deleteSensorList"),
    //   this.translate.instant("sensors.deleteSensorListMessage")
    // );
    // this.confirmModalService
    //   .onModalClose()
    //   .subscribe((confirmed: boolean): void => {
    //     if (confirmed) {
    //       this.sensorService
    //         .deleteSensor(sensorId)
    //         .pipe(
    //           tap(
    //             () => {
    //               this.getsensors();
    //               this.toastyService.success(
    //                 this.translate.instant("sensors.messages.deleteSuccess")
    //               );
    //             },
    //             () =>
    //               this.toastyService.error(
    //                 this.translate.instant("sensors.messages.failDelete")
    //               )
    //           )
    //         )
    //         .subscribe();
    //     }
    //   });
  }

  private getSensors() {
    const param = { type: 'actuator' };
    const url = this.sensorService.getUrl('sensors/');
    this.cacheService.delete(url+'type=actuator');
    this.sensorService
        .getSensors(param)
        .subscribe((sensors: ResponseModel<SensorModel[]>): void => {
            this.sensors = sensors.model;
            this.sensors.forEach((element: SensorModel) => {
                const param1 = { sensor: element.id };
                if (element.actions == undefined) {
                    element.actions = [];
                }
                const surl = this.sensorService.getUrl('actions/');
                this.cacheService.delete(surl+'sensor='+element.id);
                this.sensorService
                    .getSensorActions(param1)
                    .subscribe((data) => {
                        if (data) {
                            element.actions.push(data.body.results);
                        }
                    });
            });
            this.filteredSensors = this.sensors;
            console.log(this.sensors);
        });
}

}
