import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AttributeEntityEnum } from '../../../../../core/enums/attribute/attribute-entity.enum';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ActivityInterface } from '../../../../../core/interfaces/activity/activity.interface';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';
import { ActivityGroupModel } from '../../../../../core/models/activity/activity-group.model';
import { FapModalComponent } from '../../../../../shared/partials';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { ResourceTypeModel } from '../../../../../core/models/type/resource-type.model';
import { NewUnitModel } from '../../../../../core/models/units/new-unit-model';
import { UnitModel } from '../../../../../core/models/units/unit.model';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { environment } from '../../../../../../environments/environment';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { MapHelper } from 'src/app/core/heplers/map.helper';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';


export const DATE_TIME_FORMAT = {
    parse: {
      dateInput: 'l, LTS',
    },
    display: {
      dateInput: 'MMM DD YYYY, HH:mm',
      monthYearLabel: 'MM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
}

@Component({
    selector: 'fap-activities-add-edit-layout',
    templateUrl: './fap-activities-add-edit-layout.component.html',
    styleUrls: ['./fap-activities-add-edit-layout.component.scss'],
    providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class FapActivitiesAddEditLayoutComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public isEditMode = false;

    @Input()
    public isPreviewMode = false;

    @Input()
    public activity: ActivityModel;

    @Input()
    public activityTypes: Array<ActivityTypeModel>;

    @Input()
    public activityGroups: Array<ActivityGroupModel>;

    @Input()
    public activityId: number;

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public lots: Array<LotModel> = [];

    @Input()
    public isSubmitDisabled = false;

    @Input()
    public equipments: Array<EquipmentModel> = [];

    @Output()
    public submitEquipment: EventEmitter<{}> = new EventEmitter();

    @Output()
    public enabledEdit: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteEquipmentType: EventEmitter<number> = new EventEmitter();
    @Input()
    public title: string;
    @Output()
    public deleteActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public submitActivity: EventEmitter<any> = new EventEmitter();
    @Output()
    public updateActivity: EventEmitter<any> = new EventEmitter();
    public personList: any;
    public untiltimeVal: any;
    public timestamptimeVal: any;
    public minDate;
    public maxDate;
    public activitysCoords: any;
    public coordsString: string;
    public isPerson = false;
    public assignedTo;
    public initialActivityForm: ActivityInterface;
    private subscriptions: Array<Subscription> = [];
    public activityForm: UntypedFormGroup;
    public lotGroup: any = [];
    public entityRelation: AttributeEntityEnum = AttributeEntityEnum.Activity;
    public enityRelations: AttributeEntityEnum.Activity =
        AttributeEntityEnum.Activity;
    public group: Array<ActivityGroupModel>;
    @ViewChild('confirmPopup') confirmPopup: FapModalComponent;
    public attachableEquipments: Array<EquipmentModel> = [];
    public mainEquipments: Array<EquipmentModel> = [];
    public getMorePersons = true;
    public latest = false;
    public activityTypeId: any;
    public activityType: any;
    public activityTypeForm: UntypedFormGroup;
    public addActivityTypeModalButtonPrimaryInterface: FapModalButtonInterface;
    public addActivityTypeModalButtonSecondaryInterface: FapModalButtonInterface;
    public activityGroupId: any;
    public activityGroupForm: UntypedFormGroup;
    public addActivityGroupModalButtonPrimaryInterface: FapModalButtonInterface;
    public addActivityGroupModalButtonSecondaryInterface: FapModalButtonInterface;

    public addTransactionButtonPrimaryInterface: FapModalButtonInterface;
    public addTransactionButtonSecondaryInterface: FapModalButtonInterface;
    @ViewChild('addActivityTypeModal')
    public addActivityTypeModal: FapModalComponent;
    @ViewChild('addActivityGroupModal')
    public addActivityGroupModal: FapModalComponent;
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    @ViewChild('addEditNameTranslationsModal')
    public addEditNameTranslationsModal: FapModalComponent;
    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public isEdit = false;
    
    public imgWidth: number;
    public imgHeight: number;
    public orientation: string;
    @Input() resourceTypes: Array<ResourceTypeModel> = [];

    public productForm: UntypedFormGroup;
    public quantityPack: NewUnitModel;
    public selectedUnit: UnitModel;
    public currentUnit: UnitModel;
    public parentUnit: UnitModel;
    public productImages:any = [];
    public limit = 20;
    public units: Array<UnitModel> = [];
    public unitForm: UntypedFormGroup;
    public selectedUnitId: number;
   public persons = [];
    public selectedResource: ResourceTypeModel;
    public parentResource: ResourceTypeModel;
    public images =  [];
    public image: File;
    imageSrc: string;
    public imageForm: UntypedFormGroup;
    public farmForm: UntypedFormGroup;
    public addProductModalButtonPrimaryInterface: FapModalButtonInterface;
    public addProductModalButtonSecondaryInterface: FapModalButtonInterface;
    public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
    public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
    public product;
  @ViewChild('addProductModal')
  public addProductModal: FapModalComponent;
  @ViewChild('transaction')
  public transaction: FapModalComponent;
  @ViewChild('addImageModal')
  public addImageModal: FapModalComponent;
  @ViewChild('addResourceTypeModal')
  public addResourceTypeModal: FapModalComponent;
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  public products = [];
  public personToken: { offset: number; limit: number, search: string; } = null;
  public activitiyEquipments:Array<any> = [];
  public mediaUrl = environment.mediaUrl
  public nextToken: { offset: number; limit: number; group: string; search: string} = null;
    public usedEquipment;
    public attachedEquipment1;
    public attachedEquipment2;
    public transactions;
    public transactionIds = [];
    
    public resourcesDoc;
    public activityFarms: Array<FarmModel> = [];
    public activityDocFarm: number = null;
    public documentFarm: number = null;
    public localDocId: any;
    public localPDocId: any;
    public r_items: Array<any> = [];
    public p_items: Array<any> = [];
    public currentUser:number;
    public localLots = [];
    public prefix = 'ACTIVITY_'
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    public entityInfo: any;

    @ViewChild('fileUploader') fileUploader:ElementRef;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
    public translation = null;
    public nameT = null;
    public formType;
    public formFields;

    constructor(
        public formBuilder: UntypedFormBuilder,
        public toastr: ToastrService,
        public navService: NavService,
        public translateService: TranslateService,
        private router: Router,
        public mapService: MapService,
        public companyService: CompanyService,
        public globalRegistryService: GlobalRegistryService,
        public activityService: ActivityService,
        public globalRegistry: GlobalRegistryService,
        public stockService: StockService,
        public confirmModalService: ConfirmModalService,
        public typesService: TypesService,
        public unitService: UnitService,
        public route: ActivatedRoute,
        public userService: UserService,
        public widgetsService: WidgetsService,
        public cacheService: CacheResolverService,
        public attributeService: AttributeService
    ) {
        this.initAddActivityTypeModalButtons();
        this.initAddActivityGroupModalButtons();
        this.initTransactionModalButtons();
        this.personList = globalRegistry.systemData.persons;
        // this.subscriptions.push(
        //     this.activityService.getActivityGroups().subscribe((response: ResponseModel<ActivityGroupModel[]>) => {
        //         this.activityGroups = response.model
        //       })
        // );
        this.userService.getCurrentUser.subscribe(data => {
            this.currentUser = data.id
        });
    }

    setLocalLot(val) {
        localStorage.setItem('lots', val.value.toString());
        this.localLots = val.value;
    }

    public getFormTypes() {
        const url = this.typesService.getUrl('form_type/');
        this.cacheService.delete(url+'entity=activity');
        const params = {entity: 'activity'}
        this.typesService.getFormTypes(params).subscribe(data => {
          console.log(data);
          this.entityInfo = data.body.results[0];
        })
      }

      public updateAttributes() {
        this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
            console.log(data);
        })
    }

    getActivityFarm(lotIds) {
        console.log(lotIds);
        this.activityFarms = [];
        const localFarms = [];
        lotIds.forEach(element => {
            const lot = this.lots.filter(l => {
                if(l.id === element) {
                    return l;
                }
            });
            console.log(lot);
            localFarms.push(lot[0].farm);
        });
        const activityFarms = [...new Set(localFarms)];
        console.log(activityFarms);

            this.activityDocFarm = activityFarms[0];
            this.goToProductionBill();
        
    }

    setLocalVal(control, val) {
        localStorage.setItem(control, val.value)
    }
    setInputVal(control, val) {
        localStorage.setItem(control, val.target.value)
    }

    clearLocalVals() {
        localStorage.removeItem('activityType');
        localStorage.removeItem('group');
        localStorage.removeItem('title');
        localStorage.removeItem('lots');
        localStorage.removeItem('started');
        localStorage.removeItem('ended');
        localStorage.removeItem('phaseId');
        localStorage.removeItem('assignedTo');
        localStorage.removeItem('usedEquipment');
        localStorage.removeItem('attachedEquipment1');
        localStorage.removeItem('attachedEquipment2');
        localStorage.removeItem('info');
    }

    public initAddActivityTypeModalButtons(): void {
        const _this: FapActivitiesAddEditLayoutComponent = this;

        this.addActivityTypeModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.activityTypeSubmit();
            },
            text: this.translateService.instant('save'),
        };

        this.addActivityTypeModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addActivityTypeModal.hideModal();
            },
            text: this.translateService.instant('cancel'),
        };
    }

    public initAddActivityGroupModalButtons(): void {
        const _this: FapActivitiesAddEditLayoutComponent = this;

        this.addActivityGroupModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.activityGroupSubmit();
            },
            text: this.translateService.instant('save'),
        };

        this.addActivityGroupModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addActivityGroupModal.hideModal();
            },
            text: this.translateService.instant('cancel'),
        };
    }

    public initTransactionModalButtons(): void {
        const _this: FapActivitiesAddEditLayoutComponent = this;

        this.addTransactionButtonPrimaryInterface = {
            clickFunction(): void {
                _this.activityGroupSubmit();
            },
            text: this.translateService.instant('save'),
        };

        this.addTransactionButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addActivityGroupModal.hideModal();
            },
            text: this.translateService.instant('cancel'),
        };
    }

    activityTypeEdit(e, activity) {
        this.activityTypeId = activity.id;
        this.activityType = activity;
        console.log(activity)
        e.stopPropagation();
        this.activityTypeForm.controls['name'].setValue(activity.name);
        this.activityTypeForm.controls['name_t'].setValue(activity.nameT);
        this.activityTypeForm.controls['color'].setValue(activity.color);
        this.showActivityTypeModal();
    }

    activityGroupEdit(e, activity) {
        console.log(e)
        console.log(activity)
        this.activityGroupId = activity.id;
        e.stopPropagation();
        this.activityGroupForm.controls['name'].setValue(activity.name);
        this.activityGroupForm.controls['activities'].setValue(activity.activities);
        
        this.showActivityGroupModal();
    }

    addNewActivityGroup() {
        this.activityGroupId = null;
        this.activityGroupForm.reset();
        this.showActivityGroupModal();
    }

    activityTypeDelete(e, activity) {
        e.stopPropagation();
        this.deleteActivityType(activity.id);
    }

    activityGroupDelete(e, activity) {
        e.stopPropagation();
        this.deleteActivityGroup(activity.id);
    }

    public activityTypeSubmit() {
        if (this.activityTypeForm.invalid) {
            Object.keys(this.activityTypeForm.controls).forEach(
                (controlName: string) =>
                    this.activityTypeForm.controls[controlName].markAsTouched()
            );
            return;
        }
        console.log(this.activityTypeForm.value);
        // return
        if (this.activityTypeId) {
            this.editActivityType(
                this.activityTypeForm.value,
                this.activityTypeId
            );
            this.addActivityTypeModal.hideModal();
        } else {
            this.addActivityType(this.activityTypeForm.value);
            this.addActivityTypeModal.hideModal();
        }
        this.activityType = null;
        this.activityTypeForm.reset();
    }

    public activityGroupSubmit() {
        if (this.activityGroupForm.invalid) {
            Object.keys(this.activityGroupForm.controls).forEach(
                (controlName: string) =>
                    this.activityGroupForm.controls[controlName].markAsTouched()
            );
            return;
        }
        if(this.activityGroupForm.controls['activities'].value == null) {
            this.activityGroupForm.controls['activities'].patchValue([])
        }
        console.log(this.activityGroupForm.value);
        if (this.activityGroupId) {
            this.editActivityGroup(
                this.activityGroupForm.value,
                this.activityGroupId
            );
            this.addActivityGroupModal.hideModal();
        } else {
            this.addActivityGroup(this.activityGroupForm.value);
            this.addActivityGroupModal.hideModal();
        }
    }

    public addActivityType(activity: FormData): void {
        this.typesService.addActivityType(activity).subscribe(() => {
            this.activityService.getActivitiesList.next();
            this.toastr.success(
                this.translateService.instant('activities.createActivityType')
            );
            this.reloadActivityTypes()
        });
    }

    public addActivityGroup(activity): void {
        this.activityService.createActivityGroup(activity).subscribe(() => {
            this.activityService.getActivitiesList.next();
            this.toastr.success(
                this.translateService.instant('activities.createActivityGroup')
            );
            this.reloadActivityGroups();
        });
    }

    public reloadActivityTypes() {
        const url = this.typesService.getUrl('activity_type/limit=1000&offset=0');
        this.cacheService.delete(url);
        this.typesService.getActivityTypes().subscribe((types:ResponseModel<ActivityTypeModel[]>) => {
            this.activityTypes = types.model;
        })
    }

    public reloadActivityGroups() {
        const url = this.activityService.getUrl('group/limit=1000&offset=0');
        this.cacheService.delete(url);
        this.activityService.getActivityGroups().subscribe((response: ResponseModel<ActivityGroupModel[]>) => {
            this.activityGroups = response.model
          })
    }

    public editActivityType(activity: FormData, activityTypeId: number): void {
        this.typesService
            .updateActivityType(activity, activityTypeId)
            .subscribe(() => {
                this.activityService.getActivitiesList.next();
                this.toastr.success(
                    this.translateService.instant('activities.updateActivityType')
                );
                this.reloadActivityTypes()
            });
    }

    public editActivityGroup(activity, activityTypeId: number): void {
        this.activityService
            .updateActivityGroup(activityTypeId, activity)
            .subscribe(() => {
                this.activityService.getActivitiesList.next();
                this.toastr.success(
                    this.translateService.instant('activities.updateActivityGroup')
                );
                this.reloadActivityGroups();
            });
    }

    public deleteActivityType(activityTypeId: number): void {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('activities.deleteActivityType'),
            this.translateService.instant('activities.deleteActivityTypeMessage')
        );
        this.confirmModalService
            .onModalClose()
            .subscribe((confirmed: boolean): void => {
                if (confirmed) {
                    this.typesService
                        .deleteActivityType(activityTypeId)
                        .subscribe(() => {
                            this.activityService.getActivitiesList.next();
                            this.toastr.success(
                                this.translateService.instant(
                                    'activities.deleteActivityTypeSuccessfully'
                                )
                            );
                            this.reloadActivityTypes();
                        });
                }
            });
    }

    public deleteActivityGroup(activityTypeId: number): void {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('activities.deleteActivityGroup'),
            this.translateService.instant('activities.deleteActivityGroupMessage')
        );
        this.confirmModalService
            .onModalClose()
            .subscribe((confirmed: boolean): void => {
                if (confirmed) {
                    this.activityService
                        .deleteActivityGroup(activityTypeId)
                        .subscribe(() => {
                            this.activityService.getActivitiesList.next();
                            this.toastr.success(
                                this.translateService.instant(
                                    'activities.deleteActivityGroupSuccessfully'
                                )
                            );
                            this.reloadActivityGroups();
                        });
                }
            });
    }

    ngOnInit() {
        this.widgetsService.setSize(4);
        this.initForm();
        this.mapService.showMap();
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        const result = this.lots.map((item) => {
            return {
                ...item,
                farms: this.farms.filter((el) => el.id === item.farm),
            };
        });
        console.log(this.attachableEquipments);
        const res = Array.from(
            result
                .reduce((acc, promotion) => {
                    acc.set(
                        promotion.farm,
                        (acc.get(promotion.farm) || []).concat(promotion)
                    );
                    return acc;
                }, new Map())
                .entries(),
            ([farm, data]) => ({ farm, data })
        );
        this.lotGroup = res;
        
        this.initActivityTypeForm();
        this.initActivityGroupForm();
        this.subscriptions.push(
            this.navService.emitSubmit.subscribe((value: boolean) => {
                if (value) {
                    this.submitForm();
                }
            })
        );

        this.subscription.add(
            this.navService.emitCancel.subscribe((value: boolean) => {
                if (value) {
                    this.clearLocalVals();
                    this.router.navigate(['/pages/activities']);
                    if(this.localDocId) {
                        this.subscription.add(this.stockService.deleteDoc(this.localDocId).subscribe(data => {
                            console.log(data);
                        }))
                    }
                    if(this.localPDocId) {
                        this.subscription.add(this.stockService.deleteDoc(this.localPDocId).subscribe(data => {
                            console.log(data);
                        }))
                    }
                    this.navService.emitCancel.next(false);
                }
            })
        );

        this.subscriptions.push(
            this.navService.emitDelete.subscribe((value: boolean) => {
                value && this.deleteCurrentActivity();
            })
        );
        this.initImageForm();
        this.initImageModalButtons();
        // this.getImages();
        this.persons = this.globalRegistryService.systemData.persons;
        if(this.route.snapshot.queryParams.doc) {
            this.localDocId = this.route.snapshot.queryParams.doc;
            this.getDocDetails(this.localDocId);
        }
        this.farmForm = new UntypedFormGroup({
            farm: new UntypedFormControl(null, Validators.required)
          });

          this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 3000,
            allowSearchFilter: true
          };
          this.showAllFieldsOnMap();

          if(this.route.snapshot.queryParams.lot) {
            this.localLots.push(+this.route.snapshot.queryParams.lot);
            this.changeLotFormat(this.localLots);
        }

          this.activityService.getActivityLots.subscribe((data) => {
            if(data) {
                this.localLots.push(data);
                this.changeLotFormat(this.localLots);
            }
          })
          this.getFormTypes();
    }

    public initActivityTypeForm() {
        this.activityTypeForm = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            name_t: new UntypedFormControl(''),
            color: new UntypedFormControl('#ff0000', Validators.required),
        });
    }

    public initActivityGroupForm() {
        this.activityGroupForm = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            name_t: new UntypedFormControl(''),
            activities: new UntypedFormControl([])
        });
        this.activityGroupForm.get('activities').setValue([]);
    }

    public showAllFieldsOnMap(): void {  
        this.globalRegistry.systemData.lots.forEach((lot: LotModel): void => {
            this.mapService.mapPolygons.push({
                points: MapHelper.convertToAGMPolygon(lot.coords.coordinates[0]),
                strokeColor: '#33de55',
                fillColor: '#248a38',
                data: {field: true},
                isEditable: false
            });
        });
        this.mapService.centerMapOnPolygons();
    }

    initImageForm() {
        this.imageForm = new UntypedFormGroup({
            file: new UntypedFormControl('', Validators.required),
            group: new UntypedFormControl('equipments', Validators.required),
            tags: new UntypedFormControl([], Validators.required),
            orientation: new UntypedFormControl('', Validators.required)
          });
    }

    changeLotFormat(lotIds) {
        const lots = [];
      
        lotIds.forEach(element => {
          if (typeof element === 'number') {
            // If element is a number, find the corresponding lot by id
            const lot = this.lots.find(l => l.id === element);
            if (lot) {
              lots.push(lot);
            }
          } else if (Array.isArray(element)) {
            // If element is an array, use the array elements as lot ids
            element.forEach(id => {
              const lot = this.lots.find(l => l.id === id);
              if (lot) {
                lots.push(lot);
              }
            });
          }
        });
      
        const uniqueLots = lots.filter((item, pos) => lots.indexOf(item) == pos);
        console.log(uniqueLots);
        this.selectedItems = uniqueLots;
        return uniqueLots;
      }      

    get f() {
        return this.activityForm.controls;
      }

    public minValueChanged(ev) {
        this.minDate = ev.target.value;
        localStorage.setItem('started', ev.target.value);
    }

    public maxValueChanged(ev) {
        this.maxDate = ev.target.value;
        localStorage.setItem('ended', ev.target.value);
    }

    public verifyFarm() {
        // this.localLots = [];
        // console.log(this.selectedItems);
        // this.selectedItems.forEach(element => {
        //     this.localLots.push(element.id);
        // });
        this.getActivityFarm(this.selectedItems);
    }

    public setActivityDocFarm() {
        this.activityDocFarm = this.farmForm.get('farm').value;
        this.goToProductionBill();
    }

    public goToProductionBill() {
        if(!this.activityDocFarm) {
            this.toastr.error('Please select a lot to proceed!');
            return;
        }
        if(this.activity) {
            if(this.activity.resourcesDoc) {
                this.router.navigate(['/pages/stock/bill/'+this.activity.resourcesDoc.id], {queryParams: {type: 'production_note', from: 'activity', activityId: this.activity.id, new_doc: 'false', farm: this.activityDocFarm}});
            } else {
                this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'production_note', from: 'activity', activityId: this.activity.id, new_doc: 'true', farm: this.activityDocFarm}});
            }
            } else {
                this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'production_note', from: 'activity', new_doc: 'true', farm: this.activityDocFarm}});
            }
            
        } 
    

    public getDocDetails(id) {
        this.subscription.add(this.stockService.getDoc(id).subscribe(data => {
            this.resourcesDoc = data.body.results;
            if(data) {
                this.getDocItems(data.body.results.id);
            }
        }))
    }

    getDocItems(id): void {
        const p0 = {
          document: id,
          type: 0
        };
      
        const p1 = {
          document: id,
          type: 1
        };
      
        this.subscription.add(
          this.stockService.getDocument(p0).subscribe((data: any) => {
            if (data) {
              this.documentFarm = data.body.results[0]?.document.farm;
              console.log(data.body.results);
      
              const r_items = [];
              data.body.results.forEach(element => {
                const obj = {
                  id: element.resource_entry.id,
                  product: element.resource_entry.product,
                  quantity: element.quantity,
                };
                r_items.push(obj);
              });
              this.r_items = r_items;
              console.log(r_items);
            }
          })
        );
      
        this.subscription.add(
          this.stockService.getDocument(p1).subscribe((data: any) => {
            if (data) {
              this.documentFarm = data.body.results[0]?.document.farm;
              console.log(data.body.results);
      
              const p_items = [];
              data.body.results.forEach(element => {
                const obj = {
                  id: element.resource_entry.id,
                  product: element.resource_entry.product,
                  quantity: element.quantity,
                };
                p_items.push(obj);
              });
              this.p_items = p_items;
              console.log(p_items);
            }
          })
        );
      }      

    public initImageModalButtons(): void {
      const _this: FapActivitiesAddEditLayoutComponent = this;

      this.addImageModalButtonPrimaryInterface = {
          clickFunction(): void {
              _this.imageSubmit();
          },
          text: this.translateService.instant('save')
      };
  
      this.addImageModalButtonSecondaryInterface = {
          clickFunction(): void {
              _this.addImageModal.hideModal();
          },
          text: this.translateService.instant('cancel')
      };
  }

  public showActivityTypeModal(newCheck?: boolean): void {
    if(newCheck) {
        this.activityType = null
        this.activityTypeId = null;
        this.activityTypeForm.reset();
    }
    this.addActivityTypeModal.showModal();
  }

    public showActivityGroupModal(): void {
        this.addActivityGroupModal.showModal();
    }
    
    getTranslation(translation) {
        const t =this.globalRegistry.systemData.translations.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] != '') {
                return t[0][this.langString];
            } else {
                return
            }
        } else {
            return translation
          }
      }

    public getFarmName(farmid) {
        let farm;
        this.farms.forEach((element) => {
            if (element.id == farmid) {
                farm = element;
            }
        });
        return farm.name;
    }

    public deleteCurrentActivity() {
        console.log(this.activity);
        this.deleteActivity.emit(this.activity.id);
        setTimeout(() => {
            this.router.navigate(['/pages/activities']);
        }, 1000);
    }

    public ngOnDestroy(): void {
        this.widgetsService.setSize(6);
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.subscription.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'activity') && this.activity) {
            this.initForm();
        }
        if (
            Object.prototype.hasOwnProperty.call(changes, 'isSubmitDisabled') &&
            this.isSubmitDisabled
        ) {
            this.navService.submitFarm.next(false);
        } else {
            this.navService.submitFarm.next(true);
        }
    }

    checkLocalDate(control) {
        if(localStorage.getItem(control)!= null) {
            return moment(localStorage.getItem(control)).format('yyyy-MM-DD hh:mm:ss')
          } else return moment(new Date()).format('yyyy-MM-DD hh:mm:ss')
    }

    checkLocalPhaseId(control) {
        if(localStorage.getItem(control)!= null) {
            return localStorage.getItem(control)
          } else 0
    }

    checkLocalVal(control) {
        if(localStorage.getItem(control)!= null) {
          return +localStorage.getItem(control)
        }
      }

      checkLocalInput(control) {
        console.log(localStorage.getItem(control));
        if(localStorage.getItem(control)!= null) {
            return localStorage.getItem(control)
          } else {
            return
          }
      }

    public initForm() {
        const formBody: {} = {
            activityType: [
                this.activity ? this.activity.activityType : this.checkLocalVal('activityType'),
                Validators.compose([Validators.required]),
            ],
            group: [
                this.activity ? this.activity.group : this.checkLocalVal('group')
            ],
            lots: [this.activity ? this.activity.lots : this.checkLocalVal('lots')],
            assignedTo: [this.activity ? this.activity.assignedTo : this.checkLocalVal('assignedTo')],
            started: [
                this.activity ? moment(this.activity.started).format('') : this.checkLocalDate('started'),
                Validators.compose([Validators.required]),
            ],
            ended: [
                this.activity ? moment(this.activity.ended).format('') : null,
            ],
            info: [this.activity ? this.activity.info : this.checkLocalInput('info')],
            name: [this.activity ? this.activity.name : this.checkLocalInput('name'), Validators.compose([Validators.required])],
            name_t: [
                this.activity ? this.activity.nameT : ''
            ],
            phaseId: [this.activity ? this.activity.phaseId : this.checkLocalInput('phaseId')],
            usedEquipment: [
                this.usedEquipment ? this.usedEquipment.id : null,
            ],
            transactions: [this.transactions ? this.transactions : []],
            distance: [this.activity ? this.activity.distance : 0.0],
            area: [this.activity ? this.activity.area : 0.0],
            cost: [this.activity ? this.activity.cost : 0],
            data: [this.activity ? this.activity.data : {}],
            trackId: [this.activity ? this.activity.trackId : null],
            attachedEquipment1: [
                this.attachableEquipments ? this.attachableEquipments[0] ? this.attachableEquipments[0].id : null : null
            ],
            attachedEquipment2: [
                this.attachableEquipments ? this.attachableEquipments[1] ? this.attachableEquipments[1].id : null : null
            ],
            workingTime: [
                this.activity && this.activity.workingTime
                    ? this.activity.workingTime
                    : null,
            ],
            timestampTime: [
                this.activity
                    ? moment(this.activity.started).format('LT')
                    : null,
            ],
            untilTime: [
                this.activity ? moment(this.activity.ended).format('LT') : null,
            ],
            selectedItems: [
                this.selectedItems ? this.selectedItems : this.checkLocalVal('lots')
            ]
        };

        if(!this.activity) {
            this.minDate = moment(new Date).format('')
        }

        if(this.activity) {
            console.log(this.activity);
            this.localLots = this.activity.lots;
            this.activityId = this.activity.id;
            this.assignedTo = Object.assign({
                firstName: this.activity.assignedTo,
            });
            this.globalRegistry.systemData.translations.forEach(translation => {
                if(translation.id === this.activity.nameT) {
                    this.nameT = translation;
                    console.log(this.nameT);
                }
            });
            formBody['id'] = this.activity.id;
            // this.activitysimageUpload.url = this.activity.images; // set preview
            this.activitiyEquipments = this.activity.attachedEquipments;
            console.log(this.activitiyEquipments);
            this.usedEquipment = this.activitiyEquipments.filter(eq => {
                return eq.attachable == 0
            });
            this.activitiyEquipments.filter(eq => {
                if(eq.attachable == 1) {
                    this.attachableEquipments.push(eq);
                }
            });
            if(this.activity.attachedEquipments.length > 0) {
            this.usedEquipment = this.usedEquipment[0].id
            console.log(this.attachableEquipments);
            this.attachedEquipment1 = this.attachableEquipments ? this.attachableEquipments[0] ? this.attachableEquipments[0].id : null : null;
            this.attachedEquipment2 = this.attachableEquipments ? this.attachableEquipments[1] ? this.attachableEquipments[1].id : null : null;
            }
            this.resourcesDoc = this.activity.resourcesDoc;
            
            if(this.activity.resourcesDoc!=null) {
                this.getDocItems(this.resourcesDoc.id);
                this.documentFarm = this.resourcesDoc.farm;
            }
            if(this.activity.started) {
                this.minDate = this.activity.started;
                console.log(this.minDate);
            }
            if(this.activity.ended) {
                this.maxDate = this.activity.ended;
                console.log(this.maxDate);
            }
            this.selectedItems = this.changeLotFormat(this.activity.lots);
        } else {
            this.usedEquipment = this.checkLocalVal('usedEquipment');
            this.attachedEquipment1 = this.checkLocalVal('attachedEquipment1');
            this.attachedEquipment2 = this.checkLocalVal('attachedEquipment2');
        }
        this.activityForm = this.formBuilder.group(formBody);
        this.initialActivityForm = this.activityForm.value;
        // in preview mode we should not be able to edit or submit the form
        this.isPreviewMode
            ? this.activityForm.disable()
            : this.activityForm.enable();

        this.activityForm.valueChanges.subscribe(() => {
            if (this.activityForm.invalid) {
                this.navService.invalidForm.next(false);
            } else {
                this.navService.invalidForm.next(true);
            }
        });
    }

    calcTime(time) {
        return moment.duration(time).asMinutes();
    }

    updateItems(lots) {
        this.activityForm.get('lots').setValue(lots);
        this.selectedItems = lots;
    }

    public submitForm() {

        if(this.activity) {
            // if(this.documentFarm!=null) {
            //     if(this.activityDocFarm != this.documentFarm) {
            //         this.toastr.error('Changing farm requesting to delete existing document items!')
            //         return
            //     }
            // }
        }
        
        if(this.activityForm.get('ended').value == 'Invalid date') {
            this.activityForm.get('ended').patchValue(null);
            console.log(this.activityForm.get('ended').value);
        }

        let attachedEquipments = [
            this.usedEquipment,
            this.attachedEquipment1,
            this.attachedEquipment2,
        ];

        const results = [];

        attachedEquipments.forEach(element => {
        if (element != null || element != undefined) {
            results.push(element);
        }
        });

        console.log(results);
        if(typeof(results[0]) === 'object') {
            attachedEquipments = results[0];
        } else {
            attachedEquipments = results;
        }

        console.log(this.activityForm.value);
        if (this.activityForm.invalid) {
            this.activityForm.markAllAsTouched();
            this.toastr.error(
                this.translateService.instant(
                    'equipment.pleaseFillInMandatoryFields'
                )
            );
            return;
        }
        let started;
        let ended;
        if (typeof this.activityForm.value['started'] === 'string') {
            const dateTime = this.activityForm.value['started']
                ? this.activityForm.value['started']
                : null;
            started = dateTime;
        } else {
            const dateTime = this.activityForm.value['started']
                ? this.activityForm.value['started'].format()
                : null;
            started = dateTime;
        }

        if (typeof this.activityForm.value['ended'] === 'string') {
            const dateTime = this.activityForm.value['ended']
                ? this.activityForm.value['ended']
                : '';
            ended = dateTime;
        } else {
            const dateTime = this.activityForm.value['ended']
                ? this.activityForm.value['ended'].format()
                : null;
            ended = dateTime;
        }

        
        const lotIds = [];
        this.selectedItems.forEach(element => {
            lotIds.push(element.id);
        });

        const activity: ActivityModel = {
            name: this.activityForm.value['name'],
            nameT: this.nameT ? this.nameT.id : null,
            activityType: this.activityForm.value['activityType'],
            started: started,
            ended: ended,
            workingTime: this.activityForm.value['workingTime'],
            info: this.activityForm.value['info'],
            group: this.activityForm.value['group'],
            createdBy: this.activityForm.value['createdBy'],
            assignedTo: this.activityForm.value['assignedTo'],
            attachedEquipments: attachedEquipments,
            lots: this.activityForm.value['lots'],
            phaseId: this.activityForm.value['phaseId'],
            resourcesDoc: this.resourcesDoc? this.resourcesDoc.id : null,
            distance: +this.activityForm.value['distance'],
            area: +this.activityForm.value['area'],
            cost: +this.activityForm.value['cost'],
            data: this.activityForm.value['data'],
            trackId: this.activityForm.value['trackId'],
        };
        this.clearLocalVals();
        console.log(activity);
       
        if (this.activity) {
            activity.id = this.activity.id;
            this.updateActivity.emit(activity);
            this.updateAttributes();
        } else {
            this.submitActivity.emit(activity);
        }

        setTimeout(() => {
            this.router.navigate(['/pages/activities']);
        }, 1000);
    }

    closeModal(event: PersonModel) {
        this.assignedTo = event;
        this.isPerson = false;
        this.activityForm.controls['assignedTo'].setValue(
            event.id ? event.id : null
        );
    }

    public resetForm(): void {
        this.activityForm.setValue(this.initialActivityForm);
        this.activityForm.markAsPristine();
    }

  

    convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
          const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
          camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
        }
        return camelCaseObj;
      }

    addEditTranslation() {
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.activityType);
    if(name === null || name === '' || type.nameT === null) {
        this.translation = null
        this.addEditTranslationsModal.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
            }
        });
    }
    }

    addEditNameTranslation() {
        const name = this.nameInput1.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.activity);
        if(name === null || name === '' || type.nameT === null) {
            this.nameT = null
            this.addEditNameTranslationsModal.showModal();
        } else {
            this.globalRegistry.systemData.translations.forEach(translation => {
                if(translation.id === type.nameT) {
                    this.nameT = translation;
                    console.log(this.nameT);
                    this.addEditNameTranslationsModal.showModal();
                }
            });
        }
        }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.activityTypeForm.controls['name_t'].setValue(translation.id);
        this.activityTypeForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    onAddUpdateNameTranslation(translation) {
        console.log(translation);
        this.nameT = translation;
        this.activityForm.controls['name_t'].setValue(translation.id);
        this.activityForm.controls['name'].setValue(translation[this.langString]);
        this.addEditNameTranslationsModal.hideModal();
    }

    public getProducts() {
        const url = this.stockService.getUrl('products/');
        this.subscriptions.push(this.stockService.getProducts({ limit: this.limit, group: 'EQUIPMENT' }).subscribe(data => {
        this.cacheService.delete(url+'limit='+this.limit+'&group=EQUIPMENT');
          console.log(data);
          this.products = data.body.results;
          this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
        }))
      }

    paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }

      scrollPersons() {
        const url = this.companyService.getUrl('');
        console.log('person scrolled');
        if(this.getMorePersons) {
          this.personToken &&
            this.subscription.add(this.companyService.getPersons(this.personToken).subscribe((data) => {
              this.cacheService.delete(url+'limit='+this.personToken.limit+'&offset='+this.personToken.offset+'&search='+this.personToken.search+'&own=1');
              console.log(data);
              if(this.persons) {
                this.persons = [...this.persons, ...data.body.results];
              } else {
                this.persons = data.body.results;
              }
              if(data.body.next == null) {
                this.getMorePersons = false;
                return
              } else {
                const url = data.body.next.split('?')
                const urlParams = new URLSearchParams(url[1]);
                const entries = urlParams.entries();
                const params = this.paramsToObject(entries);
                console.log(params);
                if(this.personToken.offset != params['offset']) {
                this.personToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
                } else {
                  return
                }
              }
            }));
          } else {
            return
          }
      }
      
      onPersonScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          this.scrollPersons();
        }
      }

    addImage(ev) {
        this.productImages.push(ev.value.id);
        console.log(this.productImages);
        this.productForm.get('images').setValue(this.productImages);
        console.log(this.productForm.value);
      }

    public showImageModal(): void {
        this.addImageModal.showModal();
      }

    public getImages() {
        const url = this.companyService.getUrl('images/group=equipments')
        this.cacheService.delete(url);
        this.subscription.add(this.companyService.getCompanyImages({group: 'equipments'}).subscribe((response): void => {
            this.images = response.body["results"];
            console.log(this.images);
       }))
      }
      
      public deleteImage(imageId: number): void {
        console.log(imageId);
        this.subscription.add(this.companyService.deleteCompanyImage(imageId).subscribe(data => {
            console.log(data);
            this.getImages();
        }))
      }
      
      public imageSubmit() {
        const data = {
            width: this.imgWidth,
            height: this.imgHeight,
            orientation: this.orientation
        }
        const formData = new FormData();
        formData.append("file", this.image);
        formData.append("group", this.imageForm.get('group').value);
        formData.append("tags", this.imageForm.get('tags').value);
        formData.append("data", JSON.stringify(data));
        console.log(formData);
        // return;
        this.subscription.add(this.companyService.postCompanyImages(formData).subscribe(data => {
            console.log(data);
            if(data.body.results.id) {
                this.getImages();
            }
        }));
        this.addImageModal.hideModal();
      }

      onFileInput(event) {
        if(event.target.files && event.target.files.length) {
          if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
            this.toastr.error(this.translateService.instant('image.notAnImage'))
            this.image = null;
            this.fileUploader.nativeElement.value = null;
            return
          }
          const [image] = event.target.files;
          this.image = image;
          const _URL = window.URL || window.webkitURL;
          let file, img, height, width;
          if ((file = event.target.files[0])) {
              img = new Image();
              const objectUrl = _URL.createObjectURL(file);
              img.onload = function () {
                  height = this.height;
                  width = this.width;
                  _URL.revokeObjectURL(objectUrl);
              };
              img.src = objectUrl;
          }
          setTimeout(()=> {
            if(width <= height) {
              this.orientation  = "portrait";
              console.log("portrait");
            } else {
                this.orientation  = "landscape";
                console.log("landscape");
            }
          }, 500);
        }
      }

      createEquipment() {
        this.router.navigate(['pages/equipments/add']);
      }
}
