import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { DeviceModel, ObjectModel, ObjectService } from '../../../core/services/api/objects/object.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { MapService } from '../../layout/fap_main-map/service/map-service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'fap-device-info-widget',
  templateUrl: './fap-device-info-widget.component.html',
  styleUrls: ['./fap-device-info-widget.component.scss']
})
export class FapDeviceInfoWidgetComponent implements OnInit, OnChanges {

  public selectedWidget;
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  public toggleMain = false;
  public toggleSub = false;
  public device: DeviceModel;
  public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  public translatedNames: any = [];
  public langString: string;
  public subscriptions: Array<Subscription> = []

  constructor(public globalRegistry: GlobalRegistryService, public objectService: ObjectService, public mapService: MapService, public router: Router, public navService: NavService) { }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
        if(t[0][this.langString] != '') {
            return t[0][this.langString];
        } else {
            return
        }
    } else {
        return translation
      }
  }

  public getLotName(lotId: number = this.widget.objectId): string {
    return this.globalRegistry.systemData.lots.find(
        (lot) => lot.id === lotId
    ).name;
  }

  hideMain() {
      this.toggleMain = false;
  }

  toggleMenu() {
      this.toggleMain = !this.toggleMain;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
      console.log(this.widget);
      this.getDeviceDetail(this.widget.settings['device']);
    }
  }

  public getDeviceDetail(id) {
    this.objectService.getDevice(id).subscribe(data => {
      console.log(data);
      this.device = data.body.results;
    })
  }

  public showDevicePointer(coords) {
    if(coords[0]) {
      const markerInterface = {
        lat: coords[0],
        lng: coords[1],
        iconUrl: this.mapIconUrl
      }
      this.mapService.mapMarkers.push(markerInterface);
    }
  }

  public editDevice(deviceId) {
    this.router.navigate(['/pages/devices/edit/' + deviceId])
  }

}
