import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewEncapsulation, Input
} from '@angular/core';
import {
    Router,
    ActivatedRoute,
    NavigationEnd,
    Data,
    NavigationStart,
    ChildActivationEnd
} from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, startWith } from 'rxjs/operators';

import { AuthService } from '../../../core/services/api/auth/auth.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { NavService, Menu } from '../../services/nav.service';
import { LotModel } from '../../../core/models/lot/lot.model';
import { PrecisionHelper } from '../fap_main-map/precision/precision.helper';
import { NameValueInterface } from '../../../core/interfaces/general/name-value.interface';
import { Observable } from 'rxjs';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { UserService } from '../../../core/services/api/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { TypesService } from '../../../core/services/api/types/types.service';


// const body = document.getElementsByTagName('body')[0];

@Component({
    selector: 'fap-header',
    templateUrl: './fap_header.component.html',
    styleUrls: ['./fap_header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FapHeaderComponent implements OnInit {

    public menuItems: Menu[];
    public items: Menu[];
    public openNav = false;
    public rightSidebar = false;
    public text: string;
    public language = 'en';
    public workflowId: number;
    public background: string;
    public buttonColor: string;
    public accentColor: string;
    public sidebarColor: string;
    public hoverColor: string;
    public farmAddMenu: boolean;
    public hoverPlantation: boolean;
    public hoverPark: boolean;
    public selectPlantations: boolean;
    public lotFilterValue: string = undefined;
    public isFarmEditCreate: boolean;
    public isLotEditCreate: boolean;
    public isEquipmentEditCreate: boolean;
    public isPersonEditCreate: boolean;
    public isNotesEditCreate: boolean;
    public isFieldsEditCreate: boolean;
    public isActivitiesEditCreate: boolean;
    public isSimulationEditCreate: boolean;
    public isSchedulerEditCreate: boolean;
    public isBillEditCreate: boolean;
    public isDiseaseEditCreate: boolean;
    public isDeviceEditCreate: boolean;
    public isFormEditCreate: boolean;

    public isAlergicProfile: boolean;
    public isBioParticles: boolean;
    public isRecommendations: boolean;
    public isReports: boolean;

    public hasDashboardFilter: boolean;
    public hasSchedulerFilter: boolean;
    public hasPostsFilter: boolean;
    public hasReportsFilter: boolean;
    public hasStockFilter: boolean;
    public hasTreatmentsFilter: boolean;
    public hasNotesFilter:boolean;
    public hasPeopleFilter:boolean;
    public hasEquipmentFilter:boolean;
    public hasActivityFilter:boolean;
    public hasSimulationsFilter:boolean;
    public hasFarmFilters: boolean;
    public hasLotFilters: boolean;
    public hasSingleFarmFilter: boolean;
    public hasSingleLotFilter: boolean;
    public hasGroupFilters: boolean;
    public hasDateFilters: boolean;
    public hasSearchFilter: boolean;
    public hasMyProductsFilter: boolean;
    public hasDevicesFilter: boolean;
    public hasDeviceViewFilter: boolean;
    public hasPoolsFilter: boolean;
    public hasFormsFilter: boolean;
    public hasWorkflowsFilter: boolean;
    public hasAlergotelReportsFilter: boolean;
    public farmEditCreate: boolean;
    public farmSubmitEnable: boolean;
    public invalidForm: boolean;
    public precisionTypes: Array<NameValueInterface> = [];
    public filteredFarms: Observable<string[]>;

    public selectedFarms: UntypedFormControl = new UntypedFormControl();
    public selectedLots: UntypedFormControl = new UntypedFormControl();
    public lotFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public noteFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public activityFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public reportFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public equipmentFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public deviceFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public selectedFarm: UntypedFormControl = new UntypedFormControl();
    public selectedLot: UntypedFormControl = new UntypedFormControl();
    public selectedGroups: UntypedFormControl = new UntypedFormControl();
    public selectedType: UntypedFormControl = new UntypedFormControl();
    public selectedDate: UntypedFormControl = new UntypedFormControl();
    public toggleSidebar: boolean;
    public isCreateMod: boolean;
    public isEditMod = false;
    public isViewMod = false;
    public showDropdown = false;
    public formTypes = [];
    public workflowTypes = [];

    public lotList: LotModel[] = [];
    public drill: UntypedFormGroup;
    public filterPost: UntypedFormGroup;
    public filterReport: UntypedFormGroup;

    public showDrill = true;
    public createWidget = true;

    @Input()
    public isSelectDialogOpen: boolean;

    @Output()
    public rightSidebarEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public baseDialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public navServices: NavService,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                public translate: TranslateService,
                public globalRegistry: GlobalRegistryService,
                public sensorService:SensorService,
                public authService: AuthService,
                public widgetsSerivce: WidgetsService,
                public userService: UserService,
                public toastrService: ToastrService,
                public cacheService: CacheResolverService,
                public typesService: TypesService
                ) {
        this.navServices.createMod.subscribe((create: boolean) => {
            this.isCreateMod = create;
        });
        this.navServices.editMod.subscribe((edit: boolean) => {
            this.isEditMod = edit;
        });
        this.navServices.viewMod.subscribe((view: boolean) => {
            this.isViewMod = view;
        });
        translate.setDefaultLang(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        this.router.events.pipe(
            filter((event: any): boolean => event instanceof NavigationEnd || event instanceof NavigationStart || event instanceof ChildActivationEnd),
            map((): {} => {
                let child: ActivatedRoute = this.activatedRoute.firstChild;
                this.isFarmEditCreate = false;
                this.isLotEditCreate = false;
                this.isEquipmentEditCreate = false;
                this.isPersonEditCreate = false;
                this.isNotesEditCreate = false;
                this.isFieldsEditCreate = false;
                this.isActivitiesEditCreate = false;
                this.isSimulationEditCreate = false;
                this.isSchedulerEditCreate = false;
                this.isBillEditCreate = false;
                this.isDiseaseEditCreate = false;
                this.isDeviceEditCreate = false;
                this.isFormEditCreate = false;
                this.isReports = false;
                this.isAlergicProfile = false;
                this.isRecommendations = false;
                this.isBioParticles = false;
                if (child.snapshot.url[0].path === 'lot') {
                    this.isLotEditCreate = true;
                } else if (child.snapshot.url[0].path === 'farm') {
                    this.isFarmEditCreate = true;
                } else if(child.snapshot.url[0].path === "equipments"){
                    this.isEquipmentEditCreate = true;
                } else if(child.snapshot.url[0].path === "people"){
                    this.isPersonEditCreate = true;
                }else if(child.snapshot.url[0].path === "notes"){
                    this.isNotesEditCreate = true;
                }else if(child.snapshot.url[0].path === "activities"){
                    this.isActivitiesEditCreate = true;
                }else if(child.snapshot.url[0].path === "scheduler"){
                    this.isSchedulerEditCreate = true;
                }else if(child.snapshot.url[0].path === "stock"){
                    this.isBillEditCreate = true;
                }else if(child.snapshot.url[0].path === "diseases"){
                    this.isDiseaseEditCreate = true;
                }else if(child.snapshot.url[0].path === "fields"){
                    this.isFieldsEditCreate = true;
                }else if(child.snapshot.url[0].path === "devices"){
                    this.isDeviceEditCreate = true;
                }else if(child.snapshot.url[0].path === "forms"){
                    this.isFormEditCreate = true;
                }else if(child.snapshot.url[0].path === "simulations"){
                    this.isSimulationEditCreate = true;
                }else if(child.snapshot.url[0].path === "alergotel_alergic-profile"){
                    this.isAlergicProfile = true;
                }else if(child.snapshot.url[0].path === "alergotel_bio-particles"){
                    this.isBioParticles = true;
                }else if(child.snapshot.url[0].path === "alergotel_recommendations"){
                    this.isRecommendations = true;
                }else if(child.snapshot.url[0].path === "alergotel_reports"){
                    this.isReports = true;
                }
                let data: {} = null;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data) {
                        data = child.snapshot.data;
                        break;
                    }
                }
                let filteredFarms: string[] = this.activatedRoute.snapshot.queryParams['farms'];
                let filteredLots: string[] = this.activatedRoute.snapshot.queryParams['lots'];
                const filteredFarm: string = this.activatedRoute.snapshot.queryParams['farm'];
                const filteredLot: string = this.activatedRoute.snapshot.queryParams['lot'];
                let filteredGroups: string[] = this.activatedRoute.snapshot.queryParams['groups'];
                // if there is only one param, we have to make an array
                if (filteredFarms !== null && filteredFarms !== undefined && !Array.isArray(filteredFarms)) {
                    filteredFarms = [filteredFarms];
                }
                if (filteredLots !== null && filteredLots !== undefined && !Array.isArray(filteredLots)) {
                    filteredLots = [filteredLots];
                }
                if (filteredGroups !== null && filteredGroups !== undefined && !Array.isArray(filteredGroups)) {
                    filteredGroups = [filteredGroups];
                }
                return {
                    data,
                    selectedFarms: filteredFarms ? filteredFarms.map((farm: string): number => Number(farm)) : null,
                    selectedLots: filteredLots ? filteredLots.map((lot: string): number => Number(lot)) : null,
                    selectedGroups: filteredGroups ? filteredGroups.map((group: string): number => Number(group)) : null,
                    selectedFarm: filteredFarm ? Number(filteredFarm) : null,
                    selectedLot: filteredLot ? Number(filteredLot) : null
                };
            })).subscribe((routeData: Data): void => {
                this.hasDashboardFilter = routeData.data.filters && routeData.data.filters.hasDashboardFilter;
                this.hasSchedulerFilter = routeData.data.filters && routeData.data.filters.hasSchedulerFilter;
                this.hasPostsFilter = routeData.data.filters && routeData.data.filters.hasPostsFilter;
                this.hasReportsFilter = routeData.data.filters && routeData.data.filters.hasReportsFilter;
                this.hasStockFilter = routeData.data.filters && routeData.data.filters.hasStockFilter;
                this.hasTreatmentsFilter = routeData.data.filters && routeData.data.filters.hasTreatmentsFilter;
                this.hasNotesFilter = routeData.data.filters && routeData.data.filters.hasNotesFilter;
                this.hasPeopleFilter =  routeData.data.filters && routeData.data.filters.hasPeopleFilter;
                this.hasEquipmentFilter =  routeData.data.filters && routeData.data.filters.hasEquipmentFilter;
                this.hasActivityFilter =  routeData.data.filters && routeData.data.filters.hasActivityFilter;
                this.hasSimulationsFilter =  routeData.data.filters && routeData.data.filters.hasSimulationsFilter;
                this.hasFarmFilters = routeData.data.filters && routeData.data.filters.hasFarmFilter;
                this.hasLotFilters = routeData.data.filters && routeData.data.filters.hasLotFilter;
                this.hasSingleFarmFilter = routeData.data.filters && routeData.data.filters.hasSingleFarmFilter;
                this.hasSingleLotFilter = routeData.data.filters && routeData.data.filters.hasSingleLotFilter;
                this.hasGroupFilters = routeData.data.filters && routeData.data.filters.hasGroupFilter;
                this.hasDateFilters = routeData.data.filters && routeData.data.filters.hasDateFilters;
                this.hasSearchFilter = routeData.data.filters && routeData.data.filters.hasSearchFilter;
                this.hasMyProductsFilter = routeData.data.filters && routeData.data.filters.hasMyProductsFilter;
                this.hasDevicesFilter = routeData.data.filters && routeData.data.filters.hasDevicesFilter;
                this.hasFormsFilter = routeData.data.filters && routeData.data.filters.hasFormsFilter;
                this.hasWorkflowsFilter = routeData.data.filters && routeData.data.filters.hasWorkflowsFilter;
                this.hasDeviceViewFilter = routeData.data.filters && routeData.data.filters.hasDeviceViewFilter;
                this.hasPoolsFilter = routeData.data.filters && routeData.data.filters.hasPoolsFilter;
                this.hasAlergotelReportsFilter =  routeData.data.filters && routeData.data.filters.hasAlergotelReportsFilter;
                this.selectedFarms.setValue(routeData.selectedFarms);
                this.selectedFarm.setValue(routeData.selectedFarm);
                if(this.selectedFarms.value){
                    this.lotList = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
                        return this.selectedFarms.value.some((farmId: number): boolean => lot.farm === farmId);
                    });
                }
                else if(this.selectedFarm.value) {
                    this.lotList = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
                        return lot.farm === this.selectedFarm.value;
                    });
                }
                else {
                    this.lotList = this.globalRegistry.systemData.lots;
                }
                this.selectedLots.setValue(routeData.selectedLots);
                this.selectedLot.setValue(routeData.selectedLot);
            });
        this.buttonColor = navServices.hoverColor;
    }

    getFormattedDate(time): string {
        const [day, month, year, hour, minute, second] = time.split(/[\s/:]+/);
        const convertedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
        return convertedDate;
      }

      public changeDate(date) {
        const d = new Date();
        const e = new Date()
        d.setHours(0, 0, 0, 0);
        e.setHours(23,59,59,59);
        if (date === 'hour') {
            d.setDate(d.getDate() - 1);
            this.drill.get('start').patchValue(d);
            this.drill.get('end').patchValue(e);
        }
    
        if (date === 'day') {
            d.setDate(d.getDate() - 14);
            this.drill.get('start').patchValue(d);
            this.drill.get('end').patchValue(e);
        }
    
        if (date === 'month') {
            d.setDate(d.getDate() - 365);
            this.drill.get('start').patchValue(d);
            this.drill.get('end').patchValue(e);
        }
    }
    
    public setFromDate(date) {
        console.log(date);
        // this.filterPost.get('start').patchValue(new Date(date.value).setHours(0,0,0,0));
        // this.filterReport.get('start').patchValue(new Date(date.value).setHours(0,0,0,0));
        this.sensorService.setFromDate(date.value)
        this.sensorService.setPostFromDate(date.value)
        this.sensorService.setReportFromDate(date.value)
    }
    
    public setToDate(date) {
        // this.filterPost.get('end').patchValue(new Date(date.value).setHours(23,59,59,59));
        // this.filterReport.get('end').patchValue(new Date(date.value).setHours(23,59,59,59));
        this.sensorService.setToDate(date.value)
        this.sensorService.setPostToDate(date.value)
        this.sensorService.setReportToDate(date.value)
    }

    public getToggleStatus() {
        this.navServices.collapseSidebar.subscribe((collapse: boolean) => {
            this.toggleSidebar = collapse;
        });
    }

    public dropdown() {
        this.showDropdown = !this.showDropdown;
    }

    public ngOnInit(): void {
        this.navServices.getCurrentLanguage.subscribe(lang => {
            this.language = lang;
        })
        setTimeout(() => {
            this.navServices.collapseSidebar.next(true);
        },3000)
        this.getToggleStatus()
        const dayBack = new Date();
        dayBack.setDate(new Date().getDate()-6);
        const oneYearFromNow = new Date();
        oneYearFromNow.setMonth(oneYearFromNow.getMonth() - 12);
        const sixMonthsFromNow = new Date();
        sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() - 6);
        console.log(sixMonthsFromNow);
        const today = new Date();
        const currentYear = today.getFullYear();
        const startOfYear = new Date(currentYear, 0, 1);
        const endOfYear = new Date(currentYear, 11, 31);
        this.widgetsSerivce.getDrill.subscribe(data => {
            this.showDrill = data;
        });
        this.widgetsSerivce.getCreateWidget.subscribe(data => {
            this.createWidget = data;
        });
        console.log(this.showDrill);
        const d = new Date(),
        s = "23.59.59 PM",
        parts = s.match(/(\d+)\.(\d+) (\w+)/),
        hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
        minutes = parseInt(parts[2], 10);
        d.setHours(hours);
        d.setMinutes(minutes);
        d.setSeconds(minutes);
        d.setDate(d.getDate())

        this.drill = new UntypedFormGroup({
            start: new UntypedFormControl(dayBack),
            end:new UntypedFormControl(d),
            aggBy:new UntypedFormControl("day")
        });

        this.filterPost = new UntypedFormGroup({
            start: new UntypedFormControl(sixMonthsFromNow),
            end:new UntypedFormControl(new Date()),
        });

        this.filterReport = new UntypedFormGroup({
            start: new UntypedFormControl(startOfYear),
            end:new UntypedFormControl(endOfYear),
        });
        console.log(this.filterPost.value);
        this.drillData();
        this.filterPosts();
        // this.filterReports();

        this.navServices.items.subscribe((menuItems: any): void => {
            this.items = menuItems;
        });
        // this.globalRegistry.reloadUserData();
        this.filteredFarms = this.lotFilterCtrl.valueChanges.pipe(
            startWith(''),
            map((value: string) => this._filter(value))
        );
        this.precisionTypes = PrecisionHelper.precisionTypes;
        this.background = this.navServices.headerBackgroundColor;
        this.navServices.editFarm.subscribe(
            (value: boolean) => {
                this.farmEditCreate = value;
            });
        this.navServices.submitFarm.subscribe(
            (value: boolean ) => {
                this.farmSubmitEnable = value;
                this.checkSubmit();
            });
        this.navServices.invalidForm.subscribe(
            (value: boolean) => {
                this.invalidForm = value;
                this.checkSubmit();
            });
        this.sidebarColor = this.navServices.sidebarBackgroundColor;
        this.hoverColor = this.navServices.hoverColor;
        this.accentColor = this.navServices.accent_color;
        this.userService.getCurrentUser.subscribe((user) => {
            if (Object.keys(user).length != 0) {
                console.log(user.profile.data);
                this.userService.setTimeZone(user.profile.data.timezone);
            }
        })
    }

    public filterPosts():void {
        const {start,end}:{start:Date,end:Date} = this.filterPost.value;
        this.sensorService.setFilterTrigger(true);
        this.sensorService.setPostFromDate(start);
        this.sensorService.setPostToDate(end);
        this.sensorService.filter.next({
            from:start.toISOString(),
            to:end.toISOString()
        });
    }

    public filterReports():void {
        console.log(this.reportFilterCtrl.value);
        this.sensorService.setReportSearchString(this.reportFilterCtrl.value);
        const {start,end}:{start:Date,end:Date} = this.filterReport.value;
        this.sensorService.setFilterTrigger(true);
        this.sensorService.setReportFromDate(start);
        this.sensorService.setReportToDate(end);
        this.sensorService.reportFilter.next({
            from:start.toISOString(),
            to:end.toISOString(),
            search: this.reportFilterCtrl.value
        });
    }

    public exportPostData(): void {
        this.sensorService.setExportPostDataTrigger(true);
    }

    public drillData():void{
        const {sensor,start,aggBy,end}:{sensor:number,start:Date,aggBy:string,end:Date} = this.drill.value;
        this.sensorService.setName(true);
        this.sensorService.setFromDate(start);
        this.sensorService.setToDate(end);
        this.sensorService.setAgg(aggBy);
        this.sensorService.drill.next({
            sensor:sensor,
            from:start.toISOString(),
            agg_by:aggBy,
            to:end.toISOString(),
            compare:0
        });
    }

    public collapseSidebar(): void {
        this.navServices.collapseSidebar.next(!this.navServices.collapseSidebar);
    }

    public openSecondNav(): void {
        this.openNav = !this.openNav;
    }

    public changeLanguage(lang: string): void {
        this.translate.use(lang);
        localStorage.setItem('language', lang);
        this.navServices.setCurrentLanguage(lang);
        this.language = lang ? lang : 'en';
    }

    public closeFarmDropdown(): void {
        this.farmAddMenu = !this.farmAddMenu;
        this.hoverPark = false;
        this.hoverPlantation = false;
}

    public logOut(): void {
        this.cacheService.clearAllCache();
        this.authService.logout().subscribe(data => {
            console.log(data);
            const countryData = localStorage.getItem('country_info');
            const language = localStorage.getItem('language');
            localStorage.clear();
            localStorage.setItem('country_info', countryData);
            localStorage.setItem('language', language);
            this.globalRegistry.isLoggedIn = false;
        });
        this.router.navigate(['login']);
    }

    public clearNotesFilter(): void {
        this.noteFilterCtrl.reset("");
        const params = window.location.search
          ? this.globalRegistry.getQueryStringParams(window.location.search)
          : {};
        delete params["filter"];
        this.router.navigate(["/pages/notes"], {
          queryParams: params,
          queryParamsHandling: "",
        });
      }

      public clearActivitiesFilter(): void {
        this.activityFilterCtrl.reset("");
        const params = window.location.search
          ? this.globalRegistry.getQueryStringParams(window.location.search)
          : {};
        delete params["filter"];
        this.router.navigate(["/pages/activities"], {
          queryParams: params,
          queryParamsHandling: "",
        });
      }

      public clearReportsFilter(): void {
        this.reportFilterCtrl.reset("");
        const params = window.location.search
          ? this.globalRegistry.getQueryStringParams(window.location.search)
          : {};
        delete params["filter"];
        this.router.navigate(["/pages/alergotel_reports"], {
          queryParams: params,
          queryParamsHandling: "",
        });
      }

      public clearEquipmentsFilter(): void {
        this.equipmentFilterCtrl.reset("");
        const params = window.location.search
          ? this.globalRegistry.getQueryStringParams(window.location.search)
          : {};
        delete params["filter"];
        this.router.navigate(["/pages/equipments"], {
          queryParams: params,
          queryParamsHandling: "",
        });
      }

      public clearDevicesFilter(): void {
        this.deviceFilterCtrl.reset("");
        const params = window.location.search
          ? this.globalRegistry.getQueryStringParams(window.location.search)
          : {};
        delete params["filter"];
        this.router.navigate(["/pages/devices"], {
          queryParams: params,
          queryParamsHandling: "",
        });
      }

    // if the filters change => modify the query params
    public changedFilters(filterType: string): void {
        const originalParams: {} = this.activatedRoute.snapshot.queryParams;

        if(filterType === 'farms'){
            this.selectedLots.reset();
            this.selectedLot.reset();
            this.globalRegistry.selectedDate = '';
            this.selectedType.reset();
        }

        if(filterType === 'lots'){
            this.globalRegistry.selectedDate = '';
            this.selectedType.reset();
        }
        const farms: number[] = this.selectedFarms.value;
        const lots: number[] = this.selectedLots.value;
        const farm: number = this.selectedFarm.value;
        const lot: number = this.selectedLot.value;
        const groups: number[] = this.selectedGroups.value;
        const queryParams: {} = Object.assign({}, originalParams);
        queryParams['farms'] = farms && farms.length > 0 ? farms : undefined;
        queryParams['lots'] = lots && lots.length > 0 ? lots : undefined;
        queryParams['farm'] = farm || undefined;
        queryParams['lot'] = lot || undefined;
        queryParams['groups'] = groups && groups.length > 0 ? groups : undefined;

        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams
        });
    }

    public clearInput(inputFilter: HTMLInputElement): void {
        inputFilter.value = '';
    }

    public emitOpenDialog(): void {
        this.isSelectDialogOpen = !this.isSelectDialogOpen;
        this.baseDialogEvent.emit(this.isSelectDialogOpen);
    }

    public checkSubmit(): boolean {
        if (this.farmSubmitEnable && this.invalidForm) {
            return false;
        } else if (this.farmSubmitEnable || this.invalidForm) {
            return true;
        } else {
            return true;
        }
    }

    
  getQueryStringParams = (query) => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split("&")
          .reduce((params, param) => {
            const [key, value] = param.split("=");
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, " "))
              : "";
            return params;
          }, {})
      : {};
  };

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        const filtered: string[] = [];
        this.globalRegistry.systemData.lots.forEach((option: LotModel) => {
            filtered.push(option.name);
        });
        return filtered.filter((option: any) => option.toLowerCase().includes(filterValue));
    }

    public onSearchSubmit(page:string,value: string): void {
        console.log(value);
        // const params = window.location.search? this.getQueryStringParams( window.location.search) :{};
        this.router.navigate(['/pages/',page], {
            queryParams: {filter: value},
            queryParamsHandling: ''
        });
    }

    public getFormTypes() {
        this.typesService.getHeaderFormTypes.subscribe(data => {
            this.formTypes = data;
        })
    }

    public newFormType() {
        this.router.navigate(['/pages/forms/'], {
            queryParams: {popup: true},
            queryParamsHandling: ''
        });
    }

    public editFormType(id: number) {
        this.router.navigate(['/pages/forms/'], {
            queryParams: {edit: id},
            queryParamsHandling: ''
        });
    }

    public deleteFormType(id: number) {
        this.router.navigate(['/pages/forms/'], {
            queryParams: {delete: id},
            queryParamsHandling: ''
        });
    }

    public getWorkflowTypes() {
        this.typesService.getHeaderWorkflowTypes.subscribe(data => {
            this.workflowTypes = data;
            console.log(this.workflowTypes);
        })
    }

    public editWorkflowType(id: number) {
        this.workflowId = id;
        this.router.navigate(['/pages/workflows/'], {
            queryParams: {edit: id},
            queryParamsHandling: ''
        });
    }

    public setWorkflowId(id: number) {
        console.log(id);
        this.typesService.setWorkflowTypeId(id);
    }

}
