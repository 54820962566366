import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges } from "@angular/core";
import { WidgetModel } from "../../../core/models/widget/widget.model";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
} from "ng-apexcharts";
import { SensorModel } from "../../../core/models/sensor/sensor.model";
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import * as moment from "moment";
import { Subscription } from 'rxjs';
import { ObjectModel } from "../../../core/services/api/objects/object.service";
import { UnitTypeModel } from "../../../core/services/api/unit-type/unit-type.service";
import { NavService } from "../../services/nav.service";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  colors:any; //ApexColors
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: any;
  grid: any;
};

@Component({
  selector: "fap-chart-widget",
  templateUrl: "./fap-chart-widget.component.html",
  styleUrls: ["./fap-chart-widget.component.scss"],
})
export class FapChartWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];

  @Output()
  public emitShow:EventEmitter<boolean> = new EventEmitter<boolean>();

  public chartOptions: Partial<ChartOptions>;
  public localSensors = [];
  public finalSensorList = [];
  public loadedData = [];
  public newData;
  public seriesData = [];
  public toggleData = [];
  public listDate = [];
  public fromDate;
  public toDate;
  public agg;
  public toggleMain = false;
  public toggleSub = false;
  public cssClass = '';
  public randomId;
  public subscriptions: Array<Subscription> = [];
  public dates =[];
  public translatedNames: any = [];
  public langString: string;
  // apiCalled = false;
  public isLoading = false;
  public noData = false;
  public showMinMax = false;

  constructor(public globalRegistry:GlobalRegistryService,public sensorService:SensorService, public navService: NavService) {
    this.chartOptions = {
      series: this.seriesData,
      chart: {
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
          tools: {
            download: false,
          }
        },
        stackType: 'normal'
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: "10px",
        }
      },
      stroke: {
        width: [2, 2, 2],
        curve: 'smooth'
      },
      xaxis: {
        categories: this.dates,
        tickAmount: 5,
        labels: {
          formatter: function(value) {
            return value
          }
        }
      },
      grid: {
        clipMarkers: false
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
              fontSize: '10px'
            },
            show: false,
          },
          title: {
            text: "",
            style: {
              color: "#008FFB",
              fontSize: '10px'
            },
          },
          tooltip: {
            enabled: true,
          },
        }
      ],
      tooltip: {
        enabled: true,
        position: "topLeft",
        offsetY: 30,
        offsetX: 60,
          fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: "left",
        show: false,
        fontSize: "10px"
      },
    };
  }

  ngOnInit() {
    this.randomId = Math.random();
    this.getFromDate();
    this.getToDate();
    this.getAgg();
   
    this.subscriptions.push(this.sensorService.getName.subscribe(res => {
      if(res) {
        this.seriesData = [];
        this.loadedData = [];
        this.getRealTimeData();
        // this.apiCalled = false;
      }
    }));
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
  }

  getFromDate() {
    this.subscriptions.push(this.sensorService.getFromDate.subscribe(res => {
      this.fromDate = res;
    }));
  }

  getToDate() {
    this.subscriptions.push(this.sensorService.getToDate.subscribe(res => {
      this.toDate = res;
    }));
  }

  getAgg() {
    this.subscriptions.push(this.sensorService.getAgg.subscribe(res => {
      this.agg = res;
    }));
  }

  getTranslation(translation) {
    const t = this.translatedNames.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
        return translation
    }
}

  ngOnDestroy () {
    this.subscriptions.forEach(e => {
      e.unsubscribe();
    });
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  showMenu() {
    this.toggleSub = !this.toggleSub
    this.getRealTimeData();
    if(this.toggleSub == true) {
      this.chartOptions = {...this.chartOptions, ...{
        chart: {
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: true,
            }
          },
        },
        legend: {
          show: false,
        }
      }};
    } else {
      this.chartOptions = {...this.chartOptions, ...{
        chart: {
          type: "line",
          toolbar: {
            show: false,
            tools: {
              download: false,
            }
          },
        },
        legend: {
          show: false,
        }
      }};
    }
  }

  hideMain() {
    this.toggleMain = false
  }

  ngOnChanges() {
    this.getRealTimeData();
  }

  public getRealTimeData() {
    // if (!this.apiCalled) {
      this.isLoading = true;
    this.loadedData = [];
    this.seriesData = [];
    // const options = this.widget['settings']['options'];
    console.log(this.widget);
    this.cssClass = this.widget['sizeClass'];
      setTimeout(()=> {
        const sensors: Array<SensorModel> = this.widget["settings"]["options"].map(
          (sensor: { sensor: SensorModel }) => sensor?.sensor
        );
        const sensorIds = sensors.map((sensor) => sensor.id);
        this.subscriptions.push(this.sensorService.advdrillData({ sensors: sensorIds.toString(), from:this.fromDate.toISOString(),agg_by: this.agg, to:this.toDate.toISOString(), agg_data:'mean,sum,min,max', compare:0}).subscribe(data1 => {
          console.log(data1)
          const Newdata = data1.results;
          const data = Newdata['0'];
          console.log(data);
          if(data.labels.length === 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
        setTimeout(()=> {
          const objects = Object.values(this.objects);
          const unitTypes = Object.values(this.unitTypes);
          const arr = Object.keys(data).map(function (key) {
            return { ...data[key], sensor: key };
          });
          arr.pop();
          const datesData = Object.keys(data).map(function (key) {
            return { [key]: { ...data[key]} };
          });
          const dates = datesData.pop();
          const datesArr = Object.values(dates.labels);
          const seriesDates = [];
          // function toDateTime(secs) {
          //   var t = new Date(1970, 0, 1); // Epoch
          //   t.setSeconds(secs).toLocaleString('en-US');
          //   return t;
          // }
          datesArr.forEach((element: any) => {
            const date = new Date(element * 1000);
            seriesDates.push(date.toLocaleString('en-US'));
          });
          console.log(seriesDates);
          this.dates = seriesDates
          this.widget["settings"]["options"] = this.widget["settings"]["options"].map((item) => {
            return {
              ...item,
              unitTypes: unitTypes.filter(el => el.id === item.sensor.unitType),
              objects: objects.filter(el => el.id === item.sensor.object_id),
          }
          });

        //  arr.forEach(element => {
        //    element = Object.values(element)
        //  });

        // console.log(arr);

         this.widget['settings']['options'] = this.widget['settings']['options'].map((item) => {
          return {
            ...item,
            data: arr.filter(o1 => o1.sensor == item.sensor.id)
          };
        });
        console.log(this.widget['settings']['options']);
          
        for (let i = 0; i < this.widget['settings']['options'].length; i++) {
          const aggrSeries = this.widget["settings"]["options"].map((type) => {
            
            return {
              name: type.data[0].name + '(' + type.unitTypes[0].unit + ')',
              type: type.graphType,
              data: type.data[0][type.data[0].aggr_type]
            };
          });
        
          const minSeries = this.widget["settings"]["options"].map((type) => {
            return {
              name: type.data[0].name + '(' + type.unitTypes[0].unit + ') - Min',
              type: type.graphType,
              data: type.data[0]['min']
            };
          });
        
          const maxSeries = this.widget["settings"]["options"].map((type) => {
            
            return {
              name: type.data[0].name + '(' + type.unitTypes[0].unit + ') - Max',
              type: type.graphType,
              data: type.data[0]['max']
            };
          });
        
          const toggleSeries = this.widget["settings"]["options"].map((type) => {
            return {
              name: type.data[0].name + '(' + type.unitTypes[0].unit + ')',
              type: type,
              data: type.data[0]
            };
          });
        
          if(this.showMinMax && this.agg != 'hour') {
            this.seriesData = [...aggrSeries, ...minSeries, ...maxSeries];
          } else {
            this.seriesData = aggrSeries
          }
          this.toggleData = toggleSeries;
        }            
     
        console.log(this.seriesData);
          
          this.seriesData.forEach(element => {
            element.name = element.name.replace(/null/g, "");
          });

          this.chartOptions.series = this.seriesData;
          this.chartOptions.colors = this.widget['settings']['options'].map((type)=>type.color);
          
          if(this.chartOptions.series.length === 1) {
            this.chartOptions.chart.type = this.seriesData[0].type;
          }

          // console.log(this.chartOptions);
          const differentYear = false;
          this.chartOptions.xaxis.categories = this.dates;
               
          if (this.agg === 'hour') {
            this.chartOptions.xaxis.labels = {
              formatter: val => {
                const formatString = differentYear ? 'DD MMM YYYY, HH' : 'DD MMM, HH';
                return moment.utc(val).local().format(formatString);
              }
            };
          } else if (this.agg === 'day') {
            this.chartOptions.xaxis.labels = {
              formatter: val => {
                const formatString = differentYear ? 'DD MMM YYYY' : 'DD MMM';
                return moment.utc(val).local().format(formatString);
              }
            };
          } else if (this.agg === 'month') {
            this.chartOptions.xaxis.labels = {
              formatter: val => moment.utc(val).local().format('MMM YYYY')
            };
          }
          
          //  console.log(this.widget['settings']['options']);
          function parentWidth(elem) {
            return elem.parentElement.clientHeight;
          }
         this.chartOptions.chart.height = parentWidth(document.getElementById('parent'+this.randomId));
         if(this.showMinMax && this.agg != 'hour') {
          const tooltip = {
            enabled: true,
            custom: ({ dataPointIndex }) => {
              let tooltipContent = '';
              for (let i = 0; i < this.toggleData.length; i++) {
                const currentSeries = this.toggleData[i];
                const min = currentSeries.data.min[dataPointIndex];
                const max = currentSeries.data.max[dataPointIndex];
                const val = currentSeries.data[currentSeries.data.aggr_type][dataPointIndex];
                tooltipContent += `
                  <div class="custom-apex-tooltip custom-apex-theme-light">
                    <div class="custom-apex-tooltip-title">
                      <span class="custom-apex-tooltip-marker" style="background-color: ${currentSeries.type.color};"></span>
                      ${currentSeries.name}
                    </div>
                    <div class="custom-apex-tooltip-series-group">
                      <div class="custom-apex-tooltip-text">
                        <div class="custom-apex-tooltip-y-group">
                          <span class="custom-apex-tooltip-text-label">${currentSeries.data.aggr_type}: </span>
                          <span class="custom-apex-tooltip-text-value">${val ? val : 0}</span>
                        </div>
                        <div class="custom-apex-tooltip-y-group">
                          <span class="custom-apex-tooltip-text-label">Min: </span>
                          <span class="custom-apex-tooltip-text-value">${min ? min : 0}</span>
                        </div>
                        <div class="custom-apex-tooltip-y-group">
                          <span class="custom-apex-tooltip-text-label">Max: </span>
                          <span class="custom-apex-tooltip-text-value">${max ? max : 0}</span>
                        </div>
                      </div>
                    </div>
                  </div>`;
              }
            
              return tooltipContent;
            }
            
           }
           this.chartOptions.tooltip = tooltip;
         } else {
          const tooltip = {
            enabled: true
          }
          this.chartOptions.tooltip = tooltip;
         }
        console.log(this.seriesData);
         const dashArray = [];
         const opacity = [];

          for (const series of this.seriesData) {
            if (series.name.includes('- Min') || series.name.includes('- Max')) {
              dashArray.push(1);
              opacity.push(0.5);
            } else {
              dashArray.push(0);
              opacity.push(1);
            }
          }

          console.log(dashArray);
         
         const stroke = {
            width: 3,
            curve: "smooth",
            dashArray: dashArray
         }
         this.chartOptions.stroke = stroke
         this.chartOptions.fill = {opacity}
          this.chartOptions.yaxis = this.widget['settings']['options'].map(
              (type, index) => ({
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: type.color,
                },
               
                seriesName: type.data[0].name + '(' +type.unitTypes[0].unit +')',
                show: type.data[0].show,
                title: {
                  text: type.data[0].title + '(' +type.unitTypes[0].unit +')',
                  style: {
                    color: type.color,
                  },
                },
                tooltip: {
                  enabled: true,
                },
                opposite: index % 2 ? true : false,
                // min: type.data[0].min[0],
                // max: type.data[0].max[1]
              })
            );
        })
      }))
        this.sensorService.setName(false);
        this.isLoading = false;
      }, 1000)
    // }
    // this.apiCalled = true;
  }

  public tglValues() {
    this.showMinMax = !this.showMinMax;
    this.getRealTimeData();
  }

  public getLotName(lotId:number=this.widget.objectId):string{
    if(lotId != 0) {
      return this.globalRegistry.systemData.lots.find(lot=>lot.id===lotId).name;
    } else {
      return ''
    }
  }

}
