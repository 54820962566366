import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getProductUnit'
})
export class getProductUnitPipe implements PipeTransform {
  public transform(product: any, units: any[]): string {
    let quantityPack = '';
    let unitPack = '';
    let quantity = '';
    let unit = '';

    if (units && units.length) {
      units.forEach(u => {
        if (product?.quantity_pack && u.id === product?.pack_unit) {
          quantityPack = `${product?.quantity_pack > 1 ? product?.quantity_pack : ''}`;
          unitPack = u.shortName;
        }

        if (u.children && u.children.length > 0) {
          u.children.forEach(child => {
            if (product?.quantity_pack && child.id === product?.pack_unit) {
              quantityPack = `${product?.quantity_pack > 1 ? product?.quantity_pack : ''}`;
              unitPack = child.shortName;
            }

            if (child.id === product?.unit) {
              quantity = `${product?.quantity}`;
              unit = child.shortName;
            }
          });
        } else {
          if (u.id === product?.unit) {
            quantity = `${product?.quantity}`;
            unit = u.shortName;
          }
        }
      });

      let result = '';

      if(quantityPack) {
        result += `${quantityPack}`
      }

      if(unitPack) {
        result += `${unitPack}`
      }

      if (quantity && unit) {
        if (result) {
          result += ` ${quantity}${unit}`;
        } else {
          result = `${quantity}${unit}`;
        }
      }

      return result;
    }
  }
}
