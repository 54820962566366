import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';

@Component({
    selector: 'fap-activities-layout',
    templateUrl: './fap-activities-layout.component.html',
    styleUrls: ['./fap-activities-layout.component.scss'],
})
export class FapActivitiesLayoutComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public farms: Array<FarmModel>;

    @Input()
    public activityTypes: Array<ActivityModel>;

    @Input()
    public activities: Array<ActivityModel>;

    @Input() public persons

    @Output()
    public addNote: EventEmitter<ActivityModel> = new EventEmitter();
  
    @Output()
    public updateNote: EventEmitter<ActivityModel> = new EventEmitter();
  
    @Output()
    public deleteActivity: EventEmitter<number> = new EventEmitter();

    public selectedActivity: ActivityModel;
    public equipments: EquipmentModel[];
    public removeActivityModalButtonPrimaryInterface: FapModalButtonInterface;
    public removeActivityModalButtonSecondaryInterface: FapModalButtonInterface;
    public subscription: Subscription
    @ViewChild('removeActivityModal')
    public removeActivityModal: FapModalComponent;
    public activitiesByFarm: { [key: string]: ActivityModel[] } = {};
    public activitiesWithNoFarm: Array<ActivityModel> = [];
    public isLoading = false;

    constructor(private translateService: TranslateService, private equipmentsService:EquipmentService, public widgetService:WidgetsService, public companyService: CompanyService) {
        this.initRemoveEquipmentModalButtons();
        this.equipmentsService.getEquipments().pipe(take(1)).subscribe((equipments: ResponseModel<EquipmentModel[]>): void => {
          this.equipments = equipments.model;
          console.log(this.equipments);
      });
    }

    public showRemoveEquipmentModal(activity: ActivityModel): void {
        this.selectedActivity = activity;
        this.removeActivityModal.showModal();
    }


    private initRemoveEquipmentModalButtons(): void {
        this.removeActivityModalButtonSecondaryInterface = {
            clickFunction: (): void => {
                this.deleteActivity.emit(this.selectedActivity.id);
                this.removeActivityModal.hideModal();
            },
            text: this.translateService.instant('delete'),
        };
        this.removeActivityModalButtonPrimaryInterface = {
            clickFunction: (): void => {
                this.removeActivityModal.hideModal();
            },
            text: this.translateService.instant('cancel'),
        };
    }

    ngOnInit(): void {
        this.isLoading = true;
        if(this.activities) {
          this.isLoading = false;
        }
        this.widgetService.setSize(7);
    }

    ngOnChanges(): void {
        this.isLoading = false;
    }

    ngOnDestroy(): void {
        this.widgetService.setSize(6);
    }
}
