<div class="dashboard-card dash_cont">
    <div class="row">
        <div class="col-md-4">
            <h1 class="pool_title">
                <ng-container *ngIf="widget['nameT']">{{ getTranslation(widget["nameT"]) }}</ng-container>
                <ng-container *ngIf="widget['name_t']">{{ getTranslation(widget["name_t"]) }}</ng-container>
            </h1>
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li *ngIf="editValue" (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                    <li *ngIf="!editValue" (click)="emitDelete.emit(widget)"><span>{{"widget.delete" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-content" (click)="goToDeviceView()">
        <div class="pool_top">
            <div class="pool_img" style="min-height: 244.4px;">
                <img *ngIf="imageData?.file" [src]="mediaUrl + imageData?.file" alt="">
                <span class="empty_border"></span>
            </div>
            <div class="pool_weather" *ngIf="weatherData?.forecast">
                <ng-container *ngFor="let item of getObjectValues(weatherData?.forecast) | slice:0:1">
                    <div class="current_weather" >
                        <div class="icon">
                            <img [src]="mediaUrl + item?.weather_code?.img" alt="">
                        </div>
                        <div class="text">
                            <h6 class="clearfix">
                                <span class="value">{{ item.temperature.max }}</span> <span class="unit">{{ [unitTypes, item?.temperature?.unit] | getUnit }}</span>
                            </h6>
                            <div class="rain_and_wind">
                                <div class="temp_value rw">
                                    <div class="main_val clearfix">
                                        <span class="value">{{ item.rainfall.value }}</span>
                                        <span class="unit">{{ [unitTypes, item?.rainfall?.unit] | getUnit }}</span>
                                    </div>
                                </div>
                                <div class="temp_value rw">
                                    <div class="main_val cleafix">
                                        <span class="value">{{ item.wind_speed.value }}</span>
                                        <span class="unit">{{ [unitTypes, item?.wind_speed?.unit] | getUnit }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="wgrids">
                    <div class="wgrid" (click)="goToDeviceView(temperatureData?.element)">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/hot-water.svg" alt="">
                        </div>
                        <div class="text">
                            <div class="value">
                                {{temperatureData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{temperatureData?.element?.unit?.unit}}
                            </div>
                        </div>
                    </div>
                    <div class="wgrid">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/light-bulb.png" alt="">
                        </div>
                        <div class="text">Manual</div>
                    </div>
                    <div class="wgrid">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/filter.png" alt="">
                        </div>
                        <div class="text">Auto</div>
                    </div>
                    <div class="wgrid">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/virus.png" alt="">
                        </div>
                        <div class="text">{{50 - 1 | random:1}} gr/h</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pool_bottom">
            <div class="circle_bar" *ngIf="phData?.element?.value_float">
                <div class="trigger_wizard" (click)="triggerWorkFlow()" *ngIf="!(phData?.element?.value_float >= 7 && phData?.element?.value_float <= 7.8)">
                    <img src="../../../../assets/icons/warning.svg" alt="warning">
                </div>                
                <circle-progress-bar [progressValue]="phData?.element?.value_float" [type]="'ph'" [minValue]="0" [maxValue]="14" [fillColorRange]="{ min: 0, max: 14 }"></circle-progress-bar>
                <div class="text">PH</div>
            </div>
            <div class="circle_bar" *ngIf="redoxData?.element?.value_float">
                <div class="trigger_wizard" (click)="triggerWorkFlow()" *ngIf="redoxData?.element?.value_float < 700">
                    <img src="../../../../assets/icons/warning.svg" alt="warning">
                </div>
                <circle-progress-bar [progressValue]="redoxData?.element?.value_float" [type]="'redox'" [minValue]="0" [maxValue]="900" [fillColorRange]="{ min: 0, max: 9000 }"></circle-progress-bar>
                <div class="text">Redox</div>
            </div>
        </div>
    </div>
</div>

<fap-modal #addEditPoolModal
[inputTitle]="widget?.nameT ? getTranslation(widget?.nameT) : widget?.name" [inputIsLarge]="true" [showFooter]="false">
    <fap-attributes
        [entityRelation]="entityRelation"
        [entityId]="entityInfo?.id"
        [entityInfo]="entityInfo"
        [objectId]="objectId"
        (closeModal)="hideModal()"
        (formUpdate)="onUpdateAction()"
        [type]="'edit'"
        [isDynamic]="isDynamic">
    </fap-attributes>
</fap-modal>