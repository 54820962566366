<div class="container-fluid nc" style="padding: 10px;">
    <fap-edit-notes
             [lots]="lots"
             [farms]="farms"
             [types]="types"
             [note]="note"
             [noteId]="noteId"
             (submitNote)="submitNote($event)"
             (updateNote)="updateNote($event)"
             (deleteNote)="deleteNote($event)"
             >
    </fap-edit-notes>
</div>
