import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from '../../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ProductModel } from '../../../../../core/models/stock/product.model';
import { BillingService } from '../../../../../core/services/api/billing/billing.service';
import { PartyService } from '../../../../../core/services/api/company/party.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { FapModalComponent } from '../../../../../shared/partials';

@Component({
  selector: 'my-products-layout',
  templateUrl: './my-products-layout.component.html',
  styleUrls: ['./my-products-layout.component.scss']
})
export class MyProductsLayoutComponent implements OnInit, OnChanges {

  @Input() assets:any[] = [];
  public subscriptions: Array<Subscription> = [];
  public listedProducts = [];
  public mediaUrl = environment.mediaUrl;
  public isLoading = false;
  public product: ProductModel;
  public buyOfferForm: UntypedFormGroup;
  public partyToken: { limit: number; offset: number; search: string; own: number} = null;
  public assetToken: { limit: number; offset: number } = null;
  public getMoreParties = true;
  public limit = 10;
  public ownParties = [];
  public getMoreAssets = true;
  @ViewChild('addEditPartyModal')
    public addEditPartyModal: FapModalComponent;
    public selectedParty;

  constructor(public activatedRoute: ActivatedRoute, public router: Router, public billingService: BillingService, public stockService: StockService, public partyService: PartyService, public globalRegistry: GlobalRegistryService, public toastyService: ToastrService, public translateService: TranslateService, public cacheService: CacheResolverService) { 
    this.initBuyOfferForm();
  }

  ngOnInit(): void {
    // this.getParties();
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(data => {
        console.log(data);
        if(data && data['popup']) {
          this.showModal();
        }
      })
    );
  }

  showModal() {
    this.getListedProducts();
    const elem = document.getElementById('offers_modal');
    const parent = document.getElementById('parent91');
    elem.classList.add('active');
    parent.classList.add('backdrop');
  }

  hideModal() {
    const elem = document.getElementById('offers_modal');
    const parent = document.getElementById('parent91');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.router.navigate(['pages/my-products'])
  }

  showBuyOptionsModal(assetId:number, productId:number) {
    this.getListedProducts();
    const elem = document.getElementById('buy_options_modal');
    elem.classList.add('active');
    this.buyOfferForm.get('id').patchValue(assetId);
    this.getProduct(productId);
  }

  hideBuyOptionsModal() {
    const elem = document.getElementById('buy_options_modal');
    const parent = document.getElementById('parent91');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.buyOfferForm.reset();
    this.buyOfferForm.clearValidators();
    this.product = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'assets')) {
      console.log(this.assets);
  }
  }

//   onScrollProducts(event: any) {
//     this.scrolledDown();
// }

filterOwnParties(value) {
  console.log(value);
  this.subscriptions.push(this.partyService.getParties({own: 1, search: value}).subscribe(data => {
    console.log(data);
    this.partyToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
    this.ownParties = data.body.results;
    if(this.partyToken) this.getMoreParties = true
  }))
}

scrolledDown() {
  const url = this.billingService.getUrl('offers/');
  console.log('assets scrolled');
  if(this.getMoreAssets) {
    console.log(this.assetToken);
    this.assetToken &&
      this.subscriptions.push(this.billingService.getListedProducts(this.assetToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.assetToken.limit+'&offset='+this.assetToken.offset)
        console.log(data);
        if(this.listedProducts) {
          this.listedProducts = [...this.listedProducts, ...data.body.results];
        } else {
          this.listedProducts = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreAssets = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.assetToken.offset != params['offset']) {
          this.assetToken = {limit: params['limit'], offset: params['offset']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
}

  initBuyOfferForm() {
    this.buyOfferForm = new UntypedFormGroup({
      id: new UntypedFormControl(null, Validators.required),
      quantity: new UntypedFormControl(1, Validators.required),
      farm: new UntypedFormControl(null, Validators.required),
      billedTo: new UntypedFormControl(null, Validators.required)
    })
  }

  getListedProducts() {
    const url = this.billingService.getUrl('offers/');
    this.subscriptions.push(this.billingService.getListedProducts({limit: this.limit}).subscribe(data => {
      this.cacheService.delete(url+'limit='+this.limit);
      console.log(data);
      this.listedProducts = data.body.results;
      this.assetToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.isLoading = false;
      if(this.assetToken) this.getMoreAssets = true;
    }))
  }

  public getProduct(productId:number) {
    this.stockService.getProduct(productId).subscribe(data => {
      console.log(data);
      this.product = data.model;
    })
  }

  scrollParties() {
    const url = this.partyService.getUrl('');
    console.log('parties scrolled');
    if(this.getMoreParties) {
      this.partyToken &&
        this.subscriptions.push(this.partyService.getParties(this.partyToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.partyToken.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search+'&own=1');
          console.log(data);
          if(this.ownParties) {
            this.ownParties = [...this.ownParties, ...data.body.results];
          }
          if(data.body.next == null) {
            this.getMoreParties = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.partyToken.offset != params['offset']) {
            this.partyToken = {limit: params['limit'], offset: params['offset'], search: params['search'], own: 1};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrollParties();
    }
  }

  public editParty(party: PartyModel): void {
    this.selectedParty = party;
    this.addEditPartyModal.showModal();
}

public onDeleteParty(party: PartyModel, control): void {
  this.partyService.deleteParty(party.id).pipe(take(1)).subscribe(
      (): void => {
        this.buyOfferForm.controls[control].setValue(null);
        this.getParties();
          this.toastyService.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
      }, (): void => {
          this.toastyService.success(this.translateService.instant('people.party.errorDeletingParty'));
      }
  );
}

public getParties() {
  this.subscriptions.push(this.partyService.getParties({ limit: this.limit, own: 1 }).subscribe((data: ResponseModel<PartyModel[]>): void => {
    console.log(data);
    this.ownParties = data.body.results;
    this.buyOfferForm.get('billedTo').patchValue(this.ownParties[0].id);
    this.partyToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.partyToken) this.getMoreParties = true;
  }))
}

public addPartyModal(): void {
  this.selectedParty = null;
  this.addEditPartyModal.showModal();
}

public addParty(ev) {
  this.ownParties.push(ev);
  // this.globalRegistry.reloadParties();
  this.addEditPartyModal.hideModal();
}

public addedOrUpdatedParty(ev) {
const evArr = [ev];
evArr.map(x => {
  const index = this.ownParties.findIndex(d=> d.id === x.id)
  this.ownParties[index] = x  
});
this.addEditPartyModal.hideModal();
}

paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  console.log(result);
  return result;
}

  buyProduct() {
    console.log(this.buyOfferForm.value);
    if(this.buyOfferForm.invalid){
      this.buyOfferForm.markAllAsTouched();
      this.toastyService.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
      return
    } else {
      this.billingService.buyProduct(this.buyOfferForm.value).subscribe(data => {
        if(data) {
          this.hideBuyOptionsModal();
          this.buyOfferForm.reset();
          this.buyOfferForm.clearValidators();
          this.hideModal();
          this.assets.push(data.body.results);
        }
      })
    }
  }
}
