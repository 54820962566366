<div *ngFor="let farm of farms" class="cust_pad">
    <fap-farm-header
    [key]="key"
    [addRoute]="'lot'"
    [farm]="farm"
    [forecast]="farmForecastMapping[farm.id]">
    </fap-farm-header>
    <div class="lots_wrap" *ngIf="lots.length">
        <ng-template ngFor [ngForOf]="lots" let-lot>
            <fap-lot-card
                [key]="key"
                class="fap-lot-card mx-0"
                *ngIf="lot.farm === farm.id"
                [inputTitle]="lot.name"
                [lot]="lot"
                [currentCrop]="lot.currentCrop"
                [preview]="getLotImage(lot)"
                [surfaceArea]="'1,2 ha'"
                (click)="clickedLot.emit(lot)"
                (deleteLot)="onDeleteLot($event)">
            </fap-lot-card>
        </ng-template>
    </div>
</div>
