<form class="theme-form row" [formGroup]="resourceTypeForm" id="parent">
    <div class="form-group col-md-12">
        <mat-form-field class="w-100" (click)="addEditTranslation()">
            <input matInput placeholder="Name" formControlName="name" readonly #nameInput> 
            <mat-error *ngIf="ifControlHasError('name','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <!-- <div class="form-group col-md-12">
        <mat-form-field class="w-100">
            <mat-label class="col_form-label">{{ 'Translation' }}</mat-label>
            <mat-select #mySelect formControlName="name_t" name="name_t" [value]="selectedResource?.nameT">
                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #nFilter placeholder="Search">
                </mat-form-field>
                <em class="fa fa-plus center f-20 ml-2" (click)="showModal()"></em>
            </div>
            <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option class="noteIcons" *ngFor="let type of translatedNames | filter : nFilter.value" [value]="type.id">
                    <div class="name"><span>{{ type[langString] ? getTranslation(type[langString]) : type.id  }}</span></div>
                    <div class="actions">
                        <i class="fa fa-edit" (click)="editTranslation(type) ;$event.stopPropagation()"></i>
                        <i class="fa fa-trash" (click)="deleteTranslation(type?.id) ;$event.stopPropagation()"></i>
                    </div>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field>
</div> -->
    <div class="form-group col-md-12" *ngIf="resourceTypes">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label"><i class="fa fa-home"></i>{{ 'stock.parent' | translate }}</mat-label>
            <mat-select required formControlName="parent" [disabled]="!parentResource && selectedResource">
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <input matInput #resourceFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option [value]="'new'" class="font-weight-bold">
                    {{'units.newParent' | translate}}
                </mat-option>
                <mat-option *ngFor="let parent of resourceTypes | filter : resourceFilter.value" [value]="parent.id">
                    {{ parent.name }}
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifControlHasError('parent','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field> 
    </div>
    <div class="form-group col-md-12" *ngIf="formTypes">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'stock.formType' | translate }}</mat-label>
            <mat-select required formControlName="form_type">
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <input matInput #formTypeFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let type of formTypes | filter : formTypeFilter.value" [value]="type.id">
                    {{ type.name }}
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifControlHasError('form_type','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field> 
    </div>
    <div class="col-md-12">
        <div class="text-center">
            <i class="material-icons">format_color_fill</i>
            <div class="color-input ml-2">    
                <div>Color</div>
                <input class="w-100" type="color" formControlName="color">
                <mat-error *ngIf="ifControlHasError('color','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </div>
        </div>
    </div>
    <div class="col-md-12 mt-3 text-center">
        <button class="btn btn-lg" type="button" (click)="submit()">{{'resources.submitBtn' | translate}}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>
<!-- <div class="col-md-12 mt-3 text-center">
    <i class="material-icons file-input-icon">image</i>
    <input class="ml-2 w-80" type="file" name="Choose Icon">
</div> -->
<!-- <div class="col-md-12 text-center" style="margin-top: 3%;">
    <mat-form-field class="w-90" *ngIf="global.systemData.farms">
        <mat-label>{{'resources.farmType' | translate}}</mat-label>
        <mat-select matNativeControl [(ngModel)]="resources.selectedFarm">
            <mat-option class="row" *ngFor="let farmType of global.systemData.farms" [value]="farmType.id">
                <div class="farm-type-name">
                    {{ farmType.name }}
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div> -->
<!-- <div class="col-md-12 mt-3">
    <fap-ingredient-table [farmId]="farmId"></fap-ingredient-table>
</div>
<fap-modal #addEditIngredientModal inputTitle="Add/Edit Ingredient">
    <fap-add-edit-ingredient [farmId]="farmId"></fap-add-edit-ingredient>
</fap-modal> -->

<fap-modal #addEditTranslationsModal [inputTitle]="'Resource name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.selectedResource)" 
        [prefix]="prefix" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>
