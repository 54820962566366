<div
  class="container-fluid ter-container nc"
  infiniteScroll
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="500"
  (scrolled)="scrolledDown()"
  [scrollWindow]="false"
>
  <posts-layout
    [farms]="filteredFarms"
    [farmForecastMapping]="farmForecastMapping"
    (deletePost)="deletePost($event)"
    [posts]="filteredPosts"
    [persons]="globalRegistry?.systemData?.persons"
    [images]="images"
  >
  </posts-layout>
</div>
