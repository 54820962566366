<ng-container *ngFor="let lot of lots">
    <div class="card-header-wrapper d-flex theme_bg custom_header">
        <div class="rw d-flex" style="justify-content: space-between; width:100%">
            <div class="d-flex wrp">
            <h2 class="card-header-title custom_title" (click)="clickedLotTitle.emit(lot)">{{ lot.name }}</h2>
            <div [routerLink]="'/pages/lot/edit/' + lot.id"
                 [ngStyle]="{'cursor': 'pointer'}"
                 class="d-flex align-items-center px-2">
                <img src="assets/images/fap/pencil.svg" class="fa-pencil ml-1"/>
            </div>
            
            </div>
            <div class="btns d-flex" style="padding-right: 30px;">
                <!-- <i [routerLink]="'/pages/lot/edit/' + lot.id" class="fa fa-pencil mr-2 ter-btn-icon ter-btn-icon-success"></i> -->
                <div [routerLink]="'/pages/fields/create'" [queryParams]="{lot: lot.id}"
                 class="align-items-center">
                <fap-add-button [backgroundColor]="navService.accent_color" [color]="navService.headerBackgroundColor"></fap-add-button>
            </div>
                <!-- <i class="material-icons ter-btn-icon ter-btn-icon-danger" (click)="deleteLot.emit(lot.id)">delete_outline</i> -->
            </div>
        </div>
    </div>
    <div class="lots-grid">
        <fap-field-card [field]="field" [parties]="parties"
            *ngFor="let field of lotToFieldsMap[lot.id]"
            (click)="clickedFieldCard.emit(field)" (deleteField)="deleteField.emit($event)">
        </fap-field-card>
    </div>
</ng-container>
