import { FapBaseModel } from '../../base/fap-base-model';

export class SensorModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'object_id',
        'unit_type',
        'device',
        'type',
        'actions',
        'data',
        'action_type',
        'last_value',
        'icon',
        'name_t',
        'representation'
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public objectId: number;
    public unitType: number;
    public device: string;
    public type: string;
    public actions: any;
    public data: any;
    public actionType: string;
    public lastValue : any
    public icon: any;
    public nameT: string;
    public representation: any;
}
