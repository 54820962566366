<div class="dashboard-card dash_cont" style="position: relative">
    <div class="row">
        <div class="col-md-4">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name" *ngIf="getLotName() !== ''">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em> -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="showMenu()">{{"widget.menu" | translate}}</li>
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                    <li (click)="tglValues()">{{"widget.minMax" | translate}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div
        *ngIf="isLoading"
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 70%;
            width: 100%;
        "
    >
        <h3 style="text-align: center">
            <span class="loader">
                <i></i>
            </span>
        </h3>
    </div>
    <div style="height: calc(100% - 25px);" class="cheat_row">
      <div id="parent{{randomId}}" style="height: 100%; position: relative;">
        <div class="no_data" *ngIf="!isLoading && noData">
            {{'widget.noDataAvailable' | translate}}
        </div>
    <apx-chart
        style="height: 100%;"
        *ngIf="seriesData.length != 0 && !isLoading && !noData"
        [series]="chartOptions?.series"
        [chart]="chartOptions?.chart"
        [xaxis]="chartOptions?.xaxis"
        [markers]="chartOptions?.markers"
        [stroke]="chartOptions?.stroke"
        [yaxis]="chartOptions?.yaxis"
        [dataLabels]="chartOptions?.dataLabels"
        [fill]="chartOptions?.fill"
        [colors]="chartOptions?.colors"
        [tooltip]="chartOptions?.tooltip"
        [legend]="chartOptions?.legend"
    ></apx-chart>
  </div>
  </div>
</div>
