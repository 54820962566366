<form [formGroup]="productForm">
    <div class="form-group">
        <div class="form-group w-100">
            <div class="rw">
                <div class="col col-12 d-flex p-0">
                    <div class="d-flex" style="overflow-y: hidden;">
                        <div class="img_container">
                            <div class="images" style="display: flex;">
                                <div class="nimg" *ngFor="let imageId of productImages">
                                <ng-container *ngFor="let image of images">
                                    <div class="imgbox" *ngIf="image.id == imageId">
                                        <div class="delt_btn">
                                            <i class="fa fa-trash" (click)="deleteNoteImage(image?.id)"></i>
                                        </div>
                                        <img [src]="mediaUrl + image?.file" alt="" [attr.class]="image?.data?.orientation">
                                    </div>
                                </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="bx">
                            <mat-label style="display: block;"> {{ 'notes.images' | translate }} </mat-label>
                            <div class="input-group align-items-baseline w-100 img_bx">
                                <mat-form-field (click)="getImages()">
                                    <mat-label>{{ 'Images' | translate }}</mat-label>
                                    <mat-select #imageRef (selectionChange)="addImage($event)" name="image" required (infiniteScroll)="scrolledDownImages()" msInfiniteScroll> <!--Add formcontrol name here-->
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100 disbld">
                                            <input matInput #typeFilter disabled placeholder="Search">
                                        </mat-form-field>
                                        <div class="input-group-append pl-1" (click)="showImageModal()">
                                            <em class="fa fa-plus center f-20 ml-2"></em>
                                        </div>
                                    </div>
                                    <div class="scroll_wrap" (scroll)="onScroll($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
                                            <div class="name" style="margin: 5px;">
                                                <img style="height: 100px; width: 100%; object-fit: contain" [src]="mediaUrl + image.file" alt="image">
                                            </div>
                                            <div class="actions">
                                                <i class="fa fa-trash" (click)="deleteImage(image?.id); $event.stopPropagation()"></i>
                                            </div>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                </mat-form-field>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 10px; width: 100%;"></div>
        <div class="row align-items-baseline">
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput required placeholder="Name" formControlName="name">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>Code</mat-label>
                        <input matInput placeholder="Code" formControlName="code">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6" style="margin-bottom: -1rem;">
                <!-- <fap-add-edit-delete-select
                style="max-width: 100%; padding: 0;"
                class="nhalf_width col-4"
                [formControl]="productForm.controls['producer']"
                name="rentedFrom"
                [inputName]="'producer'"
                [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                [inputNameFieldName]="'name'"
                [inputNameTFieldName]="'nameT'"
                [inputValueFieldName]="'id'"
                [inputSelectValues]="parties"
                [inputIsRequired]="true"
                (outputOnScroll)="scrollParties()"
                (outputOnCreate)="selectedParty=null; addEditPartyModal.showModal()"
                (outputOnEdit)="selectedParty=$event; addEditPartyModal.showModal()"
                (outputOnDelete)="onDeleteParty($event)">
                </fap-add-edit-delete-select> -->

            <fap-add-edit-delete-select 
                [inputName]="'producer'"
                style="max-width: 100%; padding: 0;"
                class="nhalf_width col-4"
                [formControl]="productForm.controls['producer']"
                name="producer"
                [inputIsRequired]="true"
                [apiSearchEnabled]="true"
                [inputNameFieldName]="'name'"
                [inputNameTFieldName]="'nameT'"
                [inputValueFieldName]="'id'"
                [inputSelectValues]="parties"
                (click)="getParties()"
                [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                (outputOnDelete)="onDeleteParty($event)"
                (outputOnCreate)="selectedParty=null; addEditPartyModal.showModal()"
                (outputOnEdit)="selectedParty=$event; addEditPartyModal.showModal()"
                (outputOnSearch)="filterParties($event)"
                (outputOnScroll)="scrollParties()">
            </fap-add-edit-delete-select>
            </div>
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'stock.type' | translate }}</mat-label>
                        <mat-select #rsp class="select" required formControlName="type" (selectionChange)="getResourceType($event.value)">
                            <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #resourceFilter placeholder="Search">
                                </mat-form-field>
                                <em class="fa fa-plus center f-20 ml-2" (click)="selectResourceType()"></em>
                            </div>
                            <div class="scroll_wrap">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-optgroup class="noteIcons" *ngFor="let parent of globalRegistry?.systemData?.resourceTypes | filter : resourceFilter.value">
                                <span class="mat-option-text">
                                    <div class="type-name">
                                        <span *ngIf="parent?.name_t">
                                            <span *ngIf="getTranslation(parent?.name_t) === '' || getTranslation(parent?.name_t) == null || getTranslation(parent?.name_t) == undefined ">{{parent?.name}}</span>
                                            <span *ngIf="getTranslation(parent?.name_t) !== ''">{{getTranslation(parent?.name_t)}}</span>
                                        </span>
                                        <span *ngIf="!parent?.name_t">{{parent.name}}</span>
                                    </div>
                                    <div class="actions d-flex text-center">
                                        <i class="fa fa-edit align-self-center f-20"  (click)="selectResourceType(parent);$event.stopPropagation()"></i>
                                        <i class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteResourceType(parent?.id) ;$event.stopPropagation()"></i>
                                    </div>
                                </span>
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                    <div class="type-name">
                                        <span *ngIf="child?.name_t">
                                            <span *ngIf="getTranslation(child?.name_t) === '' || getTranslation(child?.name_t) == null || getTranslation(child?.name_t) == undefined ">{{child?.name}}</span>
                                            <span *ngIf="getTranslation(child?.name_t) !== ''">{{getTranslation(child?.name_t)}}</span>
                                        </span>
                                        <span *ngIf="!child?.name_t">{{child.name}}</span>
                                    </div>
                                    <div class="actions d-flex text-center">
                                        <i class="fa fa-edit align-self-center f-20"  (click)="selectResourceType(child, parent);$event.stopPropagation()"></i>
                                        <i class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteResourceType(child?.id) ;$event.stopPropagation()"></i>
                                    </div>
                                </mat-option>
                            </mat-optgroup>
                        </div>
                        </mat-select>
                        <mat-error *ngIf="ifControlHasError('type','required')">
                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                            </mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <!-- <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">
                            Prospect</mat-label>
                        <mat-select formControlName="prospect">
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput #personFilter placeholder="Search">
                            </mat-form-field>
                            <div class="scroll_wrap">
                            <mat-option *ngFor="let person of persons | filter : personFilter.value" [value]="person.id">
                                {{person.firstName}} {{person.lastName}} {{person?.first_name}} {{person?.last_name}}
                            </mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="ifControlHasError('prospect','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div> -->
            <div class="col-6">
                <fap-unit-value class="half_width"
                    [fieldName]="'Packaging'"
                    [(ngModel)]="packUnit"
                    [ngModelOptions]="{standalone: true}">
                </fap-unit-value>
            </div>
            <div class="col-6">
                <fap-unit-value class="half_width"
                    [fieldName]="'Product quantity'"
                    [(ngModel)]="quantityPack"
                    [ngModelOptions]="{standalone: true}">
                </fap-unit-value>
            </div>
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>Unique ID</mat-label>
                        <input matInput placeholder="Unique ID" formControlName="unique_id">
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                        <input matInput placeholder="quantity" formControlName="quantity" type="number">
                    </mat-form-field>
                </div>
            </div> -->
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>{{ 'product.cost' | translate }}</mat-label>
                        <input matInput placeholder="Cost" type="number" formControlName="cost">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>{{ 'product.alertQty' | translate }}</mat-label>
                        <input matInput type="number" placeholder="Alert quantity" required (change)="verifyAlertQuantity($event)" formControlName="alert_quantity">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div
                    class="input-group align-items-baseline w-100"
                >
                <mat-form-field class="w-100" (click)="getPeopleGroups()" [ngClass]="{'dbld': productForm.controls['alert_quantity'].value == 0}">
                    <mat-label class="col-form-label">{{ 'product.alertGroup' | translate }}</mat-label>
                    <mat-select formControlName="alert_group">
                        <div class="d-flex" style="align-items: center; padding: 0 10px;">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #userGroupsFilter placeholder="Search">
                        </mat-form-field>
                        <i class="fa fa-plus center f-20 ml-2" (click)="addGroupModal()"></i>
                    </div>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let group of peopleGroup | filter : userGroupsFilter.value" [value]="group.id">
                            <div class="party-name">
                                {{ group?.name }}
                            </div>
                            <div class="party-actions">
                                <i class="fa fa-edit center f-20"
                                    (click)="editGroup(group);$event.stopPropagation()"></i>
                                <i class="fa fa-trash center ml-2 f-20"
                                    (click)="selectedGroup=group;deleteGroupModal.showModal();$event.stopPropagation()">
                                </i>
                            </div>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field (click)="getIngredients()">
                        <mat-label>Ingredients</mat-label>
                        
                        <mat-select formControlName="ingredients" [(ngModel)]="localIngredientsId"  multiple (selectionChange)="selectIngredient($event.value)">
                            <div class="d-flex" style="padding: 0 10px; align-items: center;">
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput #ingFilter placeholder="Search">
                            </mat-form-field>
                            <div class="input-group-append pl-1" (click)="showIngredientPopup()">
                                <span class="input-group-text"><i
                                        class="icofont icofont icofont-plus"></i></span>
                            </div>
                            </div>
                            <div class="scroll_wrap">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let ingredient of globalRegistry?.systemData?.ingredients | filter : ingFilter.value" [value]="ingredient.id">
                                    <div class="opflex">
                                    <ng-container *ngFor="let type of globalRegistry?.systemData?.ingredientTypes">
                                        <span class="name" *ngIf="type?.id === ingredient?.type">{{ingredient?.sub_type}} ({{type?.name}})</span>
                                    </ng-container>
                                    <div class="actions">
                                        <i class="fa fa-edit align-self-center f-20"  (click)="editIngredient(ingredient);$event.stopPropagation()"></i>
                                        <i class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteIngredient(ingredient?.id);$event.stopPropagation()"></i>
                                    </div>
                                </div>
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group w-100">
                    <div class="checkds"  [ngClass]="from ? 'from' : ''">
                        <input type="checkbox" [(ngModel)]="produced" [ngModelOptions]="{standalone: true}" name="produced" (change)="toggleProduced()" />
                        <span class="checkbx"></span>
                        <label for="produced">My Product</label>
                    </div>
                </div>
            </div>
            <div class="form-group w-100" style="max-width: calc(100% - 30px); margin: auto;" *ngIf="localIngredients">
                <fap-ingredient-table
                    [ingredients]="localIngredients"
                    [ingredientTypes]="globalRegistry.systemData.ingredientTypes"
                    (createdIngredient)="createdIngredient($event); addEditIngredientModal.hideModal()"
                    (updatedIngredient)="updatedIngredient($event); addEditIngredientModal.hideModal()"
                    (deleteLocalIngredient)="deleteLocalIngredient($event)"
                    (deleteLocalIngredient)="deleteLocalIngredient($event)"
                    (deleteIngredient)="deleteIngredient($event)">
                </fap-ingredient-table>
            </div>
            <div class="form-group w-100 mt-4" style="max-width: calc(100% - 30px); margin: auto;" *ngIf="resourceType?.form_type">
                <fap-attributes
                    [entityRelation]="'resource_type'"
                    [entityId]="entityInfo?.id"
                    [objectId]="product?.id"
                    [entityInfo]="entityInfo"
                    (formTypeEmit)="formType = $event"
                    (formFieldsEmit)="formFields = $event"
                    >
                </fap-attributes>
            </div>
            <!-- <div style="height: 30px; width: 100%;"></div> -->
            
        </div>
    </div>
    <div class=" mt-3 text-center">
        <button class="btn btn-lg" type="submit" (click)="submitProduct()">{{ 'save' | translate }}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>

<fap-modal #addEditPartyModal
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
    (addedParty)="addParty($event); addEditPartyModal.hideModal();"
    (updatedParty)="addedOrUpdatedParty($event); addEditPartyModal.hideModal();">
    </fap-add-edit-party>
</fap-modal>

<fap-modal #addResourceTypeModal inputTitle="Add/Edit Resource Type">
    <fap-add-edit-resource-type
        [resourceTypes]="globalRegistry?.systemData?.resourceTypes"
        [selectedResource]="selectedResource"
        [parentResource]="parentResource"
        (resourceTypeActionCompleted)="onResourceTypeAction($event)">
    </fap-add-edit-resource-type>
</fap-modal>

<fap-modal
    #addEditIngredientModal
    inputTitle="Add/Edit Ingredient">
    <fap-add-edit-ingredient
        #addEditIngredient
        [ingredient]="selectedIngredient"
        [context]="'cropType'"
        (createdIngredient)="addIngredient($event); addEditIngredientModal.hideModal()"
        (updatedIngredient)="updateIngredient($event); addEditIngredientModal.hideModal()"
        (deleteLocalIngredient)="deleteLocalIngredient($event)"
        >
    </fap-add-edit-ingredient>
</fap-modal>

<fap-modal #addImageModal
[inputPrimaryButtonInterface]="addImageModalButtonPrimaryInterface"
[inputSecondaryButtonInterface]="addImageModalButtonSecondaryInterface"
inputTitle="Add Image">
<form [formGroup]="imageForm" class="col-12 p-0">
    <div class="form-group" 
    style="display: none">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Group' }}</mat-label>
            <input matInput formControlName="group" readonly value="notes">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Tags' }}</mat-label>
            <input matInput formControlName="tags" placeholder="Tags">
        </mat-form-field>
    </div>
    <div class="form-group">
        <label style="display: block;" class="upload-img-label">{{ 'Image' | translate }}</label>
        <input #fileUploader type="file" (change)="onFileInput($event)">
        <img *ngIf="imageSrc" class="preview-img d-block" [src]="imageSrc" alt="image">
    </div>
</form>
</fap-modal>

<fap-modal #addEditGroupModal [inputTitle]="'people.groups.addEditGroup' | translate">
    <fap-add-edit-group [group]="selectedGroup" (createdGroup)="createdGroup($event)"></fap-add-edit-group>
</fap-modal>

<fap-modal #deleteGroupModal [inputPrimaryButtonInterface]="deleteGroupModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="deleteGroupModalButtonSecondaryInterface"
    inputTitle="{{ 'people.groups.deleteGroup' | translate }}">
    {{ 'people.groups.deleteGroupMessage' | translate}}
</fap-modal>