import {
    Component,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    Output,
    Input,
    ViewChild,
    OnInit,
    OnDestroy
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormControl
} from '@angular/forms';
import { FarmModel } from '../../../../core/models/farm/farm.model';
import { PartyModel } from '../../../../core/models/party/party.model';
import { DatePipe } from '@angular/common';
import { StockOutcomeInterface } from '../../../../core/interfaces/stock/stock-outcome.interface';
import { ResourceTypeModel } from '../../../../core/models/type/resource-type.model';
import { FapModalComponent } from '../../../../shared/partials';
import { StockOutcomeTypesEnum } from '../../../../core/enums/stock-outcome-types.enum';
import { UnitValueModel } from '../../../../core/models/units/unit-value.model';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { TranslationModel } from '../../../../core/models/type/translation-type.model';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';
@Component({
    selector: 'fap-stock-outcome',
    templateUrl: './fap_stock-outcome.component.html',
    styleUrls: ['./fap_stock-outcome.component.scss'],
    providers: [DatePipe]
})
export class FapStockOutcomeComponent implements OnChanges, OnInit, OnDestroy{

    @Input()
    public farms: Array<FarmModel>;

    @Input()
    public hasOutcomeType: boolean;

    @Input()
    public parties: Array<PartyModel>;

    @Input()
    public resourceTypes: Array<ResourceTypeModel>;

    @Output()
    public addStockOutcome: EventEmitter<StockOutcomeInterface> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    @ViewChild('addResourceTypeModal')
    public addResourceTypeModal: FapModalComponent;

    @ViewChild('addEditPartyModal')
    public addEditPartyModal: FapModalComponent;

    public stockOutcomeForm: UntypedFormGroup;

    public selectedResource: ResourceTypeModel;

    public selectedParty: PartyModel;

    public partyFormFieldName: string;

    public parentResource: ResourceTypeModel;

    public quantity: UnitValueModel;

    public farmArray: Array<FarmModel>;
    public translatedNames: Array<TranslationModel>;
    public langString: string;
    public subscription: Subscription = new Subscription();

    constructor(private formBuilder: UntypedFormBuilder,
                private datePipe: DatePipe, private globalRegistryService: GlobalRegistryService, public stockService:StockService, private navService:NavService) {
                    this.farmArray = this.globalRegistryService.systemData.farms;
        this.initStockOutcomeForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'farms') || Object.prototype.hasOwnProperty.call(changes, 'hasOutcomeType')) {
            this.initStockOutcomeForm();
        }
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
        if(lang) {
          this.langString = lang;
        }
    }));
    this.translatedNames = this.globalRegistryService.systemData.translations;
    }

    public submit(): void {
        this.stockOutcomeForm.markAllAsTouched();
        if(this.stockOutcomeForm.invalid) {
            Object.keys(this.stockOutcomeForm.controls).forEach((controlName: string): void =>
                this.stockOutcomeForm.controls[controlName].markAsTouched()
            );
            return;
        }

        if(this.stockOutcomeForm.dirty) {
            const stockOutcome: StockOutcomeInterface = {
                quantity: this.quantity.value,
                type: this.stockOutcomeForm.controls['type'].value,
                farm: this.stockOutcomeForm.controls['farm'].value,
                cost: this.stockOutcomeForm.controls['cost'].value,
                partyFrom: this.stockOutcomeForm.controls['partyFrom'].value,
                partyTo: this.stockOutcomeForm.controls['partyTo'].value,
                receiver: this.stockOutcomeForm.controls['receiver'].value,
                receiveDate: this.datePipe.transform(this.stockOutcomeForm.controls['receiveDate'].value, 'yyyy-MM-dd'),
                outcomeType: this.hasOutcomeType ? this.stockOutcomeForm.controls['outcomeType'].value : 'consumed_internal',
                unit: this.quantity.unit
            };
            console.log(stockOutcome);
            this.stockService.setOutcomeObj(stockOutcome);
            this.addStockOutcome.emit(stockOutcome);
        }
    }

    public selectResource(resource?: ResourceTypeModel, parent?:  ResourceTypeModel): void {
        this.selectedResource = resource;

        this.parentResource = parent ? parent : null;
        this.addResourceTypeModal.showModal();
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.stockOutcomeForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public onResourceTypeAction(resourceTypeId: number): void {
        this.stockOutcomeForm.controls['type'].setValue(resourceTypeId);
        this.addResourceTypeModal.hideModal();
    }

    public get StockOutcomeTypesEnum(): typeof StockOutcomeTypesEnum {
        return StockOutcomeTypesEnum;
    }

    public onDeleteParty(party: PartyModel): void {
        this.selectedParty = party;
        this.deleteParty.emit(this.selectedParty.id);
        if (this.stockOutcomeForm.controls['partyFrom'].value === this.selectedParty.id) {
            this.stockOutcomeForm.controls['partyFrom'].setValue(null);
            this.stockOutcomeForm.controls['partyFrom'].markAsTouched();
        }
        if (this.stockOutcomeForm.controls['partyTo'].value === this.selectedParty.id) {
            this.stockOutcomeForm.controls['partyTo'].setValue(null);
            this.stockOutcomeForm.controls['partyTo'].markAsTouched();
        }
    }

    public addPartyModal(): void {
        this.selectedParty = null;
        this.addEditPartyModal.showModal();
    }

    public editParty(party: PartyModel): void {
        this.addEditPartyModal.showModal();
        this.selectedParty = party;
    }

    public addedOrUpdatedParty(party: PartyModel): void {
        this.stockOutcomeForm.controls[this.partyFormFieldName].setValue(party.id);
        this.addEditPartyModal.hideModal();
    }

    private initStockOutcomeForm(): void {
        this.stockOutcomeForm = this.formBuilder.group({
            type: [null, Validators.compose([
                    Validators.required,
                ])
            ],
            cost: [null, Validators.compose([
                Validators.required,
            ])],
            farm: [null, Validators.compose([
                Validators.required,
            ])],
            partyFrom: [null, Validators.compose([
                    Validators.required,
                ])
            ],
            partyTo: [null, Validators.compose([
                    Validators.required,
                ])
            ],
            receiver: [''],
            receiveDate: [null]
        });

        if(this.hasOutcomeType) {
            this.stockOutcomeForm.addControl('outcomeType', new UntypedFormControl(null, Validators.required));
        }

        // if(this.farms) {
        //     this.stockOutcomeForm.addControl('farm', new FormControl(null, Validators.required));
        // }
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
          return t[0][this.langString];
        } else {
            return translation
        }
      }

      
      public ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
