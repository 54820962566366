<div class="row pr" [ngClass]="{'preview': previewMode}">
    <div class="col-md-12 col-lg-12">
        <mat-form-field class="w-100">
            <mat-label>{{ fieldName }}</mat-label>
            <div class="subflex col-md-12 d-flex">
            <input class="si" matInput type="number" [(ngModel)]="unitValue.value" (ngModelChange)="valueChanged()">
            <div *ngIf="units" class="unit_value">
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>{{ 'units.unit' | translate }}</mat-label>
            <mat-select required [(ngModel)]="unitValue.unit" (ngModelChange)="valueChanged()">
                <div class="d-flex" style="align-items: center; padding: 0 10px;">
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <em class="fa fa-search f-20"></em>
                    </mat-label> -->
                    <input matInput #resourceFilter placeholder="Search">
                </mat-form-field>
                <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                <mat-optgroup *ngFor="let parent of units | filter : resourceFilter.value">
                        <div class="type-name">
                            {{ parent.nameT ? getTranslation(parent.nameT) : parent.name }}
                        </div>
                        <div class="d-flex text-center">
                            <em [ngClass]="{ 'disabled': parent.locked }" class="fa fa-edit align-self-center f-20" (click)="selectUnit(parent);$event.stopPropagation()"></em>
                            <em [ngClass]="{ 'disabled': parent.locked }" class="fa fa-trash align-self-center ml-2 f-20" (click)="onDeleteUnit(parent);$event.stopPropagation()"></em>
                        </div>
                    <mat-option *ngFor="let child of parent.children" [value]="child.id">
                        <div class="type-name">
                            {{ child.shortName }}
                        </div>
                        <div class="d-flex text-center">
                            <em [ngClass]="{ 'disabled': child.locked }" class="fa fa-edit align-self-center f-20" (click)="selectUnit(child, parent);$event.stopPropagation()"></em>
                            <em [ngClass]="{ 'disabled': child.locked }" class="fa fa-trash align-self-center ml-2 f-20" (click)="onDeleteUnit(child);$event.stopPropagation()"></em>
                        </div>
                    </mat-option>
                </mat-optgroup>
            </div>
            </mat-select>
        </mat-form-field>
    </div>
    </div>
        </mat-form-field>
    </div>
    
    
</div>

<fap-modal #addEditUnitModal
    inputTitle="{{ 'units.addEditUnit' | translate }}">
    <fap-add-edit-unit
        [selectedUnit]="selectedUnit"
        [units]="units"
        [parentUnit]="parentUnit"
        (unitActionCompleted)="onUnitAction($event)">
    </fap-add-edit-unit>
</fap-modal>
