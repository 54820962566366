import { NgModule } from '@angular/core';
import {
    CommonModule,
    NgSwitch
} from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';

import { MapHelper } from '../core/heplers/map.helper';
import { NavService } from './services/nav.service';
import { ConfirmModalService } from './services/confirm-modal.service.ts/confirm-modal.service';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';


// import {
//     ToggleFullscreenDirective
// } from './directives';

import {
    FooterComponent,
    FapHeaderComponent,
    LoaderComponent,
    FapSidebarComponent,
    FapMainMapComponent,
    MapMenuComponent
} from './layout';

import {
    FeatherIconsComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FapModalComponent,
    FapUploadImageComponent,
    FapWeatherIconSvgComponent
} from './partials';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FapUploadAvatarComponent } from './partials/components/upload/avatar/fap-upload-avatar.component';
import { FapGeneralWeatherWidgetComponent } from './widgets/fap_general-weather-widged/fap_general-weather-widged.component';
import { FapWeatherWidgetComponent } from './widgets/fap_weather-widget/fap_weather-widget.component';
import { FapSoilWidgetComponent } from './widgets/fap_soil-widget/fap_soil-widget.component';
import { FapSingleChartsBarVerticalComponent } from './widgets/fap_single-charts-bar-vertical/fap_single-charts-bar-vertical.component';
import { FapHorizontalLineChartComponent } from './widgets/fap_line-chart/fap_line-chart.component';
import { FapPieChartGridComponent } from './widgets/fap_pie-chart-grid/fap_pie-chart-grid.component';
import { FapSingelPieChartComponent } from './widgets/fap_single-pie-chart/fap_single-pie-chart.component';
import { FapAdvancedPieChartComponent } from './widgets/fap_advanced-pie-chart/fap_advanced-pie-chart.component';
import { FapHorizontalBarLineChartComponent } from './widgets/fap_horizontal-bar-chart/fap_horizontal-bar-chart.component';
import { FapVerticalBarChartComponent } from './widgets/fap_vertical-bar-chart/fap_vertical-bar-chart.component';
import { FapListWidgetComponent } from './widgets/fap_list-widget/fap_list-widget.component';
import { FapAlertListComponent } from './widgets/fap_alert-list/fap_alert-list.component';
import { FapAlertListRowComponent } from './widgets/fap_alert-list/fap_alert-list-row/fap_alert-list-row.component';
import { FapActivityListComponent } from './widgets/fap_activity-list/fap_activity-list.component';
import { FapActivityListRowComponent } from './widgets/fap_activity-list/fap_activity-list-row/fap_activity-list-row.component';
import { FapActivityListGroupRowComponent } from './widgets/fap_activity-list/fap_activity-list-group-row/fap_activity-list-group-row.component';
import { FapResourcesTableComponent } from './partials/components/fap_resources-table/fap_resources-table.component';
import { FapWizardReviewDirective } from './layout/fap_wizard/fap_base/fap-wizard-review.directive';
import { FapWizardContentDirective } from './layout/fap_wizard/fap_base/fap-wizard-content.directive';
import { FapWizard1Component } from './layout/fap_wizard/fap_base/fap_wizard1/fap-wizard1.component';
import { FapAddWidgetWizardComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_add-widget-wizard.component';
import { FapSelectWidgetInfoTypeComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_select-widget-info-type/fap_select-widget-info-type.component';
import { FapSelectWidgetComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_select-widget/fap_select-widget.component';
import { FapWidgetWrapperComponent } from './widgets/fap_widget-wrapper/fap_widget-wrapper.component';
import { FapConfigureWidgetComponent } from './layout/fap_wizard/fap_add-widget-wizard/fap_configure-widget/fap_configure-widget.component';
import { FapWizardBaseDirective } from './layout/fap_wizard/fap_base/fap-wizard-base.component';
import { FapAddEditActivityComponent } from './partials/components/fap_add-edit-activity/fap_add-edit-activity.component';
import { FapConfirmModalComponent } from './partials/components/fap-confirm-modal/fap-confirm-modal.component';
import { FapStockFarmWidgetComponent } from './widgets/fap_stock-farm-widget/fap_stock-farm-widget.component';
import { FapAddEditDeleteComponent } from './partials/components/fap_add-edit-delete-select/fap_add-edit-delete-select.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterPipe } from '../core/pipes/filter.pipe';
import { AddButtonComponent } from './partials/components/add-button/add-button.component';
import { ProgressBarComponent } from './partials/components/progress-bar/progress-bar.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FabMulitImgUploadComponent } from './partials/components/upload/fab-mulit-img-upload/fab-mulit-img-upload.component';
import { FapTableWidgetComponent } from './widgets/fap-table-widget/fap-table-widget.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { FapChartWidgetComponent } from './widgets/fap-chart-widget/fap-chart-widget.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UnitPipe } from '../core/pipes/uint.pipe';
import { FapCropEyeWidgetComponent } from './widgets/fap-crop-eye-widget/fap-crop-eye-widget.component';
import { FapImageSliderWidgetComponent } from './widgets/fap-image-slider-widget/fap-image-slider-widget.component';
import { FapImageWidgetComponent } from './widgets/fap-image-widget/fap-image-widget.component';
import { FapOverviewWidgetComponent } from './widgets/fap-overview-widget/fap-overview-widget.component';
import { MinMaxPipe } from '../core/pipes/minMax.pipe';
import { FapWaterContentWidgetComponent } from './widgets/fap-water-content-widget/fap-water-content-widget.component';
import { FapCropEyeSimpleWidgetComponent } from './widgets/fap-crop-eye-simple-widget/fap-crop-eye-simple-widget.component';
import { FapSatelliteWidgetComponent } from './widgets/fap-satellite-widget/fap-satellite-widget.component';
import { TimeZonePipe } from '../core/pipes/timeZone.pipe';
import { FapAlertWidgetComponent } from './widgets/fap-alert-widget/fap-alert-widget.component';
import { FapWeedControlWidgetComponent } from './widgets/fap-weed-control-widget/fap-weed-control-widget.component';
import { TimerPipe } from '../core/pipes/timer.pipe';
import { getLastTransmissionPipe } from '../core/pipes/deviceTransmission.pipe';
import { getLotNamesPipe } from '../core/pipes/getLotNames.pipe';
import { FapCarouselWidgetComponent } from './widgets/fap-carousel-widget/fap-carousel-widget.component';
import { FapBinWidgetComponent } from './widgets/fap-bin-widget/fap-bin-widget.component';
import { FapDeviceInfoWidgetComponent } from './widgets/fap-device-info-widget/fap-device-info-widget.component';
import { AddEditTranslationsComponent } from '../views/views-partials/fap_view-components/add-edit-translations/add-edit-translations.component';
import { ChipsComponent } from './partials/components/chips/chips.component';
import { FapWeatherForecastWidgetComponent } from './widgets/fap-weather-forecast-widget/fap-weather-forecast-widget.component';
import { getUnitPipe } from '../core/pipes/unit.pipe';
import { StepperComponent } from '../views/views-partials/fap_view-components/stepper/stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FapSensorValueWidgetComponent } from './widgets/fap-sensor-value-widget/fap-sensor-value-widget.component';
import { FapActivitiesListComponent } from './widgets/activities-list-widget/activities-list-widget.component';
import { TimeAgoPipe } from '../core/pipes/timeAgo.pipe';
import { RandomPipe } from '../core/pipes/randomNumber.pipe';
import { PoolWidgetComponent } from './widgets/pool-widget/pool-widget.component';
import { CircleProgressBarComponent } from './partials/components/circle-progress-bar/circle-progress-bar.component';
import { FapAttributesComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-attributes.component';
import { FapAttributeFormsComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-attribute-forms/fap-attribute-forms.component';
import { FapClassicalFormComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-classical-form/fap-classical-form.component';
import { ClassicalFormFieldComponent } from '../views/views-partials/fap_view-components/fap-attributes/fap-classical-form/classical-form-field/classical-form-field.component';
import { DragCarouselComponent } from './partials/components/drag-carousel/drag-carousel.component';
import { CameraComponent } from '../views/views-partials/fap_view-components/camera/camera.component';
import { MapWidgetComponent } from './widgets/map-widget/map-widget.component';
import { FapListAttributeRelationsComponent } from '../views/views-partials/fap_view-components/fap-list-attribute-relations/fap-list-attribute-relations.component';
import { FapAttributesMapComponent } from '../views/views-partials/fap_view-components/fap-attributes-map/fap-attributes-map.component';
import { ScrollService } from './services/scroll.service';
import { getProductUnitPipe } from '../core/pipes/productUnit.pipe';
import { UtcToLocalPipe } from '../core/pipes/utcToLocal.pipe';
import { GraphRangeWidgetComponent } from './widgets/graph-range-widget/graph-range-widget.component';
import { GraphPercentageWidgetComponent } from './widgets/graph-percentage-widget/graph-percentage-widget.component';

const FAP_PARTIALS: any[] = [
    FeatherIconsComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FapUploadImageComponent,
    FapModalComponent,
    FapResourcesTableComponent,
    FapUploadAvatarComponent,
    FapWeatherIconSvgComponent,
    FapWizard1Component,
    FapAddWidgetWizardComponent,
    FapSelectWidgetInfoTypeComponent,
    FapSelectWidgetComponent,
    FapConfigureWidgetComponent,
    FapWidgetWrapperComponent,
    FapWizardBaseDirective,
    FapActivityListRowComponent,
    FapActivityListGroupRowComponent,
    FapAddEditActivityComponent,
    FapAlertListRowComponent,
    FapConfirmModalComponent,
    FapStockFarmWidgetComponent,
    FapAddEditDeleteComponent,
    FabMulitImgUploadComponent,
    AddEditTranslationsComponent,
    ChipsComponent,
    StepperComponent,
    CircleProgressBarComponent,
    FapAttributesComponent,
    FapAttributeFormsComponent,
    FapClassicalFormComponent,
    ClassicalFormFieldComponent,
    FapListAttributeRelationsComponent,
    FapAttributesMapComponent,
    DragCarouselComponent,
    CameraComponent
];

const FAP_LAYOUT: any[] = [
    FooterComponent,
    FapHeaderComponent,
    LoaderComponent,
    FapSidebarComponent,
    FapMainMapComponent,
    MapMenuComponent,
];

const FAP_DIRECTIVES: any[] = [
    // ToggleFullscreenDirective,
    FapWizardReviewDirective,
    FapWizardContentDirective
];

const FAP_WIDGETS: any[] = [
    FapGeneralWeatherWidgetComponent,
    FapWeatherWidgetComponent,
    FapSoilWidgetComponent,
    FapSingleChartsBarVerticalComponent,
    FapHorizontalLineChartComponent,
    FapPieChartGridComponent,
    FapSingelPieChartComponent,
    FapAdvancedPieChartComponent,
    FapHorizontalBarLineChartComponent,
    FapVerticalBarChartComponent,
    FapListWidgetComponent,
    FapActivityListComponent,
    FapAlertListComponent,
    FapTableWidgetComponent,
    FapChartWidgetComponent,
    FapCropEyeWidgetComponent,
    FapImageSliderWidgetComponent,
    FapImageWidgetComponent,
    FapOverviewWidgetComponent,
    FapWaterContentWidgetComponent,
    FapSatelliteWidgetComponent,
    FapCropEyeSimpleWidgetComponent,
    FapAlertWidgetComponent,
    FapWeedControlWidgetComponent,
    FapCarouselWidgetComponent,
    FapBinWidgetComponent,
    FapDeviceInfoWidgetComponent,
    FapWeatherForecastWidgetComponent,
    FapSensorValueWidgetComponent,
    FapActivitiesListComponent,
    PoolWidgetComponent,
    MapWidgetComponent,
    GraphRangeWidgetComponent,
    GraphPercentageWidgetComponent
];

const FAP_PIPES: any[] = [
    FilterPipe,
    UnitPipe,
    MinMaxPipe,
    TimeZonePipe,
    TimerPipe,
    getLastTransmissionPipe,
    getLotNamesPipe,
    getUnitPipe,
    TimeAgoPipe,
    RandomPipe,
    getProductUnitPipe,
    UtcToLocalPipe
];
@NgModule({
    declarations: [
        ...FAP_LAYOUT,
        ...FAP_PARTIALS,
        ...FAP_DIRECTIVES,
        ...FAP_WIDGETS,
        ...FAP_PIPES,
        AddButtonComponent,
        ProgressBarComponent,
        GraphRangeWidgetComponent,
        GraphPercentageWidgetComponent,
    ],
    exports: [
        ...FAP_LAYOUT,
        ...FAP_PARTIALS,
        ...FAP_DIRECTIVES,
        ...FAP_WIDGETS,
        ...FAP_PIPES,
        TranslateModule,
        AddButtonComponent,
        ProgressBarComponent,
    ],
    imports: [
        CommonModule,
        NgApexchartsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatTableModule,
        MatAutocompleteModule,
        NgxCsvParserModule,
        AgmCoreModule.forRoot({
            apiKey: MapHelper.GOOGLE_API_KEY,
            libraries: ['drawing', 'places']
        }),
        NgxSpinnerModule,
        MatCheckboxModule,
        MatStepperModule
    ],
    providers: [
        NavService,
        TranslateService,
        NgSwitch,
        ConfirmModalService,
        ScrollService
    ]
})
export class SharedModule {}
