<div
    *ngIf="widget"
    class="dashboard-card dash_cont"
    style="position: relative"
    id="parent"
>
    <!-- {{ disease?.name_t ? getTranslation(disease?.name_t) : disease?.name }} -->
    <div class="row">
        <div class="col-md-4">
            <!-- <h1>{{ widget.config["name_t"] ? getTranslation(widget.config["name_t"]) : widget.config["name"] }}</h1> -->
            <h1>
                {{ "widget." + widget?.widgetType | translate }}
                
                <span *ngIf="disease?.name_t">
                    <span *ngIf="getTranslation(disease?.name_t) === '' || getTranslation(disease?.name_t) == null || getTranslation(disease?.name_t) == undefined ">{{disease?.name}}</span>
                    <span *ngIf="getTranslation(disease?.name_t) !== ''">{{getTranslation(disease?.name_t)}}</span>
                </span>
                <span *ngIf="!disease?.name_t">{{disease?.name}}</span>
            </h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row" style="height: calc(100% - 30px)">
        <div
            *ngIf="!disease"
            style="
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 70%;
                width: 100%;
            "
        >
            <h3 style="text-align: center">
                <span class="loader">
                    <i></i>
                </span>
            </h3>
        </div>
        <button
            *ngIf="alertData?.billing && alertData?.billing?.transaction == null"
            class="pay_button"
            (click)="verifyPayment()"
        >
            {{ 'widget.paymentRequired' | translate }}
        </button>
        <div class="err_msg" *ngIf="!alertData?.billing">
            {{ errorMsg }}
        </div>
        <div
            class="alert_widget w-100"
            [ngClass]="alertData?.billing?.transaction ? 'ebld' : 'dbld'"
        >
            <div class="top_row">
                <div class="box">
                    <div class="img img_place_bg">
                        <span *ngIf="disease?.images.length > 0">
                            <img [src]="mediaUrl + disease.images[disease.images.length - 1].file" alt="">
                        </span>
                        <span *ngIf="disease?.images.length == 0"><img class="portrait" src="../../../../assets/images/image_placeholder.png" alt="" /></span>
                    </div>
                    <div class="text" *ngIf="crop">
                        <div class="txt">
                            <h4>
                                {{ crop?.cropType?.name_t ? getTranslation(crop?.cropType?.name_t) : crop?.cropType?.name }}
                            </h4>
                            <p>
                                <i>{{ getLotName() }}</i>
                            </p>
                            <button
                                class="info_btn"
                                (click)="showModal(widget)"
                            >
                                {{
                                    disease?.latin
                                        ? disease?.latin
                                        : disease?.name
                                }}
                            </button>
                        </div>
                    </div>
                    <div class="graph">
                        <p
                            class="alertpercent"
                            *ngIf="alertData?.value?.value_float != '0.0'"
                        >
                            {{ alertData?.value?.value_float }} %
                        </p>
                        <div
                            class="gp"
                            *ngIf="crop?.phenology && crop?.phenology?.phases"
                        >
                            <div class="progress-bar-container">
                                <div
                                    class="progress-bar"
                                    [ngStyle]="{
                                        width: crop?.progress + '%',
                                        'background-color': red
                                    }"
                                ></div>
                            </div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td
                                            *ngFor="
                                                let i of crop?.phenology?.phases
                                            "
                                        >
                                            <img
                                                [src]="apiUrl.slice(0, -1) + i?.img"
                                                alt=""
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td
                                            *ngFor="
                                                let i of crop?.phenology?.phases
                                            "
                                        >
                                            {{ i?.bbch[0] }}-{{ i?.bbch[1] }}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foot_row" *ngIf="messages.length > 0">
                <marquee>
                    <p *ngFor="let p of messages">
                        <span [ngStyle]="{ background: p?.color }"></span>
                        <a *ngIf="p.url !== ''" [href]="p?.url" target="_blank">{{ getTranslation(p?.info_t) }}</a>
                        <i style="font-style: normal;" *ngIf="p.url === ''">{{ getTranslation(p?.info_t) }}</i>
                    </p>
                </marquee>
            </div>
        </div>
    </div>
</div>

<fap-modal
    [inputIsLarge]="true"
    #confirmPopup
    [titleColor]="'#ffffff'"
    inputTitle="Disease info"
>
    <div class="mod_body">
        <p>{{ getTranslation(disease?.info) }}</p>
    </div>
</fap-modal>
