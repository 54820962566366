import { LotModel } from '../../../../core/models/lot/lot.model';
import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { FapWizardStepInterface } from '../fap_base/data/fap-wizard-step.interface';
import { FapWizardReviewTabInterface } from '../fap_base/data/fap-wizard-review-tab.interface';
import { WidgetHelper } from '../../../../core/heplers/widget.helper';
import { FapSelectWidgetComponent } from './fap_select-widget/fap_select-widget.component';
import { FapWizard1Component } from '../fap_base/fap_wizard1/fap-wizard1.component';
import { WidgetInterface } from '../../../../core/interfaces/widget/widget.interface';
import { SensorModel } from '../../../../core/models/sensor/sensor.model';
import { FarmModel } from '../../../../core/models/farm/farm.model';

@Component({
    selector: 'fap-add-widget-wizard',
    templateUrl: './fap_add-widget-wizard.component.html',
    styleUrls: ['./fap_add-widget-wizard.component.scss']
})
export class FapAddWidgetWizardComponent {

    @Input()
    public sensors: Array<SensorModel> = [];

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public lots: Array<LotModel> = [];

    @Output()
    public submitEvent: EventEmitter<WidgetInterface> = new EventEmitter();

    @ViewChild('selectWidgetStep')
    public selectWidgetStep: FapSelectWidgetComponent;

    @ViewChild('wizard')
    public wizard: FapWizard1Component;

    public wizardSteps: Array<FapWizardStepInterface> = [];
    public wizardReviewTab: FapWizardReviewTabInterface = {
        tabTitle: 'Configure Widget',
        title: 'widget.configureWidget',
        icon: 'settings'
    };

    public selectedWidgetInfoType: string = null;
    public selectedWidget: string = null;
    public selectedContentType = 'user';
    public selectedObjectId = 0;

    public widgetConfig: {} = {};

    constructor() {
        this.wizardSteps = [
            {
                id: 'widget-step-1',
                title: 'widget.widgetType',
                icon: 'addchart'
            },
            {
                id: 'widget-step-2',
                title: 'widget.selectWidget',
                icon: 'assignment_turned_in'
            }
        ];
    }

    public onSubmit(): void {
        const widget: WidgetInterface = {
            config: WidgetHelper.getDefaultConfig(this.selectedWidget),
            contentType: this.selectedContentType,
            objectId: this.selectedObjectId,
            widgetType: this.selectedWidget
        };
        Object.assign(widget.config, this.widgetConfig);
        this.submitEvent.emit(widget);
        this.reset();
    }

    public selectWidget(widget: string): void {
        this.selectedWidget = widget;
    }

    public selectWidgetInfoType(widgetInfoType: string): void {
        this.selectedWidgetInfoType = widgetInfoType;
        this.selectedWidget = null;
    }

    public selectWidgetType(widgetType: {}): void {
        this.selectedContentType = widgetType['type'];
        this.selectedObjectId = widgetType['id'];
    }

    public reset(): void {
        this.selectWidgetStep.reset();
        // this.wizard.resetStep();
    }

}
