import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { ForecastService } from '../../../core/services/api/forecast/forecast.service';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { DataService } from '../../../core/services/websocket/data.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'fap-weather-forecast-widget',
  templateUrl: './fap-weather-forecast-widget.component.html',
  styleUrls: ['./fap-weather-forecast-widget.component.scss']
})
export class FapWeatherForecastWidgetComponent implements OnInit, OnDestroy, OnChanges {

  public selectedWidget;
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  public langString: string;
  public subscription: Subscription = new Subscription();
  public toggleMain = false;
  public toggleSub = false;
  public apiUrl = environment.baseBackendUrl;
  public mediaUrl = environment.mediaUrl;
  public sensorIds = [];
  public subscriptions: Array<Subscription> = []
  public dates =[];
  public forecasts = [];
  public mainDays = [];
  @ViewChild('container') containerRef!: ElementRef;

  private isDragging = false;
  private startX = 0;
  private scrollLeft = 0;

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService, public sensorService: SensorService, public dataService: DataService, public companyService: CompanyService, public forecastService: ForecastService, public cacheService: CacheResolverService) { }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
          if(lang) {
            this.langString = lang;
        }
    }));
  }

  hideMain() {
    this.toggleMain = false
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp() {
    this.isDragging = false;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    const container = this.containerRef.nativeElement;
    const containerRect = container.getBoundingClientRect();
    const containerLeft = containerRect.left;
    const containerRight = containerRect.right;

    if (event.clientX < containerLeft || event.clientX > containerRight) {
      // Cursor is outside the container, destroy events
      this.isDragging = false;
    } else {
      // Adjust scroll position
      const dx = event.clientX - this.startX;
      container.scrollLeft = this.scrollLeft - dx;
    }
  }

  onMouseDown(event: MouseEvent) {
    const container = this.containerRef.nativeElement;
    this.isDragging = true;
    this.startX = event.clientX;
    this.scrollLeft = container.scrollLeft;
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
        return translation
      }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
      console.log(this.widget);
      if(this.widget.settings['lots']) {
        this.getForecasts(this.widget.settings['lots'])
      }
    }
  }

  public getForecasts(lots: any[]) {
    lots.forEach(lot => {
      const param = {type: 'lot', object: lot}
      const url = this.forecastService.getUrl('');
      this.cacheService.delete(url + 'forecast/type=lot&object='+lot)
      this.forecastService.getForecast(param).subscribe(data => {
        this.forecasts.push(data.body.results[0]);
      })
    });
    console.log(this.forecasts);
}

public getLotName(lotId: number = this.widget.objectId): string {
  const foundLot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);

  return foundLot ? foundLot.name : ''; // Provide a default value or handle accordingly
}


getObjectValues(obj: any): any[] {
  return Object.values(obj);
}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
  }

}
