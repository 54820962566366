<ng-template #template>
    <div class="modal-content overflow-hidden margin-modal">
        <div class="modal-header" *ngIf="inputIsCloseable || inputTitle">
            <h2 [ngStyle]="{'color': '#ffffff'}" mat-dialog-title>{{ inputTitle }}</h2>
            <button *ngIf="inputIsCloseable"
                    type="button"
                    class="btn close-modal"
                    (click)="hideModal()">X
            </button>
        </div>
        <div class="modal-body" [ngClass]="!showFooter ? 'pb-0 pt-0' : ''">
            <ng-content></ng-content>
        </div>
        <div class="modal-footer" *ngIf="showFooter">
            <button *ngIf="inputPrimaryButtonInterface"
                    type="button"
                    class="btn btn-success mr-2"
                    (click)="inputPrimaryButtonInterface.clickFunction()">
                    {{ inputPrimaryButtonInterface.text }}
            </button>

            <button *ngIf="inputSecondaryButtonInterface"
                    type="button"
                    class="btn btn-danger"
                    (click)="inputSecondaryButtonInterface.clickFunction()">
                    {{ inputSecondaryButtonInterface.text }}
            </button>
        </div>
    </div>
</ng-template>
