import { NameValueInterface } from '../../../../core/interfaces/general/name-value.interface';
import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { FapModalComponent } from '../../../../shared/partials';
import { FapSelectDiseaseComponent } from '../fap_select-disease/fap_select-disease.component';
import { DiseasesEnum } from '../../../../core/enums/diseases.enum';

@Component({
    selector: 'fap-disease-table',
    templateUrl: './fap_disease-table.component.html',
    styleUrls: ['./fap_disease-table.component.scss']
})
export class FapDiseaseTableComponent {

    @Input()
    public headerTitle: string;

    @Input()
    public context: DiseasesEnum = DiseasesEnum.pathogen;

    @Input()
    public diseases: NameValueInterface[] = [
        {
            name: 'Pathogen1',
            value: 1
        },
        {
            name: 'Pathogen2',
            value: 2
        }
    ];

    @Output()
    public tableChanged: EventEmitter<number[]> = new EventEmitter();

    @ViewChild('addDiseaseModal')
    public addDiseaseModal: FapModalComponent;

    @ViewChild('selectDisease')
    public selectDisease: FapSelectDiseaseComponent;

    public addDisease(): void {
        this.selectDisease.reset();
        this.addDiseaseModal.showModal();
    }

    public removeDisease(diseaseId: number): void {
        this.diseases = this.diseases.filter((disease: NameValueInterface): boolean => disease.value !== diseaseId);
        this.tableChanged.emit(this.diseases.map((disease: NameValueInterface): number => Number(disease.value)));
    }

    public addedDisease(newDisease: NameValueInterface): void {
        this.diseases.push(newDisease);
        this.tableChanged.emit(this.diseases.map((disease: NameValueInterface): number => Number(disease.value)));
        this.addDiseaseModal.hideModal();
    }
}
