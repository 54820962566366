import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import * as Marzipano from "marzipano";
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { NavService } from '../../services/nav.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fap-crop-eye-widget',
  templateUrl: './fap-crop-eye-widget.component.html',
  styleUrls: ['./fap-crop-eye-widget.component.scss']
})
export class FapCropEyeWidgetComponent implements OnInit, AfterViewInit {

  @ViewChild("pano") pano:ElementRef;

  @ViewChild("right") right:ElementRef;
  @ViewChild("left") left:ElementRef;
  @ViewChild("zoomIn") zoomIn:ElementRef;
  @ViewChild("zoomOut") zoomOut:ElementRef;

  @Input()
  public widget: WidgetModel;

  public autoRotate:any;
  public toggleRotate = false;
  public viewer:any;
  public translatedNames: any = [];
  public langString: string;
  public subscriptions: Array<Subscription> = []

  constructor(private globalRegistry:GlobalRegistryService, public navService: NavService){}

  ngOnInit(){
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
        if(t[0][this.langString] != '') {
            return t[0][this.langString];
        } else {
            return
        }
    } else {
        return translation
      }
  }

  toggleAutoRotate(){
    if(!this.toggleRotate){
      this.viewer.startMovement(this.autoRotate);
      this.toggleRotate = !this.toggleRotate;
    }else{
      this.toggleRotate = !this.toggleRotate;
      this.viewer.stopMovement();
      this.viewer.setIdleMovement(Infinity);
    }
  }

  public getLotName(lotId:number=this.widget.objectId):string{
    return this.globalRegistry.systemData.lots.find(lot=>lot.id===lotId).name;
  }

  ngAfterViewInit(): void {

    const panoElement = this.pano.nativeElement;


    const viewerOpts = {
      controls: {
        mouseViewMode: 'drag',
      }
    }

    this.autoRotate = Marzipano.autorotate({
      yawSpeed: 0.1,         // Yaw rotation speed
      targetPitch: 0,        // Pitch value to converge to
      targetFov: Math.PI/2   // Fov value to converge to
    });

    
    
    this.viewer = new Marzipano.Viewer(panoElement, viewerOpts);
    
    const controls = this.viewer.controls();

    const velocity = 0.7;
    const friction = 3;

    controls.registerMethod("rightElement", new Marzipano.ElementPressControlMethod(this.right.nativeElement,  'x',  velocity, friction),true);
    controls.registerMethod("leftElement", new Marzipano.ElementPressControlMethod(this.left.nativeElement,  'x',  -velocity, friction),true);
    controls.registerMethod('inElement',    new Marzipano.ElementPressControlMethod(this.zoomIn.nativeElement,  'zoom', -velocity, friction), true);
    controls.registerMethod('outElement',   new Marzipano.ElementPressControlMethod(this.zoomOut.nativeElement, 'zoom',  velocity, friction), true);

    const limiter = Marzipano.RectilinearView.limit.traditional(1024, 100 * Math.PI / 180)
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    const geometry = new Marzipano.EquirectGeometry([{ width: 4000 }])

    const source = Marzipano.ImageUrlSource.fromString("");

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true
    })

    scene.switchTo()

  }
}
