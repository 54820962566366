import { Routes } from '@angular/router';
import { FarmEditContainerComponent } from './containers/farm-container/farm-edit-container.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { CropsContainerComponent } from '../crops/containers/crops-container/crops-container.component';

export const farmRoutes: Routes = [
    {
        path: 'edit/:farmId',
        component: FarmEditContainerComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: 'COMPANY_ADMIN',
                redirectTo: '/'
            }
        }
    },
    {
        path: 'create',
        component: FarmEditContainerComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: 'COMPANY_ADMIN',
                redirectTo: '/'
            }
        }
    },
    {
        path: 'management',
        component: CropsContainerComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: 'COMPANY_ADMIN',
                redirectTo: '/'
            }
        }
    },
    {
        path: '',
        redirectTo: 'management',
        pathMatch: 'full'
    }
];
