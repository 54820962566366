<div class="overview_page">
    <!-- {{lang}} -->
    <div
        class="overview_container"
        [ngClass]="{ 'desktop-view': width >= 768, 'mobile-view': width < 768 }"
    >
        <ul>
            <li
                *ngFor="let item of imageObjects; let i = index"
                
                class="tmb"
                [ngClass]="i === counter ? 'active' : 'inactive'"
            >
                <h2
                    *ngIf="i == counter && item.title[lang]"
                    [ngStyle]="{
                        'background-color': overviewData?.css?.background_color,
                        color: overviewData?.css?.bg_text_color
                    }"
                >
                    {{ item?.title[lang] }}
                </h2>
                <h2
                    *ngIf="i == counter && !item?.title[lang]"
                    [ngStyle]="{
                        'background-color': overviewData?.css?.background_color,
                        color: overviewData?.css?.bg_text_color
                    }"
                >
                    {{ item.title.en }}
                </h2>
                <div class="media" *ngIf="item.type === 'image'">
                    <img
                        *ngIf="i == counter && item.media.substr(-4) === '.jpg'"
                        style="width: 100%"
                        [src]="apiUrl + item.media"
                    />
                    <video autoplay [muted]="'muted'" oncanplay="this.muted=true"
                        *ngIf="i == counter && item.media.substr(-4) === '.mp4'"
                        style="width: 100%"
                    >
                        <source [src]="apiUrl + item.media" type="video/mp4" />
                    </video>
                    <div class="infos" *ngIf="i == counter">
                        <ng-container
                            *ngFor="let info of item.info"
                        >
                        <div class="info pc"
                        [ngStyle]="{
                            padding: '5px',
                            position: 'absolute',
                            left: info.position.pc[0],
                            top: info.position.pc[1],
                            'min-width': overviewData?.css?.label_width,
                            'font-size': overviewData?.css?.text_size,
                            color: overviewData?.css?.text_color,
                            transform:
                                'translate(' +
                                info.position.pc[0] +
                                info.position.pc[1] +
                                ')'
                        }">

                            <ng-container>
                                <div class="act_bg" *ngIf="info?.css?.background_color" [ngStyle]="{
                                    'background-color': info?.css?.background_color}"></div>
                                <div class="act_bg" *ngIf="!info?.css?.background_color" [ngStyle]="{
                                    'background-color': overviewData?.css?.background_color}"></div>
                            </ng-container>

                            <ng-container>
                                <!--Text-->
                                <ng-container *ngIf="info?.css">
                                    <span *ngIf="info?.field[lang]?.text" [ngStyle]="{ color: info?.css?.text_color }">{{ info?.field[lang]?.text }}</span>
                                    <span *ngIf="!info?.field[lang]?.text" [ngStyle]="{ color: info?.css?.text_color }">{{ info?.field.en.text }}</span>
                                </ng-container>
                                <ng-container *ngIf="!info?.css">
                                    <span *ngIf="info?.field[lang]?.text" [ngStyle]="{ color: overviewData?.css?.text_color }">{{ info?.field[lang]?.text }}</span>
                                    <span *ngIf="!info?.field[lang]?.text" [ngStyle]="{ color: overviewData?.css?.text_color }">{{ info?.field.en.text }}</span>
                                </ng-container>
                                <!--Text-->

                                <!--Value-->
                                <ng-container *ngIf="info?.css">
                                    <span *ngIf="info?.field[lang]?.value" [ngStyle]="{ color: info?.css?.value_color }">
                                        {{ info?.field[lang]?.value }}</span>
                                    <span *ngIf="!info?.field[lang]?.value" [ngStyle]="{ color: info?.css?.value_color }">
                                        {{ info?.field?.en?.value }}</span>
                                </ng-container>
                                <ng-container *ngIf="!info?.css">
                                    <span *ngIf="info?.field[lang]?.value" [ngStyle]="{ color: overviewData?.css?.value_color }">
                                        {{ info?.field[lang]?.value }}</span>
                                    <span *ngIf="!info?.field[lang]?.value" [ngStyle]="{ color: overviewData?.css?.value_color }">
                                        {{ info?.field?.en?.value }}</span>
                                </ng-container>
                                <!--Value-->

                                <!--Unit-->
                                <ng-container *ngIf="info?.css">
                                    <span *ngIf="info?.field[lang]?.unit" [ngStyle]="{ color: info.css?.unit_color }">
                                        {{ info?.field[lang]?.unit }}</span>
                                    <span *ngIf="!info?.field[lang]?.unit" [ngStyle]="{ color: info?.css?.unit_color }">
                                        {{ info?.field?.en?.unit }}</span>
                                </ng-container>
                                <ng-container *ngIf="!info?.css">
                                    <span *ngIf="info?.field[lang]?.unit" [ngStyle]="{ color: overviewData?.css?.unit_color }">
                                        {{ info?.field[lang]?.unit }}</span>
                                    <span *ngIf="!info?.field[lang]?.unit" [ngStyle]="{ color: overviewData?.css?.unit_color }">
                                        {{ info?.field?.en?.unit }}</span>
                                </ng-container>
                                <!--Unit-->
                            </ng-container>
                            
                        </div>

                        <div class="info mobile"
                        [ngStyle]="{
                            padding: '5px',
                            position: 'absolute',
                            left: info.position.pc[0],
                            top: info.position.pc[1],
                            'min-width': overviewData?.css?.label_width,
                            'font-size': overviewData?.css?.text_size,
                            color: overviewData?.css?.text_color,
                            transform:
                                'translate(' +
                                info.position.pc[0] +
                                info.position.pc[1] +
                                ')'
                        }">

                            <ng-container>
                                <div class="act_bg" *ngIf="info?.css?.background_color" [ngStyle]="{
                                    'background-color': info?.css?.background_color}"></div>
                                <div class="act_bg" *ngIf="!info?.css?.background_color" [ngStyle]="{
                                    'background-color': overviewData?.css?.background_color}"></div>
                            </ng-container>

                            <ng-container>
                                <!--Text-->
                                <ng-container *ngIf="info?.css">
                                    <span *ngIf="info?.field[lang]?.text" [ngStyle]="{ color: info?.css?.text_color }">{{ info?.field[lang]?.text }}</span>
                                    <span *ngIf="!info?.field[lang]?.text" [ngStyle]="{ color: info?.css?.text_color }">{{ info?.field.en.text }}</span>
                                </ng-container>
                                <ng-container *ngIf="!info?.css">
                                    <span *ngIf="info?.field[lang]?.text" [ngStyle]="{ color: overviewData?.css?.text_color }">{{ info?.field[lang]?.text }}</span>
                                    <span *ngIf="!info?.field[lang]?.text" [ngStyle]="{ color: overviewData?.css?.text_color }">{{ info?.field.en.text }}</span>
                                </ng-container>
                                <!--Text-->

                                <!--Value-->
                                <ng-container *ngIf="info?.css">
                                    <span *ngIf="info?.field[lang]?.value" [ngStyle]="{ color: info?.css?.value_color }">
                                        {{ info?.field[lang]?.value }}</span>
                                    <span *ngIf="!info?.field[lang]?.value" [ngStyle]="{ color: info?.css?.value_color }">
                                        {{ info?.field?.en?.value }}</span>
                                </ng-container>
                                <ng-container *ngIf="!info?.css">
                                    <span *ngIf="info?.field[lang]?.value" [ngStyle]="{ color: overviewData?.css?.value_color }">
                                        {{ info?.field[lang]?.value }}</span>
                                    <span *ngIf="!info?.field[lang]?.value" [ngStyle]="{ color: overviewData?.css?.value_color }">
                                        {{ info?.field?.en?.value }}</span>
                                </ng-container>
                                <!--Value-->

                                <!--Unit-->
                                <ng-container *ngIf="info?.css">
                                    <span *ngIf="info?.field[lang]?.unit" [ngStyle]="{ color: info.css?.unit_color }">
                                        {{ info?.field[lang]?.unit }}</span>
                                    <span *ngIf="!info?.field[lang]?.unit" [ngStyle]="{ color: info?.css?.unit_color }">
                                        {{ info?.field?.en?.unit }}</span>
                                </ng-container>
                                <ng-container *ngIf="!info?.css">
                                    <span *ngIf="info?.field[lang]?.unit" [ngStyle]="{ color: overviewData?.css?.unit_color }">
                                        {{ info?.field[lang]?.unit }}</span>
                                    <span *ngIf="!info?.field[lang]?.unit" [ngStyle]="{ color: overviewData?.css?.unit_color }">
                                        {{ info?.field?.en?.unit }}</span>
                                </ng-container>
                                <!--Unit-->
                            </ng-container>
                            
                        </div>
                        </ng-container>
                    </div>
                    
                </div>

                <div class="map map-container" *ngIf="item.type === 'map'">
                    
                    <agm-map
                    [latitude]="calculateCenter(item?.info).lat"
                    [longitude]="calculateCenter(item?.info).lng"
                    [zoom]="zoom"
                    [disableDefaultUI]="false"
                    [zoomControl]="true"
                    [scaleControl]="true"
                    (mapReady)="mapReady($event)"
                    #agmMap
                >
                <agm-marker
                    *ngFor="let m of item.info"
                    [latitude]="m.coords[0]"
                    [longitude]="m.coords[1]"
                    [markerDraggable]="false"
                    [iconUrl]="m.icon? mediaUrl + m.icon : ''"
                    [animation]="'DROP'"
                    (dragEnd)="markerDragEnd(m, $event)"
                    [ngClass]="'custom-marker-label'"
                >  
                <!-- [label]="{text: m?.field && m?.field[lang]?.text ? m?.field[lang]?.text : m?.field?.en?.text}"  -->
                <agm-info-window [disableAutoPan]="false" [isOpen]="true" *ngIf="m.field">
                  <strong *ngIf="m?.field[lang]?.text">{{ m?.field[lang]?.text }} {{ m?.field[lang]?.value }} {{ m?.field[lang]?.unit }}</strong>
                  <strong *ngIf="!m?.field[lang]?.text">{{ m?.field?.en?.text }} {{ m?.field?.en?.value }} {{ m?.field?.en?.unit }}</strong>
                </agm-info-window>
              </agm-marker>
            </agm-map>
                </div>
            </li>
        </ul>

        <div class="nav_btns">
            <button (click)="showPreviousImage()" class="cbtn prev"><</button>
            <button (click)="showNextImage()" class="cbtn next">></button>
        </div>
    </div>
</div>
