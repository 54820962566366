import {
    Component,
    OnDestroy,
    ViewChild,
    OnInit
} from '@angular/core';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { ActivityInterface } from '../../../../../core/interfaces/activity/activity.interface';
import {
    Subscription,
    combineLatest,
    Observable
} from 'rxjs';
import {
    ActivatedRoute,
    Router,
    Params
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ActivityGroupModel } from '../../../../../core/models/activity/activity-group.model';
import { ActivityHelper } from '../../../../../core/heplers/activity.helper';
import { FapModalComponent } from '../../../../../shared/partials';
import { ActivityGroupInterface } from '../../../../../core/interfaces/activity/activity-group.interface';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './activity-group-add-edit-container.component.html',
    styleUrls: ['./activity-group-add-edit-container.component.scss']
})
export class ActivityGroupAddEditContainerComponent implements OnDestroy, OnInit {

    @ViewChild('addEditActivityModal')
    public addEditActivityModal: FapModalComponent;

    public activityGroupId: number;

    public editActivityGroupMode = false;

    public activityGroupName: string;

    public existingActivities: Array<ActivityModel> = [];
    public newActivities: Array<ActivityInterface> = [];
    public toBeDeletedActivityIds: Array<number> = [];

    public selectedActivity: ActivityInterface = null;

    private newActivityIDCounter = -1; // we decrement the counter for new activities

    private subscriptions: Array<Subscription> = [];

    constructor(public activatedRoute: ActivatedRoute,
                public router: Router,
                public toastr: ToastrService,
                public translate: TranslateService,
                public activityService: ActivityService,
                public translateService: TranslateService,
                public globalRegistry: GlobalRegistryService,
                public mapService: MapService) {
        this.activatedRoute.params.subscribe((params: Params) => {
            if (params['activityGroupId'] !== undefined) {
                // edit activity group
                this.editActivityGroupMode = true;
                this.activityGroupId = Number(params['activityGroupId']);
                this.subscriptions.push(this.activityService.getActivityGroup(this.activityGroupId)
                .subscribe((activityGroup: ResponseModel<ActivityGroupModel>) => {
                    this.activityGroupName = activityGroup.model.name;
                }));
                this.subscriptions.push(this.activityService.getActivities({
                    group: this.activityGroupId,
                    limit: 1000,
                    offset: 0
                }).subscribe((activities: ResponseModel<ActivityModel[]>) => {
                    this.existingActivities = ActivityHelper.getGroupedActivities(activities.body.results, this.activityGroupId);
                }));
            }
            else {
                // new activityGroup
                this.editActivityGroupMode = false;
            }
        });
    }

    public ngOnInit(): void {
        this.mapService.hideMap();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public deleteActivity(activityId: number): void {
        if (activityId < 0) {
            this.newActivities = this.newActivities.filter((activity: ActivityInterface) => activity.id !== activityId);
        } else {
            this.existingActivities = this.existingActivities.filter((activity: ActivityModel) => activity.id !== activityId);
            this.toBeDeletedActivityIds.push(activityId);
        }
    }

    public createActivity(): void {
        this.selectedActivity = null;
        this.addEditActivityModal.showModal();
    }

    public editActivity(activity: ActivityInterface): void {
        this.selectedActivity = activity;
        this.addEditActivityModal.showModal();
    }

    public createdOrEditedActivity(activityData: ActivityInterface): void {
        this.addEditActivityModal.hideModal();
        if (activityData.id === null || activityData.id === undefined) {
            // created a new Activity
            const newActivity: ActivityInterface = {
                name: activityData.name,
                info: activityData.info,
                started: activityData.started,
                ended: activityData.ended,
                activityType: activityData.activityType,
                id: this.newActivityIDCounter--
            };
            this.newActivities.push(newActivity);
        } else {
            let editedActivity: ActivityInterface = null;
            if (this.selectedActivity.id < 0) {
                // edited a freshly created activity (negative ids)
                editedActivity = this.newActivities.find((activity: ActivityInterface) => activity.id === this.selectedActivity.id);
            }
            else {
                // edited an existing activity from DB
                editedActivity = this.existingActivities.find((activity: ActivityModel) => activity.id === this.selectedActivity.id);
            }
            editedActivity.name = activityData.name;
            editedActivity.started = activityData.started;
            editedActivity.ended = activityData.ended;
            editedActivity.info = activityData.info;
            editedActivity.activityType = activityData.activityType;
        }
    }

    public submitActivityGroup(): void {
        const activityObservables: Array<Observable<{}>> = [];
        this.existingActivities.forEach((activity: ActivityModel) => {
            const activityBody: ActivityModel = {
                name: activity.name,
                info: activity.info,
                started: activity.started,
                ended: activity.ended,
                activityType: activity.activityType,
                lots: activity.lots,
                assignedTo: activity.assignedTo,
                createdBy: activity.createdBy,
                workingTime: activity.workingTime,
                attachedEquipments: activity.attachedEquipments,
                group: activity.group,
                phaseId: activity.phaseId,
                distance: activity.distance,
                data: activity.data,
                cost: activity.cost,
                area: activity.area,
                resourcesDoc: activity.resourcesDoc,
                nameT: null,
                trackId: activity.trackId
            };
            activityObservables.push(this.activityService.updateActivity(activity.id, activityBody));
        });
        this.newActivities.forEach((activity: ActivityModel) => {
            delete activity.id;
            activityObservables.push(this.activityService.createActivity(activity));
        });
        this.toBeDeletedActivityIds.forEach((activityId: number) => {
            activityObservables.push(this.activityService.deleteActivity(activityId));
        });
        this.subscriptions.push(combineLatest(activityObservables).subscribe((responses: Array<ResponseModel<{}>>) => {
            const activityIds: Array<number> = [];
            responses.forEach((response: ResponseModel<{}>) => {
                if (response && response.model && response.model['id'] !== null && response.model['id'] !== undefined) {
                    activityIds.push(response.model['id']); // we have an id returned (not a delete operation)
                }
            });
            const activityGroup: ActivityGroupInterface = {
                name: this.activityGroupName,
                activities: activityIds
            };
            const activityGroupObservable: Observable<ResponseModel<ActivityGroupModel>> = this.editActivityGroupMode
                ? this.activityService.updateActivityGroup(this.activityGroupId, activityGroup)
                : this.activityService.createActivityGroup(activityGroup);
            this.subscriptions.push(activityGroupObservable.subscribe(() => {
                this.toastr.success(this.translateService.instant('activity.activityGroupCreatedUpdatedSuccessfully'));
                this.router.navigate(['/pages/activity/groups/']);
            }, () => {
                this.toastr.error(this.translateService.instant('activity.errorUpdatingCreatingActivityGroup'));
                this.router.navigate(['/pages/activity/groups/']);
            }));
        }, () => {
            this.toastr.error(this.translateService.instant('activity.errorUpdatingCreatingActivityGroup'));
            this.router.navigate(['/pages/activity/groups/']);
        }));
    }

}
