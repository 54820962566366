import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { map, tap, takeUntil, catchError, finalize } from 'rxjs/operators';
import { Router, ActivatedRoute, Data, Params } from '@angular/router';
import { FapConfirmPasswordValidator } from './fap_confirm-password.validator';
import { PasswordPatternValidator } from './fap_password-pattern.validator';
import { ConfirmAccountInterface } from '../../../../../core/services/api/auth/data/confirm-account.interface';
import { AuthService } from '../../../../../core/services/api/auth/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from '../../../../../core/models/response.model';
import { TokenModel } from '../../../../../core/models/auth/token.model';
import data from "../../../../../../assets/timezone/timezone.json";
import { ConfirmPhoneInterface } from '../../../../../core/services/api/auth/data/confirm-phone.interface';
import { ConfirmPinInterface } from '../../../../../core/services/api/auth/data/confirm-pin.interface';
import { environment } from '../../../../../../environments/environment';


@Component({
    selector: 'fap-confirm-account',
    templateUrl: './fap_confirm-account.component.html',
    styleUrls: ['./fap_confirm-account.component.scss']
})
export class FapConfirmAccountComponent implements OnInit, OnDestroy {
    public confirmAccountForm: UntypedFormGroup;
    public data$: Observable<Data>;
    private confirmationKey: string;
    private unsubscribe: Subject<any>;
    public changePassword = false;
    public checkPhoneParam = false;
    public phoneForm: UntypedFormGroup;
    public OTPForm: UntypedFormGroup;
    public timezones = [];
    public data = [];
    public isPhoneVerificationEnabled = false;
    public pinVerified = false;
    countDown: Subscription;
    counter=60;
    tick=1000;
    public show = false;
    public show1 = false;
    public phoneNumber = null;
    public subscription = new Subscription();
    public defaultCountryISO = null;
    public defaultCountryCallingCode = null;
    public mediaUrl = environment.mediaUrl+'settings/login_background.jpg';
    public passwordChanged = false;
    public link = '';

    constructor(
        private formBuilder: UntypedFormBuilder,
        public translate: TranslateService,
        public router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private toastyService: ToastrService,
        private http: HttpClient,
        private cdr: ChangeDetectorRef) {
            this.data = data.data;
            console.log(this.data);
            this.initPhoneForm();
            this.initOTPForm();
        translate.setDefaultLang(localStorage.getItem('language'));
        translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        this.unsubscribe = new Subject();
        this.getCurrentCountry().subscribe(country => {
            console.log(country);
            this.defaultCountryISO = country['country_code_iso3'];
            this.defaultCountryCallingCode = country['country_calling_code'].slice(1);
            this.phoneForm.get('countryCode').patchValue(this.defaultCountryCallingCode);
            console.log(this.phoneForm.value)
          });
    }

    checkCode(code) {
        console.log(code);
    }

    public confirmEmailPin(key) {
        const body:ConfirmPinInterface = {confirmationKey: key};
        this.subscription.add(
            this.authService.confirmPin(body).subscribe(data => {
                console.log(data);
            },(err) => {
                console.log(err);
                alert(this.translate.instant('auth.company.checkEmailFailed'));
                this.router.navigate(['login']);
            })
        )
    }

    public getCurrentCountry() {
        return this.http.get('https://ipapi.co/json/');
      }

    public ngOnInit(): void {
        this.initConfirmAccountForm();
        this.data$ = this.route.data.pipe(map((data: Data) => {
            return {
                mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
            };
        }));
        this.route.params.pipe(
            tap((params: Params) => {
                this.confirmationKey = params.confirmationKey;
                this.confirmEmailPin(this.confirmationKey);
            }),
            takeUntil(this.unsubscribe)
        ).subscribe();
        if(this.route.snapshot.queryParams.phone && this.route.snapshot.queryParams.phone == 1) {
            console.log('phone');
            this.checkPhoneParam = true;
            this.isPhoneVerificationEnabled = true;
            this.showPhonePopup();
            return;
        }
        

        if(this.route.snapshot.url[0].path === 'change_password') {
            this.changePassword = true;
        } else {
            this.changePassword = false;
        }

        console.log(this.changePassword);
    }

    public showPhonePopup() {
        this.counter = null;
        const elem = document.getElementById('custom_modal400');
        const parent = document.getElementById('parent');
        elem.classList.add('active');
        parent.classList.add('backdrop');
    }

    public showOTPPopup() {
        this.counter = 60;
        const elem = document.getElementById('custom_modal401');
        const parent = document.getElementById('parent');
        elem.classList.add('active');
        parent.classList.add('backdrop');
        this.countDown = this.authService.getCounter(this.tick).subscribe(() => {
            this.counter--;
            if(this.counter == 0) {
                this.countDown.unsubscribe();
                this.counter = null;
                elem.classList.remove('active');
                parent.classList.remove('backdrop');
                this.showPhonePopup();
                this.phoneNumber = null;
                return;
            }
        });
            
    }

    public getPin() {
        console.log(this.phoneForm.value);
        if(this.phoneForm.invalid) {
            Object.keys(this.phoneForm.controls).forEach((controlName: string) =>
                this.phoneForm.controls[controlName].markAsTouched()
            );
            this.toastyService.error('Please select a valid country')
            return;
        }

        const body: ConfirmPhoneInterface = {
            phone: '+' + this.phoneForm.controls['countryCode'].value + this.phoneForm.controls['phone'].value,
            confirmationKey: this.confirmationKey
        };

        console.log(body);
        this.phoneNumber = body.phone;
        this.authService.confirmPhone(body).subscribe(data => {
            console.log(data);
            if(data) {
                const elem = document.getElementById('custom_modal400');
                const parent = document.getElementById('parent');
                elem.classList.remove('active');
                parent.classList.remove('backdrop');
                this.showOTPPopup();
            }
        }, (err) => {
            console.log(err);
            this.toastyService.error(err.error.results.error);
        })
    }

    public confirmPhonePin() {
        console.log(this.OTPForm.value);
        if(this.OTPForm.invalid) {
            Object.keys(this.OTPForm.controls).forEach((controlName: string) =>
                this.OTPForm.controls[controlName].markAsTouched()
            );
            this.toastyService.error('Please enter the pin sent to your phone')
            return;
        }

        const body: ConfirmPinInterface = {
            pin: this.OTPForm.controls['pin'].value,
            confirmationKey: this.confirmationKey
        };

        if(this.route.snapshot.queryParams.phone && this.route.snapshot.queryParams.phone == 1) {
            Object.assign(body, {phone: this.phoneNumber})
        }

        console.log(body);
        this.authService.confirmPin(body).subscribe(data => {
            console.log(data);
            this.countDown.unsubscribe();
            this.counter = null;
            if(data.body) {
                const elem = document.getElementById('custom_modal401');
                const parent = document.getElementById('parent');
                elem.classList.remove('active');
                parent.classList.remove('backdrop');
                this.isPhoneVerificationEnabled = false;
                this.router.navigate(['confirm_account/'+this.confirmationKey]);
            }
        }, (err) => {
            this.toastyService.error('Incorrect pin');
            console.log(err);
            return;
        })
    }

    togglePassword() {
        this.show = !this.show;
    }

    togglePassword1() {
        this.show1 = !this.show1;
    }

    public ngOnDestroy(): void {
         this.unsubscribe.next(null);
        this.unsubscribe.complete();
        this.countDown=null;
    }


    public submit(): void {
        if(this.confirmAccountForm.invalid) {
            Object.keys(this.confirmAccountForm.controls).forEach((controlName: string) =>
                this.confirmAccountForm.controls[controlName].markAsTouched()
            );
            return;
        }

        const body: ConfirmAccountInterface = {
            password: this.confirmAccountForm.controls['password'].value,
            confirmPassword: this.confirmAccountForm.controls['confirmPassword'].value,
            confirmationKey: this.confirmationKey
        };

        if(!this.changePassword) {
        this.authService.confirmAccount(body).pipe(
            tap((response: ResponseModel<TokenModel>) => {
                this.toastyService.success(this.translate.instant('auth.register.userRegistered'));
                localStorage.setItem('auth_token', response.body.results.token);
                localStorage.setItem('company', JSON.stringify(response.body.results.company));
                this.router.navigateByUrl('/create-company');
            }),
            catchError((error: HttpErrorResponse) => {
                this.toastyService.error('Error');
                return throwError(error);
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cdr.markForCheck();
            })
        ).subscribe();
        } else {
            this.authService.confirmNewPassword(body).pipe(
                tap((response: ResponseModel<TokenModel>) => {
                    this.toastyService.success(this.translate.instant('auth.passwordChanged'));
                    localStorage.setItem('auth_token', response.body.results.token);
                    this.passwordChanged = true;
                    if(response.body.results.redirect) {
                        this.link = response.body.results.redirect
                    }
                    // setTimeout(() => {
                    //     window.close();
                    // }, 1000)
                    // this.router.navigateByUrl('/login');
                }),
                catchError((error: HttpErrorResponse) => {
                    this.toastyService.error('Error');
                    return throwError(error);
                }),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.cdr.markForCheck();
                })
            ).subscribe();
        }
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.confirmAccountForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public initPhoneForm(): void {
        const formBody = {
            countryCode: ['', Validators.required
            ],
            phone: ['', Validators.required]
        }
        this.phoneForm = this.formBuilder.group(formBody);
    }

    public initOTPForm(): void {
        const formBody = {
            pin: ['', Validators.required]
        }
        this.OTPForm = this.formBuilder.group(formBody);
    }

    public closeWindow() {
        console.log('close window');
        setTimeout(function() {
            window.top.close();
          }, 1000);
    }

    private initConfirmAccountForm(): void {
        this.confirmAccountForm = this.formBuilder.group({
            password: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    PasswordPatternValidator.patternValidator(/\d/, { hasNumber: true }),
                    PasswordPatternValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
                    PasswordPatternValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
                    // PasswordPatternValidator.patternValidator(/[ [!@#$%^&*()_+-=[]{};':"|,.<>/?]/](<mailto:!@#$%^&*()_+-=[]{};':"|,.<>/?]/>),
                    //  { hasSpecialCharacters: true })
                ])
            ],
            confirmPassword: ['', Validators.compose([
                    Validators.required,
                ])
            ]
        },
        {
            validator: FapConfirmPasswordValidator.matchPassword
         });
    }

}
