import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { FieldModel } from '../../../../../core/models/field/field.model';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
    selector: 'fap-field-layout',
    templateUrl: './fap_field-layout.component.html',
    styleUrls: ['./fap_field-layout.component.scss']
})
export class FapFieldLayoutComponent implements OnChanges{
    @Input()
    public lots: LotModel[];

    @Input()
    public fields: FieldModel[];

    @Input()
    public parties: PartyModel[];

    @Output()
    public clickedFieldCard: EventEmitter<FieldModel> = new EventEmitter();

    @Output()
    public clickedLotTitle: EventEmitter<LotModel> = new EventEmitter();

    @Output()
    public deleteLot: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteField: EventEmitter<number> = new EventEmitter();

    public lotToFieldsMap: {} = {};

    constructor(public navService: NavService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'lots') || Object.prototype.hasOwnProperty.call(changes, 'fields')){
            if(this.fields && this.lots){
                this.initLotToFieldsMap();
            }
        }
    }

    private initLotToFieldsMap(): void {
        this.lotToFieldsMap = {};
        this.fields.forEach((field: FieldModel): void => {
            if(this.lotToFieldsMap[field.lot] === undefined){
                this.lotToFieldsMap[field.lot] = [];
            }
            this.lotToFieldsMap[field.lot].push(field);
        });
    }
}
