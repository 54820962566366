<div class="dashboard-card dash_cont">
    <div class="row">
        <div class="col-md-4">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-content" *ngIf="activities?.length != 0" (scroll)="onScroll($event)">
        <div class="activity_card d-flex" [ngClass]="{ 'activity-overlay': activity?.completed }" *ngFor="let activity of activities" (click)="getActivityForm(activity?.activityType)">
            <ng-container *ngFor="let type of activityTypes">
                <ng-container *ngIf="activity?.activityType === type.id">
                <div class="icon">
                    <img *ngIf="type?.icon?.file" [src]="mediaUrl + type?.icon?.file" alt="icon">
                    <img *ngIf="!type?.icon || !type?.icon?.file" src="../../../../assets/images/image_placeholder.png" alt="icon">
                </div>
                </ng-container>
            </ng-container>
            <div class="info">
                {{activity?.nameT ? getTranslation(activity?.nameT) : activity.name_t ? activity.name_t : activity.name}}
            </div>
            <div class="start_date">
                {{ activity?.started | timeAgo}}
            </div>
            <div class="status_icon" *ngIf="activity?.completed">
                <img src="../../../../assets/icons/checked.svg" alt="check">
            </div>
        </div>
    </div>
</div>

<fap-modal>
    <fap-attributes *ngIf="entityInfo"
                [entityRelation]="'pool'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="0"
                (closeModal)="hidePoolsModal()"
                (formUpdate)="onUpdateAction()"
                [isDynamic]="true">
            </fap-attributes>
</fap-modal>