<div class="dashboard-card dash_cont" style="position: relative">
    <div class="row">
        <div class="col-md-4">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name" *ngIf="getLotName() !== ''">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em> -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <!-- <li (click)="showMenu()">{{"widget.menu" | translate}}</li> -->
                    <li *ngIf="widget?.settings?.graphType !== 'radialBar'" (click)="toggleLegend()">{{"widget.toggleLegend" | translate}}</li>
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div
        *ngIf="isLoading"
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 70%;
            width: 100%;
        "
    >
        <h3 style="text-align: center">
            <span class="loader">
                <i></i>
            </span>
        </h3>
    </div>
    <div style="height: 100%;" class="cheat_row">
      <div id="parent{{randomId}}" style="height: 100%; position: relative;">
        <div class="no_data" *ngIf="!isLoading && noData">
            {{'widget.noDataAvailable' | translate}}
        </div>

    <apx-chart
    style="height: 100%; display: block;"
    *ngIf="widget?.settings?.graphType === 'radialBar' && !isLoading && !noData"
    [series]="radialChartOptions.series"
    [chart]="radialChartOptions.chart"
    [plotOptions]="radialChartOptions.plotOptions"
    [labels]="radialChartOptions.labels"
  ></apx-chart>

  <apx-chart
  style="height: 100%; display: block;"
  *ngIf="widget?.settings?.graphType === 'donut' && !isLoading && !noData"
  [series]="donutChartOptions.series"
  [chart]="donutChartOptions.chart"
  [labels]="donutChartOptions.labels"
  [fill]="donutChartOptions.fill"
  [dataLabels]="donutChartOptions.dataLabels"
  [responsive]="donutChartOptions.responsive"
></apx-chart>

<apx-chart
style="height: 100%; display: block;"
    *ngIf="widget?.settings?.graphType === 'pie' && !isLoading && !noData"
    [series]="pieChartOptions.series"
    [chart]="pieChartOptions.chart"
    [labels]="pieChartOptions.labels"
    [responsive]="pieChartOptions.responsive"
  ></apx-chart>

  <apx-chart
  style="height: 100%; display: block;"
    *ngIf="widget?.settings?.graphType === 'polarArea' && !isLoading && !noData"
    [series]="polarAreaChartOptions.series"
    [chart]="polarAreaChartOptions.chart"
    [labels]="polarAreaChartOptions.labels"
    [fill]="polarAreaChartOptions.fill"
    [responsive]="polarAreaChartOptions.responsive"
  ></apx-chart>
  </div>
  </div>
</div>
