import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { DataService } from '../../../core/services/websocket/data.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'fap-bin-widget',
  templateUrl: './fap-bin-widget.component.html',
  styleUrls: ['./fap-bin-widget.component.scss']
})
export class FapBinWidgetComponent implements OnInit, OnChanges, OnDestroy {

  public selectedWidget;
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  public langString: string;
  public subscription: Subscription = new Subscription();
  public toggleMain = false;
  public toggleSub = false;
  public apiUrl = environment.baseBackendUrl;
  public mediaUrl = environment.mediaUrl;
  public sensorIds = [];
  public subscriptions: Array<Subscription> = []
  public fromDate;
  public toDate;
  public agg;
  public dates =[];
  public lastValues = [];
  public imageId = null;
  public temperatureData = null;
  public imageData = null;
  public lidData = null;
  public humidityData = null;
  public batteryData = null;
  public progressData = null;
  apiCalled = false;

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService, public sensorService: SensorService, public dataService: DataService, public companyService: CompanyService) { }

  ngOnInit(): void {
    this.getFromDate();
    this.getToDate();
    this.getAgg();
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    this.subscription = this.sensorService.getName.subscribe(res => {
      if(res) {
        this.drillData();
        this.apiCalled = false;
      }
    });
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
        if(t[0][this.langString] != '') {
            return t[0][this.langString];
        } else {
            return
        }
    } else {
        return translation
      }
  }

  getFromDate() {
    this.subscriptions.push(this.sensorService.getFromDate.subscribe(res => {
      this.fromDate = res;
    }));
  }

  getToDate() {
    this.subscriptions.push(this.sensorService.getToDate.subscribe(res => {
      this.toDate = res;
    }));
  }

  getAgg() {
    this.subscriptions.push(this.sensorService.getAgg.subscribe(res => {
      this.agg = res;
    }));
  }

  public getLotName(lotId: number = this.widget.objectId): string {
    return this.globalRegistry.systemData.lots.find(
        (lot) => lot.id === lotId
    ).name;
  }

  hideMain() {
      this.toggleMain = false;
  }

  toggleMenu() {
      this.toggleMain = !this.toggleMain;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
      console.log(this.widget);
      this.drillData();
    }
  }

  public drillData() {
    if(this.widget.settings && !this.apiCalled) {
    setTimeout(()=> {
      const sensorIds = this.widget["settings"]["options"].map((sensor) => sensor.id);
      this.subscriptions.push(this.dataService.getLastData(sensorIds.toString()).subscribe((data) => {
        this.lastValues = data
        const localDict = [...data];
        localDict.forEach(element => {
          this.sensors.forEach(sensor => {
            if (element.sensor === sensor.id) {
              element.sensor_info = sensor;

              const matchingUnit = this.unitTypes.find(unit => unit.id === element.sensor_info.unitType);
              if (matchingUnit) {
                element.unit = matchingUnit;
              }
            }
          });
          
          this.widget["settings"]["options"].forEach(s => {
            if(element.sensor === s.id) {
              Object.assign(element, {ref: s.ref})
            }
          });
          if(element.ref === '171_1000') {
            this.lidData = {element}
          } else if(element.ref === '63_10') {
            this.temperatureData = {element}
          } else if(element.ref === '63_20') {
            this.humidityData = {element}
          } else if (element.ref === '40_62') {
            this.batteryData = {element}
          } else if(element.ref === '171_62') {
            this.progressData = element.value_float+'%'
          } else {
            this.getImage(element.value_string)
          }
        });
        console.log(localDict);
      }))
    }, 1000)
  }
  this.apiCalled = true;
  }

  public getMoment(dateString) {
    if(dateString!=null) {
      moment.locale('en', {
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s:  '3s',
          ss: '%ss',
          m:  '1m',
          mm: '%dm',
          h:  '1h',
          hh: '%dh',
          d:  'a day',
          dd: '%dd',
          M:  '1M',
          MM: '%dM',
          y:  '1y',
          yy: '%dy'
        }
      });
      const dstring = moment.utc(dateString).local();
      return moment(dstring).fromNow();
    }
  }

  public getImage(string) {
    if(string) {
      const arr = string.split('=')
      this.imageId = arr[1];
      this.subscriptions.push(
        this.companyService.getCompanyImage(this.imageId).subscribe(data => {
          this.imageData = data.model;
          console.log(this.imageData)
        })
      ) 
    } else {
      return
    }
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

}
