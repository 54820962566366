<div class="container-fluid ter-container nc"
infiniteScroll
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="500"
  (scrolled)="scrolledDown()"
  [scrollWindow]="false"
>
    <fap-activities-layout
         [farms]="filteredFarms"
         [activities]="filteredActivities"
         (deleteActivity)="deleteActivity($event)"
         [activityTypes]="activityTypes"
         [persons]="globalRegistry?.systemData?.persons"
         >
    </fap-activities-layout>
</div>
