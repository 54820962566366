<div class="row rsp" id="parent">
    <div class="col-12 rspc">
        <div class="card">
            <div class="card-header px-3 rounded-0">
                {{ "Activity Data" | translate }}
            </div>

            <form [formGroup]="activityForm">
                <div class="card-body ace">
                    <div class="form-group">
                        <div class="row align-items-baseline">
                            <div class="col-6 tbg">
                                <div
                                    class="input-group align-items-baseline w-100"
                                >
                                    <mat-form-field>
                                        <mat-label>{{
                                            "activities.activity_type" | translate
                                        }}</mat-label>
                                        <mat-select
                                            formControlName="activityType"
                                            name="type"
                                            required
                                            [value]="activity?.activityType"
                                            (selectionChange)="setLocalVal('activityType', $event)"
                                        >
                                            <div
                                                class="d-flex"
                                                style="
                                                    padding: 0 10px;
                                                    align-items: center;
                                                "
                                            >
                                                <mat-form-field
                                                    appearance="fill"
                                                    class="w-100"
                                                >
                                                    <!-- <mat-label>
                                                    <i class="fa fa-search f-20"></i>
                                                </mat-label> -->
                                                    <input
                                                        matInput
                                                        #typeFilter
                                                        placeholder="Search"
                                                    />
                                                </mat-form-field>
                                                <div
                                                    class="input-group-append pl-1"
                                                    (click)="
                                                        showActivityTypeModal(true)
                                                    "
                                                >
                                                    <span
                                                        class="input-group-text"
                                                        ><i
                                                            class="icofont icofont icofont-plus"
                                                        ></i
                                                    ></span>
                                                </div>
                                            </div>
                                            <div class="scroll_wrap">
                                                <mat-option style="height: 0!important;"></mat-option>
                                                <mat-option
                                                    class="noteIcons"
                                                    *ngFor="
                                                        let type of activityTypes
                                                            | filter
                                                                : typeFilter.value
                                                    "
                                                    [value]="type?.id"
                                                >
                                                    <div class="name">
                                                        {{ getTranslation(type?.nameT) ? getTranslation(type?.nameT) : type?.name }}
                                                    </div>
                                                    <div class="actions">
                                                        <i
                                                            class="fa fa-edit"
                                                            (click)="
                                                                activityTypeEdit(
                                                                    $event,
                                                                    type
                                                                )
                                                            "
                                                        ></i>
                                                        <i
                                                            class="fa fa-trash"
                                                            (click)="
                                                                activityTypeDelete(
                                                                    $event,
                                                                    type
                                                                )
                                                            "
                                                        ></i>
                                                    </div>
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="
                                                activityForm.controls[
                                                    'activityType'
                                                ].errors?.required
                                            "
                                        >
                                            <strong>{{
                                                "auth.validation.requiredField"
                                                    | translate
                                            }}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <div
                                class="input-group align-items-baseline w-100"
                            >
                                <mat-form-field>
                                    <mat-label>{{
                                        "activities.activity_group" | translate
                                    }}</mat-label>
                                    <mat-select
                                        formControlName="group"
                                        name="type"
                                        [value]="activity?.group"
                                        (selectionChange)="setLocalVal('group', $event)"
                                    >
                                        <div
                                            class="d-flex"
                                            style="
                                                padding: 0 10px;
                                                align-items: center;
                                            "
                                        >
                                            <mat-form-field
                                                appearance="fill"
                                                class="w-100"
                                            >
                                                <!-- <mat-label>
                                                <i class="fa fa-search f-20"></i>
                                            </mat-label> -->
                                                <input
                                                    matInput
                                                    #typeFilter
                                                    placeholder="Search"
                                                />
                                            </mat-form-field>
                                            <div
                                                class="input-group-append pl-1"
                                                (click)="
                                                    addNewActivityGroup()
                                                "
                                            >
                                                <span
                                                    class="input-group-text"
                                                    ><i
                                                        class="icofont icofont icofont-plus"
                                                    ></i
                                                ></span>
                                            </div>
                                        </div>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option
                                                class="noteIcons"
                                                *ngFor="
                                                    let type of activityGroups
                                                        | filter
                                                            : typeFilter.value
                                                "
                                                [value]="type.id"
                                            >
                                                <div class="name">
                                                    {{ type.name }}
                                                </div>
                                                <div class="actions">
                                                    <i
                                                        class="fa fa-edit"
                                                        (click)="
                                                            activityGroupEdit(
                                                                $event,
                                                                type
                                                            )
                                                        "
                                                    ></i>
                                                    <i
                                                        class="fa fa-trash"
                                                        (click)="
                                                            activityGroupDelete(
                                                                $event,
                                                                type
                                                            )
                                                        "
                                                    ></i>
                                                </div>
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="
                                            activityForm.controls[
                                                'group'
                                            ].errors?.required
                                        "
                                    >
                                        <strong>{{
                                            "auth.validation.requiredField"
                                                | translate
                                        }}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row align-items-baseline">
                            <div class="col-12 tbg">
                                <mat-form-field class="w-100" (click)="addEditNameTranslation()">
                                    <mat-label>{{ "activities.title" | translate }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="name"
                                        name="name"
                                        class="p-0"
                                        style="height: 16px;"
                                        readonly #nameInput1
                                    />
                                    <mat-error
                                            *ngIf="
                                                activityForm.controls[
                                                    'name'
                                                ].errors?.required
                                            "
                                        >
                                            <strong>{{
                                                "auth.validation.requiredField"
                                                    | translate
                                            }}</strong>
                                        </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 mb-2">
                                <chips 
                                    [items]="globalRegistry.systemData.lots" 
                                    [selectedItems]="activity ? activity.lots : []"
                                    [label]="'activities.lots' | translate"
                                    (updatedItems)="updateItems($event)"
                                    [addEnabled]="false"
                                >
                                </chips>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row align-items-baseline">
                            <div class="col-4">
                                <mat-form-field (click)="picker.open()">
                                    <input
                                        matInput
                                        readonly
                                        [ngxMatDatetimePicker]="picker"
                                        placeholder="Started"
                                        [disabled]="false"
                                        formControlName="started"
                                        [max]="maxDate"
                                        (dateChange)="minValueChanged($event)"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker"
                                    ></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker
                                        [touchUi]="true"
                                        #picker
                                        [showSpinners]="true"
                                        [showSeconds]="false"
                                    >
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field (click)="until.open()">
                                    <input
                                        matInput
                                        readonly
                                        [ngxMatDatetimePicker]="until"
                                        placeholder="Until"
                                        [min]="minDate"
                                        (dateChange)="maxValueChanged($event)"
                                        [disabled]="false"
                                        formControlName="ended"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="until"
                                    ></mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker
                                        [touchUi]="true"
                                        #until
                                        [showSpinners]="true"
                                        [showSeconds]="false"
                                    >
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ "activities.phaseId" | translate }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="phaseId"
                                        name="phaseId"
                                        (focusout)="setInputVal('phaseId', $event)"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group imgs">
                        <div class="row m-0 ri">
                            <div class="col-person">
                                <div class="person_box">
                                    <div class="person_name">
                                        <div class="combo_box">
                                            <div class="input-group w-100">
                                                <mat-form-field>
                                                    <mat-label>{{
                                                        "Assigned to"
                                                    }}</mat-label>
                                                    <mat-select
                                                        formControlName="assignedTo"
                                                        name="assignedTo"
                                                        (infiniteScroll)="scrollPersons()"
                                                        (selectionChange)="setLocalVal('assignedTo', $event)"
                                                        msInfiniteScroll
                                                    >
                                                        <mat-form-field
                                                            appearance="fill"
                                                            class="w-100"
                                                        >
                                                            <input
                                                                matInput
                                                                #personFilter
                                                                placeholder="search"
                                                            />
                                                        </mat-form-field>
                                                        <div
                                                            class="scroll_wrap"
                                                            (scroll)="onPersonScroll($event)"
                                                        >
                                                        <mat-option style="height: 0!important;"></mat-option>
                                                            <mat-option
                                                                *ngFor="
                                                                    let person of personList
                                                                        | filter
                                                                            : personFilter.value
                                                                "
                                                                [value]="
                                                                    person?.id
                                                                "
                                                                >{{
                                                                    person?.firstName
                                                                }}
                                                                {{
                                                                    person?.lastName
                                                                }}
                                                                {{ person?.first_name }} {{ person?.last_name }}
                                                                </mat-option
                                                            >
                                                        </div>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="person_image user_im img_place_bg">
                                        <ng-container
                                            *ngFor="
                                                let el of personList
                                                    | filter: personFilter.value
                                            "
                                            >
                                            <span><img
                                                *ngIf="
                                                    el?.id ==
                                                        activityForm.controls
                                                            .assignedTo.value &&
                                                    el?.picture != null
                                                "
                                                [src]="mediaUrl+ el?.picture"
                                                alt="Profile"
                                            />
                                            <img
                                                *ngIf="
                                                    el?.id ==
                                                        activityForm.controls
                                                            .assignedTo.value &&
                                                    el?.picture == null
                                                "
                                                src="../../../../../../assets/images/people_placeholder.png"
                                                alt="Profile"
                                            /></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            
                            <ng-container *ngIf="equipments.length === 0">
                                <span style="display: inline-block; width: calc(100% - 110px);"> No equipments found. <span style="border-bottom: 1px solid red; cursor: pointer;" (click)="createEquipment()">Click here</span> to add a new equipment </span>
                            </ng-container>

                            <ng-container *ngIf="equipments.length !== 0">
                            <div class="col-i">
                                <div class="eq_box used">
                                    <div class="eq_name">
                                        <div class="combo_box">
                                            <div class="input-group w-100">
                                                <mat-form-field>
                                                    <mat-label>{{"Used Equipment"}}</mat-label>
                                                    <mat-select class="select" [(ngModel)]="usedEquipment" name="usedEquipment" [ngModelOptions]="{standalone: true}" (selectionChange)="setLocalVal('usedEquipment', $event)">
                                                        <mat-form-field apearance="fill" class="w-100">
                                                            <input matInput #eqFilter placeholder="Search"/>
                                                        </mat-form-field>
                                                        <div class="scroll_wrap">
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                            <div *ngFor="let el of equipments | filter : eqFilter.value">
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                            <mat-option *ngIf="el?.attachable === 0" [value]="el?.id">{{ el?.registrationPlate }}</mat-option>
                                                            </div>
                                                        </div>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="eq_image">
                                        <ng-container *ngFor="let el of equipments">
                                            <ng-container *ngIf="el?.id == usedEquipment">
                                                    <div class="user_im img_place_bg lands" style="height: 76px; border: none; width: 100%">
                                                        <img *ngIf="el?.product?.images?.length > 0" [src]="mediaUrl + el?.product?.images[el?.product?.images.length - 1]?.file" alt="">
                                                        <img src="../../../../../../assets/images/image_placeholder.png" 
                                                        *ngIf="el?.product?.images?.length == 0" alt="">
                                                    </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-i">
                                <div class="eq_box used">
                                    <div class="eq_name">
                                        <div class="combo_box attached">
                                            <div class="input-group w-100">
                                                <mat-form-field>
                                                    <mat-label>{{"Attached Equipment"}}</mat-label>
                                                    <mat-select class="select" [(ngModel)]="attachedEquipment1" name="attachedEquipment1" [ngModelOptions]="{standalone: true}" (selectionChange)="setLocalVal('attachedEquipment1', $event)" name="attachedEquipment1">
                                                        <mat-form-field apearance="fill" class="w-100">
                                                            <input matInput #eqFilter1 placeholder="Search"/>
                                                        </mat-form-field>
                                                        <div class="scroll_wrap">
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                            <div *ngFor="let el of equipments | filter : eqFilter1.value">
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                            <mat-option *ngIf="el?.attachable === 1" [value]="el?.id">{{ el?.registrationPlate }}</mat-option>
                                                            </div>
                                                        </div>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="eq_image">
                                        <ng-container *ngFor="let el of equipments">
                                            <ng-container *ngIf="el?.id == attachedEquipment1">
                                                    <div class="user_im img_place_bg lands" style="height: 76px; border: none; width: 100%">
                                                        <img *ngIf="el?.product?.images?.length > 0" [src]="mediaUrl + el?.product?.images[el?.product?.images.length - 1]?.file" alt="">
                                                        <img src="../../../../../../assets/images/image_placeholder.png" 
                                                        *ngIf="el?.product?.images?.length == 0" alt="">
                                                    </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-i">
                                <div class="eq_box used">
                                    <div class="eq_name">
                                        <div class="combo_box attached">
                                            <div class="input-group w-100">
                                                <mat-form-field>
                                                    <mat-label>{{"Attached Equipment"}}</mat-label>
                                                    <mat-select class="select" [(ngModel)]="attachedEquipment2" name="attachedEquipment2" [ngModelOptions]="{standalone: true}" (selectionChange)="setLocalVal('attachedEquipment2', $event)" name="attachedEquipment2">
                                                        <mat-form-field apearance="fill" class="w-100">
                                                            <input matInput #eqFilter2 placeholder="Search"/>
                                                        </mat-form-field>
                                                        <div class="scroll_wrap">
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                            <div *ngFor="let el of equipments | filter : eqFilter2.value">
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                            <mat-option *ngIf="el?.attachable === 1" [value]="el?.id">{{ el?.registrationPlate }}</mat-option>
                                                            </div>
                                                        </div>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="eq_image">
                                        <ng-container *ngFor="let el of equipments">
                                            <ng-container *ngIf="el?.id == attachedEquipment2">
                                                    <div class="user_im img_place_bg lands" style="height: 76px; border: none; width: 100%">
                                                        <img *ngIf="el?.product?.images?.length > 0" [src]="mediaUrl + el?.product?.images[el?.product?.images.length - 1]?.file" alt="">
                                                        <img src="../../../../../../assets/images/image_placeholder.png" 
                                                        *ngIf="el?.product?.images?.length == 0" alt="">
                                                    </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            </ng-container>
                        </div>
                    </div>
                    <br />
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ "activities.info" | translate }}</mat-label>
                                    <textarea
                                        class="info"
                                        style="height: 80px; resize: none"
                                        matInput
                                        formControlName="info"
                                        name="info"
                                        cols="10"
                                        rows="10"
                                        (focusout)="setInputVal('info', $event)"
                                    ></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="activity?.id" class="ac">
                        <div
                            class="row d-flex"
                            style="
                                padding: 0;
                                margin: auto;
                                justify-content: space-between;
                                width: 100%;
                            "
                        >
                            <!-- <div class="col-3 path">
                                <label>Path</label>
                                <img
                                    src="../../../../../../assets/images/path.png"
                                    alt="path"
                                />
                            </div> -->
                            <!-- <div class="col-3 time">
                                <label>{{ "activities.workingTime" | translate }}</label>
                                <span
                                    ><img
                                        src="../../../../../../assets/images/clock.png"
                                        alt=""
                                    />
                                    {{ calcTime(activity?.workingTime) }}
                                    {{ "activities.unit.min" | translate }}</span
                                >
                            </div>
                            <div class="col-3 distance">
                                <label>{{ "activities.distance" | translate }}</label>
                                <span
                                    ><img
                                        src="../../../../../../assets/images/distance.png"
                                        alt=""
                                    />
                                    {{ activity?.distance }} {{ "activities.unit.km" | translate }}</span
                                >
                            </div>
                            <div class="col-3 area">
                                <label>{{ "activities.area" | translate }}</label>
                                <span
                                    ><img
                                        src="../../../../../../assets/images/area.png"
                                        alt=""
                                    />
                                    {{ activity?.area }} {{ "activities.unit.Ha" | translate }}</span
                                >
                            </div> -->
                            <div class="col-4">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ "activities.workingTime" | translate }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="workingTime"
                                        name="workingTime"
                                        (focusout)="setInputVal('workingTime', $event)"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ "activities.distance" | translate }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="distance"
                                        name="distance"
                                        (focusout)="setInputVal('distance', $event)"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="w-100">
                                    <mat-label>{{ "activities.area" | translate }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="area"
                                        name="area"
                                        (focusout)="setInputVal('area', $event)"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12 mt-2">
                            <fap-resource-table
                                [parties]="globalRegistry?.systemData?.parties"
                                [resourceTypes]="globalRegistry?.systemData?.resourceTypes"
                                [headerTitle]="'Required resources'"
                                >
                            </fap-resource-table>
                        </div>
                        </div> -->
                </div>
                
                <div class="outcome">
                    <div class="title_row">
                        <p>{{ "activities.usedResources" | translate }}</p>
                        <span
                            (click)="verifyFarm()"
                            class="theme_color"
                        ></span>
                    </div>
                    <div class="notedit table-responsive">
                        <table class="items_table" *ngIf="r_items.length > 0">
                            <thead>
                                <tr>
                                    <th>{{ "activities.item" | translate }}</th>
                                    <th style="text-indent: 50px; width: 35%;">{{ "activities.qty" | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of r_items">
                                    <td>{{item?.product?.name}} <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                                        <span *ngIf="type?.id === item?.product?.type">({{type?.name}})</span>
                                        <ng-container *ngIf="type?.id !== item?.product?.type">
                                            <ng-container *ngFor="let childUnit of type?.children">
                                                <span *ngIf="childUnit?.id === item?.product?.type">({{childUnit?.name}})</span> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container></td>
                                    <td>
                                        <div class="frs">{{item?.quantity}}</div>
                                        <div class="inlin">
                                            <b>{{item?.product | getProductUnit: globalRegistry?.systemData?.units}}</b>
                                        </div>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <br />

                <div class="outcome">
                    <div class="title_row">
                        <p>{{ "activities.producedResources" | translate }}</p>
                        <span
                            (click)="verifyFarm()"
                            class="theme_color"
                        ></span>
                    </div>
                    <div class="notedit table-responsive">
                        <table class="items_table" *ngIf="p_items.length > 0">
                            <thead>
                                <tr>
                                    <th>{{ "activities.item" | translate }}</th>
                                    <th style="text-indent: 50px;  width: 35%;">{{ "activities.qty" | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of p_items">
                                    <td>{{item?.product?.name}} <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                                        <span *ngIf="type?.id === item?.product?.type">({{type?.name}})</span>
                                        <ng-container *ngIf="type?.id !== item?.product?.type">
                                            <ng-container *ngFor="let childUnit of type?.children">
                                                <span *ngIf="childUnit?.id === item?.product?.type">({{childUnit?.name}})</span> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container></td>
                                    <td>
                                        <div class="frs">{{item?.quantity}}</div>
                                        <div class="inlin">
                                            <b>{{ item?.product | getProductUnit: globalRegistry?.systemData?.units }}</b>
                                        </div>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <div class="card">
            <fap-attributes
                [entityRelation]="'activity'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="activity?.id"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
            >
            </fap-attributes>
        </div>
    </div>

    <fap-modal
        #addActivityTypeModal
        [inputPrimaryButtonInterface]="
            addActivityTypeModalButtonPrimaryInterface
        "
        [inputSecondaryButtonInterface]="
            addActivityTypeModalButtonSecondaryInterface
        "
        inputTitle="Add/Edit activity type"
    >
        <form [formGroup]="activityTypeForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100" (click)="addEditTranslation()">
                    <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput/>
                    <mat-error *ngIf="activityTypeForm.controls['name'].errors?.required">
                      <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div class="form-group">
                
                <mat-form-field class="w-100">
                    <mat-label class="col_form-label">{{ 'Translation' }}</mat-label>
                    <mat-select formControlName="name_t" name="name_t" [value]="activity?.activityType">
                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #nFilter placeholder="Search">
                        </mat-form-field>
                        <em class="fa fa-plus center f-20 ml-2" (click)="showModal()"></em>
                    </div>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option class="noteIcons" *ngFor="let type of translatedNames | filter : nFilter.value" [value]="type.id">
                            <div class="name"><span>{{ type[langString] ? type[langString] : type.id  }}</span></div>
                            <div class="actions">
                                <i class="fa fa-edit" (click)="editTranslation(type) ;$event.stopPropagation()"></i>
                                <i class="fa fa-trash" (click)="deleteTranslation(type?.id)"></i>
                            </div>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
            
            </div> -->
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "notes.color" | translate
                    }}</mat-label>
                    <input
                        class="w-100"
                        matInput
                        type="color"
                        formControlName="color"
                    />
                    <mat-error
                        *ngIf="
                            activityTypeForm.controls['color'].errors?.required
                        "
                    >
                        <strong>{{
                            "auth.validation.requiredField" | translate
                        }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </fap-modal>

    <fap-modal
        #addActivityGroupModal
        [inputPrimaryButtonInterface]="
            addActivityGroupModalButtonPrimaryInterface
        "
        [inputSecondaryButtonInterface]="
            addActivityGroupModalButtonSecondaryInterface
        "
        inputTitle="Add/Edit activity Group"
    >
        <form [formGroup]="activityGroupForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "activity.activityGroupName" | translate
                    }}</mat-label>
                    <input matInput formControlName="name" />
                </mat-form-field>
            </div>
        </form>
    </fap-modal>

    <fap-modal
        #transaction
        inputTitle="Add Bill"
        [inputPrimaryButtonInterface]="addTransactionButtonPrimaryInterface"
        [inputSecondaryButtonInterface]="addTransactionButtonSecondaryInterface"
        [inputIsMediumLarge]="true"
    >
        <div></div>
    </fap-modal>

    <div class="overlay" *ngIf="isPerson" (click)="isPerson = false"></div>
    <div class="popup" *ngIf="isPerson">
        <fap-people-card-list
            [persons]="personList"
            (closeModal)="closeModal($event)"
        ></fap-people-card-list>
    </div>
</div>

<fap-modal 
    #addImageModal
    [inputPrimaryButtonInterface]="addImageModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addImageModalButtonSecondaryInterface"
    inputTitle="Add Image"
>
<form [formGroup]="imageForm" class="col-12 p-0">
    <div class="form-group" 
    style="display: none">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Group' }}</mat-label>
            <input matInput formControlName="group" readonly value="equipments">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Tags' }}</mat-label>
            <input matInput formControlName="tags" placeholder="Tags">
        </mat-form-field>
    </div>
    <div class="form-group">
        <label style="display: block;" class="upload-img-label">{{ 'Image' | translate }}</label>
        <input #fileUploader type="file" (change)="onFileInput($event)">
        <img *ngIf="imageSrc" class="preview-img d-block" [src]="imageSrc" alt="image">
    </div>
</form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="'Type name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.activityType)" 
        [prefix]="prefix" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditNameTranslationsModal [inputTitle]="'Activity name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.activity)" 
        [prefix]="'ACTIVITY_'" 
        [translation]="nameT"
        [group]="'activities'"
        (submitTranslation)="onAddUpdateNameTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>