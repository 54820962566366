import { FapBaseModel } from '../../base/fap-base-model';


export class ActivityGroupModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'activities',
        'distance',
        'area',
        'working_time',
        'cost',
        'data'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public activities: Array<number>;
    public distance: number;
    public area: number;
    public working_time: any;
    public cost: number;
    public data: {}
}
