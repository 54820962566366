import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    OnInit,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from 'src/environments/environment';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { PersonModel } from '../../../../core/models/person/person.model';
import { ActivityService } from '../../../../core/services/api/activity/activity.service';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { FarmService } from '../../../../core/services/api/farm/farm.service';
import { FapModalComponent } from '../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NavService } from '../../../../shared/services/nav.service';

@Component({
    selector: 'fap-user-card',
    templateUrl: './fap_user-card.component.html',
    styleUrls: ['./fap_user-card.component.scss'],
})
export class FapUserCardComponent implements OnInit {
    public defaultImg: string;

    @Input()
    public person: PersonModel;

    public groupNames = '';

    @Input()
    public enableAddEdit: boolean;

    @Input()
    public enableDelete: boolean;

    @Output()
    public removePerson: EventEmitter<number> = new EventEmitter();
    @Input()
    public crops;
    
    public images;
    public imageForm: UntypedFormGroup;
    public image: File;
    uploadFileAcceptFormats: string[] = ['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'];
    activity_count = 0;
    public messageForm: UntypedFormGroup;
    public sendMsgModalButtonPrimaryInterface: FapModalButtonInterface;
    public sendMsgModalButtonSecondaryInterface: FapModalButtonInterface;
    @ViewChild('sendMsgModal')
    public sendMsgModal: FapModalComponent;
    public translatedNames:any = [];
    public langString: string;
    @ViewChild('addImageModal')
    public addImageModal: FapModalComponent;
    public subscription: Subscription = new Subscription();
    public changedImage: boolean;
    public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
    public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
    public msgImages = [];
    public personIds = [];
    imageSrc: string;
    public imgWidth: number;
    public imgHeight: number;
    public orientation: string;
    public mediaUrl = environment.mediaUrl;
    public nextImgToken :{ offset: number; limit: number; group: string; } = null;
    public getMoreImg = true;

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    @ViewChild('fileUploader') fileUploader:ElementRef;

    constructor(
        public globalRegistryService: GlobalRegistryService,
        public activityService: ActivityService,
        public translateService: TranslateService,
        public navService: NavService,
        public companyService: CompanyService,
        public farmService: FarmService,
        public cacheService: CacheResolverService,
        public toastrService: ToastrService
    ) {}

    ngOnInit(): void {
      this.defaultImg = 'assets/images/people_placeholder.svg';
      this.groupNames = this.globalRegistryService.systemData.peopleGroups
          .filter((group) => group.users.includes(this.person.id))
          .map((group) => group.name)
          .join(',');
          if(this.person && this.person.activities) {
            this.activity_count = this.person.activities.length;
          }
      this.initForm();
      this.initSendMsgModalButtons();
      this.initImageModalButtons();
      this.initImageForm();
      this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
          if(lang) {
              this.langString = lang;
          }
      }));
      this.translatedNames = this.globalRegistryService.systemData.translations;
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'Deselect All',
        itemsShowLimit: 3000,
        allowSearchFilter: true
      };
  }

  initImageForm() {
    this.imageForm = new UntypedFormGroup({
      file: new UntypedFormControl('', Validators.required),
      group: new UntypedFormControl('posts', Validators.required),
      tags: new UntypedFormControl([], Validators.required),
    });
  }

  updateItems(lots) {
    this.messageForm.get('lots').setValue(lots);
  }

  changeLotFormat(lotIds) {
    const lots = [];
    lotIds.forEach(element => {
        if(typeof(element) === 'number') {
        const lot = this.globalRegistryService.systemData.lots.filter(l => {
            if(l.id === element) {
                return l;
            }
        });
        lots.push(lot[0]);
    }});
    const uniqueLots = lots.filter(function(item, pos) {
        return lots.indexOf(item) == pos;
    })
    this.selectedItems = uniqueLots;
    return uniqueLots
}

    public sendMsg(personId) {
        console.log(personId);
    }

    public initSendMsgModalButtons(): void {
        const _this: FapUserCardComponent = this;

        this.sendMsgModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.submit();
            },
            text: this.translateService.instant('save'),
        };

        this.sendMsgModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.sendMsgModal.hideModal();
            },
            text: this.translateService.instant('cancel'),
        };
    }

    public initForm() {
        this.messageForm = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            crop: new UntypedFormControl(''),
            lots: new UntypedFormControl([]),
            images: new UntypedFormControl([]),
            info: new UntypedFormControl('')
        });
    }

    get f() {
      return this.messageForm.controls;
    }

    public submit() {
        if (this.messageForm.invalid) {
            Object.keys(this.messageForm.controls).forEach(
                (controlName: string) =>
                    this.messageForm.controls[controlName].markAsTouched()
            );
            return;
        }
        console.log(this.messageForm.value);
        this.farmService.sendMessage(this.personIds, this.messageForm.value).subscribe(data => {
          console.log(data);
          if(data.body.results) {
            
            this.sendMsgModal.hideModal();
          }
        }, () => {
          this.toastrService.error("Send message failed!");
        })
    }

    public showMsgModal(id): void {
      this.sendMsgModal.showModal();
      this.personIds.push(id);
  }

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
      return translation
  }
  }

  showCropModal() {
    console.log("show crop modal triggered!");
  }

  // editCrop(ev) {

  // }

  // deleteCrop(ev) {

  // }

public imageSubmit() {
  const data = {
    width: this.imgWidth,
    height: this.imgHeight,
    orientation: this.orientation
}
    const formData = new FormData();
    formData.append("file", this.image);
    formData.append("group", this.imageForm.get('group').value);
    formData.append("tags", this.imageForm.get('tags').value);
    formData.append("data", JSON.stringify(data));
    console.log(formData);
    // return;
    this.companyService.postCompanyImages(formData).subscribe(data => {
        console.log(data);
        this.imageForm.reset();
        this.imageForm.clearValidators();
        this.getImages();
    })
  this.addImageModal.hideModal();
}

public showImageModal(): void {
  this.addImageModal.showModal();
}

onFileInput(event) {
  if(event.target.files && event.target.files.length) {
    if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
      this.toastrService.error(this.translateService.instant('image.notAnImage'))
      this.image = null;
      this.fileUploader.nativeElement.value = null;
      return
    }
    const [image] = event.target.files;
    this.image = image;
    const _URL = window.URL || window.webkitURL;
    let file, img, height, width;
    if ((file = event.target.files[0])) {
        img = new Image();
        const objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
            height = this.height;
            width = this.width;
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    }
    setTimeout(()=> {
      if(width <= height) {
        this.orientation  = "portrait";
        console.log("portrait");
      } else {
          this.orientation  = "landscape";
          console.log("landscape");
      }
    }, 500);
  }
}

public imageUploaded(file: File): void {
  this.changedImage = true;
  this.image = file;
  console.log(this.image);
  this.imageForm.markAsDirty();
}

public initImageModalButtons(): void {
  const _this: FapUserCardComponent = this;

  this.addImageModalButtonPrimaryInterface = {
      clickFunction(): void {
          _this.imageSubmit();
      },
      text: this.translateService.instant('save')
  };

  this.addImageModalButtonSecondaryInterface = {
      clickFunction(): void {
          _this.addImageModal.hideModal();
      },
      text: this.translateService.instant('cancel')
  };
}

addImage(ev) {
  this.msgImages.push(ev.value.id);
  console.log(this.msgImages);
  this.messageForm.get('images').setValue(this.msgImages);
}

deleteNoteImage(imgId) {
  this.msgImages = this.msgImages.filter((item) => item !== imgId);
  console.log(this.msgImages);
  this.messageForm.get('images').setValue(this.msgImages);
}

public getImages() {
  const url = this.companyService.getUrl('images/')
  this.cacheService.delete(url+'group=posts');
  this.companyService.getCompanyImages({group: 'posts'}).subscribe((response): void => {
      this.images = response.body["results"];
      console.log(this.images);
      this.nextImgToken = response.body.next
      ? this.globalRegistryService.getQueryStringParams(response.body.next.split("?")[1])
      : null;
      if(this.nextImgToken) this.getMoreImg = true
    })
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
      this.scrolledDownImages();
    }
  }

  public deleteImage(imageId: number): void {
    console.log(imageId);
    this.subscription.add(this.companyService.deleteCompanyImage(imageId).subscribe(data => {
        console.log(data);
        this.images = [];
        this.getImages();
    }))
  }

  scrolledDownImages() {
    const url = this.companyService.getUrl('images/');
    console.log('scrolled');
    if(this.getMoreImg) {
    this.nextImgToken &&
      this.subscription.add(this.companyService.getCompanyImages(this.nextImgToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextImgToken.limit+'&offset='+this.nextImgToken.offset+'&group=posts');
        console.log(data);
        if(this.images) {
          this.images = [...this.images, ...data.body.results];
        } else {
          this.images = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreImg = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextImgToken.offset != params['offset']) {
          this.nextImgToken = {limit: params['limit'], offset: params['offset'], group: 'posts'};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

}
