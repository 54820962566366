<div class="row" id="parent">
    <div class="col-12">
        <div class="card mt-2">
            <div class="card-header px-3  rounded-0">
                <p>{{title}} {{ 'widget.overview' | translate }}</p>
            </div>
            <div class="card-body">
                <form [formGroup]="sensorsForm">
                    <div class="form-group">
                        <div class="row align-items-baseline schd">
                            <div class="col-12">
                                <div class="input-group w-100">
                                    <mat-form-field>
                                        <mat-label>{{"Name"}}</mat-label>
                                        <input style="width: 100%;" matInput placeholder="Name" readonly formControlName="name" (click)="showNameForm($event)">
                                      </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group w-100">
                                    <mat-form-field class="dsbld">
                                        <mat-label>{{"Type"}}</mat-label>
                                    <mat-select required formControlName="type">
                                        <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <div class="slct">
                                              <mat-option
                                                *ngFor="let type of deviceTypes | filter : farmFilter.value"
                                                [value]="type.id"
                                                style="text-transform: capitalize;"
                                              >
                                              <span class="small_text">{{ getTranslation(type?.name_t) }}</span>
                                              </mat-option>
                                            </div>
                                         
                                        </div>
                                      </mat-select>
                                      </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group w-100">
                                    <mat-form-field class="dsbld">
                                        <mat-label>{{"Device"}}</mat-label>
                                    <mat-select required formControlName="device">
                                        <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <div class="slct">
                                              <mat-option
                                                *ngFor="let device of devices | filter : farmFilter.value"
                                                [value]="device.id"
                                                style="text-transform: capitalize;"
                                              >
                                              <span class="small_text">{{device?.name ? device?.name : device?.id}}</span>
                                              </mat-option>
                                            </div>
                                         
                                        </div>
                                      </mat-select>
                                      </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group w-100">
                                    <mat-form-field class="dsbld">
                                        <mat-label>{{"Lot"}}</mat-label>
                                    <mat-select required formControlName="lot">
                                        <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                          <mat-optgroup *ngFor="let group of lotGroup | filter : farmFilter.value" [label]="getFarmName(group.farm)">
                                            <div class="slct">
                                              <mat-option
                                                *ngFor="let lot of group.data"
                                                [value]="lot.id"
                                                style="text-transform: capitalize;"
                                              >
                                              <span class="small_text">{{lot?.id}}.</span>
                                              <span class="middle_text">{{lot?.name}}</span>
                                              </mat-option>
                                            </div>
                                          </mat-optgroup>
                                        </div>
                                      </mat-select>
                                      </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group w-100">
                                    <mat-form-field class="dsbld">
                                        <mat-label>{{"Run"}}</mat-label>
                                    <mat-select required formControlName="run">
                                        <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                                            <input matInput #farmFilter placeholder="Search">
                                        </mat-form-field>
                                        <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <div class="slct">
                                              <mat-option
                                                *ngFor="let device of devices | filter : farmFilter.value"
                                                [value]="device.id"
                                                style="text-transform: capitalize;"
                                              >
                                              <span class="small_text">{{device?.id}}</span>
                                              </mat-option>
                                            </div>
                                        </div>
                                      </mat-select>
                                      </mat-form-field>
                                </div>
                            </div>
                            <div class="col-4 state">
                                <div class="input-group justify-content-center">
                                    <mat-label style="margin-right: 5px;"> {{ 'Stop' }} </mat-label>
                                    <mat-slide-toggle color="#34A59A" (change)="changeStatus($event)" formControlName="active" name="active">Start</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-12 cond">
                            <mat-form-field>
                                <mat-label> {{ 'Run condition' }} </mat-label>
                                <input matInput readonly disabled formControlName="condition" [value]="condition">
                            </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div>
            <div class="card mt-2">
                <div class="card-header px-3 d-flex rounded-0" style="align-items: center; justify-content: space-between">
                    <p style="font-size: 17px; margin: 0;">{{ 'Schedule' }}</p>
                    <span class="add_action"><span (click)="addAction()"></span></span>
                </div>
                <div class="acard-body">
                    <div class="white-background pb-2">
                        <ng-container *ngIf="tempActions">
                        <div class="action-viewer" *ngFor="let action of tempActions">
                            <div [ngStyle]="{'color': '#000'}" class="pad0 dat" style="padding-left: 10px; padding-right: 10px;">
                                <b>{{action.start | date: "MMM dd yyyy"}}</b> <b>{{ action.end | date: "MMM dd yyyy" }}</b>
                            </div>
                            <div class="min-hour">
                                <div class="mins">
                                    <span class="lbl">min:</span> 
                                    <span *ngIf="action?.minute">{{action.minute}}</span> <span *ngIf="!action?.minute">*</span>
                                </div>
                                <div class="hours">
                                    <span class="lbl">hour:</span> 
                                    <span *ngIf="action?.hour">{{action.hour}}</span> <span *ngIf="!action?.hour">*</span>
                                </div>
                            </div>
                            <div class="day-month">
                                <div class="days">
                                    <span class="lbl">day:</span> 
                                    <span *ngIf="action?.minute">{{action.day}}</span> <span *ngIf="!action?.day">*</span>
                                </div>
                                <div class="months">
                                    <span class="lbl">month:</span> 
                                    <span *ngIf="action?.month">{{action.month}}</span> <span *ngIf="!action?.month">*</span>
                                </div>
                            </div>
                            <div class="week-duration">
                                <div class="weeks">
                                    <span class="lbl">week day:</span> 
                                    <span *ngIf="action?.dweek">{{action.dweek}}</span> <span *ngIf="!action?.dweek">*</span>
                                </div>
                                <div class="duration">
                                    <span class="lbl blk">duration:</span> 
                                    <span *ngIf="action?.duration">{{action.duration}} mins</span> <span *ngIf="!action?.duration">*</span>
                                </div>
                            </div>
                            <div class="pad0 d-flex ics">
                                <i class="fa fa-trash" *ngIf="action?.id" (click)="deleteAction(action?.id)"></i>
                                <i class="fa fa-trash" *ngIf="action?.tempId" (click)="deleteAction(action?.tempId)"></i>
                                <img (click)="editAction(action)" class="editor-pen" src="./assets/images/fap/edit_pencil.svg" alt="edit action" />
                            </div>
                        </div>
                    </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom_modal" id="custom_modal">
            <div class="modal_header">
                <h2>{{"translations.addEditAction" | translate}}</h2>
                <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
            </div>
            <form [formGroup]="actionsForm">
                <div class="form-group">
                    <div class="row align-items-baseline">
                        <div class="col-12">
                            <div class="input-group w-100">
                                <div class="col">
                                    <mat-form-field (click)="picker.open()">
                                        <input matInput readonly [ngxMatDatetimePicker]="picker" placeholder="Start" 
                                          [min]="minDate" [disabled]="false" formControlName="start">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker [touchUi]="true" #picker [showSpinners]="true" [showSeconds]="false">
                                        </ngx-mat-datetime-picker>
                                      </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field (click)="picker1.open()">
                                        <input matInput readonly [ngxMatDatetimePicker]="picker1" placeholder="End" 
                                        [min]="minDate" [disabled]="false" formControlName="end">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker [touchUi]="true" #picker1 [showSpinners]="true" [showSeconds]="false">
                                        </ngx-mat-datetime-picker>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <div class="input-group justify-content-center">
                                        <mat-label> {{ 'Disabled' }} </mat-label>
                                        <mat-slide-toggle color="#34A59A" formControlName="enabled" name="active">Enabled</mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row align-items-baseline">
                        <div class="col-12">
                            <button class="btn save" *ngIf="!isEdit" (click)="saveAction()">{{"translations.save" | translate}}</button>
                            <button class="btn save" *ngIf="isEdit" (click)="updateAction()">{{"translations.update" | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="custom_modal" id="custom_modal1">
            <div class="modal_header">
                <h2>{{"translations.updateSensorName" | translate}}</h2>
                <button type="button" class="btn close-modal" (click)="hideNameForm()"><i class="fa fa-times"></i></button>
            </div>
            <form [formGroup]="nameForm">
                <div class="form-group">
                    <div class="row align-items-baseline">
                        <div class="col-12">
                            <div class="input-group w-100">
                                <div class="col">
                                    <mat-form-field>
                                        <mat-label> {{ 'Name' }} </mat-label>
                                        <input matInput formControlName="name" />
                                    </mat-form-field>
                                </div>
                                <div class="row align-items-baseline">
                                    <div class="col">
                                        <button class="btn save" (click)="saveName()">{{"translations.save" | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>