import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlergotelService } from '../../../../core/services/api/alergotel/alergotel.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../../shared/services/nav.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { UserService } from '../../../../core/services/api/user/user.service';
import { Subscription, combineLatest } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { MapService } from '../../../../shared/layout/fap_main-map/service/map-service';
import { WidgetsService } from '../../../../core/services/api/widgets/widgets.service';
import { ThemeService } from '../../../../core/services/theme/theme.service';
import { UntypedFormControl } from '@angular/forms';
import { SensorService } from '../../../../core/services/api/sensor/sensor.service';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class AlergotelReportsComponent implements OnInit, OnDestroy {

  public subscriptions: Array<Subscription> = [];
    public items = [];
    public mediaUrl = environment.mediaUrl;
    public translatedNames: any = [];
    public currentIndex = 0;
    public limit = 30;
    public currentUser;
    public getMore = true;
    public langString: string;
    public nextToken: { limit: number; offset: number; search: string, from, to } = null;
    isPortrait: boolean;
    isLandscape: boolean;
    public mapIsOpened = true;
    public isLoading = false;
    public mapShiftedOnce: boolean;
    public mapShiftedTwice: boolean;
    public theme;
    public noteFilterCtrl: UntypedFormControl = new UntypedFormControl();
    public statusCodes = [
      { "key": 0, "value": "REPORTED" },
      { "key": 1, "value": "VALIDATE_REPORT" },
      { "key": 2, "value": "AUTHORITY_CHECK" },
      { "key": 3, "value": "NOTIFY_OWNER" },
      { "key": 4, "value": "OWNER_FIX" },
      { "key": 5, "value": "CHECK_AFTER" },
      { "key": 6, "value": "CLOSED" }
    ];
    public isBaseApplication = true;
    public reportId: number;
    scrollPosition: number;
    public fromDate = new Date();
    public toDate =  new Date();
    public searchString;
    @ViewChild('reportsParent', { static: true }) reportsParent: ElementRef;

  constructor(
    public alergotelService: AlergotelService,
    public globalRegistry: GlobalRegistryService,
    public navService: NavService,
    public router: Router,
    public typesService: TypesService,
    public cacheService: CacheResolverService,
    public userService: UserService,
    public mapService: MapService,
    public widgetService: WidgetsService,
    public themeService: ThemeService,
    public navServices: NavService,
    public route: ActivatedRoute,
    public sensorService: SensorService,
    public widgetsService: WidgetsService,
) {
  this.globalRegistry.reloadTranslations();
      this.userService.getUser().subscribe(response => {
        this.currentUser = response.body.results;
        this.userService.setCurrentUser(this.currentUser);
        this.userService.setTheme(this.currentUser.profile.data.ui.theme);
    });

    const currentUrl = this.router.url;
  if (currentUrl.includes('pages')) {
    this.isBaseApplication = true;
  } else {
    this.isBaseApplication = false;
  }
  this.widgetService.setSize(4);
  this.isLoading = true;
    this.langString = localStorage.getItem('language');
    this.subscriptions.push(
        this.navService.getCurrentLanguage.subscribe((lang) => {
            if (lang) {
                this.langString = lang;
            }
        })
    );

    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: localQueryParams,
    });
    const url = this.alergotelService.getUrl('')
    this.subscriptions.push(
      combineLatest([
        route.queryParams,
        this.alergotelService.getReports({ limit: this.limit }),
      ]).subscribe(
        ([queryParams, reports]: [
          Params,
          any
        ]): void => {
          this.items = reports.body.results;
          // cacheService.delete(url+'limit='+this.limit);
          this.nextToken = reports.next
            ? this.globalRegistry.getQueryStringParams(reports.next.split("?")[1])
            : null;
          
          if (queryParams["filter"] !== undefined) {
            const search = (queryParams["filter"] as string).toLowerCase();
            alergotelService.getReports({limit:this.limit, search}).subscribe(data => {
              cacheService.delete(url+'limit='+this.limit+'search='+search);
              this.nextToken = data["next"]
            ? this.globalRegistry.getQueryStringParams(data["next"].split("?")[1])
            : null;
            if(this.nextToken) this.getMore = true;
            this.items = data.body["results"];
            this.reportsParent.nativeElement.scroll(0,0);
            console.log(this.items);
            })
          }
          // this.getScrollPosition();
        }
      )
    );

    // this.alergotelService.getReports({ limit: this.limit }).subscribe(
    //     (data) => {
    //       this.isLoading = false;
    //         this.items = data.body.results;
    //         console.log(this.items);
    //         setTimeout(() => {
    //           this.centerOnCircles();
    //         }, 500)
    //         this.nextToken = data.body.next
    //     ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
    //     : null;
    //         if(this.nextToken) this.getMore = true;
    //     },
    //     (err) => {
    //         console.log(err);
    //         return;
    //     }
    // );
}

public onMapToggle(mapIsOpened: boolean): void {
  this.mapIsOpened = mapIsOpened;
}

public onMapShift(mapIsShifted: number): void {
  switch (mapIsShifted) {
      case 0: {
          this.mapShiftedTwice = false;
          this.mapShiftedOnce = false;
          break;
      }
      case 1: {
          this.mapShiftedOnce = true;
          break;
      }
      case 2: {
          this.mapShiftedTwice = true;
          break;
      }
      default: {
          this.mapShiftedTwice = false;
          this.mapShiftedOnce = false;
          break;
      }
  }
}

  getTranslations() {
    this.subscriptions.push(
        this.typesService.getTranslations().subscribe((data) => {
            this.translatedNames = data.model;
            return;
        })
    );
}

public clearNotesFilter(): void {
  this.noteFilterCtrl.reset("");
  const params = window.location.search
    ? this.globalRegistry.getQueryStringParams(window.location.search)
    : {};
  delete params["filter"];
  this.router.navigate(["/alergotel/reports"], {
    queryParams: params,
    queryParamsHandling: "",
  });
}

public onSearchSubmit(page:string,value: string): void {
  console.log(value);
  // const params = window.location.search? this.getQueryStringParams( window.location.search) :{};
  this.router.navigate(['/alergotel/',page], {
      queryParams: {filter: value},
      queryParamsHandling: ''
  });
    if (value !== undefined) {
      const search = (value as string).toLowerCase();
      const url = this.alergotelService.getUrl('reports/')
      this.alergotelService.getReports({search}).subscribe(data => {
        this.cacheService.delete(url+'search='+search);
        this.nextToken = data.body["next"]
      ? this.globalRegistry.getQueryStringParams(data.body["next"].split("?")[1])
      : null;
      if(this.nextToken) this.getMore = true;
      this.items = data.body["results"];
      setTimeout(() => {
        this.centerOnCircles();
      }, 500)
      console.log(this.items);
      })
    }
}

ngOnInit() {
  this.widgetsService.setDrill(true);
  this.widgetsService.setCreateWidget(true);
  this.getFromDate();
  this.getToDate();
  this.getSearchString();
  this.route.queryParams.subscribe(params => {
    if (params.returnFromDetail) {
      window.scrollTo(0, this.scrollPosition);
    }
  });
    this.mapService.reportId.subscribe(val => {
      if(val) {
          this.reportId = val;
          console.log(this.reportId);
          this.router.navigate([this.isBaseApplication ? '/pages/alergotel_reports/' + this.reportId : '/alergotel/reports/' + this.reportId]);
      }
  });
  this.mapService.showMap();
}

getFromDate() {
  this.subscriptions.push(this.sensorService.getReportFromDate.subscribe(res => {
    this.fromDate = res;
  }));
}

getToDate() {
  this.subscriptions.push(this.sensorService.getReportToDate.subscribe(res => {
    this.toDate = res;
    this.getFilteredReports();
  }));
}

getSearchString() {
  this.subscriptions.push(this.sensorService.getSearchString.subscribe(res => {
    console.log(res);
    this.searchString = res;
  }));
}

public centerOnCircles() {
  if(this.items.length) {
    const reportsWithCoordinates = this.items.filter(report => 
      report.coords && report.coords.coordinates.length === 2
    );
    if (reportsWithCoordinates.length > 0) {
      this.mapService.reports = reportsWithCoordinates;
      this.mapService.centerMapOnCircles()
    } else {
      this.mapService.reports = [];
    }
  }
}

public getFilteredReports() {
  if(this.fromDate && this.toDate) {
  this.reportsParent.nativeElement.scroll(0,0);
  const url = this.alergotelService.getUrl('reports/');
  this.isLoading = true;
  const fromDate = this.fromDate.toISOString();
  const toDate = this.toDate.toISOString();
  const searchString = this.searchString? this.searchString : ''
  this.alergotelService.getReports({
      from: fromDate,
      limit: 30,
      offset: 0,
      to: toDate,
      search: searchString
   }).subscribe(data => {
    this.cacheService.delete(url+'from='+fromDate+'&limit='+this.limit+'&offset=0'+'&to='+toDate+'&search='+searchString);
      console.log(data.body.results);
      this.items = data.body.results;
      setTimeout(() => {
        this.centerOnCircles();
      }, 500)
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      if(this.nextToken) this.getMore = true
      this.isLoading = false;
   });
  }
}

setReport(report) {
    // this.mapService.reports = [];
    const coordinates = report.coords.coordinates;
    this.mapService.mapFocusPoint = { lat: coordinates[0], lng: coordinates[1] };
    this.mapService.mapZoom.next(18);
    // this.mapService.reports.push(report);
}

addReport() {
  if(this.isBaseApplication) {
    this.router.navigate(['/pages/alergotel_add-report']);
  } else {
    this.router.navigate(['/alergotel/add-report']);
  }
}

getTranslation(translation) {
    const t = this.translatedNames.filter((trans) => {
        return trans.id === translation;
    });
    if (t[0]) {
        return t[0][this.langString];
    } else {
        return translation;
    }
}

paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  console.log(result);
  return result;
}

getNextParticles() {
const url = this.alergotelService.getUrl('');
if(this.getMore) {
this.nextToken &&
  this.alergotelService.getReports(this.nextToken).subscribe((data) => {
    this.cacheService.delete(url+'from='+this.nextToken.from+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search+'&to='+this.nextToken.to);
    console.log(data);
    if(this.items) {
      this.items = [...this.items, ...data.body.results];
    } else {
      this.items = data.body.results;
    }
    // this.filterdItems = this.items;
    if(data.body.next == null) {
      this.getMore = false;
      return
    } else {
      const url = data.body.next.split('?')
      const urlParams = new URLSearchParams(url[1]);
      const entries = urlParams.entries();
      const params = this.paramsToObject(entries);
      if(this.nextToken.offset != params['offset']) {
      this.nextToken = {from: params['from'], limit: params['limit'], offset: params['offset'], search : params['search'] ? params['search'] : '', to: params['to']};
      } else {
        return
      }
    }
    this.centerOnCircles();
  });
} else {
  return
}
}

getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          const [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
}

getReportDetail(reportId) {
  this.route.params.subscribe(() => {
    const baseRoute = this.router.url;
    this.router.navigate([baseRoute, reportId]);
  });
}

storeScrollPosition(report) {
  setTimeout(() => {
    if(this.isBaseApplication) {
      this.router.navigate(['/pages/alergotel_reports/', report.id])
    } else {
      this.router.navigate(['/alergotel/reports/', report.id])
    }
  }, 0);
}

ngOnDestroy(): void {
  this.subscriptions.forEach(element => {
    element.unsubscribe();
  });
  this.mapService.reports = [];
  this.reportId = null;
  this.mapService.reportId.next(null);
}

}
