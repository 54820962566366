<div
  class="card-header-wrapper d-flex flex-wrap theme_bg custom_header"
  style="padding-right: 10px;"
>
  <div class="d-flex w-100">
    <div class="d-flex justify-content-between w-100">
      <div
        *ngxPermissionsOnly="['COMPANY_ADMIN']"
        class="d-flex align-items-center"
      >
      <h2
      class="card-header-title custom_title"
    >
      {{ farm?.name }}
    </h2>
        <img [ngStyle]="{ cursor: 'pointer' }" *ngIf="enableAddEdit" [routerLink]="'/pages/farm/edit/' + farm.id" src="assets/images/fap/pencil.svg" class="fa-pencil ml-1" alt='Edit farm' />
      </div>
      <div
        [routerLink]="enableAddEdit?['/pages',addRoute,'add']:[]"
        [queryParams]="{ farm: farm.id }"
        class="d-flex align-items-center"
      >
        <fap-add-button
          *ngIf="enableAddEdit"
        ></fap-add-button>
        <div class="card-header-forecast d-flex">
          <div class="d-flex align-items-center">
            <img src="/assets/images/fap/temperature.png" />{{
              forecast?.temperature
            }}&deg;C
          </div>
          <div class="d-flex align-items-center">
            <img src="/assets/images/fap/humidity.png" />{{ forecast?.precip }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
