<div class="cards-grid d-flex flex-col flex-wrap flex-md-{grow|shrink}-1 justify-content-md-start justify-content-center" id="parent99">
    <ng-container *ngFor="let workflow of workflowList">
        <workflow-card [workflow]="workflow" [selectedType]="selectedType"></workflow-card>
    </ng-container>
</div>

<ng-container>
    <div class="custom_modal" id="workflow_modal">
        <div class="modal_header">
            <h2 class="title pc-view" *ngIf="entityInfo?.name_t?.id">{{ entityInfo?.name_t[langString] ? entityInfo?.name_t[langString] : entityInfo?.name }}</h2>
            <h2 class="title pc-view" *ngIf="!entityInfo?.name_t">{{ entityInfo?.name }}</h2>
            <div class="d-flex">
            <button type="button" class="btn close-modal" (click)="closeModal()"><i class="fa fa-times"></i></button>
            </div>
        </div>
        <div class="modal_body">
        <fap-attributes
            [entityRelation]="'workflow_modal'"
            [entityId]="entityInfo?.id"
            [entityInfo]="entityInfo"
            [objectId]="objectId"
            (hideModal)="hideModal()"
            (formUpdate)="onUpdateAction()"
            [formDisplayType]="'workflow_modal'"
            [previousFormClick]="previousFormClick"
            [nextFormClick]="nextFormClick"
            [form]="workflow?.current_form"
            [workflow]="workflow"
            (nextButtonClick)="nextButtonClick($event)"
            (prevButtonClick)="prevButtonClick()"
            [isDynamic]="true"
        >
        </fap-attributes>
        </div>
    </div>
</ng-container>