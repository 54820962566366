<span *ngIf="isLoading" class="loader">
    <i></i>
  </span>
  <ng-container *ngFor="let sensor of sensors">
    <div class="cards-grid">
      <scheduler-card
        [sensor]="sensor"
        (deleteSensor)="deleteSensor($event)"
        [devices]="devices"
      ></scheduler-card>
    </div>
  </ng-container>
  