import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { WidgetModel } from "../../../core/models/widget/widget.model";
import {
  ApexChart,
  ApexFill,
  ApexLegend,
  ApexDataLabels,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
} from "ng-apexcharts";
import { SensorModel } from "../../../core/models/sensor/sensor.model";
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { Subscription, forkJoin } from 'rxjs';
import { DeviceModel, ObjectModel } from "../../../core/services/api/objects/object.service";
import { UnitTypeModel } from "../../../core/services/api/unit-type/unit-type.service";
import { NavService } from "../../services/nav.service";
import { DataService } from "../../../core/services/websocket/data.service";
import { UtcToLocalPipe } from "../../../core/pipes/utcToLocal.pipe";
import { UserService } from "../../../core/services/api/user/user.service";
import { TimezoneService } from "../../../core/services/api/timezone/timezone.service";
import { map } from "rxjs/operators";
import { TimeAgoPipe } from "../../../core/pipes/timeAgo.pipe";

export type RadialChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
};

export type PieChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
};


@Component({
  selector: 'graph-percentage-widget',
  templateUrl: './graph-percentage-widget.component.html',
  styleUrls: ['./graph-percentage-widget.component.scss'],
  providers: [UtcToLocalPipe, TimeAgoPipe],
})
export class GraphPercentageWidgetComponent implements OnInit, OnDestroy,OnChanges {

  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() devices: Array<DeviceModel> = [];

  @Output()
  public emitShow:EventEmitter<boolean> = new EventEmitter<boolean>();

  public radialChartOptions: Partial<RadialChartOptions>;
  public pieChartOptions: Partial<PieChartOptions>;
  public donutChartOptions: Partial<PieChartOptions>;
  public polarAreaChartOptions: Partial<PieChartOptions>;
  public localSensors = [];
  public finalSensorList = [];
  public loadedData = [];
  public newData;
  public seriesData = [];
  public dateList = [];
  public fromDate;
  public toDate;
  public agg;
  public toggleMain = false;
  public toggleSub = false;
  public cssClass = '';
  public randomId;
  public subscriptions: Array<Subscription> = [];
  public dates =[];
  public translatedNames: any = [];
  public langString: string;
  // apiCalled = false;
  public isLoading = false;
  public noData = false;
  // private utcToLocalPipe: UtcToLocalPipe
  public timezone;
  public sensorNames = [];
    public columns: any[] = [];
    public sensorIds = [];
    public units: [];
    public currentSensors: [];

  constructor(public globalRegistry:GlobalRegistryService,public sensorService:SensorService, public navService: NavService, public dataService: DataService, private userService: UserService, public timezoneService: TimezoneService, private utcToLocalPipe: UtcToLocalPipe, private timeAgoPipe: TimeAgoPipe) {
    // this.utcToLocalPipe = new UtcToLocalPipe();
    this.subscriptions.push(
      this.userService.getCurrentUser.subscribe((user) => {
        this.timezone =
          user.profile.data.timezone ||
          Intl.DateTimeFormat().resolvedOptions().timeZone ||
          'RO';
      })
    );
    this.radialChartOptions = {
      series: this.seriesData,
      chart: {
        type: "radialBar",
        height: '100%'
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px"
            },
            value: {
              fontSize: "16px"
            },
            total: {
              show: false,
              label: "Total",
              formatter: function(w) {
                return w;
              }
            }
          }
        }
      },
      labels: this.dateList
    };
    this.pieChartOptions = {
      series: [],
      chart: {
        type: "pie"
      },
      legend: {
        position: "bottom"
      },
      labels: [],
      responsive: [
        {
          breakpoint: 4800,
          options: {
            chart: {
              height: '100%'
            },
            legend: {
              show: false,
              position: "bottom"
            }
          }
        }
      ]
    };
    this.donutChartOptions = {
      series: [],
      chart: {
        type: "donut"
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient"
      },
      legend: {
        position: "bottom",
        formatter: function(val, opts) {
          return this.sensorNames[opts.seriesIndex] || val;
        }
      },
      labels: [],
      responsive: [
        {
          breakpoint: 4800,
          options: {
            chart: {
              height: '100%'
            },
            legend: {
              show: false,
              position: "bottom"
            }
          }
        }
      ]
    };
    this.polarAreaChartOptions = {
      series: [],
      chart: {
        type: "polarArea"
      },
      fill: {
        opacity: 0.8
      },
      responsive: [
        {
          breakpoint: 4800,
          options: {
            chart: {
              height: '100%'
            },
            legend: {
              show: false,
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnInit() {
    this.randomId = Math.random();
    
    this.subscriptions.push(this.sensorService.getName.subscribe(res => {
      if(res) {
        this.seriesData = [];
        this.loadedData = [];
        this.getRealTimeData();
        // this.apiCalled = false;
      }
    }));
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
        if(this.widget.settings['graphType'] === 'pie' || this.widget.settings['graphType'] === 'donut' || this.widget.settings['graphType'] === 'polarArea') {
          this.pieChartOptions.responsive[0].options.legend.show = !this.pieChartOptions.responsive[0].options.legend.show;
          this.donutChartOptions.responsive[0].options.legend.show = !this.donutChartOptions.responsive[0].options.legend.show;
          this.polarAreaChartOptions.responsive[0].options.legend.show = !this.polarAreaChartOptions.responsive[0].options.legend.show;
        }
  }

  getTranslation(translation) {
    const t = this.translatedNames.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      return t[0][this.langString];
    } else {
        return translation
    }
}

  ngOnDestroy () {
    this.subscriptions.forEach(e => {
      e.unsubscribe();
    });
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  toggleLegend() {
    console.log(this.widget.settings['graphType']);
    if(this.widget.settings['graphType'] === 'pie' || this.widget.settings['graphType'] === 'donut' || this.widget.settings['graphType'] === 'polarArea') {
      this.pieChartOptions.responsive[0].options.legend.show = !this.pieChartOptions.responsive[0].options.legend.show;
      this.donutChartOptions.responsive[0].options.legend.show = !this.donutChartOptions.responsive[0].options.legend.show;
      this.polarAreaChartOptions.responsive[0].options.legend.show = !this.polarAreaChartOptions.responsive[0].options.legend.show;

      this.getRealTimeData();
    }
  }

  showMenu() {
    this.toggleSub = !this.toggleSub
    this.getRealTimeData();
    if(this.toggleSub == true) {
      this.radialChartOptions = {...this.radialChartOptions, ...{
        chart: {
          type: "radialBar",
          toolbar: {
            show: true,
            tools: {
              download: true,
            }
          },
        },
        legend: {
          show: false,
        }
      }};
    } else {
      this.radialChartOptions = {...this.radialChartOptions, ...{
        chart: {
          type: "radialBar",
          toolbar: {
            show: false,
            tools: {
              download: false,
            }
          },
        },
        legend: {
          show: false,
        }
      }};
    }
  }

  hideMain() {
    this.toggleMain = false
  }

  ngOnChanges() {
    this.getRealTimeData();
  }

  public getRealTimeData() {
    this.isLoading = true;
    this.loadedData = [];
    this.seriesData = [];
    console.log(this.widget);
    this.cssClass = this.widget.config['sizeClass'];
      setTimeout(()=> {
        const sensors: Array<SensorModel> = this.widget.config["settings"]["options"].map(
          (sensor: { sensor: SensorModel }) => sensor?.sensor
        );
        const sensorIds = sensors.map((sensor) => sensor.id);
        this.subscriptions.push(
          this.dataService
              .getLastData(sensorIds.toString())
              .subscribe((data) => {
                // console.log(data);
                if(data) {
                  this.isLoading = false;
                  const objects = Object.values(this.objects);
                  const unitTypes = Object.values(this.unitTypes);
                  const sensors = Object.values(this.sensors);
                  const devices = Object.values(this.devices);
                  this.widget.config['settings']['options'] =
                      this.widget.config['settings']['options'].map(
                          (item) => {
                              return {
                                  ...item,
                                  unitTypes: unitTypes.filter(
                                      (el) => el.id === item.sensor.unitType
                                  ),
                                  objects: objects.filter(
                                      (el) => el.id === item.sensor.object_id
                                  ),
                                  currentSensor: sensors.filter(
                                      (el) => el.id === item.sensor.id
                                  ),
                                  devices: devices.filter(
                                      (el) => el.id === item.sensor.device
                                  ),
                              };
                          }
                      );
                  this.sensorNames = [];
                    this.widget.config['settings']['options'].forEach(
                        (item) => {
                            const nameT = item.sensor.nameT
                                ? this.getTranslation(item.sensor.nameT)
                                : '';
                            let extraText = item.objects[0].nameT
                                ? this.getTranslation(item.objects[0].nameT)
                                : null;
                            if (!extraText) {
                                extraText = item.objects[0].extraText
                                    ? item.objects[0].extraText
                                    : '';
                            }
                            const unit = item.unitTypes[0].nameT
                                ? this.getTranslation(item.unitTypes[0].nameT)
                                : item.unitTypes[0].name;
                            if(item.devices && item.devices.length) {
                            const name = nameT ? nameT : extraText + ' ' + unit;
                            this.sensorNames.push(name);
                            } else {
                                const name = nameT ? nameT : extraText + ' ' + unit;
                                this.sensorNames.push(name);
                            }
                        }
                    );
                    // console.log(this.sensorNames);
                    this.currentSensors = this.widget.config['settings'][
                        'options'
                    ].map((item) => item.currentSensor);
                    // console.log(this.currentSensors);
                    this.units = this.widget.config['settings']['options'].map(
                        (item) => `${item.unitTypes[0].unit}`
                    );

                    this.columns = [...this.sensorNames];
                    
                    if (this.widget.settings && this.widget.settings['graphType']) {
                      const graphType = this.widget.settings['graphType'];
                    
                      const handleCommonOperations = (options, seriesData) => {
                        options.series = seriesData;
                    
                        if (graphType === 'radialBar') {
                          const total = seriesData.reduce((acc, currentValue) => acc + currentValue, 0);
                          options.plotOptions.radialBar.dataLabels.total.formatter = function (w) {
                            console.log(w);
                            return total.toString();
                          };
                    
                          const userTimezone = this.timezoneService.getUserTimezone(this.timezone);
                          const observables = data.map(item =>
                            this.utcToLocalPipe.transform(item.timestamp, userTimezone)
                              .pipe(map(transformedDate => this.timeAgoPipe.transform(transformedDate)))
                          );
                    
                          forkJoin(observables).subscribe(() => {
                            options.labels = this.sensorNames;
                          });
                        } else if (graphType === 'donut' || graphType === 'pie' || graphType === 'polarArea') {
                          options.labels = this.sensorNames;
                        }
                      };
                    
                      switch (graphType) {
                        case 'radialBar':
                          handleCommonOperations(this.radialChartOptions, data.map(item => item.value_float ? item.value_float : 0));
                          break;
                    
                        case 'donut':
                          handleCommonOperations(this.donutChartOptions, data.map(item => item.value_float ? item.value_float : 0));
                          break;
                    
                        case 'pie':
                          handleCommonOperations(this.pieChartOptions, data.map(item => item.value_float ? item.value_float : 0));
                          break;
                    
                        case 'polarArea':
                          handleCommonOperations(this.polarAreaChartOptions, data.map(item => item.value_float ? item.value_float : 0));
                          break;
                    
                        default:
                          // Handle other cases if needed
                          break;
                      }
                    }
                    
                }
              }))
        this.sensorService.setName(false);
        this.isLoading = false;
      }, 1000)
    // }
    // this.apiCalled = true;
  }

  public getLotName(lotId:number=this.widget.objectId):string{
    if(lotId != 0) {
      return this.globalRegistry.systemData.lots.find(lot=>lot.id===lotId).name;
    } else {
      return ''
    }
  }

}
