<form class="theme-form mb-3" [formGroup]="addressForm">
    <div class="form-group col-sm-6" style="display: none;">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.type' | translate }}</mat-label>
            <input required matInput formControlName="type">
            <mat-error *ngIf="ifControlHasError('type','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
    <div class="form-group col-sm-6">
        <!-- <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.country' | translate }}</mat-label>
            <input required matInput formControlName="country">
        </mat-form-field> -->

        <mat-form-field class="w-100">
            <mat-label>{{ 'party.address.country' | translate }}</mat-label>
            <mat-select #mysl (selectionChange)="selectCountry($event)" formControlName="country" name="country">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #countryFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let country of globalRegistry?.systemData?.countries | filter:countryFilter.value" [value]="country?.iso3">{{country?.en}}</mat-option>
            </div>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.county' | translate }}</mat-label>
            <input matInput formControlName="county">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.city' | translate }}</mat-label>
            <input required matInput formControlName="city">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.postalCode' | translate }}</mat-label>
            <input matInput formControlName="postalCode">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.street' | translate }}</mat-label>
            <input required matInput formControlName="street">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.number' | translate }}</mat-label>
            <input matInput formControlName="number">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.building' | translate }}</mat-label>
            <input matInput formControlName="building">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.appt' | translate }}</mat-label>
            <input matInput formControlName="appt">
        </mat-form-field>
    </div>
    <ng-container *ngIf="showExtraFields">
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'people.party.phone' | translate }}</mat-label>
            <input matInput formControlName="phoneMobile">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.address.phoneFix' | translate }}</mat-label>
            <input matInput formControlName="phoneFix">
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'people.email' | translate }}</mat-label>
            <input matInput formControlName="email">
            <!-- <mat-error *ngIf="ifControlHasError('email','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
            <mat-error *ngIf="ifControlHasError('email','pattern')">
                <strong>{{ 'auth.validation.invalidField' | translate }}</strong>
            </mat-error> -->
        </mat-form-field>
    </div>
    <div class="form-group col-sm-6">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'people.party.website' | translate }}</mat-label>
            <input matInput formControlName="website">
        </mat-form-field>
    </div>
</ng-container>
</div>
    <div class=" mt-3 text-center">
        <button class="btn btn-lg" type="submit" (click)="submit()">{{ 'save' | translate }}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>
