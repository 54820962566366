import { NoteModel } from '../../../../../core/models/notes/note.model';
import { Router } from '@angular/router';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NoteTypeModel } from '../../../../../core/models/type/note-type.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { NotesInterface } from '../../../../../core/interfaces/notes/notes.interface';
import { UtilsHelper } from '../../../../../core/heplers/utils.helper';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NotesService } from './../../../../../core/services/api/farm/notes.service';
import { TypesService } from './../../../../../core/services/api/types/types.service';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import * as moment from "moment";

import {
    Component,
    Input,
    SimpleChanges,
    Output,
    EventEmitter,
    ViewChild,
    OnInit,
    OnChanges,
    OnDestroy,
    ElementRef
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { FapModalComponent } from '../../../../../shared/partials';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { NavService } from '../../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { AttributeEntityEnum } from '../../../../../core/enums/attribute/attribute-entity.enum';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from 'src/environments/environment';
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';


export const DATE_TIME_FORMAT = {
    parse: {
      dateInput: 'l, LT',
    },
    display: {
      dateInput: 'l, LT',
      monthYearLabel: 'MM yyyy',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
}
@Component({
    selector: 'fap-edit-notes',
    templateUrl: './fap_notes-edit.component.html',
    styleUrls: ['./fap_notes-edit.component.scss'],
    providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class FapNotesEditComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public title: string;

    @Input()
    public note: NoteModel;

    @Input()
    public lots: Array < LotModel > ;

    @Input()
    public farms: Array < FarmModel > ;

    @Input()
    public types: Array < NoteTypeModel > ;

    @Input()
    public isPreviewMode = false;

    @Input()
    public noteId: number;

    @Output()
    public submitNote: EventEmitter < FormData > = new EventEmitter();

    @Output()
    public updateNote: EventEmitter < FormData > = new EventEmitter();
    
    @Output()
    public deleteNote: EventEmitter < any > = new EventEmitter();
    public isEdit = false
    public mediaUrl = environment.mediaUrl

    // @ViewChild('notesImageUpload')
    // public notesimageUpload: FapUploadImageComponent;

    @ViewChild('addNoteTypeModal')
    public addNoteTypeModal: FapModalComponent;
    @ViewChild('addImageModal')
    public addImageModal: FapModalComponent;
   
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    public notesForm: UntypedFormGroup;
    public notesTypeForm:UntypedFormGroup;
    public imageForm: UntypedFormGroup;
    
    public changedImage: boolean;
    public notesHasImage: boolean;
    public assignedTo: PersonModel;
    // public notesImage: File = null;
    public subscription: Subscription = new Subscription();
    public lotGroup:any = [];
    public initialNoteForm: NotesInterface;
    public initialNoteImageUrl: string = null;
    public entityRelation:AttributeEntityEnum = AttributeEntityEnum.Note;
    public enityRelations: AttributeEntityEnum.Note = AttributeEntityEnum.Note;
    public addNoteTypeModalButtonPrimaryInterface: FapModalButtonInterface;
    public addNoteTypeModalButtonSecondaryInterface: FapModalButtonInterface;
    public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
    public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
    public noteTypeId: any;
    public noteType: NoteTypeModel;
    public isPerson=false;
    public personList:any;
    public imgData:any=[];
    public untiltimeVal:any;
    public timestamptimeVal:any;
    public minDate = new Date();
    public notesCoords: any;
    public coordsString: string;
    public noteImages:any = [];
    public noteImagesId: any = [];
    public translatedNames:any = [];
    public langString: string;
    public currentNoteType: NoteTypeModel;
    public images =  [];
    public image: File;
    imageSrc: string;
    public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
    public imgWidth: number;
    public imgHeight: number;
    public orientation: string;
    public nextImgToken :{ offset: number; limit: number; group: string; } = null;
    public getMoreImg = true;
    @ViewChild('addEditNameTranslationsModal')
    public addEditNameTranslationsModal: FapModalComponent;
    @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
    public nameT = null;
    public localLots = [];
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    @ViewChild('fileUploader') fileUploader:ElementRef;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    public prefix = 'NOTES_';
    public translation = null;
    public entityInfo: any;
    public formType;
    public formFields;
    
    constructor(private modalService: NgbModal,
                public navService: NavService,
                public formBuilder: UntypedFormBuilder,
                public toastr: ToastrService,
                public router: Router,
                public translateService: TranslateService,
                public noteService: NotesService,
                public typesService: TypesService,
                public confirmModalService:ConfirmModalService,
                public companyService:CompanyService,
                public globalRegistryService: GlobalRegistryService,
                public cacheService: CacheResolverService,
                public mapService: MapService,
                public attributeService: AttributeService
                ) {
        this.initAddNoteTypeModalButtons();
        this.initImageModalButtons();
        this.personList = globalRegistryService.systemData.persons
    }

    changeLotFormat(lotIds) {
        // console.log(lotIds);
        // let uniq = [...new Set(lotIds)];
        const lots = [];
        lotIds.forEach(element => {
            if(typeof(element) === 'number') {
            const lot = this.lots.filter(l => {
                if(l.id === element) {
                    return l;
                }
            });
            lots.push(lot[0]);
        }});
        // console.log(lots);
        const uniqueLots = lots.filter(function(item, pos) {
            return lots.indexOf(item) == pos;
        })
        if(this.note) {
            this.note.lots = uniqueLots;
        }
        this.selectedItems = uniqueLots;
        return uniqueLots
    }

    get f() {
        return this.notesForm.controls;
      }

      public getFormTypes() {
        const url = this.typesService.getUrl('form_type/');
        this.cacheService.delete(url+'entity=note');
        const params = {entity: 'note'}
        this.typesService.getFormTypes(params).subscribe(data => {
          console.log(data);
          this.entityInfo = data.body.results[0];
        })
      }

    public initAddNoteTypeModalButtons(): void {
        const _this: FapNotesEditComponent = this;

        this.addNoteTypeModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.noteTypeSubmit();
            },
            text: this.translateService.instant('save')
        };

        this.addNoteTypeModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addNoteTypeModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
    }

    public initImageModalButtons(): void {
        const _this: FapNotesEditComponent = this;

        this.addImageModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.imageSubmit();
            },
            text: this.translateService.instant('save')
        };

        this.addImageModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addImageModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
    }

    public showNoteTypeModal(newCheck?:boolean): void {
        if(newCheck) {
            this.noteType = null
            this.noteTypeId = null;
            this.notesTypeForm.reset();
        }
        this.addNoteTypeModal.showModal();
    }

    public showImageModal(): void {
        this.addImageModal.showModal();
    }

    onFileInput(event) {
        if(event.target.files && event.target.files.length) {
          if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
            this.toastr.error(this.translateService.instant('image.notAnImage'))
            this.image = null;
            this.fileUploader.nativeElement.value = null;
            return
          }
          const [image] = event.target.files;
          this.image = image;
          const _URL = window.URL || window.webkitURL;
          let file, img, height, width;
          if ((file = event.target.files[0])) {
              img = new Image();
              const objectUrl = _URL.createObjectURL(file);
              img.onload = function () {
                  height = this.height;
                  width = this.width;
                  _URL.revokeObjectURL(objectUrl);
              };
              img.src = objectUrl;
          }
          setTimeout(()=> {
            if(width <= height) {
              this.orientation  = "portrait";
              console.log("portrait");
            } else {
                this.orientation  = "landscape";
                console.log("landscape");
            }
          }, 500);
        }
      }

    public imageUploaded(file: File): void {
        this.changedImage = true;
        this.image = file;
        console.log(this.image);
        this.imageForm.markAsDirty();
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistryService.systemData.translations;
        console.log(this.farms);
        console.log(this.lots);
        const result = this.lots.map((item) => {
            return {
                ...item,
                farms: this.farms.filter((el) => el.id === item.farm),
            };
        });
       
            const res = Array.from(
                result
                    .reduce((acc, promotion) => {
                        acc.set(
                            promotion.farm,
                            (acc.get(promotion.farm) || []).concat(promotion)
                        );
                        return acc;
                    }, new Map())
                    .entries(),
                ([farm, data]) => ({ farm, data })
            );
        this.lotGroup = res;
        console.log(this.lotGroup);
          this.notesTypeForm = new UntypedFormGroup({
            name: new UntypedFormControl('', Validators.required),
            name_t: new UntypedFormControl(''),
            color: new UntypedFormControl('#ff0000', Validators.required),
          });
          this.imageForm = new UntypedFormGroup({
            file: new UntypedFormControl('', Validators.required),
            group: new UntypedFormControl('notes', Validators.required),
            tags: new UntypedFormControl([], Validators.required),
            orientation: new UntypedFormControl('', Validators.required)
          });
          
          console.log(this.note);
        this.initForm();
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 3000,
            allowSearchFilter: true
          };
        this.noteService.notesCoords.subscribe(val => {
            if(val!='') {
                this.notesCoords = val;
                this.coordsString = val.lng+','+val.lat;
                console.log(this.coordsString);
            }
        });
        
        this.subscription.add(this.navService.emitSubmit.subscribe((value: boolean) => {
            value && this.submitForm();
            this.updateAttributes();
        }));

        this.subscription.add(this.navService.emitDelete.subscribe((value: boolean) => {
            value && this.deleteCurrentNote();
        }));
        this.getFormTypes()
    }

    public updateAttributes() {
        this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
            console.log(data);
        })
    }

    public getFarmName(farmid) {
        let farm;
        this.farms.forEach(element => {
            if(element.id == farmid) {
                farm = element
            }            
        });
        return farm.name;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'note') && this.note) {
            this.initForm();
        }
    }

    public onLoadImage(file): void {
        this.changedImage = true;
        // this.notesImage = file;
        this.notesHasImage = file ? true : false;
        this.notesForm.markAsDirty();
        this.imgData = file;
    }

    public deleteCurrentNote() {
        console.log(this.note.id);
        this.deleteNote.emit(this.note.id);
        setTimeout(()=> {
            this.router.navigate(['/pages/notes']);
        }, 1000);
    }

    public submitForm(): void {
        console.log(this.coordsString);
        if(this.coordsString) {
            const newValue = this.coordsString.replace(',', ' ');
            const newValue1 = `Point(${newValue})`;
            this.notesForm.get('coords').patchValue(newValue1);
        }
        
        console.log(this.notesForm.value);
        if (this.notesForm.get('timestamp').value == null) {
            this.toastr.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
            return;
        }
        const formData: FormData = new FormData();
        // if (this.changedImage) {
            // image must be handled differently
            // if(this.imgData && this.imgData.length){        
            //     let i = 0;
            //     let imagname;
            //     let imageid;  
            //     this.imgData.forEach(img => {
            //         imagname = 'images-' + i + '.file';
            //         imageid = 'images-' + i + '.id' 
            //         if(img.file){
            //             formData.append(imagname, img.file);
            //             if(img.id > 0){
            //                 formData.append(imageid, img.id);
            //             }
            //         } else {
            //             formData.append(imageid, img.id);
            //         }
            //         i=i+1;
            //     });    
            // } 
            // else {
            //     formData.append('images', this.notesImage);
            // }
        // }
        Object.keys(this.notesForm.controls).forEach((key: string): void => {
            const formKey: string = UtilsHelper.camelCaseToSnakeCaseString(key);
            // if (!this.notesForm.get(key).value) {
            //     return; // if the input was untouched, don't push it in the form
            // }
            if (key === 'timestamp' || key === 'until') {
                // let timeOption = key && key === 'timestamp' ? 'timestampTime' : 'untilTime';                
                // let timeconvert = this.notesForm.controls[timeOption].value;
                // let dateconvert = moment(this.notesForm.value[key]).format('ll');
                // let finaldate = dateconvert + ' ' +  timeconvert;
                // finaldate = moment(finaldate).format("lll");
                // finaldate = moment(finaldate).toISOString();
                if(typeof(this.notesForm.value[key]) === 'string') {
                    const dateTime =this.notesForm.value[key]?this.notesForm.value[key]:null;               
                    formData.append(formKey, dateTime);
                    return
                }
                const dateTime =this.notesForm.value[key]?this.notesForm.value[key].format():null;               
                formData.append(formKey, dateTime);
            } else {
                formData.append(formKey, this.notesForm.value[key] !== null ? this.notesForm.value[key] : '');
            }
        });
        if(this.notesForm.get('until').value == 'Invalid date') {
            this.notesForm.get('until').patchValue(null);
            console.log(this.notesForm.get('until').value);
        }
        
        this.note ? this.updateNote.emit(this.notesForm.value) : this.submitNote.emit(this.notesForm.value);
        setTimeout(()=> {
            this.router.navigate(['/pages/notes']);
        }, 1000);
    }

    public noteTypeSubmit(){
        if(this.notesTypeForm.invalid) {
            Object.keys(this.notesTypeForm.controls).forEach((controlName: string) =>
                this.notesTypeForm.controls[controlName].markAsTouched()
            );
            return;
        }
        if(this.noteTypeId){
            this.editNoteType(this.notesTypeForm.value, this.noteTypeId);
            this.addNoteTypeModal.hideModal();
            
        } else {
            this.addNoteType(this.notesTypeForm.value)
            this.addNoteTypeModal.hideModal();
            
        }
    }

    public reloadNoteTypes() {
        this.types = [];
        const url = this.typesService.getUrl('note_type/');
        this.cacheService.delete(url);
        this.typesService.getNoteTypes().subscribe((types:ResponseModel<NoteTypeModel[]>) => {
            this.types = types.model;
            console.log(this.types);
        })
    }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.notesTypeForm.controls['name_t'].setValue(translation.id);
        this.notesTypeForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    public getImages() {
        const url = this.companyService.getUrl('images/')
        this.cacheService.delete(url+'group=notes');
        this.companyService.getCompanyImages({group: 'notes'}).subscribe((response): void => {
            this.images = response.body["results"];
            console.log(this.images);
            this.nextImgToken = response.body.next
            ? this.globalRegistryService.getQueryStringParams(response.body.next.split("?")[1])
            : null;
            if(this.nextImgToken) this.getMoreImg = true
          })
        }
      
        onScroll(event: any) {
          if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            console.log("End");
            this.scrolledDownImages();
          }
        }
      
        scrolledDownImages() {
          const url = this.companyService.getUrl('images/');
          console.log('scrolled');
          if(this.getMoreImg) {
          this.nextImgToken &&
            this.subscription.add(this.companyService.getCompanyImages(this.nextImgToken).subscribe((data) => {
              this.cacheService.delete(url+'limit='+this.nextImgToken.limit+'&offset='+this.nextImgToken.offset+'&group=notes');
              console.log(data);
              if(this.images) {
                this.images = [...this.images, ...data.body.results];
              } else {
                this.images = data.body.results;
              }
              if(data.body.next == null) {
                this.getMoreImg = false;
                return
              } else {
                const url = data.body.next.split('?')
                const urlParams = new URLSearchParams(url[1]);
                const entries = urlParams.entries();
                const params = this.paramsToObject(entries);
                console.log(params);
                if(this.nextImgToken.offset != params['offset']) {
                this.nextImgToken = {limit: params['limit'], offset: params['offset'], group: 'notes'};
                } else {
                  return
                }
              }
            }));
          } else {
            return
          }
        }

        paramsToObject(entries) {
            const result = {}
            for(const [key, value] of entries) {
              result[key] = value;
            }
            console.log(result);
            return result;
          }
    
    public imageSubmit() {
        const data = {
            width: this.imgWidth,
            height: this.imgHeight,
            orientation: this.orientation
        }
        console.log(this.image);
        const formData = new FormData();
        formData.append("file", this.image);
        formData.append("group", this.imageForm.get('group').value);
        formData.append("tags", this.imageForm.get('tags').value);
        formData.append("data", JSON.stringify(data));
        
        this.companyService.postCompanyImages(formData).subscribe(data => {
            console.log(data);
            if(data.body.results.id) {
                this.getImages();
            }
        })
        this.addImageModal.hideModal();
    }

    public addNoteType(note: FormData): void {
            this.typesService.addNoteType(note).subscribe(() => {
                this.noteService.getNotesList.next();
                this.toastr.success(this.translateService.instant('notes.createNoteType'));
                this.reloadNoteTypes();
            });    
    }

    public editNoteType(note: FormData, noteTypeId: number): void {
                this.typesService.updateNoteType(note, noteTypeId).subscribe(() => {
                this.noteService.getNotesList.next();
                this.toastr.success(this.translateService.instant('notes.updateNoteType'));
                this.reloadNoteTypes();
            });    
    }

    public deleteNoteType(noteTypeId: number): void {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('notes.deleteNoteType'),
            this.translateService.instant('notes.deleteNoteTypeMessage'));
            this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
                if (confirmed) {
                    this.typesService.deleteNoteType(noteTypeId).subscribe(() => {
                        this.noteService.getNotesList.next();
                        this.toastr.success(this.translateService.instant('notes.deleteNoteTypeSuccessfully'));
                        this.reloadNoteTypes();
                    });                 
                }
            });   
    }

    public deleteImage(imageId: number): void {
        console.log(imageId);
        this.companyService.deleteCompanyImage(imageId).subscribe(data => {
            console.log(data);
            this.getImages();
        })
    }
    
    noteTypeEdit(e, note: NoteTypeModel){
        this.noteTypeId= note.id;
        this.noteType = note;
        console.log(this.noteType)
        e.stopPropagation();
        this.notesTypeForm.controls['name'].setValue(note.name);
        this.notesTypeForm.controls['name_t'].setValue(note.nameT);
        this.notesTypeForm.controls['color'].setValue(note.color);
        this.showNoteTypeModal();
    }

    noteTypeDelete(e, note){
        e.stopPropagation();
        this.deleteNoteType(note.id);           
    }

    imageDelete(e, image){
        e.stopPropagation();
        this.deleteImage(image.id);           
    }

    updateItems(lots) {
        this.notesForm.get('lots').setValue(lots);
    }

    public enableEdit(): void {
        this.isPreviewMode = false;
        this.notesForm.enable();
    }

    public resetForm(): void {
        this.notesForm.setValue(this.initialNoteForm);
        if (this.changedImage) {
            // this.notesimageUpload.remove();
            // this.notesimageUpload.url = this.initialNoteImageUrl;
            this.notesHasImage = this.initialNoteImageUrl ? true : false;
            this.changedImage = false;
        }
        this.notesForm.markAsPristine();
    }

    public getCoords(coords) {
        if(coords) { return coords.coordinates[0] + ', ' + coords.coordinates[1]}
    }

    private initForm(): void {
        const formBody: {} = {
            name: [this.note ? this.note.name : null, Validators.compose([Validators.required])],
            name_t: [this.note ? this.note.name : null, Validators.compose([Validators.required])],
            type: [this.note ? this.note.type.id : null, Validators.compose([Validators.required])],
            timestamp: [this.note ? moment(this.note.timestamp).format('') : moment(new Date()).format(''), Validators.compose([Validators.required])],
            until: [this.note ? moment(this.note.until).format('') : null],
            noAction: [this.note ? this.note.noAction : null],
            info: [this.note ? this.note.info : null],
            active: [this.note ? this.note.active : true],
            images: [this.note ? this.note.images : []],
            lots: [this.note && this.note.lots ? this.note.lots : null],
            assigned_to:[this.note && this.note.assignedTo ? this.note.assignedTo : null],
            timestampTime:[this.note ? moment(this.note.timestamp).format('LT') : null],
            untilTime:[this.note ? moment(this.note.until).format('LT') : null],
            coords:[this.note ? this.getCoords(this.note.coords) : null]
        };

        this.changedImage = false;
        if (this.note) {
            this.noteImages = this.note.images;
            this.checkImages(this.noteImages);
            console.log(this.note);
            this.assignedTo = Object.assign({firstName:this.note.assignedTo?.first_name},this.note.assignedTo);
            formBody['id'] = this.note.id;
            // this.notesimageUpload.url = this.note.images; // set preview
            // this.initialNoteImageUrl = this.note.images;
            this.notesHasImage = true;
            this.selectedItems = this.note.lots;
            this.imgData = this.note.images;  
            if(this.note.coords) {
                this.coordsString = this.note.coords.coordinates.toString();
                this.notesForm.get('coords').patchValue(this.coordsString);
                    const markerInterface = {
                      lat: this.note.coords.coordinates[1],
                      lng: this.note.coords.coordinates[0],
                      iconUrl: this.mapIconUrl
                    }
                    this.mapService.mapMarkers.push(markerInterface);
                    this.mapService.centerMapOnMarkers();
            }
            console.log(this.coordsString);
        }
        this.notesForm = this.formBuilder.group(formBody);
        this.initialNoteForm = this.notesForm.value;
        // in preview mode we should not be able to edit or submit the form
        this.isPreviewMode ? this.notesForm.disable() : this.notesForm.enable();
    }

    public checkImages(noteImages) {
        const imageIds = [];
        noteImages.forEach(imgId => {
          const bool = this.images.some(i => i.id == imgId)
          if(!bool) {
                imageIds.push(imgId)
            }
          });
        const unique = imageIds.filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        });
        unique.forEach(element => {
          this.getImage(element);
        });
      }

    public getImage(id) {
        if(id && id!=0) {
          this.companyService
            .getCompanyImage(id)
            .subscribe({
              next: (data) => {
                const bool = this.images.some(i => i.id == id)
                if(!bool) {
                  this.images.push(data.body.results)
                }
              },
              error: (err) => console.error(err)
            });
          }
      }

    closeModal(event:PersonModel){
        this.assignedTo = event;
        this.isPerson = false;
        this.notesForm.controls['assigned_to'].setValue(event.id ? event.id : null);
    }

    addImage(ev) {
        this.noteImages.push(ev.value.id);
        console.log(this.noteImages);
        this.notesForm.get('images').setValue(this.noteImages);
        console.log(this.notesForm.value);
    }

    deleteNoteImage(imgId) {
        this.noteImages = this.noteImages.filter((item) => item !== imgId);
        console.log(this.noteImages);
        this.notesForm.get('images').setValue(this.noteImages);
        console.log(this.notesForm.value);
    }

    hideModal() {
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.remove('active');
        parent.classList.remove('backdrop');
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            return t[0][this.langString];
          } else {
            return translation
          }
      }

      convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
          const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
          camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
        }
        return camelCaseObj;
      }

      onAddUpdateNameTranslation(translation) {
        console.log(translation);
        this.nameT = translation;
        this.notesForm.controls['name_t'].setValue(translation.id);
        this.notesForm.controls['name'].setValue(translation[this.langString]);
        this.addEditNameTranslationsModal.hideModal();
    }

      addEditNameTranslation() {
        const name = this.nameInput1.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.note);
        console.log(type);
        if(name === null || name === '' || type.nameT === null) {
            this.nameT = null
            this.addEditNameTranslationsModal.showModal();
        } else {
            this.globalRegistryService.systemData.translations.forEach(translation => {
                if(translation.id === type.nameT) {
                    this.nameT = translation;
                    console.log(this.nameT);
                    this.addEditNameTranslationsModal.showModal();
                }
            });
        }
        }

      addEditTranslation() {
        const name = this.nameInput.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.noteType);
        if(name === null || name === '' || type.nameT === null) {
            this.translation = null
            this.addEditTranslationsModal.showModal();
        } else {
            this.globalRegistryService.systemData.translations.forEach(translation => {
                if(translation.id === type.nameT) {
                    this.translation = translation;
                    console.log(this.translation);
                    this.addEditTranslationsModal.showModal();
                }
            });
        }
        }
}