import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    combineLatest,
    Subscription
} from 'rxjs';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import {
    ActivatedRoute,
    Params,
    Router
} from '@angular/router';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { FapStaticDataService } from '../../../../../core/static-data/fap-static-data.service';
import { NameValueInterface } from '../../../../../core/interfaces/general/name-value.interface';
import { MapPolylineSegmentInterface } from '../../../../../shared/layout/fap_main-map/data/map-polyline.interface';
import { MapPointInterface } from '../../../../../shared/layout/fap_main-map/data/map-point.interface';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { ForecastService } from '../../../../../core/services/api/forecast/forecast.service';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';

@Component({
    templateUrl: './equipments-container.component.html',
    styleUrls: ['./equipments-container.component.scss']
})
export class EquipmentsContainerComponent implements OnDestroy, OnInit {

    public farms: Array<FarmModel>;
    public equipments: Array<EquipmentModel>;

    public filteredFarms: Array<FarmModel> = [];
    public filteredEquipments: Array<EquipmentModel>;
    public forecasts: Array<WeatherModel> = [];

    public equipmentPoweredTypes: Array<NameValueInterface> = FapStaticDataService.equipmentPoweredTypesStaticData();
    private subscriptions: Array<Subscription> = [];

    public farmForecastMapping: { [key: number]: WeatherModel } = {};
    public currentUser;
    public bills;
   
    public getMore = true;

    constructor(public farmService: FarmService,
                public equipmentService: EquipmentService,
                public activatedRoute: ActivatedRoute,
                public toastr: ToastrService,
                public forecastService: ForecastService,
                public translateService: TranslateService,
                public globalRegistry: GlobalRegistryService,
                public router: Router,
                public mapService: MapService,
                public userService: UserService,
                public cacheService: CacheResolverService
                ) {

                    this.subscriptions.push(
                        this.userService.getCurrentUser.subscribe((data) => {
                            if (Object.keys(data).length != 0) {
                                this.currentUser = data;
                                const path = this.router.routerState.snapshot.url;
                                this.userService.getMenus.subscribe((menus) => {
                                    if (Object.keys(menus).length != 0) {
                                        const pathExist = menus.some(
                                            (menu) => menu.path === path
                                        );
                                        if (pathExist === false) {
                                            this.router.navigate(['pages/posts']);
                                        }
                                    }
                                });
                            }
                        })
                    );

        const localQueryParams = localStorage.getItem("queryParams")?JSON.parse(localStorage.getItem("queryParams")):{};
        this.router.navigate([],{
            relativeTo:this.activatedRoute,
            queryParams: localQueryParams
        });
        this.subscriptions.push(combineLatest([activatedRoute.queryParams, this.farmService.getFarms()])
            .subscribe(([queryParams, farms]: [
                Params,
                ResponseModel<FarmModel[]>,
                
            ]): void => {
                if (!this.forecasts || this.forecasts.length === 0) {
                    // this.getForecasts();
                }
                this.farms = farms.model;
                if (queryParams['farms'] !== undefined) {
                    // we have farms in query params, but need to check if we have an array of farms
                    const filteredFarmIds: Array<number> = Array.isArray(queryParams['farms'])
                        ? queryParams['farms'].map((farmId: string): number => Number(farmId))
                        : [Number(queryParams['farms'])];
                    this.filteredFarms = this.farms.filter((farm: FarmModel): boolean => {
                        return filteredFarmIds.includes(farm.id);
                    });
                }
                else {
                    this.filteredFarms = this.farms;
                }
               
            }));
    }

    paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.mapService.isEditMode = false;
        this.mapService.resetMap();
    }

    public ngOnInit(): void {
        const url = this.equipmentService.getUrl('equipment/');
        this.cacheService.delete(url+'limit=1000')
        this.equipmentService.getEquipments({limit: 1000}).subscribe(data => {
            console.log(data.model);
            this.filteredEquipments = data.model;
        })
    }

    private initForecastMapping(): void {
        if(this.forecasts) {
            this.forecasts.forEach((forecast: WeatherModel): void => {
                this.farmForecastMapping[forecast.objectId] = forecast;
            });
        }
    }

    private getForecasts(): void {
        this.forecastService.getForecast({ content_type: 'farm' })
            .pipe(take(1))
            .subscribe((forecasts: ResponseModel<WeatherModel[]>): void => {
                this.forecasts = forecasts.model;
                this.initForecastMapping();
            });
    }

    public deleteEquipment(equipmentId: number): void {
        this.equipmentService.deleteEquipment(equipmentId).pipe(take(1)).subscribe((): void => {
            this.toastr.success(this.translateService.instant('equipment.equipmentDeletedSuccessfully'));
            this.loadEquipments();
        });
    }

    private loadEquipments(): void {
        this.equipmentService.getEquipments().pipe(take(1)).subscribe((equipments: ResponseModel<EquipmentModel[]>): void => {
            this.equipments = equipments.model;
            this.filteredEquipments = this.equipments.filter((equipment: EquipmentModel): boolean => {
                return this.filteredFarms.some((farm: FarmModel): boolean => farm.id === equipment.farm);
            });
            this.initMap();
        });
    }

    private initMap(): void {
        this.mapService.showMap();
        this.mapService.isEditMode = false;
        this.mapService.resetMap();
        this.filteredFarms.forEach((farm: FarmModel): void => {
            this.mapService.mapPolygons.push({
                points: MapHelper.convertToAGMPolygon(farm.coords.coordinates[0]),
                strokeColor: '#a31f1f',
                fillColor: '#de3333'
            });
        });
        this.filteredEquipments.forEach((equipment: EquipmentModel): void => {
            this.mapService.mapPolygons.push({
                points: MapHelper.convertToAGMPolygon(equipment.garageArea.coordinates[0]),
                strokeColor: '#42f5dd',
                fillColor: '#42f5dd'
            });
            // this.mapService.mapPolylines.push(this.getTrackingForEquipment(equipment));
            this.mapService.mapMarkers.push({
                lat: equipment.garageArea.coordinates[0][0][0],
                lng: equipment.garageArea.coordinates[0][0][1],
                iconUrl: this.mapService.setMarkerIconColor('red'),
                infoWindowDetails: [{
                    img: null,
                    text: equipment.product? equipment.product.name : null
                }]
            });
        });
        this.mapService.centerMapOnPolygons();
    }

    // only dummy data
    private getTrackingForEquipment(equipment: EquipmentModel): MapPolylineSegmentInterface {
        const startingPointLat: number = equipment.garageArea.coordinates[0][0][0];
        const startingPointLng: number = equipment.garageArea.coordinates[0][0][1];
        const deltaCoords: Array<MapPointInterface> = [
            { lat: 0, lng: 0 },
            { lat: 0.0020055303803943048, lng: 0.0048065185546875 },
            { lat: 0.0032177936212391955, lng: 0.007853507995605469 },
            { lat: 0.005004337242702661, lng: 0.006077885627746582 },
            { lat: 0.00660095370864866, lng: 0.004374682903289795 },
            { lat: 0.005625755719876224, lng: 0.001812167465686798 },
            { lat: 0.0063397353707088655, lng: 0.0011500809341669083 },
            { lat: 0.0069938457644411756, lng: 0.0006596557796001434 },
            { lat: 0.00779977439405144, lng: 0.002474379725754261 },
            { lat: 0.008545832925847208, lng: 0.001800013706088066 },
            { lat: 0.00782317153517198, lng: 0.00002212822437286377 },
            { lat: 0.007369314933775684, lng: -0.0008113682270050049 },
            { lat: 0.005084777127244422, lng: 0.0011265277862548828 },
            { lat: 0.003650872016635276, lng: 0.0025776028633117676 },
            { lat: 0.0022767952769839894, lng: 0.00359952449798584 },
            { lat: 0.0007979178807744347, lng: 0.00011533498764038086 },
            { lat: 0.0014608919616989624, lng: -0.0007020682096481323 },
            { lat: 0.0028123057249942462, lng: 0.0025574862957000732 },
            { lat: 0.003400425903301141, lng: 0.001976117491722107 },
            { lat: 0.0021781549777628584, lng: -0.0008684908971190453 },
            { lat: 0.00290148876133145, lng: -0.0014385860413312912 },
            { lat: 0.004108675718548227, lng: -0.002578776329755783 },
            { lat: 0.0037693132275080643, lng: -0.003657527267932892 },
            { lat: 0.002432070451526158, lng: -0.002295970916748047 }
        ];
        return {
            points: deltaCoords.map((point: MapPointInterface): MapPointInterface => ({
                lat: point.lat + startingPointLat,
                lng: point.lng + startingPointLng
            })),
            color: '#ff0000'
        };
    }
}
