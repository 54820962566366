<div class="dashboard-card dash_cont" *ngIf="widget">
  <div class="row" style="position: relative; max-height: 100%;">
    <div class="col-md-4 col-xs-4">
      <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
    </div>
    <div class="col-md-6 col-xs-6 col_lot_name">
      <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
    </div>
    <div class="col-md-2 col-xs-2 text-right">
      <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
      <span class="conf_icon" (click)="toggleMenu()">
        <i></i>
        <i></i>
        <i></i>
      </span>
      <div class="new_menu {{toggleMain}}">
        <ul (click)="hideMain()">
          <li (click)="exportTable()">{{"widget.exportTable" | translate}}</li>
          <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
          <li (click)="tglValues()">{{"widget.minMax" | translate}}</li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="columns.length == 0">
    <h3 style="text-align: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
      <span class="loader">
        <i></i>
      </span>
  </h3>
  </div>
  <div class="scroll_wrap" style="max-height: calc(100% - 40px); overflow: auto;">
 
  <table id="ExampleMaterialTable{{randomId}}" class="w-100 toggleTable {{tglShow}}">
    <thead>
      <tr class="toggle_head">
        <th *ngFor="let item of columns" [attr.colspan]="item.colspan" [innerHTML]="item.name">
        </th>
      </tr>
      <tr class="rws" [ngClass]="bgColor">
        <td *ngFor="let item of tableRows" [ngClass]="bgColor">{{item}}</td>
      </tr>
    </thead>
    <tbody id="table-body{{randomId}}">
    </tbody>
  </table>

  <div class="no_data" *ngIf="!isLoading && noData">
    {{'widget.noDataAvailable' | translate}}
</div>
</div>
</div>
