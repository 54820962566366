import { FapModalComponent } from '../fap-modal/fap-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { FapModalButtonInterface } from '../fap-modal/data/fap-modal-button.interface';
import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormControl, Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { NavService } from '../../../services/nav.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef((): any => FapAddEditDeleteComponent),
            multi: true
        }
    ],
    selector: 'fap-add-edit-delete-select',
    templateUrl: './fap_add-edit-delete-select.component.html',
    styleUrls: ['./fap_add-edit-delete-select.component.scss']
})
export class FapAddEditDeleteComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {

    @Input()
    public inputName = '';

    @Input()
    public inputSelectValues: Array<any> = [];

    @Input()
    public inputNameFieldName = 'name';

    @Input()
    public inputNameTFieldName = 'name_t';

    @Input()
    public inputValueFieldName = 'id';

    @Input()
    public inputReadOnlyFieldName = 'isDefault';

    @Input()
    public inputProtectedFieldName = 'isUsed';

    @Input()
    public inputConfirmDeleteText = 'Are you sure?';

    @Input()
    public inputSearchable = true;

    @Input()
    public inputIsRequired = false;

    @Input()
    public previewMode = false;

    @Output()
    public outputOnDelete: EventEmitter<any> = new EventEmitter();

    @Output()
    public outputOnEdit: EventEmitter<any> = new EventEmitter();

    @Output()
    public outputOnCreate: EventEmitter<any> = new EventEmitter();

    @Output()
    public outputOnScroll: EventEmitter<any> = new EventEmitter();

    @Input()
    public apiSearchEnabled = false;

    @Output()
    public outputOnSearch: EventEmitter<any> = new EventEmitter();

    @Input()
    public addExtraHeight = false;

    @ViewChild('deleteValueModal')
    public deleteValueModal: FapModalComponent;

    @ViewChild('aeds')
    public aeds;

    @ViewChild('fapaeds')
    public fapaeds: ElementRef;

    public deleteModalPrimaryButtonInterface: FapModalButtonInterface;
    public deleteModalSecondaryButtonInterface: FapModalButtonInterface;

    public clickedValue: any = null;
    public selectFilterValue: any;
    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();

    public selectFormGroup: UntypedFormGroup = new UntypedFormGroup({
        value: new UntypedFormControl()
    });

    constructor(private translateService: TranslateService,
                private formBuilder: UntypedFormBuilder,
                private navService:NavService,
                public globalRegistry: GlobalRegistryService) {
        this.selectFormGroup = this.formBuilder.group({
            value: (this.inputIsRequired ? [null, Validators.required] : [null])
        });
        this.selectFormGroup.controls['value'].valueChanges.subscribe((): void => {
            this.propagateChange(this.selectFormGroup.controls['value'].value);
        });
        this.initModalButtons();
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
    }

    public ngOnChanges(changes:SimpleChanges){
        changes['previewMode']?.currentValue
            ?this.selectFormGroup.disable()
            :this.selectFormGroup.enable(); 
    }

    public writeValue(value: any): void {
        if (value !== this.selectFormGroup.controls['value'].value) {
            this.selectFormGroup.controls['value'].setValue(value);
        }
    }

    public searchKeyword(keyword) {
        console.log(keyword);
        this.outputOnSearch.emit(keyword);
    }

    public propagateChange = (_: any): void => {
        console.log(_);
     }; 

    public registerOnChange(fn: () => any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {
        console.log("registered on touch")
     }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            return t[0][this.langString];
        } else {
            return translation
          }
      }

    private initModalButtons(): void {
        this.deleteModalPrimaryButtonInterface = {
            clickFunction: (): void => {
                this.deleteValueModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
        this.deleteModalSecondaryButtonInterface = {
            clickFunction: (): void => {
                this.deleteValueModal.hideModal();
                this.outputOnDelete.emit(this.clickedValue);
            },
            text: this.translateService.instant('delete')
        };
    }

    public onScroll(event: any): void {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.scrolledDown();
          }
    }

    public scrolledDown() {
        this.outputOnScroll.emit(true);
    }

    ngOnDestroy(): void {
        this.fapaeds.nativeElement.style.maxHeight = '200px'
    }
}
