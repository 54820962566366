import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { environment } from '../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { H2oService } from '../../../../../core/services/api/h2o/h2o.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
    selector: 'fap-classical-form',
    templateUrl: './fap-classical-form.component.html',
    styleUrls: ['./fap-classical-form.component.scss'],
})
export class FapClassicalFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() objectId: number;
    @Input() entityId: number;
    @Input() formType: any;
    @Input() entityInfo: any;
    @Input() type = 'add';
    @Input() typeOfForm = '';
    @Input() formDisplayType = 'modal';
    mediaUrl = environment.mediaUrl;
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() hideModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() formUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitPreviousFormClick: EventEmitter<any> = new EventEmitter<any>();

    @Output() nextButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() prevButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formFieldsEmit: EventEmitter<any> = new EventEmitter<any>();
    
    resetForm = false;
    public formFields = [];
    @Input() previousFormClick = false;
    @Input() nextFormClick = false;
    public langString;
    public subscriptions: Array<Subscription> = [];

    @Input()
    public entityRelation:string;

    @Input() workflow: any;

    constructor(public attributeService: AttributeService, public h2oService: H2oService, public toastr: ToastrService, public navService: NavService) {
    }

    ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
    }

    public get width() {
        return window.innerWidth;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'entityInfo') && this.entityInfo) {
            console.log(this.entityInfo);
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'nextFormClick') && this.nextFormClick) {
            console.log('next clicked');
            this.updateFields();
            this.nextFormClick = false
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'previousFormClick') && this.previousFormClick) {
            console.log('previous clicked');
            this.formFields = [];
        }
        console.log(changes);
    }

    editValue(id, event) {
        console.log(event);
        this.formType.fields.forEach((field) => {
            if (id === field.id) {
                field.value.input = event.value;
                this.appendField(id, event.value);
            }
        });
    }

    appendField(id, value) {
        const formField = {
          id,
          value
        };
        const existingFieldIndex = this.formFields.findIndex(obj => obj.id === id);
        
        if (existingFieldIndex !== -1) {
          this.formFields[existingFieldIndex].value = value;
        } else {
          this.formFields.push(formField);
        }
        this.formFieldsEmit.emit(this.formFields);
        console.log(this.formFields);
      }
      

    updateFields() {      
            this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
                console.log(data);
                this.closeModal.emit();
                this.formUpdate.emit(data.body.results);
                // this.resetForm = true;
                if(this.entityRelation === 'workflow' || this.entityRelation === 'workflow_modal') {
                    this.nextButtonClick.emit(true);
                }
            }, err => {
                this.toastr.error(err.error.results.error)
            })

        //     if(this.type == 'edit') {
        //         console.log('add edit method')
        // } else {
        // const body = {
        //     form: this.formType.id
        // }
        // this.h2oService.createPool(body).subscribe(data => {
        //     console.log(data);
        //     if(data) {
        //         this.closeModal.emit();
        //         this.formUpdate.emit(data.body.results);
        //         this.resetForm = true;
        //     }
        // }, err => {
        //     this.toastr.error(err.error.results.error)
        // })
        // }
    }

    checkPrevButtonClick() {
        console.log('prev button clicked')
    }

    ngOnDestroy() {
        this.subscriptions.forEach(element => {
            element.unsubscribe();
        });
    }
}
