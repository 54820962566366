<form class="" [formGroup]="stockIncomeForm">
    <div class="required">
        {{ 'stock.requiredFields' | translate }}
    </div>
    <div class="grid">
        <div class="form-group">
            <fap-unit-value class="w-100"
                [fieldName]="'stock.quantity' | translate"
                [(ngModel)]="quantity"
                [ngModelOptions]="{standalone: true}">
            </fap-unit-value>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label><i class="fa fa-file"></i>{{ 'stock.info' | translate }}</mat-label>
                <textarea matInput formControlName="info"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize">
                </textarea>
                <mat-error *ngIf="ifControlHasError('info','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>

        </div>
        <div class="form-group" *ngIf="resourceTypes">
            <mat-form-field class="w-100">
                <mat-label><i class="fa fa-home"></i>{{ 'stock.type' | translate }}</mat-label>
                <mat-select class="select" required formControlName="type">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #resourceFilter placeholder="Search">
                        </mat-form-field>
                        <em class="fa fa-plus center f-20 ml-2" (click)="selectResource()"></em>
                    </div>
                    <mat-optgroup *ngFor="let parent of resourceTypes | filter : resourceFilter.value">
                            <div class="type-name">
                                {{ parent.nameT ? getTranslation(parent.nameT) : parent.name }}
                            </div>
                            <div class="d-flex text-center">
                                <i class="fa fa-edit align-self-center f-20" (click)="selectResource(parent);$event.stopPropagation()"></i>
                                <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                            </div>
                            <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let child of parent.children" [value]="child.id">
                            <div class="type-name">
                                {{ child.nameT ? getTranslation(child.nameT) : child.name }}
                            </div>
                            <div class="d-flex text-center">
                                <i class="fa fa-edit align-self-center f-20" (click)="selectResource(child, parent);$event.stopPropagation()"></i>
                                <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('farm','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
            
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-group"></i>{{ 'stock.partyFrom' | translate }}</mat-label>
                <mat-select required formControlName="partyFrom">
                    <mat-form-field appearance="fill" class="w-100">
                        <!-- <mat-label>
                            <i class="fa fa-search f-20"></i>
                        </mat-label> -->
                        <input matInput #partyFromFilter placeholder="Search">
                    </mat-form-field>
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option *ngFor="let party of parties | filter : partyFromFilter.value" [value]="party.id">
                        {{ party.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('partyFrom','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-usd"></i>{{ 'stock.cost' | translate }}</mat-label>
                <input required type="number" matInput formControlName="cost">
                <mat-error *ngIf="ifControlHasError('cost','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-checkbox formControlName="internalTransaction">{{ 'stock.internalTransaction' | translate }}</mat-checkbox>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-group"></i>{{ 'stock.partyTo' | translate }}</mat-label>
                <mat-select required formControlName="partyTo">
                    <mat-form-field appearance="fill" class="w-100">
                        <!-- <mat-label>
                            <i class="fa fa-search f-20"></i>
                        </mat-label> -->
                        <input matInput #partyToFilter placeholder="Search">
                    </mat-form-field>
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option *ngFor="let party of parties | filter : partyToFilter.value" [value]="party.id">
                        {{ party.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('partyTo','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group" *ngIf="farms">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-home"></i>{{ 'stock.farm' | translate }}</mat-label>
                <mat-select required formControlName="farm">
                    <mat-form-field appearance="fill" class="w-100">
                        <!-- <mat-label>
                            <i class="fa fa-search f-20"></i>
                        </mat-label> -->
                        <input matInput #farmFilter placeholder="Search">
                    </mat-form-field>
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option *ngFor="let farm of farms | filter : farmFilter.value" [value]="farm.id">
                        {{ farm.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="ifControlHasError('farm','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-dot-circle-o"></i>{{ 'stock.receiver' | translate }}</mat-label>
                <input matInput formControlName="receiver">
                <mat-error *ngIf="ifControlHasError('receiver','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-thermometer"></i>{{ 'stock.storageTemperature' | translate }}</mat-label>
                <input type="number" matInput formControlName="storageTemperature">
                <mat-error *ngIf="ifControlHasError('storageTemperature','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100" (click)="receiveDatePicker.open()">
                <mat-label><i class="fa fa-calendar"></i>{{ 'stock.receiveDate' | translate }}</mat-label>
                <input matInput readonly formControlName="receiveDate" [matDatepicker]="receiveDatePicker">
                <mat-datepicker-toggle matSuffix [for]="receiveDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #receiveDatePicker></mat-datepicker>
                <mat-error *ngIf="ifControlHasError('receiveDate','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-tint"></i>{{ 'stock.storageMinimumHumidity' | translate }}</mat-label>
                <input type="number" matInput formControlName="storageMinimumHumidity">
                <mat-error *ngIf="ifControlHasError('storageMinimumHumidity','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100" (click)="expirationDatePicker.open()">
                <mat-label><i class="fa fa-calendar"></i>{{ 'stock.expirationDate' | translate }}</mat-label>
                <input matInput readonly formControlName="expirationDate" [matDatepicker]="expirationDatePicker">
                <mat-datepicker-toggle matSuffix [for]="expirationDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #expirationDatePicker></mat-datepicker>
                <mat-error *ngIf="ifControlHasError('expirationDate','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label"><i class="fa fa-tint"></i>{{ 'stock.storageMaximumHumidity' | translate }}</mat-label>
                <input type="number" matInput formControlName="storageMaximumHumidity">
                <mat-error *ngIf="ifControlHasError('storageMaximumHumidity','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="col-md-12 mt-3 text-center">
        <button class="btn btn-success" type="submit" (click)="submit()">{{ 'save' | translate }}
            <i class="material-icons pl-1">send</i>
        </button>
    </div>
</form>

<fap-modal #addResourceTypeModal inputTitle="Add/Edit Resource Type">
    <fap-add-edit-resource-type
        [resourceTypes]="resourceTypes"
        [selectedResource]="selectedResource"
        [parentResource]="parentResource"
        (resourceTypeActionCompleted)="onResourceTypeAction($event)">
    </fap-add-edit-resource-type>
</fap-modal>
