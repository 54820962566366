import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';

@Component({
  selector: 'workflows-layout',
  templateUrl: './workflows-layout.component.html',
  styleUrls: ['./workflows-layout.component.scss']
})
export class WorkflowsLayoutComponent implements OnInit {
  @Input() workflows:any[] = [];
  public subscriptions: Array<Subscription> = [];
  public workflow: any;
  public formTypeId: number;
  public entityInfo: any;
  public objectId: number;
  public currentStep = 0;
  public totalSteps = 0
  public nextFormClick = false;
  public previousFormClick = false;
  public workflowList = [];
  public selectedType: any;

  constructor(private typesService: TypesService, private attributeService: AttributeService, private toastrService: ToastrService, public translate: TranslateService, private activatedRoute: ActivatedRoute, private router: Router, public cacheService: CacheResolverService) {
    this.getWorkflowList();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(data => {
      console.log(data);
      if(data && data['edit']) {
        this.selectedType = this.workflows.find(item => item.id === +data['edit']);
        console.log(this.selectedType);
        this.filterWorkflowList(+data['edit']);
      }})
      )
      this.getWorkflowTypeId();
  }

  public getWorkflowList() {
    let url = this.attributeService.getUrl('workflow/');
    this.attributeService.getWorkflow().subscribe(data => {
      this.workflowList = data.body.results;
      console.log(this.workflowList);
    })
  }

  public getWorkflowTypeId() {
    this.typesService.getHeaderWorkflowTypeId.subscribe(data => {
      console.log(data);
      if(data === 0) {
        return
      } else {
        this.getWorkflow(data);
      }
    })
}

  public filterWorkflowList(id) {
    let url = this.attributeService.getUrl('workflow/type='+id);
    this.cacheService.delete(url);
    this.attributeService.getWorkflow({type:id}).subscribe(data => {
      this.workflowList = data.body.results;
      console.log(this.workflowList);
    })
    // this.workflowList = this.workflowList.filter(workflow => workflow.type === id);
    console.log(this.workflowList);
  }

  public getFormTypes() {
    this.typesService.getFormTypeById(this.formTypeId).subscribe((data) => {
      console.log(data);
        this.entityInfo = data.body.results;
    });
}

public getWorkflow(id) {
  const param = {
    type : id
  }
  this.attributeService.postWorkflow(param).subscribe(data => {
    this.workflow = data.body.results;
    this.objectId = this.workflow.object_id;
    const elem = document.getElementById('workflow_modal');
            const parent = document.getElementById('parent99');
            elem.classList.add('active');
            parent.classList.add('backdrop');
    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    this.totalSteps = this.workflow.steps_count;
    if(this.currentStep > this.totalSteps) {
      this.currentStep = 0;
    }
  })
}

nextButtonClick(value) {
  if(value) {
    this.goToNextForm();
  }
}

prevButtonClick() {
  this.goToPreviousForm();
}

hideModal() {
  this.closeModal();
  console.log('hide modal triggered');
  this.filterWorkflowList(this.selectedType.id);
  // this.router.navigate(['pages/workflows']);
}

onUpdateAction() {
  console.log('update action triggered');
}

closeModal() {
  const elem = document.getElementById('workflow_modal');
  const parent = document.getElementById('parent99');
  elem.classList.remove('active');
  parent.classList.remove('backdrop');
}

goToPreviousForm() {
  this.attributeService.getPreviousForm(this.workflow.id).subscribe(data => {
    this.workflow = data.body.results;
    this.objectId = this.workflow.object_id;
    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    this.previousFormClick = false;
  })
}

goToNextForm() {
  this.attributeService.getNextForm(this.workflow.id).subscribe(data => {
    this.workflow = data.body.results;
    this.objectId = this.workflow.object_id;
    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    if(this.currentStep > this.totalSteps) {
      this.toastrService.success(this.translate.instant('workflow.workflowUpdatedSuccessfully'));
      this.currentStep = 0
    }
    this.nextFormClick = false;
  })
}
}
