<div class="container-fluid devices_page" id="parent">
    <div class="loader_container">
        <span *ngIf="isLoading" class="loader">
            <i></i>
          </span>
    </div>
    <div class="devices_table" *ngIf="!isLoading" #deviceContainer
    infiniteScroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="500"
    (scrolled)="scrolledDownDeviceData()"
    [scrollWindow]="false"
    (scroll)="onDivScroll($event)"
    style="flex: auto;">
        <table *ngIf="deviceColumns && deviceColumns.length">
            <thead>
                <tr>
                    <th *ngFor="let head of tableHeaderLabels" [innerHTML]="head.name"></th>
                    <th *ngFor="let head of deviceColumns" resizable>{{ head?.name ? head?.name : '' }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of deviceData">
                    <td>
                        <div class="boxx" [routerLink]="['edit/', data.id]">
                            <div class="img">
                                <ng-container *ngIf="data?.product">
                                    <img *ngIf="data?.product.images.length" [src]="mediaUrl + data?.product.images[data?.product.images.length - 1]?.file" alt="">
                                    <img *ngIf="data?.product.images.length === 0" src="../../../../../../assets/images/image_placeholder.png" alt="">
                                </ng-container>
                                <ng-container *ngIf="!data?.product">
                                    <img src="../../../../../../assets/images/image_placeholder.png" alt="" />
                                </ng-container>
                                <!-- <img src="../../../../../../assets/images/landscape_placeholder.png" alt="" class="icon" *ngIf="!data?.product"> -->
                            </div>
                            <div class="text">
                                <b [innerHTML]="data?.name ? data?.name : data?.product ? data?.product?.name : '<i>No name</i>'"></b>
                                <div [routerLink]="['view/', data.id]" class="link">{{ data?.id }}</div>
                                <span class="lot_names">{{ [globalRegistry.systemData.lots, data?.lots] | getLotNames }}</span>
                            </div>
                        </div>
                    </td>
                    <td>{{ data?.last_transmission ? (data?.last_transmission | timeAgo) : '-' }}</td>
                    <td *ngFor="let sensor of data?.sensors; let i = index;" [ngStyle]="getCellStyles(sensor)">
                        <div *ngIf="sensor" [innerHTML]="getExtraFieldsById(sensor, deviceColumns[i]?.sensor_ref?.unit_type?.unit, data?.id)" (click)="handleButtonClick(data?.id, sensor)">
                            {{ sensor?.id }}
                        </div>
                        <div *ngIf="!sensor?.id">
                            -
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="custom_modal" id="device_modal">
        <div class="modal_header">
            <h2>{{"devices.addNewDevice" | translate }}</h2>
            <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
        </div>
        <div class="modal_body">
        <form [formGroup]="deviceForm">
            <div class="form-group">
                <div class="row align-items-baseline">
                    <div class="col-12">
                        <div class="input-group w-100">
                            <div class="col-6" (click)="getResourceEntries()">
                                <mat-form-field>
                                    <mat-label>{{"stock.entry" | translate}}</mat-label>
                                    <mat-select formControlName="resource_entry" name="resource_entry" required (selectionChange)="setEntry($event)" (infiniteScroll)="scrolledDown()" msInfiniteScroll>
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #typeFilter4 placeholder="Search">
                                        </mat-form-field>
                                    </div>
                                    <div class="scroll_wrap" (scroll)="onScroll($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option class="noteIcons" *ngFor="let entry of resourceEntries | filter : typeFilter4.value" [value]="entry.id">
                                            <span class="mat-option-text">
                                                <div class="name clearfix">
                                                    <div class="nme">
                                                          {{ entry?.product?.name }}
                                                    </div> 
                                                    <div class="amt">
                                                        <div class="quty">
                                                            {{ entry?.remains_quantity | number: '1.0-2'}}
                                                        </div> 
                                                        <div class="unt">
                                                            <div class="inlin">
                                                               <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.units}}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                    <mat-error *ngIf="deviceForm.controls['resource_entry'].errors?.required">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                        </div>
                        <div class="col-6 d-flex nn posrltv custom_border">
                            <div class="colq">
                                <div class="input-group w-100">
                                    <mat-form-field>
                                        <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                        <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="colu" style="padding: 0;
                            
                            width: 90px;
                            
                            margin-left: -5px;" *ngIf="entry && entry.unit">
                                <div class="inlin">
                                    <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.units}}</b>
                                </div>
        
                            </div>
                            <div class="abs error" *ngIf="entry && !entry.unit">
                                <span>{{'stock.specifyProductUnit' | translate}}</span>
                            </div>
                        </div>
                            
                            </div>
                            <div class="col-12">
                                <chips 
                                    [items]="globalRegistry.systemData.lots" 
                                    [selectedItems]="selectedItems"
                                    [label]="'activities.lots' | translate"
                                    (updatedItems)="updateItems($event)"
                                    [addEnabled]="false"
                                >
                                </chips>
                            </div>
                            <div class="col mt-2">
                                <button class="btn" (click)="addDevice()">{{ "translations.save" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    </div>
    </div>
</div>

<fap-modal
    [inputIsLarge]="true"
    #lotsModal
    [titleColor]="'#ffffff'"
    inputTitle="Lots"
>
    <div class="pl-1 mt-3 mt-md-0 c-eye">
        <div class="crop-eye-bg h-100">
            <div class="row border-green-a mb-2 mx-0" *ngFor="let lot of lotNames">
                <div class="col-12">
                    <p class="mb-0 f-14 letter-spacing-pt2em text-center p-1">
                        {{ lot }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</fap-modal>

<fap-modal
    [inputIsLarge]="true"
    #configModal
    [titleColor]="'#ffffff'"
    inputTitle="Config"
    (outputOnHide)="reroute()"
>
    <div class="config_block">
        <form [formGroup]="sensorForm" class="row">
            <mat-form-field class="col-md-12">
                <mat-label>{{ 'sensor.sensorGroup' | translate }}</mat-label>
                <mat-select formControlName="sensorGroup" #mys name="sensorGroup" required (selectionChange)="changeGroup($event.value)">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                    <mat-form-field appearance="fill" class="w-100">
                        <!-- <mat-label>
                            <i class="fa fa-search f-20"></i>
                        </mat-label> -->
                        <input matInput #typeFilter placeholder="Search">
                    </mat-form-field>
                    <div class="input-group-append pl-1" (click)="showSensorGroupTypeModal(true)">
                        <span class="input-group-text"><i
                                class="icofont icofont icofont-plus"></i></span>
                    </div>
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let type of sensorGroupTypes | filter : typeFilter.value" [value]="type.id">
                        <div class="name">
                            <span *ngIf="type?.nameT">
                                <span *ngIf="getTranslation(type?.nameT) === '' || getTranslation(type?.nameT) == null || getTranslation(type?.nameT) == undefined ">{{type?.name}}</span>
                                <span *ngIf="getTranslation(type?.nameT) !== ''">{{getTranslation(type?.nameT)}}</span>
                                </span>
                            <span *ngIf="!type?.nameT">{{type?.name}}</span>                                                    
                        </div>
                        <div class="actions">
                            <i class="fa fa-edit" (click)="sensorGroupTypeEdit($event, type)"></i>
                            <i class="fa fa-trash" (click)="sensorGroupTypeDelete($event, type)"></i>
                        </div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-md-5 col-sm-4">
                <mat-label>{{ "sensor.selectColumns" | translate }}</mat-label>
                <mat-select #matSelect required formControlName="sensorIds" (selectionChange)="onOptionSelected($event)">
                  <mat-select-trigger>
                    {{ 'Select items' }}
                  </mat-select-trigger>
                  <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                    <input matInput #farmFilter placeholder="Search" />
                  </mat-form-field>
                  <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <div class="slct">
                      <mat-option
                        *ngFor="let column of allDeviceColumns | filter : farmFilter.value"
                        [value]="column?.sensor_ref?.id"
                        [ngClass]="{'highlighted': column.highlighted}"
                        style="text-transform: capitalize"
                        (click)="$event.preventDefault();"
                      >
                      <span class="middle_text">
                            <span class="small_text">{{ column?.sensor_ref?.id }}.</span>
                          {{ column?.name }}
                          <span class="small_text" style="display: block;">
                            {{ column?.column_ref?.object_id?.extra_text }}
                            {{ column?.column_ref?.unit_type?.unit }}
                          </span>
                        </span>
                      </mat-option>
                    </div>
                  </div>
                </mat-select>
              </mat-form-field>
          
            <div class="btns-group">
                <button class="btn" (click)="selectAllItems()"><img src="../../../../../../assets/images/select_all.png" alt=""></button>
                <button class="btn" (click)="removeAllItems()"><img src="../../../../../../assets/images/remove_all.png" alt=""></button>
            </div>
          </form>                   

        <div class="form-group">
            <mat-list (dragover)="allowDrop($event)" (drop)="onDrop($event)">
                <mat-list-item
                    *ngFor="let column of deviceColumns; let idx = index"
                    style="text-transform: capitalize"
                    draggable="true"
                    (dragstart)="onDragStart($event, idx)"
                >
                <span class="small_text">{{ column?.sensor_ref?.id }}.</span>
                <span class="middle_text">
                    {{column?.name}}
                  <span class="small_text" style="display: block;">{{ column?.column_ref?.object_id?.extra_text }} {{ column?.column_ref?.unit_type?.unit }}</span>
                    </span>
                    <span class="mr-1 ml-auto" style="text-align: right; display: flex">
                    <span class="mr-1 ml-auto btn config_btn" *ngIf="selectedWidgetType === 'sensor_value' || selectedWidgetType === 'image' || selectedWidgetType === 'images_list'" (click)="editCss(column)">{{ 'widget.config' | translate }}</span>
                        <em class="material-icons pt-1 theme-color" (click)="removeItem(column?.sensor_ref?.id)">delete</em>
                    </span>
                </mat-list-item>
            </mat-list>
        </div>

        <div class="btn-group">
            <button class="btn" (click)="addColumns()">Save</button>
        </div>
    </div>
</fap-modal>

<fap-modal #addEditSensorGroupTypeModal
    [inputPrimaryButtonInterface]="addEditSensorGroupTypeModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addEditSensorGroupTypeModalButtonSecondaryInterface"
    inputTitle="Add/Edit Sensor group type">
    <form [formGroup]="sensorGroupTypeForm" class="col-12 p-0">
        <div class="form-group">
            <mat-form-field class="w-100" (click)="addEditTranslation()">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput>
                <mat-error *ngIf="sensorGroupTypeForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="sensorGroupTypeForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="'Type name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.sensorGroupType)" 
        [prefix]="'SENSOR_GROUP_'" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>