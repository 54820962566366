<!-- <div class="form-group row">
    <label class="col-xl-3 col-lg-3 col-form-label">{{ inputLabel }}</label>
    <div class="col-lg-9 col-xl-6">
        <div class="kt-avatar"
            [ngClass]="{
                'kt-avatar--outline': inputHaveOutline,
                'kt-avatar--circle': inputIsCircle,
                'kt-avatar--danger': inputIsRed
            }">
            <div class="kt-avatar__holder">
                <img [src]="innerImage">
            </div>

            <label class="kt-avatar__upload">
                <i class="fa fa-pen"></i>
                <input type="file" accept=".png, .jpg, .jpeg"
                    (change)="handleInputChange($event)">
            </label>

            <span *ngIf="(innerImage !== defaultImage) && imageIsRemovable" class="kt-avatar__cancel" (click)="handleImageRemove()">
                <i class="fa fa-times"></i>
            </span>
        </div>

        <span class="form-text text-muted">{{ inputDescription }}</span>
    </div>
</div> -->
<!--<div class="form-group mb-2">-->
<!--    <div class="contact-profile">-->
<!--        <img class="rounded-circle" alt="" [src]="innerImage">-->
<!--        <label for="upload" class="fa fa-pencil edit-icon">-->
<!--        </label>-->
<!--        <input id="upload" class="upload" type="file" (change)="handleInputChange($event)" />-->
<!--    </div>-->
    <div class="contact-profile" [ngStyle]="{'background-image': 'url(' + innerImage + ')'}">
        <label [ngStyle]="{'background': accentColor}" for="upload" class="fa fa-pencil edit-icon">
        </label>
        <input id="upload" class="upload" type="file" (change)="handleInputChange($event)" />
    </div>
<!--</div>-->
