import { PartyService } from './../../../../../core/services/api/company/party.service';
import { UtilsHelper } from './../../../../../core/heplers/utils.helper';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Params,
    Router
} from '@angular/router';
import { FieldService } from '../../../../../core/services/api/farm/field.service';
import {
    Subscription,
    combineLatest
} from 'rxjs';
import { ResponseModel } from '../../../../../core/models/response.model';
import { FieldModel } from '../../../../../core/models/field/field.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FieldInterface } from '../../../../../core/interfaces/field/field.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { LotService } from '../../../../../core/services/api/farm/lot.service';
import { take } from 'rxjs/operators';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { UnitValueModel } from '../../../../../core/models/units/unit-value.model';
import { NavService } from '../../../../../shared/services/nav.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';

@Component({
    templateUrl: './field-edit-container.component.html',
    styleUrls: ['./field-edit-container.component.scss']
})
export class FieldEditContainerComponent implements OnDestroy, OnInit {
    public isEditMode = false;
    public farm: FarmModel;

    public fieldPolygon: MapPolygonInterface;
    public initialFieldPolygon: MapPolygonInterface;
    public fieldName: string;
    public soilType: string;
    public area: UnitValueModel;
    public lotId: number;
    public fieldId: number;
    public owner: number;
    public partyId: number

    private subscriptions: Array<Subscription> = [];

    constructor(public activatedRoute: ActivatedRoute,
                public fieldService: FieldService,
                public farmService: FarmService,
                public lotService: LotService,
                public partyService: PartyService,
                public mapService: MapService,
                public router: Router,
                public toastr: ToastrService,
                public translate: TranslateService,
                public navService: NavService,
                public globalRegistry: GlobalRegistryService,
                public cacheService: CacheResolverService
                ) {
        this.subscriptions.push(
            combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
            .subscribe(([params, queryParams]: [Params, Params]): void => {
            this.setupMap();
            // if we have the fieldId parameter, we are in edit mode
            if (params['fieldId'] !== undefined) {
                this.isEditMode = true;
                this.fieldId = parseInt(params['fieldId'], 10);
                this.getFieldData();
            } else if(queryParams['lot'] !== undefined){
                this.lotId = parseInt(queryParams['lot'], 10);
                this.isEditMode = false;
                this.fieldPolygon = null;
                this.initialFieldPolygon = null;
                this.fieldName = '';
                this.area = null;
                this.soilType = '';
                this.setupLotAndFarmData();
            } else {
                router.navigate(['/']); // we don't have all the params required, redirect to home
            }
        }));
    }

    public ngOnInit(): void {
        this.mapService.resetMap();
        this.navService.editFarm.next(true);
        this.navService.createMod.next(true);
    }

    public ngOnDestroy(): void {
        this.navService.editFarm.next(false);
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.navService.createMod.next(false);
        this.navService.editMod.next(false);
    }

    public getFieldData(): void {
        const url = this.fieldService.getUrl('')
        this.cacheService.delete(url+this.fieldId+'/')
        this.fieldService.getField(this.fieldId).pipe(take(1)).subscribe((field: ResponseModel<FieldModel>): void => {
            this.fieldName = field.model.name;
            this.soilType = field.model.soilType;
            this.area = field.model.area;
            this.lotId = field.model.lot;
            this.partyId = field.model.owner;
            
            this.fieldPolygon = {
                points: MapHelper.convertToAGMPolygon(field.model.coords.coordinates[0]),
                strokeColor: '#33de55',
                fillColor: '#248a38',
                isEditable: true
            };
            this.initialFieldPolygon = UtilsHelper.getDeepCopy(this.fieldPolygon);
            this.owner = field.model.owner;
            this.mapService.mapPolygons.push(this.fieldPolygon);
            this.mapService.centerMapOnPolygonIndex(this.mapService.mapPolygons.length - 1);
            this.setupLotAndFarmData();
        });
    }

    public setupMap(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
        this.mapService.resetMap();
        this.mapService.mapHasContextMenu = true;
        this.mapService.isEditMode = true;
        const mapServiceContext: MapService = this.mapService;
        const farmContainerContext: FieldEditContainerComponent = this;
        this.mapService.mapMenuOptions = [
            {
                header: this.translate.instant('map.actions.polygonHeader')
            },
            {
                text: this.translate.instant('map.actions.drawPolygon'),
                clickFunction(): void {
                    if (!mapServiceContext.mapPolygons.some((poly: MapPolygonInterface): boolean => poly.isEditable)) {
                        mapServiceContext.drawNewPolygon();
                    }
                }
            },
            {
                text: this.translate.instant('map.actions.clearPolygons'),
                clickFunction(): void {
                    mapServiceContext.mapPolygons = mapServiceContext.mapPolygons.filter(
                        (poly: MapPolygonInterface): boolean => !poly.isEditable);
                    farmContainerContext.fieldPolygon = null;
                }
            },
            {
                text: this.translate.instant('revertChanges'),
                clickFunction(): void {
                    mapServiceContext.clickedRevertBtn.emit();
                }
            }
        ];
        this.subscriptions.push(this.mapService.onLastPolyPathChange.subscribe((polygon: MapPolygonInterface): void => {
            this.fieldPolygon = polygon;
            this.fieldPolygon.isEditable = true;
            this.fieldPolygon.strokeColor = '#33de55';
            this.fieldPolygon.fillColor = '#248a38';
        }));
        this.subscriptions.push(this.mapService.clickedRevertBtn.subscribe((): void => {
            if(this.initialFieldPolygon && this.fieldPolygon){
                this.fieldPolygon = UtilsHelper.getDeepCopy(this.initialFieldPolygon);
                this.mapService.clearEditablePolygons();
                this.mapService.mapPolygons.push(this.fieldPolygon);
            } else if (this.initialFieldPolygon) {
                this.fieldPolygon = UtilsHelper.getDeepCopy(this.initialFieldPolygon);
                this.mapService.mapPolygons.push(this.fieldPolygon);
            } else if (this.fieldPolygon) {
                this.mapService.clearEditablePolygons();
                this.fieldPolygon = null;
            }
        }));
    }

    public submitField(fieldData: FieldInterface): void {
        if(this.fieldPolygon == null) {
            this.toastr.error(this.translate.instant('farm.field.fieldMustBeDrawn'));
        }
        const mappedCoords: Array<Array<number>> = MapHelper.convertToAPIPolygon(this.fieldPolygon.points);
        fieldData.coords = {
            coordinates: [mappedCoords],
            type: 'Polygon'
        };
        if(this.isEditMode){
            this.subscriptions.push(this.fieldService.updateField(this.fieldId, fieldData).subscribe((): void => {
                this.toastr.success(this.translate.instant('farm.field.fieldUpdatedSuccessfully'));
                this.router.navigate(['/pages/fields']);
            }, (): void => {
                this.toastr.error(this.translate.instant('farm.field.errorUpdatingField'));
                this.router.navigate(['/pages/fields']);
            }));
        } else {
            fieldData.lot = this.lotId;
            this.subscriptions.push(this.fieldService.createField(fieldData).subscribe((): void => {
                this.toastr.success(this.translate.instant('farm.field.fieldCreatedSuccessfully'));
                this.router.navigate(['/pages/fields']);
            }, (): void => {
                this.toastr.error(this.translate.instant('farm.field.errorCreatingField'));
                this.router.navigate(['/pages/fields']);
            }));
        }
    }

    public deleteParty(partyId: number): void {
        this.partyService.deleteParty(partyId).pipe(take(1)).subscribe(
            (): void => {
                this.globalRegistry.reloadParties();
                this.toastr.success(this.translate.instant('people.party.partyDeletedSuccessfully'));
            }, (): void => {
                this.toastr.success(this.translate.instant('people.party.errorDeletingParty'));
            }
        );
    }

    private setupLotAndFarmData(): void {
        this.lotService.getLot(this.lotId).pipe(take(1)).subscribe((lot: ResponseModel<LotModel>): void => {
            this.farmService.getFarm(lot.model.farm).pipe(take(1)).subscribe((farm: ResponseModel<FarmModel>): void => {
                this.mapService.mapPolygons.push({
                    points: MapHelper.convertToAGMPolygon(farm.model.coords.coordinates[0]),
                    strokeColor: '#ff0000',
                    fillColor: '#ff0000',
                    isEditable: false
                });
                if (!this.fieldPolygon) {
                    this.mapService.centerMapOnPolygons();
                }
            });
        });
    }
}
