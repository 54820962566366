import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import { ChartBarWidgetInterface } from '../data/chart-bar-widget.interface';
import { NameValueInterface } from '../../../core/interfaces/general/name-value.interface';
import { EventResponseInterface } from '../data/event-response.interface';
// import * as shape from 'd3-shape';

@Component({
    selector: 'fap-line-chart',
    templateUrl: './fap_line-chart.component.html',
    styleUrls: ['./fap_line-chart.component.scss']
})
export class FapHorizontalLineChartComponent {

    @Input()
    public chartData: ChartBarWidgetInterface;

    // @Input()
    // public curveStep: {} = shape.curveMonotoneX;

    @Output()
    public selectedValue: EventEmitter<NameValueInterface> = new EventEmitter<NameValueInterface>();

    @Output()
    public activate: EventEmitter<EventResponseInterface> = new EventEmitter<EventResponseInterface>();

    @Output()
    public deactivate: EventEmitter<EventResponseInterface> = new EventEmitter<EventResponseInterface>();

    public onSelect(event: NameValueInterface): void {
        console.log(event);
        this.selectedValue.emit(event);
    }

    public onActivate(data: EventResponseInterface): void {
        this.activate.emit(data);
        console.log('Activate', JSON.parse(JSON.stringify(data)));
    }

    public onDeactivate(data: EventResponseInterface): void {
        this.deactivate.emit(data);
        console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

}
