<div
    *ngIf="widget"
    class="dashboard-card dash_cont"
    style="position: relative"
    id="parent"
>
    <div class="row">
        <div class="col-md-4">
            <h1>
                {{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}
            </h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row" style="max-height: calc(100% - 15px); padding: 10px; overflow: auto;" *ngIf="device">
        <table class="device_table">
            <tr>
                <th>Name</th>
                <td style="font-size: 16px" (click)="editDevice(device.id)">
                    <span style="display: inline-block; margin-right: 10px;" [innerHTML]="device?.name ? device?.name : device?.product ? device?.product?.name : '<i>No name</i>'"></span>
                    <span><i class="fa fa-edit"></i></span>
                </td>
            </tr>
            <tr>
                <th>ID</th>
                <td>{{device?.id}}</td>
            </tr>
            <tr>
                <th>Lots</th>
                <td class="lot_names">
                    <span>{{ [globalRegistry.systemData.lots, device?.lots] | getLotNames }}</span>
                </td>
            </tr>
            <tr>
                <th>Location</th>
                <td (click)="showDevicePointer(device?.coords?.last_value?.value_coord?.coordinates)">{{ device?.coords?.last_value?.value_coord?.coordinates ?
                    device.coords.last_value.value_coord.coordinates[0] + ' ' + device.coords.last_value.value_coord.coordinates[1] :
                    '-' }}</td>
            </tr>
        </table>
    </div>
</div>