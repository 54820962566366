<dashboard-layout
  (addWidget)="addWidget($event)"
  (updateWidget)="updateWidget($event)"
  (deleteWidget)="deleteWidget($event)"
  [lots]="lots"
  [notes]="notes"
  [objects]="objectTypes"
  [unitTypes]="unitTypes"
  [devices]="devices"
  [sensors]="sensors"
  [widgets]="filteredWidgets"
  [activityTypes]="activityTypes"
  style="max-width: 100%; width: 100%; margin: auto;"
></dashboard-layout>
