import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ImageModel } from '../../../../../core/models/company/image.model';
import { DiseaseModel } from '../../../../../core/models/diseases/disease.model';
import { TreatmentModel } from '../../../../../core/models/diseases/treatment.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { CropService } from '../../../../../core/services/api/crop/crop.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FapModalComponent } from '../../../../../shared/partials';
import { NavService } from '../../../../../shared/services/nav.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
  templateUrl: './fap-disease-overview.component.html',
  styleUrls: ['./fap-disease-overview.component.scss']
})
export class FapDiseaseOverviewComponent implements OnInit, OnDestroy {

    public checkId: Array<number> = [];
    public entries = [];
    
    public currencyUnits = [];
    closeActive = new Subject<void>();
    public subscriptions: Array<Subscription> = [];
    public transaction: Array<any> = [];
    public subscription: Subscription = new Subscription();
    public images: Array<ImageModel> = [];
    public diseases: Array<DiseaseModel> = [];
    public disease: DiseaseModel;
    public treatments: Array<TreatmentModel> = [];
    public products = [];
    public preventions = [];
    public diseaseType: string;
    public translatedNames: any = [];
    public langString: string;
    public context;
    public mediaUrl = environment.mediaUrl;
    @Output()
    public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('contextPopup') contextPopup: FapModalComponent;
    public getMore = true;
    public nextToken: { offset: number; limit: number; type: string} = null;
    public limit = 20;
    public token: string;

    constructor(
        public globalRegistry: GlobalRegistryService,
        public stockService: StockService,
        public unitService: UnitService,
        public mapService: MapService,
        public cropService: CropService,
        public companyService: CompanyService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public navService: NavService,
        public widgetService: WidgetsService
    ) {}


    public ngOnInit(): void {
      this.diseaseType = this.activatedRoute.snapshot.routeConfig.path;
      this.widgetService.setSize(12);
      this.mapService.hideMap();
      this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.subscriptions.push(
            this.cropService.getDiseases({type: this.diseaseType}).subscribe(data => {
              console.log(data.body.results);
              this.diseases = data.body.results;
              // console.log(this.diseases);
              this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.nextToken) this.getMore = true;
            }),
            );
            this.subscriptions.push(this.companyService.getCompanyImages({group: 'diseases'}).subscribe(response => {
              this.images = response.body.results;
            }))
    }


  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  
    scrolledDown() {
      if(this.getMore) {
      this.nextToken &&
        this.subscription.add(this.cropService.getDiseases(this.nextToken).subscribe((data) => {
          console.log(data);
          if(this.diseases) {
            this.diseases = [...this.diseases, ...data.body.results];
          } else {
            this.diseases = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            if(this.nextToken.offset != params['offset']) {
            this.nextToken = {offset: params['offset'], limit: params['limit'], type: this.diseaseType};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
    }

    getMoment(date) {
      const dstring = moment.utc(date).local();
      return moment(dstring).fromNow();
    }

    selectEntry(event, item) {
        // if (event.target.checked === true) {
            this.checkId = item.id;
            this.disease = item;
            this.treatments = item.treatments;
            this.preventions = item.preventions;
        // }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
        this.widgetService.setSize(6);
    }

    public newDisease() {
      this.router.navigate(['/pages/diseases/add']);
    }

    public getTransaction(resourceEntryId) {
      this.stockService.getTransactions(resourceEntryId).subscribe(data => {
        this.transaction = data.body.results[0];
      })
    }

    getTranslation(translation) {
      const t = this.translatedNames.filter((trans) => {
          return trans.id === translation;
      });
      if(t[0]) {
        return t[0][this.langString];
      } else {
        return translation
    }
      
  }

  displayInfo(context) {
    this.context = context;
    this.contextPopup.showModal();
  }

}
