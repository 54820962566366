import { DOCUMENT, DatePipe, Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { DocumentModel } from '../../../../../core/models/stock/document.model';
import { TransactionModel } from '../../../../../core/models/stock/transaction.model';
import { ResourceTypeModel } from '../../../../../core/models/type/resource-type.model';
import { UnitModel } from '../../../../../core/models/units/unit.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FapModalComponent } from '../../../../../shared/partials';
import { NavService } from '../../../../../shared/services/nav.service';
import { DecimalPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { UnitInterface } from '../../../../../core/interfaces/unit/unit.interface';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { take } from 'rxjs/operators';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';
import { StockResourceModel } from '../../../../../core/models/stock/stock-resource.model';
import * as moment from 'moment-timezone';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { PartyService } from './../../../../../core/services/api/company/party.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { BillingService } from '../../../../../core/services/api/billing/billing.service';

@Component({
    selector: 'fap-stock-bill',
    templateUrl: './fap-stock-bill.component.html',
    styleUrls: ['./fap-stock-bill.component.scss'],
    providers: [DatePipe, DecimalPipe],
})
export class FapStockBillComponent implements OnInit, OnDestroy, AfterViewInit {
 
  @ViewChild('addResourceTypeModal')
  public addResourceTypeModal: FapModalComponent;
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  @ViewChild('mySelect') mySelect;
  @ViewChild('mys') myS;
  @ViewChild('mys1') mys1;
  @ViewChild('mys2') mys2;

    public billForm: UntypedFormGroup;
    public bill: any;
    public parties = [];
    public ownParties = [];
    public persons: Array<PersonModel> = [];
    public farms: Array<FarmModel> = [];
    public currencies: Array<UnitModel>;
    public transactions: Array<TransactionModel> = [];
    public total = 0;
    public totalVat = 0;
    public grandTotal = 0;
    public billId: number;
    public subscription: Subscription = new Subscription();
    public itemForm: UntypedFormGroup;
    public productionNoteItemForm: UntypedFormGroup;
    public addEditExchangeForm: UntypedFormGroup;
    public addEditOfferForm: UntypedFormGroup;
    public productForm: UntypedFormGroup;
    public product: any;
    public products: Array<any> = [];
    public producedProducts: Array<any> = [];
    public selectedResource: ResourceTypeModel;
    public selectedUnit: UnitModel;
    public currentUnit: UnitModel;
    public parentUnit: UnitModel;
    public parentResource: ResourceTypeModel;
    public translatedNames:any = [];
    public langString: string;
   
    public items = [];
    public p_items = [];
    public selectedParty: PartyModel;
    public units: Array<UnitModel> = [];
    public unitForm: UntypedFormGroup;
    public selectedUnitId: number;

    public stockItems: Array<StockItemModel> = [];
    public entries = [];
    public max = 9999;
    public resDocId: any;
    public resDocItemIds: Array<any> = [];
    public resPDocItemIds: Array<any> = [];
    public isLoading = false
    public productUnit;
    public getMoreContracts = true;
    public getMore = true;
    public getMoreProduced = true;
    public getMoreParties = true;
    public getMoreOwnParties = true;
    public getMorePersons = true;
    public nextContractToken: { limit: number; offset: number; type: string; } = null;
    public nextToken: { limit: number; offset: number; search: string; } = null;
    public nextPToken: { limit: number; offset: number; search: string; produced: number } = null;
    public partyToken: { limit: number; offset: number; search: string; } = null;
    public ownPartyToken: { limit: number; offset: number; search: string; own: number} = null;
    public personToken: { limit: number; offset: number; search: string; } = null;
    public limit = 20;
    public offset = 20;
    public searchKeyword = '';
    public partyFrom;
    public partyTo;
    public person;
    @ViewChild('mys') mys;
    @ViewChild('addEditPartyModal')
    public addEditPartyModal: FapModalComponent;
    public productId;
    public produced = 0;
    @ViewChild('addEditProductModal')
    public addEditProductModal: FapModalComponent;
    @ViewChild('addEditProductionProductModal')
    public addEditProductionProductModal: FapModalComponent;
    @ViewChild('typeFilter7')
    public typeFilter1: ElementRef;
    @ViewChild('typeFilter2')
    public typeFilter2: ElementRef;
    public stockUnit;
    public documentCurrency;
    public defaultCurrency;
    
    public availableVAT = [];
    public entryProductId;
    public differentCurrency = false;
    public rates = [];
    public exchangeRate = null;
    public offers = [];
    public appliedOffer = [];
    public currentExchange;
    public exchangeCurrencies: Array<UnitModel> = [];
    public userId: number;
    public contractDocs = [];
    public selectedEntry = null;
    @ViewChild('selectTrigger') selectTrigger: ElementRef;

    @Output()
    public addedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public updatedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteProduct: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedProduct: EventEmitter<any> = new EventEmitter();

    @Output()
    public updatedProduct: EventEmitter<any> = new EventEmitter();


    constructor(
        public formBuilder: UntypedFormBuilder,
        public globalRegistry: GlobalRegistryService,
        public route: ActivatedRoute,
        public stockService: StockService,
        private datePipe: DatePipe,
        public navService: NavService,
        public router: Router,
        public mapService: MapService,
        public translateService: TranslateService,
        public companyService: CompanyService,
        private _decimalPipe: DecimalPipe,
        public toastrService: ToastrService,
        public unitService: UnitService,
        public toastyService: ToastrService,
        public confirmModalService: ConfirmModalService,
        public typesService: TypesService,
        public partyService: PartyService,
        public widgetService: WidgetsService,
        public cacheService: CacheResolverService,
        public userService: UserService,
        private location: Location,
        private billingService: BillingService,
        @Inject(DOCUMENT) private document: Document
    ) {
      this.userService.getCurrentUser.subscribe((user) => {
        if (Object.keys(user).length != 0) {
            console.log(user.profile.data);
            this.persons.unshift(user.profile);
            this.userId = user.profile.id
        }
    })
    }

    transformDecimal(num) {
      return this._decimalPipe.transform(num, '1.2-2', 'en-US');
    }

    ngOnInit(): void {
      // console.log(localStorage.getItem('documentFarm'));
      // console.log(JSON.parse(localStorage.getItem('documentCurrency')));
      // console.log(this.route.snapshot.queryParams.type);
      this.initForm();
      this.defaultCurrency = this.globalRegistry.systemData.company.currency;
      this.subscription.add(
        this.companyService.getCompany().subscribe(data => {
          this.availableVAT = Object.values(data.model.vatRates);
          if(this.route.snapshot.queryParams.new_doc == true) {
            this.billForm.get('currency').setValue(this.defaultCurrency);
            this.billForm.get('vat').setValue(this.availableVAT[0]);
            this.setCurrency(this.defaultCurrency);
          }
        })
      )
      
     
      // if(this.route.snapshot.queryParams.type == 'internal_note' || this.route.snapshot.queryParams.type == 'invoice_out') {
      //   this.getStockItems();
      // } else if(this.route.snapshot.queryParams.type == 'production_note' || this.route.snapshot.queryParams.type == 'estimation_note'){
      //   this.getStockItems();
      //   this.getProducts();
      //   this.getProductionProducts();
      //   console.log('here');
      // } else {
      //   this.getProducts();
      // }
      if(this.route.snapshot.queryParams.type !== 'production_note' && this.route.snapshot.queryParams.type !== 'internal_note' && this.route.snapshot.queryParams.type !== 'estimation_note') {
        this.widgetService.setSize(7);
      } else {
        this.widgetService.setSize(5);
      }
      
      this.mapService.showMap();
      this.mapService.setMapDisplay(null);
      this.initunitForm();
      this.units = this.globalRegistry.systemData.units
        this.farms = this.globalRegistry.systemData.farms;
        this.initItemForm();
        this.initProductionNoteItemForm();
        this.initExchangeForm();
        this.initOfferForm();
        
        // this.getPersons();
        this.subscription.add(
            combineLatest([this.route.params]).subscribe(
                ([params]: [Params]) => {
                    console.log(params);
                    if (params['billId'] !== undefined) {
                        this.billId = Number(params['billId']);
                        this.navService.createMod.next(false);
                        this.navService.editMod.next(true);
                        this.getBill(this.billId);
                    }
                }
            )
        );
        console.log(this.route.snapshot.queryParams.new_doc);
        if(this.route.snapshot.queryParams.new_doc == 'true') {
          console.log('method');
          this.createNewDocument();
        }
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.mapService.resetMap();
        this.navService.editFarm.next(true);
        this.navService.createMod.next(true);
        this.subscription.add(
            this.navService.emitSubmit.subscribe((value: boolean) => {
                if (value) {
                    this.submitForm();
                }
            })
        );
        this.subscription.add(
            this.navService.emitDelete.subscribe((value: boolean) => {
                value && this.deleteExistingDocAndItems();
            })
        );

        this.subscription.add(
            this.navService.emitCancel.subscribe((value: boolean) => {
                if (value) {
                  if(this.resDocId) {
                    this.deleteDocAndItems();
                  } else {
                    this.navBack();
                  }
                  this.navService.emitCancel.next(false);
                }
            })
        );

          if(this.bill) {
        this.parties.filter(p => {
          if(p.id == this.bill.party_from) {
            this.partyFrom = p;
          }
          if(p.id == this.bill.party_to) {
            this.partyTo = p;
          }
        })
        
        this.filterPersons();

        if(this.bill.party_from) {
        if (this.parties.findIndex(item => item.id == this.bill.party_from) == -1) {
          this.subscription.add(this.partyService.getParty(this.bill.party_from).subscribe(data => {
            this.bill.party_from = data.body.results.id;
            if (this.globalRegistry.systemData.parties.filter(e => e.id === this.bill.party_from).length < 0) {
              this.globalRegistry.systemData.parties.unshift(data.body.results);
              return
            } else {
              return
            }        
          }))
        }
      }

      if(this.bill.party_to) {
        if (this.parties.findIndex(item => item.id == this.bill.party_to) == -1) {
          this.subscription.add(this.partyService.getParty(this.bill.party_to).subscribe(data => {
            this.bill.party_to = data.body.results.id;
            if (this.globalRegistry.systemData.parties.filter(e => e.id === this.bill.party_to).length < 0) {
              this.globalRegistry.systemData.parties.unshift(data.model);
              return
            } else {
              return
            }        
          }))
        }
      }
  
      if(this.bill.receive_by) {
      if (this.persons.findIndex(item => item.id == this.bill.receive_by) == -1) {
        this.subscription.add(this.companyService.getPerson(this.bill.receive_by).subscribe(data => {
          this.bill.receive_by = data.body.results.id;
          if(this.persons) {
          this.persons.unshift(data.body.results);
          console.log(this.persons);
          }
          if (this.globalRegistry.systemData.persons.filter(e => e.id === this.bill.receive_by).length < 0) {
            this.globalRegistry.systemData.persons.push(data.body.results);
            return
          } else {
            return
          }        
        }))
      }
    }

    }
    }

    ngAfterViewInit() {
      this.mys.nativeElement.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) { // enter key code
          event.stopPropagation();
        }
      });
    }

    getBillFarm(lotId) {
      this.globalRegistry.systemData.lots.forEach(lot => {
        if(lot.id === lotId) {
          console.log(lot);
          this.billForm.get('farm').patchValue(lot.farm);
        }
      })
    }

    filterPersons() {
    if(this.bill && this.bill.receive_by) {
      const personExist = this.globalRegistry.systemData.persons.some((p) => p.id === this.bill.receive_by);
      if(!personExist) {
        this.companyService.getPerson(this.bill.receive_by).subscribe(data => {
        this.globalRegistry.systemData.persons.push(data.body.results);
        console.log(this.globalRegistry.systemData.persons);
      }); 
      } 
  }
  }

  public deleteExistingDocAndItems() {
    console.log(this.resDocItemIds);
    if (this.billId && this.resDocItemIds.length) {
      for (let index = this.resDocItemIds.length - 1; index >= 0; index--) {
        if (this.resDocItemIds[index] !== undefined) {
          this.subscription.add(this.stockService.deleteDocItem(this.resDocItemIds[index]).subscribe(data => {
            console.log(data);
          }));
        }
      }
  
      // Delete the document after all document items are deleted
      this.subscription.add(this.stockService.deleteDoc(this.billId).subscribe(data => {
        console.log(data);
        this.toastrService.success(this.translateService.instant('stock.documentDeletedSuccess'));
        this.navBack();
      }));
    }
  }
  

  deleteDocument() {
    this.stockService.deleteDoc(this.resDocId).subscribe(data => {
      console.log(data);
      this.toastrService.success(this.translateService.instant('stock.documentDeletedSuccess'))   
      this.navBack();
    })
  }

  public deleteDocAndItems() {
    if (this.resDocItemIds.length) {
      for (let index = this.resDocItemIds.length - 1; index >= 0; index--) {
        if (this.resDocItemIds[index] !== undefined) {
          this.subscription.add(this.stockService.deleteDocItem(this.resDocItemIds[index]).subscribe(data => {
            console.log(data);
          }));
        }
      }
      this.deleteDocument();
    } else {
      this.deleteDocument();
    }
  }

    public navBack() {
        if(this.route.snapshot.queryParams.from == 'activity') {
          if(this.route.snapshot.queryParams.activityId != null || undefined || '') {
            this.router.navigate(['/pages/activities/edit/'+this.route.snapshot.queryParams.activityId])
          } else {
            this.router.navigate(['/pages/activities/add'])
          }
        } else if(this.route.snapshot.queryParams.from == 'lot') {
          if(this.route.snapshot.queryParams.lotId != null || undefined || '') {
            this.router.navigate(['/pages/lot/edit-culture/'+this.route.snapshot.queryParams.lotId])
          } else {
            this.router.navigate(['/pages/lot/edit-culture/'+this.route.snapshot.queryParams.lotId]);
          }
        } else if(this.route.snapshot.queryParams.from == 'equipment') {
          if(this.route.snapshot.queryParams.equipmentId != null || undefined || '') {
            this.router.navigate(['/pages/equipments/edit/'+this.route.snapshot.queryParams.equipmentId])
          } else {
            this.router.navigate(['/pages/equipments/add'])
          }
        }
         else {
          this.router.navigate(['/pages/stock'])
        }
    }

    public createNewDocument() {
      this.isLoading = true;
      this.toastrService.warning(this.translateService.instant('stock.documentCreatePending'));
      localStorage.setItem('seller', this.billForm.get('partyFrom').value);
      localStorage.setItem('buyer', this.billForm.get('partyTo').value);
      localStorage.setItem('vat', this.billForm.get('vat').value);
      localStorage.setItem('documentCurrency', this.billForm.get('currency').value);
      localStorage.setItem('documentFarm', this.billForm.get('farm').value);
      localStorage.setItem('receiver', this.billForm.get('receivedBy').value);

      const document = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        contract: this.billForm.get('contract').value,
        farm: this.route.snapshot.queryParams.farm ? this.route.snapshot.queryParams.farm : this.billForm.get('farm').value,
        currency: this.defaultCurrency ? this.defaultCurrency : this.billForm.get('currency').value,
        vat: this.availableVAT ? this.availableVAT[0] : this.billForm.get('vat').value
      }

      if(document.receive_date == 'Invalid date') {
        document.receive_date  = null;
      }

      if(this.bill) {
        document.type = this.bill.type;
        
      }
      console.log(this.bill);
      if(!this.bill) {
        this.subscription.add(this.stockService.createDocument(document).subscribe(data => {
        if(data) {
          this.isLoading = false;
          this.resDocId = data.body.results.id;
          this.bill = data.body.results;
          this.initForm();
          console.log(this.resDocId);
            this.toastrService.success(this.translateService.instant('stock.documentCreatedSuccess'));
          }
        }, (error)=> {
          console.log(error);
          this.toastrService.error(this.translateService.instant('stock.documentCreateError'));
        }));
      }
    }

    public updateDoc() {
      if(this.billForm.invalid) {
        this.billForm.markAllAsTouched();
        this.toastrService.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
        return
      }
      localStorage.setItem('seller', this.billForm.get('partyFrom').value);
      localStorage.setItem('buyer', this.billForm.get('partyTo').value);
      localStorage.setItem('vat', this.billForm.get('vat').value);
      localStorage.setItem('documentCurrency', this.billForm.get('currency').value);
      localStorage.setItem('documentFarm', this.billForm.get('farm').value);
      localStorage.setItem('receiver', this.billForm.get('receivedBy').value);

      let document;

      if(this.route.snapshot.queryParams.type == 'invoice_in' || this.route.snapshot.queryParams.type == 'inovice_out' || this.route.snapshot.queryParams.type == 'estimation_note') {
        document = {
          id: this.bill ? this.bill.id : null,
          type: this.route.snapshot.queryParams.type,
          doc_id: this.billForm.get('documentId').value,
          doc_date: this.billForm.get('documentDate').value,
          party_from: this.billForm.get('partyFrom').value,
          party_to: this.billForm.get('partyTo').value,
          created_at: this.billForm.get('createdAt').value,
          updated_at: this.billForm.get('updatedAt').value,
          receive_by: this.billForm.get('receivedBy').value,
          receive_date: this.billForm.get('receivedDate').value,
          info: this.billForm.get('info').value,
          farm: this.billForm.get('farm').value,
          currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
          vat: this.availableVAT ? this.availableVAT[0] : this.billForm.get('vat').value,
          exchange: this.currentExchange ? this.currentExchange.id : null,
        }
      } else if(this.route.snapshot.queryParams.type == 'internal_note') {
        document = {
          id: this.bill ? this.bill.id : null,
          type: this.route.snapshot.queryParams.type,
          doc_id: this.billForm.get('documentId').value,
          doc_date: this.billForm.get('documentDate').value,
          party_from: this.billForm.get('partyFrom').value,
          party_to: this.billForm.get('partyTo').value,
          created_at: this.billForm.get('createdAt').value,
          updated_at: this.billForm.get('updatedAt').value,
          receive_by: this.billForm.get('receivedBy').value,
          receive_date: this.billForm.get('receivedDate').value,
          info: this.billForm.get('info').value,
          farm: this.billForm.get('farm').value,
          farm_to : this.billForm.get('farmTo').value,
          currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
          vat: this.availableVAT ? this.availableVAT[0] : this.billForm.get('vat').value,
          exchange: this.currentExchange ? this.currentExchange.id : null,
        }
      } else if((this.route.snapshot.queryParams.from == 'activity')) {
        document = {
          id: this.bill ? this.bill.id : null,
          type: this.route.snapshot.queryParams.type,
          doc_id: this.billForm.get('documentId').value,
          doc_date: this.billForm.get('documentDate').value,
          party_from: this.billForm.get('partyFrom').value,
          party_to: this.billForm.get('partyTo').value,
          created_at: this.billForm.get('createdAt').value,
          updated_at: this.billForm.get('updatedAt').value,
          receive_by: this.billForm.get('receivedBy').value,
          receive_date: this.billForm.get('receivedDate').value,
          info: this.billForm.get('info').value,
          farm: this.billForm.get('farm').value,
          activity : +this.route.snapshot.queryParams.activityId ? +this.route.snapshot.queryParams.activityId : -1,
          currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
          vat: this.availableVAT ? this.availableVAT[0] : this.billForm.get('vat').value,
          exchange: this.currentExchange ? this.currentExchange.id : null,
        } 
      } else {
        document = {
          id: this.bill ? this.bill.id : null,
          type: this.route.snapshot.queryParams.type,
          doc_id: this.billForm.get('documentId').value,
          doc_date: this.billForm.get('documentDate').value,
          party_from: this.billForm.get('partyFrom').value,
          party_to: this.billForm.get('partyTo').value,
          created_at: this.billForm.get('createdAt').value,
          updated_at: this.billForm.get('updatedAt').value,
          receive_by: this.billForm.get('receivedBy').value,
          receive_date: this.billForm.get('receivedDate').value,
          info: this.billForm.get('info').value,
          farm: this.billForm.get('farm').value,
          currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
          vat: this.availableVAT ? this.availableVAT[0] : this.billForm.get('vat').value,
          exchange: this.currentExchange ? this.currentExchange.id : null,
        }
      }

      if(document.receive_date == 'Invalid date') {
        document.receive_date  = null;
      }

      if(this.bill) {
        document.type = this.bill.type;
      }

      if(this.bill && this.resDocItemIds.length > 0) {
        this.subscription.add(this.stockService.updateDocument(document).subscribe(data => {
          console.log(data.body.result)
          this.toastrService.success(this.translateService.instant('stock.documentUpdatedSuccess'));
          setTimeout(()=> {
            if(this.route.snapshot.queryParams.from == 'activity') {
                  if(this.route.snapshot.queryParams.activityId == undefined) {
                    this.router.navigate(['/pages/activities/add'], {queryParams: {doc: this.resDocId}})
                  } else {
                    this.router.navigate(['/pages/activities/edit/'+this.route.snapshot.queryParams.activityId], {queryParams: {doc: this.resDocId}})
                  }
            }
            else if(this.route.snapshot.queryParams.from == 'lot') {
              if(this.route.snapshot.queryParams.lotId != null || this.route.snapshot.queryParams.lotId != undefined || this.route.snapshot.queryParams.lotId != '') {
                if(this.route.snapshot.queryParams.new_doc == 'true') {
                this.router.navigate(['/pages/lot/edit-culture/'+this.route.snapshot.queryParams.lotId], {queryParams: {doc: this.resDocId}})
                } else if(this.route.snapshot.queryParams.new_doc == 'false') {
                  this.router.navigate(['/pages/lot/edit-culture/'+this.route.snapshot.queryParams.lotId])
                } else {
                  this.router.navigate(['/pages/lot/edit-culture/'+this.route.snapshot.queryParams.lotId])
                }
              } else {
                this.router.navigate(['/pages/lot/edit-culture/'+this.route.snapshot.queryParams.lotId], {queryParams: {doc: this.resDocId}})
              }
            }
             else {
              this.router.navigate(['/pages/stock'])
            }
          }, 1000);
          }, (error)=> {
            console.log(error);
            this.toastrService.error(this.translateService.instant('stock.documentUpdateError'));
          }));
      }
    }

    public initForm() {
        const formBody = {
          createdAt: [
                this.bill ? moment.utc(this.bill.created_at).format('') : new Date(),
            ],
            documentDate: [
                this.bill ? moment.utc(this.bill.doc_date).format('') : new Date(),
            ],
            partyFrom: [this.bill ? this.bill.party_from : this.checkSeller(),
              
            ],
            partyTo: [
              this.bill ? this.bill.party_to : this.checkBuyer(),
              
            ],
            documentId: [
                this.bill ? this.bill.doc_id : '*',
                Validators.compose([Validators.required])
            ],
            receivedBy: [
              this.bill ? this.bill.receive_by : this.userId
            ],
            receivedDate: [
                this.bill ? moment.utc(this.bill.receive_date).format('') : new Date(),
            ],
            updatedAt: [
              this.bill ? moment.utc(this.bill.updated_at).format('') : null,
          ],
            farm: [
              this.bill ? this.bill.farm : this.route.snapshot.queryParams.farm ? this.route.snapshot.queryParams.farm : null, Validators.required],
            farmTo: [
              this.bill ? this.bill.farm_to : this.globalRegistry.systemData.farms[0].id],
            activityId: [ +this.route.snapshot.queryParams.activityId ? +this.route.snapshot.queryParams.activityId : -1 ],
            currency: [this.bill ? this.bill.currency : this.defaultCurrency],
            vat: [this.bill ? this.bill.vat : this.availableVAT[0]],
            total: [this.bill ? +this.bill.subtotal.toFixed(this.globalRegistry.systemData.company.digits) : null],
            totalVat: [this.bill ? +this.bill.total_vat.toFixed(this.globalRegistry.systemData.company.digits) : null],
            grandTotal: [this.bill ? +this.bill.grand_total.toFixed(this.globalRegistry.systemData.company.digits) : null],
            exchange: [null],
            contract: [this.bill ? this.bill.contract : null],
            info: [this.bill ? this.bill.info : ''],
        };
        this.billForm = this.formBuilder.group(formBody);
        setTimeout(() => {
          if(this.route.snapshot.queryParams.type == 'internal_note') {
            this.billForm.controls['farmTo'].setValidators(Validators.required);
            if(!this.bill) {
              this.billForm.controls['farmTo'].setValue(this.globalRegistry.systemData.farms[0].id);
            }
          }
        }, 1000);
        // if (this.bill) {
        //     this.totalVat = this.bill.totalVat;
        //     this.total = this.bill.total;
        //     this.grandTotal = this.bill.grandTotal;
        // }
        if(this.bill && this.bill.doc_id == '*') {
          const date = this.bill.doc_date.substring(0, 16).replaceAll('-', '').replaceAll(' ', '_').replaceAll(':', '');
        if(this.bill.type == 'invoice_in') {
          const docId = 'II_'+date;
          this.billForm.get('documentId').patchValue(docId);
        } 
        // else if(this.bill.type == 'invoice_out') {
        //   const docId = 'IO_'+date;
        //   this.billForm.get('documentId').patchValue(docId);
        // } else if(this.bill.type == 'internal_note') {
        //   const docId = 'IN_'+date;
        //   this.billForm.get('documentId').patchValue(docId);
        // } else if(this.bill.type == 'estimation_note') {
        //   const docId = 'EN_'+date;
        //   this.billForm.get('documentId').patchValue(docId);
        // } else if(this.bill.type == 'production_note') {
        //   const docId = 'PN_'+date;
        //   this.billForm.get('documentId').patchValue(docId);
        // } else {
        //   return
        // }
      }
      if(this.bill) {
        this.initExchangeForm();
        this.setCurrency(this.bill.currency);
        // console.log(this.selectedUnit);
        if(this.bill.currency != null && (this.bill.currency != this.defaultCurrency)) {
          this.getExchangeRates(this.bill.currency);
          this.getExchangeUnits(this.bill.currency);
          this.addEditExchangeForm.get('fromUnit').setValue(this.defaultCurrency);
          this.addEditExchangeForm.get('toUnit').setValue(this.bill.currency);
        }
        if(this.bill.contract) {
          this.filterInvoices();
        }
        this.checkDefaultPartyExistence(this.bill.party_from, this.bill.party_to);
        this.checkDefaultPersonExistence(this.bill.receive_by);

      }
      // if(this.route.snapshot.queryParams.farm) {
      //   this.billForm.get('farm').patchValue(this.route.snapshot.queryParams.farm);
      // }
    }

    public checkDefaultPersonExistence(personId) {
      const personExist = this.persons.some(p => p.id == personId)
      if(!personExist) {
        this.subscription.add(this.companyService.getPerson(personId).subscribe(data => {
          this.persons.unshift(data.body.results);
        }))
      }
  }

    public checkDefaultPartyExistence(partyFrom, partyTo) {
      if(this.route.snapshot.queryParams.type == 'invoice_in') {
        const INpartyFromPresence = this.parties.some(p => p.id == partyFrom)
        const INPartyToPresence = this.ownParties.some(p => p.id == partyTo)
        if(!INpartyFromPresence && partyFrom) {
          this.subscription.add(this.partyService.getParty(partyFrom).subscribe(data => {
            this.parties.unshift(data.body.results);
          }))
        }
        if(!INPartyToPresence && partyTo) {
          this.subscription.add(this.partyService.getParty(partyTo).subscribe(data => {
            this.ownParties.unshift(data.body.results);
          }))
        }
      }
      if(this.route.snapshot.queryParams.type == 'invoice_out') {
        const IOpartyFromPresence = this.ownParties.some(p => p.id == partyFrom)
        const IOpartyToPresence = this.parties.some(p => p.id == partyFrom)
        if(!IOpartyFromPresence && partyFrom) {
          this.subscription.add(this.partyService.getParty(partyFrom).subscribe(data => {
            this.ownParties.unshift(data.body.results);
          }))
        }
        if(!IOpartyToPresence && partyTo) {
          this.subscription.add(this.partyService.getParty(partyTo).subscribe(data => {
            this.ownParties.unshift(data.body.results);
          }))
        }
      }
    }

    checkLocalFarm() {
      if(localStorage.getItem('documentFarm')!= null) {
        return +localStorage.getItem('documentFarm')
      }
    }

    checkSeller() {
      if(localStorage.getItem('seller')!= null) {
        return +localStorage.getItem('seller')
      }
    }

    checkBuyer() {
      if(localStorage.getItem('buyer')!= null) {
        return +localStorage.getItem('buyer')
      }
    }

    checkLocalVat() {
      if(localStorage.getItem('vat')!= null) {
        return +localStorage.getItem('vat')
      } else {
        return this.availableVAT[0]
      }
    }

    checkReceiver() {
      if(localStorage.getItem('receiver')!= null) {
        return +localStorage.getItem('receiver')
      }
    }

    checkDate() {
      const date = new Date(localStorage.getItem('documentDate')).toLocaleString('en-US');
      console.log(this.datePipe.transform(new Date(date)));
      if(localStorage.getItem('documentDate')!= null) {
        return this.datePipe.transform(new Date(date));
      }
    }

    public showProductModal(): void {
      this.productId = -1;
      console.log(this.productId);
      this.addEditProductModal.showModal();
    }
    
    public hideProductModal(): void {
      this.addEditProductModal.hideModal();
    }

    public showProductionProductModal(): void {
      this.productId = -1;
      this.produced = 1;
      console.log(this.productId);
      this.addEditProductionProductModal.showModal();
    }
    
    public hideProductionProductModal(): void {
      this.addEditProductionProductModal.hideModal();
    }

    productDelete(productId) {
      this.stockService.deleteProduct(productId).subscribe(data => {
        this.product = null;
        console.log(data);
        this.itemForm.reset();
this.stockItems = [];
this.itemForm.get('product').setValue(null);
this.entries = [];
        this.productionNoteItemForm.reset();
        this.itemForm.clearValidators();
        this.productionNoteItemForm.clearValidators();
        this.initItemForm();
        this.initProductionNoteItemForm();
        this.products = this.products.filter(product => {
          return product.id !== productId
      });
      })
    }

    productionProductDelete(productId) {
      this.stockService.deleteProduct(productId).subscribe(data => {
        this.product = null;
        console.log(data);
        this.itemForm.reset();
this.stockItems = [];
this.itemForm.get('product').setValue(null);
this.entries = [];
        this.itemForm.clearValidators();
        this.productionNoteItemForm.reset();
        this.productionNoteItemForm.clearValidators();
        this.initItemForm();
        this.initProductionNoteItemForm();
        this.producedProducts = this.producedProducts.filter(product => {
          return product.id !== productId
      });
      })
    }

    public productEdit(product) {
      // this.product = product
      this.productId = product.id
      // console.log(this.productId);
      this.addEditProductModal.showModal();
    }

    public productionProductEdit(product) {
      // this.product = product
      this.productId = product.id
      // console.log(this.productId);
      this.addEditProductionProductModal.showModal();
    }
    
    public addProductAction(ev) {
      console.log(ev);
      this.product = ev;
      this.addedProduct.emit(ev);
      this.itemForm.controls['product'].setValue(ev.id);
      this.itemForm.controls['quantity'].setValue(ev.quantity);
      this.itemForm.controls['unitPrice'].setValue(ev.cost);
      this.productUnit = this.product.unit;
      this.products.findIndex((product) => {
          if(product.id === ev.id) {
            return product = ev;
          }
      })
      this.mys.close();
      this.typeFilter2.nativeElement.value = ''
      this.subscription.add(this.stockService.getProducts().subscribe(data => {
        this.products = data.body.results;
        const productExist = data.body.results.some((p) => p.id === ev.id);
        if(!productExist) {
          this.products.unshift(ev);
        }
      }))
      this.addEditProductModal.hideModal();
    }
    
    public updateProductAction(ev) {
      const updatedProducts = this.products.map(product => {
        if (product.id === ev.id) {
          return ev;
        }
        return product;
      });
      this.products = updatedProducts.map(product => this.camelToSnake(product));
      console.log(this.products);
      this.updatedProduct.emit(ev);
      this.itemForm.controls['product'].setValue(ev.id);
      this.itemForm.controls['quantity'].setValue(ev.quantity);
      this.itemForm.controls['unitPrice'].setValue(ev.cost);
      const target = this.products.find((obj) => obj.id === ev.id);
      Object.assign(target, ev);
      this.mys.close();
      
      this.addEditProductModal.hideModal();
    }

    public addProductionProductAction(ev) {
      const filters = {
        produced: 1
      }
      console.log(ev);
      this.product = ev;
      this.addedProduct.emit(ev);
      this.productionNoteItemForm.controls['product'].setValue(ev.id);
      this.productionNoteItemForm.controls['quantity'].setValue(ev.quantity);
      this.productionNoteItemForm.controls['unitPrice'].setValue(ev.cost);
      this.productUnit = this.product.unit;
      this.producedProducts.findIndex((product) => {
          if(product.id === ev.id) {
            return product = ev;
          }
      })
      this.mys.close();
      this.typeFilter1.nativeElement.value = ''
      this.subscription.add(this.stockService.getProducts(filters).subscribe(data => {
        this.producedProducts = data.body.results;
        const productExist = data.body.results.some((p) => p.id === ev.id);
        if(!productExist) {
          this.producedProducts.unshift(ev);
        }
      }))
      this.addEditProductionProductModal.hideModal();
    }
    
    public updateProductionProductAction(ev) {
      console.log(ev);
      console.log(this.products);
      this.updatedProduct.emit(ev);
      this.productionNoteItemForm.controls['product'].setValue(ev.id);
      this.productionNoteItemForm.controls['quantity'].setValue(ev.quantity);
      this.productionNoteItemForm.controls['unitPrice'].setValue(ev.cost);
      const target = this.products.find((obj) => obj.id === ev.id);
      Object.assign(target, ev);
      this.mys.close();
      
      this.addEditProductionProductModal.hideModal();
    }

    public editParty(party: PartyModel): void {
      this.selectedParty = party;
      this.addEditPartyModal.showModal();
  }

  camelToSnake(obj) {
    const snakeObj = {};
  
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const snakeKey = key.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
        snakeObj[snakeKey] = obj[key];
      }
    }    
  
    return snakeObj;
  }

  public onDeleteParty(party: PartyModel, control): void {
    this.partyService.deleteParty(party.id).pipe(take(1)).subscribe(
        (): void => {
          this.billForm.controls[control].setValue(null);
          this.getParties();
          this.getOwnParties();
            // this.globalRegistry.reloadParties();
            this.toastyService.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
        }, (): void => {
            this.toastyService.success(this.translateService.instant('people.party.errorDeletingParty'));
        }
    );
}

  public addPartyModal(): void {
    this.selectedParty = null;
    this.addEditPartyModal.showModal();
  }

  public addParty(ev) {
    this.parties.push(ev);
    // this.globalRegistry.reloadParties();
    this.addEditPartyModal.hideModal();
}

 public addedOrUpdatedParty(ev) {
  const evArr = [ev];
  evArr.map(x => {
    const index = this.parties.findIndex(d=> d.id === x.id)
    this.parties[index] = x  
  });
  this.addEditPartyModal.hideModal();
}

    submitForm() {
      // if(this.bill.farm != null) {
      //   if(this.billForm.controls['farm'].value != this.bill.farm) {
      //     this.toastrService.error('Changing the farm is requesting to delete existing document items!');
      //     return
      //   }
      // }
      if(this.billForm.controls['documentId'].value == '*') {
        this.toastrService.error('Enter a valid document ID');
        return
      } else {
      this.updateDoc();
      if(this.resDocItemIds.length === 0) {
        this.toastrService.error('Add an item to proceed');
        return;
      }
    }
    }

    currentCurrency(event) {
      if(this.items.length > 0) {
       if(this.bill.currency != null && this.bill.currency != event.value) {
        this.billForm.get('currency').patchValue(this.bill.currency);
        this.toastyService.error(this.translateService.instant('stock.error.changeCurrencyError'));
        return
       }
      } 
      else {
        this.setCurrency(event.value);
        this.getExchangeRates(event.value);
        this.getExchangeUnits(event.value);
       }
      // console.log(this.globalRegistry.systemData.company);
    }

    setCurrency(id) {
      this.globalRegistry.systemData.units.forEach(element => {
        if(element.genre === '$') {
          element.children.forEach(e => {
            if(e.id == id) {
              this.selectedUnit = e;
            }
          });
        }
      })
      this.calculateValues();
    }

    deleteItem(itemId) {
      console.log(itemId);
      if(itemId) {
      this.stockService.deleteDocItem(itemId).subscribe(data => {
        console.log(data);
        this.items = this.items.filter(item => item.id != itemId);
        console.log(this.items);
        this.calculateValues();
        this.getDocItems(this.bill.id);
      }, (error) => {
        this.toastrService.error(error.error.results.error);
        return
      })
      }
    }

    deletePItem(itemId: number) {
      console.log(itemId);
      if(itemId) {
      this.stockService.deleteDocItem(itemId).subscribe(data => {
        console.log(data);
        this.p_items = this.p_items.filter(item => item.id != itemId);
        console.log(this.p_items);
        this.calculateValues();
      }, (error) => {
        this.toastrService.error(error.error.results.error);
        return
      })
      }
    }

    ngOnDestroy(): void {
      this.widgetService.setSize(12);
        this.subscription.unsubscribe();
        this.navService.editFarm.next(false);
        this.navService.createMod.next(false);
        this.navService.editMod.next(false);
        this.mapService.hideMap();
    }

    public getBill(billId) {
        this.subscription.add(this.stockService
            .getDoc(billId)
            .subscribe((response: ResponseModel<DocumentModel>): void => {
                this.bill = response.body.results;
                console.log(this.bill);
                this.getDocItems(this.bill.id)
                this.initForm();
                if(!this.billForm.get('currency').value) {
                  this.billForm.get('currency').setValue(this.defaultCurrency);
                }
            }));
    }

    getDocItems(id) {
      const p0 = {
        document: id,
        type: 0
    };
    const p1 = {
        document: id,
        type: 1
    }
    if(this.route.snapshot.queryParams.type !== 'invoice_in') {
      this.subscription.add(
        this.stockService.getDocument(p0).subscribe(data => {
          console.log(data.body.results);
          const items = [];
          if(data.body.results.length) {
          this.total = data.body.results[0].document.subtotal;
          this.totalVat = data.body.results[0].document.total_vat;
          this.grandTotal = data.body.results[0].document.grand_total;
          this.billForm.get('total').patchValue(this.transformDecimal(this.total));
          this.billForm.get('totalVat').patchValue(this.transformDecimal(this.totalVat));
          this.billForm.get('grandTotal').patchValue(this.transformDecimal(this.grandTotal));
          }
            data.body.results.forEach(element => {
            
              const obj = {
                id: element.id,
                product: element.resource_entry.product,
                currency: element.document.currency,
                quantity: +element.quantity,
                unitPrice: +element.cost,
                vatPercentage: +element.vat,
                totalPrice: +(+element.quantity * +element.cost).toFixed(this.globalRegistry.systemData.company.digits),
                vat: this.getVat(+element.quantity, +element.cost, +element.vat / 100),
                grandTotal: parseFloat((+element.quantity * +element.cost + this.getVat(+element.quantity, +element.cost, +element.vat / 100)).toFixed(this.globalRegistry.systemData.company.digits)),
                costOrig: +element.cost_orig.toFixed(this.globalRegistry.systemData.company.digits),
                info: element.info
              }
            items.push(obj); 
            this.resDocItemIds.push(obj.id);
            this.items = items;
          console.log(items);
          this.calculateValues();
            });
          }))
    }

    if(this.route.snapshot.queryParams.type == 'production_note' || this.route.snapshot.queryParams.type == 'estimation_note' || this.route.snapshot.queryParams.type == 'invoice_in') {
      this.subscription.add(
        this.stockService.getDocument(p1).subscribe(data => {
          console.log(data.body.results);
          const p_items = [];
          const items = [];
          if(data.body.results.length) {
          this.total = data.body.results[0].document.subtotal;
          this.totalVat = data.body.results[0].document.total_vat;
          this.grandTotal = data.body.results[0].document.grand_total;
          this.billForm.get('total').patchValue(this.transformDecimal(this.total));
          this.billForm.get('totalVat').patchValue(this.transformDecimal(this.totalVat));
          this.billForm.get('grandTotal').patchValue(this.transformDecimal(this.grandTotal));
          }
            data.body.results.forEach(element => {
            
              if(this.route.snapshot.queryParams.type === 'invoice_in') {
                const obj = {
                  id: element.id,
                  product: element.resource_entry.product,
                  currency: element.document.currency,
                  quantity: +element.quantity,
                  unitPrice: +element.cost.toFixed(this.globalRegistry.systemData.company.digits),
                  vatPercentage: +element.vat,
                  totalPrice: (element.quantity * element.cost_orig).toFixed(this.globalRegistry.systemData.company.digits),
                  vat: this.getVat(+element.quantity, +element.cost_orig, +element.vat / 100),
                  grandTotal: parseFloat((+element.quantity * +element.cost_orig + this.getVat(+element.quantity, +element.cost_orig, +element.vat / 100)).toFixed(this.globalRegistry.systemData.company.digits)),
                  costOrig: +element.cost_orig,
                  info: element.info
                }
              items.push(obj); 
              this.resDocItemIds.push(obj.id);
              this.items = items;
              console.log(items);
              } else {
                const obj = {
                  id: element.id,
                  product: element.resource_entry.product,
                  currency: element.document.currency,
                  quantity: +element.quantity,
                  unitPrice: +element.cost,
                  vatPercentage: +element.vat,
                  totalPrice: +(+element.quantity * +element.cost),
                  vat: this.getVat(+element.quantity, +element.cost, +element.vat / 100),
                  grandTotal: +(+element.quantity * element.cost) + this.getVat(+element.quantity, +element.cost, +element.vat / 100),
                  info: element.info
                }
                p_items.push(obj); 
                this.resDocItemIds.push(obj.id);
                this.p_items = p_items;
                console.log(p_items);
              }
            });

          this.calculateValues();
        })
      )
    }
  }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.billForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean =
            control.hasError(validationType) &&
            (control.dirty || control.touched);
        return result;
    }

    hideModal() {
      const elem = document.getElementById('custom_modal3');
      const parent = document.getElementById('parent');
      elem.classList.remove('active');
      parent.classList.remove('backdrop');
      this.units = this.globalRegistry.systemData.units;
      this.itemForm.reset();
this.stockItems = [];
this.itemForm.get('product').setValue(null);
this.entries = [];
      this.itemForm.clearValidators();
      this.initItemForm();
      this.product = null;
  }

  hideExchangeModal() {
    const elem = document.getElementById('custom_modal6');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.units = this.globalRegistry.systemData.units;
    this.addEditExchangeForm.reset();
    this.initExchangeForm();
    this.addEditExchangeForm.clearValidators();
}

  hideProductionModal() {
    const elem = document.getElementById('custom_modal4');
      const parent = document.getElementById('parent');
      elem.classList.remove('active');
      parent.classList.remove('backdrop');
      this.units = this.globalRegistry.systemData.units;
      this.productionNoteItemForm.reset();
      this.productionNoteItemForm.clearValidators();
      this.product = null;
  }

  hideProductionUsedModal() {
    const elem = document.getElementById('custom_modal5');
      const parent = document.getElementById('parent');
      elem.classList.remove('active');
      parent.classList.remove('backdrop');
      this.units = this.globalRegistry.systemData.units;
      this.productionNoteItemForm.reset();
      this.productionNoteItemForm.clearValidators();
      this.itemForm.reset();
this.stockItems = [];
this.itemForm.get('product').setValue(null);
this.entries = [];
      this.itemForm.clearValidators();
      this.initItemForm();
      this.entries = [];
      this.product = null;
  }

  showModal() {
    // this.updateDoc();
    console.log(this.bill);
    let d
    if(this.route.snapshot.queryParams.type == 'invoice_in' || this.route.snapshot.queryParams.type == 'inovice_out' || this.route.snapshot.queryParams.type == 'estimation_note') {
      d = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        farm: this.billForm.get('farm').value,
        currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
        exchange: this.currentExchange ? this.currentExchange.id : null,
      }
    } else if(this.route.snapshot.queryParams.type == 'internal_note') {
      d = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        farm: this.billForm.get('farm').value,
        farm_to : this.billForm.get('farmTo').value,
        currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
        exchange: this.currentExchange ? this.currentExchange.id : null,
      }
    } else if((this.route.snapshot.queryParams.from == 'activity')) {
      d = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        farm: this.billForm.get('farm').value,
        activity : +this.route.snapshot.queryParams.activityId ? +this.route.snapshot.queryParams.activityId : -1,
        currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
        exchange: this.currentExchange ? this.currentExchange.id : null,
      } 
    } else {
      d = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        farm: this.billForm.get('farm').value,
        currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency,
        exchange: this.currentExchange ? this.currentExchange.id : null,
      }
    }
    
    if(d.receive_date == 'Invalid date') {
      d.receive_date  = null;
    }

    if(this.bill) {
      d.type = this.bill.type;
    }
    if(this.billForm.get('farm').value == null) {
      this.toastyService.error(this.translateService.instant('stock.error.selectWarehouseError'));
      return
    }
        
    if(this.billForm.get('currency').value == null) {
      this.toastyService.error(this.translateService.instant('stock.error.selectCurrencyError'));
        return
    }

    if(this.route.snapshot.queryParams.type == 'internal_note') {
      if(!this.billForm.get('farm').value || !this.billForm.get('farmTo').value) {
        this.toastyService.error(this.translateService.instant('stock.error.selectWarehousesError'));
        return
      }
  }
    
      
        this.subscription.add(this.stockService.updateDocument(d).subscribe(data => {
          console.log(data);
          this.bill = data.body.results;
          this.initForm();
          this.reAnalyzeDoc();
        }))
      
     
  
  }

  showProductionModal() {
    console.log(this.bill);
    let d 
    if((this.route.snapshot.queryParams.from == 'activity')) {
      d = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        farm: this.billForm.get('farm').value,
        activity : +this.route.snapshot.queryParams.activityId ? +this.route.snapshot.queryParams.activityId : -1,
        currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency
      } 
    } else {
      d = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        farm: this.billForm.get('farm').value,
        currency: this.billForm.get('currency').value
      }
    }
    console.log(this.bill.farm);
    console.log(this.billForm.get('farm').value);
    
    if(d.receive_date == 'Invalid date') {
      d.receive_date  = null;
    }

    if(this.bill) {
      d.type = this.bill.type;
    }
    this.reAnalyzePDoc();
  
  }

  showProduction1Modal() {
    console.log(this.bill);
    const d = {
        id: this.bill ? this.bill.id : null,
        type: this.route.snapshot.queryParams.type,
        doc_id: this.billForm.get('documentId').value,
        doc_date: this.billForm.get('documentDate').value,
        party_from: this.billForm.get('partyFrom').value,
        party_to: this.billForm.get('partyTo').value,
        created_at: this.billForm.get('createdAt').value,
        updated_at: this.billForm.get('updatedAt').value,
        receive_by: this.billForm.get('receivedBy').value,
        receive_date: this.billForm.get('receivedDate').value,
        info: this.billForm.get('info').value,
        farm: this.billForm.get('farm').value,
        currency: this.billForm.get('currency').value ? this.billForm.get('currency').value : this.defaultCurrency
    }
    console.log(this.bill.farm);
    console.log(this.billForm.get('farm').value);
    
    if(d.receive_date == 'Invalid date') {
      d.receive_date  = null;
    }

    if(this.bill) {
      d.type = this.bill.type;
    }
    this.reAnalyzePUDoc();
  
  }

  reAnalyzeDoc() {
      const elem = document.getElementById('custom_modal3');
      const parent = document.getElementById('parent');
      elem.classList.add('active');
      parent.classList.add('backdrop');
  }

  reAnalyzePDoc() {
    const elem = document.getElementById('custom_modal4');
    const parent = document.getElementById('parent');
    elem.classList.add('active');
    parent.classList.add('backdrop');
    this.entries = [];
}

reAnalyzePUDoc() {
  const elem = document.getElementById('custom_modal5');
  const parent = document.getElementById('parent');
  elem.classList.add('active');
  parent.classList.add('backdrop');
}

  filterParties(value) {
    console.log(value);
    this.subscription.add(this.partyService.getParties({search: value}).subscribe(data => {
      console.log(data);
      this.partyToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.parties = data.body.results;
      if(this.partyToken) this.getMoreParties = true
    }))
  }

  filterOwnParties(value) {
    console.log(value);
    this.subscription.add(this.partyService.getParties({own: 1, search: value}).subscribe(data => {
      console.log(data);
      this.ownPartyToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.ownParties = data.body.results;
      if(this.ownPartyToken) this.getMoreOwnParties = true
    }))
  }

  filterProduct(value) {
    this.subscription.add(this.stockService.getProducts({search: value}).subscribe(data => {
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.products = data.body.results;
      if(this.nextToken) this.getMore = true
    }))
  }

  filterProductionProduct(value) {
    this.subscription.add(this.stockService.getProducts({search: value, produced: 1}).subscribe(data => {
      console.log(data);
      this.nextPToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.producedProducts = data.body.results;
      if(this.nextPToken) this.getMoreProduced = true
    }))
  }

  filterInvoices() {
    const url = this.stockService.getUrl('docs/');
    this.subscription.add(
      this.stockService.getDocs({type: 'contract'}).subscribe(data => {
        this.cacheService.delete(url+'type=contract');
          this.contractDocs = data.model;
          this.nextContractToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.nextContractToken) this.getMoreContracts = true;
        }))
  }

  setProduct(event) {
    if(event.value == undefined) return
    const productId = event.value;
    this.addEditOfferForm.get('product').setValue(productId);
    console.log(productId);
    this.subscription.add(this.stockService.getProduct(productId).subscribe(data => {
      this.product = data.body.results;
      console.log(this.product);
      if(this.product.unit) {
        this.productUnit = this.product.unit;
      }
      if(!this.product.cost) {
        this.itemForm.get('unitPrice').setValue(0);
        this.addEditOfferForm.get('price').setValue(0);
      } else {
        this.itemForm.get('unitPrice').setValue(this.product.cost.toFixed(this.globalRegistry.systemData.company.digits));
        this.addEditOfferForm.get('price').setValue(this.product.cost.toFixed(this.globalRegistry.systemData.company.digits));
      }
      if(!this.product.quantity) {
        this.itemForm.get('quantity').setValue(0);
        this.addEditOfferForm.get('quantity').setValue(0);
      } else {
        this.itemForm.get('quantity').setValue(this.product.quantity);
        this.addEditOfferForm.get('quantity').setValue(this.product.quantity);
      }
    }));
    this.getOffers(productId);
  }

  setOffer(offer) {
    console.log(offer);
    this.appliedOffer = offer
    setTimeout(() => {
      this.productionNoteItemForm.get('unitPrice').patchValue(offer.price);
      this.productionNoteItemForm.get('quantity').patchValue(offer.quantity);
    }, 500)
  }

  setProductionProduct(event) {
    if(event.value == undefined) return
    console.log('production note');
    this.getFilteredStockItems(event.value, this.bill.farm);
    const productId = event.value;
    console.log(productId);
    this.subscription.add(this.stockService.getProduct(productId).subscribe(data => {
      this.product = data.body.results;
      console.log(this.product);
      this.addEditOfferForm.get('product').setValue(this.product.id);
      if(this.product.unit) {
        this.productUnit = this.product.unit;
      }
      if(!this.product.cost) {
        this.productionNoteItemForm.get('unitPrice').setValue(0);
        this.addEditOfferForm.get('price').setValue(0);
      } else {
        this.productionNoteItemForm.get('unitPrice').setValue(this.product.cost);
        this.addEditOfferForm.get('price').setValue(this.product.cost);
      }
      if(!this.product.quantity) {
        this.productionNoteItemForm.get('quantity').setValue(0);
        this.addEditOfferForm.get('quantity').setValue(0);
      } else {
        this.productionNoteItemForm.get('quantity').setValue(this.product.quantity);
        this.addEditOfferForm.get('quantity').setValue(this.product.quantity);
      }
      if(this.product.producer) {
        this.addEditOfferForm.get('supplier').setValue(this.product.producer);
      }
    }));
    this.getOffers(productId);
  }

  setEntry(event) {
    console.log(event);
    if(event.value != null) {
      this.max = event.quantity;
    }
    if(this.entries.length) {
    this.selectedEntry = this.entries.filter(e => {
      return e.product.id == event.product.id;
    });
    console.log(this.selectedEntry);
    this.itemForm.controls['unitPrice'].setValue(this.selectedEntry[0].cost.toFixed(this.globalRegistry.systemData.company.digits));
    this.itemForm.controls['vat'].setValue(this.selectedEntry[0].vat);
    this.itemForm.controls['quantity'].setValue(this.selectedEntry[0].remains_quantity);
    this.stockUnit = this.selectedEntry[0].unit;
    this.max = this.selectedEntry[0].quantity;
    this.entryProductId = this.selectedEntry[0].product.id
    // console.log(event.value.quantity);
    }
  }

public showNameForm(event) {
  console.log(event.target.value);
  const elem = document.getElementById('custom_modal3');
  const parent = document.getElementById('parent');
  elem.classList.add('active');
  parent.classList.add('backdrop');
  this.itemForm.get('name').patchValue(event.target.value);
}

  public hideNameForm() {
    const elem = document.getElementById('custom_modal3');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.itemForm.reset();
    this.stockItems = [];
    this.itemForm.get('product').setValue(null);
this.entries = [];
    this.itemForm.clearValidators();
    this.productionNoteItemForm.reset();
    this.productionNoteItemForm.clearValidators();
    this.initItemForm();
    this.initProductionNoteItemForm();
    this.entries = [];
}

  initItemForm() {
    this.itemForm = new UntypedFormGroup({
      product: new UntypedFormControl(null, Validators.required),
      offer: new UntypedFormControl(null),
      info: new UntypedFormControl(null),
      quantity: new UntypedFormControl(null, Validators.required),
      unitPrice: new UntypedFormControl(null, Validators.required),
      vat: new UntypedFormControl(this.billForm.get('vat').value, Validators.required)
  });

  if(this.itemForm.get('product').value) {
    console.log('product selected');
  }
  }

  initExchangeForm() {
    this.addEditExchangeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl(moment.utc(new Date()).format('YYYY-MM-DD'), Validators.required),
      fromUnit: new UntypedFormControl(this.bill ? this.bill.currency : null, Validators.required),
      toUnit: new UntypedFormControl(this.defaultCurrency ? this.defaultCurrency : null, Validators.required),
      fromQty: new UntypedFormControl(1, Validators.required),
      toQty: new UntypedFormControl(1, Validators.required)
    })
  }

  initOfferForm() {
    this.addEditOfferForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl(moment.utc(new Date()).format('YYYY-MM-DD'), Validators.required),
      expire: new UntypedFormControl(moment.utc(new Date()).format('YYYY-MM-DD'), Validators.required),
      product: new UntypedFormControl(null, Validators.required),
      supplier: new UntypedFormControl(null, Validators.required),
      supplierCode: new UntypedFormControl(null),
      supplierUrl: new UntypedFormControl(null),
      supplierEmail: new UntypedFormControl(null),
      price: new UntypedFormControl(null, Validators.required),
      quantity: new UntypedFormControl(1, Validators.required),
      subscription: new UntypedFormControl(null),
      document: new UntypedFormControl(this.bill ? this.bill.id : null)
    })
  }

  initProductionNoteItemForm() {
    this.productionNoteItemForm = new UntypedFormGroup({
      product: new UntypedFormControl('', Validators.required),
      offer: new UntypedFormControl(''),
      info: new UntypedFormControl(null),
      entry: new UntypedFormControl(''),
      quantity: new UntypedFormControl(null, Validators.required),
      unitPrice: new UntypedFormControl(null, Validators.required),
      vat: new UntypedFormControl(this.billForm.get('vat').value, Validators.required)
  });

  if(this.productionNoteItemForm.get('product').value) {
    console.log('product selected');
  }
  }

  getVat(quantity: number, unitPrice: number, vat: number): number {
    const value = quantity * unitPrice;
    const vatValue = vat * value
    return +vatValue.toFixed(this.globalRegistry.systemData.company.digits);
  }

  addRow() {
    if((this.route.snapshot.queryParams.type == 'invoice_in' || this.route.snapshot.queryParams.type == 'estimation_note') && +this.itemForm.get('quantity').value == 0) {
      this.toastyService.error(this.translateService.instant('stock.error.inputValidQtyError'));
      return
    }
    
    if((this.route.snapshot.queryParams.type == 'invoice_out' || this.route.snapshot.queryParams.type == 'internal_note' || this.route.snapshot.queryParams.type == 'production_note' || this.route.snapshot.queryParams.type == 'estimation_note') && +this.itemForm.get('quantity').value == 0) {
      this.toastyService.error(this.translateService.instant('stock.error.inputValidQtyRError'));
      return
    }
    
    if(+this.itemForm.get('vat').value == 0 || this.itemForm.get('vat').value == null) {
      this.toastyService.error(this.translateService.instant('stock.error.inputValidVATError'));
      return
    }
    console.log(this.productUnit);
    console.log(this.product);
    if(this.itemForm.get('product').value == '' || this.itemForm.get('product').value == null) {
      return
    }
  
    if(this.route.snapshot.queryParams.type == 'invoice_in') {
      const totalPrice = +(this.itemForm.get('quantity').value * this.itemForm.get('unitPrice').value);
      const vat = totalPrice +this.itemForm.get('vat').value / 100

      const obj = {
        id: this.bill.id,
        product: this.product,
        quantity: +this.itemForm.get('quantity').value,
        unitPrice: +this.product.cost / +this.exchangeRate,
        vatPercentage: +this.itemForm.get('vat').value,
        totalPrice,
        vat,
        grandTotal: +(+totalPrice + +vat),
        costOrig: +this.itemForm.get('unitPrice').value,
        info: this.itemForm.get('info').value
      }
      console.log(obj);
      
      const docItem =  {
        document: this.bill.id, 
        resource_entry: {
          product: obj.product.id, 
          unit: obj.product.unit, 
          cost: +obj.costOrig.toFixed(this.globalRegistry.systemData.company.digits), 
          vat: obj.vatPercentage.toFixed(this.globalRegistry.systemData.company.digits), 
          info: ''
        }, 
        quantity: obj.quantity,
        cost: +obj.costOrig.toFixed(this.globalRegistry.systemData.company.digits), 
        cost_orig: +obj.costOrig.toFixed(this.globalRegistry.systemData.company.digits),
        vat: obj.vatPercentage.toFixed(this.globalRegistry.systemData.company.digits),
        type: 1,
        info: obj.info
      }
      this.subscription.add(this.stockService.createDocumentItem(this.bill.id, docItem, 1).subscribe((data) => {
        console.log(data);
        this.getDocItems(this.bill.id);
        this.hideNameForm();
        this.calculateValues();
        this.product = null;
        this.itemForm.reset();
        this.stockItems = [];
        this.itemForm.get('product').setValue(null);
        this.entries = [];
        this.itemForm.clearValidators();
        this.initItemForm();
      }, (error) => {
        console.log(error);
        this.toastrService.error(error.error.results.error);
      }))
    }
    if(this.route.snapshot.queryParams.type == 'internal_note' || this.route.snapshot.queryParams.type == 'production_note' || this.route.snapshot.queryParams.type == 'invoice_out') {
      const totalPrice = +(this.itemForm.get('quantity').value * this.itemForm.get('unitPrice').value);
      const vat = totalPrice +this.itemForm.get('vat').value / 100
      const obj = {
        id: this.bill.id,
        resource: this.itemForm.get('product').value,
        quantity: +this.itemForm.get('quantity').value,
        unitPrice: +this.itemForm.get('unitPrice').value,
        vatPercentage: +this.itemForm.get('vat').value,
        totalPrice,
        vat,
        grandTotal: +(+totalPrice + +vat),
        info: this.itemForm.get('info').value,
      }
      console.log(obj);
      const docItem =  {
        document: this.bill.id, 
        resource_entry: {
          id: obj.resource,
          unit: this.stockUnit,
          product: this.entryProductId
        }, 
        quantity: obj.quantity,
        cost: +(this.itemForm.get('unitPrice').value),
        vat: +this.itemForm.get('vat').value,
        cost_orig: +(this.itemForm.get('unitPrice').value),
        type: 0,
        info: obj.info
      }
      this.subscription.add(this.stockService.createDocumentItem(this.bill.id, docItem, 0).subscribe(data => {
        console.log(data);
        this.resDocItemIds.push(data.body.results.id);
        this.getDocItems(this.bill.id);
        this.hideNameForm();
        this.calculateValues();
        this.product = null;
        this.stockUnit = null;
        this.entryProductId = null;
        this.itemForm.reset();
        this.stockItems = [];
        this.itemForm.get('product').setValue(null);
        this.entries = [];
        this.itemForm.clearValidators();
        this.initItemForm();
      }, (error) => {
        this.toastrService.error(error.error.results.error);
      }))
    }
    
  }

  addPRow(type:number) {
    
    if(this.productionNoteItemForm.get('quantity').value == 0) {
      this.toastyService.error(this.translateService.instant('stock.error.inputValidQtyRError'));
      return
    }
    
    if(+this.productionNoteItemForm.get('vat').value == 0 || this.productionNoteItemForm.get('vat').value == null) {
      this.toastyService.error(this.translateService.instant('stock.error.inputValidVATError'));
      return
    }
    console.log(this.productUnit);
    console.log(this.product);
    if(this.productionNoteItemForm.get('product').value == '' || this.productionNoteItemForm.get('product').value == null) {
      return
    }
    if(this.route.snapshot.queryParams.type == 'production_note' || this.route.snapshot.queryParams.type == 'estimation_note') {
      const totalPrice = +(this.productionNoteItemForm.get('quantity').value * this.productionNoteItemForm.get('unitPrice').value);
      const vat = totalPrice +this.productionNoteItemForm.get('vat').value / 100
      const obj = {
        id: this.bill.id,
        resource: this.productionNoteItemForm.get('entry').value,
        product: this.product,
        quantity: +this.productionNoteItemForm.get('quantity').value,
        unitPrice: +this.productionNoteItemForm.get('unitPrice').value,
        vatPercentage: +this.productionNoteItemForm.get('vat').value,
        totalPrice,
        vat,
        grandTotal: +(+totalPrice + +vat),
        info: this.itemForm.get('info').value,
      }
      console.log(obj);
      const docItem =  {
        document: this.bill.id, 
        resource_entry: {
          id: obj.resource ? obj.resource : null,
          product: obj.product.id,
          unit: obj.product.unit,
        }, 
        quantity: obj.quantity,
        cost: obj.unitPrice.toFixed(this.globalRegistry.systemData.company.digits),
        vat: +this.productionNoteItemForm.get('vat').value,
        type: type,
        info: obj.info
      }
      this.subscription.add(this.stockService.createDocumentItem(this.bill.id, docItem, type).subscribe(data => {
        console.log(data);
        this.resDocItemIds.push(data.body.results.id);
        this.getDocItems(this.bill.id);
        this.hideProductionModal();
        this.hideProductionUsedModal();
        this.calculateValues();
        this.product = null;
        this.productionNoteItemForm.reset();
        this.productionNoteItemForm.clearValidators();
      }, (error) => {
        this.toastrService.error(error.error.results.error);
      }))
    }
    console.log(this.items);
    this.productionNoteItemForm.reset();
    this.productionNoteItemForm.clearValidators();
    Object.keys(this.productionNoteItemForm.controls).forEach((key) => {
      this.productionNoteItemForm.get(key).setErrors(null);
    });
    
  }

  calculateValues() {
    this.initItemForm();
    this.initProductionNoteItemForm();
    // this.total = 0;
    // this.totalVat = 0;
    // this.grandTotal = 0;
    // this.items.forEach(item => {
    //   this.total+= item.quantity * item.unitPrice;
    //   this.totalVat+= this.getVat(item.quantity, item.unitPrice, item.vatPercentage / 100);
    // });
    // this.grandTotal = +this.total + +this.totalVat;
    // this.billForm.get('total').patchValue(this.transformDecimal(this.total));
    // this.billForm.get('totalVat').patchValue(this.transformDecimal(this.totalVat));
    // this.billForm.get('grandTotal').patchValue(this.transformDecimal(this.grandTotal));
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof UntypedFormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }
  
  isFieldValid(field: string) {
    return !this.productForm.get(field).valid && this.productForm.get(field).touched;
  }

  getStockItems() {
    this.subscription.add(this.stockService.getStockItems().subscribe((data: ResponseModel<StockItemModel[]>) => {
      this.stockItems = data.body.results;
      console.log(data.body.results);
    }))
  }

  getFilteredStockItems(productId, farmId) {
    this.stockItems = [];
    const obj = {
      product: productId,
      farm: farmId
    }
    this.subscription.add(this.stockService.getStockResources(obj).subscribe((data: ResponseModel<StockResourceModel[]>) => {
      this.entries = data.body.results;
      console.log(data.body.results);
    }))
  }

  getStockResources(stockId) {
    console.log(stockId)
    this.subscription.add(this.stockService.getStockResourceEntry(stockId).subscribe((data: ResponseModel<StockResourceModel[]>) => {
      this.entries = data.body.results;
      console.log(this.entries);
      if(this.entries.length > 0) {
        this.itemForm.controls['product'].setValue(this.entries[0].id);
        // this.productionNoteItemForm.controls['entry'].setValue(this.entries[0].id);
        this.itemForm.controls['unitPrice'].setValue(this.entries[0].cost);
        this.itemForm.controls['vat'].setValue(this.entries[0].vat);
        this.itemForm.controls['quantity'].setValue(this.entries[0].remains_quantity);
        this.stockUnit = this.entries[0].unit;
        this.max = this.entries[0].quantity;
        this.entryProductId = this.entries[0].product.id;
        this.setEntry(this.entries[0]);
      } else {
        this.itemForm.reset();
        this.stockItems = [];
        this.itemForm.get('product').setValue(null);
        this.entries = [];
        this.itemForm.clearValidators();
        this.productionNoteItemForm.reset();
        this.productionNoteItemForm.clearValidators();
        this.initItemForm();
        this.initProductionNoteItemForm();
        this.stockUnit = null;
        this.max = null;
      }
    }));
  }

  public getProducts() {
    const url = this.stockService.getUrl('products/');
    this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword);
    this.subscription.add(this.stockService.getProducts({ limit: this.limit, search: this.searchKeyword }).subscribe((data): void => {
      console.log(data);
      this.products = data.body.results;
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      if(this.nextToken) this.getMore = true;
    }))
  }

  getProductionProducts() {
    const url = this.stockService.getUrl('products/');
    this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword+'&produced=1');
    this.subscription.add(this.stockService.getProducts({ limit: this.limit, search: this.searchKeyword, produced: 1 }).subscribe((data): void => {
      console.log(data);
      this.producedProducts = data.body.results;
      this.nextPToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      if(this.nextPToken) this.getMoreProduced = true;
    }))
  }

  scrollParties() {
    const url = this.partyService.getUrl('');
    console.log('parties scrolled');
    if(this.getMoreParties) {
    this.partyToken &&
    this.subscription.add(this.partyService.getParties(this.partyToken).subscribe((data) => {
      this.cacheService.delete(url+'limit='+this.partyToken.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search);
        console.log(data);
        if(this.parties) {
          this.parties = [...this.parties, ...data.body.results];
        } else {
          this.parties = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreParties = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.partyToken.offset != params['offset']) {
          this.partyToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrollOwnParties() {
    const url = this.partyService.getUrl('');
    console.log('parties scrolled');
    if(this.getMoreOwnParties) {
      this.ownPartyToken &&
        this.subscription.add(this.partyService.getParties(this.ownPartyToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.partyToken.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search+'&own=1');
          console.log(data);
          if(this.ownParties) {
            this.ownParties = [...this.ownParties, ...data.body.results];
          }
          if(data.body.next == null) {
            this.getMoreOwnParties = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.ownPartyToken.offset != params['offset']) {
            this.ownPartyToken = {limit: params['limit'], offset: params['offset'], search: params['search'], own: 1};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.scrolledDown();
    }
}

onPScroll(event: any) {
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    this.scrolledDownProducedProducts();
  }
}

scrollPersons() {
  const url = this.companyService.getUrl('products/');
  console.log('person scrolled');
  if(this.getMorePersons) {
    this.personToken &&
      this.subscription.add(this.companyService.getPersons(this.personToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.personToken.limit+'&offset='+this.personToken.offset+'&search='+this.personToken.search+'&own=1');
        console.log(data);
        if(this.persons) {
          this.persons = [...this.persons, ...data.body.results];
        } else {
          this.persons = data.body.results;
        }
        if(data.body.next == null) {
          this.getMorePersons = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.personToken.offset != params['offset']) {
          this.personToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
}

onPersonScroll(event: any) {
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    this.scrollPersons();
  }
}

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  scrolledDown() {
    const url = this.stockService.getUrl('products/');
    console.log('scrolled');
    if(this.getMore) {
    this.nextToken &&
      this.subscription.add(this.stockService.getProducts(this.nextToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search);
        console.log(data);
        if(this.products) {
          this.products = [...this.products, ...data.body.results];
        } else {
          this.products = data.body.results;
        }
        if(data.body.next == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  scrolledDownProducedProducts() {
    const url = this.stockService.getUrl('products/');
    console.log('scrolled');
    const params = this.nextPToken;
    Object.assign(params, {produced: 1});
    if(this.getMoreProduced) {
    this.nextPToken && 
      this.subscription.add(this.stockService.getProducts(params).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextPToken.limit+'&offset='+this.nextPToken.offset+'&search='+this.nextPToken.search+'&produced=1');
        console.log(data);
        if(this.producedProducts) {
          this.producedProducts = [...this.producedProducts, ...data.body.results];
        } else {
          this.producedProducts = data.body.results;
        }
        if(data.body.next == null) {
          this.getMoreProduced = false;
          return
        } else {
          const url = data.body.next.split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          console.log(params);
          if(this.nextPToken.offset != params['offset']) {
          this.nextPToken = {limit: params['limit'], offset: params['offset'], search: params['search'], produced: 1};
          } else {
            return
          }
        }
      }));
    } else {
      return
    }
  }

  public selectResourceType(resource?: ResourceTypeModel, parent?:  ResourceTypeModel): void {
    this.selectedResource = resource;
    this.parentResource = parent ? parent : null;
    this.addResourceTypeModal.showModal();
}

public deleteResourceType(resourceId?: number): void {
  console.log(resourceId);
  this.typesService.deleteResourceType(resourceId).subscribe(data => {
    console.log(data);
  });
}

public selectUnit(unit?: UnitModel, parent?:  UnitModel, genre?: string): void {
  if(unit?.locked) {
    return;
}
  this.currentUnit = unit;
  this.parentUnit = parent ? parent : null;
  if(genre) {
    console.log(genre);
    this.units = this.globalRegistry.systemData.units.filter(unit => {
      return unit.genre == genre
    });
  }
  // if(!unit && !parent) {
  //   this.mySelect.close();
  //   this.units = this.globalRegistry.systemData.units;
  // }
  this.initunitForm();
  this.addEditUnitModal.showModal();
}

public onDeleteUnit(unit: UnitModel): void {
  if(unit?.locked) {
    return;
}
 
  this.confirmModalService.openConfirmModal(
      this.translateService.instant('confirmDelete'),
      this.translateService.instant('units.deleteUnitConfirmMessage')
  );
  this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
      if (confirmed) {
          this.deleteUnit(unit.id);
      }
  });
}

public onUnitAction(unitId: number): void {
  this.selectedUnitId = unitId;
  this.addEditUnitModal.hideModal();
}

getTranslation(translation) {
  const t =this.translatedNames.filter(trans => {
    return trans.id === translation
  });
  if(t[0]) {
    return t[0][this.langString];
  } else {
    return translation
}
}

public getParties() {
    const url = this.partyService.getUrl('');
    this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword);
    this.subscription.add(this.partyService.getParties({ limit: this.limit, search: this.searchKeyword }).subscribe((data: ResponseModel<PartyModel[]>): void => {
      console.log(data);
      this.parties = data.body.results;
      this.partyToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      if(this.partyToken) this.getMoreParties = true;
    }))
}

public getOwnParties() {
  const url = this.partyService.getUrl('');
  this.cacheService.delete(url+'limit='+this.limit+'&own=1&search='+this.searchKeyword);
  this.subscription.add(this.partyService.getParties({ limit: this.limit, own: 1, search: this.searchKeyword }).subscribe((data: ResponseModel<PartyModel[]>): void => {
    console.log(data);
    this.ownParties = data.body.results;
    this.ownPartyToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
          if(this.ownPartyToken) this.getMoreOwnParties = true;
  }))
}

public getPersons() {
  const url = this.companyService.getUrl('persons/');
  this.cacheService.delete(url+'limit='+this.limit);
  this.subscription.add(this.companyService.getPersons({ limit: this.limit }).subscribe((data: ResponseModel<PersonModel[]>): void => {
    console.log(data);
    this.persons = data.body.results;
    this.personToken = data.body.next
          ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
          : null;
      if(this.personToken) this.getMorePersons = true;
  }))
}

public submit(): void {

  if(this.unitForm.dirty) {
      const unit: UnitInterface = {
          name: this.unitForm.controls['name'].value,
          shortName: this.unitForm.controls['shortName'].value,
          genre: this.unitForm.controls['genre'].value
      };

      if(typeof this.unitForm.controls['parent'].value !== 'string') {
          unit.parent = this.unitForm.controls['parent'].value === 'new' ?
              null :
              this.unitForm.controls['parent'].value;
      }

      if(this.currentUnit) {
          this.updateUnit({...{id: this.currentUnit.id}, ...unit});
          this.globalRegistry.reloadUnits();
      }
      else {
          this.addUnit(unit);
          this.globalRegistry.reloadUnits();
      }
      this.getUnits()
  }
}

public getExchangeUnits(unit) {
  const params = {
    genre: '$',
    exchange: unit
  }
  this.subscription.add(this.unitService.getUnits(params).subscribe(data => {
    this.exchangeCurrencies = data.model;
  }))
}

public getUnits() {
  this.subscription.add(this.unitService.getUnits().subscribe(data => {
    this.units = data.model;
  }))
}

private initunitForm(): void {
  this.unitForm = this.formBuilder.group({
      name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(100)
          ])
      ],
      shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(100)
          ])
      ],
      parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
              Validators.required
          ])
      ],
      genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
              Validators.required
          ])
      ]
  });
}

private addUnit(unit: UnitInterface): void {
  this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
      this.toastyService.success(this.translateService.instant('units.unitAddSuccessfull'));
      this.globalRegistry.reloadUnits();
      this.onUnitAction(response.model.id);
  },
  (): void => {
      this.toastyService.error(this.translateService.instant('units.unitAddError'));
  });
}

private updateUnit(unit: UnitInterface): void {
  this.unitService.updateUnit(unit).subscribe((): void => {
      this.toastyService.success(this.translateService.instant('units.unitUpdateSuccessfull'));
      this.globalRegistry.reloadUnits();
      this.onUnitAction(this.currentUnit.id);

  },
  (): void => {
      this.toastyService.error(this.translateService.instant('units.UnitUpdateError'));
  });
}

public deleteUnit(unitId: number): void {
  this.unitService.deleteUnit(unitId).subscribe((): void => {
      this.toastyService.success(this.translateService.instant('unit.unitDeleteSuccessfull'));
      this.globalRegistry.reloadUnits();
  },
  (): void => {
      this.toastyService.error(this.translateService.instant('unit.UnitDeleteError'));
  });
}

public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
  const control: any = this.unitForm.controls[controlName];
  if (!control) {
      return false;
  }

  const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
  return result;
}

public getExchangeRates(unitTo:number, unitFrom?:number, pdate?:any) {
  this.exchangeRate = 0
  let date = '';
  if(document) {
    date = moment.utc(this.bill.doc_date).format('YYYY-MM-DD')
  } else {
    date = moment.utc(new Date()).format('YYYY-MM-DD');
  }
  console.log(unitTo);
  console.log(this.defaultCurrency);
  if(this.defaultCurrency!= null && this.defaultCurrency != unitTo) {
    console.log("called")
  const params = {
    date: pdate ? pdate : date,
    unit_from: unitFrom ? unitFrom : this.defaultCurrency,
    unit_to: unitTo
  }
  const url = this.stockService.getUrl(`exchange/date=${params.date}&unit_from=${params.unit_from}&unit_to=${params.unit_to}`);
  this.cacheService.delete(url);
  this.subscription.add(this.stockService.getExchange(params).subscribe(data => {
    this.rates = data.body.results;
    if(this.rates.length) {
    this.billForm.get('exchange').patchValue(this.rates[0]);
    this.exchangeRate = this.rates[0].to_qty / this.rates[0].from_qty;
    this.currentExchange = this.rates[0];
    this.calculateValues();
    console.log(this.exchangeRate);
    }
  }))
} else {
  this.rates = [];
}
}

public getOffers(productId:number, pdate?:any) {
  const date = moment.utc(new Date()).format('YYYY-MM-DD');
  const params = {
    date: pdate ? pdate : date,
    product: productId
  }
  this.subscription.add(this.stockService.getOffers(params).subscribe(data => {
    if(data) {
      this.offers = data.body.results;
      if(this.offers.length) {
        this.itemForm.get('offer').setValue(this.offers[0].id);
        this.productionNoteItemForm.get('offer').setValue(this.offers[0].id);
        this.setOffer(this.offers[0]);
      }
    }
  }))
}

public setExchange(ev) {
  console.log(ev.value);
  this.exchangeRate = ev.value.to_qty / ev.value.from_qty;
    console.log(this.exchangeRate);
    this.currentExchange = ev.value;
    this.calculateValues();
}

public showAddExchangeModal() {
  this.myS.close();
  const elem = document.getElementById('custom_modal6');
  const parent = document.getElementById('parent');
  elem.classList.add('active');
  parent.classList.add('backdrop');
}

public showOfferModal(selector) {
  if(selector == 'mys1') {
    this.mys1.close();
  }
  if(selector == 'mys2') {
    this.mys2.close();
  }
  const elem = document.getElementById('custom_modal7');
  const parent = document.getElementById('parent');
  elem.classList.add('active');
  parent.classList.add('backdrop');
}

public hideOfferModal() {
  const elem = document.getElementById('custom_modal7');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.units = this.globalRegistry.systemData.units;
    this.addEditOfferForm.reset();
    this.addEditOfferForm.clearValidators();
    this.addEditOfferForm.markAsUntouched();
    this.initOfferForm();
}

public addExchange() {
  const date = moment.utc(this.addEditOfferForm.get('date').value).format('YYYY-MM-DD');
  this.addEditExchangeForm.get('date').patchValue(date);
  console.log(this.addEditExchangeForm.value);
  this.subscription.add(this.stockService.addExchange(this.addEditExchangeForm.value).subscribe(data => {
    console.log(data);
    if(data) {
      this.billForm.get('currency').patchValue(data.body.results.to_unit);
      this.setCurrency(data.body.results.to_unit);
      this.getExchangeRates(data.body.results.to_unit, data.body.results.from_unit, data.body.results.date);
    }
    this.hideExchangeModal();
  }))
}

public addOffer() {
  console.log(this.addEditOfferForm.value);
  if(this.addEditOfferForm.invalid) {
    this.addEditOfferForm.markAsTouched();
    this.toastrService.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
    return
  }
  const date = moment.utc(this.addEditOfferForm.get('date').value).format('YYYY-MM-DDThh:mm');
  const expire = moment.utc(this.addEditOfferForm.get('expire').value).format('YYYY-MM-DDThh:mm');
  this.addEditOfferForm.get('date').patchValue(date);
  this.addEditOfferForm.get('expire').patchValue(expire);
  this.subscription.add(this.stockService.addOffer(this.addEditOfferForm.value).subscribe(data => {
    console.log(data);
    if(data) {
      this.getOffers(data.body.results.product, data.body.results.date);
    }
    this.hideOfferModal();
    this.addEditOfferForm.reset();
    this.addEditOfferForm.clearValidators();
    this.addEditOfferForm.markAsUntouched();
  }))
}

// public rateEdit(rate) {

// }

// public rateDelete(rate) {

// }

public updateEInvoice() {
  this.stockService.updateEInvoice(this.bill.id).subscribe(
    data => {
      window.opener.postMessage(data, '*');
      window.close();
    },
    (err) => {
      if (err.error.results.code === -109) {
        let token = localStorage.getItem('auth_token');
        let url = this.billingService.getUrl('anaf/access/?token=' + token);
        const childWindow = window.open(url, 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=300,left=300,top=300');
      }
    }
  );
}

}
