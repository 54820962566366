import { ListWidgetInterface } from '../data/list-widget.inerface';
import {
    Component,
    Input
} from '@angular/core';

@Component({
    selector: 'fap-list-widget',
    templateUrl: './fap_list-widget.component.html',
    styleUrls: ['./fap_list-widget.component.scss']
})
export class FapListWidgetComponent {

    @Input()
    public widgetData: ListWidgetInterface;
}
