import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { NavService } from '../../../../shared/services/nav.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { Router } from '@angular/router';
import { UserService } from '../../../../core/services/api/user/user.service';
import { ThemeService } from '../../../../core/services/theme/theme.service';
import { AttributeService } from '../../../../core/services/api/attribute/attribute.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { FapAPIRequestOptions } from '../../../../core/services/api/fap-api-request.options';
import { MapService } from '../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    selector: 'allergy-profile',
    templateUrl: './allergy-profile.component.html',
    styleUrls: ['./allergy-profile.component.scss'],
})
export class AllergyProfileComponent implements OnInit {
    public entityInfo = null;
    public objectId = 20;
    public isDynamic = false;

    public subscriptions: Array<Subscription> = [];
    public mediaUrl = environment.mediaUrl;
    public translatedNames: any = [];
    public langString: string;
    public currentUser;
    public workflow;
    public formTypeId;
    public currentStep = 0;
    public totalSteps = 0

    public nextFormClick = false;
    public previousFormClick = false;

    public theme;

    constructor(
        public navService: NavService,
        public globalRegistry: GlobalRegistryService,
        public typesService: TypesService,
        public cacheService: CacheResolverService,
        public router: Router,
        public userService: UserService,
        public navServices: NavService,
        public themeService: ThemeService,
        public attributeService: AttributeService,
        public toastrService: ToastrService,
        public translate: TranslateService,
        public http: HttpClient,
        public mapService: MapService
    ) {
      // alert('The token is: '+ localStorage.getItem('auth_token') + '. And language is '+ localStorage.getItem('language'));
      this.globalRegistry.reloadTranslations();
      this.userService.getUser().subscribe(response => {
        this.currentUser = response.body.results;
        this.userService.setCurrentUser(this.currentUser);
        this.userService.setTheme(this.currentUser.profile.data.ui.theme);
        if(this.currentUser.id) {
          this.getWorkflow();
        }
    });
    }

    ngOnInit(): void {
        if (!localStorage.getItem('auth_token')) {
            this.router.navigate(['/overview']);
            return;
        }
        this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
        this.mapService.hideMap();
    }

    public get width() {
        return window.innerWidth;
    }

    public getFormTypes() {
        this.typesService.getFormTypeById(this.formTypeId).subscribe((data) => {
          console.log(data);
            this.entityInfo = data.body.results;
        });
    }

    public getWorkflow() {
      const param = {
        unique_type : 'WORKFLOW_ALERGIC',
        id: this.currentUser.id
      }
      this.attributeService.getWorkflow(param).subscribe(data => {
        this.workflow = data.body.results[0];
        this.objectId = this.workflow.object_id;
        if(this.workflow.current_form) {
          this.formTypeId = this.workflow.current_form.type;
          this.getFormTypes();
        }
        this.currentStep = this.workflow.step;
        this.totalSteps = this.workflow.steps_count;
        if(this.currentStep > this.totalSteps) {
          this.currentStep = 0
        }
        this.isDynamic = true;
      })
    }

    nextButtonClick(value) {
      if(value) {
        this.goToNextForm();
      }
    }

    hideModal() {
      console.log('hide modal triggered');
    }

    onUpdateAction() {
      console.log('update action triggered');
    }

    goToPreviousForm() {
      this.attributeService.getPreviousForm(this.workflow.id).subscribe(data => {
        this.workflow = data.body.results;
        this.objectId = this.workflow.object_id;
        if(this.workflow.current_form) {
          this.formTypeId = this.workflow.current_form.type;
          this.getFormTypes();
        }
        this.currentStep = this.workflow.step;
        this.isDynamic = true;
        this.previousFormClick = false;
      })
    }

    goToNextForm() {
      this.attributeService.getNextForm(this.workflow.id).subscribe(data => {
        this.workflow = data.body.results;
        this.objectId = this.workflow.object_id;
        if(this.workflow.current_form) {
          this.formTypeId = this.workflow.current_form.type;
          this.getFormTypes();
        }
        this.currentStep = this.workflow.step;
        if(this.currentStep > this.totalSteps) {
          this.toastrService.success(this.translate.instant('user.profile.profileUpdatedSuccessfully'));
          this.currentStep = 0
        }
        this.isDynamic = true;
        this.nextFormClick = false;
      })
    }

}
