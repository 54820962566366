<div class="relation-wrap" id="parent">
    <div class="attr-header">
        <h2 class="relation-title">{{ 'attribute.formEdit' | translate }}</h2>
        <span (click)="closeRelationsModal()"></span>
    </div>
    <div class="dynamic_forms">
        <form [formGroup]="attributeForm" class="relation-form">
            <!-- <mat-form-field class="w-100">
                <mat-label>{{ 'attribute.name' | translate }}</mat-label>
                <input
                    matInput
                    formControlName="name"
                    placeholder="{{ 'attribute.name' | translate }}">
            </mat-form-field> -->
            <div class="form-group w-100 m-0">
                <mat-form-field class="w-100" (click)="addEditFormTranslation()">
                    <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput1>
                    <mat-error *ngIf="attributeForm.controls['name'].errors?.required">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field disabled class="w-100">
                <mat-label>{{ 'attribute.attributes' | translate }}</mat-label>
                <mat-select required formControlName="fields" multiple (selectionChange)="collectAttributes($event)">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                    <mat-form-field class="w-100" appearance="fill">
                        <input matInput #attributeNameFilter placeholder="Search">
                    </mat-form-field>
                    <em class="fa fa-plus center f-20 ml-2" (click)="addEditAttribute()"></em>
                </div>
                <div class="scroll_wrap" style="padding-left: 10px;">
                <mat-option style="height: 0!important;"></mat-option>
                    <mat-option style="margin: 0;" class='row' *ngFor="let attribute of globalRegistry?.systemData?.attributes | filter : attributeNameFilter.value" [value]='attribute?.id'>
                        <div class="attr-type-name">{{attribute.name}}</div>
                        <div class="attr-type-actions">
                            <i class="fa fa-edit center f-20" (click)="addEditAttribute(attribute); $event.preventDefault(); $event.stopPropagation()"></i>
                        </div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
            <div class="selected_values" *ngIf="fields.length > 0 ">
                <div class="value_table">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Value type</th>
                                <th>Input type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let field of fields">
                                <td>{{field?.id}}</td>
                                <td>{{field?.name}}</td>
                                <td>{{field?.valueType ? field?.valueType : field?.value_type}}</td>
                                <td>{{field?.inputType ? field?.inputType : field?.input_type}}</td>
                                <td>
                                    <div class="actions_btn">
                                        <i class="fa fa-edit center f-20" (click)="addEditAttribute(field); $event.preventDefault(); $event.stopPropagation()"></i>
                                        <i class="fa fa-trash center f-20" (click)="removeFromList(field?.id); $event.preventDefault(); $event.stopPropagation()"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex justify-content-end pt-2">
                <button class="btn cancel mr-4" (click)="closeRelationsModal()">
                    {{ 'translations.cancel' | translate }}
                </button>
                <button class="btn save" (click)="onSubmitNewtRelation()">{{ 'translations.save' | translate }}</button>
            </div>
        </form>
    </div>
</div>
<fap-modal #addEditAttributePopup inputTitle="Add/Edit Form fields">
    <form [formGroup]="addEditAttributeForm" class="col-12 p-0">
        <div class="form-group w-100 m-0">
            <mat-form-field class="w-100" (click)="addEditTranslation()">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name_t" readonly #nameInput>
                <mat-error *ngIf="addEditAttributeForm.controls['name_t'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="col-6">
                <mat-label class="col-form-label">{{ "notes.color" | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color"/>
                <mat-error *ngIf="addEditAttributeForm.controls['color'].errors?.required">
                    <strong>{{ "auth.validation.requiredField" | translate }}</strong>
                </mat-error>
            </mat-form-field>
            <div class="col-6">
                <label class="col-form-labl">Icon</label>
                <mat-form-field (click)="getImages()" class="w-100">
                <!-- <mat-label class="col-form-label" [ngClass]="imgPath ? 'float' : ''">{{ "widget.background" | translate }}</mat-label> -->
                <span style="position: absolute; text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;">{{ imgPath }}</span>
                <div class="form-group w-100">
                    <div class="rw h10">
                        <div class="d-flex w-100">
                            <div class="d-flex w-100" style="overflow-y: hidden;">
                                <div class="bx">
                                    <div class="input-group align-items-baseline w-100 img_bx">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{ 'Images' | translate }}</mat-label>
                                            <mat-select (selectionChange)="addImage($event)" name="image" (infiniteScroll)="scrolledDownImages()" msInfiniteScroll> <!--Add formcontrol name here-->
                                                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                                <mat-form-field appearance="fill" class="w-100 disbld">
                                                    <input matInput #typeFilter disabled placeholder="Search">
                                                </mat-form-field>
                                                <div class="input-group-append pl-1" (click)="showImageModal()">
                                                    <span class="input-group-text"><i
                                                            class="icofont icofont icofont-plus"></i></span>
                                                </div>
                                            </div>
                                            <div class="scroll_wrap" (scroll)="onScroll($event)">
                                                <mat-option style="height: 0!important;"></mat-option>
                                                <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
                                                    <div class="name" style="margin: 5px;">
                                                        <img style="height: 100px; width: 100%; object-fit: contain" [src]="mediaUrl + image.file" alt="image">
                                                    </div>
                                                    <div class="actions">
                                                        <i class="fa fa-trash" (click)="deleteImage(image?.id); $event.stopPropagation()"></i>
                                                    </div>
                                                </mat-option>
                                            </div>
                                            </mat-select>
                                        </mat-form-field>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-form-field>
        </div>
        </div>
        <div class="row">
            <mat-form-field class="col-6">
                <mat-label>{{ 'attribute.valueType' | translate }}</mat-label>
                <mat-select required formControlName='value_type' (selectionChange)="validateInputType($event.value)">
                <div class="scroll_wrap" style="padding-left: 10px;">
                <mat-option style="height: 0!important;"></mat-option>
                    <mat-option style="margin: 0;" class='row' *ngFor="let type of valueTypes" [value]='type'>
                        <div class="attr-type-name">{{ type | titlecase }}</div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field>
            <div class="col-6">
                <mat-form-field style="max-width: 100%; margin-left: -1px;" *ngIf="addEditAttributeForm.controls['value_type'].value ==='number'">
                    <mat-label>Unit</mat-label>
                <mat-select class="select u" required formControlName="unit">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #unitFilter placeholder="Search">
                        </mat-form-field>
                        <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                    </div>
                    <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-optgroup class="noteIcons" *ngFor="let parent of globalRegistry?.systemData?.units | filter : unitFilter.value">
                        <span class="mat-option-text">
                            <div class="type-name_title">
                                <span class="theme_color">{{parent.name}}</span>
                            </div>
                        </span>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                            <div class="type-name">
                                <span>{{child.shortName}}</span>
                            </div>
                        </mat-option>
                    </mat-optgroup>
                </div>
                </mat-select>
                </mat-form-field>
                <mat-form-field style="max-width: 100%; margin-left: -1px;" *ngIf="addEditAttributeForm.controls['value_type'].value ==='entity'">
                    <mat-label>{{ 'attribute.entity' | translate }}</mat-label>
                <mat-select required formControlName='entity'>
                <div class="scroll_wrap" style="padding-left: 10px;">
                <mat-option style="height: 0!important;"></mat-option>
                    <mat-option style="margin: 0;" class='row' *ngFor="let entity of allEntities" [value]='entity.id'>
                        <div class="attr-type-name">
                            <span *ngIf="getTranslation(entity?.name_t) === '' || getTranslation(entity?.name_t) == null || getTranslation(type?.name_t) == undefined ">{{entity?.name}}</span>
                            <span *ngIf="getTranslation(entity?.name_t) !== ''">{{getTranslation(entity?.name_t)}}</span>
                            </div>
                    </mat-option>
                </div>
                </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'attribute.inputType' | translate }}</mat-label>
                    <mat-select required formControlName='input_type'>
                    <div class="scroll_wrap" style="padding-left: 10px;">
                    <mat-option style="height: 0!important;"></mat-option>
                        <mat-option style="margin: 0;" class='row' *ngFor="let type of filteredInputTypes" [value]='type'>
                            <div class="attr-type-name">{{ type | titlecase }}</div>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="btns">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="selectedAttribute">
                    <i class="fa fa-trash" (click)="deleteAttribute(selectedAttribute?.id)"></i>
                </button>
            </div>
            <div class="action_btns d-flex justify-content-between mt-3">
                <button class="btn" (click)="addEditAttributePopup.hideModal(); addEditAttributeForm.reset()">{{ 'translations.cancel' | translate }}</button>
                <button class="btn" (click)="submitAttribute()">{{ 'translations.save' | translate }}</button>
            </div>
        </div>
    </form>
</fap-modal>
<fap-modal #addEditTranslationsModal [inputTitle]="'Attribute name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.selectedAttribute)" 
        [prefix]="'ATTRIBUTE'" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditFormTranslationsModal [inputTitle]="'Field name'">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.selectedFormType)" 
        [prefix]="'FIELD_'" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateFormTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>
