import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../../shared/shared.module';
import { PartialsModule } from '../../views-partials/partials.module';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import { DevicesComponent } from './devices.component';
import { DevicesContainerComponent } from './containers/devices-container/devices-container.component';
import { DevicesLayoutComponent } from './components/devices-layout/devices-layout.component';
import { DeviceItemComponent } from './components/device-item/device-item.component';
import { DeviceViewComponent } from './components/device-view/device-view.component';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResizableModule } from '../../views-partials/fap_view-components/resizable/resizable.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatCardModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        PartialsModule,
        InfiniteScrollModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        MatExpansionModule,
        MatListModule,
        MatCheckboxModule,
        DragDropModule,
        ResizableModule
    ],
    declarations: [
        DevicesComponent,
        DevicesContainerComponent,
        DevicesLayoutComponent,
        DeviceItemComponent,
        DeviceViewComponent,
        DevicesListComponent
    ]
})

export class DevicesModule {

}
