<div id="parent">
<div class="stock_box d-flex">
    <div class="stock_left">
        <div class="sbox" #stockTable (scroll)="onDivScroll('stockTable', $event)">
          <div class="d-flex theme_bg header" style="justify-content: space-between; align-items: center;">
            <h3 class="custom_title">{{ 'leftMenu.stock' | translate }}</h3>
            <span class="ricon" (click)="refreshItems()"></span>
          </div>
            <div class="table-responsive">
            <table class="stock_table">
                <thead>
                    <tr>
                        <th style="width: 40px;">
                            <!-- <input type="checkbox" name="checkAll" (change)="CheckAllOptions()"><span class="checkbx"></span> -->
                        </th>
                        <th style="width: 150px;">{{ 'stock.ingredients.ingredientQuantity' | translate }}</th>
                        <th>{{ 'stock.product' | translate }}</th>
                        <th>{{ 'stock.location' | translate }}</th>
                        <th style="width: 50px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of stockOverview">
                        <td><input type="checkbox" name="check" [(ngModel)]="item.checked" [value]="item.value" (change)="selectEntry($event, item)"><span class="checkbx"></span></td>
                        <td style="text-align: right; padding-right: 15px;">
                            <div class="frs">{{ item?.quantity | number: '1.0-2' }}</div>
                            <div class="inlin">
                                {{ item?.product | getProductUnit: globalRegistry?.systemData?.units }}
                            </div>
                        </td>
                        <td>
                            <b>{{item?.product?.name}}</b>
                            <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                                <i *ngIf="type?.id === item?.product?.type">{{type?.name}}</i>
                                <ng-container *ngIf="type?.id !== item?.product?.type">
                                    <ng-container *ngFor="let childUnit of type?.children">
                                        <i *ngIf="childUnit?.id === item?.product?.type">{{childUnit?.name}}</i> 
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                                <b><span *ngIf="farm?.id === item?.farm">{{farm?.name}}</span></b>
                            </ng-container>
                        </td>
                        <td class="dlt">
                            <span class="ricon"  (click)="refreshItem(item?.id)"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
    </div>
    <div class="stock_right" *ngIf="entries && entries.length > 0">
        <div class="entries" (scroll)="onDivScroll('entriesTable', $event)">
            <div class="sbox" #entriesTable>
                <div class="header">
                    <h3 class="custom_title">Entries</h3>
                </div>
                <div class="table-responsive">
                <table class="entry_table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{ 'stock.when' | translate }}</th>
                            <th>{{ 'stock.product' | translate }}</th>
                            <th class="green">{{ 'stock.available' | translate }}</th>
                            <th>{{ 'stock.price' | translate }}</th>
                            <th>{{ 'stock.from' | translate }}</th>
                            <th>{{ 'stock.invoice' | translate }}</th>
                            <th>{{ 'stock.invoiceQty' | translate }}</th>
                            <th>{{ 'stock.location' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let entry of entries" (click)="getTransaction(entry?.id)">
                            <td style="color: gray;">{{entry?.id}}.</td>
                            <td><i>{{getMoment(entry?.document?.doc_date)}}</i></td>
                            <td>
                                <b>{{entry?.product?.name}}</b> <span>
                                <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                                    <b style="font-weight: normal; display: block;" *ngIf="type?.id === entry?.product?.type">{{type?.name}}</b>
                                    <ng-container *ngIf="type?.id !== entry?.product?.type">
                                        <ng-container *ngFor="let childUnit of type?.children">
                                            <b style="font-weight: normal; display: block;" *ngIf="childUnit?.id === entry?.product?.type">{{childUnit?.name}}</b> 
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </span></td>
                            <td class="green split" style="font-size: 17px;  text-align: center">
                                <b style="width: 40px!important;">{{entry?.remains_quantity | number: '1.0-2'}}</b> 

                                <div class="inlin">
                                    <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.units}}</b>
                                </div>

                                <!-- <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                    <b *ngIf="unit?.id === entry?.unit">{{unit?.shortName}}</b>
                                    <ng-container *ngIf="unit?.id !== entry?.unit">
                                        <ng-container *ngFor="let childUnit of unit?.children">
                                            <b *ngIf="childUnit?.id === entry?.unit">{{childUnit?.shortName}}</b> 
                                        </ng-container>
                                    </ng-container>
                                </ng-container> -->
                            </td>
                            <td class="split"><b>{{entry?.cost | number:'1.'+globalRegistry.systemData.company.digits}} </b> 
                                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                    <b style="font-weight: normal;" *ngIf="unit?.id === entry?.unit">{{unit?.shortName}}</b>
                                    <ng-container *ngIf="unit?.id !== entry?.document?.currency">
                                        <ng-container *ngFor="let childUnit of unit?.children">
                                            <b style="font-weight: normal;" *ngIf="childUnit?.id === entry?.document?.currency">{{childUnit?.shortName}}</b> 
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <!-- <ng-container *ngFor="let party of globalRegistry?.systemData?.parties">
                                    <span *ngIf="party?.id === entry?.document?.party_from">{{party?.name}}</span>
                                </ng-container> -->
                                <span>{{getPartyInfo(entry?.document?.party_from) | async}}</span>
                            </td>
                            <td>{{entry?.document?.doc_id}} <span style="display:block;">{{entry?.document?.doc_date | date: "MMM dd, yyyy"}}</span></td>
                            <td class="p45 split"><b style="width: 40px!important;">{{entry?.quantity}} </b> 
                                <div class="inlin">
                                   <b>{{ entry?.product | getProductUnit: globalRegistry?.systemData?.units }}</b>
                                </div>
                            </td>
                            <td>
                                <ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                                    <b *ngIf="farm?.id === entry?.document?.farm">{{farm?.name}}</b>
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
        <div class="transactions" *ngIf="transactions[0]?.id" (scroll)="onDivScroll('transactionsTable', $event)">
            <div class="sbox" #transactionsTable>
                <div class="header">
                <h3 class="custom_title">Transactions on: <b style="padding-left: 5px; padding-right: 10px;">{{transactions[0]?.resource_entry?.product?.name}}</b> <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                    <i *ngIf="type?.id === transactions[0]?.resource_entry?.product?.type"> {{type?.name}}</i>
                    <ng-container *ngIf="type?.id !== transactions[0]?.resource_entry?.product?.type">
                        <ng-container *ngFor="let childUnit of type?.children">
                            <i *ngIf="childUnit?.id === transactions[0]?.resource_entry?.product?.type">{{childUnit?.name}}</i> 
                        </ng-container>
                    </ng-container>
                </ng-container></h3>
                </div>
                <div class="table-responsive">
                <table class="transaction_table">
                    <thead>
                        <tr>
                            <th>{{ 'stock.when' | translate }}</th>
                            <th>{{ 'stock.quantity' | translate }}</th>
                            <th>{{ 'stock.cost' | translate }}</th>
                            <th>{{ 'stock.from' | translate }}</th>
                            <th>{{ 'stock.to' | translate }}</th>
                            <th>{{ 'stock.document' | translate }}</th>
                            <th>{{ 'stock.location' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of transactions">
                            <td><i>{{getMoment(transaction?.document?.doc_date)}}</i></td>
                            <td class="split trr" style="font-size: 17px;  text-align: center;" [style.color]="transaction?.quantity > 0 ? 'rgba(43, 103, 76, 1)' : 'rgba(255, 0, 0, 1)'"><b style="width: 40px!important">
                                {{transaction?.quantity | number: '1.0-2'}}
                            </b> 

                            <div class="inlin">
                                <b>{{ transaction?.resource_entry?.product | getProductUnit: globalRegistry?.systemData?.units}}</b>
                            </div>
                        </td>
                        <td><b>{{transaction?.cost | number:'1.'+globalRegistry.systemData.company.digits}} </b> 
                            <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                <b style="font-weight: normal;" *ngIf="unit?.id === transaction?.document?.currency">{{unit?.shortName}}</b>
                                <ng-container *ngIf="unit?.id !== transaction?.resource_entry?.unit">
                                    <ng-container *ngFor="let childUnit of unit?.children">
                                        <b style="font-weight: normal; padding-left: 5px;" *ngIf="childUnit?.id === transaction?.document?.currency">{{childUnit?.shortName}}</b> 
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <span>{{getPartyInfo(transaction?.document?.party_from) | async}}</span>
                        </td>
                        <td>
                            {{getPartyInfo(transaction?.document?.party_to) | async}}
                        </td>
                        <td (click)="goToBill(transaction?.document?.id, transaction?.document?.type)"><a class="underline">{{transaction?.document?.doc_id}} <span style="display: block;">{{transaction?.document?.doc_date | date: "MMM dd, yyyy"}}</span></a></td>
                            <td><ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                                <b *ngIf="farm?.id === transaction?.document?.farm">{{farm?.name}}</b>
                            </ng-container></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</div>

<div class="custom_modal" id="docs_modal">
    <div class="modal_header">
        <h2>{{ 'stock.documents' | translate }}</h2>
        <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
    </div>
    <div class="modal_body">
        <div class="docs">
        <div class="search_filters" #doc_search>
            <form class ="doc_search" [formGroup]="docSearchForm" [ngClass]="status ? 'show' : ''">
                <div class="form-group m-0">
                    <div class="mt-2 row align-items-baseline">
                        <div class="col-10 p-0">
                            <div class="row search_form">
                                <div class="col-2">
                                    <div class="input-group align-items-baseline w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label class="col-form-label">{{ 'stock.search' | translate }}</mat-label>
                                            <input type="text" matInput formControlName="search">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="input-group align-items-baseline w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{ 'stock.type' | translate }}</mat-label>
                                            <mat-select formControlName="type">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <input matInput #docTypeFilter placeholder="Search">
                                                </mat-form-field>
                                                <div class="scroll_wrap">
                                                    <mat-option [value]="null"></mat-option>
                                                <mat-option *ngFor="let type of documentTypes | filter : docTypeFilter.value" [value]="type.key">
                                                    {{type.value}}
                                                </mat-option>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="input-group align-items-baseline w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{ 'stock.farm' | translate }}</mat-label>
                                            <mat-select formControlName="farm">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <input matInput #farmFilter placeholder="Search">
                                                </mat-form-field>
                                                <div class="scroll_wrap">
                                                    <mat-option [value]="null"></mat-option>
                                                <mat-option *ngFor="let farm of globalRegistry?.systemData?.farms | filter : farmFilter.value" [value]="farm.id">
                                                    {{farm.name}}
                                                </mat-option>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group align-items-baseline w-100">
                                    <!-- <mat-form-field class="w-100" (click)="from.open()">
                                        <mat-label>{{ 'stock.from' | translate }}</mat-label>
                                        <input matInput readonly formControlName="from" [matDatepicker]="from">
                                        <mat-datepicker-toggle matSuffix [for]="from">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #from></mat-datepicker>
                                        <mat-error *ngIf="ifControlHasError('from','required')">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field> -->

                                    <mat-form-field (click)="from.open()">
                                        <input
                                            matInput
                                            readonly
                                            [ngxMatDatetimePicker]="from"
                                            placeholder="From"
                                            [min]="minDate"
                                            (dateChange)="maxValueChanged($event)"
                                            [disabled]="false"
                                            formControlName="from"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="from"
                                        ></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            [touchUi]="true"
                                            #from
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [hideTime]="true"
                                        >
                                        </ngx-mat-datetime-picker>
                                    </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="input-group align-items-baseline w-100">
                                    <!-- <mat-form-field class="w-100" (click)="to.open()">
                                        <mat-label>{{ 'stock.to' | translate }}</mat-label>
                                        <input matInput readonly formControlName="to" [matDatepicker]="to">
                                        <mat-datepicker-toggle matSuffix [for]="to">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #to></mat-datepicker>
                                        <mat-error *ngIf="ifControlHasError('to','required')">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field> -->


                                    <mat-form-field (click)="to.open()">
                                        <input
                                            matInput
                                            readonly
                                            [ngxMatDatetimePicker]="to"
                                            placeholder="To"
                                            [min]="minDate"
                                            (dateChange)="maxValueChanged($event)"
                                            [disabled]="false"
                                            formControlName="to"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="to"
                                        ></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            [touchUi]="true"
                                            #to
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [hideTime]="true"
                                        >
                                        </ngx-mat-datetime-picker>
                                    </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 p-0">
                            <div class="text-center">
                                <button class="btn btn-lg" type="button" (click)="filterDocs()">
                                    <img src="../../../../../../assets/images/search.png" alt="search">
                                </button>
                                <button type="button" class="refresh" (click)="clearDocInputs()">
                                    <img src="../../../../../../assets/images/filter.png" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <span class="toggle_filters" (click)="toggleDocFilter()">Toggle filters <img src="../../../../../../assets/images/setting.png"></span>
        </div>
        <div infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="0"
            (scrolled)="scrolledDown()"
            [scrollWindow]="false"
            #documentTable
            (scroll)="onDivScroll('documentTable', $event)"
            style="height: calc(100% - 65px); flex: auto; overflow-y: auto;">
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Doc ID</th>
                            <th>Party From</th>
                            <th>Party To</th>
                            <th>Farm</th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doc of docs">
                            <td>{{doc.docDate | date: "MMM dd yyyy"}}</td>
                            <td style="text-transform: capitalize;">{{doc.type.replace('_', ' ')}}</td>
                            <td (click)="goToBill(doc.id, doc.type)"><a class="link">{{doc.docId}}</a></td>
                            <td>
                                {{doc?.partyFrom?.name}}
                            </td>
                            <td>
                                {{doc?.partyTo?.name}}
                            </td>
                            <td>
                                <ng-container *ngFor="let farm of globalRegistry.systemData.farms">
                                    <ng-container *ngIf="farm.id == doc.farm">{{farm?.name}}</ng-container>
                                </ng-container>
                            </td>
                            <!-- <td>
                                <div class="btns">
                                    <button class="btn-white-bg" (click)="exportAsPDF(doc)" title="print PDF"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>
                                    <button class="btn-white-bg" (click)="exportAsExcel(doc)" title="download as Excel"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>

  <div class="custom_modal" id="products_modal">
    <div class="modal_header">
        <h2>Products</h2>
        <button type="button" class="btn close-modal" (click)="hideProductsModal()"><i class="fa fa-times"></i></button>
    </div>
    <div class="modal_body">
        <div class="prods">
        <div class="search_filters">
            <form class ="prod_search" [formGroup]="prodFilterForm" [ngClass]="prodStatus ? 'show' : ''">
                <div class="form-group m-0">
                    <div class="mt-2 row align-items-baseline">
                        <div class="col-8 p-0">
                            <div class="row search_form">
                                <div class="col-6">
                                    <div class="input-group align-items-baseline w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label class="col-form-label">{{ 'stock.search' | translate }}</mat-label>
                                            <input type="text" matInput formControlName="search">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{ 'stock.type' | translate }}</mat-label>
                                            <mat-select #rsp class="select" formControlName="type">
                                                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                                    <mat-form-field appearance="fill" class="w-100">
                                                        <input matInput #resourceFilter placeholder="Search">
                                                    </mat-form-field>
                                                    <!-- <em class="fa fa-plus center f-20 ml-2" (click)="selectResourceType()"></em> -->
                                                </div>
                                                <div class="scroll_wrap">
                                                <mat-option style="height: 0!important;"></mat-option>
                                                <mat-option [value]="null"></mat-option>
                                                <mat-optgroup class="noteIcons" *ngFor="let parent of globalRegistry?.systemData?.resourceTypes | filter : resourceFilter.value">
                                                    <span class="mat-option-text">
                                                        <div class="type-name green">
                                                            <span *ngIf="parent?.nameT">
                                                                <span *ngIf="getTranslation(parent?.nameT) === '' || getTranslation(parent?.nameT) == null || getTranslation(parent?.nameT) == undefined ">{{parent?.name}}</span>
                                                                <span *ngIf="getTranslation(parent?.nameT) !== ''">{{getTranslation(parent?.nameT)}}</span>
                                                            </span>
                                                            <span *ngIf="!parent?.nameT">{{parent.name}}</span>
                                                        </div>
                                                        <!-- <div class="actions d-flex text-center">
                                                            <i class="fa fa-edit align-self-center f-20"  (click)="selectResourceType(parent);$event.stopPropagation()"></i>
                                                            <i class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteResourceType(parent?.id) ;$event.stopPropagation()"></i>
                                                        </div> -->
                                                    </span>
                                                    <mat-option style="height: 0!important;"></mat-option>
                                                    <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                                        <div class="type-name">
                                                            <span *ngIf="child?.nameT">
                                                                <span *ngIf="getTranslation(child?.nameT) === '' || getTranslation(child?.nameT) == null || getTranslation(child?.nameT) == undefined ">{{child?.name}}</span>
                                                                <span *ngIf="getTranslation(child?.nameT) !== ''">{{getTranslation(child?.nameT)}}</span>
                                                            </span>
                                                            <span *ngIf="!child?.nameT">{{child.name}}</span>
                                                        </div>
                                                        <!-- <div class="actions d-flex text-center">
                                                            <i class="fa fa-edit align-self-center f-20"  (click)="selectResourceType(child, parent);$event.stopPropagation()"></i>
                                                            <i class="fa fa-trash align-self-center ml-2 f-20"  (click)="deleteResourceType(child?.id) ;$event.stopPropagation()"></i>
                                                        </div> -->
                                                    </mat-option>
                                                </mat-optgroup>
                                            </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 p-0">
                            <div class="text-center">
                                <button class="btn btn-lg" type="button" (click)="filterProduct()">
                                    <img src="../../../../../../assets/images/search.png" alt="search">
                                </button>
                                <button class="button" class="add_prod">
                                    <em class="fa fa-plus center f-20 ml-2" (click)="showProductModal()"></em>
                                </button>
                                <button type="button" class="refresh" (click)="clearProdInputs()">
                                    <img src="../../../../../../assets/images/filter.png" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <span class="toggle_filters" (click)="toggleProdFilter()">Toggle filters <img src="../../../../../../assets/images/setting.png"></span>
        </div>
        <div infiniteScroll
            [infiniteScrollDistance]="4"
            [infiniteScrollThrottle]="0"
            (scrolled)="scrolledDownProducts()"
            [scrollWindow]="false"
            style="height: calc(100% - 65px); flex: auto; overflow-y: auto;">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Product</th>
                            <th>Producer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products">
                            <td>{{ product.id }}.</td>
                            <td (click)="productEdit(product)"><a class="link">{{ product.name }}</a></td>
                            <td>
                                {{product?.producer?.name}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</div>

<fap-modal #addEditProductModal
    inputTitle="Add/Edit Product">
        <fap-add-edit-product 
            [productId]="productId"
            (addedProduct)="getProducts();addEditProductModal.hideModal()"
            (updatedProduct)="getProducts();addEditProductModal.hideModal()"
        >
        </fap-add-edit-product>
</fap-modal>